/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {
    IReaderViewOptions,
} from "@colibrio/colibrio-reader-framework/colibrio-readingsystem-base";
import {
    IEpubReaderPublicationOptions
} from "@colibrio/colibrio-reader-framework/colibrio-readingsystem-formatadapter-epub";

export const VanillaReaderDefaultColibrioEpubReaderPublicationOptions: IEpubReaderPublicationOptions = {
    // Media will be streamed from the EPUB file when possible. This is currently supported for constant bitrate
    // MP3 audio that has been added to the EPUB using the ZIP "store" method.
    enableMediaStreaming: true,
    // Prevent users from right-clicking and downloading images and video etc.
    preventDefaultContextMenuOnMediaElements: true,
    // Reflow is the Colibrio module that paginates reflowable EPUBs. It is highly configurable. In the options
    // below we tell Reflow that it is ok to divide HTML elements as a default, except for the black listed
    // selectors specified.
    reflowOptions: {
        plugins: {
            fragmentableElements: {
                allowByDefault: true,
                blacklistSelectors: [
                    'details',
                    'figure',
                    'mathml',
                ],
            },
        },
    },
    // Sometimes you will want to override certain CSS in one or more publication. The following options
    // injects a CSS rule that "turns off" the focus marker *before* any of the publications own CSS, and
    // also injects CSS rules *after* the publications own CSS, thus overriding it.
    customPublicationCss: {
        injectionPointStart: [
            ':focus-visible {outline: 0 dotted;}',
        ],
        injectionPointEnd: [
            'col:empty {display: none;}',
            'details, figure {page-break-inside: avoid;}',
            'details[open] {height: auto; overflow-y: scroll; background-color: Canvas; }',
        ],
    },
};

export const VanillaReaderDefaultColibrioReaderViewOptions: IReaderViewOptions = {
    transformManagerOptions: {
        removeTransformOnNavigation: true,
    },
    gestureOptions: {
        panZoom: {
            allowSinglePointerPan: true,
            maxPanOffsetHorizontal: '0%',
            maxPanOffsetVertical: '0%',
            pointerTypes: {
                pen: true,
                mouse: true,
                touch: true
            }
        }
    }
};