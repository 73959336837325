/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {
    VanillaNumberValueCallback,
    VanillaVoidCallback, VanillaZoomToLevelCallback,
    VanillaZoomToolDragCallback
} from "../VanillaReader/VanillaReaderModel";
import {ICoordinates} from "../utils/ICoordinates";
import {VanillaReaderUiFabPanZoomToolExit} from "./VanillaReaderUiFabPanZoomToolExit";
import {ITransformData} from "@colibrio/colibrio-reader-framework/colibrio-readingsystem-base";

/**
 * # VanillaReaderUiPanZoomTool
 *
 * ## RESPONSIBILITIES
 *
 * This class creates UI components to allow the user to zoom to locations in the view by dragging a rectangle.
 * It also controls a floating action button that helps the user exit the pan zoom tool.
 *
 * ## RELATED TYPES
 * - VanillaReaderUiPanZoomToolController
 * - VanillaReaderUiFabPanZoomToolExit
 *
 */
export class VanillaReaderUiPanZoomTool {

    private _bodyStyle = `
        <style>
        
            #vanilla-reader__pan-zoom-viewport {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;                
                z-index: 1000;                
                pointer-events: all;
            }
                                   
            #vanilla-reader__pan-zoom-tool {
                position: absolute;
                width: 0;
                height: 0;
                border: 1px dotted var(--vanilla-reader__color__ui__outline-dark);
                background-color: var(--vanilla-reader__color__ui__accent);
                mix-blend-mode: multiply;       
                opacity: 0.2;         
            }
            
            #vanilla-reader__pan-zoom-viewport.dragging {
                
            }          

            #vanilla-reader__pan-zoom-viewport.panning {
                cursor: grab;
                pointer-events: none;
            }
                          
            #vanilla-reader__pan-zoom-viewport.zooming-in {
                cursor: zoom-in;
            }          

            #vanilla-reader__pan-zoom-viewport.zooming-out {
                cursor: zoom-out;
            }          
              
        </style>`;

    private _bodyTemplate = `<div id="vanilla-reader__pan-zoom-viewport"><div id="vanilla-reader__pan-zoom-tool"></div></div>`;

    private _hostElement: HTMLElement;

    private _zoomToolElement: HTMLElement | undefined;
    private _panZoomViewportElement: HTMLElement | undefined;

    private _pointerDownStartCoords: ICoordinates | undefined;
    private _isBeingDragged: boolean = false;
    private _isZoomDisabled: boolean = false;
    private _isActive: boolean = false;

    private _onZoomStateActivatedCallback: VanillaVoidCallback | undefined;
    private _onZoomStateDeactivatedCallback: VanillaVoidCallback | undefined;
    private _onZoomToolDragCallback: VanillaZoomToolDragCallback | undefined;
    private _onZoomToolDragStartCallback: VanillaZoomToolDragCallback | undefined;
    private _onZoomToolDragEndCallback: VanillaZoomToolDragCallback | undefined;
    private _onZoomDisabledCallback: VanillaVoidCallback | undefined;
    private _onZoomEnabledCallback: VanillaVoidCallback | undefined;
    private _onZoomResetCallback: VanillaVoidCallback | undefined;
    private _onZoomToLevelCallback: VanillaZoomToLevelCallback | undefined;
    private _onPanningDisabledCallback: VanillaVoidCallback | undefined;
    private _onPanningEnabledCallback: VanillaVoidCallback | undefined;

    private _activeTransformData: ITransformData | undefined;

    buttonFabPanZoomExit: VanillaReaderUiFabPanZoomToolExit;


    constructor(private _fabHostElement: HTMLElement) {
        this._hostElement = document.body;
        this._hostElement.insertAdjacentHTML("beforeend", this._bodyStyle);
        this.buttonFabPanZoomExit = new VanillaReaderUiFabPanZoomToolExit(this._fabHostElement);
        this.buttonFabPanZoomExit.onClick(()=>{
            this.deactivate()
        });
    }

    public get currentZoomRect(): DOMRect | undefined {
        return this._zoomToolElement?.getBoundingClientRect();
    }

    public get isBeingDragged(): boolean {
        return this._isBeingDragged;
    }

    public get isZoomEnabled(): boolean {
        return !this._isZoomDisabled;
    }

    public get isActive(): boolean {
        return this._isActive;
    }

    public get activeTransformData(): ITransformData | undefined {
        return this._activeTransformData;
    }

    public set activeTransformData(data: ITransformData | undefined) {
        this._activeTransformData = data;
        this._maybeToggleExitFab(data);
    }

    onDragStart(callback: VanillaZoomToolDragCallback) {
        this._onZoomToolDragStartCallback = callback;
    }

    onDrag(callback: VanillaZoomToolDragCallback) {
        this._onZoomToolDragCallback = callback;
    }

    onDragEnd(callback: VanillaZoomToolDragCallback) {
        this._onZoomToolDragEndCallback = callback;
    }

    onActivate(callback: VanillaVoidCallback) {
        this._onZoomStateActivatedCallback = callback;
    }

    onDeactivate(callback: VanillaVoidCallback) {
        this._onZoomStateDeactivatedCallback = callback;
    }

    onZoomDisabled(callback: VanillaVoidCallback) {
        this._onZoomDisabledCallback = callback;
    }

    onZoomEnabled(callback: VanillaVoidCallback) {
        this._onZoomEnabledCallback = callback;
    }

    onZoomReset(callback: VanillaVoidCallback) {
        this._onZoomResetCallback= callback;
    }

    onZoomToLevel(callback: VanillaNumberValueCallback) {
        this._onZoomToLevelCallback = callback;
    }

    onPanningDisable(callback: VanillaVoidCallback) {
        this._onPanningDisabledCallback = callback;
    }

    onPanningEnable(callback: VanillaVoidCallback) {
        this._onPanningEnabledCallback = callback;
    }

    disablePointerZoom() {
        if(this._panZoomViewportElement && this._zoomToolElement) {
            this._panZoomViewportElement.removeEventListener('pointerdown', this._pointerDown);
            this._panZoomViewportElement.removeEventListener('pointermove', this._pointerMove);
            this._panZoomViewportElement.removeEventListener('pointerup', this._pointerUp);

            this._isZoomDisabled = true;

            this._panZoomViewportElement.classList.remove('zooming-in', 'zooming-out');
            this._panZoomViewportElement.classList.add('panning');

        }

        if(this._onZoomDisabledCallback) {
            this._onZoomDisabledCallback();
        }
    }

    enablePointerZoom() {
        if(this._panZoomViewportElement && this._zoomToolElement) {
            this._panZoomViewportElement.addEventListener('pointerdown', this._pointerDown);
            this._panZoomViewportElement.addEventListener('pointermove', this._pointerMove);
            this._panZoomViewportElement.addEventListener('pointerup', this._pointerUp);

            this._isZoomDisabled = false;

            this._panZoomViewportElement.classList.remove('panning');
            this._panZoomViewportElement.classList.add('zooming-in');
        }

        if(this._onZoomEnabledCallback) {
            this._onZoomEnabledCallback();
        }
    }

    enablePanning() {
        this.disablePointerZoom();
        if(this._panZoomViewportElement) {
            this._panZoomViewportElement.classList.add('panning');
            this._panZoomViewportElement.classList.remove('zooming-in');
        }
        if(this._onPanningEnabledCallback) {
            this._onPanningEnabledCallback();
        }
    }

    disablePanning() {
        this.enablePointerZoom();
        if(this._panZoomViewportElement) {
            this._panZoomViewportElement.classList.remove('panning');
            this._panZoomViewportElement.classList.add('zooming-in');
        }
        if(this._onPanningDisabledCallback) {
            this._onPanningDisabledCallback();
        }

    }

    resetZoomLevel() {
        if(this._onZoomResetCallback) {
            this._onZoomResetCallback();
        }
    }

    activate() {

        if(this.isActive) {
            return;
        }

        if(!this._panZoomViewportElement) {
            this._hostElement.insertAdjacentHTML("beforeend", this._bodyTemplate);
            this._panZoomViewportElement = this._hostElement.querySelector<HTMLElement>('#vanilla-reader__pan-zoom-viewport')!;
            this._zoomToolElement = this._hostElement.querySelector<HTMLElement>('#vanilla-reader__pan-zoom-tool')!;
            this._panZoomViewportElement.addEventListener('pointerdown', this._pointerDown);
            this._panZoomViewportElement.addEventListener('pointermove', this._pointerMove);
            this._panZoomViewportElement.addEventListener('pointerup', this._pointerUp);
            this._panZoomViewportElement.addEventListener('keyup', this._keyUp);

            this._isActive = true;
        }

        if(this._onZoomStateActivatedCallback) {
            this._onZoomStateActivatedCallback();
        }

    }

    deactivate() {

        if(!this.isActive) {
            return;
        }

        if(this._panZoomViewportElement && this._zoomToolElement) {
            this._panZoomViewportElement.removeEventListener('pointerdown', this._pointerDown);
            this._panZoomViewportElement.removeEventListener('pointermove', this._pointerMove);
            this._panZoomViewportElement.removeEventListener('pointerup', this._pointerUp);
            this._panZoomViewportElement.removeEventListener('keyup', this._keyUp);

            this._panZoomViewportElement.removeChild(this._zoomToolElement);
            this._hostElement.removeChild(this._panZoomViewportElement);

            this._panZoomViewportElement = undefined;
            this._zoomToolElement = undefined;

            this.buttonFabPanZoomExit.hide();

            this._isActive = false;
        }

        if(this._onZoomStateDeactivatedCallback) {
            this._onZoomStateDeactivatedCallback();
        }

    }

    zoomToRect(_domRect: DOMRect, _animate?: boolean) {

    }

    zoomToPoint(_x: number, _y: number, _animate?: boolean) {

    }

    zoomToolToLevel(zoomLevel: number, animate?: boolean) {
        if(this._onZoomToLevelCallback) {
            this._onZoomToLevelCallback(zoomLevel, animate);
        }
    }

    resetZoomToolRect() {
        if(this._zoomToolElement) {
            this._zoomToolElement.style.top = '0';
            this._zoomToolElement.style.left = '0';
            this._zoomToolElement.style.width = '0';
            this._zoomToolElement.style.height = '0';
        }
    }

    /**
     *
     * PRIVATE METHODS
     *
     * */

    private _maybeToggleExitFab(data: ITransformData | undefined) {
        if(data) {
            if(!this.buttonFabPanZoomExit.isVisible) {
                this.buttonFabPanZoomExit.show();
            }
        } else {
            if(this.buttonFabPanZoomExit.isVisible) {
                this.buttonFabPanZoomExit.hide();
            }
        }
    }

    private _pointerDown = (ev: PointerEvent) => {
        if(!this._panZoomViewportElement || !this._zoomToolElement) {
            return;
        }

        ev.preventDefault();
        ev.stopImmediatePropagation();

        this.resetZoomToolRect();

        this._pointerDownStartCoords = {x: ev.clientX, y: ev.clientY};
        this._zoomToolElement.style.top = `${ev.clientY}px`;
        this._zoomToolElement.style.left = `${ev.clientX}px`;

        if(this._onZoomToolDragStartCallback) {
            this._onZoomToolDragStartCallback(ev, this.currentZoomRect);
        }

    }

    private _pointerMove = (ev: PointerEvent) => {

        if(!this._panZoomViewportElement || !this._zoomToolElement) {
            return;
        }

        if(!this._pointerDownStartCoords) {
            return;
        }

        ev.preventDefault();
        ev.stopImmediatePropagation();

        let xPositionDelta = ev.clientX - this._pointerDownStartCoords.x;
        let yPositionDelta = ev.clientY - this._pointerDownStartCoords.y;
        this._isBeingDragged = true;
        this._zoomToolElement.style.width = `${xPositionDelta}px`;
        this._zoomToolElement.style.height = `${yPositionDelta}px`;

        this._zoomToolElement.classList.add('dragging');

        if(this._onZoomToolDragCallback) {
            this._onZoomToolDragCallback(ev, this.currentZoomRect);
        }

    }

    private _pointerUp = (ev: PointerEvent) => {
        if(!this._panZoomViewportElement || !this._zoomToolElement) {
            return;
        }

        ev.preventDefault();
        ev.stopImmediatePropagation();

        this._isBeingDragged = false;
        this._zoomToolElement.classList.remove('dragging');

        this._pointerDownStartCoords = undefined;

        if(this._onZoomToolDragEndCallback) {
            this._onZoomToolDragEndCallback(ev, this.currentZoomRect);
        }
    }

    private _keyUp = (ev: KeyboardEvent) => {
        switch (ev.key) {
            case 'Escape':
                this.deactivate();
                break;
        }
    };
}