/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.4.0
 */
 'use strict'; (function (s, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-base','./colibrio-core-io-base','./colibrio-core-io-mediatypedetector','./colibrio-core-url'], function (a0,a1,a2,a3) { return f(s,a0,a1,a2,a3); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(s,require('./colibrio-core-base'),require('./colibrio-core-io-base'),require('./colibrio-core-io-mediatypedetector'),require('./colibrio-core-url')); } else { throw new Error('Unsupported runtime environment!'); } }( typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function (__self,__dep__colibrio_core_base,__dep__colibrio_core_io_base,__dep__colibrio_core_io_mediatypedetector,__dep__colibrio_core_url) {  var api = {}; (function () { var h;function k(a){var b=0;return function(){return b<a.length?{done:!1,value:a[b++]}:{done:!0}}}var l="function"==typeof Object.defineProperties?Object.defineProperty:function(a,b,e){if(a==Array.prototype||a==Object.prototype)return a;a[b]=e.value;return a};
function m(a){a=["object"==typeof globalThis&&globalThis,a,"object"==typeof window&&window,"object"==typeof self&&self,"object"==typeof global&&global];for(var b=0;b<a.length;++b){var e=a[b];if(e&&e.Math==Math)return e}throw Error("Cannot find global object");}var n=m(this);function q(a,b){if(b)a:{var e=n;a=a.split(".");for(var d=0;d<a.length-1;d++){var f=a[d];if(!(f in e))break a;e=e[f]}a=a[a.length-1];d=e[a];b=b(d);b!=d&&null!=b&&l(e,a,{configurable:!0,writable:!0,value:b})}}
q("Symbol",function(a){function b(c){if(this instanceof b)throw new TypeError("Symbol is not a constructor");return new e(d+(c||"")+"_"+f++,c)}function e(c,g){this.g=c;l(this,"description",{configurable:!0,writable:!0,value:g})}if(a)return a;e.prototype.toString=function(){return this.g};var d="jscomp_symbol_"+(1E9*Math.random()>>>0)+"_",f=0;return b});
q("Symbol.iterator",function(a){if(a)return a;a=Symbol("Symbol.iterator");for(var b="Array Int8Array Uint8Array Uint8ClampedArray Int16Array Uint16Array Int32Array Uint32Array Float32Array Float64Array".split(" "),e=0;e<b.length;e++){var d=n[b[e]];"function"===typeof d&&"function"!=typeof d.prototype[a]&&l(d.prototype,a,{configurable:!0,writable:!0,value:function(){return r(k(this))}})}return a});function r(a){a={next:a};a[Symbol.iterator]=function(){return this};return a}
var aa="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},t;if("function"==typeof Object.setPrototypeOf)t=Object.setPrototypeOf;else{var u;a:{var ba={a:!0},v={};try{v.__proto__=ba;u=v.a;break a}catch(a){}u=!1}t=u?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var w=t;function x(){this.i=!1;this.g=null;this.I=void 0;this.j=1;this.K=0;this.h=null}
function y(a){if(a.i)throw new TypeError("Generator is already running");a.i=!0}x.prototype.H=function(a){this.I=a};function z(a,b){a.h={M:b,N:!0};a.j=a.K}x.prototype.return=function(a){this.h={return:a};this.j=this.K};function ca(a,b){a.j=2;return{value:b}}function da(a){this.g=new x;this.h=a}function ea(a,b){y(a.g);var e=a.g.g;if(e)return A(a,"return"in e?e["return"]:function(d){return{value:d,done:!0}},b,a.g.return);a.g.return(b);return B(a)}
function A(a,b,e,d){try{var f=b.call(a.g.g,e);if(!(f instanceof Object))throw new TypeError("Iterator result "+f+" is not an object");if(!f.done)return a.g.i=!1,f;var c=f.value}catch(g){return a.g.g=null,z(a.g,g),B(a)}a.g.g=null;d.call(a.g,c);return B(a)}function B(a){for(;a.g.j;)try{var b=a.h(a.g);if(b)return a.g.i=!1,{value:b.value,done:!1}}catch(e){a.g.I=void 0,z(a.g,e)}a.g.i=!1;if(a.g.h){b=a.g.h;a.g.h=null;if(b.N)throw b.M;return{value:b.return,done:!0}}return{value:void 0,done:!0}}
function fa(a){this.next=function(b){y(a.g);a.g.g?b=A(a,a.g.g.next,b,a.g.H):(a.g.H(b),b=B(a));return b};this.throw=function(b){y(a.g);a.g.g?b=A(a,a.g.g["throw"],b,a.g.H):(z(a.g,b),b=B(a));return b};this.return=function(b){return ea(a,b)};this[Symbol.iterator]=function(){return this}}function ha(a){function b(d){return a.next(d)}function e(d){return a.throw(d)}return new Promise(function(d,f){function c(g){g.done?d(g.value):Promise.resolve(g.value).then(b,e).then(c,f)}c(a.next())})};var C=__dep__colibrio_core_base;var ia=__dep__colibrio_core_io_base;var D=__dep__colibrio_core_url;var E=new Uint16Array(1);E[0]=4660;new Uint8Array(E.buffer);function F(a,b){return C.ColibrioError.call(this,"colibrio.core.io.resourcecache",a,b)||this}var G=C.ColibrioError;F.prototype=aa(G.prototype);F.prototype.constructor=F;if(w)w(F,G);else for(var H in G)if("prototype"!=H)if(Object.defineProperties){var I=Object.getOwnPropertyDescriptor(G,H);I&&Object.defineProperty(F,H,I)}else F[H]=G[H];function J(a){this.h=a;this.j=[];this.g=!1;this.i=a.I++}h=J.prototype;h.createUrlForContent=function(a,b,e){a=this.h.h.createUrl(a,b,e).url;this.j.push(a);return a};h.destroy=function(){var a=this;this.g||(this.g=!0,this.j.forEach(function(b){a.h.h.revokeUrl(b)}),this.j=[],ja(this.h,this.i))};h.fetchBlob=function(a,b){return this.g?K():ka(this.h,a,b,this.i)};h.fetchBrowserNativeUrl=function(a,b,e){return this.g?K():la(this.h,a,b,e,this.i)};
h.fetchText=function(a,b){return this.g?K():ma(this.h,a,b,this.i)};h.fetchUint8Array=function(a,b){return this.g?K():na(this.h,a,b,this.i)};h.isDestroyed=function(){return this.g};function K(){return Promise.reject(new F(C.ColibrioErrorType.ABORTED))};function oa(a){return new Promise(function(b,e){var d=new FileReader;d.onload=function(){d.onabort=null;d.onload=null;d.onerror=null;b(d.result)};d.onabort=d.onerror=function(){d.onabort=null;d.onload=null;d.onerror=null;e(d.error)};d.readAsArrayBuffer(a)})};function L(){this.h=this.g=void 0;this.i=new TextDecoder("utf-8")}var M;L.prototype.decode=function(a){return 2<=a.length&&254===a[0]&&255===a[1]&&(void 0===this.g&&(this.g=N("utf-16be")),this.g)?this.g.decode(a):2<=a.length&&255===a[0]&&254===a[1]&&(void 0===this.h&&(this.h=N("utf-16le")),this.h)?this.h.decode(a):this.i.decode(a)};function N(a){try{return new TextDecoder(a)}catch(b){return console.error(b),null}};function O(a){this.H=a;this.h=new ia.BlobUrlManager;this.g=new Map;this.j=new Map;this.i=!1;this.I=1;this.K=new TextEncoder}function ka(a,b,e,d){if(a.i)return P();b=Q(a,b);var f=R(a,b,e);d=S(a,d,f);f.B++;d.B++;if(f.l)return f.l;f.l=T(a,b,e,f).then(function(c){U(a,f,f.l);return{data:V(c),metadata:c.metadata}}).catch(function(c){W(a,f,f.l)&&(C.Logger.logWarning("Unexpected ResourceCache error"),X(a,f));throw c;});return f.l}
function la(a,b,e,d,f){if(a.i)return P();b=Q(a,b);var c=R(a,b,e);d=!C.RuntimePlatform.isBlobUrlSupported()||d;f=S(a,f,c);if(d){if(c.F++,f.F++,c.v)return c.v}else if(c.D++,f.D++,c.u)return c.u;!d&&C.RuntimePlatform.isBlobUrlSupported()?(c.l||(c.l=T(a,b,e,c).then(function(g){U(a,c,c.u);return{data:V(g),metadata:g.metadata}}).catch(function(g){W(a,c,c.u)&&(C.Logger.logWarning("Unexpected ResourceCache error"),X(a,c));throw g;})),b=c.l.then(function(g){U(a,c,c.u);c.C=a.h.createUrlForBlob(g.data);return{data:c.C,
metadata:g.metadata}}).catch(function(g){W(a,c,c.u)&&(C.Logger.logWarning("Unexpected ResourceCache error"),X(a,c));throw g;})):b=T(a,b,e,c).then(function(g){U(a,c,c.v);return{data:a.h.createUrl([g.data],g.metadata.mediaType||"",!0).url,metadata:g.metadata}}).catch(function(g){W(a,c,c.v)&&(C.Logger.logWarning("Unexpected ResourceCache error"),X(a,c));throw g;});d?c.v=b:c.u=b;return b}
function ma(a,b,e,d){if(a.i)return P();b=Q(a,b);var f=R(a,b,e);d=S(a,d,f);f.G++;d.G++;if(f.s)return f.s;f.s=T(a,b,e,f).then(function(c){U(a,f,f.s);var g=c.data;"string"!==typeof g&&(M||(M=new L),g=M.decode(g));return{data:g,metadata:c.metadata}}).catch(function(c){W(a,f,f.s)&&(C.Logger.logWarning("Unexpected ResourceCache error"),X(a,f));throw c;});return f.s}
function na(a,b,e,d){if(a.i)return P();b=Q(a,b);var f=R(a,b,e);d=S(a,d,f);f.A++;d.A++;if(f.o)return f.o;f.o=T(a,b,e,f).then(function(c){U(a,f,f.o);var g=c.data;return{data:"string"===typeof g?a.K.encode(g):g,metadata:c.metadata}}).catch(function(c){W(a,f,f.o)&&(C.Logger.logWarning("Unexpected ResourceCache error"),X(a,f));throw c;});return f.o}
function ja(a,b){var e=a.j.get(b);a.j.delete(b);e&&e.forEach(function(d,f){var c=a.g.get(f);c&&(c.A-=d.A,c.B-=d.B,c.D-=d.D,c.F-=d.F,c.G-=d.G,d=0,0===c.A&&(d++,c.o=null),0===c.D&&(d++,c.C&&(a.h.revokeUrl(c.C),c.C=null,c.u=null),0===c.B&&(d++,c.l=null)),0===c.F&&(d++,c.v=null),0===c.G&&(d++,c.s=null),5===d&&(c.m&&(c.m.abort(),c.m=null),a.g.delete(f)))})}O.prototype.destroy=function(){this.i||(this.i=!0,this.g.forEach(function(a){a.m&&(a.m.abort(),a.m=null)}),this.g.clear(),this.j.clear(),this.h.revokeAllUrls())};
O.prototype.getBackingResourceProvider=function(){return this.H};function V(a){var b=a.metadata.mediaType;return b?new Blob([a.data],{type:b}):new Blob([a.data])}function X(a,b){var e=b.J;b.m&&(b.m.abort(),b.m=null);a.g.get(e)===b&&(a.g.delete(e),b.C&&a.h.revokeUrl(b.C),a.j.forEach(function(d){d.delete(e)}))}
function T(a,b,e,d){return d.o?d.o:d.s?d.s:d.l&&d.L?d.l.then(function(f){return oa(f.data).then(function(c){return{data:new Uint8Array(c),metadata:f.metadata}})}):d.v?d.v.then(function(f){U(a,d);var c=f.data,g=c.indexOf(","),p=null;if(-1!==g){var pa=c.slice(0,g);p=decodeURIComponent(c.slice(g+1));pa.endsWith("base64")&&(p=C.Base64.decode(p))}return null===p?Y(a,b,e,d):{data:p,metadata:f.metadata}}):Y(a,b,e,d)}
function Y(a,b,e,d){var f,c;return ha(new fa(new da(function(g){if(1==g.j)return ca(g,a.H.fetch(b,{accept:e}));f=g.I;if(!W(a,d))throw f.abort(),new F(C.ColibrioErrorType.ABORTED);c=f.getMetadata();d.m=f;d.L=c;return g.return(f.asUint8Array().then(function(p){return{data:p,metadata:c}}).finally(function(){d.m=null}))})))}function S(a,b,e){var d=a.j.get(b);d||(d=new Map,a.j.set(b,d));a=d.get(e.J);a||(a={A:0,B:0,D:0,F:0,G:0},d.set(e.J,a));return a}
function P(){return Promise.reject(new F("RESOURCE_CACHE_HOST_DESTROYED"))}function R(a,b,e){b=D.URLUtils.getUrlWithoutHash(b);e&&0<e.length&&(b+="#"+e.join("&"));e=b;b=a.g.get(e);b||(b={A:0,o:null,m:null,l:null,B:0,C:null,D:0,u:null,J:e,F:0,v:null,L:null,G:0,s:null},a.g.set(e,b));return b}function W(a,b,e){return a.g.get(b.J)===b&&null!==e}function Q(a,b){"string"===typeof b&&(b=new URL(b,a.H.getBaseUrl()||void 0),D.URLUtils.normalizePathnamePercentEncoding(b));return b}
function U(a,b,e){if(!W(a,b,e))throw new F(C.ColibrioErrorType.ABORTED);};api={ResourceCacheClient:J,ResourceCacheHost:O,ResourceCacheError:F,ResourceCacheErrorType:{RESOURCE_CACHE_HOST_DESTROYED:"RESOURCE_CACHE_HOST_DESTROYED"}};var Z=new ArrayBuffer(8);new DataView(Z);new Uint8Array(Z); }).call(__self); return api; } ) );
