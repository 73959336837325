/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {VanillaVoidCallback} from '../VanillaReader/VanillaReaderModel';

/**
 * # VanillaReaderUiSyncMediaPlayer
 *
 * ## RESPONSIBILITIES
 *
 * ## RELATED TYPES
 * - VanillaReaderUiMenuMediaPlayerController
 */
export class VanillaReaderUiMenuMediaPlayer {
    private mediaPlayer: HTMLElement;
    private buttonPrevious: HTMLElement;
    private buttonNext: HTMLElement;
    private buttonPlay: HTMLElement;
    private buttonPause: HTMLElement;
    private hostDocument: Document;

    private _openedCallback: VanillaVoidCallback | undefined;
    private _closedCallback: VanillaVoidCallback | undefined;
    private _playCallback: VanillaVoidCallback | undefined;
    private _pauseCallback: VanillaVoidCallback | undefined;
    private _seekNextCallback: VanillaVoidCallback | undefined;
    private _seekPreviousCallback: VanillaVoidCallback | undefined;
    private _stateIsPlaying: boolean = false;

    private _style = `
        <style>
            #vanilla-reader__menu__mediaplayer {
                display: none;                
                min-height: 48px;
                justify-self: stretch;
                justify-content: center;                
                margin: 0.5em;
            }
            
             #vanilla-reader__menu__mediaplayer button {
                font-size: x-large;
                min-width: 48px;
                min-height: 48px;
                margin-top: 0;
                margin-bottom: 0;
                border: 1px solid var(--vanilla-reader__color__ui__outline-dark);
            }
 
            #vanilla-reader__menu__mediaplayer__button-pause {
                display: none;
            }
        </style>`;

    private _template = `
    <div id="vanilla-reader__menu__mediaplayer" title="Read aloud controls" tabindex="0">
        <button id="vanilla-reader__menu__mediaplayer__button-previous" aria-label="Skip back"><span aria-hidden="true">skip_previous</span></button>
        <button id="vanilla-reader__menu__mediaplayer__button-play" aria-label="Play"><span aria-hidden="true">play_arrow</span></button>
        <button id="vanilla-reader__menu__mediaplayer__button-pause" aria-label="Pause"><span aria-hidden="true"><span aria-hidden="true">pause</span></span></button>
        <button id="vanilla-reader__menu__mediaplayer__button-next" aria-label="Skip forward"><span aria-hidden="true">skip_next</span></button>
    </div>`;

    constructor(private _hostElement: HTMLElement, insertPosition: InsertPosition = 'afterbegin') {
        this._hostElement.insertAdjacentHTML(insertPosition, this._template);
        this.hostDocument = _hostElement.ownerDocument!;
        this.mediaPlayer = this.hostDocument.getElementById('vanilla-reader__menu__mediaplayer')!;
        this.mediaPlayer.insertAdjacentHTML('afterbegin', this._style);
        this.buttonPrevious = this.hostDocument.getElementById('vanilla-reader__menu__mediaplayer__button-previous')!;
        this.buttonNext = this.hostDocument.getElementById('vanilla-reader__menu__mediaplayer__button-next')!;
        this.buttonPlay = this.hostDocument.getElementById('vanilla-reader__menu__mediaplayer__button-play')!;
        this.buttonPause = this.hostDocument.getElementById('vanilla-reader__menu__mediaplayer__button-pause')!;

        // Start out disabled. Controls will be enabled in the `VanillaReaderUi._event_mediaPlayerCreated()`.
        this.disable();

        this._setup();
    }

    public set stateIsPlaying(playing: boolean) {
        this._stateIsPlaying = playing;
        if (playing) {
            this.buttonPlay.style.display = 'none';
            this.buttonPause.style.display = 'flex';
        } else {
            this.buttonPlay.style.display = 'flex';
            this.buttonPause.style.display = 'none';
        }
    }

    public get stateIsPlaying(): boolean {
        return this._stateIsPlaying;
    }

    onOpened(callback: VanillaVoidCallback) {
        this._openedCallback = callback;
    }

    onClosed(callback: VanillaVoidCallback) {
        this._closedCallback = callback;
    }

    onPlay(callback: VanillaVoidCallback) {
        this._playCallback = callback;
    }

    onPause(callback: VanillaVoidCallback) {
        this._pauseCallback = callback;
    }

    onSeekNext(callback: VanillaVoidCallback) {
        this._seekNextCallback = callback;
    }

    onSeekPrevious(callback: VanillaVoidCallback) {
        this._seekPreviousCallback = callback;
    }

    isOpen(): boolean {
        return this.mediaPlayer.style.display === 'flex';
    }

    disable() {
        this.buttonNext.setAttribute('disabled', 'disabled');
        this.buttonNext.setAttribute('aria-disabled', 'true');
        this.buttonPrevious.setAttribute('disabled', 'disabled');
        this.buttonPrevious.setAttribute('aria-disabled', 'true');
        this.buttonPause.setAttribute('disabled', 'disabled');
        this.buttonPause.setAttribute('aria-disabled', 'disabled');
        this.buttonPlay.setAttribute('disabled', 'disabled');
        this.buttonPlay.setAttribute('aria-disabled', 'disabled');
    }

    enable() {
        this.buttonNext.removeAttribute('disabled');
        this.buttonNext.removeAttribute('aria-disabled');
        this.buttonPrevious.removeAttribute('disabled');
        this.buttonPrevious.removeAttribute('aria-disabled');
        this.buttonPlay.removeAttribute('disabled');
        this.buttonPlay.removeAttribute('aria-disabled');
        this.buttonPause.removeAttribute('disabled');
        this.buttonPause.removeAttribute('aria- disabled');
    }

    open() {
        this.mediaPlayer.style.display = 'flex';

        if (this._openedCallback) {
            this._openedCallback();
        }
    }

    close() {
        this.mediaPlayer.style.display = 'none';

        this.buttonPlay.style.display = 'flex';
        this.buttonPause.style.display = 'none';

        if (this._closedCallback) {
            this._closedCallback();
        }
    }

    getComponentElement(): HTMLElement {
        return this.mediaPlayer;
    }

    /*
    * PRIVATE METHODS
    * */

    private _setup() {
        this.buttonPrevious.addEventListener('click', () => {
            if (this._seekPreviousCallback) {
                this._seekPreviousCallback();
            }
        });

        this.buttonNext.addEventListener('click', () => {
            if (this._seekNextCallback) {
                this._seekNextCallback();
            }
        });

        this.buttonPlay.addEventListener('click', () => {
            if (this._playCallback) {
                this._playCallback();
            }
            this.stateIsPlaying = true;
        });

        this.buttonPause.addEventListener('click', () => {
            if (this._pauseCallback) {
                this._pauseCallback();
            }

            this.stateIsPlaying = false;
        });

    }

}
