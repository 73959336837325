/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {VanillaReaderUiDialog} from './VanillaReaderUiDialog';

/**
 * # VanillaReaderUiImageViewerDialog
 *
 * ## RESPONSIBILITIES
 *
 * ## RELATED TYPES
 * -VanillaReaderUiDialogImageViewerController
 */
export class VanillaReaderUiDialogImageViewer extends VanillaReaderUiDialog<string> {
    private _image: HTMLImageElement;
    private _imageDescription: HTMLElement;

    private _bodyStyle = `
        <style>
            #vanilla-reader__dialog-image-viewer__image-container {
                margin: 0;
            }
            #vanilla-reader__dialog-image-viewer__image-container__image {
                width: 100%;
                height: auto;
            }       
        </style>`;

    private _bodyTemplate = `
            <figure id="vanilla-reader__dialog-image-viewer__image-container">
                <img id="vanilla-reader__dialog-image-viewer__image-container__image" src="">
                <label for="vanilla-reader__dialog-image-viewer__image-container__image-desc">Alternate description</label>
                <figcaption id="vanilla-reader__dialog-image-viewer__image-container__image-desc"></figcaption>
            </figure>`;

    constructor(hostElement: HTMLElement, insertPosition: InsertPosition = 'afterbegin') {
        super(hostElement, 'vanilla-reader__dialog-image-viewer', '', insertPosition);

        this._dialogElement.insertAdjacentHTML('afterbegin', this._bodyStyle);
        this._dialogBodyElement.innerHTML = this._bodyTemplate;
        this._image = this._hostDocument.getElementById('vanilla-reader__dialog-image-viewer__image-container__image')! as HTMLImageElement;
        this._imageDescription = this._hostDocument.getElementById('vanilla-reader__dialog-image-viewer__image-container__image-desc')! as HTMLElement;
    }

    loadImage(url: string, description: string | undefined) {
        if (url) {
            this._image.setAttribute('data-mediaobjecturl', url);
            this._image.src = url;

            this._imageDescription.innerText = description ? description : 'No alt text for this image';
        }
    }

    _getDialogResult<T>(): T {
        let mediaUrl = this._image.getAttribute('data-mediaobjecturl');

        // @ts-ignore
        return mediaUrl;
    }

}
