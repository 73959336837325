/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import { IAttributeData, IIntegerRange } from '@colibrio/colibrio-reader-framework/colibrio-core-base';
import { MediaType, MediaTypeCategory } from '@colibrio/colibrio-reader-framework/colibrio-core-io-base';
import { MediaTypeDetector } from '@colibrio/colibrio-reader-framework/colibrio-core-io-mediatypedetector';
import { ILocator } from '@colibrio/colibrio-reader-framework/colibrio-core-locator';
import { WpPublication } from '@colibrio/colibrio-reader-framework/colibrio-core-publication-wp';

import {
    IEngineEventMediaResourceData,
    IKeyboardEngineEvent,
    IMouseEngineEvent,
    INavigationEndedEngineEvent,
    INavigationIntentEngineEvent,
    IPointerEngineEvent,
    IReaderPublication,
    IReaderViewEngineEvent,
    IReaderViewGotoOptions,
    ISelectionChangedEngineEvent,
    ITransformData,
    ReaderViewGestureType,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';
import { IntRange } from '../utils/IntRange';
import { IVanillaReaderBookmarkDataStore } from '../VanillaReaderDataStore/IVanillaReaderBookmarkDataStore';
import { IVanillaReaderHighlightDataStore } from '../VanillaReaderDataStore/IVanillaReaderHighlightDataStore';
import { IVanillaReaderOptionsDataStore } from '../VanillaReaderDataStore/IVanillaReaderOptionsDataStore';
import { IVanillaReaderPublicationDataStore } from '../VanillaReaderDataStore/IVanillaReaderPublicationDataStore';
import {
    IVanillaReaderStreamedResourceStorage
} from '../VanillaReaderDataStore/VanillaReaderStreamedResourceIndexDbStorage';
import { VanillaReaderAudiobookPlayer } from './VanillaReaderAudiobookPlayer';

import {
    IVanillaReaderAppEvent_APP_LOADED,
    IVanillaReaderAppEvent_APP_ONLINE_STATE_CHANGED,
    IVanillaReaderAppEvent_APP_WINDOW_RESIZED,
    IVanillaReaderAppEvent_BOOKMARK_ADDED,
    IVanillaReaderAppEvent_BOOKMARK_DELETE_INTENT,
    IVanillaReaderAppEvent_BOOKMARK_DELETED,
    IVanillaReaderAppEvent_BOOKMARK_READINGPOSITION_INTENT,
    IVanillaReaderAppEvent_BOOKMARK_TEXTLOCATION_FAILED,
    IVanillaReaderAppEvent_BOOKMARK_TEXTLOCATION_INTENT,
    IVanillaReaderAppEvent_BOOKMARKS_LOADED,
    IVanillaReaderAppEvent_BOOKMARKS_UPDATED,
    IVanillaReaderAppEvent_CLICK,
    IVanillaReaderAppEvent_DOCUMENT_IS_AT_END,
    IVanillaReaderAppEvent_DOCUMENT_IS_AT_START,
    IVanillaReaderAppEvent_DOCUMENT_LANDMARKS_FETCH_INTENT,
    IVanillaReaderAppEvent_DOCUMENT_LANDMARKS_READY,
    IVanillaReaderAppEvent_FOCUS_ON_READINGPOSITION_INTENT,
    IVanillaReaderAppEvent_HIGHLIGHT_ADD_INTENT,
    IVanillaReaderAppEvent_HIGHLIGHT_ADDED,
    IVanillaReaderAppEvent_HIGHLIGHT_DELETE_INTENT,
    IVanillaReaderAppEvent_HIGHLIGHT_DELETED,
    IVanillaReaderAppEvent_HIGHLIGHT_TEXTLOCATION_FAILED,
    IVanillaReaderAppEvent_HIGHLIGHT_TEXTLOCATION_INTENT,
    IVanillaReaderAppEvent_HIGHLIGHT_UPDATED,
    IVanillaReaderAppEvent_HIGHLIGHTS_LOADED,
    IVanillaReaderAppEvent_HIGHLIGHTS_UPDATED,
    IVanillaReaderAppEvent_KEYBOARD_EVENT,
    IVanillaReaderAppEvent_MEDIA_OBJECT_CLICKED,
    IVanillaReaderAppEvent_MEDIA_OBJECT_VIEW_CLOSED,
    IVanillaReaderAppEvent_MEDIA_OBJECT_VIEW_INTENT,
    IVanillaReaderAppEvent_MEDIAPLAYER_ATTACHED,
    IVanillaReaderAppEvent_MEDIAPLAYER_CREATE_PROGRESS_UPDATED,
    IVanillaReaderAppEvent_MEDIAPLAYER_CREATED,
    IVanillaReaderAppEvent_MEDIAPLAYER_DETACHED,
    IVanillaReaderAppEvent_MEDIAPLAYER_PAUSE_INTENT,
    IVanillaReaderAppEvent_MEDIAPLAYER_PLAY_INTENT,
    IVanillaReaderAppEvent_MEDIAPLAYER_PLAYBACK_POSITION_CHANGED,
    IVanillaReaderAppEvent_MEDIAPLAYER_PLAYBACK_STATE_CHANGED,
    IVanillaReaderAppEvent_MEDIAPLAYER_RATE_CHANGE_INTENT,
    IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_COMPLETED,
    IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_NEXT_INTENT,
    IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_PREVIOUS_INTENT,
    IVanillaReaderAppEvent_MEDIAPLAYER_TOGGLE_INTENT,
    IVanillaReaderAppEvent_MEDIAPLAYER_VOICE_CHANGE_INTENT,
    IVanillaReaderAppEvent_MEDIAPLAYER_VOICE_CHANGED,
    IVanillaReaderAppEvent_MEDIAPLAYER_VOLUME_CHANGE_INTENT,
    IVanillaReaderAppEvent_NAVIGATION_COMPLETED,
    IVanillaReaderAppEvent_NAVIGATION_EXTERNAL_INTENT,
    IVanillaReaderAppEvent_NAVIGATION_INTENT,
    IVanillaReaderAppEvent_NAVIGATION_INTERNAL_INTENT,
    IVanillaReaderAppEvent_POINTER_DOWN,
    IVanillaReaderAppEvent_POINTER_UP,
    IVanillaReaderAppEvent_PUBLICATION_DELETE_STORED_RESOURCES_INTENT,
    IVanillaReaderAppEvent_PUBLICATION_DOWNLOAD_PROGRESS_UPDATED,
    IVanillaReaderAppEvent_PUBLICATION_IS_AT_END,
    IVanillaReaderAppEvent_PUBLICATION_IS_AT_START,
    IVanillaReaderAppEvent_PUBLICATION_LANDMARKS_FETCH_INTENT,
    IVanillaReaderAppEvent_PUBLICATION_LANDMARKS_READY,
    IVanillaReaderAppEvent_PUBLICATION_LOAD_FROM_STORE_INTENT,
    IVanillaReaderAppEvent_PUBLICATION_LOAD_INTENT,
    IVanillaReaderAppEvent_PUBLICATION_LOAD_URL_INTENT,
    IVanillaReaderAppEvent_PUBLICATION_LOADED,
    IVanillaReaderAppEvent_PUBLICATION_RENDERED,
    IVanillaReaderAppEvent_PUBLICATION_SEARCH_COMPLETED,
    IVanillaReaderAppEvent_PUBLICATION_SEARCH_INDEX_BUILD_PROGRESS,
    IVanillaReaderAppEvent_PUBLICATION_SEARCH_INDEX_READY,
    IVanillaReaderAppEvent_PUBLICATION_SEARCH_INTENT,
    IVanillaReaderAppEvent_PUBLICATION_STORED_RESOURCES_DELETED,
    IVanillaReaderAppEvent_PUBLICATION_STYLE_OPTIONS_CHANGE_INTENT,
    IVanillaReaderAppEvent_READING_POSITION_UPDATED,
    IVanillaReaderAppEvent_REDUCED_MOTION_OPTION_CHANGE_INTENT,
    IVanillaReaderAppEvent_REDUCED_MOTION_OPTION_CHANGED,
    IVanillaReaderAppEvent_RENDERER_ASPECT_RATIO_CHANGED,
    IVanillaReaderAppEvent_RENDERER_CHANGE_ASPECT_RATIO_INTENT,
    IVanillaReaderAppEvent_RENDERER_CHANGE_INTENT,
    IVanillaReaderAppEvent_RENDERER_CHANGED,
    IVanillaReaderAppEvent_TEXT_SELECTED,
    IVanillaReaderAppEvent_TIMELINE_READY,
    IVanillaReaderAppEvent_TIMELINE_UPDATE_INTENT,
    IVanillaReaderAppEvent_VIEW_REFRESH_INTENT,
    IVanillaReaderAppEvent_VIEW_TRANSFORM_CHANGED,
    IVanillaReaderAppEvent_VIEW_TRANSFORM_RESET_INTENT,
    IVanillaReaderAppEvent_VIEW_TRANSFORM_PAN_MODE_ACTIVATE_INTENT,
    IVanillaReaderAppEvent_VIEW_TRANSFORM_PAN_MODE_ACTIVATED,
    IVanillaReaderAppEvent_VIEW_TRANSFORM_PAN_MODE_DEACTIVATE_INTENT,
    IVanillaReaderAppEvent_VIEW_TRANSFORM_PAN_MODE_DEACTIVATED,
    IVanillaReaderAppEvent_VIEW_TRANSFORM_ZOOM_TO_LEVEL_INTENT,
    IVanillaReaderAppEvent_VIEW_TRANSFORM_ZOOM_TO_RECT_INTENT,
    IVanillaReaderAppEvent_VIEW_TRANSFORM_ZOOMED,
    IVanillaReaderAppEvent_VIEW_TRANSITION_ENDED,
    IVanillaReaderAppEvent_VIEW_TRANSITION_STARTED,
    IVanillaReaderAppEvent_VISIBLE_RANGE_DATA_CHANGED,
    VanillaReaderAppEvents,
    VanillaReaderEventBus,
    IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_BACKWARD_INTENT,
    IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_FORWARD_INTENT,
} from './VanillaReaderEventBus';
import { VanillaReaderMediaPlayer } from './VanillaReaderMediaPlayer';

import {
    IVanillaReaderBookmarkData,
    IVanillaReaderHighlightData,
    IVanillaReaderInitialOptions,
    IVanillaReaderOptionsData,
    IVanillaReaderPublicationSearchResultItem,
    IVanillaReaderReadingProgressionTimeline,
    IVanillaReaderSyncMediaOptions,
    IVanillaReaderUserProfileData,
    IVanillaSyncMediaPlaybackStateData,
    VanillaReaderNavigationType,
    VanillaReaderRendererNames,
} from './VanillaReaderModel';
import { VanillaReaderProgressionTimeline } from './VanillaReaderProgressionTimeline';
import { VanillaReaderPublication } from './VanillaReaderPublication';
import { VanillaReaderReadingSystem } from './VanillaReaderReadingSystem';
import { VanillaReaderView } from './VanillaReaderView';
import { VanillaReaderPublicationSearch } from "./VanillaReaderPublicationSearch";
import { IVanillaReaderPublicationSearch } from "./IVanillaReaderPublicationSearch";
import { VanillaReaderPublicationLandmarkCollection } from "./VanillaReaderPublicationLandmarkCollection";
import { APIRoutes } from '../Reader/APIRoutes';


/**
 *
 * # VanillaReader
 *
 *  ## RESPONSIBILITIES
 *
 * VanillaReader is where all the fun Colibrio Reader Framework action takes place!
 *
 * This class has no UI related code, if you are looking for such things they are in the
 * `VanillaReaderUI` module in this project.
 *
 * ## NAMING CONVENTIONS
 *
 * All classes and other types that are specific to the Imbiblio Reader application are prefixed with "VanillaReader".
 * Instances of Colibrio Framework classes are prefixed with "_colibrio". This will make it easier for you to see
 * the boundaries between app code and framework types.
 *
 *
 * ## EVENT SYSTEM
 *
 * To keep a clear separation between the application logic in VanillaReader.ts and the UI logic in the `VanillaReaderUI`
 * all communication between these two modules are done using the `VanillaReaderEventBus`.
 *
 * When the UI has something it wants the VanillaReader (and the Colibrio Reader Framework) to know, it dispatches
 * an intent as a CustomEvent using the event bus. Likewise, when the `VanillaReader` has updated its state, it will dispatch
 * an event that the application state has changed and sends along the updated data as payload.
 *
 * The general convention is that the VanillaReaderUI sends "intentions" for the VanillaReader to performs actions,
 * and that the VanillaReader responds with updated data.
 *
 *      |-----------------------|                   |-----------------------|
 *      |                       |   -- intent ->    |                       |
 *      |   VanillaReaderUI     |                   |     VanillaReader     |
 *      |                       |   <- update --    |                       |
 *      |-----------------------|                   |-----------------------|
 *
 * All events have their own types. All the event names, and the type interfaces, are found in the `VanillaReaderEventBus.ts`
 * file. I recommend that you have a look at the `VanillaReaderAppEvents` enum to get an overview of the behaviour of
 * the application.
 *
 *
 * ## STATE MANAGEMENT
 *
 * ### Colibrio runtime state
 * In the Colibrio Reader Framework the different components, such as ReaderViews and ContentPositionTimeline, manage
 * their own state. Almost all data types in the Colibrio Reader Framework are serializable to JSON. This means that it
 * is easy to serialize runtime state, and also to restore it.
 *
 * ### Imbiblio Reader state
 * The Imbiblio Reader application state, with data about user customization options, bookmarks etc. is persisted to
 * various data stores such as the `VanillaReaderOptionsIndexedDbDataStore` and the `VanillaReaderBookmarkIndexedDbDataStore`.
 * All data store types and their implementations can be found in the `src/VanillaDataStore/` folder.
 *
 *
 * ## MULTI FORMAT SUPPORT
 *
 * Imbiblio Reader supports both traditional text based publications as well audiobooks. This implementation strategy is
 * mainly chosen in order to make the Imbiblio Reader your one-stop-shop to check out all basic Colibrio Framework
 * features. In a real world application you should probably separate the audiobook implementation more clearly from the
 * EPUB and PDF implementation.
 *
 * The main difference between traditional ebooks and audiobooks is of course that the audiobook does not necessarily
 * render anything to the screen, and therefore do not have a `IReaderView` instance.
 * Another difference is that the audiobooks do not have a `ContentPositionTimeline`, but relies instead on the
 * `ISyncMediaTimeline` (the same type that is used by the TTS and Media Overlay features).
 *
 * To make things easier for the UI to work across the different formats without too many additional forks in its code,
 * the differences between the `ContentPositionTimeline` and the `ISyncMediaTimeline` have been "hidden" behind the
 * `IVanillaReaderReadingProgressionTimeline` interface. Check out the `_createReadingProgressionTimeline` method for
 * more details.
 *
 * To see where the code paths differ between ebooks and audiobooks start in the `_onAfterPublicationLoaded` method.
 * Additionally, you can search for usages of the `vanillaPublication.isAudiobook` property, and also the "type predicate"
 * `_isWpAudiobookReaderPublication`.
 *
 *
 */

export class VanillaReader {
    vanillaReaderReadingSystem: VanillaReaderReadingSystem;
    vanillaReadingProgressionTimeLine: IVanillaReaderReadingProgressionTimeline | undefined = undefined;
    vanillaMediaPlayer: VanillaReaderMediaPlayer | undefined = undefined;
    vanillaAudiobookPlayer: VanillaReaderAudiobookPlayer | undefined = undefined;
    bookmarkStore: IVanillaReaderBookmarkDataStore;
    highlightStore: IVanillaReaderHighlightDataStore;
    publicationDataStore: IVanillaReaderPublicationDataStore;
    optionsDataStore: IVanillaReaderOptionsDataStore;
    vanillaPublication: VanillaReaderPublication | undefined;
    vanillaPublicationSearch: IVanillaReaderPublicationSearch | undefined;

    vanillaReaderView: VanillaReaderView | undefined;

    private _colibrioReaderPublication: IReaderPublication | undefined = undefined;

    // @ts-ignore
    private _userHasSlowNetwork: boolean = false;
    // @ts-ignore
    private _useReducedMotion: boolean = false;
    private _isOffline: boolean = false;
    private _shouldFocusOnReadingPosition: boolean = true;

    constructor(
        private _appElement: HTMLElement,

        // This is the license key that has been provided to you by Colibrio.
        licenseApiKey: string,

        // User profile data is used to tag reading sessions that are sent to the Colibrio License Server with a unique token.
        // See `fetchOrCreateUserProfile()` in index.ts for our sample implementation.
        private _userProfileData: IVanillaReaderUserProfileData,

        // Initial configuration options retrieved by the calling context (index.ts)
        private _initialOptions: IVanillaReaderInitialOptions,

        // If the users has installed the app as a PWA this storage client takes care of caching of the publication file
        // data.
        private _offlinePublicationDataStorageClient: IVanillaReaderStreamedResourceStorage | undefined = undefined,

        bookmarkStore: IVanillaReaderBookmarkDataStore,
        highlightStore: IVanillaReaderHighlightDataStore,
        publicationDataStore: IVanillaReaderPublicationDataStore,
        optionsDataStore: IVanillaReaderOptionsDataStore,
    ) {
        this.optionsDataStore = optionsDataStore;
        this.bookmarkStore = bookmarkStore;
        this.highlightStore = highlightStore;
        this.publicationDataStore = publicationDataStore;

        this.vanillaReaderReadingSystem = new VanillaReaderReadingSystem(licenseApiKey, this._userProfileData, this._offlinePublicationDataStorageClient);

        this._setUpEventBusEventHandlers();
        this._setUpComponentCallbacks();

        // This option is used in the Imbiblio Reader to change behavior to better work when bandwidth is limited.
        this._userHasSlowNetwork = this._initialOptions?.slowNetwork ? true : false;

        // This option disables or reduces animations in the renderers if set to true
        this._useReducedMotion = this._initialOptions?.viewOptions?.useReducedMotion || false;

        // Let's report to all that are listening that we are all set to open books!
        VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_APP_LOADED>(VanillaReaderAppEvents.APP_LOADED, {
            detail: {
                initialOptions: this._initialOptions,
            },
        }));

        /**
         *
         * What happens now?
         *
         * Now the ReaderSystemEngine is up and running, but we still have not loaded any publication. That happens in
         * one of the following methods:
         *
         * - loadPublicationFromUrl()
         * - loadPublicationFromStore()
         * - loadPublicationFromFile()
         *
         * These methods are called when the UI sends one of the corresponding events:
         *
         * - IVanillaReaderAppEvent_PUBLICATION_LOAD_URL_INTENT
         * - IVanillaReaderAppEvent_PUBLICATION_LOAD_FROM_STORE_INTENT
         * - IVanillaReaderAppEvent_PUBLICATION_LOAD_INTENT
         *
         * Once the publication has started loading by the `VanillaReaderReadingSystem` (which in turn of course uses the
         * Colibrio Reader Framework `ReadingSystemEngine`) things continue in `_onAfterPublicationLoaded`. Go check it out!
         *
         * */

    }

    /*
    *
    * This method serializes all configuration options to be persisted between application sessions.
    *
    * */
    async serializeToOptionsData(): Promise<IVanillaReaderOptionsData> {
        let options: IVanillaReaderOptionsData = {
            dateCreated: Date.now(),
            viewOptions: undefined,
            syncMediaOptions: undefined
        };

        options.viewOptions = this.vanillaReaderView?.getCurrentVanillaReaderViewOptionsState();

        return options;
    }

    /**
     *
     * This property turns on/off the accessibility feature that helps a Screen Reader focus on the current reading
     * position. Focusing is done, as the name suggests, by calling `HTMLElement.focus()` near the reading position.
     * We recommend that you turn this on as a default.
     *
     * NOTE:
     *
     * A consequence of setting this to true is that a focus marker will be rendered in many books, so you might want to
     * have an option to toggle this in the UI.
     *
     * */
    public get shouldFocusOnReadingPosition(): boolean {
        return this._shouldFocusOnReadingPosition;
    }

    public set shouldFocusOnReadingPosition(value: boolean) {
        this._shouldFocusOnReadingPosition = value;
    }

    /*
    *
    * It is very important for the whole application to be aware of any changes to internet connectivity. This property
    * is set by the calling context (index.ts in our case) to signal this. The method itself determines if the new state
    * really is different from the current state, and if it is an app wide event is sent.
    *
    */
    public set isOffline(state: boolean) {

        if (this._isOffline !== state) {
            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_APP_ONLINE_STATE_CHANGED>(new CustomEvent<IVanillaReaderAppEvent_APP_ONLINE_STATE_CHANGED>(VanillaReaderAppEvents.APP_ONLINE_STATE_CHANGED, { detail: { isOnline: !state } }));
        }
        this._isOffline = state;
    }

    public get isOffline(): boolean {
        return this._isOffline;
    }

    /**
     *
     * This method takes in a URL as a string and tries to load a publication from it. "Under the hood" it uses the
     * Colibrio `ReadingSystemEngine`, so have a peek at the `VanillaReaderReadingSystem.loadPublicationFromUrl()`
     *
     * @param publicationUrl - A string URL that points to a publication resource file
     * @param size - Optional. The byte size of publication resource. This is only necessary if you can not make HEAD requests to your file server.
     * @param initialLocator - Optional. A publication location to navigate to after the publication has been loaded.
     * @param storeOnDevice - Optional. Should the `IRandomAccessDataSource` instance used when loading the publication use IndexedDB for long term caching?
     * @return void
     * */
    async loadPublicationFromUrl(
        publicationUrl: string,
        size?: number | undefined,
        initialLocator?: string | undefined,
        storeOnDevice: boolean = false,
    ) {
        this._colibrioReaderPublication = await this.vanillaReaderReadingSystem.loadPublicationFromUrl(publicationUrl, size, storeOnDevice);
        let pathName = new URL(publicationUrl).pathname;
        let fileName = pathName.slice(pathName.lastIndexOf('/') + 1);

        await this._onAfterPublicationLoaded(fileName, publicationUrl, this._colibrioReaderPublication, initialLocator);
    }

    /**
     *
     * This method takes in a file name and a source URI to identify the origin of the file and tries to load the publication
     * from using the `IVanillaReaderStreamedResourceStorage` storage client. It uses two different methods to load data,
     * for `https:` URIs it uses `VanillaReaderReadingSystem.loadPublicationFromUrl()` and for `file:` based URIs it uses
     * `VanillaReaderReadingSystem.loadPublicationFromArrayBuffer()`
     *
     * @param fileName - A publication resource file name as a string
     * @param fileSourceUri - The URI used to load the publication as a string
     * @param initialLocator - Optional. A publication location to navigate to after the publication has been loaded.
     * @param storeOnDevice - Optional. Should the `IRandomAccessDataSource` instance used when loading the publication use IndexedDB for long term caching?
     * @return void
     * */
    async loadPublicationFromStore(
        fileName: string,
        fileSourceUri: string,
        initialLocator?: string | undefined,
        storeOnDevice: boolean = false,
    ) {
        if (this._offlinePublicationDataStorageClient && this._offlinePublicationDataStorageClient.hasResource(fileSourceUri)) {

            let fileData = await this._offlinePublicationDataStorageClient.getResource(fileSourceUri);

            if (fileData) {
                // If the fileSourceUri parameter is provided this is a URL
                if (fileSourceUri?.startsWith('https://')) {
                    return this.loadPublicationFromUrl(fileSourceUri, fileData.size, initialLocator, storeOnDevice);
                } else if ((fileSourceUri?.startsWith('file://'))) {
                    // According to `fileSourceUri` string, this is a file resource. Files opened from the file system
                    // are stored as one single ArrayBuffer.
                    let fileBuffer = await this._offlinePublicationDataStorageClient?.getResourceDataByStartOffset(fileSourceUri, 0);
                    if (fileBuffer) {
                        this._colibrioReaderPublication = await this.vanillaReaderReadingSystem.loadPublicationFromArrayBuffer(fileBuffer.data, fileName, storeOnDevice);

                        await this._onAfterPublicationLoaded(fileName, fileSourceUri, this._colibrioReaderPublication, initialLocator);
                    } else {
                        console.warn('VanillaReader.loadPublicationFromStore(): Unable to get ArrayBuffer data for ' + fileData.name);
                    }

                } else {
                    console.warn('VanillaReader.loadPublicationFromStore(): The fileSourceUri does not have a valid protocol (https:// or file://). ' + fileSourceUri);
                }

            } else {
                console.warn('VanillaReader.loadPublicationFromStore(): Unable to get stored publication with file name ' + fileName);
            }

        }
    }

    /**
     *
     * This method takes in a File object and tries to load a publication from it. "Under the hood" it uses the
     * Colibrio `ReadingSystemEngine`, so have a peek at the `VanillaReaderReadingSystem.loadPublicationFromFile()`
     *
     * @param file - A File object to the publication resource.
     * @param initialLocator - Optional. A publication location to navigate to after the publication has been loaded.
     * @param storeOnDevice - Optional. Should the loaded publication data be cached on the device for offline access?.
     * @return void
     * */
    async loadPublicationFromFile(
        file: File,
        initialLocator?: string | undefined,
        storeOnDevice: boolean = false,
    ) {
        this._colibrioReaderPublication = await this.vanillaReaderReadingSystem.loadPublicationFromFile(file, storeOnDevice);

        // Since files loaded from disc do not have a file path, with need to create a value for the
        // file `fileSourceUri` argument. This should perhaps be done in the `VanillaReaderReadingSystem`
        // class instead.
        let fileUri = 'file://' + file.name;

        await this._onAfterPublicationLoaded(file.name, fileUri, this._colibrioReaderPublication, initialLocator);
    }

    /**
     *
     * Navigates to the position described in the locator.
     *
     * @param locator - The locator to navigate to as an `ILocator` or as a string
     * @param options - The `IReaderViewGotoOptions.setReadingPositionToVisibleRangeStart` option "snaps" the reading
     * position to the start of the visible content if set to `true`. Default is `false` meaning that the reading position
     * will be at the precise position described in the locator.
     *
     * */
    async goTo(locator: ILocator | string, options?: IReaderViewGotoOptions) {

        if (this.vanillaPublication) {

            if (this.vanillaReaderView) {

                await this.vanillaReaderView.goTo(locator, options);

            } else if (this.vanillaPublication?.isAudiobook) {
                let location = await this.vanillaPublication.getColibrioReaderPublication().fetchContentLocation(locator);
                this.vanillaAudiobookPlayer?.seekToLocator(location.getLocator());
            }

        }
    }

    async goToStart() {

        if (!this.vanillaPublication?.isAudiobook && this.vanillaReaderView) {
            if (!this.vanillaReaderView.isAtStart()) {
                await this.vanillaReaderView.goToStart();

            }
        } else {
            this.vanillaAudiobookPlayer?.seekToApproximateTimeMs(0);
        }

    }

    async next() {

        if (this.vanillaPublication?.isAudiobook) {
            await this.vanillaAudiobookPlayer?.seekToNextSegment();
        } else if (this.vanillaReaderView && this.vanillaReaderView.canPerformNext()) {

            this.vanillaReaderView.next().catch(console.error)

        }
    }

    async previous() {

        if (this.vanillaPublication?.isAudiobook) {
            await this.vanillaAudiobookPlayer?.seekToPreviousSegment();
        } else if (this.vanillaReaderView && this.vanillaReaderView.canPerformPrevious()) {

            this.vanillaReaderView.previous().catch(console.error)

        }
    }

    /**
     *
     * This method navigates to a location in the book based on a given position off the readingProgressionTimeline.
     * The behavior differs a bit depending on the book format. If it's an EPUB or PDF we first need to resolve the
     * timeline position to a `IContentLocation` that we can then use as a parameter when calling `goTo`.
     * If it is an Audiobook things are much simpler. Since the timeline represents the duration of the audiobook in
     * milliseconds, we can just seek directly to that position in the book.
     *
     * */
    async gotoTimelinePosition(position: number) {
        if (this.vanillaReadingProgressionTimeLine) {
            if (!this.vanillaPublication?.isAudiobook) {
                let contentLocation = await this.vanillaReadingProgressionTimeLine.fetchContentLocation(position);
                if (contentLocation) {
                    await this.goTo(contentLocation.getLocator(), { setReadingPositionToVisibleRangeStart: true });
                }
            } else {
                this.vanillaAudiobookPlayer?.seekToApproximateTimeMs(position);
            }

        }
    }

    /**
     *
     * This method gets the timeline position as a number. If the loaded `IReaderPublication` is an Audiobook the number
     * represents the progression in ms. If the `IReaderPublication` is a reflowable EPUB the returned number represents
     * the index of the word at the current position. For PDF and Fixed Layout EPUBs the number represents the current
     * page index.
     *
     * */
    async getTimelinePosition(): Promise<number | undefined> {

        if (this.vanillaReadingProgressionTimeLine) {

            if (!this.vanillaPublication?.isAudiobook && this.vanillaReaderView) {
                let readingPosition = this.vanillaReaderView.getReadingPosition()?.collapseToStart().getLocator();
                if (readingPosition) {
                    return this.vanillaReadingProgressionTimeLine.fetchTimelinePosition(readingPosition);
                }
            } else {
                return this.vanillaAudiobookPlayer?.getApproximateElapsedTimeMs();
            }

        }

        return undefined;
    }

    /**
     *
     * This method searches the entire publication, or a subset of documents, for the provided search string.
     * In order to be able to perform a search, the search index must first be built. This is done adhoc in the
     * `VanillaReaderPublication.search()` method.
     *
     * */
    async searchPublication(
        searchString: string,
        resultSetMaxSize: number = 50,
        documentIndexes?: number[],
        highlightResults: boolean = true
    ): Promise<IVanillaReaderPublicationSearchResultItem[]> {
        if (!this.vanillaPublication) {
            return [];
        }
        if (!highlightResults) {
            return this.vanillaPublicationSearch?.search(searchString, resultSetMaxSize, 50, documentIndexes) || [];
        } else {
            return this.vanillaPublicationSearch?.searchAndHighlight(searchString, resultSetMaxSize, 50, documentIndexes) || [];
        }
    }
    searchPublicationCompleted(resultSet: IVanillaReaderPublicationSearchResultItem[]) {
        VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_PUBLICATION_SEARCH_COMPLETED>(VanillaReaderAppEvents.PUBLICATION_SEARCH_COMPLETED, { detail: { resultSet } }));
    }

    clearPublicationSearchLayer() {
        this.vanillaPublicationSearch?.clearSearch();
        VanillaReaderEventBus.dispatchEvent(new CustomEvent(VanillaReaderAppEvents.PUBLICATION_SEARCH_CLEARED));
    }

    /**
     * This method is a quirkfix to let Screen Reader users (primarily NVDA and JAWS) highlight text based on the contents
     * of their clipboard.
     *
     * The reason that we need this is because of the Screen Readers inability to scroll the document as it moves through
     * the document contents. This is a problem for us since we use the visible range as a means to know where the user
     * is reading, so without a change in the visible range we can't can add a correct highlight or bookmark.
     *
     * The way that the Screen Reader user will use this feature is as follows:
     *
     * 1. The user selects and copies the text that represents the location they want highlight or bookmark.
     * 2. The user uses some method to trigger a UI call to this method, sending along the text that is currently
     * in the user's clipboard as well as the document index in the publication spine.
     * 3. This method uses the publication search to grab the first occurrence of the string in the specified document.
     * 4. The result is communicated back to the user using the `HIGHLIGHT_ADDED` / `BOOKMARK_ADDED` or
     * `HIGHLIGHT_TEXTLOCATION_FAILED` / `BOOKMARK_TEXTLOCATION_FAILED` events.
     *
     * */

    async bookmarkTextLocation(
        textToBookmark: string,
        documentIndex: number,
    ): Promise<void> {
        this.searchPublication(textToBookmark, 1, [documentIndex], false).then(async (resultSet) => {
            if (resultSet.length > 0) {
                let bookmarkData: IVanillaReaderBookmarkData | undefined = await this.vanillaPublication?.addBookmark(resultSet[0].locator);
                if (!bookmarkData) {
                    VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_BOOKMARK_TEXTLOCATION_FAILED>(new CustomEvent<IVanillaReaderAppEvent_BOOKMARK_TEXTLOCATION_FAILED>(VanillaReaderAppEvents.BOOKMARK_TEXTLOCATION_FAILED, {
                        detail: {
                            text: textToBookmark,
                            documentIndex,
                        },
                    }));
                }
            } else {
                VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_BOOKMARK_TEXTLOCATION_FAILED>(new CustomEvent<IVanillaReaderAppEvent_BOOKMARK_TEXTLOCATION_FAILED>(VanillaReaderAppEvents.BOOKMARK_TEXTLOCATION_FAILED, {
                    detail: {
                        text: textToBookmark,
                        documentIndex,
                    },
                }));

            }
        }).catch(console.error);

        return;
    }

    // Bookmarks the `ContentLocation` returned by the `ReaderView`.
    async bookmarkReadingPosition(): Promise<IVanillaReaderBookmarkData | undefined> {
        let readingPositionLocator = this.vanillaReaderView?.getReadingPosition()?.getLocator();
        if (!readingPositionLocator) {
            console.warn(`VanillaReader.bookmarkReadingPosition(): Unable to get reading position from VanillaReaderView.`);
            return;
        }
        return this.vanillaPublication?.addBookmark(readingPositionLocator);
    }

    async addHighlight(highlightData: IVanillaReaderHighlightData): Promise<IVanillaReaderHighlightData | void> {
        return this.vanillaPublication?.addHighlight(highlightData);
    }

    /**
     * This method is a quirkfix to let Screen Reader users (primarily NVDA and JAWS) highlight text based on the contents
     * of their clipboard.
     *
     * The reason that we need this is because of the Screen Readers inability to scroll the document as it moves through
     * the document contents. This is a problem for us since we use the visible range as a means to know where the user
     * is reading, so without a change in the visible range we can't can add a correct highlight or bookmark.
     *
     * The way that the Screen Reader user will use this feature is as follows:
     *
     * 1. The user selects and copies the text that represents the location they want highlight or bookmark.
     * 2. The user uses some method to trigger a UI call to this method, sending along the text that is currently
     * in the user's clipboard as well as the document index in the publication spine.
     * 3. This method uses the publication search to grab the first occurrence of the string in the specified document.
     * 4. The result is communicated back to the user using the `HIGHLIGHT_ADDED` / `BOOKMARK_ADDED` or
     * `HIGHLIGHT_TEXTLOCATION_FAILED` / `BOOKMARK_TEXTLOCATION_FAILED` events.
     *
     * */
    highlightTextLocation(
        textToHighlight: string,
        documentIndex: number,
        sourceHighlightData: IVanillaReaderHighlightData,
    ) {
        this.searchPublication(textToHighlight, 1, [documentIndex], false).then((resultSet) => {
            if (resultSet.length > 0) {
                if (resultSet[0].locator) {
                    sourceHighlightData.locator = resultSet[0].locator;
                    this.addHighlight(sourceHighlightData).then((highlightData) => {
                        if (highlightData) {
                            this.highlightStore?.addHighlight(highlightData).catch(console.warn);
                        }
                    }).catch(console.warn);
                }
            } else {
                VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_HIGHLIGHT_TEXTLOCATION_FAILED>(new CustomEvent<IVanillaReaderAppEvent_HIGHLIGHT_TEXTLOCATION_FAILED>(VanillaReaderAppEvents.HIGHLIGHT_TEXTLOCATION_FAILED, {
                    detail: {
                        text: textToHighlight,
                        documentIndex,
                        highlightData: sourceHighlightData,
                    },
                }));
            }
        }).catch(console.error);
    }

    /**
     *
     * This method helps a Screen Reader focus on the current reading position by calling `HTMLElement.focus()` as close
     * to the current reading position as possible.
     *
     * */
    async focusOnReadingPosition(): Promise<void> {
        if (this.vanillaReaderView) {
            return this.vanillaReaderView.focusOnReadingPosition({
                focusOnPageContainer: false,
                focusOnPageBodyElement: false,
                focusNearContentLocation: true,
            }).catch(console.error);
        }
    }

    /*
    *
    * PRIVATE METHODS
    *
    * */

    /*
    *
    * You can't have an event driven architecture without events right? Let's add event listeners.
    * Almost all events are intents sent from the `VanillaReaderUI` to the `VanillaReader` as it wants to perform
    * actions, such as navigations etc.
    * */
    private _setUpEventBusEventHandlers() {
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.APP_ONLINE_STATE_CHANGED, this._event_appOnlineStateChanged);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.TIMELINE_READY, this._event_timelineReady);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.MEDIA_OBJECT_CLICKED, this._event_mediaObjectClicked);

        /*
        * All the following events are dispatched by the `VanillaReaderUI` component. You can basically see this as a list
        * of all the main features that are offered by the reading app.
        * */
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.APP_WINDOW_RESIZED, this._uiEvent_appWindowResized);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.BOOKMARK_READINGPOSITION_INTENT, this._uiEvent_bookmarkReadingPositionIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.BOOKMARK_TEXTLOCATION_INTENT, this._uiEvent_bookmarkTextLocationIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.BOOKMARK_DELETE_INTENT, this._uiEvent_bookmarkDeleteIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.DOCUMENT_LANDMARKS_FETCH_INTENT, this._uiEvent_documentLandmarksFetchIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.FOCUS_ON_READINGPOSTION_INTENT, this._uiEvent_focusOnReadingPosition);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.HIGHLIGHT_TEXTLOCATION_INTENT, this._uiEvent_highlightTextLocationIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.HIGHLIGHT_ADD_INTENT, this._uiEvent_highlightAddIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.HIGHLIGHT_DELETE_INTENT, this._uiEvent_highlightDeleteIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.HIGHLIGHT_UPDATE_INTENT, this._uiEvent_highlightUpdateIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.MEDIA_OBJECT_VIEW_CLOSED, this._uiEvent_mediaObjectViewClosed);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.NAVIGATION_INTENT, this._uiEvent_navigationIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.PUBLICATION_LANDMARKS_FETCH_INTENT, this._uiEvent_publicationLandmarksFetchIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.PUBLICATION_LOAD_INTENT, this._uiEvent_publicationLoadIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.PUBLICATION_LOAD_URL_INTENT, this._uiEvent_publicationLoadUrlIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.PUBLICATION_LOAD_FROM_STORE_INTENT, this._uiEvent_publicationLoadFromStoreIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.PUBLICATION_STYLE_OPTIONS_CHANGE_INTENT, this._uiEvent_publicationStyleOptionsChangeIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.PUBLICATION_SEARCH_INTENT, this._uiEvent_publicationSearchIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.PUBLICATION_SEARCH_CLEAR_INTENT, this._uiEvent_publicationSearchClearIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.PUBLICATION_DELETE_STORED_RESOURCES_INTENT, this._uiEvent_publicationDeleteCacheIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.REDUCED_MOTION_OPTION_CHANGE_INTENT, this._uiEvent_reducedMotionOptionChangeIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.RENDERER_CHANGE_INTENT, this._uiEvent_rendererChangeIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.RENDERER_CHANGE_ASPECT_RATIO_INTENT, this._uiEvent_rendererChangeAspectRatio);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.MEDIAPLAYER_TOGGLE_INTENT, this._uiEvent_mediaPlayerToggleIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.VIEW_REFRESH_INTENT, this._uiEvent_viewRefreshIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.MEDIAPLAYER_SEEK_PREVIOUS_INTENT, this._uiEvent_mediaPlayerSeekPreviousIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.MEDIAPLAYER_SEEK_BACKWARD_INTENT, this._uiEvent_mediaPlayerSeekBackwardIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.MEDIAPLAYER_SEEK_FORWARD_INTENT, this._uiEvent_mediaPlayerSeekForwardIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.MEDIAPLAYER_SEEK_NEXT_INTENT, this._uiEvent_mediaPlayerSeekNextIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.MEDIAPLAYER_PLAY_INTENT, this._uiEvent_mediaPlayerPlayIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.MEDIAPLAYER_PAUSE_INTENT, this._uiEvent_mediaPlayerPauseIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.MEDIAPLAYER_VOLUME_CHANGE_INTENT, this._uiEvent_mediaPlayerVolumeChangeIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.MEDIAPLAYER_RATE_CHANGE_INTENT, this._uiEvent_mediaPlayerRateChangeIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.MEDIAPLAYER_VOICE_CHANGE_INTENT, this._uiEvent_mediaPlayerVoiceChangeIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.VIEW_TRANSFORM_STATE_RESET_INTENT, this._uiEvent_viewTransformResetIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.VIEW_TRANSFORM_PAN_MODE_ACTIVATE_INTENT, this._uiEvent_viewTransformPanModeActivateIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.VIEW_TRANSFORM_PAN_MODE_DEACTIVATE_INTENT, this._uiEvent_viewTransformPanModeDeactivateIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.VIEW_TRANSFORM_ZOOM_TO_RECT_INTENT, this._uiEvent_viewTransformZoomToRectIntent);
        VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.VIEW_TRANSFORM_ZOOM_TO_LEVEL_INTENT, this._uiEvent_viewTransformZoomToLevelIntent);
    }

    /*
    *
    * Whereas events are used for module to module communication, callbacks are used  within the `VanillaReader` module to
    * act on "child component" events.
    *
    * */
    private _setUpComponentCallbacks() {

        /**
         * READING SYSTEM CALLBACKS
         * */

        this.vanillaReaderReadingSystem.onPublicationRendered(() => {
            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_PUBLICATION_RENDERED>(new CustomEvent<IVanillaReaderAppEvent_PUBLICATION_RENDERED>(VanillaReaderAppEvents.PUBLICATION_RENDERED));
        });

        this.vanillaReaderReadingSystem.onReadingPositionChanged((ev: IReaderViewEngineEvent) => {
            this._event_readingPositionChanged(ev).catch(console.warn);
        });

        this.vanillaReaderReadingSystem.onNavigationEnded((ev: INavigationEndedEngineEvent) => {
            VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_NAVIGATION_COMPLETED>(VanillaReaderAppEvents.NAVIGATION_COMPLETED, {
                detail: {
                    srcEventData: ev.toJSON(),
                },
            }));
        });

        this.vanillaReaderReadingSystem.onNavigationIntent((ev: INavigationIntentEngineEvent) => {
            // Checkout the `ui_event_navigationIntent` for the other navigation code when navigation intents are sent
            // from the UI.

            if (!ev.internalNavigation) {
                VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_NAVIGATION_EXTERNAL_INTENT>(VanillaReaderAppEvents.NAVIGATION_EXTERNAL_INTENT, {
                    detail: {
                        targetUrl: ev.locator.toString(),
                    },
                }));
            } else {
                // Since the UI logic may want to act on this event, if it leads to a footnote for example, we prevent it.
                // The event data contains the information needed for the UI to perform the navigation manually.
                ev.preventDefault();
                VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_NAVIGATION_INTERNAL_INTENT>(VanillaReaderAppEvents.NAVIGATION_INTERNAL_INTENT, {
                    detail: {
                        srcEvent: ev,
                    },
                }));

            }
        });

        this.vanillaReaderReadingSystem.onClick((ev: IMouseEngineEvent) => {
            if (ev.mediaResource) {

                VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_MEDIA_OBJECT_CLICKED>(VanillaReaderAppEvents.MEDIA_OBJECT_CLICKED, {
                    detail: {
                        mediaResourceData: ev.toJSON().mediaResource,
                    },
                }));
                ev.preventDefault();
            }

            VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_CLICK>(VanillaReaderAppEvents.CLICK, {
                detail: {
                    srcEventData: ev.toJSON()
                },
            }));

        });

        this.vanillaReaderReadingSystem.onPointerUp((ev: IPointerEngineEvent) => {
            VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_POINTER_UP>(VanillaReaderAppEvents.POINTER_UP, { detail: { srcEventData: ev.toJSON() } }));
        });

        this.vanillaReaderReadingSystem.onPointerDown((ev: IPointerEngineEvent) => {
            VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_POINTER_DOWN>(VanillaReaderAppEvents.POINTER_DOWN, { detail: { srcEventData: ev.toJSON() } }));
        });

        this.vanillaReaderReadingSystem.onVisibleRangeChanged(() => {
            this._event_visibleRangeDataChanged().catch(console.error);
        });

        this.vanillaReaderReadingSystem.onSelectionChanged((ev: ISelectionChangedEngineEvent) => {
            this._event_textSelected(ev);
            if (this.vanillaMediaPlayer && ev.contentLocation && !ev.isRange) {
                let selectionLocator = ev.contentLocation.getLocator();
                this.vanillaMediaPlayer.seekToLocatorWithinVisibleRange(selectionLocator).catch(console.error);
            }
        });

        this.vanillaReaderReadingSystem.onKeyDown((ev: IKeyboardEngineEvent) => {
            VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_KEYBOARD_EVENT>(VanillaReaderAppEvents.KEY_DOWN, { detail: { srcEventData: ev.toJSON() } }));
        });

        this.vanillaReaderReadingSystem.onKeyUp((ev: IKeyboardEngineEvent) => {
            VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_KEYBOARD_EVENT>(VanillaReaderAppEvents.KEY_UP, { detail: { srcEventData: ev.toJSON() } }));
        });

        this.vanillaReaderReadingSystem.onPublicationDownloadProgress(((progress, url) => {
            VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_PUBLICATION_DOWNLOAD_PROGRESS_UPDATED>(VanillaReaderAppEvents.PUBLICATION_DOWNLOAD_PROGRESS_UPDATED, {
                detail: {
                    progress,
                    publicationId: url,
                },
            }));
        }));

        this.vanillaReaderReadingSystem.onActiveReaderViewTransformChanged((transformData: ITransformData | null) => {
            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_CHANGED>(new CustomEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_CHANGED>(VanillaReaderAppEvents.VIEW_TRANSFORM_CHANGED, {
                detail: {
                    'transformData': transformData || undefined
                }
            }));
        });

        /*
        *
        * STORAGE CALLBACKS
        *
        * */

        this._offlinePublicationDataStorageClient?.onResourceDeleted((_id: string) => {
            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_PUBLICATION_STORED_RESOURCES_DELETED>(new CustomEvent(VanillaReaderAppEvents.PUBLICATION_STORED_RESOURCES_DELETED));
        });

        // These are set up when the SyncMediaPlayer is created. Check out _createSyncMediaPlayer().

        /**
         *
         * HIGHLIGHTS CALLBACKS
         *
         * */

        this.highlightStore.onHighlightAdded(async (highlight: IVanillaReaderHighlightData, silent?: boolean) => {
            if (!this.vanillaPublication) {
                return
            }

            let highlights = await this.highlightStore.fetchHighlightsByPublication(this.vanillaPublication.id);

            this.vanillaReaderView?.highlightsLayer?.addHighlight(highlight);

            // The `silent` parameter tells us that we should not send an event to the UI. This is used when the `addHighlight`
            // method is called repeatedly from the `addHighlights` batch method.
            if (!silent) {
                VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_HIGHLIGHT_ADDED>(new CustomEvent(VanillaReaderAppEvents.HIGHLIGHT_ADDED, { detail: { highlight } }));
            }
            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_HIGHLIGHTS_UPDATED>(new CustomEvent(VanillaReaderAppEvents.HIGHLIGHTS_COLLECTION_UPDATED, { detail: { highlights } }));
        });

        this.highlightStore.onHighlightUpdated(async (highlight: IVanillaReaderHighlightData) => {
            if (!this.vanillaPublication) {
                return
            }
            let highlights = await this.highlightStore.fetchHighlightsByPublication(this.vanillaPublication.id);
            this.vanillaReaderView?.highlightsLayer?.updateHighlight(highlight);
            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_HIGHLIGHT_UPDATED>(new CustomEvent(VanillaReaderAppEvents.HIGHLIGHT_UPDATED, { detail: { highlight } }));
            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_HIGHLIGHTS_UPDATED>(new CustomEvent(VanillaReaderAppEvents.HIGHLIGHTS_COLLECTION_UPDATED, { detail: { highlights } }));
        });

        this.highlightStore.onHighlightDeleted(async (highlight: IVanillaReaderHighlightData) => {
            if (!this.vanillaPublication) {
                return
            }

            let highlights = await this.highlightStore.fetchHighlightsByPublication(this.vanillaPublication.id);
            this.vanillaReaderView?.highlightsLayer?.deleteHighlight(highlight.locator);
            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_HIGHLIGHT_DELETED>(new CustomEvent(VanillaReaderAppEvents.HIGHLIGHT_DELETED, { detail: { highlight } }));
            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_HIGHLIGHTS_UPDATED>(new CustomEvent(VanillaReaderAppEvents.HIGHLIGHTS_COLLECTION_UPDATED, { detail: { highlights } }));
        });

        /**
         *
         * BOOKMARKS CALLBACKS
         *
         * */

        this.bookmarkStore.onBookmarkAdded(async (bookmark, silent) => {
            if (!this.vanillaPublication) {
                return
            }

            let bookmarks = await this.bookmarkStore.fetchBookmarksByPublication(this.vanillaPublication.id);

            this.vanillaReaderView?.bookmarksLayer?.addBookmark(bookmark);

            // The `silent` parameter tells us that we should not send an event to the UI. This is used when the `addBookmark`
            // method is called repeatedly from the `addBookmarks` batch method.
            if (!silent) {
                VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_BOOKMARK_ADDED>(new CustomEvent(VanillaReaderAppEvents.BOOKMARK_ADDED, { detail: { bookmark } }));
            }
            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_BOOKMARKS_UPDATED>(new CustomEvent(VanillaReaderAppEvents.BOOKMARKS_COLLECTION_UPDATED, { detail: { bookmarks } }));

        });
        this.bookmarkStore.onBookmarkDeleted(async (bookmark) => {
            if (!this.vanillaPublication) {
                return
            }

            let bookmarks = await this.bookmarkStore.fetchBookmarksByPublication(this.vanillaPublication.id);

            this.vanillaReaderView?.bookmarksLayer?.deleteBookmark(bookmark.locator);

            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_BOOKMARK_DELETED>(new CustomEvent(VanillaReaderAppEvents.BOOKMARK_DELETED, { detail: { bookmark } }));
            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_BOOKMARKS_UPDATED>(new CustomEvent(VanillaReaderAppEvents.BOOKMARKS_COLLECTION_UPDATED, { detail: { bookmarks } }));

        });
    }

    /*
    * _onAfterPublicationLoaded is called after we have a ReaderPublication. This is when we can do all fun stuff such as,
    *
    * - build the search index
    * - build the navigation tree
    * - etc
    *
    * And once all that is done, we can dispatch the `IVanillaReaderAppEvent_PUBLICATION_LOADED` event to everyone that
    * are listening.
    *
    * It has some less optimal aspects still as it has some reliance on the order in which some methods are
    * called. Refactoring is on the todo 🙂
    *
    * */
    private async _onAfterPublicationLoaded(
        fileName: string,
        fileSourceUri: string,
        colibrioReaderPublication: IReaderPublication,
        initialLocator?: string | undefined,
    ) {
        this.vanillaPublication = new VanillaReaderPublication(fileName, fileSourceUri, colibrioReaderPublication, this.bookmarkStore, this.highlightStore, this.publicationDataStore, this._offlinePublicationDataStorageClient);
        // const getCircularReplacer = () => {
        //     const seen = new WeakSet();
        //     return (key, value) => {
        //         if (typeof value === "object" && value !== null) {
        //             if (seen.has(value)) {
        //                 return; // Omit circular reference
        //             }
        //             seen.add(value);
        //         }
        //         return value;
        //     };
        // };
        
       // console.log(`vanillaPublication: ${JSON.stringify(this.vanillaPublication, getCircularReplacer(), 2)}`);
        
       // console.log('File Name:', fileName);
       // console.log('File Source URI:', fileSourceUri);
       // console.log('Colibrio Reader Publication:', colibrioReaderPublication);
        
        // Log the properties of VanillaReaderPublication instance
       // console.log('VanillaPublication ID:', this.vanillaPublication.id);
       // console.log('VanillaPublication FileName:', this.vanillaPublication.fileName);
       // console.log('VanillaPublication FileSourceUri:', this.vanillaPublication.fileSourceUri);
    
        // Check and log bookmarkStore
        if (this.bookmarkStore) {
         //   console.log('Bookmark Store:', this.bookmarkStore);
        } else {
          //  console.warn('Bookmark Store is not defined.');
        }
    
        // Check and log highlightStore
        if (this.highlightStore) {
          //  console.log('Highlight Store:', this.highlightStore);
        } else {
          //  console.warn('Highlight Store is not defined.');
        }
    
        // Check and log publicationDataStore
        if (this.publicationDataStore) {
          //  console.log('Publication Data Store:', this.publicationDataStore);
        } else {
           // console.warn('Publication Data Store is not defined.');
        }
    
        // Check and log offlinePublicationDataStorageClient
        if (this._offlinePublicationDataStorageClient) {
           // console.log('Offline Publication Data Storage Client:', this._offlinePublicationDataStorageClient);
        } else {
           // console.warn('Offline Publication Data Storage Client is not defined.');
        }

        // TODO: Move to VanillaReaderPublication.ts.
        let initialPublicationCacheDataState = await this.publicationDataStore.fetchPublicationCacheData(this.vanillaPublication.id);
        let initialReadingPositionDataState = await this.publicationDataStore.fetchPublicationReadingPositionData(this.vanillaPublication.id);
       // console.log(`initialReadingPosition: ${JSON.stringify(initialReadingPositionDataState, null, 2)}`);

        if (this.vanillaPublication.isAudiobook) {

            await this._createAudiobookView(this.vanillaPublication);

            this.vanillaAudiobookPlayer = new VanillaReaderAudiobookPlayer(this.vanillaPublication);

            this.vanillaAudiobookPlayer.onMediaPlayerCreated(() => {

                this.vanillaReadingProgressionTimeLine = this._createReadingProgressionTimeline(this.vanillaPublication!);

                VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_MEDIAPLAYER_CREATED>(new CustomEvent(VanillaReaderAppEvents.MEDIAPLAYER_CREATED));
            });

            this.vanillaAudiobookPlayer.onMediaPlayerCreateProgressUpdated((progress: number) => {
                VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_MEDIAPLAYER_CREATE_PROGRESS_UPDATED>(new CustomEvent(VanillaReaderAppEvents.MEDIAPLAYER_CREATE_PROGRESS_UPDATED, {
                    detail: {
                        progress,
                    },
                }));
            });

            this.vanillaAudiobookPlayer.onMediaPlayerPlaybackStateChanged((state: IVanillaSyncMediaPlaybackStateData) => {
                VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_MEDIAPLAYER_PLAYBACK_STATE_CHANGED>(new CustomEvent<IVanillaReaderAppEvent_MEDIAPLAYER_PLAYBACK_STATE_CHANGED>(VanillaReaderAppEvents.MEDIAPLAYER_PLAYBACK_STATE_CHANGED, { detail: { state } }));
            });

            this.vanillaAudiobookPlayer.onMediaPlayerPlaybackPositionChanged((state: IVanillaSyncMediaPlaybackStateData) => {
                VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_MEDIAPLAYER_PLAYBACK_POSITION_CHANGED>(new CustomEvent<IVanillaReaderAppEvent_MEDIAPLAYER_PLAYBACK_POSITION_CHANGED>(VanillaReaderAppEvents.MEDIAPLAYER_PLAYBACK_POSITION_CHANGED, { detail: { state } }));
            });

            this.vanillaAudiobookPlayer.onMediaPlayerTrackChanged((state: IVanillaSyncMediaPlaybackStateData) => {
                VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_COMPLETED>(new CustomEvent<IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_COMPLETED>(VanillaReaderAppEvents.MEDIAPLAYER_SEEK_COMPLETED, { detail: { state } }));
            });

        } else {
            // alert(initialLocator)
            /*
            *
            * EPUB and PDF specific setup
            *
            * */

           // console.log('EPUB or PDF detected, initializing reader view.');
            this.vanillaReaderView = new VanillaReaderView(this.vanillaReaderReadingSystem, this.vanillaPublication, this._appElement, this.optionsDataStore, this._initialOptions?.customReaderViewContentOnLoading, this._initialOptions?.customReaderViewContentOnLoadError);
           // console.log('Vanilla Reader View created:', this.vanillaReaderView);
            this.vanillaReaderView.onActiveRendererChanged((ev) => {
               // console.log('Active renderer changed:', ev.newActiveRenderer?.getName())
                VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_RENDERER_CHANGED>(VanillaReaderAppEvents.RENDERER_CHANGED, {
                    detail: {
                        newActiveRendererName: ev.newActiveRenderer?.getName() as VanillaReaderRendererNames,
                        newActiveRendererOptions: ev.newActiveRenderer?.getOptions(),
                    },
                }));
            });

            this.vanillaReaderView.onRendererTransitionStarted((ev) => {
               // console.log('Renderer transition started:', ev);
                VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_VIEW_TRANSITION_STARTED>(VanillaReaderAppEvents.VIEW_TRANSITION_STARTED, { detail: { srcEventData: ev.toJSON() } }));
            });

            this.vanillaReaderView.onRendererTransitionEnded((ev) => {
               // console.log('Renderer transition ended:', ev);
                VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_VIEW_TRANSITION_ENDED>(VanillaReaderAppEvents.VIEW_TRANSITION_ENDED, { detail: { srcEventData: ev.toJSON() } }));
            });

            this.vanillaReaderView.onReducedMotionValueChanged((preference: boolean) => {
                VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_REDUCED_MOTION_OPTION_CHANGED>(new CustomEvent<IVanillaReaderAppEvent_REDUCED_MOTION_OPTION_CHANGED>(VanillaReaderAppEvents.REDUCED_MOTION_OPTION_CHANGED, { detail: { useReducedMotion: preference } }));
            });

            this.vanillaReaderView.onIgnoreAspectRatioValueChanged((ignore) => {
                VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_RENDERER_ASPECT_RATIO_CHANGED>(VanillaReaderAppEvents.RENDERER_ASPECT_RATIO_CHANGED, { detail: { ignoreAspectRatio: ignore } }));
            });

            this.vanillaReadingProgressionTimeLine = this._createReadingProgressionTimeline(this.vanillaPublication, initialPublicationCacheDataState?.timelineData);

            this.vanillaPublicationSearch = new VanillaReaderPublicationSearch(this.vanillaPublication.colibrioReaderPublication, this.vanillaReaderView.colibrioReaderView);

            this.vanillaPublicationSearch?.onSearchIndexCreateProgressUpdate((progress: number) => {
               // console.log('Search index creation progress:', progress);
                VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_PUBLICATION_SEARCH_INDEX_BUILD_PROGRESS>(new CustomEvent<IVanillaReaderAppEvent_PUBLICATION_SEARCH_INDEX_BUILD_PROGRESS>(VanillaReaderAppEvents.PUBLICATION_SEARCH_INDEX_BUILD_PROGRESS, { detail: { progress } }));
            });

            this.vanillaPublicationSearch?.onSearchIndexCreated(() => {
               // console.log('Search index created.');
                VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_PUBLICATION_SEARCH_INDEX_READY>(new CustomEvent<IVanillaReaderAppEvent_PUBLICATION_SEARCH_INDEX_READY>(VanillaReaderAppEvents.PUBLICATION_SEARCH_INDEX_READY));
            });



        }

        // We want to preserve all style options *except* for palette which the user will expect to mirror the app theme.
        if (this._initialOptions?.viewOptions?.colibrioReaderViewOptions?.publicationStyleOptions?.palette) {
            this.vanillaReaderView?.setVanillaReaderOptions({
                colibrioReaderViewOptions: {
                    publicationStyleOptions: {
                        palette: this._initialOptions?.viewOptions?.colibrioReaderViewOptions?.publicationStyleOptions?.palette,
                    },
                },
            });
        }

        /**
        * If the book has been opened by the user before it will probably have some state that should be restored from
        * the previous session, such as style publicationStyleOptions, bookmarks and highlights.
        **/

        await this._restoreBookmarks().catch(console.warn);
        await this._restoreHighlights().catch(console.warn);

        // Should we start out by restoring the reading position from a previous session?
        if (initialReadingPositionDataState && !initialLocator) {
            initialLocator = initialReadingPositionDataState.locator;
        }

        // The publication is loaded, and we are ready to tell the UI that it can do its thing.
        // We start with serializing the initial publication state so that we can send it back to the UI.
        let vanillaPublicationData = await this.vanillaPublication.serialize();
        let publicationOptionsData = await this.vanillaPublication.fetchPublicationOptionsData();
       // console.log('Dispatching publication loaded event.');
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_PUBLICATION_LOADED>(new CustomEvent<IVanillaReaderAppEvent_PUBLICATION_LOADED>(VanillaReaderAppEvents.PUBLICATION_LOADED, {
            detail: {
                initialPosition: initialLocator,
                publicationData: vanillaPublicationData,
                publicationOptionsData
            },
        }));

       // console.log('Fetched publication cache data:', initialPublicationCacheDataState);
       // console.log('Fetched reading position data:', initialReadingPositionDataState);


        // @ts-ignore
        await window.reader.navigateReaderPosition()

    }

    /*
    *
    * _createAudiobookView renders a simple view that contains the audiobook cover image. It also adds pointer event
    * listeners to the cover image containers.
    *
    * For EPUB and PDF publications, please check out the _createReaderView method.
    *
    * */
    private async _createAudiobookView(vanillaReaderPublication: VanillaReaderPublication) {

        const sourcePublication = vanillaReaderPublication.getColibrioReaderPublication().getSourcePublication() as WpPublication;
        const coverImageResourceUrl = sourcePublication.getCoverImageResourceUrl();

        if (coverImageResourceUrl) {
            const coverImageResponse = await sourcePublication.getBackingResourceProvider().fetch(coverImageResourceUrl);
            const coverImageData = await coverImageResponse.asUint8Array();
            const mediaType = MediaTypeDetector.detectFromUint8Array(coverImageData);
            const objectURL = URL.createObjectURL(new Blob([coverImageData], { type: mediaType || MediaType.IMAGE_JPEG }));

            // As the audiobook does not really have a "ReaderView" equivalent, we just insert some markup to show the
            // book cover.
            this._appElement.insertAdjacentHTML('afterbegin', `
                <div id="vanilla-reader__reader-view__audiobook-cover-container">
                    <img src="${objectURL}" aria-label="Book cover" id="vanilla-reader__reader-view__audiobook-cover-container__image">
                </div>`);

            this._appElement.addEventListener('pointerdown', (ev: PointerEvent) => {
                VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_POINTER_DOWN>(new CustomEvent<IVanillaReaderAppEvent_POINTER_DOWN>(VanillaReaderAppEvents.POINTER_DOWN, {
                    detail: {
                        srcEventData: ev,
                    },
                }));
            });
            this._appElement.addEventListener('pointerup', (ev: PointerEvent) => {
                VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_POINTER_UP>(new CustomEvent<IVanillaReaderAppEvent_POINTER_UP>(VanillaReaderAppEvents.POINTER_UP, {
                    detail: {
                        srcEventData: ev,
                    },
                }));
            });
        }
    }

    /*
    *
    * The VanillaReader has a general implementation of a reading progress timeline in order to abstract away the differences
    * between Audiobooks and text based books such as EPUB and PDF (`IVanillaReaderReadingProgressionTimeline`).
    *
    * "Under the hood" Audiobooks use the Colibrio `SyncMediaTimeline`, for EPUB and PDF the `ContentPositionTimeline`
    * is used.
    *
    * This method creates and returns a new instance of the `IVanillaReaderReadingProgressionTimeline` which is then used
    * in
    *
    * */
    private _createReadingProgressionTimeline(
        vanillaPublication: VanillaReaderPublication,
        timelineSerializedData?: string | undefined,
    ): IVanillaReaderReadingProgressionTimeline | undefined {

        let readingProgressionTimeLine: IVanillaReaderReadingProgressionTimeline | undefined;

        if (!vanillaPublication.isAudiobook && this.vanillaReaderView) {
            /*
            * EPUB and PDF specific setup
            * */
            readingProgressionTimeLine = new VanillaReaderProgressionTimeline(this.vanillaReaderView, vanillaPublication, timelineSerializedData as string | undefined, (_progress: number) => {
            });

        } else if (this.vanillaAudiobookPlayer) {
            /*
            * Audiobook specific setup
            * */
            if (this.vanillaAudiobookPlayer.hasBeenCreated) {
                readingProgressionTimeLine = this.vanillaAudiobookPlayer.getTimeline();
            }
        }

        // The Colibrio `SyncMediaTimeline` and `ContentPositionTimeline` both report the progress of their build process.
        // This information is sent on to the rest of the application using an event.
        readingProgressionTimeLine?.onTimelineUpdateIntent((position: number) => {
            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_TIMELINE_UPDATE_INTENT>(new CustomEvent(VanillaReaderAppEvents.TIMELINE_UPDATE_INTENT, {
                detail: {
                    timelinePosition: position,
                },
            }));
        });

        // The timeline can take a moment to build, so we need to send an event when it has finished building. Check out
        // the `_event_timelineReady` handler to follow this thread in the `VanillaReader`.
        readingProgressionTimeLine?.onTimelineReady((length: number) => {
            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_TIMELINE_READY>(new CustomEvent(VanillaReaderAppEvents.TIMELINE_READY, { detail: { timelineLength: length } }));
        });

        return readingProgressionTimeLine;
    }

    private _registerWordRangeReadToStats(wordRange: IntRange) {
        this.vanillaReaderView?.readingStats?.addWordRange(wordRange);
    }

    /**
     *
     * This method creates an instance of a `VanillaReaderMediaPlayer`. This object will be used for playback of TTS or
     * EPUB Media Overlays.
     *
     * If you are looking for the method that creates the audiobook player it is done in the `_onAfterPublicationLoaded`.
     *
     * */
    private _createSyncMediaPlayer(vanillaReaderView: VanillaReaderView, syncMediaOptions?: IVanillaReaderSyncMediaOptions) {
        this.vanillaMediaPlayer = new VanillaReaderMediaPlayer(vanillaReaderView, syncMediaOptions, this._event_onSyncMediaPlayerCreateProgress);

        this.vanillaMediaPlayer.onAttached(this._event_onSyncMediaPlayerAttached);
        this.vanillaMediaPlayer.onDetached(this._event_onSyncMediaPlayerDetached);
        this.vanillaMediaPlayer.onCreated(this._event_onSyncMediaPlayerCreated);
        this.vanillaMediaPlayer.onPlaybackStateChanged(this._event_onSyncMediaPlayerPlaybackStateChanged);
        return this.vanillaMediaPlayer;
    }

    private async _restoreBookmarks(_silent: boolean = true) {

        if (this.vanillaPublication?.id) {
            let bookmarks = await this.bookmarkStore.fetchBookmarksByPublication(this.vanillaPublication.id);
            this.vanillaReaderView?.renderBookmarks(bookmarks);

            // Dispatch events so that the UI can update its state.
            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_BOOKMARKS_LOADED>(new CustomEvent(VanillaReaderAppEvents.BOOKMARKS_LOADED, { detail: { bookmarks } }));
            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_BOOKMARKS_LOADED>(new CustomEvent(VanillaReaderAppEvents.BOOKMARKS_COLLECTION_UPDATED, { detail: { bookmarks } }));
        }
    }

    private async _restoreHighlights(_silent: boolean = true) {

        if (this.vanillaPublication && this.vanillaPublication.id) {
            let highlights = await this.highlightStore.fetchHighlightsByPublication(this.vanillaPublication.id);
            this.vanillaReaderView?.renderHighlights(highlights);

            // Dispatch events so that the UI can update its state.
            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_HIGHLIGHTS_LOADED>(new CustomEvent(VanillaReaderAppEvents.HIGHLIGHTS_LOADED, { detail: { highlights } }));
            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_HIGHLIGHTS_LOADED>(new CustomEvent(VanillaReaderAppEvents.HIGHLIGHTS_COLLECTION_UPDATED, { detail: { highlights } }));
        }
    }

    /*
    *
    * EVENT HANDLERS
    *
    **/

    private _event_onSyncMediaPlayerAttached = () => {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_MEDIAPLAYER_ATTACHED>(new CustomEvent(VanillaReaderAppEvents.MEDIAPLAYER_ATTACHED));
    };

    private _event_onSyncMediaPlayerDetached = () => {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_MEDIAPLAYER_DETACHED>(new CustomEvent(VanillaReaderAppEvents.MEDIAPLAYER_DETACHED));
    };

    private _event_onSyncMediaPlayerCreated = () => {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_MEDIAPLAYER_CREATED>(new CustomEvent(VanillaReaderAppEvents.MEDIAPLAYER_CREATED));
    };

    private _event_onSyncMediaPlayerCreateProgress = (progress: number) => {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_MEDIAPLAYER_CREATE_PROGRESS_UPDATED>(new CustomEvent(VanillaReaderAppEvents.MEDIAPLAYER_CREATE_PROGRESS_UPDATED, { detail: { progress } }));
    };

    private _event_onSyncMediaPlayerPlaybackStateChanged = (state: IVanillaSyncMediaPlaybackStateData) => {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_MEDIAPLAYER_PLAYBACK_STATE_CHANGED>(new CustomEvent<IVanillaReaderAppEvent_MEDIAPLAYER_PLAYBACK_STATE_CHANGED>(VanillaReaderAppEvents.MEDIAPLAYER_PLAYBACK_STATE_CHANGED, { detail: { state } }));
    };

    private _event_mediaObjectClicked = async (ev: CustomEvent<IVanillaReaderAppEvent_MEDIA_OBJECT_CLICKED>) => {

        if (this.vanillaPublication && !this.vanillaPublication.isFixedLayout) {
            let mediaResourceData: IEngineEventMediaResourceData | null = ev.detail.mediaResourceData;
            let mediaElementAttributes: IAttributeData[] | undefined;

            if (mediaResourceData && mediaResourceData.resourceUrl && mediaResourceData.mediaTypeCategory === MediaTypeCategory.IMAGE) {
                let mediaResourceBlobUrl = await this.vanillaPublication.fetchResourceBlobUrl(mediaResourceData.resourceUrl);
                this.vanillaPublication.fetchLandmarkCollection().then((landmarkCollection: VanillaReaderPublicationLandmarkCollection | undefined) => {

                    if (landmarkCollection) {
                        let mediaElementUrl = new URL(mediaResourceData!.resourceUrl!);
                        let mediaElement = landmarkCollection.getFigures().find((mediaElement) => {
                            if (!mediaElement || !mediaElement.resourceHrefs || mediaElement.resourceHrefs.length === 0) {
                                return false;
                            }
                            return mediaElementUrl.pathname.includes(mediaElement.resourceHrefs[0]);
                        });

                        mediaElementAttributes = mediaElement ? mediaElement.attributes : undefined;

                    }

                    VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_MEDIA_OBJECT_VIEW_INTENT>(VanillaReaderAppEvents.MEDIA_OBJECT_VIEW_INTENT, { detail: { mediaUrl: mediaResourceBlobUrl, mediaElementAttributes } }));
                }).catch(console.warn);
            }
        }
    };

    private _event_timelineReady = async () => {
        if (this._colibrioReaderPublication && this.vanillaReadingProgressionTimeLine) {
            this.vanillaReaderView?.readingStats?.setPublicationLength(this.vanillaReadingProgressionTimeLine.getLength());

            if (this.vanillaPublication) {
                this.vanillaPublication.progressionTimeline = this.vanillaReadingProgressionTimeLine;
                await this.vanillaPublication?.buildNavigationTree(this.vanillaReadingProgressionTimeLine).catch(console.error);
            }
            // As we now have both timeline and navigation tree, we can send more complete visible range data to the UI.
            this._event_visibleRangeDataChanged().catch(console.error);
        }
    };

    private _event_readingPositionChanged = async (ev: IReaderViewEngineEvent) => {

        const readingPosition = ev.readerView.getReadingPosition();
        const readingPositionData = await this.vanillaReaderView?.getReadingPositionData();

        if (readingPosition && readingPositionData && this.vanillaPublication && this.vanillaPublication.id) {
            this.vanillaPublication.setCurrentReadingPositionData(readingPositionData);
            this.vanillaReadingProgressionTimeLine?.updateTimelinePosition(readingPosition.getLocator());
            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_READING_POSITION_UPDATED>(new CustomEvent<IVanillaReaderAppEvent_READING_POSITION_UPDATED>(VanillaReaderAppEvents.READING_POSITION_UPDATED, { detail: { readingPositionData } }));
        }

        // @ts-ignore
        window.reader.updatePublicationData()

    };

    private _event_visibleRangeDataChanged = async () => {

        let visibleRangeData = await this.vanillaReaderView?.fetchVisibleRangeData(this.vanillaPublication?.navigationTree, this.vanillaReadingProgressionTimeLine);
        if (visibleRangeData) {

            if (this.vanillaReadingProgressionTimeLine?.isReady() && visibleRangeData.locator) {
                let wordRange: IIntegerRange | undefined = await this.vanillaReadingProgressionTimeLine?.fetchTimelineRange(visibleRangeData.locator);
                if (wordRange) {
                    this._registerWordRangeReadToStats(new IntRange(wordRange.start, wordRange.end));
                }
            }

            if (visibleRangeData.documentIsAtStart) {
                VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_DOCUMENT_IS_AT_START>(new CustomEvent<IVanillaReaderAppEvent_DOCUMENT_IS_AT_START>(VanillaReaderAppEvents.DOCUMENT_IS_AT_START));
            }
            if (visibleRangeData.documentIsAtEnd) {
                VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_DOCUMENT_IS_AT_END>(new CustomEvent<IVanillaReaderAppEvent_DOCUMENT_IS_AT_END>(VanillaReaderAppEvents.DOCUMENT_IS_AT_END));
            }

            // Check if the start / of the visible range is at the start or end of the publication, and if so send
            // events.

            if (visibleRangeData.publicationIsAtStart) {
                VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_PUBLICATION_IS_AT_START>(new CustomEvent<IVanillaReaderAppEvent_PUBLICATION_IS_AT_START>(VanillaReaderAppEvents.PUBLICATION_IS_AT_START));
            }
            if (visibleRangeData.publicationIsAtEnd) {
                VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_PUBLICATION_IS_AT_END>(new CustomEvent<IVanillaReaderAppEvent_PUBLICATION_IS_AT_END>(VanillaReaderAppEvents.PUBLICATION_IS_AT_END));
            }

            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_VISIBLE_RANGE_DATA_CHANGED>(
                new CustomEvent<IVanillaReaderAppEvent_VISIBLE_RANGE_DATA_CHANGED>(
                    VanillaReaderAppEvents.VISIBLE_RANGE_DATA_CHANGED, {
                    detail: {
                        visibleRangeData,
                    },
                },
                ));
        }

    };

    private _event_textSelected = (ev: ISelectionChangedEngineEvent) => {

        // If we have a range of text selected we turn of view gestures in order to make sure that the view does respond
        // to swipe navigation events etc.
        if (ev.isRange) {
            this.vanillaReaderView?.setAllowedGestureTypes([]);
        } else {
            this.vanillaReaderView?.setAllowedGestureTypes([
                ReaderViewGestureType.SWIPE_NAVIGATION,
                ReaderViewGestureType.PAN_ZOOM
            ]);
        }

        VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_TEXT_SELECTED>(VanillaReaderAppEvents.TEXT_SELECTED, { detail: { srcEventData: ev.toJSON() } }));
    };

    private _event_appOnlineStateChanged = (_ev: CustomEvent<IVanillaReaderAppEvent_APP_ONLINE_STATE_CHANGED>) => {
    };

    private _uiEvent_appWindowResized = (_ev: CustomEvent<IVanillaReaderAppEvent_APP_WINDOW_RESIZED>) => {
        this.vanillaReaderView?.refresh();
    };

    private _uiEvent_publicationLandmarksFetchIntent = async (_ev: CustomEvent<IVanillaReaderAppEvent_PUBLICATION_LANDMARKS_FETCH_INTENT>) => {
        let landmarkCollection = await this.vanillaPublication?.fetchLandmarkCollection(this.vanillaReadingProgressionTimeLine);
        let headings = landmarkCollection?.getHeadings();
        let footnotes = landmarkCollection?.getFootnotes();
        let figures = landmarkCollection?.getFigures();

        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_PUBLICATION_LANDMARKS_READY>(new CustomEvent<IVanillaReaderAppEvent_PUBLICATION_LANDMARKS_READY>(VanillaReaderAppEvents.PUBLICATION_LANDMARKS_READY, {
            detail: {
                headings,
                footnotes,
                figures
            },
        }));
    };

    private _uiEvent_documentLandmarksFetchIntent = async (ev: CustomEvent<IVanillaReaderAppEvent_DOCUMENT_LANDMARKS_FETCH_INTENT>) => {
        let landmarkCollection = await this.vanillaPublication?.fetchLandmarkCollectionForDocument(ev.detail.documentIndex, this.vanillaReadingProgressionTimeLine);
        let headings = landmarkCollection?.getHeadings();
        let footnotes = landmarkCollection?.getFootnotes();
        let figures = landmarkCollection?.getFigures();

        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_DOCUMENT_LANDMARKS_READY>(new CustomEvent<IVanillaReaderAppEvent_DOCUMENT_LANDMARKS_READY>(VanillaReaderAppEvents.DOCUMENT_LANDMARKS_READY, {
            detail: {
                documentIndex: ev.detail.documentIndex,
                headings,
                footnotes,
                figures
            },
        }));
    };

    private _uiEvent_publicationLoadIntent = async (ev: CustomEvent<IVanillaReaderAppEvent_PUBLICATION_LOAD_INTENT>) => {
        await this.loadPublicationFromFile(ev.detail.publicationFile as File, ev.detail.initialLocator, ev.detail.storeOnDevice);
    };

    private _uiEvent_publicationLoadUrlIntent = async (ev: CustomEvent<IVanillaReaderAppEvent_PUBLICATION_LOAD_URL_INTENT>) => {
        await this.loadPublicationFromUrl(ev.detail.publicationUrl, undefined, ev.detail.initialLocator, ev.detail.storeOnDevice);
    };

    private _uiEvent_publicationLoadFromStoreIntent = async (ev: CustomEvent<IVanillaReaderAppEvent_PUBLICATION_LOAD_FROM_STORE_INTENT>) => {
        await this.loadPublicationFromStore(ev.detail.fileName, ev.detail.fileSourceUri, undefined, true);
    };

    private _uiEvent_navigationIntent = (ev: CustomEvent<IVanillaReaderAppEvent_NAVIGATION_INTENT>) => {
        let eventData = ev.detail;

        // alert("Switch: "+ JSON.stringify(eventData.navigationType))
        switch (eventData.navigationType) {
            case VanillaReaderNavigationType.NEXT:
                this.next().catch(console.warn);
                break;
            case VanillaReaderNavigationType.PREVIOUS:
                this.previous().catch(console.warn);
                break;
            case VanillaReaderNavigationType.TIMELINEPOSITION:
                if (eventData.position) {
                    this.gotoTimelinePosition(eventData.position).catch(console.error);
                } else {
                    console.warn('VanillaReader.event_navigationIntent()', 'event.detail.position is undefined');
                }
                break;
            case VanillaReaderNavigationType.GOTO:
                if (eventData.locator) {
                    this.goTo(eventData.locator).catch(console.error);
                } else {
                    console.warn('VanillaReader.event_navigationIntent()', 'event.detail.location is undefined');
                }
                break;
            case VanillaReaderNavigationType.GOTOSTART:
                this.goToStart().catch(console.error);
                break;
        }

        if (this.shouldFocusOnReadingPosition && eventData.hasOwnProperty('focusNearContentLocation') && eventData.focusNearContentLocation) {
            VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.READING_POSITION_UPDATED, () => {
                this.focusOnReadingPosition().catch(console.warn);
            }, true);
        }

    };

    private _uiEvent_highlightAddIntent = async (ev: CustomEvent<IVanillaReaderAppEvent_HIGHLIGHT_ADD_INTENT>) => {
        if (ev.detail.highlight) {
            await this.addHighlight(ev.detail.highlight);
        }
    };

    private _uiEvent_highlightTextLocationIntent = (ev: CustomEvent<IVanillaReaderAppEvent_HIGHLIGHT_TEXTLOCATION_INTENT>) => {
        this.highlightTextLocation(ev.detail.text, ev.detail.documentIndex, ev.detail.highlightData);
    };

    private _uiEvent_highlightDeleteIntent = (ev: CustomEvent<IVanillaReaderAppEvent_HIGHLIGHT_DELETE_INTENT>) => {
        this.highlightStore?.deleteHighlight(ev.detail.selector).catch(console.warn);
    };

    private _uiEvent_highlightUpdateIntent = (ev: CustomEvent<IVanillaReaderAppEvent_HIGHLIGHT_ADD_INTENT>) => {
        this.highlightStore?.updateHighlight(ev.detail.highlight).catch(console.warn);
    };

    private _uiEvent_bookmarkDeleteIntent = (ev: CustomEvent<IVanillaReaderAppEvent_BOOKMARK_DELETE_INTENT>) => {
        let locator = ev.detail.bookmark.locator;
        if (locator) {
            this.bookmarkStore.deleteBookmark(locator).catch(console.warn);
        }

    };

    private _uiEvent_bookmarkReadingPositionIntent = async (_ev: CustomEvent<IVanillaReaderAppEvent_BOOKMARK_READINGPOSITION_INTENT>) => {
        await this.bookmarkReadingPosition();
    };

    private _uiEvent_bookmarkTextLocationIntent = async (ev: CustomEvent<IVanillaReaderAppEvent_BOOKMARK_TEXTLOCATION_INTENT>) => {
        await this.bookmarkTextLocation(ev.detail.text, ev.detail.documentIndex);
    };

    private _uiEvent_focusOnReadingPosition = (_ev: CustomEvent<IVanillaReaderAppEvent_FOCUS_ON_READINGPOSITION_INTENT>) => {
        if (this.shouldFocusOnReadingPosition) {
            this.focusOnReadingPosition().catch(console.error);
        }
    };

    private _uiEvent_publicationStyleOptionsChangeIntent = (ev: CustomEvent<IVanillaReaderAppEvent_PUBLICATION_STYLE_OPTIONS_CHANGE_INTENT>) => {
        if (this.vanillaReaderView) {
            this.vanillaPublication?.setPublicationStyleOptions(ev.detail.styleOptions, this.vanillaReaderView);
        }
    };

    private _uiEvent_mediaObjectViewClosed = (ev: CustomEvent<IVanillaReaderAppEvent_MEDIA_OBJECT_VIEW_CLOSED>) => {
        if (ev.detail.mediaUrl) {
            this.vanillaPublication?.revokeResourceBlobUrl(ev.detail.mediaUrl);
        } else {
            // The media url was undefined, let's just revoke all urls to be on the safe side. Don't want to leak memory.
            this.vanillaPublication?.revokeAllResourceBlobUrls();
        }

    };

    private _uiEvent_publicationSearchIntent = (ev: CustomEvent<IVanillaReaderAppEvent_PUBLICATION_SEARCH_INTENT>) => {
        if (ev.detail && ev.detail.searchTerm.length >= 3) {
            this.clearPublicationSearchLayer();
            this.searchPublication(ev.detail.searchTerm).then((resultSet) => {
                this.searchPublicationCompleted(resultSet);
            }).catch(console.error);
        }
    };

    private _uiEvent_publicationDeleteCacheIntent = (ev: CustomEvent<IVanillaReaderAppEvent_PUBLICATION_DELETE_STORED_RESOURCES_INTENT>) => {
        this._offlinePublicationDataStorageClient?.deleteResource(ev.detail.publicationId).then(() => {
            VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_PUBLICATION_STORED_RESOURCES_DELETED>(VanillaReaderAppEvents.PUBLICATION_STORED_RESOURCES_DELETED, { detail: { publicationId: ev.detail.publicationId } }));
        });
    };

    private _uiEvent_reducedMotionOptionChangeIntent = (ev: CustomEvent<IVanillaReaderAppEvent_REDUCED_MOTION_OPTION_CHANGE_INTENT>) => {
        if (this.vanillaReaderView) {
            this.vanillaReaderView.useReducedMotion = ev.detail.useReducedMotion;
        }
    };

    private _uiEvent_publicationSearchClearIntent = () => {
        this.clearPublicationSearchLayer();
    };

    private _uiEvent_rendererChangeIntent = (ev: CustomEvent<IVanillaReaderAppEvent_RENDERER_CHANGE_INTENT>) => {
        if (ev.detail.rendererName && Object.values(VanillaReaderRendererNames).includes(ev.detail.rendererName)) {
            this.vanillaReaderView?.changeRenderer(ev.detail.rendererName, ev.detail.ignoreAspectRatio);
        }
    };

    private _uiEvent_rendererChangeAspectRatio = (ev: CustomEvent<IVanillaReaderAppEvent_RENDERER_CHANGE_ASPECT_RATIO_INTENT>) => {
        this.vanillaReaderView?.changeRendererAspectRatio(ev.detail.ignoreAspectRatio || false);
    };

    private _uiEvent_viewRefreshIntent = (ev: CustomEvent<IVanillaReaderAppEvent_VIEW_REFRESH_INTENT>) => {
        this.vanillaReaderView?.refresh(ev.detail?.force);
    };

    private _uiEvent_mediaPlayerToggleIntent = (ev: CustomEvent<IVanillaReaderAppEvent_MEDIAPLAYER_TOGGLE_INTENT>) => {
        if (this.vanillaReaderView) {
            if (ev.detail.activate) {
                let player = this.vanillaMediaPlayer;
                if (!player) {
                    player = this._createSyncMediaPlayer(this.vanillaReaderView, ev.detail.options);
                }
                player?.toggle(ev.detail.activate);

            } else {
                if (this.vanillaMediaPlayer) {
                    this.vanillaMediaPlayer.pause();
                    this.vanillaMediaPlayer.toggle(false);
                }
            }
        } else {
            console.warn('VanillaReader._uiEvent_mediaPlayerToggleIntent(): No _colibrioReaderView instance found. Is this an audiobook?');
        }
    };

    private _uiEvent_mediaPlayerSeekNextIntent = (_ev: CustomEvent<IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_NEXT_INTENT>) => {
        if (this.vanillaMediaPlayer && this.vanillaMediaPlayer.playerIsAttachedToView) {
            this.vanillaMediaPlayer.seekToNext();
        } else if (this.vanillaAudiobookPlayer) {
            this.vanillaAudiobookPlayer.seekToNextSegment().catch(console.warn);
        }
    };

    private _uiEvent_mediaPlayerSeekPreviousIntent = (_ev: CustomEvent<IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_PREVIOUS_INTENT>) => {
        if (this.vanillaMediaPlayer && this.vanillaMediaPlayer.playerIsAttachedToView) {
            this.vanillaMediaPlayer.seekToPrevious();
        } else if (this.vanillaAudiobookPlayer) {
            this.vanillaAudiobookPlayer.seekToPreviousSegment().catch(console.warn);
        }
    };

    private _uiEvent_mediaPlayerSeekBackwardIntent = (_ev: CustomEvent<IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_BACKWARD_INTENT>) => {
        if (this.vanillaMediaPlayer && this.vanillaMediaPlayer.playerIsAttachedToView) {
            this.vanillaMediaPlayer.seekBackward();
        } else if (this.vanillaAudiobookPlayer) {
            this.vanillaAudiobookPlayer.seekBackward();
        }
    };

    private _uiEvent_mediaPlayerSeekForwardIntent = (_ev: CustomEvent<IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_FORWARD_INTENT>) => {
        if (this.vanillaMediaPlayer && this.vanillaMediaPlayer.playerIsAttachedToView) {
            this.vanillaMediaPlayer.seekForward();
        } else if (this.vanillaAudiobookPlayer) {
            this.vanillaAudiobookPlayer.seekForward();
        }
    };

    private _uiEvent_mediaPlayerPlayIntent = async (ev: CustomEvent<IVanillaReaderAppEvent_MEDIAPLAYER_PLAY_INTENT>) => {
        if (this.vanillaMediaPlayer && this.vanillaMediaPlayer.playerIsAttachedToView) {
            if (ev.detail.locator) {
                await this.vanillaMediaPlayer.seekToLocator(ev.detail.locator);
            }
            this.vanillaMediaPlayer.play();
        } else if (this.vanillaAudiobookPlayer) {
            this.vanillaAudiobookPlayer.play().catch(console.warn);
        }
    };

    private _uiEvent_mediaPlayerPauseIntent = (_ev: CustomEvent<IVanillaReaderAppEvent_MEDIAPLAYER_PAUSE_INTENT>) => {
        if (this.vanillaMediaPlayer && this.vanillaMediaPlayer.playerIsAttachedToView) {
            this.vanillaMediaPlayer.pause();
        } else if (this.vanillaAudiobookPlayer) {
            this.vanillaAudiobookPlayer.pause().catch(console.warn);
        }
    };

    private _uiEvent_mediaPlayerRateChangeIntent = (ev: CustomEvent<IVanillaReaderAppEvent_MEDIAPLAYER_RATE_CHANGE_INTENT>) => {
        if (!this.vanillaPublication) return;

        if (this.vanillaMediaPlayer && this.vanillaMediaPlayer.playerIsAttachedToView) {
            this.vanillaMediaPlayer.setPlaybackRate(ev.detail.rate);
            this.vanillaPublication.ttsOptions = this.vanillaMediaPlayer.getTtsPlaybackOptions();
        } else if (this.vanillaAudiobookPlayer) {
            this.vanillaAudiobookPlayer.setPlaybackRate(ev.detail.rate);
        }
    };

    private _uiEvent_mediaPlayerVoiceChangeIntent = async (ev: CustomEvent<IVanillaReaderAppEvent_MEDIAPLAYER_VOICE_CHANGE_INTENT>) => {
        if (!this.vanillaPublication) return;

        if (this.vanillaMediaPlayer && this.vanillaMediaPlayer.playerIsAttachedToView) {

            let voiceName: string | undefined = ev.detail.voiceName === 'undefined' ? undefined : ev.detail.voiceName;

            await this.vanillaMediaPlayer.setTtsVoiceByName(voiceName).catch(console.warn);

            // Persist the options change in the VanillaPublication instance so that it will be serialized properly.
            this.vanillaPublication.ttsOptions = this.vanillaMediaPlayer.getTtsPlaybackOptions();

            // Back up the media player to the start of the phrase so that it repeats it using the new voice.
            let readingPosition = this.vanillaReaderView?.getReadingPosition();
            if (readingPosition) {
                this.vanillaMediaPlayer.seekToLocator(readingPosition.getLocator()).catch(console.warn);
            }

            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_MEDIAPLAYER_VOICE_CHANGED>(new CustomEvent<IVanillaReaderAppEvent_MEDIAPLAYER_VOICE_CHANGED>(VanillaReaderAppEvents.MEDIAPLAYER_VOICE_CHANGED, { detail: { voiceName } }))
        }
    };

    private _uiEvent_mediaPlayerVolumeChangeIntent = (ev: CustomEvent<IVanillaReaderAppEvent_MEDIAPLAYER_VOLUME_CHANGE_INTENT>) => {
        if (!this.vanillaPublication) return;

        if (this.vanillaMediaPlayer && this.vanillaMediaPlayer.playerIsAttachedToView) {
            this.vanillaMediaPlayer.setVolume(ev.detail.volume);
            this.vanillaPublication.ttsOptions = this.vanillaMediaPlayer.getTtsPlaybackOptions();
        } else if (this.vanillaAudiobookPlayer) {
            this.vanillaAudiobookPlayer.setPlaybackRate(ev.detail.volume);
        }
    };

    private _uiEvent_viewTransformPanModeDeactivateIntent = (_ev: CustomEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_PAN_MODE_DEACTIVATE_INTENT>) => {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_PAN_MODE_DEACTIVATED>(new CustomEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_PAN_MODE_DEACTIVATED>(VanillaReaderAppEvents.VIEW_TRANSFORM_PAN_MODE_DEACTIVATED))
    }

    private _uiEvent_viewTransformPanModeActivateIntent = (_ev: CustomEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_PAN_MODE_ACTIVATE_INTENT>) => {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_PAN_MODE_ACTIVATED>(new CustomEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_PAN_MODE_ACTIVATED>(VanillaReaderAppEvents.VIEW_TRANSFORM_PAN_MODE_ACTIVATED));
    }

    private _uiEvent_viewTransformResetIntent = (_ev: CustomEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_RESET_INTENT>) => {
        this.vanillaReaderView?.resetViewTransformation();
    }

    private _uiEvent_viewTransformZoomToRectIntent = async (ev: CustomEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_ZOOM_TO_RECT_INTENT>) => {
        let transformData = await this.vanillaReaderView?.zoomToClientRect(ev.detail.zoomRect) || undefined;
        VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_ZOOMED>(VanillaReaderAppEvents.VIEW_TRANSFORM_ZOOMED, { detail: { transformData } }))
    }

    private _uiEvent_viewTransformZoomToLevelIntent = async (ev: CustomEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_ZOOM_TO_LEVEL_INTENT>) => {
        let transformData = await this.vanillaReaderView?.zoomToLevel(ev.detail.zoomLevel, ev.detail.animate) || undefined;
        VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_ZOOMED>(VanillaReaderAppEvents.VIEW_TRANSFORM_ZOOMED, { detail: { transformData } }))
    }


}
