/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
export interface IIntRangeData {
    start: number,
    end: number
}

export class IntRange {
    constructor(public readonly start: number, public readonly end: number) {
        this.start = Math.round(start);
        this.end = Math.round(end);

        if(end < start) {
            throw new Error("Invalid range. End offset must be greater than start offset");
        }
    }

    public get length(): number {
        return this.end - this.start;
    }

    contains(range: IntRange): boolean {
        /**
         * |--------------------|
         *     |----------|
         * */

        return this.start <= range.start && this.end >= range.end;
    }

    intersects(range: IntRange): boolean {
        /**
         * |-------|
         *     |----------|
         * */

        const isOutside = this.isBefore(range) || this.isAfter(range);

        return !isOutside;
    }

    isAdjancent(range: IntRange) {
        return this.end === range.start || this.start === range.end;
    }

    isBefore(range: IntRange): boolean {
        /**
         * |-------|
         *          |-----|
         * */

        return  this.end <= range.start;
    }

    isAfter(range: IntRange) {
        /**
         *          |-------|
         *  |-----|
         * */

        return  this.start >= range.end;
    }

    intersection(range: IntRange): IntRange | null {

        /**
         *  |--------------|
         *         |=======|-----|
         * */


        if(!this.intersects(range)) {
            return null;
        }

        return new IntRange(Math.max(this.start, range.start), Math.min(this.end, range.end));

    }

    // difference(range: IntRange): IntRange[] {
    //
    //     /**
    //      * |===|---------|===|
    //      *     |---------|
    //      *
    //      *     |---------|
    //      * |===|---------|===|
    //      *
    //      *     |--------|====|
    //      * |===|--------|
    //      *
    //      * |-------------|
    //      *      |--------|===|
    //      *
    //      * */
    //
    //     if(!this.intersects(range)) {
    //         return [];
    //     }
    //
    //     if(this.contains(range)) {
    //         return [new IntRange(this.start, range.start), new IntRange(range.end, this.end)];
    //     }
    //
    //     if(range.contains(this)) {
    //         return [new IntRange(range.start, this.start), new IntRange(this.end, range.end)];
    //     }
    //
    //     if(range.start < this.start) {
    //         return [new IntRange(range.start, this.start), new IntRange(range.end, this.end)];
    //     } else{
    //         return [new IntRange(this.end, range.end)];
    //     }
    // }

    merge(range: IntRange): IntRange {

        /**
         * |=======
         *     ===========|
         * */

        let startIndex = Math.min(this.start, range.start);
        let endIndex = Math.max(this.end, range.end);
        return new IntRange(startIndex, endIndex);
    }
}
