/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {
    IPublicationStyleFontSet,
    IPublicationStyleOptions,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';
import {
    IVanillaReaderTtsOptions,
    IVanillaReaderUiColorPalette,
    VanillaReaderRendererNames,
} from '../VanillaReader/VanillaReaderModel';
import {Key} from "../utils/Key.enum";
import {IVanillaReaderAppEvent_NAVIGATION_INTENT, IVanillaUiDialogResult} from "../VanillaReader/VanillaReaderEventBus";

/*
* # IVanillaReaderAppUiSettingsData
*
* ## RESPONSIBILITIES
*
* This interface defines the data that is needed by the application UI to restore its settings from a previous setting.
*
* It is used as a schema in the `IVanillaReaderDataStore` implementations so change this type with caution if offer the
* app as a PWA as it will require migration.
*
* */

export interface IVanillaReaderUiOptionsData {
    _id?: number,
    dateCreated?: number;
    showNarrationControls?: boolean;
    ignoreAspectRatio?: boolean;
    useReducedMotion?: boolean;
    useDarkMode?: boolean;
    activeRendererTypeName?: VanillaReaderRendererNames;
    activeUiPaletteName?: string,
    activePublicationPaletteName?: string;
    activePublicationFontSetName?: string;
    publicationStyleOptions?: IPublicationStyleOptions;
    mediaPlayerPlaybackRate?: number;
    ttsOptions?: IVanillaReaderTtsOptions,
    useAccessibilityMode?: boolean;
    uIActionKeyCommands?: IVanillaReaderUiActionKeyCommand[],
    fontSets?: {
        SansSerif: IPublicationStyleFontSet;
        Serif: IPublicationStyleFontSet;
        EasyReading: IPublicationStyleFontSet;
    },
    palettes?: {
        Default: IVanillaReaderUiColorPalette;
        Dark: IVanillaReaderUiColorPalette;
        ContrastDark: IVanillaReaderUiColorPalette;
        Paperlike: IVanillaReaderUiColorPalette;
    }
}

/**
 * # IVanillaReaderAppUiSettingsData
 *
 * ## RESPONSIBILITIES
 *
 * This enum holds all the UI actions that can be performed using keyboard shortcuts and through the UIActions menu.
 *
 * */
export const enum VanillaReaderUiActionNames {
    ACTIONS_MENU_OPEN = 'uiaction_actions_menu_open',
    MAIN_MENU_OPEN = 'uiaction_main_menu_open',
    WHERE_AM_I = 'uiaction_where_am_i',
    TOC_OPEN_DIALOG= 'uiaction_toc_open',
    NAVIGATE_NEXT= 'uiaction_navigate_next',
    NAVIGATE_PREVIOUS= 'uiaction_navigate_previous',
    NAVIGATE_NEXT_HEADING= 'uiaction_navigate_next_heading',
    NAVIGATE_PREVIOUS_HEADING= 'uiaction_navigate_previous_heading',
    HIGHLIGHT_OPEN_DIALOG_ALL = 'uiaction_highlight_open_dialog_all',
    HIGHLIGHT_OPEN_DIALOG_ACTIVE = 'uiaction_highlight_open_dialog_active',
    HIGHLIGHT_SELECTION = 'uiaction_highlight_selection',
    HIGHLIGHT_CLIPBOARD = 'uiaction_highlight_clipboard',
    HIGHLIGHT_READING_POSITION = 'uiaction_highlight_reading_position',
    HIGHLIGHT_ACTIVE_CONTENT = 'uiaction_highlight_active_content',
    BOOKMARK_SELECTION = 'uiaction_bookmark_selection',
    BOOKMARK_CLIPBOARD = 'uiaction_bookmark_clipboard',
    BOOKMARK_READING_POSITION = 'uiaction_bookmark_reading_position',
    BOOKMARK_OPEN_DIALOG_ALL = 'uiaction_bookmark_open_dialog_all',
    BOOKMARK_OPEN_DIALOG_ACTIVE = 'uiaction_bookmark_open_dialog_active',
    SEARCH_DIALOG_OPEN = 'uiaction_search_open_dialog_active',
    SEARCH_CLEAR = 'uiaction_search_clear',
    MEDIA_PLAYBACK_START = 'uiaction_media_playback_start',
    MEDIA_PLAYBACK_STOP = 'uiaction_media_playback_stop',
    HELP_DIALOG_OPEN = 'uiaction_help_dialog_open',
    ACCESSIBILITY_MODE_ENTER = 'uiaction_accessibility_mode_enter',
    ACCESSIBILITY_MODE_EXIT = 'uiaction_accessibility_mode_exit',
    SETTINGS_DIALOG_OPEN = 'uiaction_settings_dialog_open',
    BOOKSHELF_DIALOG_OPEN = 'uiaction_bookshelf_dialog_open',
    CLOSE_DIALOGS_AND_FOCUS_ON_READING_POSITION = 'uiaction_close_dialogs_and_focus_on_reading_position',
    GOTO_PAGE_DIALOG_OPEN = 'uiaction_goto_page_dialog_open',
    ZOOM_TOOL_ACTIVATE = 'uiaction_zoom_mode_enter',
    ZOOM_TOOL_DEACTIVATE = 'uiaction_zoom_mode_exit',
    ZOOM_OUT = 'uiaction_zoom_out',
    ZOOM_IN = 'uiaction_zoom_in',
    ZOOM_RESET = 'uiaction_zoom_reset',
}

export interface IVanillaReaderUiAction {
    name: VanillaReaderUiActionNames;
}

export interface IVanillaReaderUiActionKeyCommand {
    actionName: VanillaReaderUiActionNames;
    key: string;
    modifiers: Key[];
}

export type VanillaUiNavigationIntentEventCallback = (navigationData: IVanillaReaderAppEvent_NAVIGATION_INTENT) => void;
export type VanillaUiActionEventCallback = (actionName: VanillaReaderUiActionNames) => void;
export type VanillaUiSettingsUpdatedCallback = (settings: IVanillaReaderUiOptionsData) => void;
export type VanillaUiThemeChangedCallback = (palette: IVanillaReaderUiColorPalette) => void;
export type VanillaUiSliderValueChangedCallback = (palette: IVanillaReaderUiColorPalette) => void;
export type VanillaUiUnloadEventCallback = (settings: IVanillaReaderUiOptionsData) => void;
export type VanillaUiDialogResultCallback<T> = (result: IVanillaUiDialogResult<T>) => void;
