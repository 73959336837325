/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IVanillaReaderUiComponentController} from "./IVanillaReaderUiComponentController";
import {IVanillaReaderUI} from "./IVanillaReaderUI";
import {VanillaReaderUI} from "./VanillaReaderUI";

export interface VanillaReaderUiButtonMenuController extends IVanillaReaderUiComponentController, IVanillaReaderUI {}

/**
 * # VanillaReaderUiButtonMenuController
 *
 * ## RESPONSIBILITIES
 * This controller mixing class handles all event callbacks from the `VanillaReaderUiButtonMenu` class.
 *
 * Just as all other controller mixin classes, this class is used to add functionality to the `VanillaReaderUi` class
 * in a "modular" way. Read more about this in the `UI Components and Controller Mixins` in the README.md file.
 *
 * ## RELATED TYPES
 * - VanillaReaderUi
 * - VanillaReaderUiButtonMenu
 *
 * */
export class VanillaReaderUiButtonMenuController {
    static __initialize__(vanillaReaderUiContext: VanillaReaderUI) {
        vanillaReaderUiContext.buttonMenu.onClick(vanillaReaderUiContext._onButtonMenuClicked.bind(vanillaReaderUiContext));
    }

    _onButtonMenuClicked() {
        this.menuBottom.show();
    };

}