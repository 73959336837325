/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {NavigationCollectionType} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';
import {ITreeViewItemSourceData, TreeView, TreeViewItem} from '../utils/TreeView';
import {
    VanillaUiDialogResultCallback,
    VanillaUiNavigationIntentEventCallback
} from '../VanillaReaderUI/VanillaReaderUiModel';
import {
    IVanillaReaderNavigationItem,
    IVanillaReaderNavigationTreeData,
    VanillaReaderNavigationType,
} from '../VanillaReader/VanillaReaderModel';
import {VanillaReaderUiDialog} from './VanillaReaderUiDialog';

/**
 * # VanillaReaderUiDialogContents
 *
 * ## RESPONSIBILITIES
 * This dialog shows a list of all highlights in the publication.
 *
 * This component is stateful. It's list state is refreshed by the VanillaReaderUI class whenever new data is available,
 * such as when a new item has become active.
 *
 * ## RELATED TYPES
 * - VanillaReaderUiDialogContentsController
 */

export class VanillaReaderUiDialogContents extends VanillaReaderUiDialog<void> {
    private _sectionToc: HTMLElement;
    private _sectionPages: HTMLElement;
    private _navItemClickedCallback: VanillaUiNavigationIntentEventCallback | undefined = undefined;
    private _treeViewToc: TreeView | undefined = undefined;
    private _treeViewPages: TreeView | undefined = undefined;
    private _treeViewTocItems: ITreeViewItemSourceData[] | undefined;
    private _treeViewPageItems: ITreeViewItemSourceData[] | undefined;

    private _bodyStyle = `
        <style>

            #vanilla-reader__dialog-contents__section-toc ul[role=tree] {
                padding-left: 0;
                list-style: none;
            }

            #vanilla-reader__dialog-contents__section-toc ul[role=tree] button {
                font-size: x-large;
                margin-left: 0;
            }

            #vanilla-reader__dialog-contents__section-toc ul[role=group] {
                padding-left: 1em;
                list-style: none;
            }

            #vanilla-reader__dialog-contents__section-toc ul[role=group] li:not([data-treeview-role=group]) {
               margin-left: 1em;
            }

            #vanilla-reader__dialog-contents__section-toc li {
                align-items: center;
            }

            #vanilla-reader__dialog-contents__section-toc li a {
                max-width: 70%;
            }

            #vanilla-reader__dialog-contents__section-toc li[aria-selected=true] {
                text-decoration: underline;
            }

            #vanilla-reader__dialog-contents__section-toc li[aria-expanded=true] ul {
                border-left: 1px dotted var(--vanilla-reader__color__ui__outline-dark);
            }

            #vanilla-reader__dialog-contents__section-pages ul {
                padding-left: 0;
                display: flex;
                flex-wrap: wrap;
            }

            #vanilla-reader__dialog-contents__section-pages li {
                justify-content: space-around;
                align-items: center;
                border: 1px solid var(--vanilla-reader__color__ui__outline-light);
                border-radius: 8px;
                height: 48px;
                width: 48px;
                margin: 0.5em;
            }

            #vanilla-reader__dialog-contents__section-pages li[aria-selected=true] {
                border: 2px solid var(--vanilla-reader__color__ui__outline-dark);
                text-decoration: underline;
            }

            #vanilla-reader__dialog-contents__section-pages li a {
                max-width: 100%;
                width: 100%;
                text-align: center;
            }



        </style>`;

    private _bodyTemplate = `
        <section id="vanilla-reader__dialog-contents__section-toc">
            <h2>Table of Contents</h2>
        </section>
        <section id="vanilla-reader__dialog-contents__section-pages">
            <h2>Page list</h2>
        </section>
    `;

    constructor(
        _hostElement: HTMLElement,
        _insertPosition: InsertPosition = 'afterbegin',
    ) {
        super(_hostElement, 'vanilla-reader__dialog-contents', 'Book contents', _insertPosition);

        this._dialogBodyElement.innerHTML = this._bodyTemplate;
        this._dialogElement.insertAdjacentHTML('afterbegin', this._bodyStyle);
        this._sectionToc = this._dialogElement.querySelector<HTMLElement>('#vanilla-reader__dialog-contents__section-toc')!;
        this._sectionPages = this._dialogElement.querySelector<HTMLElement>('#vanilla-reader__dialog-contents__section-pages')!;
    }

    show(silent: boolean = false, returnFocusOnClose: boolean = true, onDialogClosedCallbackOverride?: VanillaUiDialogResultCallback<void>) {
        super.show(silent, returnFocusOnClose, onDialogClosedCallbackOverride);
        // Wait to select the item until the dialog has ended its fade in transition.
        setTimeout(() => {
            this._selectCurrentActiveItem();
        }, 200);
    }

    onNavItemClicked(callback: VanillaUiNavigationIntentEventCallback) {
        this._navItemClickedCallback = callback;
    }

    populate(treeData: IVanillaReaderNavigationTreeData) {

        if (treeData) {
            let collections = treeData.collections;
            if (collections) {

                // console.log("Collections:", collections);

                this.empty();

                for (const collection of collections) {

                    if (collection.type === NavigationCollectionType.TOC) {
                        if (!this._treeViewTocItems) {
                            this._treeViewTocItems = this._mapNavigationItemDataToTreeItemData(collection.children);
                        }
                        this._treeViewToc = new TreeView<IVanillaReaderNavigationItem>(this._sectionToc, this._treeViewTocItems);
                        this._treeViewToc.cssClassName = 'vanilla-reader__dialog-contents__list-toc';
                        console.log('LoadingTOC:', this._treeViewToc);

                        this._treeViewToc.addItemClickEventHandler((navItem: ITreeViewItemSourceData<IVanillaReaderNavigationItem>) => {

                            if (navItem.data.locator && this._navItemClickedCallback) {
                                this._navItemClickedCallback({
                                    navigationType: VanillaReaderNavigationType.GOTO,
                                    locator: navItem.data.locator,
                                });
                            }

                        });
                        this._treeViewToc.render();
                    }

                    if (collection.type === NavigationCollectionType.PAGE_LIST) {
                        if (!this._treeViewPageItems) {
                            this._treeViewPageItems = this._mapNavigationItemDataToTreeItemData(collection.children);
                        }
                        this._treeViewPages = new TreeView<IVanillaReaderNavigationItem>(this._sectionPages, this._treeViewPageItems);
                        this._treeViewPages.cssClassName = 'vanilla-reader__dialog-contents__list-toc';

                        this._treeViewPages.addItemClickEventHandler((navItem: ITreeViewItemSourceData<IVanillaReaderNavigationItem>) => {

                            if (navItem.data.locator && this._navItemClickedCallback) {
                                this._navItemClickedCallback({
                                    navigationType: VanillaReaderNavigationType.GOTO,
                                    locator: navItem.data.locator,
                                });
                            }

                        });
                        this._treeViewPages.render();
                    }

                }
            }
        }
    }

    empty() {
        if (this._treeViewToc) {
            this._treeViewToc.destroy();
        }
    }

    setActiveItems(navigationItemsInVisibleRange: IVanillaReaderNavigationItem[]) {
        if (navigationItemsInVisibleRange && navigationItemsInVisibleRange.length > 0) {
            this.setActivePageItems(navigationItemsInVisibleRange);
            this.setActiveTocItems(navigationItemsInVisibleRange);
        }
    }

    setActiveTocItems(navigationItemsInVisibleRange: IVanillaReaderNavigationItem[]) {
        if (navigationItemsInVisibleRange && navigationItemsInVisibleRange.length > 0) {

            this._treeViewToc?.deselectAllItems();

            let selectedItems: TreeViewItem[] = [];

            navigationItemsInVisibleRange.forEach((item) => {
                if (item.collectionType === 'TOC') {
                    let locator = item.locator;
                    if (locator) {
                        let treeViewItems = this._treeViewToc?.filterTreeItemsByItemData((navItem: IVanillaReaderNavigationItem) => {
                            return navItem.locator === item.locator;
                        });

                        if (treeViewItems && treeViewItems.length > 0) {
                            selectedItems.push(treeViewItems[0]);
                        }
                    }
                }
            });

            let activeTreeItem = selectedItems[selectedItems.length - 1];
            if (activeTreeItem) {
                this._treeViewToc?.expandToTreeItem(activeTreeItem);
                activeTreeItem.setIsActive(true, true);
                activeTreeItem.setIsSelected(true);
            }

        }
    }

    setActivePageItems(navigationItemsInVisibleRange: IVanillaReaderNavigationItem[]) {
        if (navigationItemsInVisibleRange && navigationItemsInVisibleRange.length > 0) {

            this._treeViewPages?.deselectAllItems();

            let selectedItems: TreeViewItem[] = [];

            navigationItemsInVisibleRange.forEach((item) => {
                if (item.collectionType === 'PAGE_LIST') {
                    let locator = item.locator;
                    if (locator) {
                        let treeViewItems = this._treeViewPages?.filterTreeItemsByItemData((navItem: IVanillaReaderNavigationItem) => {
                            return navItem.locator === item.locator;
                        });

                        if (treeViewItems && treeViewItems.length > 0) {
                            selectedItems.push(treeViewItems[0]);
                        }
                    }
                }
            });

            selectedItems.forEach((activeTreeItem: TreeViewItem) => {
                activeTreeItem.setIsSelected(true);
            });

        }
    }

    /*
    *
    * PRIVATE METHODS
    *
    * */

    private _mapNavigationItemDataToTreeItemData(navigationItems: IVanillaReaderNavigationItem[]): ITreeViewItemSourceData<IVanillaReaderNavigationItem>[] {
        const map = (navigationItem: IVanillaReaderNavigationItem): ITreeViewItemSourceData => {
            return {
                data: navigationItem,
                id: navigationItem.locator || '',
                textContent: navigationItem.title,
                children: navigationItem.children && navigationItem.children.map(map),
            };
        };

        return navigationItems.map(map);
    }

    private _selectCurrentActiveItem() {
        if (this._treeViewToc) {
            this._treeViewToc.activeItem?.setIsActive(true, true);
        }
    }

}
