/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {BrowserDetector, LengthUnit} from '@colibrio/colibrio-reader-framework/colibrio-core-base';

import {
    IPublicationStyleFontSet,
    IPublicationStyleTextAlignmentOptions,
    TextAlignment,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';
import {VanillaUiSettingsUpdatedCallback} from '../VanillaReaderUI/VanillaReaderUiModel';
import {
    IVanillaReaderTtsOptions,
    IVanillaReaderUiColorPalette,
    VanillaReaderRendererNames
} from '../VanillaReader/VanillaReaderModel';
import {VanillaReaderAppUiDefaults} from './VanillaReaderAppUiDefaults';
import {VanillaReaderUiDialog} from './VanillaReaderUiDialog';
import {IVanillaReaderUiOptionsData} from './VanillaReaderUiModel';
import {WebSpeechUtils} from "../utils/WebSpeechUtils";

/*
* # VanillaReaderUiDialogSettings
*
* ## RESPONSIBILITIES
*
* This dialog component displays the settings for the Imbiblio Reader in an HTML form.
*
* VIEW STATE
*
* View state for this component is stored in the HTML form element. When the VanillaReader is unloaded
* the state is serialized using the `serializeViewState` method which returns `IVanillaReaderAppUiSettings` data
* that can be stored, and restored when the app is restarted.
*
* The component is also used in the VanillaReaderUI class to lookup settings, such as active renderer etc, at runtime.
* It is in other words used as application view state at runtime for some settings.
*
* ## RELATED TYPES
* - VanillaReaderUiDialogSettingsController
*
* */

export class VanillaReaderUiDialogSettings extends VanillaReaderUiDialog<void> {
    private _form: HTMLElement;
    private _rendererSelect: HTMLSelectElement;
    private _fontScaleSelect: HTMLSelectElement;
    private _lineHeightSelect: HTMLSelectElement;
    private _pageMarginHorizontalSelect: HTMLSelectElement;
    private _pageMarginVerticalSelect: HTMLSelectElement;
    private _paletteSelect: HTMLSelectElement;
    private _fontSetSelect: HTMLSelectElement;
    private _textAlignSelect: HTMLSelectElement;
    private _playbackRateSelect: HTMLSelectElement;
    private _playbackVoiceSelect: HTMLSelectElement;
    private _useAccessibilityModeOption: HTMLInputElement;
    private _reducedMotionOption: HTMLInputElement;
    private _aspectRatioOption: HTMLInputElement;
    private _ttsVoiceNote: HTMLElement;
    private _activeRendererTypeName: VanillaReaderRendererNames | undefined;
    private _activeAspectRatioOption: boolean;
    private _activeReducedMotionOption: boolean;
    private _activePlaybackVoiceOption: string | undefined;
    private _activeAccessibilityModeOption: boolean;
    private _playbackVoiceLocalOnly: boolean = false;
    private _playbackVoiceRemoteOnly: boolean = false;
    private _playbackVoiceLanguageOnly: boolean = false;
    private _playbackVoiceNoSupport: boolean = false;

    private _settingsUpdatedCallback: VanillaUiSettingsUpdatedCallback | undefined;

    private _bodyStyle = `
        <style>
            #vanilla-reader__dialog-settings__form__mediaplayer-tts-voice__note {
                display: none;
            }     

 .vanilla-reader__dialog-settings__form__field{
        background-color:red;
        // border: 1px solid #a77b02;
    }
        </style>  
    `
    private _bodyTemplate = `
            <form id="vanilla-reader__dialog-settings__form">
            
                <label for="vanilla-reader__dialog-settings__form__a11y-mode" tabindex="-1"><span class="vanilla-reader-icon" aria-hidden="true">accessibility</span> Accessibility UI mode</label>                
                <input type="checkbox" data-optionname="useAccessibilityMode" class="vanilla-reader__dialog-settings__form__field" id="vanilla-reader__dialog-settings__form__a11y-mode">
                
                <hr>
                
                <label for="vanilla-reader__dialog-settings__form__font-size" tabindex="-1"><span class="vanilla-reader-icon" aria-hidden="true">format_size</span> Font size (%)</label>                
                <select data-optionname="fontSizeScaleFactor" class="vanilla-reader__dialog-settings__form__field" id="vanilla-reader__dialog-settings__form__font-size">
                    <option value="1" selected="selected">100%</option>
                    <option value="1.2">120%</option>
                    <option value="1.3">130%</option>
                    <option value="1.4">140%</option>
                    <option value="1.5">150%</option>
                    <option value="1.6">160%</option>
                    <option value="1.7">170%</option>
                    <option value="1.8">180%</option>
                    <option value="1.9">190%</option>
                    <option value="2">200%</option>
                    <option value="2.2">225%</option>
                    <option value="2.5">250%</option>
                    <option value="2.7">275%</option>
                    <option value="3">300%</option>
                    <option value="3.5">350%</option>
                    <option value="4">400%</option>
                </select>

                <label for="vanilla-reader__dialog-settings__form__line-height" tabindex="-1"><span class="vanilla-reader-icon" aria-hidden="true">format_line_spacing</span> Line height (%)</label>
                <select data-optionname="lineHeightScaleFactor" class="vanilla-reader__dialog-settings__form__field" id="vanilla-reader__dialog-settings__form__line-height">
                    <option value="1" selected="selected">100%</option>
                    <option value="1.2">120%</option>
                    <option value="1.3">130%</option>
                    <option value="1.4">140%</option>
                    <option value="1.5">150%</option>
                    <option value="1.6">160%</option>
                    <option value="1.7">170%</option>
                    <option value="1.8">180%</option>
                    <option value="1.9">190%</option>
                    <option value="2">200%</option>
                    <option value="2.2">225%</option>
                    <option value="2.5">250%</option>
                </select>

                <label for="vanilla-reader__dialog-settings__form__font-set" tabindex="-1"><span class="vanilla-reader-icon" aria-hidden="true">title</span> Publication font</label>
                <select data-optionname="fontSet" class="vanilla-reader__dialog-settings__form__field" id="vanilla-reader__dialog-settings__form__font-set">
                    <option value="default" selected="selected">Publication Default</option>
                    <option value="EasyReading">EasyReading</option>
                    <option value="sansSerif">All Sans</option>
                </select>

                <label for="vanilla-reader__dialog-settings__form__text-align" tabindex="-1"><span class="vanilla-reader-icon" aria-hidden="true">format_align_left</span> Text align</label>
                <select data-optionname="textAlign" class="vanilla-reader__dialog-settings__form__field" id="vanilla-reader__dialog-settings__form__text-align">
                    <option value="default" selected="selected">Publication Default</option>
                    <option value="left">Left</option>                    
                    <option value="justify">Justify</option>                    
                    <option value="right">Right</option>                    
                    <option value="center">Center</option>                    
                </select>
                                
                <label for="vanilla-reader__dialog-settings__form__palette" tabindex="-1"><span class="vanilla-reader-icon" aria-hidden="true">format_color_fill</span> Publication color
                    theme</label>
                <select data-optionname="palette" class="vanilla-reader__dialog-settings__form__field" id="vanilla-reader__dialog-settings__form__palette">
                    <option value="default" selected="selected">Publication Default</option>
                    <option value="Dark" data-usedarkmode>Dark</option>
                    <option value="Paperlike" >Paperlike</option>
                    <option value="ContrastDark" data-usedarkmode>Dark contrast</option>
                </select>
                
                <hr>
                
                <label for="vanilla-reader__dialog-settings__form__margin-vertical" tabindex="-1"><span class="vanilla-reader-icon" aria-hidden="true">margin</span> Vertical page margin
                    (%)</label>
                <select data-optionname="pageMarginHorizontal" class="vanilla-reader__dialog-settings__form__field" id="vanilla-reader__dialog-settings__form__margin-vertical">
                    <option value="2">2 px</option>
                    <option value="6" selected="selected">6 px</option>
                    <option value="8">8 px</option>
                    <option value="10">10 px</option>
                    <option value="12">12 px</option>
                    <option value="14">14 px</option>
                    <option value="16">16 px</option>
                    <option value="18">18 px</option>
                    <option value="20">20 px</option>
                </select>

                <label for="vanilla-reader__dialog-settings__form__margin-horizontal" tabindex="-1"><span class="vanilla-reader-icon" aria-hidden="true" style="transform: rotate(-90deg);">margin</span> Horizontal page
                    margin (%)</label>
                <select data-optionname="pageMarginVertical" class="vanilla-reader__dialog-settings__form__field" id="vanilla-reader__dialog-settings__form__margin-horizontal">
                    <option value="2">2 px</option>
                    <option value="6" selected="selected">6 px</option>
                    <option value="8">8 px</option>
                    <option value="10">10 px</option>
                    <option value="12">12 px</option>
                    <option value="14">14 px</option>
                    <option value="16">16 px</option>
                    <option value="18">18 px</option>
                    <option value="20">20 px</option>
                </select>

                <hr>

                <label for="vanilla-reader__dialog-settings__form__renderer" tabindex="-1"><span class="vanilla-reader-icon" aria-hidden="true">auto_stories</span> Reading mode</label>
                <select data-optionname="renderer" class="vanilla-reader__dialog-settings__form__field" id="vanilla-reader__dialog-settings__form__renderer">
                    <option value="__responsive__" selected="selected">Adapt to screen</option>
                    <option value="FlipBookRenderer">Flipbook</option>
                    <option value="StackRenderer">Stack</option>
                    <option value="SinglePageSwipeRenderer">Swipe - Single page</option>
                    <option value="SpreadSwipeRenderer">Swipe - Spread</option>                    
                    <option value="SingleDocumentScrollRenderer">Scroll</option>
                </select>
                               
                <hr>

                <label for="vanilla-reader__dialog-settings__form__mediaplayer-playbackrate" tabindex="-1"><span class="vanilla-reader-icon" aria-hidden="true">speed</span> Audio playback rate (%)</label>
                <select data-optionname="mediaPlayerPlaybackRate" class="vanilla-reader__dialog-settings__form__field" id="vanilla-reader__dialog-settings__form__mediaplayer-playbackrate">                                       
                    <option value="0.4">40%</option>
                    <option value="0.6">60%</option>
                    <option value="0.8">80%</option>
                    <option value="1" selected="selected">100%</option>
                    <option value="1.2">120%</option>
                    <option value="1.3">130%</option>
                    <option value="1.4">140%</option>
                    <option value="1.5">150%</option>
                    <option value="1.6">160%</option>
                    <option value="1.7">170%</option>
                    <option value="1.8">180%</option>
                    <option value="1.9">190%</option>
                    <option value="2">200%</option>
                    <option value="2.2">225%</option>
                    <option value="2.5">250%</option>
                    <option value="3">300%</option>
                    <option value="3.5">350%</option>
                    <option value="4">400%</option>
                    <option value="4.5">450%</option>
                </select>
                
                <label for="vanilla-reader__dialog-settings__form__mediaplayer-tts-voice" tabindex="-1"><span class="vanilla-reader-icon" aria-hidden="true">record_voice_over</span> TTS Voice</label>
                <select data-optionname="mediaPlayerTtsVoice" class="vanilla-reader__dialog-settings__form__field" id="vanilla-reader__dialog-settings__form__mediaplayer-tts-voice"></select>
                <p id="vanilla-reader__dialog-settings__form__mediaplayer-tts-voice__note">
                    NOTE:<br>
                    Selecting TTS voice may not work properly on the browser that you are using. 
                </p>
                <hr>
                
                <label for="vanilla-reader__dialog-settings__form__aspect-ratio" tabindex="-1"><span class="vanilla-reader-icon" aria-hidden="true">aspect_ratio</span> Ignore default aspect ratio</label>
                <input type="checkbox" data-optionname="aspect" class="vanilla-reader__dialog-settings__form__field" id="vanilla-reader__dialog-settings__form__aspect-ratio">
                
                <hr>
                
                <label for="vanilla-reader__dialog-settings__form__motion" tabindex="-1"><span class="vanilla-reader-icon" aria-hidden="true">animation</span> Reduce UI motion</label>
                <input type="checkbox" data-optionname="motion" class="vanilla-reader__dialog-settings__form__field" id="vanilla-reader__dialog-settings__form__motion">
            </form>`;

    constructor(
        hostElement: HTMLElement,
        initialState?: IVanillaReaderUiOptionsData,
        insertPosition: InsertPosition = 'afterbegin',
    ) {
        super(hostElement, 'vanilla-reader__dialog-settings', 'Settings', insertPosition);

        this._dialogElement.insertAdjacentHTML(insertPosition, this._bodyStyle);
        this._dialogBodyElement.innerHTML = this._bodyTemplate;

        this._form = this._hostDocument.getElementById('vanilla-reader__dialog-settings__form')! as HTMLFormElement;
        this._pageMarginHorizontalSelect = this._hostDocument.getElementById('vanilla-reader__dialog-settings__form__margin-horizontal')! as HTMLSelectElement;
        this._pageMarginVerticalSelect = this._hostDocument.getElementById('vanilla-reader__dialog-settings__form__margin-vertical')! as HTMLSelectElement;
        this._rendererSelect = this._hostDocument.getElementById('vanilla-reader__dialog-settings__form__renderer')! as HTMLSelectElement;
        this._fontSetSelect = this._hostDocument.getElementById('vanilla-reader__dialog-settings__form__font-set')! as HTMLSelectElement;
        this._fontScaleSelect = this._hostDocument.getElementById('vanilla-reader__dialog-settings__form__font-size')! as HTMLSelectElement;
        this._lineHeightSelect = this._hostDocument.getElementById('vanilla-reader__dialog-settings__form__line-height')! as HTMLSelectElement;
        this._textAlignSelect = this._hostDocument.getElementById('vanilla-reader__dialog-settings__form__text-align')! as HTMLSelectElement;
        this._paletteSelect = this._hostDocument.getElementById('vanilla-reader__dialog-settings__form__palette')! as HTMLSelectElement;
        this._playbackRateSelect = this._hostDocument.getElementById('vanilla-reader__dialog-settings__form__mediaplayer-playbackrate')! as HTMLSelectElement;
        this._playbackVoiceSelect = this._hostDocument.getElementById('vanilla-reader__dialog-settings__form__mediaplayer-tts-voice')! as HTMLSelectElement;
        this._useAccessibilityModeOption = this._hostDocument.getElementById('vanilla-reader__dialog-settings__form__a11y-mode')! as HTMLInputElement;
        this._reducedMotionOption = this._hostDocument.getElementById('vanilla-reader__dialog-settings__form__motion')! as HTMLInputElement;
        this._aspectRatioOption = this._hostDocument.getElementById('vanilla-reader__dialog-settings__form__aspect-ratio')! as HTMLInputElement;
        this._ttsVoiceNote = this._hostDocument.getElementById('vanilla-reader__dialog-settings__form__mediaplayer-tts-voice__note')! as HTMLElement;
        this._activeAspectRatioOption = this._aspectRatioOption.checked;
        this._activeAccessibilityModeOption = this._useAccessibilityModeOption.checked;
        this._activeReducedMotionOption = this._reducedMotionOption.checked;
        this._activePlaybackVoiceOption = undefined;

        if(BrowserDetector.isOS("macOS") && BrowserDetector.isBrowser("Safari")) {
            this._playbackVoiceNoSupport = true;
        }

        this._populateTtsVoicesList().catch(console.warn);

        this._setup();

        if (initialState) {
            this.setViewState(initialState);
        }
    }

    public get activeRendererTypeName(): string | undefined {
        return this._activeRendererTypeName;
    }

    public get selectedRendererTypeName(): string {
        return this._rendererSelect.selectedOptions[0].value.toString() as VanillaReaderRendererNames;
    }

    public get activeAspectRatioOption(): boolean {
        return this._activeAspectRatioOption;
    }

    public get activePlaybackRateOption(): number {
        return parseFloat(this._playbackRateSelect.selectedOptions[0].value);
    }

    public get activeReducedMotionOption(): boolean {
        return this._activeReducedMotionOption;
    }

    public get activeAccessibilityModeOption(): boolean {
        return this._activeAccessibilityModeOption;
    }

    onSettingsUpdated(callback: VanillaUiSettingsUpdatedCallback) {
        this._settingsUpdatedCallback = callback;
    }

    setSelectedRendererOption(rendererName: string) {
        this._setSelectedItemInSelectList(this._rendererSelect, rendererName);
        this._activeRendererTypeName = rendererName as VanillaReaderRendererNames;
    }

    setRendererAspectRatioOption(ignoreAspectRatio: boolean = false) {
        this._activeAspectRatioOption = ignoreAspectRatio;
        this._aspectRatioOption.checked = ignoreAspectRatio;
    }

    setUseReducedMotionOption(useReducedMotion: boolean) {
        this._reducedMotionOption.checked = useReducedMotion;
        this._activeReducedMotionOption = useReducedMotion;
    }

    setUseAccessibilityMode(useAccessibilityMode: boolean) {
        this._useAccessibilityModeOption.checked = useAccessibilityMode;
        this._activeAccessibilityModeOption = useAccessibilityMode;
    }

    setSelectedTtsVoiceOption(voiceName: string) {
        this._setSelectedItemInSelectList(this._playbackVoiceSelect, voiceName, 'Default', true);
        this._activePlaybackVoiceOption = this._playbackVoiceSelect.selectedOptions[0].getAttribute('data-name') || undefined;
    }

    public get activeAspectTtsVoiceOption(): string | undefined {
        return this._activePlaybackVoiceOption;
    }

    setViewState(settings: IVanillaReaderUiOptionsData) {
        let publicationStyleOptions = settings.publicationStyleOptions;
        // The two variables below are forced (!) to be not-undefined as they are set in the config file VanillaReaderUiModel.ts
        let pageMarginVertical = publicationStyleOptions?.pageMargins?.top ?
            publicationStyleOptions?.pageMargins?.top :
            VanillaReaderAppUiDefaults.options.publicationStyleOptions?.pageMargins?.top!;
        let pageMarginHorizontal = publicationStyleOptions?.pageMargins?.left ?
            publicationStyleOptions?.pageMargins?.left :
            VanillaReaderAppUiDefaults.options.publicationStyleOptions?.pageMargins?.left!;

        if (pageMarginHorizontal && this._pageMarginHorizontalSelect.selectedOptions[0].value !== pageMarginVertical?.value.toString()) {
            this._setSelectedItemInSelectList(this._pageMarginHorizontalSelect, pageMarginHorizontal?.value.toString());
        }

        if (pageMarginVertical && this._pageMarginVerticalSelect.selectedOptions[0].value !== pageMarginVertical?.value.toString()) {
            this._setSelectedItemInSelectList(this._pageMarginVerticalSelect, pageMarginVertical?.value.toString());
        }

        if (publicationStyleOptions?.fontSizeScaleFactor && this._fontScaleSelect.selectedOptions[0].value !== publicationStyleOptions.fontSizeScaleFactor.toString()) {
            this._setSelectedItemInSelectList(this._fontScaleSelect, publicationStyleOptions.fontSizeScaleFactor.toString());
        }

        if (settings.activePublicationFontSetName && this._fontSetSelect.selectedOptions[0].value !== settings.activePublicationFontSetName) {
            this._setSelectedItemInSelectList(this._fontSetSelect, settings.activePublicationFontSetName);
        }

        if (settings.activePublicationPaletteName && this._paletteSelect.selectedOptions[0].value !== settings.activePublicationPaletteName) {
            this._setSelectedItemInSelectList(this._paletteSelect, settings.activePublicationPaletteName);
        }

        if (publicationStyleOptions?.lineHeightScaleFactor && this._lineHeightSelect.selectedOptions[0].value !== publicationStyleOptions.lineHeightScaleFactor.toString()) {
            this._setSelectedItemInSelectList(this._lineHeightSelect, publicationStyleOptions.lineHeightScaleFactor.toString());
        }

        if (publicationStyleOptions?.textAlignment && this._textAlignSelect.selectedOptions[0].value !== publicationStyleOptions.textAlignment.defaultAlignment) {
            this._setSelectedItemInSelectList(this._textAlignSelect, publicationStyleOptions.textAlignment.defaultAlignment);
        }

        if (settings.mediaPlayerPlaybackRate && this._playbackRateSelect.selectedOptions[0].value !== settings.mediaPlayerPlaybackRate.toString()) {
            this._setSelectedItemInSelectList(this._playbackRateSelect, settings.mediaPlayerPlaybackRate.toString());
        }

        if (settings.activeRendererTypeName && this._rendererSelect.selectedOptions[0].value !== settings.activeRendererTypeName) {
            this._setSelectedItemInSelectList(this._rendererSelect, settings.activeRendererTypeName);
        }

        if(settings.ttsOptions && settings.ttsOptions.voiceName) {
            this._setSelectedItemInSelectList(this._playbackVoiceSelect, settings.ttsOptions.voiceName, 'Default', true);
        }

        this._useAccessibilityModeOption.checked = settings.useAccessibilityMode ?? this._useAccessibilityModeOption.checked;
        this._aspectRatioOption.checked = settings.ignoreAspectRatio ?? this._aspectRatioOption.checked;
        this._activeAspectRatioOption = this._aspectRatioOption.checked;
        this._activePlaybackVoiceOption = this._playbackVoiceSelect.selectedOptions[0]?.getAttribute('data-name') || undefined;

        if (settings.useReducedMotion !== undefined) {
            this._reducedMotionOption.checked = settings.useReducedMotion;
            this._activeReducedMotionOption = this._reducedMotionOption.checked;
        }

    }

    serializeSettingsState(): IVanillaReaderUiOptionsData {

        let pageMarginHorizontal = parseFloat(this._pageMarginHorizontalSelect.selectedOptions[0].value);
        let pageMarginVertical = parseFloat(this._pageMarginVerticalSelect.selectedOptions[0].value);
        let fontScaleFactor = parseFloat(this._fontScaleSelect.selectedOptions[0].value);
        let activeFontSetName = this._fontSetSelect.selectedOptions[0].value === 'default' ?
            undefined :
            this._fontSetSelect.selectedOptions[0].value;
        let activePaletteName = this._paletteSelect.selectedOptions[0].value === 'default' ?
            undefined :
            this._paletteSelect.selectedOptions[0].value;
        let activeUiPaletteName = activePaletteName || 'Default';
        let activeRendererTypeName = this._rendererSelect.selectedOptions[0].value.toString() as VanillaReaderRendererNames;
        let lineHeightScaleFactor = parseFloat(this._lineHeightSelect.selectedOptions[0].value);
        let textAlignValue = this._textAlignSelect.selectedOptions[0].value.toString() as TextAlignment;
        let mediaPlayerPlaybackRate = parseFloat(this._playbackRateSelect.selectedOptions[0].value);
        let useReducedMotion = this._reducedMotionOption.checked;
        let ignoreAspectRatio = this._aspectRatioOption.checked;
        let useDarkMode: boolean;
        let activePaletteData: IVanillaReaderUiColorPalette | undefined;
        let activeFontSetData: IPublicationStyleFontSet | undefined;

        let textAlignOption: IPublicationStyleTextAlignmentOptions | null;

        // If textAlign is set to `"default"` we should set the `textAlignOption` to null in order to tell
        // the ReaderPublication to use the default publication style.
        if (textAlignValue.toString() === 'default') {
            textAlignOption = null;
        } else {
            textAlignOption = {
                defaultAlignment: textAlignValue,
                preserved: [],
            };
        }

        // Normally the `!` operator is a big no-no. In this case though we know that the `VanillaReaderAppUiDefaults`
        // has a set value for all it's members.
        switch (activePaletteName) {
            case 'Dark':
                activePaletteData = VanillaReaderAppUiDefaults.options.palettes!.Dark;
                break;
            case 'Paperlike':
                activePaletteData = VanillaReaderAppUiDefaults.options.palettes!.Paperlike;
                break;
            case 'ContrastDark':
                activePaletteData = VanillaReaderAppUiDefaults.options.palettes!.ContrastDark;
                break;
            default:
                activePaletteData = undefined;
        }

        useDarkMode = !activePaletteData ? false : activePaletteData.uiIsDark;

        switch (activeFontSetName) {
            case 'sansSerif':
                activeFontSetData = VanillaReaderAppUiDefaults.options.fontSets!.SansSerif;
                break;
            case 'EasyReading':
                activeFontSetData = VanillaReaderAppUiDefaults.options.fontSets!.EasyReading;
                break;
            default:
                activeFontSetData = undefined;
        }

        let ttsOptions:  IVanillaReaderTtsOptions | undefined
        if(this._playbackVoiceSelect.selectedOptions.length > 0) {
            ttsOptions = {voiceName: ''};
            ttsOptions.voiceName = this._playbackVoiceSelect.selectedOptions[0].getAttribute('data-name') || undefined;
        }

        return {
            dateCreated: Date.now(),
            useReducedMotion,
            activeUiPaletteName,
            activePublicationFontSetName: activeFontSetName,
            activePublicationPaletteName: activePaletteName,
            activeRendererTypeName,
            ignoreAspectRatio,
            useDarkMode,
            mediaPlayerPlaybackRate,
            publicationStyleOptions: {
                fontSizeScaleFactor: fontScaleFactor,
                fontSet: activeFontSetData,
                lineHeightScaleFactor: lineHeightScaleFactor,
                textAlignment: textAlignOption,
                pageMargins: {
                    top: {
                        unit: LengthUnit.PERCENT,
                        value: pageMarginVertical,
                    },
                    bottom: {
                        unit: LengthUnit.PERCENT,
                        value: pageMarginVertical,
                    },
                    left: {
                        unit: LengthUnit.PERCENT,
                        value: pageMarginHorizontal,
                    },
                    right: {
                        unit: LengthUnit.PERCENT,
                        value: pageMarginHorizontal,
                    },
                },
                palette: activePaletteData,
            },
            fontSets: VanillaReaderAppUiDefaults.options.fontSets,
            palettes: VanillaReaderAppUiDefaults.options.palettes,
            ttsOptions,
            useAccessibilityMode: this._useAccessibilityModeOption.checked

        };

    }

    /*
    *
    * PRIVATE METHODS
    *
    * */

    private _setup() {
        this._form.addEventListener<'change'>('change', this._event_change);
    }

    private _sendUpdateOptionsIntent(settings: IVanillaReaderUiOptionsData) {
        if (this._settingsUpdatedCallback) {
            this._settingsUpdatedCallback(settings);
        }
    }

    private _setSelectedItemInSelectList(list: HTMLSelectElement, optionValueToSelect: string, fallbackValueToSelect?: string, fuzzyMatch: boolean = false) {
        let optionToSelect: HTMLOptionElement | undefined;
        let fallbackOption: HTMLOptionElement | undefined;

        for (let i = 0; list.options.length > i; i++) {
            let option = list.options[i];
            let optionToSelectFound: boolean = false;
            let fallbackOptionFound: boolean = false;

            option.selected = false;

            if(!fuzzyMatch) {
                optionToSelectFound = option.value === optionValueToSelect;
                if(fallbackValueToSelect) {
                    fallbackOptionFound = option.value === fallbackValueToSelect;
                }
            } else {
                optionToSelectFound = option.value.includes(optionValueToSelect);
                if(fallbackValueToSelect) {
                    fallbackOptionFound = option.value.includes(fallbackValueToSelect);
                }
            }

            if(optionToSelectFound) {
                optionToSelect = option;
            }

            if(fallbackOptionFound) {
                fallbackOption = option;
            }

        }

        if (optionToSelect) {
            optionToSelect.selected = true;
        } else if(fallbackOption) {
            fallbackOption.selected = true;
        }
    }

    private async _populateTtsVoicesList() {

        if(this._playbackVoiceNoSupport) {
            this._ttsVoiceNote.style.display = 'flex';
        }

        let voicesCollection = await WebSpeechUtils.fetchTtsVoices();
        let languages = Array.from(voicesCollection.languages);

        languages.sort((a, b)=>{
            if(a[0] < b[0]) {
                return -1;
            } else {
                return 1;
            }
            return 0;
        });

        let option = document.createElement('option');
        option.textContent = `Default`;
        this._playbackVoiceSelect.appendChild(option);

        languages.forEach((lang: [string, SpeechSynthesisVoice[]])=>{
            let langName = lang[0];
            let voices = lang[1];

            let optionLangGroup = document.createElement('optgroup');
            optionLangGroup.label = langName.toUpperCase();
            this._playbackVoiceSelect.appendChild(optionLangGroup);

            if(this._playbackVoiceLanguageOnly) {

                let option = document.createElement('option');
                option.textContent = 'Default';
                option.setAttribute('data-lang', voices[0].lang);
                option.setAttribute('data-name', voices[0].name);
                option.setAttribute('data-voiceuri', voices[0].voiceURI);
                option.setAttribute('data-localservice', voices[0].localService.toString());
                option.setAttribute('data-default', voices[0].default.toString());

                optionLangGroup.appendChild(option);

            } else {
                for(let i = 0; i < voices.length; i++) {

                    if(this._playbackVoiceLocalOnly && !voices[i].localService) {
                        continue;
                    }

                    if(this._playbackVoiceRemoteOnly && voices[i].localService) {
                        continue;
                    }

                    let option = document.createElement('option');
                    option.textContent = voices[i].name + ' (' + voices[i].lang + ')';

                    option.setAttribute('data-lang', voices[i].lang);
                    option.setAttribute('data-name', voices[i].name);
                    option.setAttribute('data-voiceuri', voices[i].voiceURI);
                    option.setAttribute('data-localservice', voices[i].localService.toString());
                    option.setAttribute('data-default', voices[i].default.toString());

                    optionLangGroup.appendChild(option);
                }
            }

            this._playbackVoiceSelect.appendChild(optionLangGroup);

        });
    }


    /*
    *
    * EVENT HANDLERS
    *
    * */

    private _event_change = (_ev: Event) => {
        let formState = this.serializeSettingsState();
        this._sendUpdateOptionsIntent(formState);
    };

}
