/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IPublicationMetadataItem} from '@colibrio/colibrio-reader-framework/colibrio-core-publication-base';
import {
} from '../VanillaReader/VanillaReaderEventBus';
import {
    IVanillaReaderPublicationData,
    IVanillaReaderShelfItemData,
    VanillaOpenFileCallback, VanillaOpenUrlCallback, VanillaPublicationDataCallback
} from '../VanillaReader/VanillaReaderModel';
import {defaultCoverImageAsBase64} from './VanillaReaderDefaultBookCoverImageData';
import {VanillaReaderUiDialog} from './VanillaReaderUiDialog';

/**
 * # VanillaReaderUiDialogOpenFile
 *
 * ## RESPONSIBILITIES
 *
 * ## RELATED TYPES
 * -VanillaReaderUiDialogOpenFileController
 *
 */

export class VanillaReaderUiDialogOpenFile extends VanillaReaderUiDialog<void> {
    private _inputFile: HTMLElement;
    private _shelfList: HTMLElement;

    private _isOffline: boolean = false;
    private _fileSelectedCallback: VanillaOpenFileCallback | undefined;
    private _listItemSelectedCallback: VanillaOpenUrlCallback | undefined;
    private _shelfListItemClickedCallback: VanillaPublicationDataCallback | undefined;
    private _listItemDeleteCacheCallback: VanillaPublicationDataCallback | undefined;

    private _bodyStyle = `        
        <style>                    
            #vanilla-reader__dialog-file h2 {
                margin-top: 0.5em;
                margin-bottom: 1.5em;
                color: var(--vanilla-reader__color__ui__foreground-dark);      
            }

            #vanilla-reader__dialog-file h3 {
                margin-top: 1.2em;
                margin-bottom: 1.2em;
                color: var(--vanilla-reader__color__ui__foreground-dark);      
            }

            #vanilla-reader__dialog-file h4 {
                color: var(--vanilla-reader__color__ui__foreground-dark);                
            }

            #vanilla-reader__dialog-file__header {
                text-align: center;
                margin-top: 24px;
                margin-bottom: 72px;
                color: var(--vanilla-reader__color__ui__foreground-dark);
            }
            
            #vanilla-reader__dialog-file__header__logo {
                font-size: 36px;
                display: block;               
            }
            
            #icon {
                display: inline;
                width: 64px;
                height: 64px;
                position: relative;
                top: 16px;
            }
            
            #vanilla-reader__dialog-file__header__logo span {
                display: inline-block;                       
                border: 2px solid #ffb55a;
                border-radius: 50%;
                background-color: #fff5db;
                padding: 0.2em;
                width: 48px;
                height: 48px;
             }
             
            #vanilla-reader__dialog-file__header__tagline {                
                margin-top: 16px;
                font-size: 1em;                                
            }            
            
            #vanilla-reader__dialog-file__header__tagline a {
                color: var(--vanilla-reader__color__ui__foreground-dark);
            } 
            
            #vanilla-reader__dialog-file[role=dialog] {  
                padding: 0!important;
                top: 0!important;
                bottom: 0!important;
                right: 0!important;
                left: 0!important;
                height: 100%!important;
                width: 100%!important;
                max-width: initial!important;
                border-radius: 0!important;
                border: 0!important;
            }
            
            #vanilla-reader__dialog-file .vanilla-reader-ui__dialog__body {
                flex-grow: 2;
                margin: 16px;
                margin-top: 0;
            }
            
            @media only screen and (max-device-width: 480px) {         
                #vanilla-reader__dialog-file .vanilla-reader-ui__dialog__body {
                    margin: 8px;
                }
                
                .vanilla-reader__card {
                   padding: 8px;
                   box-shadow: none;             
                }
                
                .vanilla-reader__dialog-file__book-data {
                    word-break: break-word;
                }
                
                .vanilla-reader__dialog-file__form__shelf-list__item {                
                    box-shadow: rgb(0 0 0 / 19%) 1px 1px 4px;
                    background-color: rgba(256,256,256,0.2);
                }
            }
        
            #vanilla-reader__dialog-file .vanilla-reader-ui__dialog__header__close-button {
                display: none;
            }

            #vanilla-reader__dialog-file + .vanilla-reader__modal-overlay {
                pointer-events: none;
            }
            
            #vanilla-reader__dialog-file__form {
                max-width: 600px;
                margin-left: auto;
                margin-right: auto;
            }
                              
            #vanilla-reader__dialog-file__form h3 {
                margin-top: 2em;
                margin-bottom: 2em;                
            }
            
            #vanilla-reader__dialog-file__file-input {
                border: 0;
                max-width: 100%;
            }
            
            #vanilla-reader__dialog-file__form__file-list {                                
                max-width: 600px;
                margin-left: auto;
                margin-right: auto;
            }

            .vanilla-reader__dialog-file__form__file-list__item {
                list-style: none;   
            }
                        
                        
            .vanilla-reader__dialog-file__form__file-list__item .vanilla-reader__dialog-file__book-title {
                font-weight: normal;   
            }
            
            #vanilla-reader__dialog-file__form__shelf-list {                                
                max-width: 600px;
                margin-left: auto;
                margin-right: auto;
                list-style: none;
            }
            
                        
            .vanilla-reader__dialog-file__form__shelf-list__item {      
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                align-content: space-between;
                min-height: 48px;
                border-radius: 8px;
                padding: 8px;
                box-shadow: rgb(0 0 0 / 20%) 0 0 4px;
                background-color: rgba(256, 256, 256, 0.1);
            }

            .vanilla-reader__dialog-file__form__shelf-list__item a {    
                display: flex;                
                padding: 8px;
                flex-grow: 2;
                align-items: flex-start;
            }
            
            #vanilla-reader__dialog-file__form__shelf-list .vanilla-reader__dialog-file__form__shelf-list__item .vanilla-reader__dialog-file__book-title {
                display: inline-block;
                margin: 0;                                
                pointer-events: none;
            }
                        
            .vanilla-reader__dialog-file__form__shelf-list__item a img {    
                display: inline-block;                
                width: 96px;
                height: auto;                
                pointer-events: none;
                box-shadow: #0000001f 2px 2px 3px 1px;
                border-radius: 4px;
            }
                        
            .vanilla-reader__dialog-file__form__shelf-list__item__button-remove {
                align-self: flex-start;  
                background-color: rgba(256, 256, 256, 0.1);                
            }
            
            .vanilla-reader__dialog-file__form__shelf-list__notice {
                font-size: small;
                margin-top: -1.5em;
            }
            
            #vanilla-reader__dialog-file .vanilla-reader-ui__dialog__footer {
                text-align: center;
                font-size: small;
                margin-top: 2em;
                margin-bottom: 2em;
            }

            #vanilla-reader__dialog-file .vanilla-reader-ui__dialog__footer a {
                color: var(--vanilla-reader__color__ui__foreground-dark);
            }      
            
            .vanilla-reader__dialog-file__book-title {
                pointer-events: none;
                font-size: 16px;
                font-weight: bold;                
            }
            
            .vanilla-reader__dialog-file__book-data {
                flex-direction: column;
                margin-left: 16px;
                pointer-events: none;
            }
            
            .vanilla-reader__dialog-file__book-a11y-info {
                pointer-events: auto;                        
            }

            .vanilla-reader__dialog-file__book-a11y-info summary {
                text-decoration: underline;
            }

            .vanilla-reader__dialog-file__book-a11y-info summary::marker {
                content: none;
            }

            .vanilla-reader__dialog-file__book-a11y-info h4 {
                opacity: 0.8;
            }
            
                        
        </style>`;

    private _bodyTemplate = `
        <div id="vanilla-reader__dialog-file__header">
            <h1 id="vanilla-reader__dialog-file__header__logo">Vanilla <img id="icon" aria-hidden="true" src="https://demo.colibrio.com/assets/icons/vr-icon.svg"> Reader</h1>
            <p id="vanilla-reader__dialog-file__header__tagline">A vanilla demo implementation of the<br>
                <a href="https://colibrio.com" title="Open the Colibrio Reader Framework homepage in a new window" target="_blank">
                        Colibrio Reader Framework
                </a>
            </p>        
        </div>
        <form id="vanilla-reader__dialog-file__form" class="vanilla-reader__card">
            <h2>Choose a file from your device</h2>
            <input type="file" id="vanilla-reader__dialog-file__file-input" value="Choose a book file" aria-label="Choose a book file">
        </form>

        <ul id="vanilla-reader__dialog-file__form__shelf-list" class="vanilla-reader__card">
            <h2>Bookshelf</h2>            
        </ul>

        <ul id="vanilla-reader__dialog-file__form__file-list" class="vanilla-reader__card">
            <h2>Open an online example book</h2>
            <h3>EPUB books</h3>
            <li class="vanilla-reader__dialog-file__form__file-list__item"><a tabindex="0" data-href="https://s3.eu-north-1.amazonaws.com/catalog.colibrio.com/Accessible_EPUB_3.epub"><h4 class="vanilla-reader__dialog-file__book-title">Accessible EPUB3</h4></a></li>
            <li class="vanilla-reader__dialog-file__form__file-list__item"><a tabindex="0" data-href="https://s3.eu-north-1.amazonaws.com/catalog.colibrio.com/NNELS_Accessible_Publishing_Best_Practices_June_2019.epub"><h4 class="vanilla-reader__dialog-file__book-title">NNELS Accessible Publishing Best Practices</h4></a></li>
            <li class="vanilla-reader__dialog-file__form__file-list__item"><a tabindex="0" data-href="https://s3.eu-north-1.amazonaws.com/open.catalog.colibrio.com/Opening-Eyes-onto-Inclusion-and-Diversity.epub"><h4 class="vanilla-reader__dialog-file__book-title">Opening Eyes onto Inclusion and Diversity</h4></a></li>
            <li class="vanilla-reader__dialog-file__form__file-list__item"><a tabindex="0" data-href="https://s3.eu-north-1.amazonaws.com/open.catalog.colibrio.com/Technical-Writing-Essentials.epub"><h4 class="vanilla-reader__dialog-file__book-title">Technical Writing Essentials</h4></a></li>
            <li class="vanilla-reader__dialog-file__form__file-list__item"><a tabindex="0" data-href="https://s3.eu-north-1.amazonaws.com/open.catalog.colibrio.com/Physical-Geology-2nd-Edition.epub"><h4 class="vanilla-reader__dialog-file__book-title">Physical Geology 2nd Edition</h4></a></li>
            <li class="vanilla-reader__dialog-file__form__file-list__item"><a tabindex="0" data-href="https://s3.eu-north-1.amazonaws.com/catalog.colibrio.com/design-with-fontforge_en-US.epub"><h4 class="vanilla-reader__dialog-file__book-title">Design with FontForge</h4></a></li>
            <li class="vanilla-reader__dialog-file__form__file-list__item"><a tabindex="0" data-href="https://s3.eu-north-1.amazonaws.com/catalog.colibrio.com/Jules-Verne-20000-Leagues-Under-the-Sea.epub"><h4 class="vanilla-reader__dialog-file__book-title">20000 Leagues Under the Sea</h4></a></li>
            <h4>Interactive</h4>
            <li class="vanilla-reader__dialog-file__form__file-list__item"><a tabindex="0" data-href="https://s3.eu-north-1.amazonaws.com/catalog.colibrio.com/FF001-Overdrive_UK_v1.1_sample.epub"><h5 class="vanilla-reader__dialog-file__book-title">FactFactory - Issue 1</h5></a></li>
            <li class="vanilla-reader__dialog-file__form__file-list__item"><a tabindex="0" data-href="https://s3.eu-north-1.amazonaws.com/catalog.colibrio.com/CircularFLO_v5_1_promo.epub"><h5 class="vanilla-reader__dialog-file__book-title">CircularFLO</h5></a></li>
            <h4>Narrated</h4>                
            <li class="vanilla-reader__dialog-file__form__file-list__item"><a tabindex="0" data-href="https://s3.eu-north-1.amazonaws.com/catalog.colibrio.com/The+Jungle+Book-MO.epub"><h5 class="vanilla-reader__dialog-file__book-title">The Jungle Book</h5></a></li>
            <li class="vanilla-reader__dialog-file__form__file-list__item"><a tabindex="0" data-href="https://s3.eu-north-1.amazonaws.com/catalog.colibrio.com/FF001-Overdrive_UK_v1.1_sample.epub"><h5 class="vanilla-reader__dialog-file__book-title">FactFactory - Issue 1</h5></a></li>
            <h3>Audiobooks</h3>       
            <li class="vanilla-reader__dialog-file__form__file-list__item"><a tabindex="0" data-href="https://s3.eu-north-1.amazonaws.com/catalog.colibrio.com/crimson.lpf"><h4 class="vanilla-reader__dialog-file__book-title">The Crimson Fairy Book</h4></a></li>

        </ul>`;

    constructor(
        _hostElement: HTMLElement,
        insertPosition: InsertPosition = 'afterbegin',
        showShelf: boolean = false,
        private _shelfItems: IVanillaReaderShelfItemData[] = [],
        private _shelfSortOrderDescending: boolean = true,
    ) {
        super(_hostElement, 'vanilla-reader__dialog-file', '', insertPosition);

        this._dialogBodyElement.innerHTML = this._bodyTemplate;
        this._dialogElement.insertAdjacentHTML('afterbegin', this._bodyStyle);
        this._inputFile = this._dialogElement.querySelector<HTMLElement>('#vanilla-reader__dialog-file__file-input')!;
        this._shelfList = this._dialogElement.querySelector<HTMLElement>('#vanilla-reader__dialog-file__form__shelf-list')!;

        this._dialogFooterElement.innerHTML = `
                Powered by the 
                <a href="https://colibrio.com" title="Open the Colibrio Reader Framework homepage in a new window" target="_blank">
                    Colibrio Reader Framework
                </a>`;

        this._inputFile.addEventListener('change', (ev) => {
            this._event_change(ev);
        });

        let listItems = this._dialogElement.querySelectorAll<HTMLElement>('.vanilla-reader__dialog-file__form__file-list__item a');
        listItems.forEach((item: HTMLElement) => {
            item.addEventListener('click', this._event_listItemClick);
            item.addEventListener('keyup', (ev)=>{
                if (ev.key === 'Enter' || ev.key === 'Space') {
                    this._event_listItemClick(ev);
                }
            });

        });

        if (showShelf) {
            this.sortShelfItemsOnReadingDate(this._shelfSortOrderDescending);
            this._renderShelfItems();
        } else {
            this._shelfList.style.display = 'none';
        }

    }

    onFileSelected(callback: VanillaOpenFileCallback) {
        this._fileSelectedCallback = callback;
    }

    onFileListItemClicked(callback: VanillaOpenUrlCallback) {
        this._listItemSelectedCallback = callback;
    }

    onShelfListItemClicked(callback: VanillaPublicationDataCallback) {
        this._shelfListItemClickedCallback = callback;
    }

    onFileListItemDeletePublicationResourcesButtonClicked(callback: VanillaPublicationDataCallback) {
        this._listItemDeleteCacheCallback = callback;
    }

    public get isOffline(): boolean {
        return this._isOffline;
    }

    public set isOffline(value: boolean) {
        this._isOffline = value;
    }

    sortShelfItemsOnReadingDate(descending: boolean = true) {

        this._shelfItems.sort((a, b) => {
            let aTimestamp: number = a.latestReadingPositionData?.timestamp || 0;
            let bTimestamp: number = b.latestReadingPositionData?.timestamp || 0;

            if (aTimestamp > bTimestamp) {
                return -1;
            } else if (aTimestamp < bTimestamp) {
                return 1;
            } else {
                return 0;
            }

        });

        if (!descending) {
            this._shelfItems.reverse();
        }
    }

    updateShelfItems(items: IVanillaReaderShelfItemData[]) {
        this._shelfItems = items;
        this.sortShelfItemsOnReadingDate(this._shelfSortOrderDescending);
        this._emptyShelf();
        this._renderShelfItems();
    }

    /*
    *
    * PRIVATE METHODS
    *
    * */

    _emptyShelf() {
        if (this._shelfList.childElementCount > 0) {
            let listItems = this._shelfList.querySelectorAll('li');

            listItems.forEach((item) => {
                this._shelfList.removeChild(item);
            });
        }
    }

    _renderShelfItems() {
        this._shelfItems.forEach((item) => {
            let authorMetadata: IPublicationMetadataItem | undefined = item.metadata?.all.find((metadataItem: IPublicationMetadataItem) => {
                return metadataItem.property.name === 'creator';
            });

            let accessibilitySummaryMetadata: IPublicationMetadataItem | undefined = item.metadata?.all.find((metadataItem: IPublicationMetadataItem) => {
                return metadataItem.property.name === 'accessibilitySummary';
            });

            let listItem = document.createElement('li');
            listItem.className = 'vanilla-reader__dialog-file__form__shelf-list__item';
            listItem.innerHTML = `
               <a tabindex="0" data-href="${item.fileSourceUri}" data-hasofflinedata="${item.hasOfflineData}" data-filename="${item.fileName}">
                    <img src="data:;base64,${item.coverImageAsBase64 || defaultCoverImageAsBase64}">
                    <div class="vanilla-reader__dialog-file__book-data">                    
                        <h3  class="vanilla-reader__dialog-file__book-title" aria-label="Book title">${item.title?.slice(0, 150)}</h3>
                        <p class="vanilla-reader__dialog-file__book-author">by ${authorMetadata?.content.value || 'Unknown'}</p>
                        <details class="vanilla-reader__dialog-file__book-a11y-info">
                            <summary>Accessibility information</summary>
                            <h4>Summary</h4>
                            <p>
                                ${accessibilitySummaryMetadata?.content.value || 'No information available'}
                            </p>                            
                        </details>
                </div>
               </a> 
               <button class="vanilla-reader__dialog-file__form__shelf-list__item__button-remove" title="Remove from device" data-cachekey="${item.fileName}">delete</button>`;

            let anchorElement = listItem.querySelector('a')! as HTMLElement;
            anchorElement.addEventListener('click', this._event_shelfListItemClick);
            anchorElement.addEventListener('keyup', (ev)=>{
                if (ev.key === 'Enter' || ev.key === 'Space') {
                    this._event_shelfListItemClick(ev);
                }
            });

            let deleteButton = listItem.querySelector('button')! as HTMLElement;
            deleteButton.addEventListener('click', this._event_listItemDeleteCacheClick);

            this._shelfList.appendChild(listItem);
        });
    }

    /*
    *
    * EVENT HANDLERS
    *
    * */

    private _event_listItemClick = (ev: Event) => {
        if (this._listItemSelectedCallback) {
            let url = (ev.target as HTMLElement).getAttribute('data-href');
            if (url) {
                this._listItemSelectedCallback(url);
            } else {
                console.warn(`List item ${ev.target} has no data-href attribute.`);
            }
        }
    };

    private _event_shelfListItemClick = (ev: Event) => {
        if (this._shelfListItemClickedCallback) {
            let fileName = (ev.target as HTMLElement).getAttribute('data-filename');
            if (fileName) {
                let itemData = this._getShelfItemByFileName(fileName);
                if (itemData) {
                    this._shelfListItemClickedCallback(itemData);
                } else {
                    console.warn(`List item ${ev.target} has no related shelfItem data.`);
                }
            } else {
                console.warn(`List item ${ev.target} has no data-filename attribute.`);
            }
        }
    };

    private _event_listItemDeleteCacheClick = (ev: MouseEvent) => {
        if (this._listItemDeleteCacheCallback) {
            let key = (ev.target as HTMLElement).getAttribute('data-cachekey');
            if (key) {
                let shelfItemToDelete = this._getShelfItemByFileName(key);
                if (shelfItemToDelete) {
                    this._listItemDeleteCacheCallback(shelfItemToDelete);
                } else {
                    console.warn(`List item ${ev.target} has no related shelfItem data.`);
                }
            } else {
                console.warn(`List item ${ev.target} has no data-cachekey attribute.`);
            }
        }
    };

    private _event_change = (ev: Event) => {
        const filelist = (ev.target as HTMLInputElement).files;
        if (filelist && this._fileSelectedCallback) {
            let file = filelist[0];
            if (file) {
                this._fileSelectedCallback(file);
            }
        }
    };

    private _getShelfItemByFileName(fileName: string): IVanillaReaderPublicationData | undefined {
        return this._shelfItems.find((item: IVanillaReaderPublicationData) => {
            return item.fileName === fileName;
        });
    }
}
