/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IVanillaReaderUI} from "./IVanillaReaderUI";
import {VanillaReaderUiMenuMediaPlayerController} from "./VanillaReaderUiMenuMediaPlayerController";
import {IVanillaSyncMediaPlaybackStateData} from "../VanillaReader/VanillaReaderModel";

export interface VanillaReaderUiMediaSessionsController extends IVanillaReaderUI, VanillaReaderUiMenuMediaPlayerController {}

/**
 * # VanillaReaderUiMediaSessionsController
 *
 * ## RESPONSIBILITIES
 * This controller mixing class handles media playback related events from the `VanillaReader` class.
 *
 * Just as all other controller mixin classes, this class is used to add functionality to the `VanillaReaderUi` class
 * in a "modular" way. Read more about this in the `UI Components and Controller Mixins` in the README.md file.
 *
 * ## RELATED TYPES
 * - VanillaReaderUi
 * - VanillaReaderUiMenuMediaPlayerController
 * - MediaSession (https://developer.mozilla.org/en-US/docs/Web/API/MediaSession)
 *
 */
export class VanillaReaderUiMediaSessionsController {

    /*
    *
    * Media Session event setup to support background media playback actions from the lockscreen etc.
    * Works for Audiobooks and EPUBs that have Media Overlays.
    *
    * Check out the `_vanillaReaderEvent_mediaPlayerPlaybackStateChanged`, `_vanillaReaderEvent_mediaPlayerPlaybackPositionChanged` and
    * `_vanillaReaderEvent_mediaPlayerSeekCompleted` for more mediaSession related stuff.
    *
    * */

    _setUpMediaSessionActionHandlers() {

        if (window.navigator.mediaSession) {
            window.navigator.mediaSession.setActionHandler('nexttrack', (_actionDetails) => {
                this.seekNext();
            });

            window.navigator.mediaSession.setActionHandler('previoustrack', (_actionDetails) => {
                this.seekPrevious();
            });

            window.navigator.mediaSession.setActionHandler('pause', (_actionDetails) => {
                this.pausePlayback();
            });

            window.navigator.mediaSession.setActionHandler('play', (_actionDetails) => {
                this.startPlayback();
            });


            window.navigator.mediaSession.setActionHandler('seekto', (actionDetails) => {
                if (actionDetails.seekTime) {
                    this.navigateToTimelinePosition(actionDetails.seekTime);
                }
            });

            window.navigator.mediaSession.setActionHandler('seekforward', (_actionDetails) => {
                this.seekForward();
            });

            window.navigator.mediaSession.setActionHandler('seekbackward', (_actionDetails) => {
                this.seekBackward();
            });
        }
    }

    onMediaPlayerPlaybackPositionChanged(state: IVanillaSyncMediaPlaybackStateData) {
        if(state.publicationDurationMs && state.position) {
            window.navigator.mediaSession.setPositionState({
                position: state.position / 1000,
                duration: state.publicationDurationMs / 1000,
                playbackRate: state.rate,
            });
        }

    }

    onMediaPlayerPlaybackStateChanged(state: IVanillaSyncMediaPlaybackStateData) {

        if (window.navigator.mediaSession) {
            let isPaused: boolean = state.isPaused || false;

            if (isPaused) {
                window.navigator.mediaSession.playbackState = 'paused';
            } else {
                window.navigator.mediaSession.playbackState = 'playing';
            }

        }
    }

    onMediaPlayerSeekCompleted(state: IVanillaSyncMediaPlaybackStateData) {
        let segmentTitle = state.navigationItems ? state.navigationItems[0].title : 'Untitled';
        window.navigator.mediaSession.metadata = new MediaMetadata({
            title: segmentTitle,
            album: this.initialPublicationData?.title,
            artwork: [{
                src: `data:;base64,${this.initialPublicationData?.coverImageAsBase64}`
            }]
        });

    }
}