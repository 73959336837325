/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {Dexie} from 'dexie';
import {IVanillaReaderOptionsData} from '../VanillaReader/VanillaReaderModel';
import {IVanillaReaderUiOptionsData} from '../VanillaReaderUI/VanillaReaderUiModel';
import {IVanillaReaderOptionsDataStore} from './IVanillaReaderOptionsDataStore';

/*
* # VanillaReaderIndexedDbDataStore
*
* ## RESPONSIBILITIES
*
* This is an implementation of the `IVanillaReaderDataStore` interface that uses IndexedDb. IndexedDb is the preferred
* way to store web app data, However since its APIs are asynchronous it is not possible to use to store state when
* the application is closed by the system, or when it is put in the background on mobile devices.
*
* I suggest using it in combination with the `VanillaReaderLocalStorageDataStore` to get both a fail-safe temp storage,
* as well as a long-term storage.
*
* Checkout the `IVanillaReaderDataStore` interface documentation for more general information.
*
* */

export class VanillaReaderOptionsIndexedDbDataStore extends Dexie implements IVanillaReaderOptionsDataStore {

    private _vanillaReaderOptionsData: Dexie.Table<IVanillaReaderOptionsData, number>;
    private _vanillaReaderUiOptionsData: Dexie.Table<IVanillaReaderUiOptionsData, number>;

    constructor(dbName: string = 'options.vanillareader.colibrio.com', persist: boolean = true) {
        super(dbName);

        if (persist && navigator.storage && navigator.storage.persist) {
            navigator.storage.persist().then((canPersist) => {
                if (canPersist) {
                    console.log('VanillaReaderSettingsIndexedDbDataStore will persist data.');
                } else {
                    console.log('VanillaReaderSettingsIndexedDbDataStore will NOT persist data.');
                }
            }).catch(console.warn);
        }

        this.version(1).stores({
            vanillaReaderOptionsData: '_id, dateCreated',
            vanillaReaderUiOptionsData: '_id, dateCreated',
        });

        this._vanillaReaderOptionsData = this.table('vanillaReaderOptionsData');
        this._vanillaReaderUiOptionsData = this.table('vanillaReaderUiOptionsData');
    }

    async fetchVanillaReaderOptionsData(): Promise<IVanillaReaderOptionsData | undefined> {
        return this._vanillaReaderOptionsData.toCollection().last();
    }

    async setVanillaReaderOptionsData(state: IVanillaReaderOptionsData): Promise<void> {
        // There should only be one
        let existingOptionCount = await this._vanillaReaderOptionsData.count();
        if (existingOptionCount > 1) {
            await this._vanillaReaderOptionsData.clear();
        }

        state._id = 1;
        await this._vanillaReaderOptionsData.put(state, 1);
    }

    async clearVanillaReaderOptionsData(): Promise<void> {
        await this._vanillaReaderOptionsData.clear();
    }

    async fetchVanillaReaderUiOptionsData(): Promise<IVanillaReaderUiOptionsData | undefined> {
        return this._vanillaReaderUiOptionsData.toCollection().last();
    }

    async setVanillaReaderUiOptionsData(state: IVanillaReaderUiOptionsData): Promise<void> {
        // There should only be one
        let existingOptionCount = await this._vanillaReaderUiOptionsData.count();
        if (existingOptionCount > 1) {
            await this._vanillaReaderUiOptionsData.clear();
        }
        state._id = 1;
        await this._vanillaReaderUiOptionsData.put(state, 1);
    }

    async clearVanillaReaderUiOptionsData(): Promise<void> {
        return this._vanillaReaderUiOptionsData.clear();
    }
}
