/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import { LengthUnit } from '@colibrio/colibrio-reader-framework/colibrio-core-base';
import {
    IMouseEngineEventData,
    IPointerEngineEventData,
    IPublicationStyleOptions, ITransformData,
    NavigationCollectionType,

} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';

import {
    IVanillaReaderAppEvent_APP_UI_THEME_CHANGED,
    IVanillaReaderAppEvent_APP_WINDOW_RESIZED,
    IVanillaReaderAppEvent_BOOKMARK_READINGPOSITION_INTENT,
    IVanillaReaderAppEvent_BOOKMARK_TEXTLOCATION_INTENT,
    IVanillaReaderAppEvent_DOCUMENT_LANDMARKS_FETCH_INTENT,
    IVanillaReaderAppEvent_HIGHLIGHT_TEXTLOCATION_INTENT,
    IVanillaReaderAppEvent_NAVIGATION_INTENT, IVanillaReaderAppEvent_PUBLICATION_CLOSE_INTENT,
    IVanillaReaderAppEvent_PUBLICATION_SEARCH_INTENT,
    IVanillaReaderAppEvent_REDUCED_MOTION_OPTION_CHANGE_INTENT,
    IVanillaReaderAppEvent_RENDERER_CHANGE_ASPECT_RATIO_INTENT,
    IVanillaReaderAppEvent_RENDERER_CHANGE_INTENT,
    IVanillaReaderAppEvent_RENDERER_CHANGED,
    IVanillaReaderAppEvent_VIEW_REFRESH_INTENT,
    VanillaReaderAppEvents,
    VanillaReaderEventBus as VanillaReaderEventBus
} from '../VanillaReader/VanillaReaderEventBus';
import {
    IVanillaReaderHighlightData,
    IVanillaReaderLandmarkData,
    IVanillaReaderNavigationCollection,
    IVanillaReaderNavigationItem,
    IVanillaReaderNavigationTreeData,
    IVanillaReaderPublicationData,
    IVanillaReaderPublicationOptionsData,
    IVanillaReaderPublicationStateData,
    IVanillaReaderReadingPositionData,
    IVanillaReaderUiColorPalette,
    IVanillaReaderVisibleRangeData,
    VanillaReaderNavigationType,
    VanillaReaderRendererNames,
} from '../VanillaReader/VanillaReaderModel';
import { IVanillaReaderOptionsDataStore } from '../VanillaReaderDataStore/IVanillaReaderOptionsDataStore';
import { IVanillaReaderPublicationDataStore } from '../VanillaReaderDataStore/IVanillaReaderPublicationDataStore';
import { VanillaReaderAppUiDefaults } from './VanillaReaderAppUiDefaults';
import { VanillaReaderUiButtonMenu } from './VanillaReaderUiButtonMenu';
import { VanillaReaderUiButtonNext } from './VanillaReaderUiButtonNext';
import { VanillaReaderUiButtonPrevious } from './VanillaReaderUiButtonPrevious';
import { VanillaReaderUiDialogBookmarks } from './VanillaReaderUiDialogBookmarks';
import { VanillaReaderUiDialogContents } from './VanillaReaderUiDialogContents';
import { VanillaReaderUiDialogFootnote } from './VanillaReaderUiDialogFootnote';
import { VanillaReaderUiDialogHelp } from './VanillaReaderUiDialogHelp';
import { VanillaReaderUiDialogHighlight } from './VanillaReaderUiDialogHighlight';
import { VanillaReaderUiDialogHighlights } from './VanillaReaderUiDialogHighlights';
import { VanillaReaderUiDialogImageViewer } from './VanillaReaderUiDialogImageViewer';
import { VanillaReaderUiDialogOpenFile } from './VanillaReaderUiDialogOpenFile';
import { VanillaReaderUiDialogSearch } from './VanillaReaderUiDialogSearch';
import { VanillaReaderUiDialogSettings } from './VanillaReaderUiDialogSettings';
import { VanillaReaderUiFabHighlight } from './VanillaReaderUiFabHighlight';
import { VanillaReaderUiFabMediaPlayer } from './VanillaReaderUiFabMediaPlayer';
import { VanillaReaderUiMenuMediaPlayer } from './VanillaReaderUiMenuMediaPlayer';
import { VanillaReaderUiMenu } from './VanillaReaderUiMenu';
import { VanillaReaderUiToaster } from './VanillaReaderUiToaster';
import { VanillaReaderUiDialogActions } from "./VanillaReaderUiDialogUIActions";
import { VanillaReaderUiKeyboardShortcutController } from "./VanillaReaderUiKeyboardShortcutController";
import { IVanillaReaderUiOptionsData } from "./VanillaReaderUiModel";
import { VanillaReaderUiMenuMediaPlayerController } from "./VanillaReaderUiMenuMediaPlayerController";
import { IVanillaReaderUI } from "./IVanillaReaderUI";
import { VanillaReaderUiMenuController } from "./VanillaReaderUiMenuController";
import { VanillaReaderUiMediaSessionsController } from "./VanillaReaderUiMediaSessionsController";
import { VanillaReaderUiDialogHighlightController } from "./VanillaReaderUiDialogHighlightController";
import { VanillaReaderUiDialogHighlightsController } from "./VanillaReaderUiDialogHighlightsController";
import { VanillaReaderUiDialogSettingsController } from "./VanillaReaderUiDialogSettingsController";
import { VanillaReaderUiVanillaReaderEventController } from "./VanillaReaderUiVanillaReaderEventController";
import { VanillaReaderUiDialogBookmarksController } from "./VanillaReaderUiDialogBookmarksController";
import { VanillaReaderUiDialogOpenFileController } from "./VanillaReaderUiDialogOpenFileController";
import { VanillaReaderUiDialogFootnoteController } from "./VanillaReaderUiDialogFootnoteController";
import { VanillaReaderUiDialogController } from "./VanillaReaderUiDialogController";
import { VanillaReaderUiDialogImageViewerController } from "./VanillaReaderUiDialogImageViewerController";
import { VanillaReaderUiDialogSearchController } from "./VanillaReaderUiDialogSearchController";
import { ICoordinates } from "../utils/ICoordinates";
import { VanillaReaderUiFabHighlightController } from "./VanillaReaderUiFabHighlightController";
import { VanillaReaderUiFabMediaPlayerController } from "./VanillaReaderUiFabMediaPlayerController";
import { VanillaReaderUiDialogContentsController } from "./VanillaReaderUiDialogContentsController";
import { VanillaReaderUiDialogUiActionsController } from "./VanillaReaderUiDialogUiActionsController";
import { IVanillaReaderUiComponentController } from "./IVanillaReaderUiComponentController";
import { VanillaReaderUiButtonNextController } from "./VanillaReaderUiButtonNextController";
import { VanillaReaderUiButtonMenuController } from "./VanillaReaderUiButtonMenuController";
import { VanillaReaderUiButtonPreviousController } from "./VanillaReaderUiButtonPreviousController";
import { VanillaReaderUiPanZoomToolController } from "./VanillaReaderUiPanZoomToolController";
import { VanillaReaderUiPanZoomTool } from "./VanillaReaderUiPanZoomTool";


/**
 * # VanillaReaderUI
 *
 * ## RESPONSIBILITIES
 *
 * This is the main class, and also root container, for the Imbiblio Reader UI. It takes care of creating all child components,
 * and acts as the central "event manager" for all the various incoming reading system events, dispatched from the VanillaReader
 * class.
 *
 * This UI class, or any of its child components, knows anything about the Colibrio Reader Framework. All the Colibrio
 * logic is in the `VanillaReader` module of this project.
 *
 * ## COMPONENT AND CONTROLLER MIXINS
 *
 * The app UI is composed of components of code, defined by their scope of functionality. As an example, each of the
 * dialogs in the app have their own component, such as the `VanillaReaderUiDialogHighlights` class. Component behavior
 * is exposed to the `VanillaReaderUI` (this file) using callbacks, such as `menu.onNavigateNext` and `dialogHighlight.onAddHighlight.`
 *
 * To make this file easier to work with, each component has its own `VanillaReaderUi...Controller` class which separates
 * out component specific code from the main UI code. The controllers are added to the VanillaReaderUI class at runtime
 * using a mixin pattern approach (check out the `_applyUiControllerMixins` method).
 *
 * You can think of this strategy of code splitting using mixins as being similar to Partial Classes in C#.
 *
 * ## NOTE ON THE EVENT SYSTEM
 *
 * To keep a clear separation between the application logic in VanillaReader.ts and the GUI element logic
 * in this file, all communication between these two "components" are done using the VanillaReaderEventBus.ts.
 *
 * When the GUI has something it wants the VanillaReader (and the Colibrio Reader Framework) to do, it dispatches
 * a CustomEvent using the event bus. Likewise, when the VanillaReader updates its state it will dispatch an event
 * that tells the UI what data that has been changed.
 *
 * The general convention is that the VanillaReaderUI sends "intentions" for the VanillaReader to performs actions,
 * and that the VanillaReader responds with updated data.
 *
 *
 *
 *      |-----------------------|                   |-----------------------|
 *      |                       |   -- intent ->    |                       |
 *      |   VanillaReaderUI     |                   |     VanillaReader     |
 *      |                       |   <- update --    |                       |
 *      |-----------------------|                   |-----------------------|
 *
 *
 *
 * ## SWAPPABILITY
 *
 * Thanks to the very clear separation of concern, and thanks to the intent/update strategy, it will be easy to swap out
 * the `VanillaReaderUI` to any other frontend tech, such as React or Vue. Not that there is anything wrong with vanilla
 * HTML and CSS of course 🙂
 *
 */

export class VanillaReaderUI implements IVanillaReaderUI {

    vanillaReaderUIElement: HTMLElement;
    vanillaReaderViewElement: HTMLElement;
    vanillaReaderFabContainerElement: HTMLElement;
    vanillaReaderButtonContainerElement: HTMLElement;

    vanillaReaderAppUiDefaultSettings = VanillaReaderAppUiDefaults.options;
    publicationHeadingLandmarks: IVanillaReaderLandmarkData[] | undefined;
    publicationFootnoteLandmarks: IVanillaReaderLandmarkData[] | undefined;
    publicationFigureLandmarks: IVanillaReaderLandmarkData[] | undefined;
    navigationTreeData: IVanillaReaderNavigationTreeData | undefined = undefined;

    navigationHistory: IVanillaReaderReadingPositionData[] = [];

    menuMediaPlayer: VanillaReaderUiMenuMediaPlayer;
    dialogContents: VanillaReaderUiDialogContents;
    dialogSettings: VanillaReaderUiDialogSettings;
    dialogHighlight: VanillaReaderUiDialogHighlight;
    dialogOpenFile: VanillaReaderUiDialogOpenFile;
    dialogHighlights: VanillaReaderUiDialogHighlights;
    dialogBookmarks: VanillaReaderUiDialogBookmarks;
    dialogSearch: VanillaReaderUiDialogSearch;
    dialogImageViewer: VanillaReaderUiDialogImageViewer;
    dialogHelp: VanillaReaderUiDialogHelp;
    dialogFootnote: VanillaReaderUiDialogFootnote;
    dialogUiActions: VanillaReaderUiDialogActions;
    menuBottom: VanillaReaderUiMenu;
    toaster: VanillaReaderUiToaster;
    fabHighlight: VanillaReaderUiFabHighlight;
    fabMediaPlayer: VanillaReaderUiFabMediaPlayer;
    buttonPrevious: VanillaReaderUiButtonPrevious;
    buttonNext: VanillaReaderUiButtonNext;
    buttonMenu: VanillaReaderUiButtonMenu;
    panZoomTool: VanillaReaderUiPanZoomTool;

    isReaderViewNavigating: boolean = false;
    isReaderViewTransitioning: boolean = false;
    isInDarkMode: boolean = false;
    isInAccessibilityMode: boolean = false;
    initialPublicationOptionsData: IVanillaReaderPublicationOptionsData | undefined;
    mediaPlayerShouldStartPlaybackWhenReady: boolean = false;
    useReducedMotion: boolean = false;
    sendRefreshIntentAfterDialogClosed: boolean = false;
    useDeviceStorage: boolean = false;
    isReaderViewIsInPanZoomMode: boolean | undefined;

    initialPublicationData: IVanillaReaderPublicationData | undefined;
    initialReadingPosition: IVanillaReaderReadingPositionData | undefined;
    initialVanillaReaderUiOptions: IVanillaReaderUiOptionsData | undefined;

    activeSelection: { selector: string | undefined; isRange: boolean, selectionText: string } | undefined;
    latestSelectedSearchResultLocator: string | undefined = undefined;
    latestReaderViewPointerDownEventCoords: ICoordinates | undefined;
    latestReaderViewPointerUpEventCoords: ICoordinates | undefined;
    latestContentRangeData: IVanillaReaderVisibleRangeData | undefined;
    latestTimelinePosition: number | undefined = undefined;
    latestReadingPosition: IVanillaReaderReadingPositionData | undefined = undefined;
    currentActiveDialogTitle: string | undefined;
    activeTransformData: ITransformData | undefined;

    screenWakeLockSentinel: WakeLockSentinel | undefined;

    private _ownerDocument: Document;

    private _style = `
        <style>
            :root {
                font-size: 16px;

                /*
                * The color variables below define the Imbiblio Readers "theming system". These vars are used by all
                * UI components, such as the menu and the dialogs, as color values.
                * Check out the setUiTheme method to see how they are used in JavaScript to change app theme.
                */
                --vanilla-reader__color__reader__background-light: ${VanillaReaderAppUiDefaults.options.palettes!.Default.backgroundLight};
                --vanilla-reader__color__reader__background-dark: ${VanillaReaderAppUiDefaults.options.palettes!.Default.backgroundDark};
                --vanilla-reader__color__reader__foreground-light: ${VanillaReaderAppUiDefaults.options.palettes!.Default.foregroundLight};
                --vanilla-reader__color__reader__foreground-dark: ${VanillaReaderAppUiDefaults.options.palettes!.Default.foregroundDark};

                --vanilla-reader__color__ui__background-light: ${VanillaReaderAppUiDefaults.options.palettes!.Default.uiBackgroundLight};
                --vanilla-reader__color__ui__background-dark: ${VanillaReaderAppUiDefaults.options.palettes!.Default.uiBackgroundDark};
                --vanilla-reader__color__ui__foreground-light: ${VanillaReaderAppUiDefaults.options.palettes!.Default.uiForegroundLight};
                --vanilla-reader__color__ui__foreground-dark: ${VanillaReaderAppUiDefaults.options.palettes!.Default.uiForegroundDark};
                --vanilla-reader__color__ui__outline-light: ${VanillaReaderAppUiDefaults.options.palettes!.Default.uiOutlineLight};
                --vanilla-reader__color__ui__outline-dark: ${VanillaReaderAppUiDefaults.options.palettes!.Default.uiOutlineDark};
                --vanilla-reader__color__ui__accent: ${VanillaReaderAppUiDefaults.options.palettes!.Default.accent};
            }

            hr {
                min-width: 100%;
                height: 1px;
                border: 0;
                border-top: 1px solid var(--vanilla-reader__color__ui__outline-dark);
            }

            li {
                cursor: pointer;
                margin-top: 1em;
                margin-bottom: 1em;
                min-height: 40px;
            }

            li a {
                display: block;
            }

            label, input, select {
                display: block;
                margin: 1em;
                margin-left: 0;
                margin-right: 0;
            }

            input, select {
                font-size: 16px;
                border-radius: 12px;
                padding: 1em;
                border: 1px solid var(--vanilla-reader__color__ui__outline-dark);
            }

            select {
                min-width: 12em;
                max-width: min(100%,24em);
            }

            input[type=checkbox] {
                height: 40px;
                width: 40px;
                border-radius: 12px;
                /* remove saturation in order to better match with color themes */
                mix-blend-mode: luminosity;
            }

            input[type=range] {
                /* remove saturation in order to better match with color themes */
                mix-blend-mode: luminosity;
            }

            textarea {
                font-size: 16px;
            }

            button, input[type=button], input[type=submit] {
                font-family: Material Icons Round;
                display: flex;
                flex-direction: row;
                align-items: center;
                align-self: center;
                justify-content: space-around;
                margin: 0.5em;
                cursor: pointer;
                border: 0;
                border-radius: 12px;
                min-width: 48px;
                height: 48px;
                border: 1px solid var(--vanilla-reader__color__ui__outline-dark);
                background-color: var(--vanilla-reader__color__ui__background-light);
                color: var(--vanilla-reader__color__ui__foreground-dark);
            }

            button[disabled] {
                opacity: 0.5;
            }

            #vanilla-reader {
                background-color: var(--vanilla-reader__color__reader__background-light);
                color: var(--vanilla-reader__color__reader__foreground-dark);
                width: 100%;
                height: 100%;
                max-height: 100%;
                display: flex;
                flex-direction: column;
            }

            #vanilla-reader__reader-view {
                width: 100%;
                height: 100%;
                max-height: 100%;
                position: relative;
                display: flex;
                flex-direction: column;
            }

            #vanilla-reader__reader-view__loader {
                width: auto;
                height: auto;
                text-align: center;
                vertical-align: center;
                animation-name: spin;
                animation-duration: 5000ms;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
            }

            #vanilla-reader__fab-container {
                position: fixed;
                z-index: 1;
                display: flex;
                flex-direction: column;
                right: 24px;
                bottom: 24px;
                width: 48px;
                cursor: pointer;
            }

            #vanilla-reader__button-container {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                width: fit-content;
                align-self: center;
                margin-bottom: 16px;
            }

            .vanilla-reader__fab {
                display: none;
                pointer-events: none;
                opacity: 0;
                transition: opacity ease-in-out 0.2s;
                cursor: pointer;
                z-index: 1;
                box-shadow: 0 4px 8px rgba(0,0,0,0.2);
                background-color: var(--vanilla-reader__color__ui__background-dark);
                color: var(--vanilla-reader__color__ui__foreground-light);
                border-radius: 12px;
                width: 48px;
                height: 48px;
                font-size: x-large;
            }

            .vanilla-reader__fab__icon {
                pointer-events: none;
            }

            .vanilla-reader__bookmark-container {
                width: 48px;
                height: 48px;
                cursor: pointer;
            }

            .vanilla-reader__bookmark-container::before {
                content: "bookmark";
                font-family: "Material Icons Round"
            }

            .colibrio-reader-view-annotation-layer--right-spread .vanilla-reader__bookmark-container {
                right: 0;
                left: unset!important;
            }

            .colibrio-custom-page-content {
                pointer-events: none;
            }

            .vanilla-reader-icon {
                display: inline-block;
                font-family: Material Icons Round;
                font-weight: normal;
                color: var(--vanilla-reader__color__ui__foreground-dark);
                font-size: 24px;
                margin-right: 0.5em;
                vertical-align: middle;
            }

            .vanilla-reader__card {
                padding: 1em;
                border-radius: 12px;
                box-shadow: 0px 2px 8px rgb(0 0 0 / 17%);
            }


        </style>`;

    private _template = `
        <div id="vanilla-reader">
            <div id="vanilla-reader__reader-view" tabindex="0"></div>
            <div role="presentation" id="vanilla-reader__button-container" tabindex="0"></div>
            <div role="presentation" id="vanilla-reader__fab-container" tabindex="0"></div>
        </div>`;

    constructor(
        private _rootElement: HTMLElement,
        vanillaReaderPublicationDataStore: IVanillaReaderPublicationDataStore,
        vanillaReaderOptionsDataStore: IVanillaReaderOptionsDataStore,
        useDeviceStorage: boolean = false,
        shelfItems: IVanillaReaderPublicationData[] = [],
    ) {
        this.vanillaReaderOptionsDataStore = vanillaReaderOptionsDataStore;
        this.vanillaReaderPublicationDataStore = vanillaReaderPublicationDataStore;

        this.useDeviceStorage = useDeviceStorage;

        this.isMobileScreenSize = window.matchMedia('only screen and (max-width: 480px)').matches;

        this._ownerDocument = _rootElement.ownerDocument!;
        this._rootElement.innerHTML = this._template;
        this._rootElement.insertAdjacentHTML('afterbegin', this._style);
        this.vanillaReaderUIElement = this._ownerDocument.getElementById('vanilla-reader')!;
        this.vanillaReaderViewElement = this._ownerDocument.getElementById('vanilla-reader__reader-view')!;
        this.vanillaReaderButtonContainerElement = this._ownerDocument.getElementById('vanilla-reader__button-container')!;
        this.vanillaReaderFabContainerElement = this._ownerDocument.getElementById('vanilla-reader__fab-container')!;

        // This component uses output elements to display toast messages.
        this.toaster = new VanillaReaderUiToaster(this.vanillaReaderUIElement);
        // The bottom menu component
        this.menuBottom = new VanillaReaderUiMenu(this.vanillaReaderUIElement, 'beforeend', this);
        this.menuMediaPlayer = new VanillaReaderUiMenuMediaPlayer(this.menuBottom.getMediaPlayerContainerElement());
        this.dialogHighlight = new VanillaReaderUiDialogHighlight(this.vanillaReaderUIElement);
        this.dialogHighlights = new VanillaReaderUiDialogHighlights(this.vanillaReaderUIElement);
        this.dialogSettings = new VanillaReaderUiDialogSettings(this.vanillaReaderUIElement);
        this.dialogContents = new VanillaReaderUiDialogContents(this.vanillaReaderUIElement);
        this.dialogBookmarks = new VanillaReaderUiDialogBookmarks(this.vanillaReaderUIElement);
        this.dialogSearch = new VanillaReaderUiDialogSearch(this.vanillaReaderUIElement);
        this.dialogImageViewer = new VanillaReaderUiDialogImageViewer(this.vanillaReaderUIElement);
        this.dialogHelp = new VanillaReaderUiDialogHelp(this.vanillaReaderUIElement);
        this.dialogOpenFile = new VanillaReaderUiDialogOpenFile(this.vanillaReaderUIElement, 'afterbegin', this.useDeviceStorage, shelfItems);
        this.dialogFootnote = new VanillaReaderUiDialogFootnote(this.vanillaReaderUIElement);
        this.dialogUiActions = new VanillaReaderUiDialogActions(this.vanillaReaderUIElement);
        this.fabMediaPlayer = new VanillaReaderUiFabMediaPlayer(this.vanillaReaderFabContainerElement);
        this.fabHighlight = new VanillaReaderUiFabHighlight(this.vanillaReaderFabContainerElement);
        this.buttonPrevious = new VanillaReaderUiButtonPrevious(this.vanillaReaderButtonContainerElement, 'beforeend');
        this.buttonMenu = new VanillaReaderUiButtonMenu(this.vanillaReaderButtonContainerElement, 'beforeend');
        this.buttonNext = new VanillaReaderUiButtonNext(this.vanillaReaderButtonContainerElement, 'beforeend');
        this.panZoomTool = new VanillaReaderUiPanZoomTool(this.vanillaReaderFabContainerElement);


        // In order to get some code separation in VanillaReaderUI class, UI controller code is split up into mixin classes.
        this._applyUiControllerMixins([
            VanillaReaderUiKeyboardShortcutController,
            VanillaReaderUiMenuMediaPlayerController,
            VanillaReaderUiMenuController,
            VanillaReaderUiMediaSessionsController,
            VanillaReaderUiDialogController,
            VanillaReaderUiDialogUiActionsController,
            VanillaReaderUiDialogHighlightController,
            VanillaReaderUiDialogHighlightsController,
            VanillaReaderUiDialogBookmarksController,
            VanillaReaderUiDialogOpenFileController,
            VanillaReaderUiDialogSettingsController,
            VanillaReaderUiDialogFootnoteController,
            VanillaReaderUiDialogImageViewerController,
            VanillaReaderUiDialogSearchController,
            VanillaReaderUiDialogContentsController,
            VanillaReaderUiVanillaReaderEventController,
            VanillaReaderUiFabHighlightController,
            VanillaReaderUiFabMediaPlayerController,
            VanillaReaderUiButtonMenuController,
            VanillaReaderUiButtonNextController,
            VanillaReaderUiButtonPreviousController,
            VanillaReaderUiPanZoomToolController,
        ]);

        window.addEventListener('resize', this._uiEvent_windowResize.bind(this));
        window.addEventListener('keyup', this._uiEvent_uiKeyUp.bind(this));

        this._setUpMediaSessionActionHandlers();

        this._addVanillaReaderEventListeners();

        this._setup().catch(console.warn);

    }

    destroy() {
        window.removeEventListener('resize', this._uiEvent_windowResize.bind(this));
    }

    setReaderViewAsNotImportantForAccessibility() {
        this.vanillaReaderViewElement.ariaHidden = 'true';
    }

    setReaderViewAsImportantForAccessibility() {
        this.vanillaReaderViewElement.ariaHidden = 'false';
    }

    /*
    *
    * This method serializes the app settings and returns them to the caller.
    * This method is called in index.ts when the app saves the current state.
    *
    * */
    serializeToUiOptions(): IVanillaReaderUiOptionsData {
        let settingsState = this.dialogSettings?.serializeSettingsState();
        settingsState.showNarrationControls = this.menuMediaPlayer.isOpen();
        return settingsState;
    }

    /*
    *
    * setUiColorTheme takes in a IVanillaReaderColorPalette object and applies it by updating the CSS variables defined in the
    * VanillaReaderUI stylesheet.
    * Note that these colors are specific to the UI, the publication style is updated in the `setPublicationStyleOptions`
    * method.
    *
    * */
    setUiColorTheme(palette: IVanillaReaderUiColorPalette | undefined) {
        if (!palette) {
            // Normally the `!` operator is a big no-no. In this case though we know that the `VanillaReaderAppUiDefaults`
            // has a set value for all it's members.
            palette = VanillaReaderAppUiDefaults.options.palettes!.Default;
        }

        let uiStyle = this.vanillaReaderUIElement.style;

        !palette.backgroundDark || uiStyle.setProperty('--vanilla-reader__color__reader__background-dark', palette.backgroundDark);
        !palette.backgroundLight || uiStyle.setProperty('--vanilla-reader__color__reader__background-light', palette.backgroundLight);
        !palette.foregroundDark || uiStyle.setProperty('--vanilla-reader__color__reader__foreground-dark', palette.foregroundDark);
        !palette.foregroundLight || uiStyle.setProperty('--vanilla-reader__color__reader__foreground-light', palette.foregroundLight);
        !palette.uiBackgroundDark || uiStyle.setProperty('--vanilla-reader__color__ui__background-dark', palette.uiBackgroundDark);
        !palette.uiBackgroundLight || uiStyle.setProperty('--vanilla-reader__color__ui__background-light', palette.uiBackgroundLight);
        !palette.uiForegroundDark || uiStyle.setProperty('--vanilla-reader__color__ui__foreground-dark', palette.uiForegroundDark);
        !palette.uiForegroundLight || uiStyle.setProperty('--vanilla-reader__color__ui__foreground-light', palette.uiForegroundLight);
        !palette.uiOutlineDark || uiStyle.setProperty('--vanilla-reader__color__ui__outline-dark', palette.uiOutlineDark);
        !palette.uiOutlineLight || uiStyle.setProperty('--vanilla-reader__color__ui__outline-light', palette.uiOutlineLight);
        !palette.accent || uiStyle.setProperty('--vanilla-reader__color__ui__accent', palette.accent);

        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_APP_UI_THEME_CHANGED>(new CustomEvent<IVanillaReaderAppEvent_APP_UI_THEME_CHANGED>(VanillaReaderAppEvents.APP_UI_THEME_CHANGED, { detail: { colorPalette: palette } }))

    }

    /**
     *
     * This is a utility method for screen reader users. If the users gets lost in the interface this method will close
     * all modals and set focus back on the reading position.
     *
     * */
    closeAllDialogsAndFocusOnReadingPosition() {
        this.dialogSettings.close(true);
        this.dialogContents.close(true);
        this.dialogSearch.close(true);
        this.dialogHighlights.close(true);
        this.dialogBookmarks.close(true);
        this.dialogHighlight.close(true);
        this.dialogImageViewer.close(true);
        this.dialogHelp.close(true);
        this.dialogFootnote.close(true);
        this.dialogUiActions.close(true, false);
        // `menuBottom.hide` also focuses on reading position
        this.menuBottom.hide(this.useReducedMotion, true);
    }

    /**
     *
     * This is a utility method for screen reader users. It alerts the current reading position using a toast.
     *
     * */
    async announceReadingPosition() {
        if (this.latestContentRangeData) {

            let previousHeadingLandmark: IVanillaReaderLandmarkData | undefined = undefined;
            let timelinePos = this.latestTimelinePosition;
            let headings = this.publicationHeadingLandmarks;
            let heading: string = '';

            if (headings && timelinePos) {
                for (let i = headings.length - 1; i >= 0; i--) {
                    let heading: IVanillaReaderLandmarkData = headings[i];
                    if (heading.timelinePosition && heading.timelinePosition < timelinePos) {
                        previousHeadingLandmark = heading;
                        break;
                    }
                }
            }

            if (previousHeadingLandmark) {
                heading = `, Closest previous heading ${previousHeadingLandmark.textContent}`;
            }


            let tocItems: IVanillaReaderNavigationItem[] | undefined = this.latestContentRangeData.navItems.filter((item: IVanillaReaderNavigationItem) => {
                return item.collectionType === NavigationCollectionType.TOC;
            });

            let tocItemTitle = 'Unknown';

            if (tocItems && tocItems.length > 0) {
                tocItemTitle = tocItems[0].title;
            }

            let pageItems: IVanillaReaderNavigationItem[] | undefined = this.latestContentRangeData.navItems.filter((item: IVanillaReaderNavigationItem) => {
                return item.collectionType === NavigationCollectionType.PAGE_LIST;
            });

            let pageNumber = '';

            if (pageItems && pageItems.length > 0) {
                pageNumber = `, on page ${pageItems[0].title}`;
            }

            let readingProgression = 'Unknown';

            if (this.latestContentRangeData.readingProgression) {
                readingProgression = Math.round(this.latestContentRangeData.readingProgression * 100) + '%';
            }

            await this.toaster.toast(`You are at ${readingProgression} in ${this.initialPublicationData?.title}, section ${tocItemTitle} ${heading} ${pageNumber}`, true);
        }
    }

    // This method tries to find a `IVanillaReaderLandmarkData` object based on an HTML element's id attribute.
    getFootnoteLandmarkByElementId(id: string): IVanillaReaderLandmarkData | undefined {
        return this.publicationFootnoteLandmarks?.find((footnote: IVanillaReaderLandmarkData) => {
            return footnote.attributes.find((attributeData) => {
                return attributeData.localName === 'id' && attributeData.value === id;
            });
        });
    }

    /**
     *
     * This is a utility method for screen reader users. It navigates to the next heading in the publication contents,
     * similar to what the screen reader offers when browsing the web.
     *
     * */
    navigateToNextHeading() {
        let nextHeadingLandmark: IVanillaReaderLandmarkData | undefined = undefined;
        let timelinePos = this.latestTimelinePosition;
        let headings = this.publicationHeadingLandmarks;

        if (headings && timelinePos) {
            for (let i = 0; i < headings.length; i++) {
                let heading: IVanillaReaderLandmarkData = headings[i];
                if (heading.timelinePosition && heading.timelinePosition > timelinePos) {
                    nextHeadingLandmark = heading;
                    break;
                }
            }
        }

        if (nextHeadingLandmark && nextHeadingLandmark.locatorUrl) {
            this.navigateTo(nextHeadingLandmark.locatorUrl, true);
        } else {
            this.toaster.toast('No next heading').catch(console.warn);
        }

    }

    /**
     *
     * This is a utility method for screen reader users. It navigates to the previous heading in the publication contents,
     * similar to what the screen reader offers when browsing the web.
     *
     * */
    navigateToPreviousHeading() {
        let previousHeadingLandmark: IVanillaReaderLandmarkData | undefined = undefined;
        let timelinePos = this.latestTimelinePosition;
        let headings = this.publicationHeadingLandmarks;

        if (headings && timelinePos) {
            for (let i = headings.length - 1; i >= 0; i--) {
                let heading: IVanillaReaderLandmarkData = headings[i];
                if (heading.timelinePosition && heading.timelinePosition < timelinePos) {
                    previousHeadingLandmark = heading;
                    break;
                }
            }
        }

        if (previousHeadingLandmark && previousHeadingLandmark.locatorUrl) {
            this.navigateTo(previousHeadingLandmark.locatorUrl, true);
        } else {
            this.toaster.toast('No previous heading').catch(console.warn);
        }
    }

    /**
     *
     * `AccessibilityMode` is a "preset" configuration of sorts that is optimised for screen readers.
     * It turns on the `SingleDocumentScrollRenderer`, enlarges the fonts size and turns off animations.
     *
     **/
    enterAccessibilityMode() {
        let pageMargin = this.isMobileScreenSize ? 6 : 18;
        this.setPublicationStyleOptions({
            fontSizeScaleFactor: 2.5,
            lineHeightScaleFactor: 1.2,
            pageMargins: {
                left: {
                    value: pageMargin,
                    unit: LengthUnit.PERCENT,
                },
                right: {
                    value: pageMargin,
                    unit: LengthUnit.PERCENT,
                },
                top: {
                    unit: LengthUnit.PERCENT,
                    value: VanillaReaderAppUiDefaults.options.publicationStyleOptions!.pageMargins!.top.value,
                },
                bottom: {
                    unit: LengthUnit.PERCENT,
                    value: VanillaReaderAppUiDefaults.options.publicationStyleOptions!.pageMargins!.bottom.value,
                },
            },
        });

        this.setPublicationRenderer(VanillaReaderRendererNames.SCROLL, true);

        // We need to manually re-focus the AT on the current reading position after the renderer has changed.
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_RENDERER_CHANGED>(VanillaReaderAppEvents.RENDERER_CHANGED, (_evt) => {
            this.focusOnReadingPosition();
        }, true);

        this.setUseReducedMotion(true);

        this.menuMediaPlayer.open();

        this.dialogSettings.setUseAccessibilityMode(true);
        this.isInAccessibilityMode = true;

        this.toaster.toast('UI is in accessibility mode.', true).catch(console.warn);
    }

    exitAccessibilityMode() {
        this.setPublicationStyleOptions({
            fontSizeScaleFactor: 1,
            lineHeightScaleFactor: 1,
            pageMargins: {
                left: {
                    value: VanillaReaderAppUiDefaults.options.publicationStyleOptions!.pageMargins!.left.value,
                    unit: LengthUnit.PERCENT,
                },
                right: {
                    value: VanillaReaderAppUiDefaults.options.publicationStyleOptions!.pageMargins!.right.value,
                    unit: LengthUnit.PERCENT,
                },
                top: {
                    unit: LengthUnit.PERCENT,
                    value: VanillaReaderAppUiDefaults.options.publicationStyleOptions!.pageMargins!.top.value,
                },
                bottom: {
                    unit: LengthUnit.PERCENT,
                    value: VanillaReaderAppUiDefaults.options.publicationStyleOptions!.pageMargins!.bottom.value,
                },
            },
        });

        this.setPublicationRenderer(VanillaReaderRendererNames.RESPONSIVE, false);

        if (this.isMobileScreenSize) {
            this.setIgnoreAspectRatio(true);
        }

        // We need to manually re-focus the AT on the current reading position after the renderer has changed.
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_RENDERER_CHANGED>(VanillaReaderAppEvents.RENDERER_CHANGED, (_evt) => {
            this.focusOnReadingPosition();
        }, true);

        this.setUseReducedMotion(false);

        this.dialogSettings.setUseAccessibilityMode(false);
        this.isInAccessibilityMode = false;

        this.toaster.toast('UI is not accessibility mode.', true).catch(console.warn);
    }

    setPublicationRenderer(rendererTypeName: VanillaReaderRendererNames, ignoreAspectRatio?: boolean) {
        this.dialogSettings.setSelectedRendererOption(rendererTypeName);
        VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_RENDERER_CHANGE_INTENT>(VanillaReaderAppEvents.RENDERER_CHANGE_INTENT, {
            detail: { rendererName: rendererTypeName, ignoreAspectRatio },
        }));
    }

    setPublicationStyleOptions(publicationStyleOptions: IPublicationStyleOptions | undefined) {
        VanillaReaderEventBus.dispatchEvent(new CustomEvent(VanillaReaderAppEvents.PUBLICATION_STYLE_OPTIONS_CHANGE_INTENT, {
            detail: {
                styleOptions: publicationStyleOptions,
            },
        }));
    }

    setUseReducedMotion(useReducedMotion: boolean) {
        VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_REDUCED_MOTION_OPTION_CHANGE_INTENT>(VanillaReaderAppEvents.REDUCED_MOTION_OPTION_CHANGE_INTENT, {
            detail: { useReducedMotion: useReducedMotion ?? false },
        }));
        this.useReducedMotion = useReducedMotion ?? false;
    }

    setIgnoreAspectRatio(ignoreAspectRatio: boolean) {
        VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_RENDERER_CHANGE_ASPECT_RATIO_INTENT>(VanillaReaderAppEvents.RENDERER_CHANGE_ASPECT_RATIO_INTENT, {
            detail: { ignoreAspectRatio: ignoreAspectRatio },
        }));
    }

    highlightVisibleRange(showEditDialog: boolean = true) {
        if (this.latestContentRangeData && this.latestContentRangeData.locator) {
            let highlight: IVanillaReaderHighlightData = {
                locator: this.latestContentRangeData.locator.toUrl().toString(),
                color: VanillaReaderAppUiDefaults.highlightColorValues.yellow,
                selectionText: this.latestContentRangeData.visibleTextContent || '',
                comment: '',
            };
            this.dialogHighlight?.setState(highlight, true);

            // Should we show the highlight dialog so that the user can edit it directly, or should we just save it?
            if (showEditDialog) {
                this.dialogHighlight?.show();
            } else {
                this._onDialogHighlightAdd(highlight);
            }
        }
    }

    highlightReadingPosition(showEditDialog: boolean = true) {
        if (this.latestReadingPosition && this.latestReadingPosition.contentBlockData && this.latestReadingPosition.contentBlockData.locator) {

            let highlight: IVanillaReaderHighlightData = {
                locator: this.latestReadingPosition.contentBlockData.locator.toString(),
                color: VanillaReaderAppUiDefaults.highlightColorValues.yellow,
                selectionText: this.latestReadingPosition.contentBlockData.textContent,
                comment: '',
            };

            // Should we show the highlight dialog so that the user can edit it directly, or should we just save it?
            if (showEditDialog) {
                this.dialogHighlight?.setState(highlight, true);
                this.dialogHighlight?.show();
            } else {
                this._onDialogHighlightAdd(highlight);
            }
        }
    }

    /**
     * This method is a quirkfix to let Screen Reader users (primarily NVDA and JAWS) highlight text based on the contents
     * of their clipboard.
     *
     * The reason that we need this is because of the Screen Readers inability to scroll the document as it moves through
     * the document contents. This is a problem for us since we use the visible range as a means to know where the user
     * is reading, so without a change in the visible range we can't can add a correct highlight or bookmark.
     *
     * The way that the Screen Reader user will use this feature is as follows:
     *
     * 1. The user selects and copies the text that represents the location they want highlight or bookmark.
     * 2. The user uses some method to trigger a UI call to the VanillaReader, sending along the text that is currently
     * in the user's clipboard as well as the document index in the publication spine.
     * 3. This method uses the publication search to grab the first occurrence of the string in the specified document.
     * 4. The result is communicated back to the VanillaReaderUI using the `HIGHLIGHT_ADDED` / `BOOKMARK_ADDED` or
     * `HIGHLIGHT_TEXTLOCATION_FAILED` / `BOOKMARK_TEXTLOCATION_FAILED` events.
     *
     * */
    async highlightClipboardContents() {
        let clipboardContents = await navigator.clipboard.readText();

        if (!clipboardContents) {
            this.toaster.toast('Unable to create highlight. The clipboard is empty.', true).catch(console.warn);
            return;
        }

        if (this.latestReadingPosition?.documentIndex) {
            VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_HIGHLIGHT_TEXTLOCATION_INTENT>(VanillaReaderAppEvents.HIGHLIGHT_TEXTLOCATION_INTENT, {
                detail: {
                    documentIndex: this.latestReadingPosition.documentIndex, text: clipboardContents, highlightData: {
                        locator: '',
                        color: VanillaReaderAppUiDefaults.highlightColorValues.yellow,
                        selectionText: clipboardContents,
                        comment: '',
                    },
                },
            }));
        }
    }

    /**
     * This method is a quirkfix to let Screen Reader users (primarily NVDA and JAWS) highlight text based on the contents
     * of their clipboard.
     *
     * The reason that we need this is because of the Screen Readers inability to scroll the document as it moves through
     * the document contents. This is a problem for us since we use the visible range as a means to know where the user
     * is reading, so without a change in the visible range we can't can add a correct highlight or bookmark.
     *
     * The way that the Screen Reader user will use this feature is as follows:
     *
     * 1. The user selects and copies the text that represents the location they want highlight or bookmark.
     * 2. The user uses some method to trigger a UI call to the VanillaReader, sending along the text that is currently
     * in the user's clipboard as well as the document index in the publication spine.
     * 3. This method uses the publication search to grab the first occurrence of the string in the specified document.
     * 4. The result is communicated back to the VanillaReaderUI using the `HIGHLIGHT_ADDED` / `BOOKMARK_ADDED` or
     * `HIGHLIGHT_TEXTLOCATION_FAILED` / `BOOKMARK_TEXTLOCATION_FAILED` events.
     *
     * */
    async bookmarkClipboardContents() {
        let clipboardContents = await navigator.clipboard.readText();

        if (!clipboardContents) {
            this.toaster.toast('Unable to create bookmark. The clipboard is empty.', true).catch(console.warn);
            return;
        }

        if (this.latestReadingPosition?.documentIndex && clipboardContents) {
            VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_BOOKMARK_TEXTLOCATION_INTENT>(VanillaReaderAppEvents.BOOKMARK_TEXTLOCATION_INTENT, {
                detail: {
                    documentIndex: this.latestReadingPosition.documentIndex,
                    text: clipboardContents,
                },
            }));
        }
    }

    bookmarkReadingPosition() {
        VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_BOOKMARK_READINGPOSITION_INTENT>(VanillaReaderAppEvents.BOOKMARK_READINGPOSITION_INTENT));
    }

    focusOnReadingPosition() {
        VanillaReaderEventBus.dispatchEvent(new CustomEvent(VanillaReaderAppEvents.FOCUS_ON_READINGPOSTION_INTENT, {}));
    }

    navigatePrevious(focusNearContentLocation: boolean = true) {

        VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_NAVIGATION_INTENT>(VanillaReaderAppEvents.NAVIGATION_INTENT, {
            detail: { navigationType: VanillaReaderNavigationType.PREVIOUS, focusNearContentLocation },
        }));

    }

    navigateNext(focusNearContentLocation: boolean = true) {

        VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_NAVIGATION_INTENT>(VanillaReaderAppEvents.NAVIGATION_INTENT, {
            detail: { navigationType: VanillaReaderNavigationType.NEXT, focusNearContentLocation },
        }));

    }

    navigateTo(locator: string, focusNearContentLocation: boolean = true) {

        if (this.latestReadingPosition) {
            this._updateNavigationHistory(this.latestReadingPosition);
        }

        VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_NAVIGATION_INTENT>(VanillaReaderAppEvents.NAVIGATION_INTENT, {
            detail: {
                navigationType: VanillaReaderNavigationType.GOTO,
                locator,
                focusNearContentLocation: focusNearContentLocation,
            },
        }));
    }

    navigateToTimelinePosition(position: number) {

        if (this.latestReadingPosition) {
            this._updateNavigationHistory(this.latestReadingPosition);
        }
        VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_NAVIGATION_INTENT>(VanillaReaderAppEvents.NAVIGATION_INTENT, {
            detail: { navigationType: VanillaReaderNavigationType.TIMELINEPOSITION, position },
        }));
    }

    refreshReaderView(force: boolean = false) {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_VIEW_REFRESH_INTENT>(new CustomEvent<IVanillaReaderAppEvent_VIEW_REFRESH_INTENT>(VanillaReaderAppEvents.VIEW_REFRESH_INTENT, { detail: { force } }));
    }

    searchPublication(searchTerm: string, resultLimit?: number, documentsToSearch?: number[]) {
        VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_PUBLICATION_SEARCH_INTENT>(VanillaReaderAppEvents.PUBLICATION_SEARCH_INTENT, {
            detail: {
                searchTerm,
                resultLimit,
                documentIndexes: documentsToSearch,
            },
        }));
    }

    /**
     * This method shows a simple browser prompt that lets the user input a page number to navigate to.
     * It's very basic and to the point UI component, so I did not bother making a class etc. for it.
     *
     * Note that there are caveats with using `window.prompt` as it blocks the Main thread until the prompt is closed.
     * This can cause the app to behave incorrectly as event handlers and setTimeout calls will be not be called
     * while the prompt is active.
     *
     * The reason I chose the native prompt for this is that it is, to my knowledge, the only way for
     * us to guarantee that the screen reader will return to its original position within the page contents.
     *
     * The native Dialog element is still not reliable enough to be used from my research.
     */
    showGoToPagePrompt() {

        let pageListCollection = this.navigationTreeData?.collections.find((item: IVanillaReaderNavigationCollection) => {
            return item.type === NavigationCollectionType.PAGE_LIST;
        });

        if (!pageListCollection) {
            this.toaster.toast('This publication has no page list', true).catch(console.warn);
            return;
        }

        // If the SyncMedia is playing we should pause it since the prompt will block the main thread and so the narration
        // gets out of sync with reader view.
        if (this.menuMediaPlayer.stateIsPlaying) {
            this._onMenuMediaPlayerPauseIntent();
        }

        let currentPageItem: IVanillaReaderNavigationItem | undefined = this.latestReadingPosition?.navItemData?.find((item: IVanillaReaderNavigationItem) => {
            return item.collectionType === NavigationCollectionType.PAGE_LIST;
        });

        let pageInputValue = window.prompt(`Go to page\n\nInput the page number to go to (1-${pageListCollection.children.length})`, currentPageItem?.title);

        if (!pageInputValue) {
            return;
        }

        let pageItemToGoTo = pageListCollection?.children?.find((item: IVanillaReaderNavigationItem) => {
            return item.title === pageInputValue;
        });

        if (!pageItemToGoTo || !pageItemToGoTo.locator) {
            this.toaster.toast('This page number does not exist in the publication.', true).catch(console.warn);
            return;
        } else {
            this.navigateTo(pageItemToGoTo.locator);
        }
    }

    closePublicationAndShowFileOpenDialog() {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_PUBLICATION_CLOSE_INTENT>(new CustomEvent<IVanillaReaderAppEvent_PUBLICATION_CLOSE_INTENT>(VanillaReaderAppEvents.PUBLICATION_CLOSE_INTENT));
    }

    openMediaPlayer(startPlaybackWhenReady: boolean) {
        this.menuMediaPlayer.open();
        if (startPlaybackWhenReady) {
            this.mediaPlayerShouldStartPlaybackWhenReady = true;
        }
    }

    startMediaPlayer(locator?: string) {
        this.startPlayback(locator);
    }

    closeMediaPlayer() {
        if (this.menuMediaPlayer.isOpen()) {
            this.pausePlayback();
            this.menuMediaPlayer.close();
        }
    }


    showHighlightDialogWithActiveSelectionState() {
        if (this.activeSelection && this.activeSelection.selector) {
            let highlight: IVanillaReaderHighlightData = {
                locator: this.activeSelection.selector,
                color: VanillaReaderAppUiDefaults.highlightColorValues.yellow,
                selectionText: this.activeSelection.selectionText,
                comment: '',
            };
            this.dialogHighlight?.setState(highlight, true);
            this.dialogHighlight?.show();
        }
    }

    activatePanZoomTool() {
        if (!this.panZoomTool.isActive) {
            this.panZoomTool.activate();
        }
        this.panZoomTool.enablePointerZoom();
    }

    deactivatePanZoomTool() {
        if (this.isReaderViewIsInPanZoomMode === true) {
            this.panZoomTool.deactivate();
        }
    }

    resetPanZoomTransform() {
        this.panZoomTool.resetZoomLevel();
    }

    zoomToLevel(zoomLevel: number, animate?: boolean) {
        this.activatePanZoomTool();
        let relativeZoomLevel = this.activeTransformData ? this.activeTransformData.scale * zoomLevel : zoomLevel;

        this.panZoomTool.zoomToolToLevel(relativeZoomLevel, animate);
        this.panZoomTool.enablePanning();
    }

    /*
    *
    * PRIVATE METHODS
    *
    * */

    private async _setup() {

        this.dialogOpenFile.onFileSelected(this._onDialogOpenFileSelected.bind(this));
        this.dialogOpenFile.onFileListItemClicked(this._onDialogOpenFileOpenUrl.bind(this));
        this.dialogOpenFile.onShelfListItemClicked(this._onDialogOpenFileOpenShelfItem.bind(this));
        this.dialogOpenFile.onFileListItemDeletePublicationResourcesButtonClicked(this._onDialogOpenFilePublicationResourcesDeleteButtonClicked.bind(this));
        this.dialogOpenFile.closeOnEscKeyUp = false;

        this.initialVanillaReaderUiOptions = await this.vanillaReaderOptionsDataStore.fetchVanillaReaderUiOptionsData() || VanillaReaderAppUiDefaults.options;
        this.dialogSettings.setViewState(this.initialVanillaReaderUiOptions);

        if (this.initialVanillaReaderUiOptions) {

            // The user prefers reduced motion, so we will not use movement in our UI transitions.
            if (this.initialVanillaReaderUiOptions.useReducedMotion) {
                this.useReducedMotion = true;
                this.toaster.toast('Using reduced UI animations').catch(console.error);
            }

            // Grab the initial color palette for the app and apply it.
            let palette: IVanillaReaderUiColorPalette;
            if (VanillaReaderAppUiDefaults.options && VanillaReaderAppUiDefaults.options.palettes) {

                palette = Object.values<IVanillaReaderUiColorPalette>(VanillaReaderAppUiDefaults.options.palettes).find((value: IVanillaReaderUiColorPalette) => {
                    return value.name === this.initialVanillaReaderUiOptions?.activeUiPaletteName;
                }) || VanillaReaderAppUiDefaults.options.palettes.Default;

                this.setUiColorTheme(palette);
                this.isInDarkMode = palette.uiIsDark || false;
            }

        }
    }

    _onAfterPublicationLoadedSetup(publicationData: IVanillaReaderPublicationStateData) {

        if (this.initialVanillaReaderUiOptions?.showNarrationControls || publicationData.hasMoSyncMedia || publicationData.isAudiobook) {
            this.menuMediaPlayer.open();
            this.menuBottom.toggleReadAloudButtonState(true);
            this.fabMediaPlayer.show();
        }

        if ('wakeLock' in navigator) {
            navigator.wakeLock.request('screen').then((sentinel: WakeLockSentinel) => {
                this.screenWakeLockSentinel = sentinel;
            }).catch(console.warn);
        } else {
            console.log('wake lock is not supported');
        }

        if (this.initialVanillaReaderUiOptions?.useAccessibilityMode) {
            this.enterAccessibilityMode();
        }

        this.toaster.toast('Press Alt + F1 / Control + Option + F1 to open help text.', true).catch(console.warn);

    }

    _populatePublicationLandmarks() {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_DOCUMENT_LANDMARKS_FETCH_INTENT>(
            new CustomEvent<IVanillaReaderAppEvent_DOCUMENT_LANDMARKS_FETCH_INTENT>(
                VanillaReaderAppEvents.PUBLICATION_LANDMARKS_FETCH_INTENT,
            ),
        );
    }

    _updateNavigationHistory(readingPositionData: IVanillaReaderReadingPositionData) {
        if (this.navigationHistory.length >= 20) {
            this.navigationHistory.slice(this.navigationHistory.length - 20, this.navigationHistory.length);
        }
        this.navigationHistory.push(readingPositionData);
    }

    // TypeScript type guard
    _eventDataIsPointerEngineEventData(eventData: PointerEvent | IPointerEngineEventData): eventData is IPointerEngineEventData {
        return (eventData as IPointerEngineEventData).readerViewName !== undefined;
    }

    _eventDataIsMouseEngineEventData(eventData: MouseEvent | IMouseEngineEventData): eventData is IMouseEngineEventData {
        return (eventData as IMouseEngineEventData).readerViewName !== undefined;
    }

    /*
    *
    * CALLBACKS
    *
    * */

    _onPublicationDownloadProgressUpdated(_publicationId: string | undefined, _progress: number) { };

    _onPublicationDownloaded(publicationId: string | undefined) {
        this.toaster.toast(`Publication ${publicationId} has been added.`).catch(console.error);
    };

    /*
    *
    * EVENT HANDLERS
    *
    * */

    _uiEvent_uiKeyUp = (ev: KeyboardEvent) => {
        this._handleKeyboardShortcut(ev.key, ev.altKey, ev.shiftKey);
    };

    _uiEvent_windowResize = (ev: Event) => {
        ev.stopPropagation();

        // A resize can happen when an on-screen keyboard pops up for example. If there is a dialog open, such as the
        // search dialog, we do not refresh the reader view as this can disrupt the user experience. We need to add
        // a reminder however to do a refresh later when the dialog has closed.
        if (!this.currentActiveDialogTitle) {
            VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_APP_WINDOW_RESIZED>(VanillaReaderAppEvents.APP_WINDOW_RESIZED, { detail: { srcEvent: ev } }));
            this.sendRefreshIntentAfterDialogClosed = false;
        } else {
            this.sendRefreshIntentAfterDialogClosed = true;
        }
    };

    _applyUiControllerMixins(constructors: any[]) {
        constructors.forEach((mixinControllerClass: IVanillaReaderUiComponentController) => {

            Object.getOwnPropertyNames(mixinControllerClass.prototype).forEach((name) => {
                Object.defineProperty(
                    this.__proto__,
                    name,
                    Object.getOwnPropertyDescriptor(mixinControllerClass.prototype, name) ||
                    Object.create(null)
                );
            });

            // If the Class has a static instance method called __initialize__ we call this so that the mixin
            // can do initial setup actions.
            let hasInitializer: boolean = Object.getOwnPropertyNames(mixinControllerClass).includes('__initialize__');

            if (hasInitializer) {
                mixinControllerClass.__initialize__(this);
            }
        });
    }
}

/**
 * VanillaReaderUI
 *
 * This interface makes the mixin code splitting strategy work in TypeScript.
 *
 * */

export interface VanillaReaderUI extends
    VanillaReaderUiButtonMenuController,
    VanillaReaderUiButtonNextController,
    VanillaReaderUiButtonPreviousController,
    VanillaReaderUiKeyboardShortcutController,
    VanillaReaderUiMenuMediaPlayerController,
    VanillaReaderUiMenuController,
    VanillaReaderUiMediaSessionsController,
    VanillaReaderUiDialogUiActionsController,
    VanillaReaderUiDialogController,
    VanillaReaderUiDialogHighlightController,
    VanillaReaderUiDialogHighlightsController,
    VanillaReaderUiDialogBookmarksController,
    VanillaReaderUiDialogOpenFileController,
    VanillaReaderUiDialogFootnoteController,
    VanillaReaderUiDialogSettingsController,
    VanillaReaderUiDialogImageViewerController,
    VanillaReaderUiDialogSearchController,
    VanillaReaderUiDialogContentsController,
    VanillaReaderUiVanillaReaderEventController,
    VanillaReaderUiFabHighlightController,
    VanillaReaderUiFabMediaPlayerController,
    VanillaReaderUiPanZoomToolController {
    __proto__: any
}