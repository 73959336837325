/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IVanillaReaderUI} from "./IVanillaReaderUI";
import {IVanillaReaderUiComponentController} from "./IVanillaReaderUiComponentController";
import {VanillaReaderUiDialog} from "./VanillaReaderUiDialog";
import {VanillaReaderUI} from "./VanillaReaderUI";

export interface VanillaReaderUiDialogController extends IVanillaReaderUiComponentController, IVanillaReaderUI {};

/**
 * # VanillaReaderUiDialogController
 *
 * ## RESPONSIBILITIES
 * This base controller mixing class handles all event callbacks from the `VanillaReaderUiDialog` class.
 *
 * Just as all other controller mixin classes, this class is used to add functionality to the `VanillaReaderUi` class
 * in a "modular" way. Read more about this in the `UI Components and Controller Mixins` in the README.md file.
 *
 * ## RELATED TYPES
 * - VanillaReaderUiDialog
 *
 * */
export class VanillaReaderUiDialogController {

    static __initialize__(vanillaReaderUiContext: VanillaReaderUI) {
        // Static event callbacks that are triggered regardless of what dialog instance are opened and closed.
        VanillaReaderUiDialog.sharedOnDialogOpened(vanillaReaderUiContext._uiEvent_dialogOpened.bind(vanillaReaderUiContext));
        VanillaReaderUiDialog.sharedOnDialogClosed(vanillaReaderUiContext._uiEvent_dialogClosed.bind(vanillaReaderUiContext));
        // This callback is a bit of a workaround to manage the FocusTrap focus management. Ironic right? ;)
        // When the FocusTrap is deactivated we can safely move focus back to the current reading position.
        VanillaReaderUiDialog.sharedOnDialogFocusTrapDeactivated(vanillaReaderUiContext._uiEvent_dialogFocusTrapDeactivated.bind(vanillaReaderUiContext));
    }

    _uiEvent_dialogOpened (dialogTitle: string, _silent: boolean = false) {
        this.setReaderViewAsNotImportantForAccessibility();
        this.activeDialogTitle = dialogTitle;
    };

    _uiEvent_dialogClosed (dialogTitle: string, silent: boolean = false) {
        this.setReaderViewAsImportantForAccessibility();
        this.activeDialogTitle = undefined;
        if (!silent) {
            this.toaster.toast(`Dialog ${dialogTitle} closed`).catch(console.error);
        }

        if (this.sendRefreshIntentAfterDialogClosed) {
            this.refreshReaderView(true);
        }
    };

    _uiEvent_dialogFocusTrapDeactivated(returnFocusOnClose: boolean) {
        if(returnFocusOnClose) {
            this.focusOnReadingPosition();
        }
    };
}