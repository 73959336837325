/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {LengthUnit} from '@colibrio/colibrio-reader-framework/colibrio-core-base';
import {MediaType} from '@colibrio/colibrio-reader-framework/colibrio-core-io-base';
import {TextAlignment} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';
import {EasyReadingPRO} from '../assets/fonts/EasyReadingPRO';
import {IVanillaReaderUiOptionsData, VanillaReaderUiActionNames} from './VanillaReaderUiModel';
import {Key} from "../utils/Key.enum";

/*
* # VanillaReaderAppUiDefaults
*
* ## RESPONSIBILITIES
*
* This class is used to hold the default values to for settings in the UI.
*
* */

export class VanillaReaderAppUiDefaults {
    static highlightColorValues = {
        red: '#FF8080',
        green: '#AFE9DD',
        blue: '#80B3FF',
        yellow: '#FFDD55',
    };

    static options: IVanillaReaderUiOptionsData = {
        publicationStyleOptions: {
            fontSizeScaleFactor: 1,
            lineHeightScaleFactor: 1.2,
            textAlignment: {
                defaultAlignment: TextAlignment.LEFT,
                preserved: [],
            },
            pageMargins: {
                top: {
                    unit: LengthUnit.PERCENT,
                    value: 6,
                },
                bottom: {
                    unit: LengthUnit.PERCENT,
                    value: 6,
                },
                left: {
                    unit: LengthUnit.PERCENT,
                    value: 6,
                },
                right: {
                    unit: LengthUnit.PERCENT,
                    value: 6,
                },
            },
        },
        dateCreated: undefined,
        showNarrationControls: false,
        ignoreAspectRatio: false,
        useReducedMotion: false,
        useDarkMode: false,
        activeRendererTypeName: undefined,
        activeUiPaletteName: 'Default',
        activePublicationFontSetName: undefined,
        activePublicationPaletteName: undefined,
        mediaPlayerPlaybackRate: 1,
        fontSets: {
            SansSerif: {
                defaults: {
                    fallback: 'sans-serif',
                },
                fontFaces: [],
            },

            Serif: {
                defaults: {
                    fallback: 'serif',
                    serif: 'serif',
                    sansSerif: 'serif',
                    cursive: 'serif',
                    monospace: 'serif',
                    fantasy: 'serif',
                },
                fontFaces: [],
            },

            EasyReading: {
                defaults: {
                    fallback: 'EasyReading',
                },
                fontFaces: [
                    {
                        family: 'EasyReading',
                        src: EasyReadingPRO,
                        mediaType: MediaType.FONT_TTF,
                    },
                ],
            },
        },
        palettes: {
            Default: {
                name: 'Default',
                backgroundLight: 'rgba(256, 256, 256, 1)',
                backgroundDark: 'rgba(244, 244, 244,1)',
                foregroundLight: 'rgba(88, 88, 88, 1)',
                foregroundDark: 'rgba(51, 51, 51, 1)',
                accent: 'rgba(0, 13, 255, 1)',

                uiIsDark: false,
                uiOutlineLight: 'rgba(255,255,255,0.5)',
                uiOutlineDark: 'rgba(54, 48, 54, 0.3)',
                uiBackgroundLight: '#f7f6f7',
                uiBackgroundDark: '#660050',
                uiForegroundLight: '#fafafa',
                uiForegroundDark: '#382e38',
            },

            Dark: {
                name: 'Dark',
                backgroundLight: '#3e3e3e',
                backgroundDark: '#424242',
                foregroundLight: '#ffffff',
                foregroundDark: '#dedede',
                accent: '#d48872',

                uiIsDark: true,
                uiOutlineLight: 'rgba(255,255,255,0.5)',
                uiOutlineDark: 'rgba(255,255,255,0.2)',
                uiBackgroundLight: '#3e3e3e',
                uiBackgroundDark: '#424242',
                uiForegroundLight: '#ffffff',
                uiForegroundDark: '#dedede',

            },

            ContrastDark: {
                name: 'ContrastDark',
                backgroundLight: '#000000',
                backgroundDark: '#000000',
                foregroundLight: '#ffffff',
                foregroundDark: '#ffffff',
                accent: '#ffde00',

                uiIsDark: true,
                uiOutlineLight: 'rgba(255, 255, 255, 1)',
                uiOutlineDark: 'rgba(255, 255, 255, 0.6)',
                uiBackgroundLight: '#000000',
                uiBackgroundDark: '#000000',
                uiForegroundLight: '#ffffff',
                uiForegroundDark: '#ffffff',

            },

            Paperlike: {
                name: 'Paperlike',
                backgroundLight: '#ece8e2',
                backgroundDark: '#b3a196',
                foregroundLight: '#ece8e2',
                foregroundDark: '#38251d',
                accent: '#D88C00',

                uiIsDark: false,
                uiOutlineLight: '#7f5e4d',
                uiOutlineDark: '#a7958c',
                uiBackgroundLight: '#ece8e2',
                uiBackgroundDark: '#b3a196',
                uiForegroundLight: '#ece8e2',
                uiForegroundDark: '#38251d',
            },
        },
        uIActionKeyCommands: [
            {
                actionName: VanillaReaderUiActionNames.NAVIGATE_NEXT,
                key: 'ArrowRight',
                modifiers: [Key.Alt]
            },
            {
                actionName: VanillaReaderUiActionNames.NAVIGATE_PREVIOUS,
                key: 'ArrowLeft',
                modifiers: [Key.Alt]
            },
            {
                actionName: VanillaReaderUiActionNames.NAVIGATE_NEXT,
                key: 'ArrowUp',
                modifiers: [Key.Alt]
            },
            {
                actionName: VanillaReaderUiActionNames.NAVIGATE_PREVIOUS,
                key: 'ArrowDown',
                modifiers: [Key.Alt]
            },
            {
                actionName: VanillaReaderUiActionNames.MAIN_MENU_OPEN,
                key: 'm',
                modifiers: [Key.Alt]
            },
            {
                actionName: VanillaReaderUiActionNames.SEARCH_DIALOG_OPEN,
                key: 's',
                modifiers: [Key.Alt]
            },
            {
                actionName: VanillaReaderUiActionNames.SEARCH_CLEAR,
                key: 'S',
                modifiers: [Key.Alt, Key.Shift]
            },
            {
                actionName: VanillaReaderUiActionNames.BOOKMARK_READING_POSITION,
                key: 'b',
                modifiers: [
                    Key.Alt
                ]
            },
            {
                actionName: VanillaReaderUiActionNames.BOOKMARK_CLIPBOARD,
                key: 'B',
                modifiers: [Key.Alt, Key.Shift]
            },
            {
                actionName: VanillaReaderUiActionNames.TOC_OPEN_DIALOG,
                key: 'c',
                modifiers: [Key.Alt]
            },
            {
                actionName: VanillaReaderUiActionNames.SETTINGS_DIALOG_OPEN,
                key: 't',
                modifiers: [Key.Alt]
            },
            {
                actionName: VanillaReaderUiActionNames.BOOKSHELF_DIALOG_OPEN,
                key: 'q',
                modifiers: [Key.Alt]
            },
            {
                actionName: VanillaReaderUiActionNames.CLOSE_DIALOGS_AND_FOCUS_ON_READING_POSITION,
                key: 'x',
                modifiers: [Key.Alt]
            },
            {
                actionName: VanillaReaderUiActionNames.BOOKMARK_OPEN_DIALOG_ALL,
                key: 'o',
                modifiers: [Key.Alt]
            },
            {
                actionName: VanillaReaderUiActionNames.BOOKMARK_OPEN_DIALOG_ACTIVE,
                key: 'O',
                modifiers: [Key.Alt, Key.Shift]
            },
            {
                actionName: VanillaReaderUiActionNames.HIGHLIGHT_OPEN_DIALOG_ALL,
                key: 'h',
                modifiers: [Key.Alt]
            },
            {
                actionName: VanillaReaderUiActionNames.HIGHLIGHT_OPEN_DIALOG_ACTIVE,
                key: 'H',
                modifiers: [Key.Alt, Key.Shift]
            },
            {
                actionName: VanillaReaderUiActionNames.HELP_DIALOG_OPEN,
                key: 'F1',
                modifiers: [Key.Alt]
            },
            {
                actionName: VanillaReaderUiActionNames.HELP_DIALOG_OPEN,
                key: '/',
                modifiers: [Key.Alt]
            },
            {
                actionName: VanillaReaderUiActionNames.HIGHLIGHT_SELECTION,
                key: 'i',
                modifiers: [Key.Alt]
            },
            {
                actionName: VanillaReaderUiActionNames.HIGHLIGHT_CLIPBOARD,
                key: 'I',
                modifiers: [Key.Alt, Key.Shift]
            },
            {
                actionName: VanillaReaderUiActionNames.WHERE_AM_I,
                key: 'w',
                modifiers: [Key.Alt]
            },
            {
                actionName: VanillaReaderUiActionNames.NAVIGATE_NEXT_HEADING,
                key: 'g',
                modifiers: [Key.Alt]
            },
            {
                actionName: VanillaReaderUiActionNames.NAVIGATE_PREVIOUS_HEADING,
                key: 'G',
                modifiers: [Key.Alt, Key.Shift]
            },
            {
                actionName: VanillaReaderUiActionNames.MEDIA_PLAYBACK_START,
                key: 'r',
                modifiers: [Key.Alt]
            },
            {
                actionName: VanillaReaderUiActionNames.MEDIA_PLAYBACK_STOP,
                key: 'R',
                modifiers: [Key.Alt, Key.Shift]
            },
            {
                actionName: VanillaReaderUiActionNames.ACCESSIBILITY_MODE_ENTER,
                key: 'z',
                modifiers: [Key.Alt]
            },
            {
                actionName: VanillaReaderUiActionNames.ACCESSIBILITY_MODE_EXIT,
                key: 'Z',
                modifiers: [Key.Alt, Key.Shift]
            },
            {
                actionName: VanillaReaderUiActionNames.GOTO_PAGE_DIALOG_OPEN,
                key: 'p',
                modifiers: [Key.Alt]
            },
            {
                actionName: VanillaReaderUiActionNames.ACTIONS_MENU_OPEN,
                key: 'F2',
                modifiers: [Key.Alt]
            },
            {
                actionName: VanillaReaderUiActionNames.ZOOM_IN,
                key: '+',
                modifiers: [Key.Alt]
            },
            {
                actionName: VanillaReaderUiActionNames.ZOOM_TOOL_ACTIVATE,
                key: '?',
                modifiers: [Key.Alt, Key.Shift]
            },
            {
                actionName: VanillaReaderUiActionNames.ZOOM_OUT,
                key: '-',
                modifiers: [Key.Alt]
            },
            {
                actionName: VanillaReaderUiActionNames.ZOOM_TOOL_DEACTIVATE,
                key: '_',
                modifiers: [Key.Alt, Key.Shift]
            }
        ]
    };

}