/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IVanillaReaderAppEvent_NAVIGATION_INTENT} from "../VanillaReader/VanillaReaderEventBus";
import {IVanillaReaderUI} from "./IVanillaReaderUI";
import {VanillaReaderUiMenuMediaPlayerController} from "./VanillaReaderUiMenuMediaPlayerController";
import {IVanillaReaderUiComponentController} from "./IVanillaReaderUiComponentController";
import {VanillaReaderUI} from "./VanillaReaderUI";

export interface VanillaReaderUiMenuController extends IVanillaReaderUiComponentController, IVanillaReaderUI, VanillaReaderUiMenuMediaPlayerController {}

/**
 * # VanillaReaderUiMenuController
 *
 * ## RESPONSIBILITIES
 * This controller mixing class handles all event callbacks from the `VanillaReaderUiMenu` class.
 *
 * Just as all other controller mixin classes, this class is used to add functionality to the `VanillaReaderUi` class
 * in a "modular" way. Read more about this in the `UI Components and Controller Mixins` in the README.md file.
 *
 * ## RELATED TYPES
 * - VanillaReaderUi
 * - VanillaReaderUiMenu
 *
 */
export class VanillaReaderUiMenuController {

    static __initialize__(vanillaReaderUiContext: VanillaReaderUI) {
        vanillaReaderUiContext.menuBottom.onMenuHandleClicked(vanillaReaderUiContext._onMenuBottomHandleClicked.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.menuBottom.onMenuHandleSwipeDown(vanillaReaderUiContext._onMenuBottomHandleClicked.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.menuBottom.onFileOpen(vanillaReaderUiContext._onMenuBottomFileOpenAction.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.menuBottom.onPublicationSearch(vanillaReaderUiContext._onMenuBottomPublicationSearch.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.menuBottom.onBookmarkReadingPosition(vanillaReaderUiContext._onMenuBottomBookmarkReadingPosition.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.menuBottom.onNavigateNext(vanillaReaderUiContext._onMenuBottomNavigateNext.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.menuBottom.onNavigatePrevious(vanillaReaderUiContext._onMenuBottomNavigatePrevious.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.menuBottom.onNavigateToTimelinePosition(vanillaReaderUiContext._onMenuBottomNavigateToTimelinePosition.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.menuBottom.onMenuVisible(vanillaReaderUiContext._onMenuBottomVisible.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.menuBottom.onMenuHidden(vanillaReaderUiContext._onMenuBottomHidden.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.menuBottom.onHelpOpen(vanillaReaderUiContext._onMenuBottomHelpDialogOpen.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.menuBottom.onContentsOpen(vanillaReaderUiContext._onMenuBottomContentsDialogOpen.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.menuBottom.onReadAloudControlsOpen(vanillaReaderUiContext._onMenuBottomReadAloudControlsOpen.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.menuBottom.onReadAloudControlsClose(vanillaReaderUiContext._onMenuBottomReadAloudControlsClose.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.menuBottom.onPanZoomActivate(vanillaReaderUiContext._onPanZoomActivate.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.menuBottom.onPanZoomDeactivate(vanillaReaderUiContext._onPanZoomDeactivate.bind(vanillaReaderUiContext));
    }

    _onMenuBottomPublicationSearch() {
        this.dialogSearch.show(false, true, undefined, this.latestSelectedSearchResultLocator);
    };

    _onMenuBottomHandleClicked() {
        this.menuBottom.hide(!this.useReducedMotion);
        this.toaster.toast('Menu closed').catch(console.warn);
    };

    _onMenuBottomFileOpenAction() {
        this.closePublicationAndShowFileOpenDialog()
    };

    _onMenuBottomBookmarkReadingPosition() {
        this.bookmarkReadingPosition();
    };

    _onMenuBottomNavigateNext() {
        this.navigateNext();
    };

    _onMenuBottomNavigatePrevious() {
        this.navigatePrevious();
    };

    _onMenuBottomNavigateToTimelinePosition(navigationData: IVanillaReaderAppEvent_NAVIGATION_INTENT) {
        if (navigationData.position) {
            this.navigateToTimelinePosition(navigationData.position);
        }
    };

    _onMenuBottomVisible() {
        this.menuBottom.setAsImportantForAccessibility();
        // Let's make sure that the highlight button is not hidden below the menu
        let fabBottomOffset = 16;
        let fabButtonOffset = this.menuBottom.getComponentElement().getBoundingClientRect().height + fabBottomOffset + 'px';
        this.vanillaReaderFabContainerElement.style.marginBottom = fabButtonOffset;
        this.setReaderViewAsNotImportantForAccessibility();
    };

    _onMenuBottomHidden() {
        this.menuBottom.setAsNotImportantForAccessibility();
        // Move the highlight button back to its original position
        this.vanillaReaderFabContainerElement.style.marginBottom = '0';

        this.setReaderViewAsImportantForAccessibility();
        this.focusOnReadingPosition();
    };

    _onMenuBottomHelpDialogOpen() {
        this.dialogHelp.show(!this.useReducedMotion);
    };

    _onMenuBottomContentsDialogOpen() {
        this.dialogContents.show();
    };

    _onMenuBottomReadAloudControlsOpen(startPlaybackWhenReady: boolean = false) {
        this.openMediaPlayer(startPlaybackWhenReady);
    };

    _onMenuBottomReadAloudControlsClose() {
        this.closeMediaPlayer()
    };

    _onPanZoomActivate() {
        this.activatePanZoomTool();
    }

    _onPanZoomDeactivate() {
        this.deactivatePanZoomTool();
    }
}