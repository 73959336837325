/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {VanillaVoidCallback} from '../VanillaReader/VanillaReaderModel';

/**
 * # VanillaReaderUiFabPanZoomToolExit
 *
 * ## RESPONSIBILITIES
 *
 * A floating action button that is shown when ever the view is in a transform state, meaning that it has been zoomed
 * and panned.
 *
 * ## RELATED TYPES
 * - VanillaReaderUiPanZoomTool
 *
 */
export class VanillaReaderUiFabPanZoomToolExit {
    private _template = `<button class="vanilla-reader__fab" id="vanilla-reader__fab-panzoom-exit" type="button" title="Exit Pan Zoom mode" aria-hidden="true"><span class="vanilla-reader__fab__icon" id="vanilla-reader__fab-panzoom__icon" aria-hidden="true">zoom_out_map</span></button>`;
    private _onClickCallback: VanillaVoidCallback | undefined;
    private _onVisibleCallback: VanillaVoidCallback | undefined;
    private _onHiddenCallback: VanillaVoidCallback | undefined;
    private _exitButton: HTMLButtonElement;

    constructor(private _hostElement: HTMLElement, insertPosition: InsertPosition = 'afterbegin') {
        this._hostElement.insertAdjacentHTML(insertPosition, this._template);
        this._exitButton = this._hostElement.querySelector('#vanilla-reader__fab-panzoom-exit')!;
        this._exitButton.addEventListener('click', (ev: MouseEvent) => {
            ev.preventDefault();
            if (this._onClickCallback) {
                if (this.isVisible) {
                    this._onClickCallback();
                }
            }
        });

    }

    public get isVisible(): boolean {
        return !this._exitButton.hasAttribute('aria-hidden');
    }

    show() {
        this._exitButton.removeAttribute('aria-hidden');
        this._exitButton.style.display = 'flex';
        this._exitButton.style.opacity = '1';
        this._exitButton.style.pointerEvents = 'all';
        if (this._onVisibleCallback) {
            this._onVisibleCallback();
        }
    }

    hide() {
        this._exitButton.setAttribute('aria-hidden', 'true');
        this._exitButton.style.opacity = '0';
        this._exitButton.style.pointerEvents = 'none';

        setTimeout(()=>{
            this._exitButton.style.display = 'none';
        },200);

        if (this._onHiddenCallback) {
            this._onHiddenCallback();
        }
    }

    getComponentElement(): HTMLElement {
        return this._exitButton;
    }

    onClick(callback: VanillaVoidCallback) {
        this._onClickCallback = callback;
    }

    onVisible(callback: VanillaVoidCallback) {
        this._onVisibleCallback = callback;
    }

    onHidden(callback: VanillaVoidCallback) {
        this._onHiddenCallback = callback;
    }

}