/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {
    IFetchNavigationItemReferencesResult,
    IReaderPublicationNavigation,
    IReaderPublicationNavigationItem,
    IReaderPublicationNavigationItemReference,
    NavigationCollectionType,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';
import {
    IVanillaReaderNavigationCollection,
    IVanillaReaderNavigationItem,
    IVanillaReaderNavigationTreeData,
    IVanillaReaderReadingProgressionTimeline,
} from './VanillaReaderModel';
import {VanillaReaderView} from './VanillaReaderView';

/*
* # VanillaReaderNavigationTree
*
* ## PRIMARY COLIBRIO FRAMEWORK TYPES
*
* - IReaderPublicationNavigation
* - IReaderView
*
* ## RESPONSIBILITIES
*
* This class wraps the IReaderPublicationNavigation data builds the tree structure that is used by the UI.
*
* */

export class VanillaReaderNavigationTree {
    private _tree: IVanillaReaderNavigationTreeData;
    private _colibrioPublicationNavigation: IReaderPublicationNavigation | undefined;
    private _timeline: IVanillaReaderReadingProgressionTimeline | undefined;
    private _hasBeenBuilt: boolean = false;

    constructor() {
        this._tree = {
            collections: [],
            items: [],
        };
    }

    public get hasBeenBuilt(): boolean {
        return this._hasBeenBuilt;
    }

    async build(
        publicationNavigation: IReaderPublicationNavigation,
        timeline?: IVanillaReaderReadingProgressionTimeline,
    ) {
        this._colibrioPublicationNavigation = publicationNavigation;
        this._timeline = timeline;
        await this._buildVanillaReaderNavigationTree();
    }

    getTreeData() {
        return this._tree;
    }

    setTreeData(treeData: IVanillaReaderNavigationTreeData) {
        this._tree = treeData;
        this._hasBeenBuilt = true;
    }

    async fetchItemsInVisibleRange(
        types: NavigationCollectionType[],
        greedy: boolean = true,
        vanillaReaderView: VanillaReaderView,
    ): Promise<IReaderPublicationNavigationItemReference[] | undefined> {
        let items: IFetchNavigationItemReferencesResult | undefined = await vanillaReaderView?.getVisibleRange()?.fetchNavigationItemReferences({
            collectionTypes: types,
            greedy: greedy,
        });
        return items?.getItemsInRange();
    }

    /*
    *
    * PRIVATE METHODS
    *
    * */

    private async _buildVanillaReaderNavigationTree() {
        if (this._colibrioPublicationNavigation) {
            let collections = this._colibrioPublicationNavigation.getNavigationCollections();

            for (const collection of collections) {
                let newCollection: IVanillaReaderNavigationCollection = {
                    type: collection.getType(),
                    children: [],
                };
                for (const item of collection.getChildren()) {
                    let navItem = await this._createNavigationItem(item);
                    if (navItem) {
                        this._tree.items.push(navItem);
                        newCollection.children.push(navItem);
                    }
                }

                this._tree.collections.push(newCollection);

            }
            this._hasBeenBuilt = true;
        }
    }

    private async _createNavigationItem(navItem: IReaderPublicationNavigationItem): Promise<IVanillaReaderNavigationItem> {
        const locator = navItem.getLocator();
        let textContent: string;
        textContent = navItem.getTextContent();
        let timelineStartPos: number | undefined;

        if (locator && this._timeline) {
            timelineStartPos = await this._timeline.fetchTimelinePosition(locator);
        }

        let newItem: IVanillaReaderNavigationItem = {
            locator: locator?.toString() || undefined,
            title: textContent,
            children: [],
            timelineStartPosition: timelineStartPos,
        };

        let children = navItem.getChildren() as IReaderPublicationNavigationItem[];

        if (children && children.length > 0) {
            for (const item of children) {
                let newChildItem = await this._createNavigationItem(item);
                newItem.children.push(newChildItem);
            }
        }

        return newItem;
    }

}
