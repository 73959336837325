
import { VanillaReader } from '../VanillaReader/VanillaReader'
import { VanillaReaderUI } from '../VanillaReaderUI/VanillaReaderUI'
import { IVanillaReaderBookmarkData, IVanillaReaderHighlightData } from '../VanillaReader/VanillaReaderModel'

import swal from 'sweetalert'

/**
 * Represents a Reader class for handling publication loading and token processing.
 */
export class Reader {

    private static instance: Reader | null = null

    public ENV: string = ''

    public APP_URL: string = ''
    public API_URL: string = ''
    public DOTNET_SITE_URL: string = ''
    public DOTNET_SITE_READER_URL: string = ''

    public PARAMS: Record<string, string> = {}

    private TOKEN_DECODE_API_URL: string = ''

    private UPDATE_PUBLICATION_API_URL: string = ''
    private GET_PUBLICATION_API_URL: string = ''

    private ADD_BOOKMARK_API_URL: string = ''
    private ADD_BOOKMARKS_API_URL: string = ''

    private FETCH_BOOKMARK_API_URL: string = ''
    private FETCH_BOOKMARKS_BY_PUBLICATION_API_URL: string = ''
    private FETCH_ALL_BOOKMARKS_API_URL: string = ''

    private DELETE_BOOKMARK_API_URL: string = ''
    private DELETE_ALL_BOOKMARKS_BY_PUBLICATION_API_URL: string = ''
    private DELETE_ALL_BOOKMARKS_API_URL: string = ''

    private ADD_HIGHLIGHT_API_URL: string = ''
    private UPDATE_HIGHLIGHT_API_URL: string = ''

    private FETCH_HIGHLIGHT_API_URL: string = ''
    private FETCH_HIGHLIGHTS_BY_PUBLICATION_API_URL: string = ''
    private FETCH_ALL_HIGHLIGHTS_API_URL: string = ''

    private DELETE_HIGHLIGHT_API_URL: string = ''
    private DELETE_HIGHLIGHTS_BY_PUBLICATION_API_URL: string = ''
    private DELETE_ALL_HIGHLIGHTS_API_URL: string = ''

    private TOKEN_PARAM: string = ''
    private DECODED_DATA: object = {}

    // @ts-ignore
    public vanillaReaderUi: VanillaReaderUI

    // @ts-ignore
    public vanillaReader: VanillaReader

    public USER = {
        id: 0,
        book_id: 0,
        book_name: '',
        book_token: '',
        book_url: '',

        publication: {
            id: 0,
            user_id: 0,
            book_id: 0,
            reader_position: 0,
            created_at: '',
            updated_at: '',
        }

    }

    private constructor() {

        this.PARAMS = this.getUrlParams()

    }

    public async init() {

        this.TOKEN_DECODE_API_URL = this.API_URL + '/jwt/decode'

        this.UPDATE_PUBLICATION_API_URL = this.API_URL + '/update_publication'
        this.GET_PUBLICATION_API_URL = this.API_URL + '/get_publication'

        this.ADD_BOOKMARK_API_URL = this.API_URL + '/add_bookmark'
        this.ADD_BOOKMARKS_API_URL = this.API_URL + '/add_bookmarks'

        this.FETCH_BOOKMARK_API_URL = this.API_URL + '/fetch_bookmark'
        this.FETCH_BOOKMARKS_BY_PUBLICATION_API_URL = this.API_URL + '/fetch_bookmarks_by_publication'
        this.FETCH_ALL_BOOKMARKS_API_URL = this.API_URL + '/fetch_all_bookmarks'

        this.DELETE_BOOKMARK_API_URL = this.API_URL + '/delete_bookmark'
        this.DELETE_ALL_BOOKMARKS_BY_PUBLICATION_API_URL = this.API_URL + '/delete_all_bookmarks_by_publication'
        this.DELETE_ALL_BOOKMARKS_API_URL = this.API_URL + '/delete_all_bookmarks'

        this.ADD_HIGHLIGHT_API_URL = this.API_URL + '/add_highlight'
        this.UPDATE_HIGHLIGHT_API_URL = this.API_URL + '/update_highlight'

        this.FETCH_HIGHLIGHT_API_URL = this.API_URL + '/fetch_highlight'
        this.FETCH_HIGHLIGHTS_BY_PUBLICATION_API_URL = this.API_URL + '/fetch_highlights_by_publication'
        this.FETCH_ALL_HIGHLIGHTS_API_URL = this.API_URL + '/fetch_all_highlights'

        this.DELETE_HIGHLIGHT_API_URL = this.API_URL + '/delete_highlight'
        this.DELETE_HIGHLIGHTS_BY_PUBLICATION_API_URL = this.API_URL + '/delete_highlight_by_publication'
        this.DELETE_ALL_HIGHLIGHTS_API_URL = this.API_URL + '/delete_all_highlights'

    }

    /**
     * As the name suggests, this function is used to load a publication when the `publication_url=` querystring parameter
     *
     * is present.
     *
     * Loads a publication from the initial URL and displays it using the provided reader and UI components.
     *
     * @param vanillaReaderUI - The UI component for the reader.
     * @param vanillaReader - The reader component.
     * @param USE_DEVICE_STORAGE - Flag indicating whether to use device storage.
     *
     *  index.ts
     *
     *  async function setupVanillaReader(licenseApiKey: string): Promise<VanillaReader> {}
     *
     * */
    public async loadPublicationFromInitialUrl(
        USE_DEVICE_STORAGE: any,
    ) {

        if (this.PARAMS['open']) {

            this.vanillaReaderUi.dialogOpenFile.show()
            Reader.stopTheApp()

        }

        if (this.USER.book_url === null || this.ENV !== 'TESTING') {

            const token_decode_status = await this.processURLParams();
            const publication_data_status = await this.fetchPublicationData();

            console.log('Token Decode Status:', token_decode_status);
            console.log('Publication Data Status:', publication_data_status);

            this.USER.book_url = this.DOTNET_SITE_READER_URL + this.USER.book_token

        }

        if (this.USER.book_name !== null) {

            localStorage.setItem('book_name', this.USER.book_name);
            this.USER.book_name = this.USER.book_name;

        }
        else {
            let fileName = this.USER.book_url.slice(
                this.USER.book_url.lastIndexOf('/') + 1
            );

            localStorage.setItem('book_name', fileName);
            this.USER.book_name = fileName;
        }

        await this.vanillaReader.loadPublicationFromUrl(
            this.USER.book_url, undefined, undefined, USE_DEVICE_STORAGE
        );

        this.vanillaReaderUi.toaster.toast(
            `Opening ${this.USER.book_name}`
        ).catch(console.warn);

        this.vanillaReaderUi.dialogOpenFile.close(true);

    }

    /**
     * Processes the URL parameters, fetches token data, and performs necessary operations.
     * @returns A promise indicating the success or failure of the token processing.
     */
    private async processURLParams(): Promise<any> {

        const tokenParam = this.PARAMS['token']
        this.TOKEN_PARAM = tokenParam;

        try {

            const getTokenDataFromAPI = await this.fetchTokenData();

            if (getTokenDataFromAPI.statusCode === 200) {

                this.DECODED_DATA = getTokenDataFromAPI.data.decoded

                // @ts-ignore
                const userid = this.DECODED_DATA.userId

                // @ts-ignore
                const productId = this.DECODED_DATA.productId

                // @ts-ignore
                // const bookName = this.DECODED_DATA.BookName

                // @ts-ignore
                const bookToken = this.DECODED_DATA.Token

                localStorage.setItem('userid', userid);
                localStorage.setItem('book_id', productId);

                this.USER.id = parseInt(userid);
                this.USER.book_id = (productId);
                // this.USER.book_name = bookName
                this.USER.book_token = bookToken + `ProductID${productId}`;

                return Promise.resolve('Success');

            } else {

                let msg = 'Token data not valid'
                console.error(msg + ': ', getTokenDataFromAPI)

                swal('Failed!', msg, 'error')
                throw new Error('Stop the app!')

            }

        } catch (error) {

            let msg = 'Error fetching token data'
            console.error(msg + ' v2 : ', error)

            swal('Failed!', msg, 'error')
            throw new Error('Stop the app!')
        }

    }

    /**
     * Fetches token data from the server using the provided token.
     * @param token - The token to fetch data for.
     * @returns A promise that resolves with the token data.
     */
    private async fetchTokenData(): Promise<any> {

        const url = this.TOKEN_DECODE_API_URL;
        const body = JSON.stringify({ token: this.TOKEN_PARAM })

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            });

            const data = await response.json();

            return data;

        } catch (error) {

            let msg = 'Error fetching token data'
            console.error(msg + ' v1 : ', error)

            swal('Failed!', msg, 'error')
            throw new Error('Stop the app!')

        }
    }

    private async fetchPublicationData(): Promise<any> {

        const url = this.GET_PUBLICATION_API_URL + `?user_id=${this.USER.id}&book_id=${this.USER.book_id}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
            });

            const data = await response.json()

            if (!data) {
                return
            }

            if (!data.data.publication_data[0]) {
                return
            }

            let pub_data = data.data.publication_data[0]

            if (pub_data.id) {
                this.USER.publication.id = parseInt(pub_data.id)
            }

            if (pub_data.user_id) {
                this.USER.publication.user_id = parseInt(pub_data.user_id)
            }

            if (pub_data.book_id) {
                this.USER.publication.book_id = parseInt(pub_data.book_id)
            }

            if (pub_data.reader_position) {
                this.USER.publication.reader_position = parseInt(pub_data.reader_position)
            }

            return Promise.resolve('Success');

        } catch (error) {

            let msg = 'Error fetching book data'
            console.error(msg + ' v1 : ', error)

            swal('Failed!', msg, 'error')
            throw new Error('Stop the app!')

        }
    }

    // @ts-ignore
    private async updatePublicationData(type: string = 'default'): void {

        try {

            // @ts-ignore
            this.USER.publication.reader_position = await window.vanillaReader.getTimelinePosition()

            let readerPosition = this.USER.publication.reader_position

            const url = this.UPDATE_PUBLICATION_API_URL + `?user_id=${this.USER.id}&book_id=${this.USER.book_id}&reader_position=${readerPosition}`;

            const response = await fetch(url, {
                method: 'GET',
            });

            if (!response.ok) {
                throw new Error(' [+] Failed to update publication data.');
            }

            const data = await response.json();

            return data

        } catch (error) {

            console.error(' [+] Error updating publication data: ', error);

        }

    }

    // @ts-ignore
    public async navigateReaderPosition(): void {

        let readerPosition = this.USER.publication.reader_position

        if (typeof readerPosition === 'number' && readerPosition > 0) {

            // @ts-ignore
            await window.vanillaReader.gotoTimelinePosition(readerPosition)

        } else {

            // @ts-ignore
            await window.vanillaReader.goToStart()

        }

    }

    /*******************************************************************************************************
     *
     *  VanillaReaderDataStore/VanillaReaderBookmarkIndexedDbDataStore.ts
     *
     *  async addBookmark(bookmarkData: IVanillaReaderBookmarkData) {}
     *
     *******************************************************************************************************/
    public async addBookmark(bookmarkData: IVanillaReaderBookmarkData) {

        const url = this.ADD_BOOKMARK_API_URL

        const body = JSON.stringify({

            user_id: this.USER.id,
            book_id: this.USER.book_id,
            bookmark_data: bookmarkData,

        })

        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            })

            if (!response.ok) {
                throw new Error(' [+] Failed to create bookmark.')
            }

            const data = await response.json()

            return data

        } catch (error) {

            console.error(' [+] Error occured while creating bookmark: ', error)

        }

    }

    /*******************************************************************************************************
     *
     *  VanillaReaderDataStore/VanillaReaderBookmarkIndexedDbDataStore.ts
     *
     *  private async _addBookmark(...) {}
     *
     *******************************************************************************************************/
    public async addBookmarks(bookmarkData: IVanillaReaderBookmarkData[]) {

        const url = this.ADD_BOOKMARKS_API_URL

        const body = JSON.stringify({

            user_id: this.USER.id,
            book_id: this.USER.book_id,
            bookmark_data: bookmarkData,

        })

        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            })

            if (!response.ok) {
                throw new Error(' [+] Failed to create bookmarks.')
            }

            const data = await response.json()

            return data

        } catch (error) {

            console.error(' [+] Error occured while creating bookmarks: ', error)

        }

    }

    /*******************************************************************************************************
     *
     *  VanillaReaderDataStore/VanillaReaderBookmarkIndexedDbDataStore.ts
     *
     *  async fetchBookmark(locator: string) {}
     *
     *******************************************************************************************************/
    public async fetchBookmark(field_data: object) {

        const url = this.FETCH_BOOKMARK_API_URL

        const body = JSON.stringify({

            user_id: this.USER.id,
            book_id: this.USER.book_id,

            // @ts-ignore
            field_name: field_data.name,
            // @ts-ignore
            field_value: field_data.value,

        })

        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            })

            if (!response.ok) {
                throw new Error(' [+] Failed to get bookmark.')
            }

            const data = await response.json()

            return data

        } catch (error) {

            console.error(' [+] Error occured while getting bookmark: ', error)

        }

    }

    /*******************************************************************************************************
     *
     *  VanillaReaderDataStore/VanillaReaderBookmarkIndexedDbDataStore.ts
     *
     *  async fetchBookmarksByPublication(publicationId: string) {}
     *
     *******************************************************************************************************/
    public async fetchBookmarksByPublication(publicationId: string) {

        const url = this.FETCH_BOOKMARKS_BY_PUBLICATION_API_URL

        const body = JSON.stringify({

            user_id: this.USER.id,
            book_id: this.USER.book_id,
            publication_id: publicationId,

        })

        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            })

            if (!response.ok) {
                throw new Error(' [+] Failed to get bookmarks by publication id.')
            }

            const data = await response.json()

            return data

        } catch (error) {

            console.error(' [+] Error occured while getting bookmarks by publication id: ', error)

        }

    }

    /*******************************************************************************************************
     *
     *  VanillaReaderDataStore/VanillaReaderBookmarkIndexedDbDataStore.ts
     *
     *  async fetchAllBookmarks() {}
     *
     *******************************************************************************************************/
    public async fetchAllBookmarks() {

        const url = this.FETCH_ALL_BOOKMARKS_API_URL

        const body = JSON.stringify({

            user_id: this.USER.id,
            book_id: this.USER.book_id,

        })

        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            })

            if (!response.ok) {
                throw new Error(' [+] Failed to get all bookmarks.')
            }

            const data = await response.json()

            return data

        } catch (error) {

            console.error(' [+] Error occured while getting all bookmarks: ', error)

        }

    }

    /*******************************************************************************************************
     *
     *  VanillaReaderDataStore/VanillaReaderBookmarkIndexedDbDataStore.ts
     *
     *  async deleteBookmark(locator: string) {}
     *
     *******************************************************************************************************/
    public async deleteBookmark(locator: string) {

        const url = this.DELETE_BOOKMARK_API_URL

        const body = JSON.stringify({

            user_id: this.USER.id,
            book_id: this.USER.book_id,
            locator: locator,

        })

        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            })

            if (!response.ok) {
                throw new Error(' [+] Failed to delete bookmark.')
            }

            const data = await response.json()

            return data

        } catch (error) {

            console.error(' [+] Error occured while deleting bookmark: ', error)

        }

    }

    /*******************************************************************************************************
     *
     *  VanillaReaderDataStore/VanillaReaderBookmarkIndexedDbDataStore.ts
     *
     *  private async _addBookmark(...) {}
     *
     *******************************************************************************************************/
    public async deleteAllBookmarksByPublication(publicationId: string) {

        const url = this.DELETE_ALL_BOOKMARKS_BY_PUBLICATION_API_URL

        const body = JSON.stringify({

            user_id: this.USER.id,
            book_id: this.USER.book_id,
            publication_id: publicationId,

        })

        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            })

            if (!response.ok) {
                throw new Error(' [+] Failed to delete all bookmarks by publication.')
            }

            const data = await response.json()

            return data

        } catch (error) {

            console.error(' [+] Error occured while deleting all bookmarks by publication: ', error)

        }

    }

    /*******************************************************************************************************
     *
     *  VanillaReaderDataStore/VanillaReaderBookmarkIndexedDbDataStore.ts
     *
     *  private async _addBookmark(...) {}
     *
     *******************************************************************************************************/
    public async deleteAllBookmarks() {

        const url = this.DELETE_ALL_BOOKMARKS_API_URL

        const body = JSON.stringify({

            user_id: this.USER.id,
            book_id: this.USER.book_id,

        })

        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            })

            if (!response.ok) {
                throw new Error(' [+] Failed to delete all bookmarks.')
            }

            const data = await response.json()

            return data

        } catch (error) {

            console.error(' [+] Error occured while deleting all bookmarks: ', error)

        }

    }


    /*******************************************************************************************************
     *
     * src/VanillaReaderDataStore/VanillaReaderHighlightIndexedDbDataStore.ts
     *
     * async addHighlight(highlightData: IVanillaReaderHighlightData) {}
     *
     *******************************************************************************************************/
    // @ts-ignore
    public async addHighlight(highlightData: IVanillaReaderHighlightData) {

        const url = this.ADD_HIGHLIGHT_API_URL

        const body = JSON.stringify({

            user_id: this.USER.id,
            book_id: this.USER.book_id,
            highlight_data: highlightData,

        })

        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            })

            if (!response.ok) {
                throw new Error(' [+] Failed to create highlight.')
            }

            const data = await response.json()

            return data

        } catch (error) {

            console.error(' [+] Error occured while creating highlight: ', error)

        }

    }

    /*******************************************************************************************************
     *
     * src/VanillaReaderDataStore/VanillaReaderHighlightIndexedDbDataStore.ts
     *
     * async updateHighlight(highlightData: IVanillaReaderHighlightData) {}
     *
     *******************************************************************************************************/
    // @ts-ignore
    public async updateHighlight(highlightData: IVanillaReaderHighlightData) {

        const url = this.UPDATE_HIGHLIGHT_API_URL

        const body = JSON.stringify({

            user_id: this.USER.id,
            book_id: this.USER.book_id,
            highlight_data: highlightData,

        })

        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            })

            if (!response.ok) {
                throw new Error(' [+] Failed to update highlight.')
            }

            const data = await response.json()

            return data

        } catch (error) {

            console.error(' [+] Error occured while updating highlight: ', error)

        }

    }

    /*******************************************************************************************************
    *
    * src/VanillaReaderDataStore/VanillaReaderHighlightIndexedDbDataStore.ts
    *
    * async addHighlight(highlightData: IVanillaReaderHighlightData) {}
    * async fetchHighlight(locator: string) {}
    *
    *******************************************************************************************************/
    // @ts-ignore
    public async fetchHighlight(field_data: object) {

        const url = this.FETCH_HIGHLIGHT_API_URL

        const body = JSON.stringify({

            user_id: this.USER.id,
            book_id: this.USER.book_id,

            // @ts-ignore
            field_name: field_data.name,
            // @ts-ignore
            field_value: field_data.value,

        })

        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            })

            if (!response.ok) {
                throw new Error(' [+] Failed to get highlight.')
            }

            const data = await response.json()

            return data

        } catch (error) {

            console.error(' [+] Error occured while getting highlight: ', error)
            throw error

        }

    }


    /*******************************************************************************************************
     *
     * src/VanillaReaderDataStore/VanillaReaderHighlightIndexedDbDataStore.ts
     *
     * async fetchHighlightsByPublication(publicationId: string) {}
     *
     *******************************************************************************************************/
    // @ts-ignore
    public async fetchHighlightsByPublication(publicationId: string) {

        const url = this.FETCH_HIGHLIGHTS_BY_PUBLICATION_API_URL

        const body = JSON.stringify({

            user_id: this.USER.id,
            book_id: this.USER.book_id,
            publication_id: publicationId,

        })

        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            })

            if (!response.ok) {
                throw new Error(' [+] Failed to get highlight by publication id.')
            }

            const data = await response.json()

            return data

        } catch (error) {

            console.error(' [+] Error occured while getting highlight by publication id: ', error)

        }

    }

    /*******************************************************************************************************
    *
    * src/VanillaReaderDataStore/VanillaReaderHighlightIndexedDbDataStore.ts
    *
    *  async fetchAllHighlights() {}
    *
    *******************************************************************************************************/
    // @ts-ignore
    public async fetchAllHighlights() {

        const url = this.FETCH_ALL_HIGHLIGHTS_API_URL

        const body = JSON.stringify({

            user_id: this.USER.id,
            book_id: this.USER.book_id,

        })

        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            })

            if (!response.ok) {
                throw new Error(' [+] Failed to get highlights.')
            }

            const data = await response.json()

            return data

        } catch (error) {

            console.error(' [+] Error occured while getting highlights: ', error)

        }

    }

    /*******************************************************************************************************
     *
     * src/VanillaReaderDataStore/VanillaReaderHighlightIndexedDbDataStore.ts
     *
     * fun
     *
     *******************************************************************************************************/
    // @ts-ignore
    public async deleteHighlight(locator: string) {

        const url = this.DELETE_HIGHLIGHT_API_URL

        const body = JSON.stringify({

            user_id: this.USER.id,
            book_id: this.USER.book_id,
            locator: locator,

        })

        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            })

            if (!response.ok) {
                throw new Error(' [+] Failed to delete highlight.')
            }

            const data = await response.json()

            return data

        } catch (error) {

            console.error(' [+] Error occured while deleting highlight: ', error)

        }

    }
    /*******************************************************************************************************
     *
     * src/VanillaReaderDataStore/VanillaReaderHighlightIndexedDbDataStore.ts
     *
     * async deleteAllHighlightsByPublication(publicationId: string) {}
     *
     *******************************************************************************************************/
    // @ts-ignore
    public async deleteAllHighlightsByPublication(publicationId: string) {

        const url = this.DELETE_HIGHLIGHTS_BY_PUBLICATION_API_URL

        const body = JSON.stringify({

            user_id: this.USER.id,
            book_id: this.USER.book_id,
            publication_id: publicationId,

        })

        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            })

            if (!response.ok) {
                throw new Error(' [+] Failed to delete all highlights by publication.')
            }

            const data = await response.json()

            return data

        } catch (error) {

            console.error(' [+] Error occured while deleting all highlights by publication: ', error)

        }

    }
    /*******************************************************************************************************
     *
     * src/VanillaReaderDataStore/VanillaReaderHighlightIndexedDbDataStore.ts
     *
     * async deleteAllHighlights() {}
     *
     *******************************************************************************************************/
    // @ts-ignore
    public async deleteAllHighlights() {

        const url = this.DELETE_ALL_HIGHLIGHTS_API_URL

        const body = JSON.stringify({

            user_id: this.USER.id,
            book_id: this.USER.book_id,

        })

        try {

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: body
            })

            if (!response.ok) {
                throw new Error(' [+] Failed to delete all highlights.')
            }

            const data = await response.json()

            return data

        } catch (error) {

            console.error(' [+] Error occured while deleting all highlights: ', error)

        }

    }


    /**
     * Parses the URL parameters and returns them as an object.
     * @param url - The URL to parse. If not provided, the current window location is used.
     * @returns An object containing the URL parameters.
     */
    private getUrlParams(url?: string): Record<string, string> {

        const urlString = url || window.location.href;
        const queryString = urlString.split('?')[1] || '';
        const urlParams = new URLSearchParams(queryString);
        const paramsObject: Record<string, string> = {};

        // @ts-ignore
        for (const [key, value] of urlParams) {
            paramsObject[key] = value;
        }

        return paramsObject;

    }

    // public loader(display: string = 'hide') {

    //     let loader_id = '#imbiblioapp-reader-loader'
    //     let loader = document.querySelector(loader_id)

    //     if (loader) {

    //         if (display == 'show') {

    //             // @ts-ignore
    //             loader.style.display = 'block'

    //         }
    //         else if (display == 'hide') {

    //             // @ts-ignore
    //             loader.style.display = 'none'

    //         }

    //     }

    // }

    // public loader(display: string = 'hide'): void {
    //     const loaderId: string = '#imbiblioapp-reader-loader';
    //     const loader: HTMLElement | null = document.querySelector(loaderId);
    //     const readerViewId: string = '#vanilla-reader__reader-view';
    //     const readerView: HTMLElement | null = document.querySelector(readerViewId);
    
    //     if (loader && readerView) {
    //         loader.style.display = 'block';
    
    //         const observer: MutationObserver = new MutationObserver((mutationsList) => {
    //             mutationsList.forEach((mutation) => {
    //                 if (mutation.type === 'childList' && readerView.children.length > 0) {
    //                     loader.style.display = 'none';
    //                     observer.disconnect();  
    //                 }
    //             });
    //         });
    
    //         observer.observe(readerView, {
    //             childList: true,  
    //             subtree: false   
    //         });
    //     }
    // }

    // public loader(display: string = 'hide'): void {
    //     const loaderId: string = '#imbiblioapp-reader-loader';
    //     const loader: HTMLElement | null = document.querySelector(loaderId);
    //     const readerViewId: string = '#vanilla-reader__reader-view';
    //     const readerView: HTMLElement | null = document.querySelector(readerViewId);
    //     const progressCircle: HTMLElement | null = document.querySelector('.circular-progress');
    
    //     let progress = 0;
        
    //     if (loader && readerView && progressCircle) {
    //         loader.style.display = 'block';
    //         progressCircle.style.setProperty('--progress', '0');
    
    //         const observer: MutationObserver = new MutationObserver((mutationsList) => {
    //             mutationsList.forEach((mutation) => {
    //                 if (mutation.type === 'childList' && readerView.children.length > 0) {
    //                     progress = 100;
    //                     progressCircle.style.setProperty('--progress', `${progress}`);
    //                     loader.style.display = 'none';
    //                     observer.disconnect();  
    //                 }
    //             });
    //         });
    
    //         observer.observe(readerView, {
    //             childList: true,  
    //             subtree: false   
    //         });

    //         const progressInterval = setInterval(() => {
    //             if (progress < 90) {
    //                 progress += 1;
    //                 progressCircle.style.setProperty('--progress', `${progress}`);
    //             } else {
    //                 clearInterval(progressInterval);
    //             }
    //         }, 100);  
    //     }
    // }

    // public loader(display: string = 'hide'): void {
    //     const loaderId: string = '#imbiblioapp-reader-loader';
    //     const loader: HTMLElement | null = document.querySelector(loaderId);
    //     const readerViewId: string = '#vanilla-reader__reader-view';
    //     const readerView: HTMLElement | null = document.querySelector(readerViewId);
    //     const progressCircle: HTMLElement | null = document.querySelector('.circular-progress');
        
    //     let progress = 0;
    //     let fallbackIncrement = 1;  // Set the increment to 1%
    
    //     if (loader && readerView && progressCircle) {
    //         loader.style.display = 'block';
    //         progressCircle.style.setProperty('--progress', '0');
    
    //         // Monitor child elements in readerView
    //         const observer: MutationObserver = new MutationObserver((mutationsList) => {
    //             mutationsList.forEach((mutation) => {
    //                 if (mutation.type === 'childList' && readerView.children.length > 0) {
    //                     // If child elements are detected, set progress to 100% and hide the loader
    //                     progress = 100;
    //                     progressCircle.style.setProperty('--progress', `${progress}`);
    //                     loader.style.display = 'none';
    //                     observer.disconnect();  // Stop observing once the content is loaded
    //                 }
    //             });
    //         });
    
    //         observer.observe(readerView, {
    //             childList: true,  // Watch for child elements being added
    //             subtree: false    // Do not observe the whole subtree
    //         });
    
    //         // Handle the HTTP request with progress tracking
    //         const xhr = new XMLHttpRequest();
    //         const url = 'http://localhost:8080/fetch_bookmarks_by_publication';
    
    //         xhr.addEventListener('progress', (event) => {
    //             if (event.lengthComputable) {
    //                 progress = (event.loaded / event.total) * 100;
    //                 progressCircle.style.setProperty('--progress', `${progress}`);
    //             } else {
    //                 // Fallback: Increment by 1% if the total size is unknown
    //                 if (progress < 90) {
    //                     progress += fallbackIncrement;
    //                     progressCircle.style.setProperty('--progress', `${progress}`);
    //                 }
    //             }
            
    //             // Ensure progress reaches 100% once the request is completed
    //             if (event.loaded === event.total || progress >= 100) {
    //                 progress = 100;
    //                 progressCircle.style.setProperty('--progress', '100');
    //                 if (!readerView.children.length) {
    //                     loader.style.display = 'none';
    //                 }
    //             }
    //         });            
    
    //         // Open and send the request
    //         xhr.open('GET', url, true);
    //         xhr.send();
    
    //         // Optional: Increment progress by 1% at regular intervals if no progress is detected
    //         const progressInterval = setInterval(() => {
    //             if (progress < 90) {
    //                 progress += fallbackIncrement;
    //                 progressCircle.style.setProperty('--progress', `${progress}`);
    //             } else {
    //                 clearInterval(progressInterval);  
    //             }
    //         }, 100);  
    //     }
    // } 
    
    public loader(display: string = 'hide'): void {
        const loaderId: string = '#imbiblioapp-reader-loader';
        const loader: HTMLElement | null = document.querySelector(loaderId);
        const readerViewId: string = '#vanilla-reader__reader-view';
        const readerView: HTMLElement | null = document.querySelector(readerViewId);
        const progressCircle: HTMLElement | null = document.querySelector('.circular-progress');
    
        let progress = 0;
    
        if (loader && readerView && progressCircle) {
            loader.style.display = 'block';
            progressCircle.style.setProperty('--progress', '0');
    
            // Monitor child elements in readerView
            const observer: MutationObserver = new MutationObserver((mutationsList) => {
                mutationsList.forEach((mutation) => {
                    if (mutation.type === 'childList' && readerView.children.length > 0) {
                        // When content is loaded, set progress to 100% and hide loader
                        progress = 100;
                        progressCircle.style.setProperty('--progress', `${progress}`);
                        loader.style.display = 'none';
                        observer.disconnect();
                    }
                });
            });
    
            observer.observe(readerView, {
                childList: true,
                subtree: false
            });
    
            const progressInterval = setInterval(() => {
                if (progress < 80) {
                    progress += 1;  
                } else if (progress >= 80 && progress < 90) {
                    progress += 0.5;  
                } else {
                    clearInterval(progressInterval);  
                }
                progressCircle.style.setProperty('--progress', `${progress}`);
            }, 100);  
        }
    }
    

    /**
     * Gets a singleton instance of the Reader class.
     * @returns The singleton instance of the Reader class.
     */
    public static getInstance(): Reader {
        if (!Reader.instance) {
            Reader.instance = new Reader();
        }
        return Reader.instance;
    }

    public appRefresh(): void {

        const url = new URL(window.location.href)
        url.searchParams.set('reloadTime', Date.now().toString())
        window.location.href = url.toString()

    }

    public static stopTheApp(): void {

        console.error('By Reader class...')
        throw new Error('Stop the app!')

    }

}
