/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IIntegerRange} from '@colibrio/colibrio-reader-framework/colibrio-core-base';
import {ILocator, Locator} from '@colibrio/colibrio-reader-framework/colibrio-core-locator';
import {MediaFragmentSelector} from '@colibrio/colibrio-reader-framework/colibrio-core-selector-media';

import {
    IContentLocation,
    ISyncMediaPlayer,
    ISyncMediaTimeline,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';
import {
    VanillaReaderAppEvents,
    VanillaReaderEventBus,
} from './VanillaReaderEventBus';
import {
    IVanillaReaderReadingProgressionTimeline,
    VanillaProgressEventCallback,
    VanillaTimelineReadyEventCallback
} from './VanillaReaderModel';

export class VanillaReaderAudiobookProgressionTimeline implements IVanillaReaderReadingProgressionTimeline {
    private _colibrioTimelineInstance: ISyncMediaTimeline;
    private _timelineUpdateIntentCallback: VanillaProgressEventCallback | undefined = undefined;

    constructor(private _colibrioSyncMediaPlayer: ISyncMediaPlayer) {
        this._colibrioTimelineInstance = this._colibrioSyncMediaPlayer.getTimeline();
    }

    fetchTimelinePosition(locator: ILocator): Promise<number> {
        let startPosition = locator.getSelectors()[0];

        if (!(startPosition instanceof MediaFragmentSelector)) {
            return Promise.reject();
        }

        let startMs = startPosition.getTimeInterval()?.startMs;

        if (typeof startMs !== 'number') {
            return Promise.reject('VanillaReaderAudiobookProgressionTimeline.updateTimelinePosition(): unable to resolve start position for locator ' + locator.toString());
        }

        return Promise.resolve(startMs);
    }

    async fetchContentLocation(timelinePosition: number): Promise<IContentLocation> {
        let mediaFragmentSelector = new MediaFragmentSelector({
            timeInterval: {
                startMs: timelinePosition,
            },
        }, []);
        let locator = new Locator(this._colibrioTimelineInstance.getReaderPublication().getDefaultLocatorUrl(), [mediaFragmentSelector]);
        let position = await this._colibrioTimelineInstance.fetchTimelinePosition(locator);
        return this._colibrioTimelineInstance.fetchContentLocation(position);
    }

    async fetchTimelineRange(_location: ILocator): Promise<IIntegerRange> {
        return Promise.reject('Not implemented for VanillaReaderAudiobookProgressionTimeline');
    }

    updateTimelinePosition(locator: ILocator) {
        const updateFn = () => {
            let startPosition = locator.getSelectors()[0];
            if (startPosition instanceof MediaFragmentSelector) {
                let startMs = startPosition.getTimeInterval()?.startMs;

                if (typeof startMs !== 'number') {
                    console.warn('VanillaReaderAudiobookProgressionTimeline.updateTimelinePosition(): unable to resolve start position for locator ' + locator.toString());
                    return;
                }

                if (this._timelineUpdateIntentCallback) {
                    this._timelineUpdateIntentCallback(startMs);
                }
            }
        };

        if (!this._colibrioTimelineInstance) {
            VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.TIMELINE_READY, updateFn, true);
        } else {
            updateFn();
        }

    }

    getLength(): number {
        return this._colibrioTimelineInstance.getApproximateDurationMs();
    }

    toSerializedData(): undefined {
        return undefined;
    }

    onTimelineReady(callback: VanillaTimelineReadyEventCallback) {
        Promise.resolve().then(() => {
            callback(this._colibrioTimelineInstance.getApproximateDurationMs());
        }).catch();
    }

    onTimelineUpdateIntent(callback: VanillaProgressEventCallback) {
        this._timelineUpdateIntentCallback = callback;
    }

    isReady() {
        return this._colibrioSyncMediaPlayer.isReady();
    }

    getBackingTimelineImplementation(): ISyncMediaTimeline {
        return this._colibrioTimelineInstance;
    }

}