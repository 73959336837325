/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {
    IVanillaReaderAppEvent_PUBLICATION_LOAD_FROM_STORE_INTENT,
    IVanillaReaderAppEvent_PUBLICATION_LOAD_INTENT,
    IVanillaReaderAppEvent_PUBLICATION_LOAD_URL_INTENT, IVanillaReaderAppEvent_PUBLICATION_RESOURCES_DELETE_INTENT,
    VanillaReaderAppEvents,
    VanillaReaderEventBus
} from "../VanillaReader/VanillaReaderEventBus";
import {IVanillaReaderPublicationData, IVanillaReaderShelfItemData} from "../VanillaReader/VanillaReaderModel";
import {IVanillaReaderUI} from "./IVanillaReaderUI";

export interface VanillaReaderUiDialogOpenFileController extends IVanillaReaderUI {}

/**
 * # VanillaReaderUiDialogOpenFileController
 *
 * ## RESPONSIBILITIES
 * This controller mixing class handles all event callbacks from the `VanillaReaderUiDialogOpenFile` class.
 *
 * Just as all other controller mixin classes, this class is used to add functionality to the `VanillaReaderUi` class
 * in a "modular" way. Read more about this in the `UI Components and Controller Mixins` in the README.md file.
 *
 * ## RELATED TYPES
 * - VanillaReaderUi
 * -VanillaReaderUiDialogOpenFile
 *
 */

export class VanillaReaderUiDialogOpenFileController {

    async _onDialogOpenFileSelected (fileObject: File) {
        let publicationData = await this.vanillaReaderPublicationDataStore.fetchPublicationDataByFileName(fileObject.name);
        let storeOnDevice = this.useDeviceStorage; // If we run as a Progressive Web App we can store the downloaded data for offline reading.

        if (publicationData) {
            this.initialReadingPosition = await this.vanillaReaderPublicationDataStore.fetchPublicationReadingPositionData(publicationData.id);
            this.initialPublicationData = publicationData;
        }

        VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_PUBLICATION_LOAD_INTENT>(VanillaReaderAppEvents.PUBLICATION_LOAD_INTENT, {
            detail: {publicationFile: fileObject, publicationData, storeOnDevice},
        }));

        this.toaster.toast(`Opening ${fileObject.name}`).catch(console.warn);
    };

    async _onDialogOpenFileOpenShelfItem (shelfItem: IVanillaReaderShelfItemData) {
        let publicationData = await this.vanillaReaderPublicationDataStore.fetchPublicationDataByFileName(shelfItem.fileName);

        if (publicationData) {
            this.initialReadingPosition = await this.vanillaReaderPublicationDataStore.fetchPublicationReadingPositionData(publicationData.id);
            this.initialPublicationData = publicationData;
        }

        if (shelfItem.hasOfflineData) {
            VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_PUBLICATION_LOAD_FROM_STORE_INTENT>(VanillaReaderAppEvents.PUBLICATION_LOAD_FROM_STORE_INTENT, {
                detail: {fileName: shelfItem.fileName, fileSourceUri: shelfItem.fileSourceUri, publicationData},
            }));
        }

        this.toaster.toast(`Opening ${shelfItem.fileName}`).catch(console.warn);

    };

    async _onDialogOpenFileOpenUrl(fileUrl: string) {
        // We should really use the url here as the file name is not guaranteed to be unique.
        let fileName = fileUrl.slice(fileUrl.lastIndexOf('/') + 1);
        // If we run as a Progressive Web App we can store the downloaded data for offline reading.
        let storeOnDevice = this.useDeviceStorage;

        let publicationData = await this.vanillaReaderPublicationDataStore.fetchPublicationDataByFileName(fileName);

        if (publicationData) {
            this.initialReadingPosition = await this.vanillaReaderPublicationDataStore.fetchPublicationReadingPositionData(publicationData.id);
            this.initialPublicationData = publicationData;
        }

        VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_PUBLICATION_LOAD_URL_INTENT>(VanillaReaderAppEvents.PUBLICATION_LOAD_URL_INTENT, {
            detail: {publicationUrl: fileUrl, publicationData, storeOnDevice},
        }));

        this.toaster.toast(`Opening ${fileName}`).catch(console.warn);
    };

    _onDialogOpenFilePublicationResourcesDeleteButtonClicked(shelfItemData: IVanillaReaderPublicationData) {
        if (window.confirm(`Are you sure you want to remove "${shelfItemData.title}", including all bookmarks and annotations?`)) {
            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_PUBLICATION_RESOURCES_DELETE_INTENT>(new CustomEvent<IVanillaReaderAppEvent_PUBLICATION_RESOURCES_DELETE_INTENT>(VanillaReaderAppEvents.PUBLICATION_RESOURCES_DELETE_INTENT, {detail: {shelfItem: shelfItemData}}))
        }
    };
}