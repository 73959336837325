/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {
    IVanillaReaderAppEvent_MEDIAPLAYER_PAUSE_INTENT,
    IVanillaReaderAppEvent_MEDIAPLAYER_PLAY_INTENT,
    IVanillaReaderAppEvent_MEDIAPLAYER_RATE_CHANGE_INTENT,
    IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_BACKWARD_INTENT,
    IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_FORWARD_INTENT,
    IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_NEXT_INTENT,
    IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_PREVIOUS_INTENT,
    IVanillaReaderAppEvent_MEDIAPLAYER_TOGGLE_INTENT,
    IVanillaReaderAppEvent_MEDIAPLAYER_VOICE_CHANGE_INTENT,
    IVanillaReaderAppEvent_MEDIAPLAYER_VOLUME_CHANGE_INTENT,
    VanillaReaderAppEvents,
    VanillaReaderEventBus
} from "../VanillaReader/VanillaReaderEventBus";
import {IVanillaReaderUI} from "./IVanillaReaderUI";
import {IVanillaReaderUiComponentController} from "./IVanillaReaderUiComponentController";
import {VanillaReaderUI} from "./VanillaReaderUI";

export interface VanillaReaderUiMenuMediaPlayerController extends IVanillaReaderUiComponentController, IVanillaReaderUI {}

/**
 * # VanillaReaderUiMenuMediaPlayerController
 *
 * ## RESPONSIBILITIES
 * This controller mixing class handles all event callbacks from the `VanillaReaderUiMenuMediaPlayer` class.
 * It also holds all the playback related methods to that send intents to the `VanillaReader` to trigger behaviours
 * such as play and pause.
 *
 * Just as all other controller mixin classes, this class is used to add functionality to the `VanillaReaderUi` class
 * in a "modular" way. Read more about this in the `UI Components and Controller Mixins` in the README.md file.
 *
 * ## RELATED TYPES
 * - VanillaReaderUi
 * - VanillaReaderUiMenuMediaPlayer
 */
export class VanillaReaderUiMenuMediaPlayerController {

    static __initialize__(vanillaReaderUiContext: VanillaReaderUI) {
        vanillaReaderUiContext.menuMediaPlayer.onOpened(vanillaReaderUiContext._onMenuMediaPlayerOpened.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.menuMediaPlayer.onClosed(vanillaReaderUiContext._onMenuMediaPlayerClosed.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.menuMediaPlayer.onPlay(vanillaReaderUiContext._onMenuMediaPlayerPlayIntent.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.menuMediaPlayer.onPause(vanillaReaderUiContext._onMenuMediaPlayerPauseIntent.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.menuMediaPlayer.onSeekNext(vanillaReaderUiContext._onMenuMediaPlayerSeekNextIntent.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.menuMediaPlayer.onSeekPrevious(vanillaReaderUiContext._onMenuMediaPlayerPreviousIntent.bind(vanillaReaderUiContext));

    }

    startPlayback(locator?: string) {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_MEDIAPLAYER_PLAY_INTENT>(new CustomEvent(VanillaReaderAppEvents.MEDIAPLAYER_PLAY_INTENT, {detail: {locator}}));
    }

    pausePlayback() {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_MEDIAPLAYER_PAUSE_INTENT>(new CustomEvent(VanillaReaderAppEvents.MEDIAPLAYER_PAUSE_INTENT));
    }

    seekNext() {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_NEXT_INTENT>(new CustomEvent(VanillaReaderAppEvents.MEDIAPLAYER_SEEK_NEXT_INTENT));
    }

    seekPrevious() {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_PREVIOUS_INTENT>(new CustomEvent(VanillaReaderAppEvents.MEDIAPLAYER_SEEK_PREVIOUS_INTENT));
    }

    seekForward() {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_FORWARD_INTENT>(new CustomEvent(VanillaReaderAppEvents.MEDIAPLAYER_SEEK_FORWARD_INTENT));
    }

    seekBackward() {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_BACKWARD_INTENT>(new CustomEvent(VanillaReaderAppEvents.MEDIAPLAYER_SEEK_BACKWARD_INTENT));
    }

    setPlaybackRate(rate: number) {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_MEDIAPLAYER_RATE_CHANGE_INTENT>(new CustomEvent(VanillaReaderAppEvents.MEDIAPLAYER_RATE_CHANGE_INTENT, {detail: {rate}}));
    }

    setPlaybackVolume(volume: number) {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_MEDIAPLAYER_VOLUME_CHANGE_INTENT>(new CustomEvent(VanillaReaderAppEvents.MEDIAPLAYER_VOLUME_CHANGE_INTENT, {detail: {volume}}));
    }

    setTtsVoice(voiceName: string) {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_MEDIAPLAYER_VOICE_CHANGE_INTENT>(new CustomEvent(VanillaReaderAppEvents.MEDIAPLAYER_VOICE_CHANGED, {detail: {voiceName}}));
    }

    togglePlayback(activate: boolean) {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_MEDIAPLAYER_TOGGLE_INTENT>(new CustomEvent(VanillaReaderAppEvents.MEDIAPLAYER_TOGGLE_INTENT, {
            detail: {
                activate,
                options: {rate: this.dialogSettings.activePlaybackRateOption}
            }
        }));
    }

    _onMenuMediaPlayerOpened(){
        this.fabMediaPlayer.stateIsPlaying = false;
        this.fabMediaPlayer.show();

        this.togglePlayback(true);

        // Save the updated option value
        let updatedUiOptions = this.serializeToUiOptions();
        this.vanillaReaderOptionsDataStore.setVanillaReaderUiOptionsData(updatedUiOptions).catch(console.warn);

    };

    _onMenuMediaPlayerClosed() {
        this.fabMediaPlayer.stateIsPlaying = false;
        this.fabMediaPlayer.hide();
        this.togglePlayback(false);

        // Save the updated option value
        let updatedUiOptions = this.serializeToUiOptions();
        this.vanillaReaderOptionsDataStore.setVanillaReaderUiOptionsData(updatedUiOptions).catch(console.warn);

    };

    _onMenuMediaPlayerPlayIntent (locator?: string) {
        this.startPlayback(locator);
    };

    _onMenuMediaPlayerPauseIntent() {
        this.pausePlayback();
    };

    _onMenuMediaPlayerSeekNextIntent() {
        this.seekNext();
    };

    _onMenuMediaPlayerPreviousIntent() {
        this.seekPrevious();
    };

    _onMediaPlayerIsReadyToPlay() {
        this.menuMediaPlayer.enable();
        if (this.menuMediaPlayer.isOpen() && this.mediaPlayerShouldStartPlaybackWhenReady) {
            this._onMenuMediaPlayerPlayIntent();
        }
    };
}