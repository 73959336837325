/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import { Dexie } from 'dexie';
import { VanillaHighlightDataCallback, VanillaVoidCallback } from '../VanillaReader/VanillaReaderModel';
import { IVanillaReaderHighlightData } from '../VanillaReader/VanillaReaderModel';
import { IVanillaReaderHighlightDataStore } from './IVanillaReaderHighlightDataStore';


/**
 * # VanillaReaderHighlightIndexedDbDataStore
 *
 *  ## RESPONSIBILITIES
 *
 * This class acts as a runtime storage for the highlights that have been added to a publication.
 * Again, it does not persist its state anywhere. To save highlights between sessions call `getHighlights()` and
 * store the returned data. To restore state you provide the same data to the `addHighlights()` method.
 *
 * ## NOTE
 *
 * This is just the runtime storage, the rendering of highlights is done in the `VanillaReaderAnnotationLayerHighlights`
 * class.
 *
 */

export class VanillaReaderHighlightIndexedDbDataStore extends Dexie implements IVanillaReaderHighlightDataStore {

    // private _highlights: Dexie.Table<IVanillaReaderHighlightData, string>;

    private _highlightAddedCallback: VanillaHighlightDataCallback | undefined = undefined;
    private _highlightDeletedCallback: VanillaHighlightDataCallback | undefined = undefined;
    private _highlightUpdatedCallback: VanillaHighlightDataCallback | undefined = undefined;
    private _highlightsCollectionUpdatedCallback: VanillaVoidCallback | undefined = undefined;

    constructor(dbName: string = 'highlights.vanillareader.colibrio.com') {

        super(dbName);

        // this.version(1).stores({
        //     highlights: '_id,userid,locator, color, bookSection, publicationId, dateCreated',
        // });

        // this._highlights = this.table('highlights');

    }

    onHighlightAdded(callback: VanillaHighlightDataCallback) {
        this._highlightAddedCallback = callback;
    }

    onHighlightUpdated(callback: VanillaHighlightDataCallback) {
        this._highlightUpdatedCallback = callback;
    }

    onHighlightDeleted(callback: VanillaHighlightDataCallback) {
        this._highlightDeletedCallback = callback;
    }

    onHighlightsCollectionUpdated(callback: VanillaVoidCallback) {
        this._highlightsCollectionUpdatedCallback = callback;
    }

    async addHighlight(highlightData: IVanillaReaderHighlightData): Promise<IVanillaReaderHighlightData> {

        let targetLocator = highlightData.locator
        let field_data = { name: 'locator', value: targetLocator }

        // @ts-ignore
        let data = await window.reader.fetchHighlight(field_data)

        if (!data.data.highlight) {
            return this._addHighlight(highlightData).catch()
        }

        else {

            console.warn(`VanillaReaderHighlightStore.addHighlight(): location "${targetLocator}" is already highlighted.`)
            return Promise.reject(`VanillaReaderHighlightStore.addHighlight(): location "${targetLocator}" is already highlighted.`)
        }

    }

    async addHighlights(highlightData: IVanillaReaderHighlightData[], silent: boolean = true): Promise<void> {

        highlightData.forEach((highlight: IVanillaReaderHighlightData) => {
            this._addHighlight(highlight, silent).catch(console.warn)
        })

        return Promise.resolve()

    }

    async updateHighlight(highlightData: IVanillaReaderHighlightData): Promise<void> {

        let highlight_id = highlightData._id
        let field_data = { name: '_id', value: highlight_id }

        // @ts-ignore
        let data = await window.reader.fetchHighlight(field_data)

        if (data.data.highlight && Object.keys(data.data.highlight).length > 0) {

            highlightData.dateUpdated = Date.now()

            // @ts-ignore
            await window.reader.updateHighlight(highlightData)

            if (this._highlightUpdatedCallback) {
                this._highlightUpdatedCallback(highlightData)
            }
            if (this._highlightsCollectionUpdatedCallback) {
                this._highlightsCollectionUpdatedCallback()
            }

        } else {

            console.warn(`VanillaReaderHighlightStore.addHighlight(): no highlight found for location "${highlightData.locator}".`)
            return Promise.reject(`VanillaReaderHighlightStore.addHighlight(): no highlight found for location "${highlightData.locator}".`)

        }

    };

    async fetchHighlight(locator: string): Promise<IVanillaReaderHighlightData | undefined> {

        let field_data = { name: 'locator', value: locator }

        // @ts-ignore
        let data = await window.reader.fetchHighlight(field_data)

        if (data && Array.isArray(data.data.highlight)) {

            return data.highlight

        } else {
            console.warn(`VanillaReaderHighlightStore.addHighlight(): no highlight found for location "${locator}".`)
        }

        return;

    }

    async fetchHighlightsByPublication(publicationId: string): Promise<IVanillaReaderHighlightData[]> {

        try {
            // @ts-ignore
            let data = await window.reader.fetchHighlightsByPublication(publicationId)

            if (data && data.data && Array.isArray(data.data.highlights)) {

                return data.data.highlights

            } else {
                console.warn('No highlights found or invalid data format for publication:', publicationId)
                return []
            }

        } catch (error) {
            console.error('Error fetching highlights by publication:', error)
            return []
        }

    }


    async fetchAllHighlights(): Promise<IVanillaReaderHighlightData[]> {

        try {
            // @ts-ignore
            let data = await window.reader.fetchAllHighlights()

            if (data && data.data && Array.isArray(data.data.highlights)) {

                return data.data.highlights

            } else {
                console.warn('No highlights found or invalid data format')
                return []
            }

        } catch (error) {
            console.error('Error fetching highlights:', error)
            return []
        }

    }

    async deleteHighlight(locator: string): Promise<void> {

        let field_data = { name: 'locator', value: locator }

        try {

            // @ts-ignore
            let data = await window.reader.fetchHighlight(field_data)

            if (data.data.highlight && Object.keys(data.data.highlight).length > 0) {

                // @ts-ignore
                await window.reader.deleteHighlight(locator)

                if (this._highlightDeletedCallback) {
                    this._highlightDeletedCallback(data.data.highlight)
                }

                if (this._highlightsCollectionUpdatedCallback) {
                    this._highlightsCollectionUpdatedCallback()
                }

            } else {
                console.warn(`VanillaReaderHighlightStore.deleteHighlight(): location "${locator}" is not highlighted.`)
                return Promise.reject(`VanillaReaderHighlightStore.deleteHighlight(): location "${locator}" is not highlighted.`)
            }

        } catch (error) {
            console.error(`Error deleting highlight at location "${locator}":`, error)
            return Promise.reject(error)
        }

    }

    async deleteAllHighlightsByPublication(publicationId: string): Promise<void> {

        // @ts-ignore
        await window.reader.deleteAllHighlightsByPublication(publicationId)

        return Promise.resolve()

    }

    async deleteAllHighlights(): Promise<void> {

        // @ts-ignore
        await window.reader.deleteAllHighlights()

        return Promise.resolve()

    }

    /**
     *
     * PRIVATE METHODS
     *
     * */

    private async _addHighlight(
        highlightData: IVanillaReaderHighlightData,
        silent: boolean = false,
    ): Promise<IVanillaReaderHighlightData> {

        if (!highlightData.dateCreated) {
            highlightData.dateCreated = Date.now()
        }

        if (!highlightData.dateUpdated) {
            highlightData.dateUpdated = Date.now()
        }

        if (!highlightData._id) {
            highlightData._id = Date.now().toString()
        }

        // @ts-ignore
        await window.reader.addHighlight(highlightData)

        if (this._highlightAddedCallback) {
            this._highlightAddedCallback(highlightData, silent)
        }
        if (this._highlightsCollectionUpdatedCallback) {
            this._highlightsCollectionUpdatedCallback()
        }

        return highlightData
    }
}