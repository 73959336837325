/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IAttributeData, TreeNodeWalker} from '@colibrio/colibrio-reader-framework/colibrio-core-base';
import {
    IContentBlock,
    IContentBlockData,
    IReaderDocument,
    IReaderPublication,
    TextContentBlockType,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';
import {
    IVanillaReaderDocumentLandmarkData,
    IVanillaReaderLandmarkData,
    IVanillaReaderPublicationLandmarkData,
    IVanillaReaderReadingProgressionTimeline,
    VanillaReaderPublicationLandmarkType,
} from './VanillaReaderModel';

/*
* # VanillaReaderPublicationLandmarks
*
* ## RESPONSIBILITIES
*
* * ## PRIMARY COLIBRIO FRAMEWORK TYPES
*
* - IReaderPublication
* - IContentPositionTimeline
* - IPageProgressionTimeline
*
* ## NOTES:
*
*
* */

export class VanillaReaderPublicationLandmarkCollection {
    private _documentIndexToLandmarksMap: Map<number, IVanillaReaderDocumentLandmarkData> = new Map();
    private _landmarkToDocumentIndex: Map<IVanillaReaderLandmarkData, number> = new Map();
    private _blockIdToLandmarkMap: Map<string, IVanillaReaderLandmarkData> = new Map();
    private _hasBeenPopulated: boolean = false;

    constructor(
        private _readerPublication: IReaderPublication,
        private _contentPositionTimeline?: IVanillaReaderReadingProgressionTimeline,
    ) {
    }

    async populate(documentIndexes?: number[], landmarkTypes?: VanillaReaderPublicationLandmarkType[]) {
        await this._populate(this._readerPublication, documentIndexes, landmarkTypes).catch(console.error);
    }

    setPublicatonLandmarkData(stateData: IVanillaReaderPublicationLandmarkData) {
        this._deserializeFromStateData(stateData);
    }

    getAll(): IVanillaReaderPublicationLandmarkData {
        let publicationLandmarkData: IVanillaReaderPublicationLandmarkData = {
            publicationId: this._readerPublication.getSourcePublication().getMetadata().getIdentifiers()[0]?.content.value.toString(),
            documents: [],
        };
        let landmarks: IVanillaReaderDocumentLandmarkData[] = [];

        this._documentIndexToLandmarksMap.forEach((
            _doc: IVanillaReaderDocumentLandmarkData,
            key: number,
        ) => {
            let landmark = this._documentIndexToLandmarksMap!.get(key);
            if (landmark && landmarks.findIndex(item => item === landmark) < 0) {
                landmarks.push(landmark);
            }
        });
        publicationLandmarkData.documents = landmarks;
        return publicationLandmarkData;
    }

    getHeadings(level?: TextContentBlockType): IVanillaReaderLandmarkData[] {
        let filteredResult: IVanillaReaderLandmarkData[] = Array.from(this._landmarkToDocumentIndex.keys());
        return filteredResult.filter((item) => {
            if (level) {
                return item.blockType.indexOf(level) >= 0;
            } else {
                return item.blockType.indexOf('HEADING') >= 0;
            }
        });
    }

    getFootnotes(): IVanillaReaderLandmarkData[] {
        let filteredResult: IVanillaReaderLandmarkData[] = Array.from(this._landmarkToDocumentIndex.keys());
        return filteredResult.filter((item) => {
            return item.subType && item.subType.indexOf('FOOTNOTE') >= 0;
        });
    }

    getFigures(): IVanillaReaderLandmarkData[] {
        let filteredResult: IVanillaReaderLandmarkData[] = Array.from(this._landmarkToDocumentIndex.keys());
        return filteredResult.filter((item) => {
            return item.blockType.includes('IMAGE') || item.blockType.includes('FIGURE');
        });
    }

    getLandmarkById(id: string): IVanillaReaderLandmarkData | undefined {
        return this._blockIdToLandmarkMap.get(id);
    }

    private async _populate(
        readerPublication: IReaderPublication,
        documentIndexes?: number[],
        landmarkTypes?: VanillaReaderPublicationLandmarkType[],
    ) {

        if (this._hasBeenPopulated) {
            return;
        }

        let documentsToSearch = readerPublication.getSpine();

        // Check if we should search only some elements as listed in the `documentIndexes` argument.
        if (documentIndexes) {
            documentsToSearch = readerPublication.getSpine().filter((doc: IReaderDocument) => {
                return documentIndexes.includes(doc.getSourceContentDocument().getIndexInSpine());
            });
        }

        for (const doc of documentsToSearch) {
            let documentIndex = doc.getSourceContentDocument().getIndexInSpine();

            if (this._documentIndexToLandmarksMap!.has(documentIndex)) {
                continue;
            }

            let docLandmarks: IVanillaReaderDocumentLandmarkData = {
                documentIndex,
                headings: [],
                tables: [],
                figures: [],
                audio: [],
                video: [],
                lists: [],
                sections: [],
                articles: [],
                asides: [],
                footnotes: [],
            };

            let contentBlockTree = await doc.fetchContentBlockTree();

            let walker = new TreeNodeWalker(contentBlockTree.getContentBlocks());

            while (walker.hasNext()) {
                let addBlock: boolean = false;
                let block = walker.next() as IContentBlock;
                let blockType = (block.getBlockType() as unknown) as VanillaReaderPublicationLandmarkType;
                let collectionToAddTo: IVanillaReaderLandmarkData[] | undefined;
                let hasFootnoteAttribute: boolean = false;
                let blockAttributes: IAttributeData[] = [];
                let currentLandmarkData: IVanillaReaderLandmarkData | undefined;

                if (!landmarkTypes) {
                    landmarkTypes = (Object.values(VanillaReaderPublicationLandmarkType) as unknown) as VanillaReaderPublicationLandmarkType[];
                }

                if (landmarkTypes.includes(blockType)) {
                    switch (blockType) {
                        case VanillaReaderPublicationLandmarkType.ARTICLE:
                            addBlock = true;
                            collectionToAddTo = docLandmarks.articles;
                            break;
                        case VanillaReaderPublicationLandmarkType.SECTION:
                            addBlock = true;
                            collectionToAddTo = docLandmarks.sections;
                            break;
                        case VanillaReaderPublicationLandmarkType.ASIDE:
                            addBlock = true;
                            collectionToAddTo = docLandmarks.asides;
                            break;
                        case VanillaReaderPublicationLandmarkType.HEADING1:
                        case VanillaReaderPublicationLandmarkType.HEADING2:
                        case VanillaReaderPublicationLandmarkType.HEADING3:
                        case VanillaReaderPublicationLandmarkType.HEADING4:
                        case VanillaReaderPublicationLandmarkType.HEADING5:
                        case VanillaReaderPublicationLandmarkType.HEADING6:
                            addBlock = true;
                            collectionToAddTo = docLandmarks.headings;
                            break;
                        case VanillaReaderPublicationLandmarkType.AUDIO:
                            addBlock = true;
                            collectionToAddTo = docLandmarks.audio;
                            break;
                        case VanillaReaderPublicationLandmarkType.VIDEO:
                            addBlock = true;
                            collectionToAddTo = docLandmarks.video;
                            break;
                        case VanillaReaderPublicationLandmarkType.IMAGE:
                        case VanillaReaderPublicationLandmarkType.FIGURE:
                            addBlock = true;
                            collectionToAddTo = docLandmarks.figures;
                            break;
                        case VanillaReaderPublicationLandmarkType.TABLE:
                            addBlock = true;
                            collectionToAddTo = docLandmarks.tables;
                            break;
                        case VanillaReaderPublicationLandmarkType.DESCRIPTION_LIST:
                        case VanillaReaderPublicationLandmarkType.UNORDERED_LIST:
                        case VanillaReaderPublicationLandmarkType.ORDERED_LIST:
                            addBlock = true;
                            collectionToAddTo = docLandmarks.lists;
                            break;
                        default:
                    }
                }

                // Let's see if this ContentBlock is also a footnote or endnote
                blockAttributes = block.getAttributes();
                hasFootnoteAttribute = blockAttributes.some((attribute: IAttributeData) => {
                    return (attribute.localName === 'role' && attribute.value === 'doc-footnote')
                        || (attribute.localName === 'role' && attribute.value === 'doc-endnote')
                        || (attribute.localName === 'type' && attribute.value === 'footnote')
                        || (attribute.localName === 'type' && attribute.value === 'endnote')
                        || (attribute.localName === 'type' && attribute.value === 'rearnote');

                });

                if (hasFootnoteAttribute && landmarkTypes.includes(VanillaReaderPublicationLandmarkType.FOOTNOTE)) {
                    addBlock = true;
                    currentLandmarkData = await this._getLandmarkDataForContentBlock(block);
                    currentLandmarkData.subType = 'FOOTNOTE';
                    docLandmarks.footnotes.push(currentLandmarkData);
                }

                if (addBlock) {

                    collectionToAddTo = collectionToAddTo ? collectionToAddTo : [];
                    if(!currentLandmarkData) {
                        currentLandmarkData = await this._getLandmarkDataForContentBlock(block);
                    }

                    if (hasFootnoteAttribute) {
                        currentLandmarkData.subType = 'FOOTNOTE';
                    }

                    collectionToAddTo.push(currentLandmarkData);

                    this._landmarkToDocumentIndex.set(currentLandmarkData, documentIndex);

                    let blockIdAttribute = blockAttributes.find((attribute: IAttributeData) => {
                        return attribute.localName === 'id';
                    });

                    if (blockIdAttribute && !this._blockIdToLandmarkMap.has(blockIdAttribute.value)) {
                        this._blockIdToLandmarkMap.set(blockIdAttribute.value, currentLandmarkData);
                    }

                    currentLandmarkData = undefined;
                }
            }

            this._documentIndexToLandmarksMap!.set(documentIndex, docLandmarks);

            this._hasBeenPopulated = true;

        }
    }

    private _deserializeFromStateData(stateData: IVanillaReaderPublicationLandmarkData) {
        stateData.documents.forEach((documentLandmarkdata: IVanillaReaderDocumentLandmarkData) => {

            this._documentIndexToLandmarksMap!.set(documentLandmarkdata.documentIndex, documentLandmarkdata);

            for (const [_, landmarkCollection] of Object.entries(documentLandmarkdata)) {
                if (landmarkCollection instanceof Array) {
                    landmarkCollection.forEach((landmarkData: IVanillaReaderLandmarkData) => {
                        this._landmarkToDocumentIndex.set(landmarkData, documentLandmarkdata.documentIndex);
                        this._blockIdToLandmarkMap.set(landmarkData.id.toString(), landmarkData);
                    });
                }
            }

        });

        this._hasBeenPopulated = true;
    }

    private async _getLandmarkDataForContentBlock(
        block: IContentBlock,
    ): Promise<IVanillaReaderLandmarkData> {
        let timelinePosition = await this._contentPositionTimeline?.fetchTimelinePosition(block.getLocator());
        let blockData = block.toSerializableData({createLocators: true});
        let description = this._getDescriptionAttributeFromContentBlock(blockData, 'aria-label');

        return {
            timelinePosition,
            blockType: blockData.blockType,
            textContent: blockData.textContent,
            blockClass: blockData.blockClass,
            children: blockData.children,
            locator: blockData.locator,
            locatorUrl: blockData.locator?.toUrl(blockData.locator?.getSourceUrl()).toString(),
            id: blockData.id,
            attributes: blockData.attributes,
            marks: blockData.marks,
            resourceHrefs: blockData.resourceHrefs,
            description,
        };
    }

    // @ts-ignore
    private _getDescriptionAttributeFromContentBlock(
        block: IContentBlockData,
        preferredAttributeName: string | undefined = undefined,
    ): string | undefined {
        let blockAttributes = block.attributes;
        let descAttribute: string | undefined = undefined;

        if (preferredAttributeName) {
            let attr = blockAttributes.find((attr: IAttributeData) => {
                return attr.localName == preferredAttributeName;
            });
            if (attr) {
                return attr.value;
            }
        }

        blockAttributes.forEach((attr: IAttributeData) => {
            switch (attr.localName) {
                case 'title':
                    descAttribute = attr.value;
                    break;
                case 'alt':
                    descAttribute = attr.value;
                    break;
                case 'aria-label':
                    descAttribute = attr.value;
                    break;
            }
        });

        return descAttribute;
    }

}
