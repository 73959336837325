/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.4.0
 */
 'use strict'; (function (s, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-base','./colibrio-core-io-base','./colibrio-core-io-resourceprovider-zip','./colibrio-core-publication-base','./colibrio-core-url','./colibrio-core-xml','./colibrio-core-selector-media'], function (a0,a1,a2,a3,a4,a5,a6) { return f(s,a0,a1,a2,a3,a4,a5,a6); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(s,require('./colibrio-core-base'),require('./colibrio-core-io-base'),require('./colibrio-core-io-resourceprovider-zip'),require('./colibrio-core-publication-base'),require('./colibrio-core-url'),require('./colibrio-core-xml'),require('./colibrio-core-selector-media')); } else { throw new Error('Unsupported runtime environment!'); } }( typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function (__self,__dep__colibrio_core_base,__dep__colibrio_core_io_base,__dep__colibrio_core_io_resourceprovider_zip,__dep__colibrio_core_publication_base,__dep__colibrio_core_url,__dep__colibrio_core_xml,__dep__colibrio_core_selector_media) {  var api = {}; (function () { var k;function aa(a){var b=0;return function(){return b<a.length?{done:!1,value:a[b++]}:{done:!0}}}var q="function"==typeof Object.defineProperties?Object.defineProperty:function(a,b,c){if(a==Array.prototype||a==Object.prototype)return a;a[b]=c.value;return a};
function ba(a){a=["object"==typeof globalThis&&globalThis,a,"object"==typeof window&&window,"object"==typeof self&&self,"object"==typeof global&&global];for(var b=0;b<a.length;++b){var c=a[b];if(c&&c.Math==Math)return c}throw Error("Cannot find global object");}var ca=ba(this);function da(a,b){if(b)a:{var c=ca;a=a.split(".");for(var d=0;d<a.length-1;d++){var e=a[d];if(!(e in c))break a;c=c[e]}a=a[a.length-1];d=c[a];b=b(d);b!=d&&null!=b&&q(c,a,{configurable:!0,writable:!0,value:b})}}
da("Symbol",function(a){function b(g){if(this instanceof b)throw new TypeError("Symbol is not a constructor");return new c(d+(g||"")+"_"+e++,g)}function c(g,f){this.g=g;q(this,"description",{configurable:!0,writable:!0,value:f})}if(a)return a;c.prototype.toString=function(){return this.g};var d="jscomp_symbol_"+(1E9*Math.random()>>>0)+"_",e=0;return b});
da("Symbol.iterator",function(a){if(a)return a;a=Symbol("Symbol.iterator");for(var b="Array Int8Array Uint8Array Uint8ClampedArray Int16Array Uint16Array Int32Array Uint32Array Float32Array Float64Array".split(" "),c=0;c<b.length;c++){var d=ca[b[c]];"function"===typeof d&&"function"!=typeof d.prototype[a]&&q(d.prototype,a,{configurable:!0,writable:!0,value:function(){return ea(aa(this))}})}return a});function ea(a){a={next:a};a[Symbol.iterator]=function(){return this};return a}
function t(a){var b="undefined"!=typeof Symbol&&Symbol.iterator&&a[Symbol.iterator];return b?b.call(a):{next:aa(a)}}var fa="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},u;if("function"==typeof Object.setPrototypeOf)u=Object.setPrototypeOf;else{var v;a:{var ha={a:!0},ia={};try{ia.__proto__=ha;v=ia.a;break a}catch(a){}v=!1}u=v?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var ja=u;
function ka(a,b){a.prototype=fa(b.prototype);a.prototype.constructor=a;if(ja)ja(a,b);else for(var c in b)if("prototype"!=c)if(Object.defineProperties){var d=Object.getOwnPropertyDescriptor(b,c);d&&Object.defineProperty(a,c,d)}else a[c]=b[c];a.ba=b.prototype}function w(){this.v=!1;this.j=null;this.h=void 0;this.g=1;this.F=this.m=0;this.l=null}function la(a){if(a.v)throw new TypeError("Generator is already running");a.v=!0}w.prototype.B=function(a){this.h=a};
function ma(a,b){a.l={N:b,S:!0};a.g=a.m||a.F}w.prototype.return=function(a){this.l={return:a};this.g=this.F};function x(a,b,c){a.g=c;return{value:b}}function na(a){a.m=0;var b=a.l.N;a.l=null;return b}function oa(a){this.g=new w;this.h=a}function pa(a,b){la(a.g);var c=a.g.j;if(c)return qa(a,"return"in c?c["return"]:function(d){return{value:d,done:!0}},b,a.g.return);a.g.return(b);return y(a)}
function qa(a,b,c,d){try{var e=b.call(a.g.j,c);if(!(e instanceof Object))throw new TypeError("Iterator result "+e+" is not an object");if(!e.done)return a.g.v=!1,e;var g=e.value}catch(f){return a.g.j=null,ma(a.g,f),y(a)}a.g.j=null;d.call(a.g,g);return y(a)}function y(a){for(;a.g.g;)try{var b=a.h(a.g);if(b)return a.g.v=!1,{value:b.value,done:!1}}catch(c){a.g.h=void 0,ma(a.g,c)}a.g.v=!1;if(a.g.l){b=a.g.l;a.g.l=null;if(b.S)throw b.N;return{value:b.return,done:!0}}return{value:void 0,done:!0}}
function ra(a){this.next=function(b){la(a.g);a.g.j?b=qa(a,a.g.j.next,b,a.g.B):(a.g.B(b),b=y(a));return b};this.throw=function(b){la(a.g);a.g.j?b=qa(a,a.g.j["throw"],b,a.g.B):(ma(a.g,b),b=y(a));return b};this.return=function(b){return pa(a,b)};this[Symbol.iterator]=function(){return this}}function sa(a){function b(d){return a.next(d)}function c(d){return a.throw(d)}return new Promise(function(d,e){function g(f){f.done?d(f.value):Promise.resolve(f.value).then(b,c).then(g,e)}g(a.next())})}
function z(a){return sa(new ra(new oa(a)))};var A=__dep__colibrio_core_base;var ta=__dep__colibrio_core_io_base;var ua=__dep__colibrio_core_io_resourceprovider_zip;var B=__dep__colibrio_core_publication_base;var va=__dep__colibrio_core_selector_media;var C=__dep__colibrio_core_url;var wa=__dep__colibrio_core_xml;function E(a){return"undefined"===typeof a}function xa(a,b,c){Array.isArray(a)?b=[]:b||(b={});if("object"===typeof a&&null!==a)for(var d=Object.keys(a),e=0;e<d.length;e++){var g=d[e],f=a[g];null!==f&&"object"===typeof f?b[g]=xa(f,b[g],c):E(f)?c&&delete b[g]:b[g]=f}return b}var F=Array.isArray;function G(a){return"number"===typeof a&&!isNaN(a)}function H(a){return a&&!F(a)&&"object"===typeof a}function I(a){return"string"===typeof a}var ya=Function.prototype.call.bind(Object.prototype.hasOwnProperty);function za(a){return/^(?:(en-GB-oed|i-ami|i-bnn|i-default|i-enochian|i-hak|i-klingon|i-lux|i-mingo|i-navajo|i-pwn|i-tao|i-tay|i-tsu|sgn-BE-FR|sgn-BE-NL|sgn-CH-DE)|(art-lojban|cel-gaulish|no-bok|no-nyn|zh-guoyu|zh-hakka|zh-min|zh-min-nan|zh-xiang))$|^((?:[a-z]{2,3}(?:(?:-[a-z]{3}){1,3})?)|[a-z]{4}|[a-z]{5,8})(?:-([a-z]{4}))?(?:-([a-z]{2}|\d{3}))?((?:-(?:[\da-z]{5,8}|\d[\da-z]{3}))*)?((?:-[\da-wy-z](?:-[\da-z]{2,8})+)*)?(-x(?:-[\da-z]{1,8})+)?$|^(x(?:-[\da-z]{1,8})+)$/i.test(a)};var Aa=new Uint16Array(1);Aa[0]=4660;new Uint8Array(Aa.buffer);function Ba(a,b,c){this.l=a;this.j=b;this.g=c;a=a.getManifest().getManifestResourceProvider().getBaseUrl();this.h=new URL(c.url,a)}k=Ba.prototype;k.getContentUrl=function(){return this.h};k.getIndexInSpine=function(){return this.j};k.getLayout=function(){return B.ContentDocumentLayout.REFLOWABLE};k.getLinkedResource=function(){return this.g};k.getMediaType=function(){return this.g.encodingFormat||null};k.getPageSpreadSlot=function(){return B.PageSpreadSlot.AUTO};k.getPublication=function(){return this.l};
k.getSyntheticSpreadBehavior=function(){return B.SyntheticSpreadBehavior.AUTO};k.hasRemoteResources=function(){return!1};k.isInLinearContent=function(){return!0};k.isScripted=function(){return!1};k.toJSON=function(){return{hasRemoteResources:this.hasRemoteResources(),inLinearContent:this.isInLinearContent(),layout:this.getLayout(),indexInSpine:this.getIndexInSpine(),mediaType:this.getMediaType(),scripted:this.isScripted(),pageSpreadSlot:this.getPageSpreadSlot(),syntheticSpreadBehavior:this.getSyntheticSpreadBehavior()}};function Ca(a){this.j=a;this.g=[];this.h="";this.l=[]}function J(a,b,c,d){a.l.push({path:a.g.slice(),Z:a.h,$:c,aa:b,value:d})}function Da(a){var b=[];F(a)&&a.forEach(function(c){"ItemList"===c.type&&F(c.T)&&c.T.forEach(function(d){I(d)&&!b.includes(d)&&b.push(d)})});return b}
function K(a,b,c){if(c.type)if(F(c.type)){for(var d=c.type,e=!1,g=0;g<d.length&&!e;g++)switch(d[g]){case "boolean":e="boolean"===typeof b;break;case "object":e=H(b);break;case "number":e=G(b);break;case "array":e=F(b);break;case "string":e=I(b);break;case "integer":e=G(b)&&Number.isInteger(b)}e||(J(a,"type","["+d.join(", ")+"]",b),b=void 0)}else{d=c.type;e=!1;switch(d){case "boolean":e="boolean"!==typeof b;break;case "object":H(b)||(c.K?(g={},b=(g[c.K]=b,g)):e=!0);break;case "number":G(b)||(b=Number(b),
e=isNaN(b));break;case "array":F(b)||(b=[b]);break;case "itemlist":(g=Da(b))?b=g:e=!0;break;case "string":I(b)||(G(b)?b=""+b:e=!0);break;case "integer":G(b)||(b=Number(b)),e=!Number.isInteger(b)}e&&(J(a,"type",d,b),b=void 0)}if(H(b)){d=b=Object.assign({},b);e=Object.keys(d);g=c.C;var f=c.R;f&&f.i&&(f=L(a.j,f.i));for(var h=0;h<e.length;h++){var l=e[h];if("@context"===l)d[l]=xa(d[l],{},!1);else if(!g||!g[l])if(f){a.g.push(l);var r=K(a,d[l],f);E(r)?delete d[l]:d[l]=r;a.g.pop()}else J(a,"unknownProperty",
l,d[l]),c.O||delete d[l]}if(c.C)for(d=b,e=Object.entries(c.C),g=0;g<e.length;g++)h=e[g],f=h[0],h=h[1],h.i&&(h=L(a.j,h.i)),!E(h.default)&&E(d[f])&&(d[f]=h.default),E(d[f])||(a.g.push(f),h=K(a,d[f],h),E(h)?delete d[f]:d[f]=h,a.g.pop());if(c.required)a:for(c=c.required,d=0;d<c.length;d++)if(e=c[d],!ya(b,e)){J(a,"required",e,"");b=void 0;break a}}else if(F(b)){if(c.items){c=c.items;c.i&&(c=L(a.j,c.i));d=[];e=a.h;c.u&&(a.h=c.u);for(g=0;g<b.length;g++)a.g.push(g),f=K(a,b[g],c),a.g.pop(),E(f)||d.push(f);
a.h=e;a=d}else a=xa(b,{},!1);b=a}return b};function Ea(a,b){var c=a["@context"];if(!F(c))return!1;a=c.indexOf("https://schema.org");b=c.indexOf(b);return-1!==a&&-1!==b&&b>a};function M(a){var b=Error.call(this,"[colibrio.internal] "+(a||""));this.message=b.message;"stack"in b&&(this.stack=b.stack);this.errorMessage=a}ka(M,Error);function Fa(){this.g=new Map}function N(a,b){if(!b.u)throw new M("Schema is missing $id");a.g.set(b.u,b)}function L(a,b){a=a.g.get(b);if(!a)throw new M("Schema not found: "+b);if(a.i)throw new M("Schema $ref chain not supported: "+b);return a};function Ga(a){a=Date.parse(a);return!isNaN(a)}function O(a){return/^P(?!$)(\d+(?:\.\d+)?Y)?(\d+(?:\.\d+)?M)?(\d+(?:\.\d+)?W)?(\d+(?:\.\d+)?D)?(T(?=\d)(\d+(?:\.\d+)?H)?(\d+(?:\.\d+)?M)?(\d+(?:\.\d+)?S)?)?$/.test(a)}
function Ha(a){var b=0,c=!1;a=a.replace(/,/g,".");for(var d=0,e=0;e<a.length;e++)switch(a[e]){case "Y":b=P(a,b,e+1);d+=31556952E3*b;b=e+1;break;case "D":b=P(a,b,e+1);d+=864E5*b;b=e+1;break;case "H":b=P(a,b,e+1);d+=36E5*b;b=e+1;break;case "M":b=P(a,b,e+1);d=c?d+6E4*b:d+2629746E3*b;b=e+1;break;case "S":b=P(a,b,e+1);d+=1E3*b;b=e+1;break;case "P":b=e+1;break;case "T":c=!0,b=e+1}return d}function P(a,b,c){a=parseFloat(a.slice(b,c));return isNaN(a)?0:a};function Q(a,b){return A.ColibrioError.call(this,"core.publication.wp",a,b)||this}ka(Q,A.ColibrioError);var Ia={AUDIOBOOKS:"https://www.w3.org/TR/audiobooks/",GENERIC:"https://www.w3.org/TR/pub-manifest/"};function R(a,b){var c;return(null==(c=a.rel)?void 0:c.some(function(d){return d.toLowerCase()===b}))||!1};function Ja(a,b,c){this.j=a;this.h=b;this.s=c;this.g=[]}
function Ka(a,b){var c=-1;b.duration&&(O(b.duration)?c=Ha(b.duration):(a.logWarning("duration","Invalid ISO 8601 duration: "+b.duration),b.duration=void 0));var d=0;b.readingOrder.forEach(function(e){e.duration?O(e.duration)?d+=Ha(e.duration):(a.logWarning("readingOrder",'"readingOrder" - Invalid ISO 8601 duration for resource "'+e.url+'": '+e.duration),e.duration=void 0):S(b)&&a.logWarning("readingOrder",'Duration missing for resource "'+e.url+'"')});-1!==c&&1E3<Math.abs(d-c)&&a.logWarning("duration",
"The sum of the readingOrder items' duration ("+Math.round(d/1E3)+") does not match the declared total duration ("+(Math.round(c/1E3)+")."));b.resources.forEach(function(e){e.duration&&!O(e.duration)&&(e.duration=void 0,a.logWarning("resources",'Invalid ISO 8601 duration for resource "'+e.url+'": '+e.duration))});b.links.forEach(function(e){e.duration&&!O(e.duration)&&(e.duration=void 0,a.logWarning("links",'Invalid ISO 8601 duration for resource "'+e.url+'": '+e.duration))})}
function La(a,b){for(var c=["cover","pagelist","contents"],d={},e=b.links.length-1;0<=e;d={G:d.G},e--){var g=b.links[e];d.G="";g.rel?g.rel.some(function(f){return function(h){return c.includes(h.toLowerCase())?(f.G=h,!0):!1}}(d))&&(a.logWarning("links",'Must not include cover, pagelist or contents. Removed link "'+g.url+'" with rel: "'+d.G+'"'),b.links.splice(e,1)):a.logWarning("links",'The link "'+g.url+'" is missing a "rel" attribute.')}}
function Ma(a,b,c){a.forEach(function(d){d=new URL(d.url,b);C.URLUtils.normalizePathnamePercentEncoding(d);c.add(C.URLUtils.getUrlWithoutHash(d))})}function S(a){return a.conformsTo.includes("https://www.w3.org/TR/audiobooks/")}function Na(a,b,c,d){for(var e=new Set,g=0;g<b.length;g++){var f=b[g];f=I(f)?f:f.url;var h=new URL(f,c);C.URLUtils.normalizePathnamePercentEncoding(h);h=C.URLUtils.getUrlWithoutHash(h);e.has(h)?a.logWarning(d,"contains duplicate URL: "+f):e.add(h)}return e}
Ja.prototype.logError=function(a,b){this.g.push({property:a,message:b});var c="Validation error :: ";a&&(c+=a+": ");A.Logger.logError(new Q("INVALID_MANIFEST",c+b))};Ja.prototype.logWarning=function(a,b){this.g.push({property:a,message:b});var c="Validation error :: ";a&&(c+=a+": ");A.Logger.logWarning(new Q("INVALID_MANIFEST",c+b))};
function Oa(a,b){if(0===b.conformsTo.length)a.logWarning("conformsTo",'conformsTo property is missing! Defaulting to the generic "https://www.w3.org/TR/pub-manifest/"'),b.conformsTo=["https://www.w3.org/TR/pub-manifest/"];else{var c=Object.values(Ia);b.conformsTo.forEach(function(d){c.includes(d)||a.logWarning("conformsTo",'Unsupported profile found: "'+d+'"')})}}function Pa(a,b){a.type||(a.type=[]);0===a.type.length&&a.type.push(b)}
function Qa(a,b){var c=new Set;Ma(a.readingOrder,b,c);Ma(a.resources,b,c);var d=[];c.forEach(function(e){return d.push(e)});a.uniqueResources=d}function T(a,b,c,d){for(var e=0;e<b.length;e++){var g=b[e];g=I(g)?g:g.url;try{new URL(g,c)}catch(f){a.logWarning(d,'Removed invalid resource URL: "'+g+'"'),b.splice(e--,1)}}}
function Ra(a,b){for(var c=t("abridged accessibilityFeature accessibilityHazard accessibilitySummary accessMode accessModeSufficient author duration dateModified datePublished id inLanguage readBy readingProgression resources type url".split(" ")),d=c.next();!d.done;d=c.next())d=d.value,E(b[d])&&a.logWarning(d,"Recommended property missing.");b.resources.some(function(e){return R(e,"cover")})||b.readingOrder.some(function(e){return R(e,"cover")})||a.logWarning(null,'Audiobook :: Recommended link relation "cover" not found in "resources" or "readingOrder"')}
function Sa(a,b){function c(e,g){e.forEach(function(f){R(f,"cover")&&(!f.name&&f.encodingFormat&&f.encodingFormat.startsWith("image")&&a.logWarning(g,'The resource "'+f.url+'" is marked as "cover" but is missing the "name" property.'),d?a.logWarning(null,'Manifest should not contain multiple "cover" resources.'):d=!0)})}var d=!1;c(b.readingOrder,"readingOrder");c(b.resources,"resources")}
function Ta(a,b){var c=!1;b.readingOrder.concat(b.resources).forEach(function(d){R(d,"pagelist")&&(c?a.logWarning(null,'Manifest should not contain multiple "pagelist" resources.'):c=!0)})}function Ua(a,b,c){var d=!1;S(b)&&c&&c.A.querySelector("[role=doc-toc]")&&(d=!0);b.readingOrder.concat(b.resources).forEach(function(e){R(e,"contents")&&(d?a.logWarning(null,'Manifest should not contain multiple "contents" resources.'):d=!0)});S(b)&&!d&&a.logWarning(null,"Table of contents not found")};function U(a,b,c){this.D=a;try{var d=JSON.parse(b)}catch(D){throw A.Logger.logError(D),new Q("INVALID_MANIFEST",'Failed parsing the JSON manifest located at "'+this.D.getBaseUrl()+'": '+D);}b=d;if(!Ea(b,"https://www.w3.org/ns/pub-context"))if(Ea(b,"https://www.w3.org/ns/wp-context"))A.Logger.logWarning(new Q("INVALID_MANIFEST","WpPublication - Old version of manifest @context: https://www.w3.org/ns/wp-context Expected: https://www.w3.org/ns/pub-context"));else throw new Q("INVALID_MANIFEST",'Invalid JSON-LD @context, it must include "https://www.w3.org/ns/pub-context" and "https://schema.org"');
var e;d=b["@context"];if(F(d))for(var g=0;g<d.length;g++){var f=d[g];H(f)&&(f=f.direction,I(f)&&(e=f))}else e=void 0;void 0!==e&&"ltr"!==e&&"rtl"!==e&&(A.Logger.logWarning("WpPublication - Invalid manifest base direction specified in @context: Must be ltr or rtl."),e=void 0);var h;d=b["@context"];if(F(d))for(g=0;g<d.length;g++)f=d[g],H(f)&&(f=f.hasOwnProperty("language")?f.language:void 0,I(f)&&(za(f)?h=f:A.Logger.logWarning("Invalid BCP 47 language tag: "+f)));else h=void 0;d=new Fa;N(d,{u:"WpLocalizableString",
type:"object",K:"value",required:["value"],C:{language:{default:h,type:"string"},value:{type:"string"},direction:{default:e,type:"string"}}});N(d,{u:"WpEntity",type:"object",O:!0,K:"name",required:["name"],C:{id:{type:"string"},identifier:{type:"array",items:{type:"string"}},name:{type:"array",items:{i:"WpLocalizableString"}},type:{type:"array",items:{type:"string"}},url:{type:"string"}}});N(d,{u:"WpLinkedResource",type:"object",O:!0,K:"url",required:["url"],C:{alternate:{type:"array",items:{i:"WpLinkedResource"}},
description:{i:"WpLocalizableString"},encodingFormat:{type:"string"},duration:{type:"string"},integrity:{type:"string"},name:{type:"array",items:{i:"WpLocalizableString"}},rel:{type:"array",items:{type:"string"}},type:{type:"array",items:{type:"string"}},url:{type:"string"}}});N(d,{u:"WpManifest",type:"object",required:[],R:{},C:{abridged:{type:"boolean"},accessibilityFeature:{type:"array",items:{type:"string"}},accessibilityHazard:{type:"array",items:{type:"string"}},accessibilitySummary:{i:"WpLocalizableString"},
accessMode:{type:"array",items:{type:"string"}},accessModeSufficient:{type:"itemlist",items:{type:"string"}},artist:{type:"array",items:{i:"WpEntity"}},author:{type:"array",items:{i:"WpEntity"}},colorist:{type:"array",items:{i:"WpEntity"}},conformsTo:{type:"array",default:[],items:{type:"string"}},contributor:{type:"array",items:{i:"WpEntity"}},creator:{type:"array",items:{i:"WpEntity"}},dateModified:{type:"string"},datePublished:{type:"string"},direction:{type:"string"},duration:{type:"string"},
editor:{type:"array",items:{i:"WpEntity"}},id:{type:"string"},illustrator:{type:"array",items:{i:"WpEntity"}},inker:{type:"array",items:{i:"WpEntity"}},inLanguage:{type:"array",items:{type:"string"}},letterer:{type:"array",items:{i:"WpEntity"}},links:{type:"array",default:[],items:{i:"WpLinkedResource"}},name:{type:"array",items:{i:"WpLocalizableString"}},penciler:{type:"array",items:{i:"WpEntity"}},publisher:{type:"array",items:{i:"WpEntity"}},readBy:{type:"array",items:{i:"WpEntity"}},readingOrder:{type:"array",
items:{i:"WpLinkedResource"}},readingProgression:{type:"string"},resources:{type:"array",default:[],items:{i:"WpLinkedResource"}},translator:{type:"array",items:{i:"WpEntity"}},type:{type:"array",items:{type:"string"}},uniqueResources:{type:"array",items:{i:"WpLinkedResource"}},url:{type:"array",items:{type:"string"}}}});h=new Ca(d);e=L(h.j,"WpManifest");h.h=e.u||"";h.g=[];h.l=[];b=K(h,b,e);h=h.l;0<h.length&&(A.Logger.logWarning("WP Manifest JSON errors"),A.Logger.logWarning(h));if(!b)throw new Q("INVALID_MANIFEST",
"Failed to normalize the Manifest, required properties are missing");c=new Ja(b,a.getBaseUrl(),c);c.g=[];a=c.j;h=c.h;b=c.s;e=new va.MediaFragmentSelectorParser;a.readingOrder||(a.readingOrder=[]);if(0===a.readingOrder.length)if(b)a.readingOrder.push({url:C.URLUtils.getUrlWithoutHash(b.J)});else throw new Q("INVALID_MANIFEST",'"readingOrder" is empty!');if(a.inLanguage)for(d=a.inLanguage.length-1;0<=d;d--)g=a.inLanguage[d],za(g)||(c.logWarning("inLanguage","Invalid BCP 47 language tag: "+g),a.inLanguage.splice(d,
1));Oa(c,a);T(c,a.readingOrder,h,"readingOrder");T(c,a.resources,h,"resources");T(c,a.links,h,"links");a.url&&T(c,a.url,h,"url");d=a.readingOrder;for(g=0;g<d.length;g++){f=d[g];var l=I(f)?f:f.url,r=new URL(l,h);e.canParseFragment(r.hash)&&(r=C.URLUtils.getUrlWithoutHash(r),c.logWarning("readingOrder",'Removed fragment selectors from resource URL: "'+l+'"'),I(f)?d[g]=r:(f.url=r,d[g]=f))}e=Na(c,a.readingOrder,h,"readingOrder");d=Na(c,a.resources,h,"resources");for(g=a.links.length-1;0<=g;g--)f=a.links[g].url,
l=new URL(f,h),C.URLUtils.normalizePathnamePercentEncoding(l),l=C.URLUtils.getUrlWithoutHash(l),e.has(l)?(c.logWarning("links",'The URL "'+f+'" was removed from links because it already appears in "readingOrder"'),a.links.splice(g,1)):d.has(l)&&(c.logWarning("links",'The URL "'+f+'" was removed from links because it already appears in "resources"'),a.links.splice(g,1));a.id?C.URLUtils.isValidUrl(a.id)||(c.logWarning("id",'Property removed because it is not a valid URL. Got: "'+a.id+'"'),a.id=void 0):
c.logWarning("id","Property is missing.");Ka(c,a);a.dateModified&&!Ga(a.dateModified)&&(c.logWarning("dateModified","Invalid date format: "+a.dateModified),a.dateModified=void 0);a.datePublished&&!Ga(a.datePublished)&&(c.logWarning("datePublished","Invalid date format: "+a.datePublished),a.datePublished=void 0);var p;(e=null==(p=a.readingProgression)?void 0:p.toLowerCase())&&"ltr"!==e&&"rtl"!==e&&(c.logWarning("readingProgression",'Must be either "ltr" or "rtl". Got: "'+a.readingProgression+'"'),
a.readingProgression=void 0);La(c,a);Sa(c,a);Ta(c,a);Ua(c,a,b);Qa(a,h);if(b){e=p=!1;d=C.URLUtils.getUrlWithoutHash(b.J);g=t(a.readingOrder);for(f=g.next();!f.done;f=g.next()){f=f.value;try{var n=new URL(f.url,h);C.URLUtils.normalizePathnamePercentEncoding(n);C.URLUtils.getUrlWithoutHash(n)===d&&(p=!0)}catch(D){c.logWarning("readingOrder","Invalid resource URL found: "+f.url)}}n=t(a.resources);for(f=n.next();!f.done;f=n.next()){g=f.value;try{var m=new URL(g.url,h);C.URLUtils.normalizePathnamePercentEncoding(m);
C.URLUtils.getUrlWithoutHash(m)===d&&(e=!0)}catch(D){c.logWarning("resources","Invalid resource URL found: "+g.url)}}p||e||c.logWarning(null,'Entry page URL not found in either "resources" or "readingOrder"')}a.name||(a.name=[]);0===a.name.length&&b&&(m=b.A.documentElement.getAttribute("lang"),n=b.A.documentElement.getAttribute("dir"),(p=b.A.querySelector("title"))&&a.name.push({value:p.innerText,direction:"ltr"===n||"rtl"===n?n:void 0,language:m||void 0}));0===a.name.length&&(c.logWarning("name",
'Property is missing and no "Primary Entry Page" title is available. Defaulting "name" to "Untitled Publication"'),a.name.push({direction:"ltr",language:"en",value:"Untitled Publication"}));if(S(a)){Ra(c,a);m=!1;for(n=a.readingOrder.length-1;0<=n;n--)p=a.readingOrder[n],p.encodingFormat?p.encodingFormat.toLowerCase().startsWith("audio")?m=!0:(a.readingOrder.splice(n,1),c.logWarning("readingOrder",'Removed non-audio resource "'+p.url+'" with encodingFormat "'+p.encodingFormat+'"')):c.logWarning("readingOrder",
'Resource with URL "'+p.url+' is missing the "encodingFormat" property.');m||c.logError("readingOrder","Must contain at least one audio resource.");Pa(a,"Audiobook")}else Pa(a,"CreativeWork");m=c.g;this.h=a;this.g=m}U.prototype.getValidationErrors=function(){return this.g};U.prototype.getManifestResourceProvider=function(){return this.D};U.prototype.getProcessedManifestData=function(){return this.h};function Va(a,b){this.o=a;this.g=b}k=Va.prototype;k.acceptsUrl=function(a){V(this,a);return!1};k.fetch=function(a,b){V(this,a);return this.o.fetch(a,b)};k.fetchMetadata=function(a,b){V(this,a);return this.o.fetchMetadata(a,b)};k.getBackingResourceProvider=function(){return this.o};k.getBaseUrl=function(){return C.URLUtils.cloneUrl(this.g)};k.getResourceManifest=function(){return this.o.getResourceManifest()};
k.isUrlSupportedByBrowser=function(a,b){V(this,a);return this.o.isUrlSupportedByBrowser(a,b)};function V(a,b){I(b)&&(b=new URL(b,a.g),C.URLUtils.normalizePathnamePercentEncoding(b))};function Wa(a){this.g=a;this.j=[];this.h=0;this.l=[];this.m=[];this.v=[];this.B=[];this.F=[];this.P=[];Xa(this)}k=Wa.prototype;k.getAll=function(){return this.j};k.getIdentifiers=function(){return this.m};k.getLanguages=function(){return this.B};k.getTitles=function(){return this.P};k.toJSON=function(){return{all:this.j,identifierIndexes:this.l,languageIndexes:this.v,titleIndexes:this.F,type:B.PublicationMetadataType.WP_PUBLICATION_METADATA}};
function Xa(a){Object.keys(a.g).forEach(function(b){var c;switch(b){case "abridged":(c=a.g[b])&&W(a,b,[{content:{dir:null,value:c+"",lang:null},refinedBy:[],property:{name:b,namespace:X(b)},contentScheme:null}]);break;case "accessMode":case "accessModeSufficient":case "accessibilityFeature":case "accessibilityHazard":case "conformsTo":case "inLanguage":case "type":case "url":(c=a.g[b])&&W(a,b,Ya(b,c));break;case "accessibilitySummary":(c=a.g[b])&&W(a,b,[Za(b,c)]);break;case "name":(c=a.g[b])&&W(a,
b,$a(b,c));break;case "artist":case "author":case "colorist":case "contributor":case "creator":case "editor":case "illustrator":case "inker":case "letterer":case "penciler":case "publisher":case "readBy":case "translator":(c=a.g[b])&&W(a,b,ab(b,c));break;case "dateModified":case "datePublished":case "direction":case "duration":case "id":case "readingProgression":(c=a.g[b])&&W(a,b,[bb(b,c)]);break;case "links":case "readingOrder":case "resources":(c=a.g[b])&&W(a,b,cb(b,c))}})}
function W(a,b,c){c=t(c);for(var d=c.next();!d.done;d=c.next())d=d.value,a.j.push(d),"inLanguage"===b?(a.B.push(d),a.v.push(a.h)):"id"===b?(a.m.push(d),a.l.push(a.h)):"name"===b&&(a.P.push(d),a.F.push(a.h)),a.h++}function cb(a,b){return b.map(function(c){return{content:{dir:null,value:c.url,lang:null},refinedBy:[],property:{name:a,namespace:X(a)},contentScheme:null}})}
function ab(a,b){var c=[];b.forEach(function(d){var e=c.push,g=e.apply;d=db(a,d);if(!(d instanceof Array)){d=t(d);for(var f,h=[];!(f=d.next()).done;)h.push(f.value);d=h}g.call(e,c,d)});return c}function db(a,b){var c=X(a);return b.name.map(function(d){return{content:d,property:{name:a,namespace:c},refinedBy:[],contentScheme:null}})}function $a(a,b){return b.map(function(c){return Za(a,c)})}
function Za(a,b){var c=b.direction;return{content:{dir:"ltr"===c?A.TextDirection.LTR:"rtl"===c?A.TextDirection.RTL:null,value:b.value,lang:b.language||null},contentScheme:null,refinedBy:[],property:{name:a,namespace:X(a)}}}function Ya(a,b){return b.map(function(c){return bb(a,c)})}function bb(a,b){return{content:{dir:null,value:b,lang:null},refinedBy:[],property:{name:a,namespace:X(a)},contentScheme:null}}function X(a){a=eb[a];return I(a)?a:null}
var eb={W:"https://www.w3.org/ns/pub-vocab/manifest#",accessMode:"https://schema.org/",accessModeSufficient:"https://schema.org/",accessibilityFeature:"https://schema.org/",accessibilityHazard:"https://schema.org/",alternate:"https://www.w3.org/ns/pub-vocab/manifest#",artist:"https://schema.org/",author:"https://schema.org/",colorist:"https://schema.org/",conformsTo:"http://purl.org/dc/terms/",contributor:"https://schema.org/",creator:"https://schema.org/",duration:"https://schema.org/",editor:"https://schema.org/",
encodingFormat:"https://schema.org/",identifier:"https://schema.org/",illustrator:"https://schema.org/",inLanguage:"https://schema.org/",inker:"https://schema.org/",integrity:"https://www.w3.org/ns/pub-vocab/manifest-vocab/manifest#",letterer:"https://schema.org/",links:"https://www.w3.org/ns/pub-vocab/manifest-vocab/manifest#",penciler:"https://schema.org/",publisher:"https://schema.org/",readBy:"https://schema.org/",readingOrder:"https://www.w3.org/ns/pub-vocab/manifest#",rel:"https://www.w3.org/ns/pub-vocab/manifest#",
resources:"https://www.w3.org/ns/pub-vocab/manifest#",translator:"https://schema.org/"};function Y(a){this.h=void 0;this.j=!1;this.l=void 0;this.o=a.o;this.s=a.s;this.H=a.H;this.I=a.I;this.g=new U(a.D,a.M,a.L?null:a.s);this.m=new Wa(this.g.getProcessedManifestData())}function fb(a){var b;return z(function(c){if(1==c.g)return x(c,ua.ZipResourceProvider.createFromBlob(a),2);b=c.h;return c.return(gb(b,b.getCentralDirectorySha1Signature(),function(){return b.destroy()}))})}
function gb(a,b,c){var d,e,g,f;return z(function(h){switch(h.g){case 1:return h.m=2,x(h,a.fetchMetadata("publication.json"),4);case 4:return h.return(hb(a,"publication.json",b,c));case 2:d=g=na(h);case 3:return h.m=5,x(h,a.fetchMetadata(Z),7);case 7:return h.return(ib(a,Z,b,c));case 5:e=f=na(h);case 6:throw new Q("PARSE_ERROR",'Failed to load WpPublication from publication.json or index.html. "publication.json" error: '+d+', "index.html" error: '+e);}})}
function hb(a,b,c,d,e){e=void 0===e?!1:e;var g,f,h,l,r,p;return z(function(n){switch(n.g){case 1:if(I(b)){try{b=new URL(b,a.getBaseUrl()||void 0)}catch(m){throw new Q("INVALID_RESOURCE_URL","Invalid manifest URL specified: "+b);}C.URLUtils.normalizePathnamePercentEncoding(b)}return x(n,a.fetch(b),2);case 2:return g=n.h,x(n,g.asText(),3);case 3:f=n.h;h=new Va(a,b);if(e){l=null;n.g=4;break}return x(n,jb(a),5);case 5:l=n.h;case 4:return r=l,p={o:a,s:r,H:c,M:f,D:h,I:d,L:!0},n.return(new Y(p))}})}
function ib(a,b,c,d){var e,g,f,h,l,r,p,n;return z(function(m){if(1==m.g){if(I(b)){try{b=new URL(b,a.getBaseUrl()||void 0)}catch(D){throw new Q("INVALID_RESOURCE_URL","Invalid Primary Entry Page URL specified: "+b);}C.URLUtils.normalizePathnamePercentEncoding(b)}return x(m,kb(a,b),2)}if(3!=m.g){e=m.h;if(f=null==(g=e.querySelector("base"))?void 0:g.getAttribute("href")){try{h=new URL(f,b)}catch(D){A.Logger.logError(new Q("PARSE_ERROR","<base> element in the primary entry page has an invalid href: "+
f+". Reason: "+D)),h=b}C.URLUtils.normalizePathnamePercentEncoding(h)}else h=b;return x(m,lb(e,a,h),3)}l=m.h;r=l.V;p=l.U;n={o:a,s:{J:b,A:e},H:c,M:r,D:new Va(a,p),I:d,L:!1};return m.return(new Y(n))})}function mb(a,b){var c;return z(function(d){if(1==d.g)return x(d,ua.ZipResourceProvider.createFromRandomAccessDataSource(a,b),2);c=d.h;return d.return(gb(c,c.getCentralDirectorySha1Signature(),function(){return c.destroy()}))})}
function lb(a,b,c){var d,e,g,f,h,l,r,p,n;return z(function(m){switch(m.g){case 1:f=null==(g=a.querySelector('link[rel="publication"]'))?void 0:g.getAttribute("href");if(!f){if(h=a.querySelector('script[type="application/ld+json"]'))e=h.innerHTML,d=c;else throw new Q("MANIFEST_NOT_FOUND",'Unable to find <link rel="publication" /> or <script type="application/ld+json"> in primary entry page containing the Publication Manifest.');m.g=2;break}if(f.startsWith("#")){l=a.querySelector(f);if(!l)throw new Q("INVALID_RESOURCE_URL",
'Failed to fetch the publication manifest from the <link rel="publication"> element with href: '+f);e=l.innerHTML;d=c;m.g=2;break}m.m=4;r=new URL(f,c);C.URLUtils.normalizePathnamePercentEncoding(r);return x(m,b.fetch(r),6);case 6:return p=m.h,x(m,p.asText(),7);case 7:e=m.h;m.g=5;m.m=0;break;case 4:throw n=na(m),new Q("INVALID_RESOURCE_URL",'Failed to fetch the publication manifest from the <link rel="publication"> element with href: '+f+". Reason: "+n);case 5:d=r;case 2:return m.return({V:e,U:d})}})}
function jb(a){return z(function(b){return b.return(a.fetchMetadata(Z).then(function(){var c=new URL(Z,a.getBaseUrl()||void 0);C.URLUtils.normalizePathnamePercentEncoding(c);return kb(a,c).then(function(d){return{J:c,A:d}})}).catch(function(){return null}))})}
function kb(a,b){var c,d,e,g,f,h;return z(function(l){if(1==l.g)return x(l,a.fetch(b),2);if(3!=l.g)return c=l.h,x(l,c.asText(),3);d=l.h;e=c.getMetadata();g=new wa.XhtmlParser(d,e.pathRelativeToBaseUrl||e.url,!0);f=g.getDocument();if(!f)throw new Q("PARSE_ERROR","Failed to parse entry page as HTML: "+(null==(h=g.getParserError())?void 0:h.textContent));return l.return(f)})}k=Y.prototype;k.destroy=function(){if(!this.j){this.j=!0;var a;null==(a=this.I)||a.call(this)}};k.getBackingResourceProvider=function(){return this.o};
k.getCoverImageResourceUrl=function(){if(void 0===this.h){var a=this.g.getProcessedManifestData();if(a=a.readingOrder.concat(a.resources).find(function(b){return R(b,"cover")}))try{this.h=new URL(a.url,this.g.getManifestResourceProvider().getBaseUrl())}catch(b){A.Logger.logError(new Q("INVALID_RESOURCE_URL",'Invalid "cover" resource URL: '+a.url)),this.h=null}else this.h=null}return this.h};k.getDefaultLayout=function(){return B.ContentDocumentLayout.REFLOWABLE};k.getHashSignature=function(){return this.H};
k.getManifest=function(){return this.g};k.getMediaType=function(){return ta.MediaType.APPLICATION_LPF_ZIP};k.getMetadata=function(){return this.m};
k.getNavigationResourceUrl=function(){var a=null;if(this.s&&this.s.A.querySelector("[role=doc-toc]"))a=this.s.J;else{var b=this.getSpine().find(function(c){return R(c.getLinkedResource(),"contents")});if(b)a=b.getContentUrl();else if(b=this.g.getProcessedManifestData().resources.find(function(c){return R(c,"contents")}))try{a=new URL(b.url,this.g.getManifestResourceProvider().getBaseUrl())}catch(c){A.Logger.logError(new Q("INVALID_RESOURCE_URL","Invalid resource URL "+b.url))}}return a};
k.getPageProgressionDirection=function(){switch(this.g.getProcessedManifestData().readingProgression){case "ltr":return B.PageProgressionDirection.LTR;case "rtl":return B.PageProgressionDirection.RTL}return B.PageProgressionDirection.AUTO};k.getPreferredFlowMode=function(){return B.ContentDocumentFlowMode.AUTO};k.getPreferredOrientation=function(){return B.PublicationOrientation.AUTO};k.getPreferredSyntheticSpreadBehavior=function(){return B.SyntheticSpreadBehavior.AUTO};k.getProfiles=function(){return this.g.getProcessedManifestData().conformsTo};
k.getSpine=function(){var a=this;this.l||(this.l=this.g.getProcessedManifestData().readingOrder.map(function(b,c){return new Ba(a,c,b)}));return this.l};k.getType=function(){return B.PublicationType.WP};k.isDestroyed=function(){return this.j};
k.toJSON=function(){return{defaultLayout:this.getDefaultLayout(),hashSignature:this.getHashSignature(),metadata:this.getMetadata().toJSON(),pageProgressionDirection:this.getPageProgressionDirection(),preferredFlowMode:this.getPreferredFlowMode(),preferredOrientation:this.getPreferredOrientation(),preferredSyntheticSpreadBehavior:this.getPreferredSyntheticSpreadBehavior(),spine:this.getSpine().map(function(a){return a.toJSON()}),type:B.PublicationType.WP}};var Z="index.html";Y.createFromBlob=function(a){return fb(a)};
Y.createFromPrimaryEntryPageUrl=function(a,b,c,d){return ib(a,b,c,d)};Y.createFromManifestUrl=function(a,b,c,d){return hb(a,b,c,d,!0)};Y.createFromRandomAccessDataSource=function(a,b){return mb(a,b)};Y.createFromResourceProvider=function(a,b,c){return gb(a,b,c)};api={WpPublication:Y,WpPublicationError:Q,WpPublicationErrorType:{INVALID_MANIFEST:"INVALID_MANIFEST",INVALID_RESOURCE_URL:"INVALID_RESOURCE_URL",MANIFEST_NOT_FOUND:"MANIFEST_NOT_FOUND",NOT_IMPLEMENTED:"NOT_IMPLEMENTED",PARSE_ERROR:"PARSE_ERROR",RESOURCE_PROVIDER_MISSING_BASE_URL:"RESOURCE_PROVIDER_MISSING_BASE_URL"}};var nb=new ArrayBuffer(8);new DataView(nb);new Uint8Array(nb); }).call(__self); return api; } ) );
