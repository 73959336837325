/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IVanillaReaderUI} from "./IVanillaReaderUI";
import {IVanillaReaderHighlightData} from "../VanillaReader/VanillaReaderModel";
import {VanillaReaderAppUiDefaults} from "./VanillaReaderAppUiDefaults";
import {IVanillaReaderUiComponentController} from "./IVanillaReaderUiComponentController";
import {VanillaReaderUI} from "./VanillaReaderUI";

export interface VanillaReaderUiFabHighlightController extends IVanillaReaderUiComponentController, IVanillaReaderUI {}

/**
 * # VanillaReaderUiFabHighlight
 *
 * ## RESPONSIBILITIES
 * This controller mixing class handles all event callbacks from the `VanillaReaderUiFabHighlight` class.
 *
 * Just as all other controller mixin classes, this class is used to add functionality to the `VanillaReaderUi` class
 * in a "modular" way. Read more about this in the `UI Components and Controller Mixins` in the README.md file.
 *
 * ## RELATED TYPES
 * - VanillaReaderUi
 * - VanillaReaderUiFabHighlight
 *
 */
export class VanillaReaderUiFabHighlightController {

    static __initialize__(vanillaReaderUiContext: VanillaReaderUI) {
        vanillaReaderUiContext: vanillaReaderUiContext.fabHighlight.onClick(vanillaReaderUiContext._onFabHighlightClicked.bind(vanillaReaderUiContext));
    }

    _onFabHighlightClicked() {
        if (this.activeSelection && this.activeSelection.selector) {
            let highlight: IVanillaReaderHighlightData = {
                locator: this.activeSelection.selector,
                color: VanillaReaderAppUiDefaults.highlightColorValues.yellow,
                selectionText: this.activeSelection.selectionText,
                comment: '',
            };
            this.dialogHighlight?.setState(highlight, true);
            this.dialogHighlight?.show();
        }
    };

}