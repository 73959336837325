/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IByteRange, IRandomAccessDataSource} from '@colibrio/colibrio-reader-framework/colibrio-core-io-base';
import {ZipResourceProvider} from '@colibrio/colibrio-reader-framework/colibrio-core-io-resourceprovider-zip';
import {IPublication, PublicationType} from '@colibrio/colibrio-reader-framework/colibrio-core-publication-base';
import {
    EpubOcfResourceProvider,
    IEpubOcfResourceProvider,
} from '@colibrio/colibrio-reader-framework/colibrio-core-publication-epub';
import {IPdfPublication, PdfPublication} from '@colibrio/colibrio-reader-framework/colibrio-core-publication-pdf';
import {WpPublication} from '@colibrio/colibrio-reader-framework/colibrio-core-publication-wp';
import {
    IKeyboardEngineEvent, IMouseEngineEvent,
    INavigationEndedEngineEvent,
    INavigationIntentEngineEvent,
    INavigationStartedEngineEvent,
    IPointerEngineEvent,
    IReaderPublication,
    IReaderPublicationOptions,
    IReaderView,
    IReaderViewEngineEvent, IReaderViewTransformEngineEvent,
    IReadingSessionOptions,
    IReadingSystemEngine,
    ISelectionChangedEngineEvent,
    ISyncMediaPlayer,
    ISyncMediaPlayerInitOptions,
    ISyncMediaTimeline,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';
import {
    ReaderViewEngineEvent,
    ReadingSystemEngine,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-engine';
import {
    EpubFormatAdapter,
    IEpubReaderPublication,
    IEpubReaderPublicationOptions,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-formatadapter-epub';
import {
    IPdfReaderPublication,
    IPdfReaderPublicationOptions,
    PdfFormatAdapter,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-formatadapter-pdf';
import {
    WpAudiobookFormatAdapter,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-formatadapter-wp-audiobook';
import {
    HttpCachingRandomAccessDataSource,
    PreloadProgressCallback,
} from '../utils/io/http/HttpCachingRandomAccessDataSource';
import {HttpDataSource} from '../utils/io/http/HttpDataSource';
import {HttpHeaderReader} from '../utils/io/http/HttpHeaderReader';
import {IResourceMetadata} from '../utils/io/http/IResourceMetadata';
import {
    IStoredResource,
    IVanillaReaderStreamedResourceStorage,
} from '../VanillaReaderDataStore/VanillaReaderStreamedResourceIndexDbStorage';
import {
    IVanillaReaderAppEvent_APP_ONLINE_STATE_CHANGED,
    VanillaReaderAppEvents,
    VanillaReaderEventBus,
} from './VanillaReaderEventBus';
import {
    IVanillaReaderUserProfileData,
    IVanillaReaderViewOptions,
    VanillaKeyboardEventCallback, VanillaMouseEventCallback,
    VanillaNavigationEndedEventCallback,
    VanillaNavigationIntentEventCallback,
    VanillaNavigationStartedEventCallback,
    VanillaPointerEventCallback, VanillaProgressEventCallback,
    VanillaReaderViewEventCallback,
    VanillaSelectionChangedEventCallback, VanillaViewTransformChangedCallback,
    VanillaVoidCallback
} from './VanillaReaderModel';
import {
    VanillaReaderDefaultColibrioEpubReaderPublicationOptions,
    VanillaReaderDefaultColibrioReaderViewOptions
} from "./VanillaReaderDefaultOptions";

/**
 * # VanillaReaderReadingSystem
 *
 *  ## RESPONSIBILITIES
 *
 * This class wraps the Colibrio Reader Framework's `ReadingSystemEngine` API in order to give you some additional, more
 * high level methods for loading publications.
 *
 * The VanillaReaderReadingSystem also handles of all the relevant Colibrio Reader Framework `EngineEvents` and exposes
 * callback methods (onReadingPositionChanged etc.) for each of them through its API.
 *
 * ## OVERVIEW OF RESOURCE LOADING
 *
 * General flow of events when loading a file into the Colibrio `ReadingSystemEngine`:
 *
 * ```
 * File data --> 1. IResourceProvider --> 2. IPublication --> 3. IFormatAdapter -->  4. IReaderPublication
 * ```
 *
 * EPUB Example:
 * 1. An EPUB file is provided to an `EpubOcfResourceProvider` as a File object.
 * 2. The resource provider returns an instance of `EpubPublication`.
 * 3. The `EpubPublication` is loaded into the Colibrio `ReadingSystemEngine` by calling its `loadPublication` method.
 * 4. If the `EpubFormatAdapter` has been added to the `ReadingSystemEngine` it will process the publication and return an `EpubReaderPublication` that can be rendered in the `ReaderView`.
 *
 * Colibrio has a very powerful and flexible system for loading publication data. Three of the central concepts that you
 * will encounter in this file are,
 *
 * - `IResourceProvider` - A file format aware component that knows how to fetch requested resources from within a package.
 * - `IRandomAccessDataSource` - A component that stream data while abstracting away the actual location of a publication file.
 * - `IFormatAdapter` - A component that knows how to render a specific file format etc.
 *
 * The following implementations of `IResourceProvider`s are used in this class,
 *
 * - `EpubOcfResourceProvider` - Knows all about how to find resources inside an EPUB OCF container.
 * - `WpAudiobookResourceProvider` - Knows all about how to find resources inside an WebPublication Audiobook LPF.
 * - `PdfResourceProvider` - Knows all about how to find resources inside a PDF.
 *
 * Two different `IRandomAccessDataSource` implementations are used to stream publications over HTTP,
 *
 * - `HttpRandomAccessDataSource` - Helps the `IResourceProvider` retrieve data in the form of chunks.
 * - `HttpCachingRandomAccessDataSource` - Same as above, but also caches chunks for offline retrieval.
 *
 * The loading of publication is handled in one of three methods,
 *
 * - `loadPublicationFromFile` - If you have a File object this is the method to call.
 * - `loadPublicationFromArrayBuffer` - If you have an ArrayBuffer this is the method to call. Uses `loadPublicationFromFile` "under the hood".
 * - `loadPublicationFromUrl` - If your file is located on an HTTP server use this method.
 *
 * All these methods return an instance that implements `IReaderPublication` that later will be used to actually render the
 * publication using the Colibrio `ReaderView` (see the `VanillaReaderView` class).
 *
 * ## PRIMARY COLIBRIO FRAMEWORK TYPES
 *
 * - `ReadingSystemEngine`
 * - `EpubFormatAdapter`
 * - `EpubPublication`
 * - `PdfFormatAdapter`
 * - `PdfPublication`
 * - `EpubOcfResourceProvider`
 *
 */

export class VanillaReaderReadingSystem {
    private _colibrioReadingSystem: IReadingSystemEngine;

    private _navigationStartedCallback: VanillaNavigationStartedEventCallback | undefined = undefined;
    private _navigationEndedCallback: VanillaNavigationEndedEventCallback | undefined = undefined;
    private _readingPositionUpdatedCallback: VanillaReaderViewEventCallback | undefined = undefined;
    private _navigationIntentCallback: VanillaNavigationIntentEventCallback | undefined = undefined;
    private _selectionChangedCallback: VanillaSelectionChangedEventCallback | undefined = undefined;
    private _clickCallback: VanillaMouseEventCallback | undefined = undefined;
    private _pointerDownCallback: VanillaPointerEventCallback | undefined = undefined;
    private _pointerUpCallback: VanillaPointerEventCallback | undefined = undefined;
    private _visibleRangeChangedCallback: VanillaReaderViewEventCallback | undefined = undefined;
    private _visibleContentRenderedCallback: VanillaReaderViewEventCallback | undefined = undefined;
    private _keyDownCallback: VanillaKeyboardEventCallback | undefined = undefined;
    private _keyUpCallback: VanillaKeyboardEventCallback | undefined = undefined;
    private _publicationDownloadProgressCallback: PreloadProgressCallback | undefined = undefined;
    private _publicationRenderedCallback: VanillaVoidCallback | undefined = undefined;
    private _activeViewTransformChangedCallback: VanillaViewTransformChangedCallback | undefined = undefined;
    // private _activeViewTransformResetCallback: VanillaVoidCallback | undefined = undefined;
    // private _currentActiveViewTransform: ITransformData | null = null;

    private _vanillaResourceStorageClient: IVanillaReaderStreamedResourceStorage | undefined = undefined;
    private _isOffline: boolean = false;
    private _colibrioEpubReaderPublicationOptions: IEpubReaderPublicationOptions;
    private _vanillaReaderUserProfileData: IVanillaReaderUserProfileData;


    constructor(
        licenseApiKey: string,
        vanillaUserProfileData: IVanillaReaderUserProfileData,
        vanillaResourceStorageClient?: IVanillaReaderStreamedResourceStorage,
    ) {
        this._colibrioReadingSystem = new ReadingSystemEngine({
            licenseApiKey: licenseApiKey,
        });

        // If the Imbiblio Reader is installed as a PWA, this store is used to cache the binary chunks for offline
        // access.
        this._vanillaResourceStorageClient = vanillaResourceStorageClient;

        // The IVanillaReaderUserProfileData holds the user token for the current user. This is provided by you and
        // is used in the communication with the Colibrio license server whenever a publication is loaded.
        this._vanillaReaderUserProfileData = vanillaUserProfileData;

        this.create();

        // Here we define the default EPUB publication options that will be used when an EPUB is loaded.
        this._colibrioEpubReaderPublicationOptions = VanillaReaderDefaultColibrioEpubReaderPublicationOptions;
    }

    private create() {

        /*
        *
        * The Colibrio Reader Framework is an event driven system. All state changes etc. within the framework are
        * communicated using so called `IEngineEvent`s (see the event type map in the API reference for a full list
        * https://learn.colibrio.com/api/3.0/interfaces/_colibrio_readingsystem_base_d_.iengineeventtypemap.html).
        *
        * Here we set up listeners to some of the listeners that we will need.
        *
        * */

        this._colibrioReadingSystem.addEngineEventListener('click', this._colibrioReadingSystem_event_click);
        this._colibrioReadingSystem.addEngineEventListener('keydown', this._colibrioReadingSystem_event_keyDown);
        this._colibrioReadingSystem.addEngineEventListener('keyup', this._colibrioReadingSystem_event_keyUp);
        this._colibrioReadingSystem.addEngineEventListener('navigationIntent', this._colibrioReadingSystem_event_navigationIntent);
        this._colibrioReadingSystem.addEngineEventListener('navigationStarted', this._colibrioReadingSystem_event_navigationStarted);
        this._colibrioReadingSystem.addEngineEventListener('navigationEnded', this._colibrioReadingSystem_event_navigationEnded);
        this._colibrioReadingSystem.addEngineEventListener('readingPositionChanged', this._colibrioReadingSystem_event_readingPositionUpdated);
        this._colibrioReadingSystem.addEngineEventListener('pointerdown', this._colibrioReadingSystem_event_pointerDown);
        this._colibrioReadingSystem.addEngineEventListener('pointerup', this._colibrioReadingSystem_event_pointerUp);
        this._colibrioReadingSystem.addEngineEventListener('selectionChanged', this._colibrioReadingSystem_event_selectionChanged);
        this._colibrioReadingSystem.addEngineEventListener('visibleRangeChanged', this._colibrioReadingSystem_event_visibleRangeChanged);
        this._colibrioReadingSystem.addEngineEventListener('visibleContentRendered', this._colibrioReadingSystem_event_visibleContentRendered);
        this._colibrioReadingSystem.addEngineEventListener('activeTransformChanged', this._colibrioReadingSystem_event_activeTransformChanged);

        // Add listener for the event that tells us if the device has lost internet connectivity.
        VanillaReaderEventBus.addEventListener<IVanillaReaderAppEvent_APP_ONLINE_STATE_CHANGED>(VanillaReaderAppEvents.APP_ONLINE_STATE_CHANGED, this._event_onlineStateChanged);
    }

    destroy() {
        this._colibrioReadingSystem.destroy();
    }

    /**
     *
     * CALLBACKS
     *
     * */

    onNavigationStarted(callback: VanillaNavigationStartedEventCallback) {
        this._navigationStartedCallback = callback;
    }

    onNavigationEnded(callback: VanillaNavigationEndedEventCallback) {
        this._navigationEndedCallback = callback;
    }

    onReadingPositionChanged(callback: VanillaReaderViewEventCallback) {
        this._readingPositionUpdatedCallback = callback;
    }

    onNavigationIntent(callback: VanillaNavigationIntentEventCallback) {
        this._navigationIntentCallback = callback;
    }

    onSelectionChanged(callback: VanillaSelectionChangedEventCallback) {
        this._selectionChangedCallback = callback;
    }

    onClick(callback: VanillaMouseEventCallback) {
        this._clickCallback = callback;
    }

    onPointerDown(callback: VanillaPointerEventCallback) {
        this._pointerDownCallback = callback;
    }

    onPointerUp(callback: VanillaPointerEventCallback) {
        this._pointerUpCallback = callback;
    }

    onVisibleRangeChanged(callback: VanillaReaderViewEventCallback) {
        this._visibleRangeChangedCallback = callback;
    }

    onVisibleContentRendered(callback: VanillaReaderViewEventCallback) {
        this._visibleContentRenderedCallback = callback;
    }

    onKeyDown(callback: VanillaKeyboardEventCallback) {
        this._keyDownCallback = callback;
    }

    onKeyUp(callback: VanillaKeyboardEventCallback) {
        this._keyUpCallback = callback;
    }

    onPublicationDownloadProgress(callback: VanillaProgressEventCallback) {
        this._publicationDownloadProgressCallback = callback;
    }

    /**
     * onPublicationRendered fires the first time the Colibrio `ReaderView` renders publication content on the screen.
     * */
    onPublicationRendered(callback: VanillaVoidCallback) {
        this._publicationRenderedCallback = callback;
    }

    onActiveReaderViewTransformChanged(callback: VanillaViewTransformChangedCallback) {
        this._activeViewTransformChangedCallback = callback;
    }

    createReaderView(_initialViewOptions: IVanillaReaderViewOptions | undefined): IReaderView {

        return this._colibrioReadingSystem.createReaderView(VanillaReaderDefaultColibrioReaderViewOptions);
    }

    createSyncMediaPlayer(timeline: ISyncMediaTimeline, options?: ISyncMediaPlayerInitOptions) {
        return this._colibrioReadingSystem.createSyncMediaPlayer(timeline, options);
    }

    destroySyncMediaPlayer(player: ISyncMediaPlayer) {
        this._colibrioReadingSystem.destroySyncMediaPlayer(player);
    }

    getColibrioReaderPublication(): IReaderPublication {
        return this._colibrioReadingSystem.getReaderPublications()[0];
    }

    async loadPublicationFromUrl(
        publicationUrl: string,
        size?: number | undefined,
        storeOnDevice?: boolean,
    ): Promise<IReaderPublication> {

        return this._getPublicationFromUrl(publicationUrl, size, storeOnDevice).then(async (publication) => {
            let loadPublicationPromise: Promise<IReaderPublication>;

            const readingSessionOptions: IReadingSessionOptions = {
                publicationToken: publication.getHashSignature(),
                userToken: this._vanillaReaderUserProfileData.token,
            };

            const onVisibleContentRendered = (_e: IReaderViewEngineEvent) => {
                if (this._publicationRenderedCallback) {
                    this._publicationRenderedCallback();
                }
                this._colibrioReadingSystem.removeEngineEventListener('visibleContentRendered', onVisibleContentRendered);
            };

            // Add a one-off event listener for `visibleContentRendered`. This way we can tell the `VanillaReader` when
            // something has actually been rendered to the screen.
            this._colibrioReadingSystem.addEngineEventListener('visibleContentRendered', onVisibleContentRendered);

            switch (publication.getType()) {
                case PublicationType.EPUB:
                    loadPublicationPromise = this._loadPublication<IEpubReaderPublication>(publication, this._colibrioEpubReaderPublicationOptions, readingSessionOptions);
                    break;
                case PublicationType.PDF:
                    let pdfReaderPublicationOptions: IReaderPublicationOptions = {};
                    loadPublicationPromise = this._loadPublication<IPdfReaderPublication>(publication, pdfReaderPublicationOptions, readingSessionOptions);
                    break;
                case PublicationType.WP:
                default:
                    let defaultReaderPublicationOptions: IReaderPublicationOptions = {};
                    loadPublicationPromise = this._loadPublication<IReaderPublication>(publication, defaultReaderPublicationOptions, readingSessionOptions);
            }

            return loadPublicationPromise;
        });
    }

    async loadPublicationFromArrayBuffer(
        fileBuffer: ArrayBuffer,
        fileName: string,
        storeOnDevice: boolean = false,
    ): Promise<IReaderPublication> {
        let file = new File([fileBuffer], fileName);
        return this.loadPublicationFromFile(file, storeOnDevice);
    }

    async loadPublicationFromFile(file: File, storeOnDevice: boolean = false): Promise<IReaderPublication> {
        let fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1).toUpperCase();
        let readerPublicationPromise: Promise<IReaderPublication>;

        switch (fileExtension) {

            case 'EPUB':

                this._colibrioReadingSystem.addFormatAdapter(new EpubFormatAdapter());

                readerPublicationPromise = this._getEpubPublicationFromFile(file, storeOnDevice).then(async (publication) => {
                    const readingSessionOptions: IReadingSessionOptions = {
                        publicationToken: publication.getHashSignature(),
                        userToken: this._vanillaReaderUserProfileData.token,
                    };

                    return this._colibrioReadingSystem.loadPublication(publication, this._colibrioEpubReaderPublicationOptions, readingSessionOptions);

                });

                break;

            case 'PDF':
                this._colibrioReadingSystem.addFormatAdapter(new PdfFormatAdapter());

                readerPublicationPromise = this._getPdfPublicationFromFile(file, storeOnDevice).then(async (publication: IPublication) => {
                    const readerPublicationOptions: IReaderPublicationOptions = {};

                    const readingSessionOptions: IReadingSessionOptions = {
                        publicationToken: publication.getHashSignature(),
                        userToken: this._vanillaReaderUserProfileData.token,
                    };

                    return this._colibrioReadingSystem.loadPublication(publication, readerPublicationOptions, readingSessionOptions);

                });

                break;

            case 'LPF':
                this._colibrioReadingSystem.addFormatAdapter(new WpAudiobookFormatAdapter());

                readerPublicationPromise = this._getWpAudiobookPublicationFromFile(file, storeOnDevice).then((publication) => {
                    const readerPublicationOptions = {};
                    const readingSessionOptions: IReadingSessionOptions = {
                        publicationToken: publication.getHashSignature(),
                        userToken: this._vanillaReaderUserProfileData.token,
                    };

                    return this._colibrioReadingSystem.loadPublication(publication, readerPublicationOptions, readingSessionOptions);
                });

                break;

            default:
                throw new DOMException('loadPublicationFromFile', 'file format "' + file.type + '" not supported.');
        }

        const onVisibleContentRendered = (_e: IReaderViewEngineEvent) => {
            if (this._publicationRenderedCallback) {
                this._publicationRenderedCallback();
            }
            this._colibrioReadingSystem.removeEngineEventListener('visibleContentRendered', onVisibleContentRendered);
        };

        // Add a one-off event listener for `visibleContentRendered`. This way we can tell the `VanillaReader` when
        // something has actually been rendered to the screen.
        this._colibrioReadingSystem.addEngineEventListener('visibleContentRendered', onVisibleContentRendered);

        return readerPublicationPromise;

    }

    /*
    *
    * PRIVATE METHODS
    *
    * */

    private async _loadPublication<T>(
        publication: IPublication,
        readerPublicationOptions: IReaderPublicationOptions | IEpubReaderPublicationOptions | IPdfReaderPublicationOptions,
        readingSessionOptions: IReadingSessionOptions,
    ): Promise<T> {

        return await this._colibrioReadingSystem.loadPublication(publication, readerPublicationOptions, readingSessionOptions).then(async (readerPublication: IReaderPublication) => {
            return readerPublication;
        }) as T;

    }

    private async _getPublicationFromUrl(
        url: string,
        size?: number | undefined,
        storeOnDevice: boolean = false,
    ): Promise<IPublication> {
        let mediaType = '';
        let publication: IPublication | null = null;
        let chunkDescriptors: IByteRange[] = [];
        let dataSource: IRandomAccessDataSource | undefined;
        let resourceInfo: IResourceMetadata | undefined;
        let resourceSize: number | undefined;
        // let fileExtension = url.slice(url.lastIndexOf('.') + 1).toUpperCase();
        let fileExtension = ""; 

        if (this._isOffline && !this._vanillaResourceStorageClient) {
            throw new DOMException('Device is not connected to the Internet, and no IResourceStorage has been set.');
        }

        if (!this._isOffline) {
            if (!size) {
                resourceInfo = await HttpHeaderReader.fromUrl(url);
                resourceSize = resourceInfo.size;
                mediaType = resourceInfo.mediaType;
                fileExtension = this.getFileExtensionFromMediaType(mediaType);

            } else {
                resourceSize = size;
            }

        } else {
            if (this._vanillaResourceStorageClient && !this._vanillaResourceStorageClient.hasResource(url)) {
                throw new DOMException(`Device is not connected to the Internet, and publication "${url}" is not stored.`);
            } else {
                let resource = await this._vanillaResourceStorageClient!.getResource(url) as IStoredResource;
                resourceSize = resource.size;
                mediaType = resource.mimetype;
            }
        }

        if (storeOnDevice && !this._vanillaResourceStorageClient) {
            console.warn('VanillaReaderReadingSystem.loadPublicationFromUrl', 'Can not store publication data on device as no IResourceStorage has been set.');
            storeOnDevice = false;
        }

        if (!resourceSize) {
            throw Error('VanillaReaderReadingSystem._getPublicationFromUrl(): Unable to resolve resource size.');
        }

        if (storeOnDevice && this._vanillaResourceStorageClient) {
            let fileName = url.slice(url.lastIndexOf('/') + 1);
            this._vanillaResourceStorageClient.addResource({
                name: fileName,
                url: url,
                id: url,
                size: resourceSize,
                mimetype: mediaType as string,
            });
            dataSource = new HttpCachingRandomAccessDataSource(this._vanillaResourceStorageClient, url, url, resourceSize);

        } else {
            dataSource = new HttpDataSource(url, resourceSize);
        }

        switch (fileExtension) {

            case 'EPUB':
                this._colibrioReadingSystem.addFormatAdapter(new EpubFormatAdapter());

                //let epubCore = await this._importEpubSupport();
                let zipResourceProvider = await ZipResourceProvider.createFromRandomAccessDataSource(dataSource, {
                    transferBuffers: true,
                });

                if (storeOnDevice) {
                    chunkDescriptors = zipResourceProvider.getDataChunkDescriptors();
                }

                let epubOcfResourceProvider = await EpubOcfResourceProvider.createFromBackingResourceProvider(zipResourceProvider, zipResourceProvider.getCentralDirectorySha1Signature());

                publication = epubOcfResourceProvider.getDefaultPublication();
                break;

            case 'PDF':
                this._colibrioReadingSystem.addFormatAdapter(new PdfFormatAdapter());
                let chunkSize = 128000;
                let fileSize = dataSource.getSize();
                publication = await PdfPublication.createFromRandomAccessDataSource(dataSource, {
                    enableDeterministicChunkRequests: true,
                    chunkSize,
                });

                if (storeOnDevice) {

                    // Fill the chunkDescriptors array with all the byte ranges that will be requested by the data source.
                    for (let i = 0; i < fileSize; i += chunkSize) {
                        chunkDescriptors.push({
                            start: i,
                            end: Math.min(i + chunkSize, fileSize),
                        });
                    }
                }

                break;

            case 'LPF':
                this._colibrioReadingSystem.addFormatAdapter(new WpAudiobookFormatAdapter());
                let backingZipResourceProvider = await ZipResourceProvider.createFromRandomAccessDataSource(dataSource, {
                    transferBuffers: true,
                });

                publication = await WpPublication.createFromManifestUrl(backingZipResourceProvider, 'publication.json', backingZipResourceProvider.getCentralDirectorySha1Signature(), () => {
                    backingZipResourceProvider.destroy();
                });

                if (storeOnDevice) {
                    chunkDescriptors = backingZipResourceProvider.getDataChunkDescriptors();
                }

                break;
        }

        if (storeOnDevice) {
            (dataSource as HttpCachingRandomAccessDataSource).setChunkDescriptors(chunkDescriptors);
            (dataSource as HttpCachingRandomAccessDataSource).preload().catch(console.error);
            (dataSource as HttpCachingRandomAccessDataSource).onPreloadProgressUpdated((progress) => {
                if (this._publicationDownloadProgressCallback) {
                    this._publicationDownloadProgressCallback(progress, url);
                }
            });
        }

        if (!publication) {
            throw new Error('The file did not contain any supported publication type');
        }

        return publication;
    }

    private getFileExtensionFromMediaType(mediaType: string): string {
        switch (mediaType) {
            case "application/pdf":
                return "PDF";
            case "application/epub+zip":
                return "EPUB";
            case "application/lpf+zip":
                return "LPF";
            default:
                return "unknown"; 
        }
    }

    private async _getEpubPublicationFromFile(file: File, storeOnDevice: boolean) {
        let epubOcfResourceProvider: IEpubOcfResourceProvider;
        epubOcfResourceProvider = await EpubOcfResourceProvider.createFromBlob(file);
        let publication = epubOcfResourceProvider.getDefaultPublication();

        if (!publication) {
            throw new Error('VanillaReadingSystem._getEpubPublicationFromFile(): The file did not contain any EPUB publication');

        } else if (storeOnDevice && this._vanillaResourceStorageClient) {
            this._maybeAddPublicationToStorage(file).catch(console.warn);
        }

        return publication;
    }

    private async _getPdfPublicationFromFile(file: File, storeOnDevice: boolean) {
        let publication: IPdfPublication;
        publication = await PdfPublication.createFromBlob(file);

        if (!publication) {
            throw new Error('VanillaReadingSystem._getPdfPublicationFromFile(): File did not contain a valid PDF.');
        } else {
            if (storeOnDevice && this._vanillaResourceStorageClient) {
                this._maybeAddPublicationToStorage(file).catch(console.warn);
            }
        }

        return publication;
    }

    private async _getWpAudiobookPublicationFromFile(file: File, storeOnDevice: boolean) {

        let publication = WpPublication.createFromBlob(file);

        if (!publication) {
            throw new Error('VanillaReadingSystem._getWpAudiobookPublicationFromFile(): The file did not contain any EPUB publication');

        } else if (storeOnDevice && this._vanillaResourceStorageClient) {
            this._maybeAddPublicationToStorage(file).catch(console.warn);
        }

        return publication;
    }

    private async _maybeAddPublicationToStorage(file: File) {

        if (!this._vanillaResourceStorageClient) {
            return;
        }

        let fileUrl = `file://${file.name}`;
        let publicationHasStoredResources = await this._vanillaResourceStorageClient.hasResource(fileUrl);
        if (!publicationHasStoredResources) {

            this._vanillaResourceStorageClient.addResource({
                name: file.name,
                url: fileUrl,
                id: fileUrl,
                size: file.size,
                mimetype: file.type as string,
            });

            let fileArrayBuffer = await file.arrayBuffer();
            this._vanillaResourceStorageClient.addResourceData(fileUrl, 0, file.size, fileArrayBuffer);

            if (this._publicationDownloadProgressCallback) {
                this._publicationDownloadProgressCallback(1, fileUrl);
            }

        }
    }

    /*
    *
    * EVENT HANDLERS
    *
    * */

    private _event_onlineStateChanged = (ev: CustomEvent<IVanillaReaderAppEvent_APP_ONLINE_STATE_CHANGED>) => {
        this._isOffline = !ev.detail.isOnline;
    };

    private _colibrioReadingSystem_event_navigationIntent = (ev: INavigationIntentEngineEvent) => {
        if (this._navigationIntentCallback) {
            this._navigationIntentCallback(ev);
        }
    };

    private _colibrioReadingSystem_event_readingPositionUpdated = (ev: IReaderViewEngineEvent) => {
        if (this._readingPositionUpdatedCallback) {
            this._readingPositionUpdatedCallback(ev);
        }
    };

    private _colibrioReadingSystem_event_navigationStarted = (ev: INavigationStartedEngineEvent) => {
        if (this._navigationStartedCallback) {
            this._navigationStartedCallback(ev);
        }
    };

    private _colibrioReadingSystem_event_navigationEnded = (ev: INavigationEndedEngineEvent) => {
        if (this._navigationEndedCallback) {
            this._navigationEndedCallback(ev);
        }
    };

    private _colibrioReadingSystem_event_click = (ev: IMouseEngineEvent) => {
        if (this._clickCallback) {
            this._clickCallback(ev);
        }
    };

    private _colibrioReadingSystem_event_pointerDown = (ev: IPointerEngineEvent) => {
        if (this._pointerDownCallback) {
            this._pointerDownCallback(ev);
        }
    };

    private _colibrioReadingSystem_event_pointerUp = (ev: IPointerEngineEvent) => {
        if (this._pointerUpCallback) {
            this._pointerUpCallback(ev);
        }
    };

    private _colibrioReadingSystem_event_visibleRangeChanged = (ev: ReaderViewEngineEvent) => {
        if (this._visibleRangeChangedCallback) {
            this._visibleRangeChangedCallback(ev);
        }
    };

    private _colibrioReadingSystem_event_visibleContentRendered = (ev: ReaderViewEngineEvent) => {
        if (this._visibleContentRenderedCallback) {
            this._visibleContentRenderedCallback(ev);
        }
    };

    private _colibrioReadingSystem_event_activeTransformChanged = (ev: IReaderViewTransformEngineEvent) => {
        if (this._activeViewTransformChangedCallback) {
            this._activeViewTransformChangedCallback(ev.transform);
        }
    };


    private _colibrioReadingSystem_event_selectionChanged = (ev: ISelectionChangedEngineEvent) => {
        if (this._selectionChangedCallback) {
            this._selectionChangedCallback(ev);
        }
    };

    private _colibrioReadingSystem_event_keyDown = (ev: IKeyboardEngineEvent) => {
        if (this._keyDownCallback) {
            this._keyDownCallback(ev);
        }
    };

    private _colibrioReadingSystem_event_keyUp = (ev: IKeyboardEngineEvent) => {
        if (this._keyUpCallback) {
            this._keyUpCallback(ev);
        }
    };
}