/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {Dexie} from 'dexie';
import {
    VanillaPublicationCacheDataCallback,
    VanillaPublicationDataCallback,
    VanillaPublicationOptionsDataCallback,
    VanillaPublicationStateDataCallback,
    VanillaStringValueCallback,
    VanillaVoidCallback,
    IVanillaReaderPublicationCacheData,
    IVanillaReaderPublicationData,
    IVanillaReaderPublicationOptionsData,
    IVanillaReaderPublicationStateData,
    IVanillaReaderReadingPositionData,
} from '../VanillaReader/VanillaReaderModel';
import {IVanillaReaderPublicationDataStore} from './IVanillaReaderPublicationDataStore';

/*
* # VanillaReaderIndexedDbDataStore
*
* ## RESPONSIBILITIES
*
* This is an implementation of the `IVanillaReaderDataStore` interface that uses IndexedDb. IndexedDb is the preferred
* way to store web app data, However since its APIs are asynchronous it is not possible to use to store state when
* the application is closed by the system, or when it is put in the background on mobile devices.
*
* I suggest using it in combination with the `VanillaReaderLocalStorageDataStore` to get both a fail-safe temp storage,
* as well as a long-term storage.
*
* Checkout the `IVanillaReaderDataStore` interface documentation for more general information.
*
* */

export class VanillaReaderPublicationIndexedDbDataStore extends Dexie implements IVanillaReaderPublicationDataStore {

    private _vanillaReaderPublicationCacheData: Dexie.Table<IVanillaReaderPublicationCacheData, string>;
    private _vanillaReaderPublicationOptionsData: Dexie.Table<IVanillaReaderPublicationOptionsData, string>;
    private _vanillaReaderPublicationData: Dexie.Table<IVanillaReaderPublicationData, string>;
    private _vanillaReaderPublicationReadingPositionData: Dexie.Table<IVanillaReaderReadingPositionData, string>;

    private _onPublicationDataAdded: VanillaPublicationStateDataCallback | undefined;
    private _onPublicationDataUpdated: VanillaPublicationStateDataCallback | undefined;
    private _onPublicationDataDeleted: VanillaStringValueCallback | undefined;

    private _onPublicationCacheDataAdded: VanillaPublicationCacheDataCallback | undefined;
    private _onPublicationCacheDataUpdated: VanillaPublicationCacheDataCallback | undefined;
    private _onPublicationCacheDataDeleted: VanillaStringValueCallback | undefined;

    // @ts-ignore
    private _onPublicationOptionsDataAdded: VanillaPublicationOptionsDataCallback | undefined;
    private _onPublicationOptionsDataUpdated: VanillaPublicationOptionsDataCallback | undefined;
    private _onPublicationOptionsDataDeleted: VanillaVoidCallback | undefined;

    constructor(dbName: string = 'publication.vanillareader.colibrio.com', persist = true) {
        super(dbName);

        if (persist && navigator.storage && navigator.storage.persist) {
            navigator.storage.persist().then((canPersist) => {
                if (canPersist) {
                    console.log('VanillaReaderPublicationIndexedDbDataStore will persist data.');
                } else {
                    console.log('VanillaReaderPublicationIndexedDbDataStore will NOT persist data.');
                }
            }).catch(console.warn);
        }

        this.version(1).stores({
            vanillaReaderPublicationData: 'id, fileName, url',
            vanillaReaderPublicationOptionsData: 'publicationId',
            vanillaReaderPublicationCacheData: 'publicationId',
            vanillaReaderPublicationReadingPositionData: 'publicationId',
        });

        this._vanillaReaderPublicationData = this.table('vanillaReaderPublicationData');
        this._vanillaReaderPublicationOptionsData = this.table('vanillaReaderPublicationOptionsData');
        this._vanillaReaderPublicationCacheData = this.table('vanillaReaderPublicationCacheData');
        this._vanillaReaderPublicationReadingPositionData = this.table('vanillaReaderPublicationReadingPositionData');
    }

    /**
     *
     * IVanillaReaderPublicationMetadata
     *
     * */

    async hasPublicationData(publicationId: string): Promise<boolean> {
        return await this._vanillaReaderPublicationData.where('id').equals(publicationId).count() > 0;
    }

    async hasPublicationDataByFileName(fileName: string): Promise<boolean> {
        return await this._vanillaReaderPublicationData.where('fileName').equals(fileName).count() > 0;
    }

    fetchAllPublicationData(): Promise<IVanillaReaderPublicationData[]> {
        return this._vanillaReaderPublicationData.toArray();
    }

    async fetchPublicationDataByFileName(fileName: string): Promise<IVanillaReaderPublicationStateData | undefined> {
        return this._vanillaReaderPublicationData.where('fileName').equals(fileName).first((state) => {
            if (state) {
                return Promise.resolve(state);
            }
            return Promise.resolve(undefined);
        });
    }

    async setPublicationDataByFileName(
        fileName: string,
        state: IVanillaReaderPublicationData,
    ): Promise<void> {
        // There can be only one!
        await this.deletePublicationDataByFileName(fileName);
        await this._vanillaReaderPublicationData.add(state);

        if (this._onPublicationDataAdded) {
            this._onPublicationDataAdded(state);
        }

        return Promise.resolve();
    }

    async updatePublicationDataByFileName(
        fileName: string,
        state: IVanillaReaderPublicationStateData,
    ): Promise<void> {
        await this._vanillaReaderPublicationData.where('fileName').equals(fileName).modify(state);

        if (this._onPublicationDataUpdated) {
            this._onPublicationDataUpdated(state);
        }

        return Promise.resolve(undefined);
    }

    async deletePublicationDataByFileName(fileName: string): Promise<number> {
        let numberOfDeletions = this._vanillaReaderPublicationData.where('fileName').equals(fileName).delete();

        if (this._onPublicationDataDeleted) {
            this._onPublicationDataDeleted(fileName);
        }

        return Promise.resolve(numberOfDeletions);
    }

    async fetchPublicationData(publicationId: string): Promise<IVanillaReaderPublicationData | undefined> {
        return this._vanillaReaderPublicationData.where('id').equals(publicationId).first((state) => {
            if (state) {
                return Promise.resolve(state);
            }
            return Promise.resolve(undefined);
        });
    }

    async setPublicationData(
        publicationId: string,
        state: IVanillaReaderPublicationData,
    ): Promise<void> {
        // There can be only one!
        await this._vanillaReaderPublicationData.where('id').equals(publicationId).delete();
        await this._vanillaReaderPublicationData.add(state);

        if (this._onPublicationDataAdded) {
            this._onPublicationDataAdded(state);
        }

        return Promise.resolve();
    }

    async deletePublicationData(publicationId: string): Promise<number> {
        let numberOfDeletions = this._vanillaReaderPublicationData.where('id').equals(publicationId).delete();

        if (this._onPublicationDataDeleted) {
            this._onPublicationDataDeleted(publicationId);
        }

        return Promise.resolve(numberOfDeletions);
    }

    async updatePublicationData(
        publicationId: string,
        state: IVanillaReaderPublicationStateData,
    ): Promise<void> {
        await this._vanillaReaderPublicationData.where('id').equals(publicationId).modify(state);

        if (this._onPublicationDataUpdated) {
            this._onPublicationDataUpdated(state);
        }

        return Promise.resolve(undefined);
    }

    /**
     *
     * IVanillaReaderPublicationCacheData
     *
     * */

    async hasPublicationCacheData(publicationId: string): Promise<boolean> {
        return await this._vanillaReaderPublicationCacheData.where('publicationId').equals(publicationId).count() > 0;
    }

    async fetchPublicationCacheData(publicationId: string): Promise<IVanillaReaderPublicationCacheData | undefined> {
        return this._vanillaReaderPublicationCacheData.where('publicationId').equals(publicationId).first((state) => {
            if (state) {
                return Promise.resolve(state);
            }
            return Promise.resolve(undefined);
        });
    }

    async setPublicationCacheData(
        publicationId: string,
        state: IVanillaReaderPublicationCacheData,
    ): Promise<void> {
        // There can be only one!
        await this._vanillaReaderPublicationCacheData.where('publicationId').equals(publicationId).delete();
        await this._vanillaReaderPublicationCacheData.add(state, publicationId);

        if (this._onPublicationCacheDataAdded) {
            this._onPublicationCacheDataAdded(state);
        }

        return Promise.resolve();
    }

    async updatePublicationCacheData(publicationId: string, state: IVanillaReaderPublicationCacheData): Promise<void> {

        await this._vanillaReaderPublicationCacheData.update(publicationId, state);

        if (this._onPublicationCacheDataUpdated) {
            this._onPublicationCacheDataUpdated(state);
        }

        return Promise.resolve();
    }

    async deletePublicationCacheData(publicationId: string): Promise<number> {
        let numberOfDeletions: number = await this._vanillaReaderPublicationCacheData.where('publicationId').equals(publicationId).delete();

        if (this._onPublicationCacheDataDeleted) {
            this._onPublicationCacheDataDeleted(publicationId);
        }

        return Promise.resolve(numberOfDeletions);
    }

    /**
     *
     * IVanillaReaderPublicationOptionsData
     *
     * */

    async hasPublicationOptionsData(publicationId: string): Promise<boolean> {
        return await this._vanillaReaderPublicationOptionsData.where('publicationId').equals(publicationId).count() > 0;
    }

    async deletePublicationOptionsData(publicationId: string): Promise<number> {
        let numberOfDeletions: number = await this._vanillaReaderPublicationOptionsData.where('publicationId').equals(publicationId).delete();

        if (this._onPublicationOptionsDataDeleted) {
            this._onPublicationOptionsDataDeleted();
        }

        return Promise.resolve(numberOfDeletions);

    }

    fetchAllPublicationOptionsData(): Promise<IVanillaReaderPublicationOptionsData[]> {
        return this._vanillaReaderPublicationOptionsData.toArray();
    }

    fetchPublicationOptionsData(publicationId: string): Promise<IVanillaReaderPublicationOptionsData | undefined> {
        return this._vanillaReaderPublicationOptionsData.where('publicationId').equals(publicationId).first();
    }

    async setPublicationOptionsData(publicationId: string, state: IVanillaReaderPublicationOptionsData): Promise<void> {
        await this._vanillaReaderPublicationOptionsData.where('publicationId').equals(publicationId).delete();
        await this._vanillaReaderPublicationOptionsData.add(state);

        if (this._onPublicationOptionsDataAdded) {
            this._onPublicationOptionsDataAdded(state);
        }

        return Promise.resolve();
    }

    async updatePublicationOptionsData(
        publicationId: string,
        state: IVanillaReaderPublicationOptionsData,
    ): Promise<void> {
        if(!await this.hasPublicationOptionsData(publicationId)) {
            await this.setPublicationOptionsData(publicationId, {
                publicationId,
                ttsOptions: undefined,
                styleOptions: undefined
            })
        }

        await this._vanillaReaderPublicationOptionsData.update(publicationId, state);

        if (this._onPublicationOptionsDataUpdated) {
            this._onPublicationOptionsDataUpdated(state);
        }

        return Promise.resolve();
    }

    /**
     *
     * IVanillaReaderReadingPositionData
     *
     * */

    async hasPublicationReadingPositionData(publicationId: string): Promise<boolean> {
        return await this._vanillaReaderPublicationReadingPositionData.where('publicationId').equals(publicationId).count() > 0;
    }

    async setPublicationReadingPositionData(publicationId: string, state: IVanillaReaderReadingPositionData) {
        await this._vanillaReaderPublicationReadingPositionData.put(state, publicationId);
        return Promise.resolve();
    }

    async fetchPublicationReadingPositionData(publicationId: string): Promise<IVanillaReaderReadingPositionData | undefined> {
        return this._vanillaReaderPublicationReadingPositionData.get(publicationId);
    }

    async clearPublicationReadingPositionData(publicationId: string): Promise<void> {
        return this._vanillaReaderPublicationReadingPositionData.delete(publicationId);
    }

    /**
     *
     * IVanillaReaderPublicationStateData
     *
     * */


    async fetchCompletePublicationStateData(publicationId: string): Promise<IVanillaReaderPublicationStateData> {
        let publicationMetadataData = await this.fetchPublicationData(publicationId);
        let publicationOptionsData = await this.fetchPublicationOptionsData(publicationId);
        let publicationCacheData = await this.fetchPublicationCacheData(publicationId);
        let publicationReadingPositionData = await this.fetchPublicationReadingPositionData(publicationId);

        return this._serializeStateData(publicationMetadataData, publicationOptionsData, publicationCacheData, publicationReadingPositionData);

    }

    async fetchCompletePublicationStateDataByFileName(fileName: string): Promise<IVanillaReaderPublicationStateData> {
        let publicationOptionsData: IVanillaReaderPublicationOptionsData | undefined;
        let publicationCacheData: IVanillaReaderPublicationCacheData | undefined;
        let publicationReadingPositionData: IVanillaReaderReadingPositionData | undefined;
        let publicationData = await this.fetchPublicationDataByFileName(fileName);

        if (publicationData && publicationData.id) {
            publicationReadingPositionData = await this.fetchPublicationReadingPositionData(publicationData.id);
            publicationOptionsData = await this.fetchPublicationOptionsData(publicationData.id);
            publicationCacheData = await this.fetchPublicationCacheData(publicationData.id);
        }

        return this._serializeStateData(publicationData, publicationOptionsData, publicationCacheData, publicationReadingPositionData);

    }

    async setCompletePublicationStateData(stateData: IVanillaReaderPublicationStateData) {

        if (!stateData.id) {
            console.error('VanillaReaderPublicationIndexedDbDataStore.setCompletePublicationStateData(): No valid id paramenter found in state data.');
            return;
        }

        const publicationData: IVanillaReaderPublicationData = {
            id: stateData?.id,
            fileName: stateData?.fileName || '',
            metadata: stateData?.metadata,
            url: stateData?.url || '',
            isRtl: stateData?.isRtl,
            isAudiobook: stateData?.isAudiobook,
            format: stateData?.format || '',
            title: stateData?.title,
            coverImageAsBase64: stateData?.coverImageAsBase64,
            hasMoSyncMedia: stateData?.hasMoSyncMedia,
            isScripted: stateData?.isScripted,
            isFixedLayout: stateData?.isFixedLayout,
            fileSourceUri: stateData?.fileSourceUri || '',
        };

        this.setPublicationData(stateData.id, publicationData).catch(console.warn);

        const publicationCacheData: IVanillaReaderPublicationCacheData = {
            publicationId: stateData.id,
            searchIndexData: stateData?.searchIndexData,
            navigationTreeData: stateData?.navigationTreeData,
            landmarkData: stateData?.landmarkData,
            timelineData: stateData?.timelineData,
            storageState: stateData?.storageState,
        };

        this.setPublicationCacheData(stateData.id, publicationCacheData).catch(console.warn);

        const publicationOptionsData: IVanillaReaderPublicationOptionsData = {
            publicationId: stateData.id,
            styleOptions: stateData?.styleOptions,
            ttsOptions: stateData?.ttsOptions,
        };

        this.setPublicationOptionsData(stateData.id, publicationOptionsData).catch(console.warn);

        if (stateData.latestReadingPositionData) {
            this.setPublicationReadingPositionData(stateData.id, stateData.latestReadingPositionData).catch(console.warn);
        }
    }

    async _serializeStateData(
        publicationData: IVanillaReaderPublicationData | undefined,
        publicationOptionsData: IVanillaReaderPublicationOptionsData | undefined,
        publicationCacheData: IVanillaReaderPublicationCacheData | undefined,
        publicationReadingPositionData: IVanillaReaderReadingPositionData | undefined,
    ): Promise<IVanillaReaderPublicationStateData> {

        return {
            id: publicationData?.id,
            fileName: publicationData?.fileName || '',
            metadata: publicationData?.metadata,
            url: publicationData?.url || '',
            isRtl: publicationData?.isRtl,
            isAudiobook: publicationData?.isAudiobook,
            format: publicationData?.format || '',
            title: publicationData?.title,
            coverImageAsBase64: publicationData?.coverImageAsBase64,
            hasMoSyncMedia: publicationData?.hasMoSyncMedia,
            isScripted: publicationData?.isScripted,
            isFixedLayout: publicationData?.isFixedLayout,
            fileSourceUri: publicationData?.fileSourceUri || '',

            searchIndexData: publicationCacheData?.searchIndexData,
            navigationTreeData: publicationCacheData?.navigationTreeData,
            landmarkData: publicationCacheData?.landmarkData,
            timelineData: publicationCacheData?.timelineData,
            storageState: publicationCacheData?.storageState,

            latestReadingPositionData: publicationReadingPositionData,

            styleOptions: publicationOptionsData?.styleOptions,
            ttsOptions: publicationOptionsData?.ttsOptions,
        } as IVanillaReaderPublicationStateData;
    }

    /**
     *
     * IVanillaReaderPublicationMetadata
     *
     * */


    onPublicationDataAdded(callback: VanillaPublicationDataCallback): void {
        this._onPublicationDataAdded = callback;
    }

    onPublicationDataDeleted(callback: VanillaStringValueCallback): void {
        this._onPublicationDataDeleted = callback;
    }

    onPublicationDataUpdated(callback: VanillaPublicationStateDataCallback): void {
        this._onPublicationDataUpdated = callback;
    }

    /**
     *
     * IVanillaReaderPublicationCacheData
     *
     * */

    onPublicationCacheDataUpdated(callback: VanillaPublicationCacheDataCallback): void {
        this._onPublicationCacheDataUpdated = callback;
    }

    onPublicationCacheDataAdded(callback: VanillaPublicationCacheDataCallback): void {
        this._onPublicationCacheDataAdded = callback;
    }

    onPublicationCacheDataDeleted(callback: VanillaVoidCallback): void {
        this._onPublicationOptionsDataDeleted = callback;
    }

    /**
     *
     * IVanillaReaderPublicationOptionsData
     *
     * */

    onPublicationOptionsDataUpdated(callback: VanillaPublicationCacheDataCallback): void {
        this._onPublicationOptionsDataUpdated = callback;
    }

    onPublicationOptionsDataAdded(callback: VanillaPublicationCacheDataCallback): void {
        this._onPublicationOptionsDataAdded = callback;
    }

    onPublicationOptionsDataCleared(callback: VanillaVoidCallback): void {
        this._onPublicationOptionsDataDeleted = callback;
    }

}
