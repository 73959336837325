/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {
    IReaderView,
    IReaderViewAnnotation,
    IReaderViewAnnotationEngineEvent,
    IReaderViewAnnotationLayer,
    IReaderViewAnnotationLayerOptions,
    IReaderViewAnnotationMouseEngineEvent,
    IReaderViewAnnotationOptions,
    IReaderViewAnnotationPointerEngineEvent,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';

import {
    IVanillaReaderBookmarkData,
    VanillaAnnotationMouseEventCallback,
    VanillaAnnotationPointerEventCallback,
    VanillaBookmarkDataCallback,
    VanillaVoidCallback,
} from './VanillaReaderModel';
import { APIRoutes } from '../Reader/APIRoutes';

/**
 * # VanillaReaderAnnotationLayerBookmarks
 *
 * ## PRIMARY COLIBRIO FRAMEWORK TYPES USED
 *
 * - IReaderViewAnnotationLayer
 * - IReaderViewAnnotation
 * - IReaderViewAnnotationEngineEvent
 *
 * ## RESPONSIBILITIES
 *
 * This class wraps the bookmark's ReaderViewAnnotationLayer. It also acts as runtime storage for bookmarks.
 *
 */

export class VanillaReaderAnnotationLayerBookmarks {
    private readonly _colibrioAnnotationLayer: IReaderViewAnnotationLayer;
    private _locatorToAnnotationMap: Map<string, IReaderViewAnnotation<IVanillaReaderBookmarkData>> = new Map();
    private _bookmarksInVisibleRange: Set<IVanillaReaderBookmarkData> = new Set();
    private _bookmarksCollectionUpdatedCallback: VanillaVoidCallback | undefined = undefined;
    private _bookmarkAddedCallback: VanillaBookmarkDataCallback | undefined = undefined;
    private _bookmarkDeletedCallback: VanillaBookmarkDataCallback | undefined = undefined;
    private _bookmarkClickCallback: VanillaAnnotationMouseEventCallback | undefined = undefined;
    private _bookmarkPointerEnterCallback: VanillaAnnotationPointerEventCallback | undefined = undefined;
    private _bookmarkPointerLeaveCallback: VanillaAnnotationPointerEventCallback | undefined = undefined;
    private _bookmarkEnterViewCallback: VanillaBookmarkDataCallback | undefined = undefined;
    private _bookmarkExitViewCallback: VanillaBookmarkDataCallback | undefined = undefined;
    private _bookmarkContextMenuCallback: VanillaAnnotationMouseEventCallback | undefined = undefined;

    constructor(
        private name: string,
        private _colibrioReaderView: IReaderView,
    ) {
        this._colibrioAnnotationLayer = this._colibrioReaderView.createAnnotationLayer(this.name);
        this._create();
    }

    onBookmarkAdded(callback: VanillaBookmarkDataCallback) {
        this._bookmarkAddedCallback = callback;
    }

    onBookmarkDeleted(callback: VanillaBookmarkDataCallback) {
        this._bookmarkDeletedCallback = callback;
    }

    onBookmarksCollectionUpdated(callback: VanillaVoidCallback) {
        this._bookmarksCollectionUpdatedCallback = callback;
    }

    onBookmarkClick(callback: VanillaAnnotationMouseEventCallback) {
        this._bookmarkClickCallback = callback;
    }

    onBookmarkPointerEnter(callback: VanillaAnnotationPointerEventCallback) {
        this._bookmarkPointerEnterCallback = callback;
    }

    onBookmarkPointerLeave(callback: VanillaAnnotationPointerEventCallback) {
        this._bookmarkPointerLeaveCallback = callback;
    }

    onBookmarkEnterView(callback: VanillaBookmarkDataCallback) {
        this._bookmarkEnterViewCallback = callback;
    }

    onBookmarkExitView(callback: VanillaBookmarkDataCallback) {
        this._bookmarkExitViewCallback = callback;
    }

    onBookmarkContextMenu(callback: VanillaAnnotationMouseEventCallback) {
        this._bookmarkContextMenuCallback = callback;
    }

    loadBookmarks(items: IVanillaReaderBookmarkData[]) {

        // fetch(APIRoutes.GETBOOKMARK + `?userId=${userId}&book_id=${bookId}`)
        //     .then(response => {
        //         if (!response.ok) {
        //             throw new Error('Request failed');
        //         }
        //         return response.json();
        //     })
        //     .then(data => {
        //         console.log(data);
        //         const apiHighlights = data.data.message; // Extract the highlights from the API response
        //         // alert("====Items api===="+JSON.stringify(apiHighlights));
        //         items = apiHighlights;
        //         items.forEach((item) => {
        //             this.addBookmark(item);
        //             // alert(item.color);
        //         });
        //     })
        //     .catch(error => {
        //         // Handle any errors
        //         // console.log(error);
        //     });
        // if (this._colibrioAnnotationLayer) {
        //     items.forEach((item) => {
        //         this.addBookmark(item).catch(console.warn);
        //     });
        // }
    }

    getBookmarks(): IVanillaReaderBookmarkData[] {
        let bookmarks: IVanillaReaderBookmarkData[] = [];
        this._colibrioAnnotationLayer.getAnnotations().forEach((item) => {
            bookmarks.push(item.getCustomData());
        });
        return bookmarks;
    }

    getReaderViewAnnotations(): IReaderViewAnnotation[] {
        return this._colibrioAnnotationLayer.getAnnotations();
    }

    async addBookmark(bookmarkData: IVanillaReaderBookmarkData): Promise<IVanillaReaderBookmarkData | undefined> {

        let locator = bookmarkData.locator;
        let readerViewAnnotation: IReaderViewAnnotation;
        let readerPublication = this._colibrioReaderView.getReaderPublications()[0];
        let contentLocation = await readerPublication?.fetchContentLocation(locator);

        if (contentLocation) {
            readerViewAnnotation = this._colibrioAnnotationLayer.createAnnotation<IVanillaReaderBookmarkData>(locator);
            readerViewAnnotation.setCustomData(bookmarkData);
            this._locatorToAnnotationMap.set(locator.toString(), readerViewAnnotation);

            if (this._bookmarkAddedCallback) {
                this._bookmarkAddedCallback(bookmarkData);
            }

            if (this._bookmarksCollectionUpdatedCallback) {
                this._bookmarksCollectionUpdatedCallback();
            }

            return bookmarkData;
        }

        return undefined;
    }

    getBookmark(locator: string): IVanillaReaderBookmarkData | undefined {
        if (this._locatorToAnnotationMap.has(locator)) {
            return this._locatorToAnnotationMap.get(locator)!.getCustomData() || undefined;
        } else {
            return;
        }
    }

    deleteBookmark(bookmark: IVanillaReaderBookmarkData | string) {

        let locator: string = bookmark.hasOwnProperty('locator') ?
            (bookmark as IVanillaReaderBookmarkData).locator :
            bookmark as string;

        if (locator) {
            let annotation = this._locatorToAnnotationMap.get(locator);
            if (annotation) {
                let vanillaReaderAnnotationData = annotation.getCustomData();
                let layer = annotation.getAnnotationLayer();
                layer.destroyAnnotation(annotation);
                this._locatorToAnnotationMap.delete(locator);

                if (this._bookmarkDeletedCallback) {
                    if (vanillaReaderAnnotationData) {
                        this._bookmarkDeletedCallback(vanillaReaderAnnotationData);
                    } else {
                        console.warn(`VanillaReaderAnnotationLayerHighlights.deleteHighlight(): Unable to get annotation data for annotation with locator "${locator}". "bookmarkDeletedCallback" Callback not fired.`);
                    }
                }

                if (this._bookmarksCollectionUpdatedCallback) {
                    this._bookmarksCollectionUpdatedCallback();
                }
            }
        }
    }

    clear() {
        this._colibrioAnnotationLayer.destroyAllAnnotations();
    }

    destroy() {
        this._colibrioReaderView.destroyAnnotationLayer(this._colibrioAnnotationLayer);
    }

    locationHasBookmark(locator: string): boolean {
        return this._locatorToAnnotationMap.has(locator);
    }

    getBookmarksInVisibleRange(): IVanillaReaderBookmarkData[] {
        return Array.from(this._bookmarksInVisibleRange);
    }

    /*
    *
    * PRIVATE METHODS
    *
    * */

    private _create() {

        let layerOptions: IReaderViewAnnotationLayerOptions = {
            layerClassName: 'vanilla-reader__bookmarks-layer',
            annotationInputEngineEventsEnabled: true,
        };
        this._colibrioAnnotationLayer.setLayerOptions(layerOptions);

        let annotationOptions: IReaderViewAnnotationOptions = {
            positionStyle: {
                'width': '100%',
                'height': '100%',
            },
            containerClassName: 'vanilla-reader__bookmark-container',
            containerStyle: {
                'left': '0',
                'width': '32px',
                'height': '32px',
            },
        };

        this._colibrioAnnotationLayer.setDefaultAnnotationOptions(annotationOptions);

        this._colibrioAnnotationLayer.addEngineEventListener('annotationContextMenu', this._event_contextMenu);
        this._colibrioAnnotationLayer.addEngineEventListener('annotationClick', this._event_click);
        this._colibrioAnnotationLayer.addEngineEventListener('annotationIntersectingVisibleRange', this._event_enterView);
        this._colibrioAnnotationLayer.addEngineEventListener('annotationOutsideVisibleRange', this._event_exitView);
        this._colibrioAnnotationLayer.addEngineEventListener('annotationPointerEnter', this._event_pointerEnter);
        this._colibrioAnnotationLayer.addEngineEventListener('annotationPointerLeave', this._event_pointerLeave);

    }

    /*
    *
    * EVENT HANDLERS
    *
    * */

    private _event_click = (event: IReaderViewAnnotationMouseEngineEvent) => {
        if (this._bookmarkClickCallback) {
            this._bookmarkClickCallback(event);
        }

    };

    private _event_enterView = (event: IReaderViewAnnotationEngineEvent) => {
        let annotationData = event.annotation.getCustomData();
        this._bookmarksInVisibleRange.add(annotationData);

        if (this._bookmarkEnterViewCallback) {
            this._bookmarkEnterViewCallback(annotationData);
        }
    };

    private _event_exitView = (event: IReaderViewAnnotationEngineEvent) => {
        let annotationData = event.annotation.getCustomData();
        this._bookmarksInVisibleRange.delete(annotationData);

        if (this._bookmarkExitViewCallback) {
            this._bookmarkExitViewCallback(annotationData);
        }
    };

    private _event_pointerEnter = (event: IReaderViewAnnotationPointerEngineEvent) => {
        if (this._bookmarkPointerEnterCallback) {
            this._bookmarkPointerEnterCallback(event);
        }
    };
    private _event_pointerLeave = (event: IReaderViewAnnotationPointerEngineEvent) => {
        if (this._bookmarkPointerLeaveCallback) {
            this._bookmarkPointerLeaveCallback(event);
        }
    };

    private _event_contextMenu = (event: IReaderViewAnnotationMouseEngineEvent) => {
        if (this._bookmarkContextMenuCallback) {
            this._bookmarkContextMenuCallback(event);
        }
    };
}
