/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.4.0
 */
 'use strict'; (function (s, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-base','./colibrio-core-io-base','./colibrio-core-io-mediatypedetector','./colibrio-core-io-resourceprovider-zip','./colibrio-core-publication-base','./colibrio-core-drm','./colibrio-core-encryption','./colibrio-core-url','./colibrio-core-xml'], function (a0,a1,a2,a3,a4,a5,a6,a7,a8) { return f(s,a0,a1,a2,a3,a4,a5,a6,a7,a8); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(s,require('./colibrio-core-base'),require('./colibrio-core-io-base'),require('./colibrio-core-io-mediatypedetector'),require('./colibrio-core-io-resourceprovider-zip'),require('./colibrio-core-publication-base'),require('./colibrio-core-drm'),require('./colibrio-core-encryption'),require('./colibrio-core-url'),require('./colibrio-core-xml')); } else { throw new Error('Unsupported runtime environment!'); } }( typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function (__self,__dep__colibrio_core_base,__dep__colibrio_core_io_base,__dep__colibrio_core_io_mediatypedetector,__dep__colibrio_core_io_resourceprovider_zip,__dep__colibrio_core_publication_base,__dep__colibrio_core_drm,__dep__colibrio_core_encryption,__dep__colibrio_core_url,__dep__colibrio_core_xml) {  var api = {}; (function () { var f,h=h||{};h.scope={};h.O=function(a){var b=0;return function(){return b<a.length?{done:!1,value:a[b++]}:{done:!0}}};h.$=function(a){return{next:h.O(a)}};h.J=!1;h.ka=!1;h.la=!1;h.oa=!1;h.M=!1;h.ma=!1;h.na=!1;h.defineProperty=h.J||"function"==typeof Object.defineProperties?Object.defineProperty:function(a,b,c){if(a==Array.prototype||a==Object.prototype)return a;a[b]=c.value;return a};
h.ca=function(a){a=["object"==typeof globalThis&&globalThis,a,"object"==typeof window&&window,"object"==typeof self&&self,"object"==typeof global&&global];for(var b=0;b<a.length;++b){var c=a[b];if(c&&c.Math==Math)return c}throw Error("Cannot find global object");};h.global=h.ca(this);h.F="function"===typeof Symbol&&"symbol"===typeof Symbol("x");h.N=!h.M||h.F;h.H={};h.I={};h.V="$jscp$";h.T=function(a,b,c){b&&(h.M?h.ga(a,b,c):h.ha(a,b))};
h.ha=function(a,b){var c=h.global;a=a.split(".");for(var d=0;d<a.length-1;d++){var e=a[d];if(!(e in c))return;c=c[e]}a=a[a.length-1];d=c[a];b=b(d);b!=d&&null!=b&&h.defineProperty(c,a,{configurable:!0,writable:!0,value:b})};
h.ga=function(a,b,c){var d=a.split(".");a=1===d.length;var e=d[0],g;!a&&e in h.H?g=h.H:g=h.global;for(e=0;e<d.length-1;e++){var k=d[e];if(!(k in g))return;g=g[k]}d=d[d.length-1];c=h.F&&"es6"===c?g[d]:null;b=b(c);null!=b&&(a?h.defineProperty(h.H,d,{configurable:!0,writable:!0,value:b}):b!==c&&(void 0===h.I[d]&&(a=1E9*Math.random()>>>0,h.I[d]=h.F?h.global.Symbol(d):h.V+a+"$"+d),h.defineProperty(g,h.I[d],{configurable:!0,writable:!0,value:b})))};h.ua=function(){};
h.T("Symbol",function(a){function b(g){if(this instanceof b)throw new TypeError("Symbol is not a constructor");return new c(d+(g||"")+"_"+e++,g)}function c(g,k){this.g=g;h.defineProperty(this,"description",{configurable:!0,writable:!0,value:k})}if(a)return a;c.prototype.toString=function(){return this.g};var d="jscomp_symbol_"+(1E9*Math.random()>>>0)+"_",e=0;return b},"es6");
h.T("Symbol.iterator",function(a){if(a)return a;a=Symbol("Symbol.iterator");for(var b="Array Int8Array Uint8Array Uint8ClampedArray Int16Array Uint16Array Int32Array Uint32Array Float32Array Float64Array".split(" "),c=0;c<b.length;c++){var d=h.global[b[c]];"function"===typeof d&&"function"!=typeof d.prototype[a]&&h.defineProperty(d.prototype,a,{configurable:!0,writable:!0,value:function(){return h.fa(h.O(this))}})}return a},"es6");
h.fa=function(a){a={next:a};a[Symbol.iterator]=function(){return this};return a};h.ra=function(a){return a.raw=a};h.sa=function(a,b){a.raw=b;return a};h.D=function(a){var b="undefined"!=typeof Symbol&&Symbol.iterator&&a[Symbol.iterator];return b?b.call(a):h.$(a)};h.Z=function(a){for(var b,c=[];!(b=a.next()).done;)c.push(b.value);return c};h.W=function(a){return a instanceof Array?a:h.Z(h.D(a))};h.S=h.J||"function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b};
h.ba=function(){function a(){function c(){}new c;Reflect.construct(c,[],function(){});return new c instanceof c}if(h.N&&"undefined"!=typeof Reflect&&Reflect.construct){if(a())return Reflect.construct;var b=Reflect.construct;return function(c,d,e){c=b(c,d);e&&Reflect.setPrototypeOf(c,e.prototype);return c}}return function(c,d,e){void 0===e&&(e=c);e=h.S(e.prototype||Object.prototype);return Function.prototype.apply.call(c,e,d)||e}};h.construct={valueOf:h.ba}.valueOf();
h.ia=function(){var a={a:!0},b={};try{return b.__proto__=a,b.a}catch(c){}return!1};h.setPrototypeOf=h.N&&"function"==typeof Object.setPrototypeOf?Object.setPrototypeOf:h.ia()?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null;
h.da=function(a,b){a.prototype=h.S(b.prototype);a.prototype.constructor=a;if(h.setPrototypeOf){var c=h.setPrototypeOf;c(a,b)}else for(c in b)if("prototype"!=c)if(Object.defineProperties){var d=Object.getOwnPropertyDescriptor(b,c);d&&Object.defineProperty(a,c,d)}else a[c]=b[c];a.va=b.prototype};h.o={};h.o.aa=function(a){if(!(a instanceof Object))throw new TypeError("Iterator result "+a+" is not an object");};h.o.C=function(){this.m=!1;this.i=null;this.h=void 0;this.g=1;this.A=this.l=0;this.j=null};
function aa(a){if(a.m)throw new TypeError("Generator is already running");a.m=!0}h.o.C.prototype.u=function(a){this.h=a};function ba(a,b){a.j={R:b,ea:!0};a.g=a.l||a.A}h.o.C.prototype.return=function(a){this.j={return:a};this.g=this.A};function l(a,b,c){a.g=c;return{value:b}}function p(a,b){a.g=b;a.l=0}function r(a){a.l=0;var b=a.j.R;a.j=null;return b}h.o.C.g=function(a){this.g=[];for(var b in a)this.g.push(b);this.g.reverse()};h.o.K=function(a){this.g=new h.o.C;this.h=a};
function ca(a,b){aa(a.g);var c=a.g.i;if(c)return da(a,"return"in c?c["return"]:function(d){return{value:d,done:!0}},b,a.g.return);a.g.return(b);return y(a)}function da(a,b,c,d){try{var e=b.call(a.g.i,c);h.o.aa(e);if(!e.done)return a.g.m=!1,e;var g=e.value}catch(k){return a.g.i=null,ba(a.g,k),y(a)}a.g.i=null;d.call(a.g,g);return y(a)}
function y(a){for(;a.g.g;)try{var b=a.h(a.g);if(b)return a.g.m=!1,{value:b.value,done:!1}}catch(c){a.g.h=void 0,ba(a.g,c)}a.g.m=!1;if(a.g.j){b=a.g.j;a.g.j=null;if(b.ea)throw b.R;return{value:b.return,done:!0}}return{value:void 0,done:!0}}
h.o.L=function(a){this.next=function(b){aa(a.g);a.g.i?b=da(a,a.g.i.next,b,a.g.u):(a.g.u(b),b=y(a));return b};this.throw=function(b){aa(a.g);a.g.i?b=da(a,a.g.i["throw"],b,a.g.u):(ba(a.g,b),b=y(a));return b};this.return=function(b){return ca(a,b)};this[Symbol.iterator]=function(){return this}};h.o.qa=function(a,b){b=new h.o.L(new h.o.K(b));h.setPrototypeOf&&a.prototype&&h.setPrototypeOf(b,a.prototype);return b};
h.P=function(a){function b(d){return a.next(d)}function c(d){return a.throw(d)}return new Promise(function(d,e){function g(k){k.done?d(k.value):Promise.resolve(k.value).then(b,c).then(g,e)}g(a.next())})};h.pa=function(a){return h.P(a())};h.B=function(a){return h.P(new h.o.L(new h.o.K(a)))};h.ta=function(){for(var a=Number(this),b=[],c=a;c<arguments.length;c++)b[c-a]=arguments[c];return b};var C=__dep__colibrio_core_base;var ea=__dep__colibrio_core_drm;var fa=__dep__colibrio_core_encryption;var D=__dep__colibrio_core_io_base;var ha=__dep__colibrio_core_io_mediatypedetector;var F=__dep__colibrio_core_io_resourceprovider_zip;var G=__dep__colibrio_core_publication_base;var H=__dep__colibrio_core_url;var I=__dep__colibrio_core_xml;function J(a){return"string"===typeof a&&/\S/.test(a)}function ia(a,b,c){Array.isArray(a)?b=[]:b||(b={});if("object"===typeof a&&null!==a)for(var d=Object.keys(a),e=0;e<d.length;e++){var g=d[e],k=a[g];null!==k&&"object"===typeof k?b[g]=ia(k,b[g],c):"undefined"===typeof k?c&&delete b[g]:b[g]=k}return b}function K(a,b,c){return a&&ja(c,a)?c[a]:b}var ja=Function.prototype.call.bind(Object.prototype.hasOwnProperty);var la=new Uint16Array(1);la[0]=4660;new Uint8Array(la.buffer);function ma(a,b,c,d,e){this.i=a;this.l=b;this.h=c;this.g=d;this.j=e;this.m=d?d.i:null}f=ma.prototype;f.getContentUrl=function(){return this.h.getContentUrl()};f.getIndexInSpine=function(){return this.j};f.getLayout=function(){var a;this.g&&(a=na(this.g,{"rendition:layout-pre-paginated":G.ContentDocumentLayout.PRE_PAGINATED,"rendition:layout-reflowable":G.ContentDocumentLayout.REFLOWABLE}));return a?a:this.i.getDefaultLayout()};
f.getMediaOverlayUrl=function(){var a=null,b=this.h.m;b&&(b=L(M(this.l),b))&&b.g===D.MediaType.APPLICATION_SMIL&&(a=b.getContentUrl());return a};f.getMediaType=function(){return this.h.g};f.getOpfManifestItemElement=function(){return this.h.i};f.getOpfSpineItemElement=function(){return this.m};
f.getPageSpreadSlot=function(){var a;this.g&&(a=na(this.g,{"rendition:page-spread-left":G.PageSpreadSlot.LEFT,"page-spread-left":G.PageSpreadSlot.LEFT,"rendition:page-spread-right":G.PageSpreadSlot.RIGHT,"page-spread-right":G.PageSpreadSlot.RIGHT,"rendition:page-spread-center":G.PageSpreadSlot.CENTER}));return a?a:G.PageSpreadSlot.AUTO};f.getPublication=function(){return this.i};
f.getSyntheticSpreadBehavior=function(){var a;this.g&&(a=na(this.g,{"rendition:spread-landscape":G.SyntheticSpreadBehavior.LANDSCAPE,"rendition:spread-both":G.SyntheticSpreadBehavior.BOTH,"rendition:spread-portrait":G.SyntheticSpreadBehavior.BOTH,"rendition:spread-auto":G.SyntheticSpreadBehavior.AUTO,"rendition:spread-none":G.SyntheticSpreadBehavior.NONE}));return a?a:this.i.getPreferredSyntheticSpreadBehavior()};f.hasRemoteResources=function(){return-1!==this.h.h.indexOf("remote-resources")};
f.isInLinearContent=function(){return this.g?this.g.j:!1};f.isScripted=function(){return-1!==this.h.h.indexOf("scripted")};f.toJSON=function(){return{hasRemoteResources:this.hasRemoteResources(),indexInSpine:this.j,inLinearContent:this.isInLinearContent(),layout:this.getLayout(),mediaType:this.getMediaType(),pageSpreadSlot:this.getPageSpreadSlot(),syntheticSpreadBehavior:this.getSyntheticSpreadBehavior(),scripted:this.isScripted()}};
function na(a,b){return(a=a.g.find(function(c){return ja(b,c)}))?b[a]:void 0};function N(a,b,c){this.h=a;this.g=b;this.i=c}N.prototype.getDocument=function(){return this.h};N.prototype.getType=function(){return this.g};N.prototype.getUrl=function(){return this.i};function O(a,b){return C.ColibrioError.call(this,"core.publication.epub",a,b)||this}h.da(O,C.ColibrioError);function oa(a,b){this.g=a;this.h=null;a=this.g.getMetadata();!a.mediaType&&b&&(this.h=Object.assign({},a),this.h.mediaType=b)}f=oa.prototype;f.abort=function(){this.g.abort()};f.asText=function(){return this.g.asText()};f.asUint8Array=function(){return this.g.asUint8Array()};f.getMetadata=function(){return this.h||this.g.getMetadata()};f.getReadableStream=function(){return this.g.getReadableStream()};function pa(a,b){this.g=a;this.j=b;this.i=null;a=this.g.getBaseUrl();if(!a)throw new O("BASE_URL_MISSING","BaseURL required for EpubResourceProvider");this.h=a}f=pa.prototype;f.acceptsUrl=function(a){return this.g.acceptsUrl(a)};f.fetch=function(a,b){a=h.D(qa(this,a,b));var c=a.next().value,d=a.next().value;return this.g.fetch(c,b).then(function(e){return new oa(e,d)})};
f.fetchMetadata=function(a,b){a=h.D(qa(this,a,b));var c=a.next().value,d=a.next().value;return this.g.fetchMetadata(c,b).then(function(e){!e.mediaType&&d&&(e=Object.assign({},e),e.mediaType=d);return e})};f.getBaseUrl=function(){return null!==this.h?H.URLUtils.cloneUrl(this.h):null};
f.getResourceManifest=function(){var a=this;null===this.i&&(this.i=this.j.g.map(function(b){return{mediaType:a.getMediaType(b.getContentUrl(),b),url:b.getContentUrl().toString(),pathRelativeToBaseUrl:H.URLUtils.getSubpath(b.getContentUrl(),a.h)}}));return this.i};f.isUrlSupportedByBrowser=function(a,b){return this.g.isUrlSupportedByBrowser(a,b)};
f.getMediaType=function(a,b){if(b){if(b=b.g,b.startsWith("audio")||b.startsWith("video"))b=ha.MediaTypeDetector.getMediaTypeByExtension(a.pathname)||b}else b=ha.MediaTypeDetector.getMediaTypeByExtension(a.pathname)||void 0;if(b)switch(b.toLowerCase()){case "audio/m4a":b=D.MediaType.AUDIO_MP4}return b};
function qa(a,b,c){"string"===typeof b&&(b=new URL(b,a.h),H.URLUtils.normalizePathnamePercentEncoding(b));(c=ra(a.j,b,null==c?void 0:c.accept))&&(b=c.getContentUrl());a=a.getMediaType(b,c);return[b,a]};function sa(a){this.g=a}sa.prototype.getAppleBooksOptions=function(){return this.g};function ta(a){a=a.trim();var b=0,c=0,d=0,e=0,g=/^[0-9]+(\.[0-9]+)?(h|min|s|ms)?$/;if(/^([0-9]+:)?[0-9][0-9]:[0-9][0-9](\.[0-9]+)?$/.test(a))a=a.split(":"),d=parseFloat(a.pop()),0<a.length&&(c=parseFloat(a.pop()),0<a.length&&(b=parseFloat(a.pop())));else if(g.test(a))-1!=a.indexOf("h")?b=parseFloat(a):-1!=a.indexOf("min")?c=parseFloat(a):-1!=a.indexOf("ms")?e=parseFloat(a):d=parseFloat(a);else return null;return 1E3*(3600*b+60*c+d)+e};function ua(a,b){this.h=a;this.g=b}
function va(a,b){var c=[];for(b=b.firstElementChild;b;){switch(b.localName){case "par":var d=a;for(var e=b,g=null,k=null,m=e.firstElementChild;m;){switch(m.localName){case "text":g=m;var n=g.getAttribute("src");g=n?{attributes:C.NodeDataUtils.createXmlElementAttributeData(g),childNodes:[],nodeName:g.localName,namespaceURI:g.namespaceURI,nodeType:C.NodeType.XML_ELEMENT,prefix:g.prefix,src:n}:null;break;case "audio":k=d;n=m;var B=n.getAttribute("src"),w=n.getAttribute("clipBegin"),q=null;w&&(q=ta(w),
null===q&&C.Logger.logWarning(new O("INVALID_MEDIA_OVERLAY",k.g.pathname+": invalid SMIL clock value: "+w)));w=n.getAttribute("clipEnd");var t=null;w&&(t=ta(w),null===t&&C.Logger.logWarning(new O("INVALID_MEDIA_OVERLAY",k.g.pathname+": invalid SMIL clock value: "+w)));k=B?{attributes:C.NodeDataUtils.createXmlElementAttributeData(n),childNodes:[],clipBeginMs:q||0,clipEndMs:t,namespaceURI:n.namespaceURI,nodeType:C.NodeType.XML_ELEMENT,prefix:n.prefix,src:B,nodeName:n.localName}:null}m=m.nextElementSibling}g?
d={audio:k,attributes:C.NodeDataUtils.createXmlElementAttributeData(e),childNodes:k?[g,k]:[g],prefix:e.prefix,namespaceURI:e.namespaceURI,nodeType:C.NodeType.XML_ELEMENT,nodeName:e.localName,text:g}:(C.Logger.logError(new O("INVALID_MEDIA_OVERLAY",d.g.pathname+": par element missing valid text child")),d=null);d&&c.push(d);break;case "seq":d=a,e=b,e.hasAttributeNS(C.XmlNamespace.EPUB,"textref")||C.Logger.logWarning(new O("INVALID_MEDIA_OVERLAY",d.g.pathname+" seq element missing textref attribute")),
m=va(d,e),1>m.length?(C.Logger.logWarning(new O("INVALID_MEDIA_OVERLAY",d.g.pathname+": seq element doesn't have any valid seq or par children")),d=null):d=wa(e,m),d&&c.push(d)}b=b.nextElementSibling}return c}function wa(a,b){return{attributes:C.NodeDataUtils.createXmlElementAttributeData(a),children:b,childNodes:b,nodeName:a.localName,namespaceURI:a.namespaceURI,nodeType:C.NodeType.XML_ELEMENT,prefix:a.prefix,textRef:a.getAttributeNS(C.XmlNamespace.EPUB,"textref")}};function P(a){this.g=a}P.prototype.getActiveClassName=function(){return Q(this,"active-class")};P.prototype.getDurationMs=function(){var a=Q(this,"duration");return a?ta(a):null};P.prototype.getNarrator=function(){return Q(this,"narrator")};P.prototype.getPlaybackActiveClassName=function(){return Q(this,"playback-active-class")};function Q(a,b){return(a=xa(a.g,C.XmlNamespace.MEDIA_OVERLAYS,b))?a.content.value:null};function ya(a){this.g=a.getMetadata();this.h=new P(this.g)}f=ya.prototype;f.getAll=function(){return this.g.getAll().slice()};f.getIdentifiers=function(){return za(this,this.g.i)};f.getLanguages=function(){return za(this,this.g.j)};f.getMediaOverlayMetadata=function(){return this.h};f.getTitles=function(){return za(this,this.g.l)};f.getUniqueIdentifier=function(){return this.g.getUniqueIdentifier()};
f.toJSON=function(){var a=this.getAll(),b=this.g.i.slice(),c=this.g.j.slice(),d=this.g.l.slice(),e=G.PublicationMetadataType.EPUB_PUBLICATION_METADATA;var g=this.g.getUniqueIdentifier();g=ia(g,{},!1);return{all:a,identifierIndexes:b,languageIndexes:c,titleIndexes:d,type:e,uniqueIdentifier:g}};function za(a,b){var c=a.g.getAll();return b.map(function(d){return c[d]})};function Aa(a,b,c,d){this.U=b;this.g=c;this.h=this.i=null;this.l=!1;this.m=new Map;this.u=new Map;this.A=new ya(c);this.j=new pa(a,M(c));d&&(this.i=d.getOptionsForPlatform("ALL"));this.ja=new sa(d)}f=Aa.prototype;f.destroy=function(){this.l||(this.l=!0,this.h=[],this.m.clear())};
f.fetchMediaOverlay=function(a){var b=this,c=a.href,d=this.u.get(c);d||(d=this.j.fetch(a,{accept:[D.MediaType.APPLICATION_SMIL]}).then(function(e){return e.asText().then(function(g){var k=new I.DomParser,m=e.getMetadata(),n;g=k.parseXmlString(g,null!=(n=m.pathRelativeToBaseUrl)?n:m.url);g=new ua(g,a);n=g.h.documentElement;if(!n)throw new O("INVALID_MEDIA_OVERLAY",g.g.pathname+": document element not found");for(k=n.firstElementChild;k&&"body"!==k.localName;)k=k.nextElementSibling;if(!k)throw new O("INVALID_MEDIA_OVERLAY",
g.g.pathname+": body element not found");var B=va(g,k);if(1>B.length)throw new O("INVALID_MEDIA_OVERLAY",g.g.pathname+": smil body missing valid seq and par children");g=wa(k,B);return{mediaOverlay:{attributes:C.NodeDataUtils.createXmlElementAttributeData(n),body:g,childNodes:[g],namespaceURI:n.namespaceURI,nodeType:C.NodeType.XML_ELEMENT,prefix:n.prefix,nodeName:n.localName},metadata:m}})}).catch(function(e){b.u.delete(c);return Promise.reject(e)}),this.u.set(c,d));return d};
f.fetchNavigationDocument=function(){var a=this,b,c,d,e,g,k,m,n,B,w,q,t,u,z,v,x,E,ka,Ca,Da,Ea;return h.B(function(A){switch(A.g){case 1:b=a.g;c=M(b);d=c.j;if(!d){A.g=2;break}g=d.g;if(g!==D.MediaType.TEXT_HTML&&g!==D.MediaType.APPLICATION_XHTML&&g!==D.MediaType.APPLICATION_HTML){A.g=2;break}A.l=4;k=d.getContentUrl();return l(A,a.j.fetch(k),6);case 6:return m=A.h,l(A,m.asText(),7);case 7:n=A.h;B=new I.XhtmlParser(n,k.pathname,g===D.MediaType.TEXT_HTML||g===D.MediaType.APPLICATION_HTML);w=B.getDocument();
if(!w)throw new O("INVALID_NAV_DOCUMENT",'path="'+k.pathname+'"');e=new N(w,"nav",k);p(A,2);break;case 4:q=r(A),C.Logger.logWarning(q);case 2:if(e){A.g=8;break}t=b.getSpine().h.getAttribute("toc");if(!t){A.g=8;break}u=L(c,t);if(!u){A.g=8;break}A.l=11;z=u.getContentUrl();return l(A,a.j.fetch(z),13);case 13:return v=A.h,l(A,v.asText(),14);case 14:x=A.h;E=new I.DomParser;ka=v.getMetadata();Da=E.parseXmlString(x,null!=(Ca=ka.pathRelativeToBaseUrl)?Ca:ka.url);e=new N(Da,"ncx",z);p(A,8);break;case 11:Ea=
r(A),C.Logger.logWarning(Ea);case 8:if(!e)throw new O("INVALID_OR_MISSING_NAV_OR_NCX_DOCUMENT");return A.return(e)}})};
f.getContentDocumentByUrl=function(a){"string"===typeof a&&(a=new URL(a,this.getEpubResourceProvider().getBaseUrl()||void 0),H.URLUtils.normalizePathnamePercentEncoding(a));var b=ra(M(this.g),a,[D.MediaType.APPLICATION_XHTML,D.MediaType.IMAGE_SVG,D.MediaType.TEXT_HTML,D.MediaType.APPLICATION_HTML]);a=null;if(b&&(a=this.getSpine().find(function(d){return d.getOpfManifestItemElement()===b.i})||null,!a)){var c=H.URLUtils.getOriginAndPath(b.getContentUrl());a=this.m.get(c);a||(a=new ma(this,this.g,b,
null,-1),this.m.set(c,a))}return a};f.getCoverImageResourceUrl=function(){var a=M(this.g).h;if(!a){var b=xa(this.g.getMetadata(),null,"cover");b&&(a=L(M(this.g),b.content.value))}return a?a.getContentUrl():null};f.getDefaultLayout=function(){var a=R(this,"layout"),b=this.i&&this.i.isFixedLayout()?G.ContentDocumentLayout.PRE_PAGINATED:G.ContentDocumentLayout.REFLOWABLE;return K(a,b,{"pre-paginated":G.ContentDocumentLayout.PRE_PAGINATED})};f.getEpubResourceProvider=function(){return this.j};
f.getFormatExtensions=function(){return this.ja};f.getHashSignature=function(){return this.U};f.getMediaType=function(){return D.MediaType.APPLICATION_EPUB_ZIP};f.getMetadata=function(){return this.A};f.getOpfDocument=function(){return this.g.getOpfDocument()};f.getOpfDocumentUrl=function(){return this.g.i};f.getPageProgressionDirection=function(){var a=this.g.getSpine().getPageProgressionDirection();return K(a,G.PageProgressionDirection.AUTO,{ltr:G.PageProgressionDirection.LTR,rtl:G.PageProgressionDirection.RTL})};
f.getPreferredFlowMode=function(){var a=R(this,"flow");return K(a,G.ContentDocumentFlowMode.AUTO,{paginated:G.ContentDocumentFlowMode.PAGINATED,"scrolled-continuous":G.ContentDocumentFlowMode.SCROLLED_CONTINUOUS,"scrolled-doc":G.ContentDocumentFlowMode.SCROLLED_DOC})};f.getPreferredOrientation=function(){var a=R(this,"orientation"),b=this.i&&this.i.getOrientationLock()||G.PublicationOrientation.AUTO;return K(a,b,{landscape:G.PublicationOrientation.LANDSCAPE,portrait:G.PublicationOrientation.PORTRAIT})};
f.getPreferredSyntheticSpreadBehavior=function(){var a=R(this,"spread");return K(a,G.SyntheticSpreadBehavior.AUTO,{none:G.SyntheticSpreadBehavior.NONE,landscape:G.SyntheticSpreadBehavior.LANDSCAPE,both:G.SyntheticSpreadBehavior.BOTH,portrait:G.SyntheticSpreadBehavior.BOTH})};
f.getSpine=function(){if(!this.h){this.h=[];for(var a=M(this.g),b=this.g.getSpine().g,c=0;c<b.length;c++){var d=b[c];try{var e=L(a,d.h,[D.MediaType.APPLICATION_XHTML,D.MediaType.IMAGE_SVG,D.MediaType.TEXT_HTML,D.MediaType.APPLICATION_HTML]);e&&this.h.push(new ma(this,this.g,e,d,this.h.length))}catch(g){C.Logger.logError(g)}}}return this.h.slice()};f.getType=function(){return G.PublicationType.EPUB};f.isDestroyed=function(){return this.l};
f.toJSON=function(){var a;return{coverImageUrl:(null==(a=this.getCoverImageResourceUrl())?void 0:a.toString())||null,defaultLayout:this.getDefaultLayout(),hashSignature:this.U,metadata:this.A.toJSON(),pageProgressionDirection:this.getPageProgressionDirection(),preferredFlowMode:this.getPreferredFlowMode(),preferredOrientation:this.getPreferredOrientation(),preferredSyntheticSpreadBehavior:this.getPreferredSyntheticSpreadBehavior(),spine:this.getSpine().map(function(b){return b.toJSON()}),type:G.PublicationType.EPUB}};
function R(a,b){return(a=xa(a.g.getMetadata(),C.XmlNamespace.EPUB_RENDITION,b))?a.content.value:null};function S(a){this.h=a;this.g=null}
S.prototype.decrypt=function(a){if(!this.g){var b=this.h.getDefaultPublication();if(!b)throw new O("IDPF_OBFUSCATION_DECRYPTION_FAILED","No default publication found");b=b.getMetadata().getUniqueIdentifier();if(!b)throw new O("IDPF_OBFUSCATION_DECRYPTION_FAILED","Unique identifier missing");var c=new fa.Sha1;c.append(b.content.value.replace(/[\x20\x09\x0d\x0a]/g,""));this.g=c.digestToUint8Array()}b=this.g;c=new Uint8Array(a.length);for(var d=0,e=0;1040>e&&e<a.length;)20===d&&(d=0),c[e]=a[e]^b[d],
e++,d++;e<a.length&&c.set(a.subarray(1040),1040);return Promise.resolve(c)};S.prototype.getName=function(){return"http://www.idpf.org/2008/embedding"};function Ba(a,b,c,d){this.j=a;this.l=c;this.i=d;this.h=(a=d.getAttribute("properties"))?a.split(/\s+/):[];this.m=d.getAttribute("media-overlay");this.g=b.toLowerCase()}Ba.prototype.getContentUrl=function(){return this.l};function Fa(a,b){this.h=null;this.i=new Map;this.g=[];this.j=null;this.l=new Map;this.m=new Map;a=a.children;for(var c=0,d=a.length;c<d;c++){var e=a[c];if(e&&e.namespaceURI===C.XmlNamespace.OPF&&"item"===e.localName){var g=e.getAttribute("id"),k=e.getAttribute("href"),m=e.getAttribute("media-type");if(J(g))if(J(k))if(J(m)){var n=void 0;try{n=new URL(k,b),H.URLUtils.normalizePathnamePercentEncoding(n)}catch(B){C.Logger.logError(new O("INVALID_MANIFEST_ITEM_URL",k+": "+String(B)));continue}e=new Ba(g,
m,n,e);-1!==e.h.indexOf("nav")&&(this.j=e);-1!==e.h.indexOf("cover-image")&&(this.h=e);this.g.push(e);this.i.set(g,e);g=H.URLUtils.getOriginAndPath(n);this.l.set(g,e);this.m.set(g.toLowerCase(),e)}else C.Logger.logError(Ga("media-type",c));else C.Logger.logError(Ga("href",c));else C.Logger.logError(Ga("id",c))}}}
function Ha(a,b){b=L(a,b);var c=null;if(b&&b.i.getAttribute("fallback"))for(var d=[];b;){d.push(b.j);var e=b.i.getAttribute("fallback");e&&-1===d.indexOf(e)?b=L(a,e):b=null;b&&(c||(c=[]),c.push(b))}return c}function L(a,b,c){return(b=a.i.get(b)||null)&&c?Ia(a,b,c):b}function ra(a,b,c){b=H.URLUtils.getOriginAndPath(b);return(b=a.l.get(b)||a.m.get(b.toLowerCase())||null)&&c?Ia(a,b,c):b}
function Ia(a,b,c){var d=[];c.forEach(function(e){e.endsWith("/*")?d.push.apply(d,h.W(ha.MediaTypeDetector.getMediaTypesFromCategory(e))):d.push(e)});c=null;-1!==d.indexOf(b.g)?c=b:(a=Ha(a,b.j))&&(c=a.find(function(e){return-1!==d.indexOf(e.g)})||c);return c||b}function Ga(a,b){return new O("OPF_MANIFEST_ITEM_ATTRIBUTE_NOT_FOUND",'attrName="'+a+'" in /manifest/item['+b+"]")};function T(){this.i=0;this.h={G:null,s:null,v:null};this.g={G:null,s:null,v:this.h};this.h.s=this.g}T.prototype.forEach=function(a){for(var b=this.h.s;b!==this.g;){var c=b.s;a(b);b=null===b.s?c:b.s}};T.prototype.push=function(a){a={G:a,s:this.g,v:this.g.v};a.v.s=a;this.g.v=a;this.i++;return a};T.prototype.size=function(){return this.i};function Ja(a,b){if(b.v&&b.s){var c=b.v,d=b.s;c.s=d;d.v=c;b.s=null;b.v=null;a.i--}};function Ka(a,b){this.j=!0;this.i=this.g=a;this.h=b||null}function La(a,b){if(null!==a.g){var c=Ma(a,b);for(a.j=!1;null!==a.g&&a.h&&!a.h(a.g);)c+=Ma(a,b)}}Ka.prototype.next=function(a){if(this.j&&(!this.h||this.h(this.g)))return this.j=!1,this.g;La(this,a);return this.g};
function Ma(a,b){var c=a.g,d=0;if(c.firstChild&&("function"===typeof b?b(c):b))a.g=c.firstChild,d=1;else if(c!==a.i&&c.nextSibling)a.g=c.nextSibling;else{for(;c!==a.i&&!c.nextSibling;)c=c.parentNode,d--;c===a.i?a.g=null:a.g=c.nextSibling}return d};function Na(a,b,c,d){var e=this;this.u=c;this.m=d;this.g=[];this.h=new Map;this.i=[];this.j=[];this.l=[];a=new Ka(a,function(w){return 1===w.nodeType});La(a,!0);for(var g=new T;a.g;){c=a.g;if(c.namespaceURI===C.XmlNamespace.OPF){if("meta"===c.localName){var k=c.getAttribute("name"),m=c.getAttribute("content"),n=c.getAttribute("refines");d=c.getAttribute("property");k&&m?(c=U(this,{name:k,namespace:null},null,m,c),this.g.push(c)):n?g.push(c):d?(k=c.getAttribute("scheme"),d=V(this,d,c,null,null),k=
k?V(this,k,c,null,null):null,(m=(c.textContent||"").trim())?(c=U(this,d,k,m,c),this.g.push(c)):C.Logger.logWarning(new O("INVALID_OPF_METADATA","Expected meta element to contain text content."))):C.Logger.logWarning(new O("INVALID_OPF_METADATA","Invalid metadata element"))}}else if(c.namespaceURI===C.XmlNamespace.DC)if(d=(c.textContent||"").trim()){d=U(this,{name:c.localName,namespace:C.XmlNamespace.DC},null,d,c);k=this.g.length;this.g.push(d);switch(c.localName){case "language":this.j.push(k);break;
case "title":this.l.push(k);break;case "identifier":this.i.push(k)}if(2===b)for(c=c.attributes,k=0;k<c.length;k++)m=c[k],m.namespaceURI===C.XmlNamespace.OPF&&J(m.value)&&(m=U(this,{name:m.localName,namespace:C.XmlNamespace.OPF},null,m.value.trim(),null),d.refinedBy.push(m))}else C.Logger.logWarning(new O("INVALID_OPF_METADATA","Found empty dc:"+c.localName));La(a,!0)}for(var B=!0;0<g.size()&&B;)B=!1,g.forEach(function(w){var q=w.G,t=q.getAttribute("refines"),u=q.getAttribute("property"),z=(q.textContent||
"").trim();if(u&&t&&z&&"#"===t[0]){if(t=e.h.get(t.slice(1))){u=V(e,u,q,C.XmlNamespace.EPUB_OPF_META_VOCABULARY,Oa);var v=q.getAttribute("scheme");v=v?V(e,v,q,null,null):null;q=U(e,u,v,z,q);t.refinedBy.push(q);B=!0;Ja(g,w)}}else C.Logger.logWarning(new O("INVALID_OPF_METADATA","Invalid EPUB 3 subexpression meta element")),Ja(g,w)})}Na.prototype.getAll=function(){return this.g};function xa(a,b,c){return a.g.find(function(d){return d.property.namespace===b&&d.property.name===c})||null}
Na.prototype.getUniqueIdentifier=function(){return this.h.get(this.u)||null};function U(a,b,c,d,e){var g=null,k=null;e&&(k=e.getAttribute("dir"),"ltr"===k?g=C.TextDirection.LTR:"rtl"===k&&(g=C.TextDirection.RTL),k=e.getAttributeNS(C.XmlNamespace.XML,"lang"));b={content:{dir:g,lang:k,value:d},contentScheme:c,property:b,refinedBy:[]};e&&e.id&&a.h.set(e.id,b);return b}
function V(a,b,c,d,e){var g=b.split(":",2);2!==g.length?(c=null,d&&e?e.has(b)?c=d:C.Logger.logWarning(new O("INVALID_OPF_METADATA",'Found unknown property "'+b+'". It is not defined by '+d)):C.Logger.logWarning(new O("INVALID_OPF_METADATA",'Found unknown property "'+b+". Expected a namespace prefix.")),b={name:b,namespace:c}):(d=g[0],(a=c.lookupNamespaceURI(d)||a.m.get(d)||Pa.get(d)||null)?b={name:g[1],namespace:a}:(C.Logger.logWarning(new O("INVALID_OPF_METADATA",'Found unknown property "'+b+'" in element <'+
c.localName+">")),b={name:b,namespace:null}));return b}var Pa=new Map([["a11y",C.XmlNamespace.EPUB_A11Y],["dcterms",C.XmlNamespace.DCTERMS],["marc",C.XmlNamespace.MARC],["media",C.XmlNamespace.MEDIA_OVERLAYS],["opf",C.XmlNamespace.OPF],["onix",C.XmlNamespace.ONIX],["rendition",C.XmlNamespace.EPUB_RENDITION],["schema",C.XmlNamespace.SCHEMA_ORG],["xsd",C.XmlNamespace.XSD]]),Oa=new Set("alternate-script authority belongs-to-collection collection-type display-seq file-as group-position identifier-type meta-auth role source-of term title-type".split(" "));function Qa(a,b){this.h=a;this.i=b;this.j="no"!==b.getAttribute("linear");this.g=(a=b.getAttribute("properties"))?a.split(/\s+/):[]};function Ra(a){this.h=a;this.g=[];a=a.children;for(var b=0,c=a.length;b<c;b++){var d=a[b];if(d&&d.namespaceURI===C.XmlNamespace.OPF&&"itemref"===d.localName){var e=d.getAttribute("idref");if(J(e))this.g.push(new Qa(e,d));else{d=C.Logger;e=d.logError;var g=new O("OPF_SPINE_ITEMREF_ATTRIBUTE_NOT_FOUND",'attrName="idref" in /spine/itemref['+b+"]");e.call(d,g)}}}}Ra.prototype.getPageProgressionDirection=function(){return this.h.getAttribute("page-progression-direction")||"default"};function W(a,b){this.g=a;this.i=b;this.spine=this.metadata=this.h=null}function M(a){if(!a.h){var b=a.g.querySelector("package > manifest");if(!b)throw new O("OPF_ELEMENT_NOT_FOUND","<manifest>");a.h=new Fa(b,a.i)}return a.h}
W.prototype.getMetadata=function(){if(!this.metadata){var a=this.g.querySelector("package > metadata"),b=this.g.documentElement.getAttribute("unique-identifier"),c=this.g.documentElement.getAttribute("version")||"2";c=parseInt(c)||2;if(!a)throw new O("OPF_ELEMENT_NOT_FOUND","<metadata>");if(!b)throw new O("OPF_ATTRIBUTE_UNIQUE_IDENTIFIER_NOT_FOUND");var d=this.g.documentElement.getAttribute("prefix"),e=new Map;if(d)for(var g=/(\S+):\s+(\S+)/g,k;null!==(k=g.exec(d));)if(3===k.length){var m=k[1];k=
k[2];m&&k&&(H.URLUtils.isValidUrl(k)?e.set(m,k):C.Logger.logWarning(new O("INVALID_OPF_METADATA",'Package attribute "prefix" contains invalid namespace URI: '+k)))}this.metadata=new Na(a,c,b,e)}return this.metadata};W.prototype.getOpfDocument=function(){return this.g};W.prototype.getSpine=function(){if(!this.spine){var a=this.g.querySelector("package > spine");if(!a)throw new O("OPF_ELEMENT_NOT_FOUND","<spine>");this.spine=new Ra(a)}return this.spine};function Sa(a){this.m=this.h=this.l=this.j=this.i=null;switch(a.getAttribute("name")){case "iphone":this.g="IPHONE";break;case "ipad":this.g="IPAD";break;default:this.g="ALL"}for(a=a.firstElementChild;a;){if("option"===a.localName)switch(a.getAttribute("name")){case "interactive":this.j="true"===X(a);break;case "fixed-layout":this.i="true"===X(a);break;case "open-to-spread":this.l="true"===X(a);break;case "orientation-lock":var b=X(a);"landscape-only"===b?this.h=G.PublicationOrientation.LANDSCAPE:
"portrait-only"===b&&(this.h=G.PublicationOrientation.PORTRAIT);break;case "specified-fonts":this.m="true"===X(a)}a=a.nextElementSibling}}f=Sa.prototype;f.getOrientationLock=function(){return this.h};f.getPlatformType=function(){return this.g};f.hasCustomFonts=function(){return this.m};f.isFixedLayout=function(){return this.i};f.isInteractive=function(){return this.j};f.shouldOpenToSpread=function(){return this.l};function X(a){return(a=a.firstChild)&&3===a.nodeType?a.data.trim():""};function Ta(a){this.g=[];a=a.documentElement;if("display_options"===a.localName)for(a=a.firstElementChild;a;)"platform"===a.localName&&this.g.push(new Sa(a)),a=a.nextElementSibling}Ta.prototype.getOptionsForPlatform=function(a){return this.g.find(function(b){return b.getPlatformType()===a})||null};function Y(a,b,c,d,e){this.g=a;this.m=b;this.i=c;this.A=d;this.u=e;this.l=this.j=!1;this.h=[];this.enableBuiltInEncryptionMethods()}f=Y.prototype;f.acceptsUrl=function(a){return this.g.acceptsUrl(a)};f.destroy=function(){if(!this.l){this.l=!0;for(var a=0;a<this.h.length;a++)this.h[a].destroy();this.h=[];this.u&&this.u()}};f.disableBuiltInEncryptionMethods=function(){this.j&&(this.i.removeEncryptionMethod("http://www.idpf.org/2008/embedding"),this.j=!1)};
f.enableBuiltInEncryptionMethods=function(){this.j||(this.i.addEncryptionMethod(new S(this)),this.j=!0)};f.fetch=function(a,b){var c=this;return this.g.fetch(a,b).then(function(d){return c.i.getDecryptedResourceResponse(d)})};f.fetchMetadata=function(a,b){var c=this;return this.g.fetchMetadata(a,b).then(function(d){return c.i.getDecryptedResourceMetadata(d)})};f.getBaseUrl=function(){var a=this.g.getBaseUrl();return null!==a?H.URLUtils.cloneUrl(a):null};
f.getDefaultPublication=function(){return this.h[0]||null};f.getDrmManager=function(){return this.i};f.getMimeType=function(){return this.m};f.getPublications=function(){return this.h};f.getResourceManifest=function(){return this.g.getResourceManifest()};f.isDestroyed=function(){return this.l};f.isUrlSupportedByBrowser=function(a,b){return this.g.isUrlSupportedByBrowser(a,b)};
f.load=function(){var a=this,b,c,d,e,g,k,m,n,B;return h.B(function(w){if(1==w.g)return a.m!==D.MediaType.APPLICATION_EPUB_ZIP&&C.Logger.logWarning("Invalid EPUB container mimetype: "+a.m),b=a.fetch("META-INF/container.xml").then(function(q){var t,u,z,v;return h.B(function(x){if(1==x.g)return l(x,q.asText(),2);t=x.h;u=new I.DomParser;z=q.getMetadata();return x.return(u.parseXmlString(t,null!=(v=z.pathRelativeToBaseUrl)?v:z.url))})}),c=a.fetch("META-INF/com.apple.ibooks.display-options.xml").then(function(q){var t,
u,z,v,x;return h.B(function(E){if(1==E.g)return l(E,q.asText(),2);t=E.h;u=new I.DomParser;z=q.getMetadata();x=u.parseXmlString(t,null!=(v=z.pathRelativeToBaseUrl)?v:z.url);return E.return(new Ta(x))})}).catch(function(q){C.ColibrioError.isColibrioError(q)&&q.errorType===D.IoErrorType.RESOURCE_NOT_FOUND||C.Logger.logWarning(q);return null}),d=h,e=d.D,l(w,Promise.all([b,c]),2);g=e.call(d,w.h);k=g.next().value;m=g.next().value;n=Ua(k);B=n.map(function(q){return a.fetch(q).then(function(t){return t.asText().then(function(u){var z=
t.getMetadata(),v;u=(new I.DomParser).parseXmlString(u,null!=(v=z.pathRelativeToBaseUrl)?v:z.url);z=new W(u,new URL(t.getMetadata().url));v=new fa.Sha1;u=F.ZipResourceProvider.createBaseUrl(a.A);v.append(u.toString());u=new URL(q,u);v.append(u.toString());v=v.digestToHex();return new Aa(a,v,z,m)})}).catch(function(t){C.ColibrioError.isColibrioAbortedError(t)||C.Logger.logError(t);return null})});return w.return(Promise.all(B).then(function(q){a.h=q.filter(function(t){return null!==t})}))})};
function Ua(a){a=a.querySelectorAll("rootfile");for(var b=[],c=0;c<a.length;c++){var d=a[c],e=d.getAttribute("full-path");d=d.getAttribute("media-type");e&&d===D.MediaType.APPLICATION_OPF_PACKAGE&&b.push(e)}return b}Y.createFromArrayBuffer=Va;Y.createFromBackingResourceProvider=Wa;Y.createFromBase64=Xa;Y.createFromBlob=Ya;Y.createFromRandomAccessDataSource=Za;function Va(a,b,c){return F.ZipResourceProvider.createFromArrayBuffer(a,b).then(function(d){return Z(d,c)})}
function Xa(a,b){return F.ZipResourceProvider.createFromBase64(a).then(function(c){return Z(c,b)})}function Ya(a,b){return F.ZipResourceProvider.createFromBlob(a).then(function(c){return Z(c,b)})}function Za(a,b,c){return F.ZipResourceProvider.createFromRandomAccessDataSource(a,c).then(function(d){return Z(d,b)})}
function Wa(a,b,c,d){var e,g,k,m,n,B,w,q,t,u,z,v;return h.B(function(x){switch(x.g){case 1:e=a.getBaseUrl();if(!e)throw new O("BASE_URL_MISSING");x.l=2;return l(x,a.fetch("mimetype").then(function(E){return E.asText()}),4);case 4:g=x.h;p(x,3);break;case 2:k=r(x),C.Logger.logWarning(k),g=D.MediaType.APPLICATION_EPUB_ZIP;case 3:return m=null,x.l=5,l(x,a.fetch("META-INF/encryption.xml"),7);case 7:return n=x.h,l(x,n.asText(),8);case 8:B=x.h;w=new I.DomParser;q=n.getMetadata();m=w.parseXmlString(B,null!=
(t=q.pathRelativeToBaseUrl)?t:q.url);p(x,6);break;case 5:u=r(x),C.ColibrioError.isColibrioError(u)&&u.errorType===D.IoErrorType.RESOURCE_NOT_FOUND||C.Logger.logWarning(u);case 6:return c||(c=new ea.DrmManager(null)),m&&(z=new ea.XmlEncryptionInfo(m,e),c.setXmlEncryptionInfo(z)),v=new Y(a,g,c,b,d||null),x.return(v.load().then(function(){return v}))}})}function Z(a,b){return Wa(a,a.getCentralDirectorySha1Signature(),b,function(){a.destroy()})};api={drm:{IdpfObfuscationEncryptionMethod:S},EpubOcfResourceProvider:Y,EpubAppleBooksOptionsPlatformType:{ALL:"ALL",IPHONE:"IPHONE",IPAD:"IPAD"},EpubPublicationError:O,EpubPublicationErrorType:{BASE_URL_MISSING:"BASE_URL_MISSING",IDPF_OBFUSCATION_DECRYPTION_FAILED:"IDPF_OBFUSCATION_DECRYPTION_FAILED",INVALID_OR_MISSING_NAV_OR_NCX_DOCUMENT:"INVALID_OR_MISSING_NAV_OR_NCX_DOCUMENT",INVALID_NAV_DOCUMENT:"INVALID_NAV_DOCUMENT",INVALID_MANIFEST_ITEM_URL:"INVALID_MANIFEST_ITEM_URL",INVALID_MEDIA_OVERLAY:"INVALID_MEDIA_OVERLAY",
INVALID_OPF_METADATA:"INVALID_OPF_METADATA",OPF_ELEMENT_NOT_FOUND:"OPF_ELEMENT_NOT_FOUND",OPF_MANIFEST_ITEM_ATTRIBUTE_NOT_FOUND:"OPF_MANIFEST_ITEM_ATTRIBUTE_NOT_FOUND",OPF_SPINE_ITEMREF_ATTRIBUTE_NOT_FOUND:"OPF_SPINE_ITEMREF_ATTRIBUTE_NOT_FOUND",OPF_ATTRIBUTE_UNIQUE_IDENTIFIER_NOT_FOUND:"OPF_ATTRIBUTE_UNIQUE_IDENTIFIER_NOT_FOUND"}};var $a=new ArrayBuffer(8);new DataView($a);new Uint8Array($a); }).call(__self); return api; } ) );
