/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
/**
 * # VanillaReaderEventBus
 *
 *  ## RESPONSIBILITIES
 *
 * The VanillaReaderEventBus is the applications communication channel. It lets any of our components
 * dispatch and add listeners for CustomEvents (https://developer.mozilla.org/en-US/docs/Web/Guide/Events/Creating_and_triggering_events).
 *
 * The actual element that acts as the "dispatch central" for the event bus is by default the "<body>" element in index.html.
 *
 * Any component that you write "on top" of the VanillaReader will need to include this file as it is the common "protocol"
 * and channel for communicating between the components.
 *
 * ## EVENT TYPES
 *
 * This file contains `VanillaReaderAppEvents` which holds all the event names used by the VanillaReader. It also
 * contains all the various `IVanillaReaderAppEvent` types that specify what data is sent along with each event.
 *
 * ## NOTES:
 *
 * The naming convention for events are as follows:
 *
 * - An "INTENT" is dispatched to trigger an action (typically sent from the UI)
 * - Any other event is sent **after** an action has completed and reflects the changed state.
 *
 * The exception to this convention is the "APP_UNLOADING" event which fires as part of the browser `window`'s unload process.
 *
 */
import {ILocatorData} from '@colibrio/colibrio-reader-framework/colibrio-core-locator';
import {

    IEngineEventMediaResourceData,

    IKeyboardEngineEventData,
    IMouseEngineEventData,

    INavigationEndedEngineEventData,
    INavigationIntentEngineEvent,

    INavigationStartedEngineEventData,
    IPointerEngineEventData,
    IPublicationStyleOptions,
    IReaderViewEngineEventData,
    IReaderViewOptions,
    IRendererOptions,
    ISelectionChangedEngineEventData, ITransformData,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';
import {IVanillaReaderUiOptionsData} from '../VanillaReaderUI/VanillaReaderUiModel';
import {
    IVanillaReaderBookmarkData,
    IVanillaReaderHighlightData,
    IVanillaReaderInitialOptions,
    IVanillaReaderLandmarkData,
    IVanillaReaderNavigationTreeData,
    IVanillaReaderOptionsData,
    IVanillaReaderPublicationData,
    IVanillaReaderPublicationOptionsData,
    IVanillaReaderPublicationSearchResultItem,
    IVanillaReaderReadingPositionData, IVanillaReaderShelfItemData, IVanillaReaderSyncMediaOptions,
    IVanillaReaderUiColorPalette,
    IVanillaReaderVisibleRangeData,
    IVanillaSyncMediaPlaybackStateData,
    VanillaReaderNavigationType,
    VanillaReaderRendererNames,
} from './VanillaReaderModel';
import {IAttributeData} from "@colibrio/colibrio-reader-framework/colibrio-core-base";

export class VanillaReaderEventBus {
    static eventBusElement: HTMLElement | undefined;
    private static _listeners: Map<VanillaReaderAppEvents, Set<CustomEventListener<any>>> = new Map();

    private constructor() {
    }

    static addEventListener<T>(event: VanillaReaderAppEvents, listener: CustomEventListener<T>, once: boolean = false) {
        // @ts-ignore
        VanillaReaderEventBus._getBus().addEventListener<T>(event, listener, {
            once,
        });

        /* If the `once` parameter is set to true, the DOM API will take care of the event listener life cycle
        *  so we should not save it to our own `listeners` set.
        */
        if (!once) {
            let listeners = VanillaReaderEventBus._listeners.get(event);
            if (!listeners) {
                listeners = new Set();
                VanillaReaderEventBus._listeners.set(event, listeners);
            }
            listeners.add(listener);
        }
    }

    static destroy() {
        VanillaReaderEventBus._listeners.forEach((listeners, eventType) => {
            listeners.forEach(listener => {
                // @ts-ignore
                VanillaReaderEventBus._getBus().removeEventListener(eventType, listener);
            });
        });
        VanillaReaderEventBus._listeners.clear();
    }

    static dispatchEvent<T>(event: CustomEvent<T>) {
        VanillaReaderEventBus._getBus().dispatchEvent(event);
    }

    static removeEventListener(event: VanillaReaderAppEvents, listener: CustomEventListener<any>) {
        // @ts-ignore
        VanillaReaderEventBus._getBus().removeEventListener(event, listener);
        let listeners = VanillaReaderEventBus._listeners.get(event);
        if (listeners) {
            listeners.delete(listener);
        }
    }

    private static _getBus(): HTMLElement {
        if (!VanillaReaderEventBus.eventBusElement) {
            VanillaReaderEventBus.eventBusElement = document.documentElement as HTMLBodyElement;
        }

        return VanillaReaderEventBus.eventBusElement;
    }
}

export type CustomEventListener<T> = (evt: CustomEvent<T>) => void;

/**
 * This VanillaReaderAppEvents is just a list of the event names that we use.
 * These correspond to the IVanillaReaderAppEvent that are listed below.
 */
export enum VanillaReaderAppEvents {
    APP_ONLINE_STATE_CHANGED = 'vanillareader_apponlinestatechanged',
    APP_LOADED = 'vanillareader_apploaded',
    APP_PWA_INSTALLING = 'vanillareader_apppwainstalling',
    APP_PWA_INSTALLED = 'vanillareader_apppwainstalled',
    APP_PWA_SKIP_WAITING_INTENT = 'vanillareader_appskipwaitingintent',
    APP_PWA_REFRESHING = 'vanillareader_apppwarefreshing',
    APP_PWA_REFRESHED = 'vanillareader_apppwarefreshed',
    APP_PWA_ACTIVATED = 'vanillareader_apppwaactivated',
    APP_UNLOADING = 'vanillareader_appunloading',
    APP_WINDOW_RESIZED = 'vanillareader_appwindowresized',
    APP_UI_THEME_CHANGE_INTENT = 'vanillareader_appthemechangeintent',
    APP_UI_THEME_CHANGED = 'vanillareader_appuithemechanged',
    APP_UI_OPTIONS_CHANGED = 'vanillareader_appuioptionschanged',
    BOOKMARKS_LOADED = 'vanillareader_bookmarksloaded',
    BOOKMARK_READINGPOSITION_INTENT = 'vanillareader_bookmarkaddintent',
    BOOKMARK_TEXTLOCATION_INTENT = 'vanillareader_bookmarktextlocationintent',
    BOOKMARK_TEXTLOCATION_FAILED = 'vanillareader_bookmarktextlocationfailed',
    BOOKMARK_ADDED = 'vanillareader_bookmarkadded',
    BOOKMARK_CLICKED = 'vanillareader_bookmarkclicked',
    BOOKMARKS_COLLECTION_UPDATED = 'vanillareader_bookmarksupdated',
    BOOKMARK_DELETE_INTENT = 'vanillareader_bookmarkdeleteintent',
    BOOKMARK_DELETED = 'vanillareader_bookmarkdeleted',
    BOOKMARK_IN_VISIBLE_RANGE = 'vanillareader_bookmarkinvisibledocument',
    BOOKMARK_OUTSIDE_VISIBLE_RANGE = 'vanillareader_bookmarkoutsidevisibledocument',
    DOCUMENT_LANDMARKS_FETCH_INTENT = 'vanillareader_documentlandmarksfetchintent',
    DOCUMENT_LANDMARKS_READY = 'vanillareader_documentlandmarksfetchready',
    DOCUMENT_IS_AT_START = 'vanillareader_documentisatstart',
    DOCUMENT_IS_AT_END = 'vanillareader_documentisatend',
    FOCUS_ON_READINGPOSTION_INTENT = 'vanillareader_focusonreadingpositionintent',
    HIGHLIGHTS_LOADED = 'vanillareader_highlightsloaded',
    HIGHLIGHT_ADD_INTENT = 'vanillareader_highlightaddintent',
    HIGHLIGHT_TEXTLOCATION_INTENT = 'vanillareader_highlighttextlocationintent',
    HIGHLIGHT_TEXTLOCATION_FAILED = 'vanillareader_highlighttextlocationfailed',
    HIGHLIGHT_ADDED = 'vanillareader_addhighlight',
    HIGHLIGHT_CLICKED = 'vanillareader_clicked',
    HIGHLIGHT_DELETE_INTENT = 'vanillareader_deletehighlightintent',
    HIGHLIGHT_DELETED = 'vanillareader_deletehighlight',
    HIGHLIGHT_IN_VISIBLE_RANGE = 'vanillareader_highlightinvisibledocument',
    HIGHLIGHT_OUTSIDE_VISIBLE_RANGE = 'vanillareader_highlightoutsidevisibledocument',
    HIGHLIGHT_UPDATE_INTENT = 'vanillareader_highlightupdateintent',
    HIGHLIGHT_UPDATED = 'vanillareader_highlightupdated',
    HIGHLIGHTS_COLLECTION_UPDATED = 'vanillareader_highlightsupdated',
    KEY_DOWN = 'vanillareader_keydown',
    KEY_UP = 'vanillareader_keyup',
    MEDIAPLAYER_CREATED = 'vanillareader_mediaplayercreated',
    MEDIAPLAYER_DETACHED = 'vanillareader_mediaplayerdetach',
    MEDIAPLAYER_ATTACHED = 'vanillareader_mediaplayerattach',
    MEDIAPLAYER_TOGGLE_INTENT = 'vanillareader_mediaplayerplaytoggleintent',
    MEDIAPLAYER_PLAYBACK_STATE_CHANGED = 'vanillareader_mediaplayerplaybackstatechanged',
    MEDIAPLAYER_PLAY_INTENT = 'vanillareader_mediaplayerplaybackplayintent',
    MEDIAPLAYER_PAUSE_INTENT = 'vanillareader_mediaplayerplaybackpauseintent',
    MEDIAPLAYER_SEEK_NEXT_INTENT = 'vanillareader_mediaplayerplaybackseeknextintent',
    MEDIAPLAYER_SEEK_PREVIOUS_INTENT = 'vanillareader_mediaplayerplaybackseekpreviousintent',
    MEDIAPLAYER_SEEK_BACKWARD_INTENT = 'vanillareader_mediaplayerplaybackseekbackwardintent',
    MEDIAPLAYER_SEEK_FORWARD_INTENT = 'vanillareader_mediaplayerplaybackseekforwardintent',
    MEDIAPLAYER_SEEK_COMPLETED = 'vanillareader_mediaplayerplaybackseekcompleted',
    MEDIAPLAYER_RATE_CHANGE_INTENT = 'vanillareader_mediaplayerplaybackchangerateintent',
    MEDIAPLAYER_VOLUME_CHANGE_INTENT = 'vanillareader_mediaplayerplaybackchangevolumeintent',
    MEDIAPLAYER_VOICE_CHANGE_INTENT = 'vanillareader_mediaplayerplaybackchangevoiceintent',
    MEDIAPLAYER_VOICE_CHANGED = 'vanillareader_mediaplayerplaybackvoicechanged',
    MEDIAPLAYER_PLAYBACK_POSITION_CHANGED = 'vanillareader_mediaplayerplaybackpositionchanged',
    MEDIAPLAYER_CREATE_PROGRESS_UPDATED = 'vanillareader_mediaplayerplaybackcreateprogress',
    MEDIA_OBJECT_CLICKED = 'vanillareader_mediaobjectclicked',
    MEDIA_OBJECT_VIEW_INTENT = 'vanillareader_mediaobjectviewintent',
    MEDIA_OBJECT_VIEW_CLOSED = 'vanillareader_mediaobjectviewclosed',
    NAVIGATION_STARTED = 'vanillareader_navigationstarted',
    NAVIGATION_COMPLETED = 'vanillareader_navigationcompleted',
    NAVIGATION_INTENT = 'vanillareader_navigationuiintent',
    NAVIGATION_INTERNAL_INTENT = 'vanillareader_navigationinternalintent',
    NAVIGATION_EXTERNAL_INTENT = 'vanillareader_navigationexternalintent',
    NAVIGATION_TREE_READY = 'vanillareader_navigationtreeready',
    CLICK = 'vanillareader_click',
    POINTER_DOWN = 'vanillareader_pointerdown',
    POINTER_UP = 'vanillareader_pointerup',
    PUBLICATION_DELETE_STORED_RESOURCES_INTENT = 'vanillareader_publicationdeletecachedresourcesintent',
    PUBLICATION_IS_AT_START = 'vanillareader_publicationisatstart',
    PUBLICATION_IS_AT_END = 'vanillareader_publicationisatend',
    PUBLICATION_LANDMARKS_FETCH_INTENT = 'vanillareader_publicationlandmarksfetchintent',
    PUBLICATION_LANDMARKS_READY = 'vanillareader_publicationlandmarksready',
    PUBLICATION_CLOSE_INTENT = 'vanillareader_publicationcloseintent',
    PUBLICATION_LOAD_INTENT = 'vanillareader_publicationloadintent',
    PUBLICATION_LOAD_URL_INTENT = 'vanillareader_publicationloadurlintent',
    PUBLICATION_LOAD_FROM_STORE_INTENT = 'vanillareader_publicationloadfromstoreintent',
    PUBLICATION_LOADED = 'vanillareader_publicationloaded',
    PUBLICATION_RENDERED = 'vanillareader_publicationrendered',
    PUBLICATION_DOWNLOAD_PROGRESS_UPDATED = 'vanillareader_publicationdownloadprogressupdate',
    PUBLICATION_SHELF_UPDATED = 'vanillareader_publicationshelfupdated',
    PUBLICATION_STORED_RESOURCES_DELETED = 'vanillareader_publicationcachedresourcesdeleted',
    PUBLICATION_SEARCH_INTENT = 'vanillareader_publicationtextsearchintent',
    PUBLICATION_SEARCH_COMPLETED = 'vanillareader_publicationtextsearchcompleted',
    PUBLICATION_SEARCH_CLEAR_INTENT = 'vanillareader_publicationtextsearchclearintent',
    PUBLICATION_SEARCH_CLEARED = 'vanillareader_publicationtextsearchclear',
    PUBLICATION_SEARCH_INDEX_BUILD_PROGRESS = 'vanillareader_publicationsearchindexbuildprogress',
    PUBLICATION_SEARCH_INDEX_READY = 'vanillareader_publicationsearchindexready',
    PUBLICATION_STYLE_OPTIONS_CHANGE_INTENT = 'vanillareader_publicationstyleoptionchangesintent',
    PUBLICATION_STYLE_OPTIONS_CHANGED = 'vanillareader_publicationstyleoptionchanged',
    PUBLICATION_RESOURCES_DELETE_INTENT = 'vanillareader_publicationresourcesdeleteintent',
    PUBLICATION_RESOURCES_DELETED = 'vanillareader_publicationresourcesdeleted',
    REDUCED_MOTION_OPTION_CHANGE_INTENT = 'vanillareader_reducedmotionoptionchangeintent',
    REDUCED_MOTION_OPTION_CHANGED = 'vanillareader_reducedmotionoptionchanged',
    READING_POSITION_UPDATED = 'vanillareader_readingpositionchanged',
    RENDERER_CHANGE_INTENT = 'vanillareader_rendererchangeintent',
    RENDERER_ASPECT_RATIO_CHANGED = 'vanillareader_rendereraspectratiochanged',
    RENDERER_CHANGED = 'vanillareader_rendererchanged',
    RENDERER_CHANGE_ASPECT_RATIO_INTENT = 'vanillareader_renderersetaspectratiointent',
    SETTINGS_UPDATED = 'vanillareader_settingsupdated',
    TEXT_SELECTED = 'vanillareader_textselected',
    TIMELINE_UPDATE_INTENT = 'vanillareader_timelineupdateintent',
    TIMELINE_READY = 'vanillareader_timelineready',
    VIEW_OPTIONS_UPDATED = 'vanillareader_viewoptionsupdated',
    VIEW_REFRESH_INTENT = 'vanillareader_viewrefreshintent',
    VIEW_TRANSITION_STARTED = 'vanillareader_viewtransitionstarted',
    VIEW_TRANSITION_ENDED = 'vanillareader_viewtransitionended',
    VIEW_TRANSFORM_CHANGED = 'vanillareader_viewtransformationchanged',
    VIEW_TRANSFORM_STATE_RESET_INTENT = 'vanillareader_viewtransformationresetintent',
    VIEW_TRANSFORM_RESET = 'vanillareader_viewtransformationexited',
    VIEW_TRANSFORM_PAN_MODE_ACTIVATE_INTENT = 'vanillareader_viewtransformationpanmodeactivateintent',
    VIEW_TRANSFORM_PAN_MODE_ACTIVATED = 'vanillareader_viewtransformationpanmodeactivated',
    VIEW_TRANSFORM_PAN_MODE_DEACTIVATE_INTENT = 'vanillareader_viewtransformationpanmodedeactivateintent',
    VIEW_TRANSFORM_PAN_MODE_DEACTIVATED = 'vanillareader_viewtransformationpanmodedeactivated',
    VIEW_TRANSFORM_ZOOM_TO_RECT_INTENT = 'vanillareader_viewtransformationzoomintent',
    VIEW_TRANSFORM_ZOOM_TO_LEVEL_INTENT = 'vanillareader_viewtransformationzoomtolevelintent',
    VIEW_TRANSFORM_ZOOMED = 'vanillareader_viewtransformationzoomed',
    VISIBLE_RANGE_DATA_CHANGED = 'vanillareader_visiblerangechanged',
}

/**
 * IVanillaReaderAppEvents help you get typings for CustomEvents in your app code.
 * */
export interface IVanillaReaderAppEvent {
}

export interface IVanillaReaderSwEvent extends MessageEvent {
    eventName: VanillaReaderAppEvents;
    'message': MessageEvent;
    'messageerror': MessageEvent;
}

export interface IVanillaReaderAppEvent_APP_PWA_ACTIVATED extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_APP_PWA_INSTALLING extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_APP_PWA_INSTALLED extends IVanillaReaderAppEvent {
    installedResources: string[];
}

export interface IVanillaReaderAppEvent_APP_PWA_REFRESHED extends IVanillaReaderAppEvent {
    refreshedResources: string[];
}

export interface IVanillaReaderAppEvent_DOCUMENT_LANDMARKS_FETCH_INTENT extends IVanillaReaderAppEvent {
    documentIndex: number;
}

export interface IVanillaReaderAppEvent_DOCUMENT_LANDMARKS_READY extends IVanillaReaderAppEvent {
    documentIndex: number;
    headings: IVanillaReaderLandmarkData[] | undefined;
    footnotes: IVanillaReaderLandmarkData[] | undefined;
    figures: IVanillaReaderLandmarkData[] | undefined;

}

export interface IVanillaReaderAppEvent_DOCUMENT_IS_AT_START extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_DOCUMENT_IS_AT_END extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_APP_ONLINE_STATE_CHANGED extends IVanillaReaderAppEvent {
    isOnline: boolean;
}

export interface IVanillaReaderAppEvent_APP_LOADED extends IVanillaReaderAppEvent {
    initialOptions?: IVanillaReaderInitialOptions;
}

export interface IVanillaReaderAppEvent_APP_WINDOW_RESIZED extends IVanillaReaderAppEvent {
    srcEvent: Event;
}

export interface IVanillaReaderAppEvent_APP_UI_OPTIONS_CHANGED extends IVanillaReaderAppEvent {
    uiOptions: IVanillaReaderUiOptionsData;
}

export interface IVanillaReaderAppEvent_APP_UI_THEME_CHANGED extends IVanillaReaderAppEvent {
    colorPalette: IVanillaReaderUiColorPalette;
}

export interface IVanillaReaderAppEvent_NAVIGATION_EXTERNAL_INTENT extends IVanillaReaderAppEvent {
    targetUrl?: string;
}

export interface IVanillaReaderAppEvent_NAVIGATION_INTERNAL_INTENT extends IVanillaReaderAppEvent {
    srcEvent?: INavigationIntentEngineEvent;
}

export interface IVanillaReaderAppEvent_MEDIA_OBJECT_VIEW_INTENT extends IVanillaReaderAppEvent {
    mediaUrl: string | undefined;
    mediaElementAttributes?: IAttributeData[];
}

export interface IVanillaReaderAppEvent_PUBLICATION_CLOSE_INTENT extends IVanillaReaderAppEvent {}

export interface IVanillaReaderAppEvent_PUBLICATION_SEARCH_COMPLETED extends IVanillaReaderAppEvent {
    resultSet: IVanillaReaderPublicationSearchResultItem[];
}

export interface IVanillaReaderAppEvent_PUBLICATION_IS_AT_START extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_PUBLICATION_IS_AT_END extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_PUBLICATION_LANDMARKS_FETCH_INTENT extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_PUBLICATION_LANDMARKS_READY extends IVanillaReaderAppEvent {
    headings: IVanillaReaderLandmarkData[] | undefined;
    footnotes: IVanillaReaderLandmarkData[] | undefined;
    figures: IVanillaReaderLandmarkData[] | undefined;
}

export interface IVanillaReaderAppEvent_PUBLICATION_LOAD_INTENT extends IVanillaReaderAppEvent {
    publicationFile: File,
    initialLocator?: string,
    publicationData?: IVanillaReaderPublicationData,
    storeOnDevice?: boolean,
}

export interface IVanillaReaderAppEvent_PUBLICATION_LOAD_URL_INTENT extends IVanillaReaderAppEvent {
    publicationUrl: string,
    initialLocator?: string,
    publicationData?: IVanillaReaderPublicationData,
    storeOnDevice: boolean,
}

export interface IVanillaReaderAppEvent_PUBLICATION_LOAD_FROM_STORE_INTENT extends IVanillaReaderAppEvent {
    fileName: string,
    fileSourceUri: string,
    initialLocator?: string,
    publicationData?: IVanillaReaderPublicationData
}

export interface IVanillaReaderAppEvent_PUBLICATION_LOADED extends IVanillaReaderAppEvent {
    initialPosition: string | undefined,
    publicationData: IVanillaReaderPublicationData,
    publicationOptionsData: IVanillaReaderPublicationOptionsData | undefined
}

export interface IVanillaReaderAppEvent_PUBLICATION_RENDERED extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_PUBLICATION_DOWNLOAD_PROGRESS_UPDATED extends IVanillaReaderAppEvent {
    publicationId?: string,
    progress: number
}

export interface IVanillaReaderAppEvent_PUBLICATION_DELETE_STORED_RESOURCES_INTENT extends IVanillaReaderAppEvent {
    publicationId: string;
}

export interface IVanillaReaderAppEvent_PUBLICATION_STORED_RESOURCES_DELETED extends IVanillaReaderAppEvent {
    publicationId: string;
}

export interface IVanillaReaderAppEvent_PUBLICATION_SHELF_UPDATED extends IVanillaReaderAppEvent {
    shelfItems: IVanillaReaderPublicationData[];
}

export interface IVanillaReaderAppEvent_NAVIGATION_INTENT extends IVanillaReaderAppEvent {
    navigationType: VanillaReaderNavigationType,
    externalNavigation?: boolean,
    locator?: string,
    position?: number,
    url?: string,
    focusNearContentLocation?: boolean
}

export interface IVanillaReaderAppEvent_NAVIGATION_STARTED extends IVanillaReaderAppEvent {
    srcEventData: INavigationStartedEngineEventData;
}

export interface IVanillaReaderAppEvent_NAVIGATION_COMPLETED extends IVanillaReaderAppEvent {
    srcEventData: INavigationEndedEngineEventData;
    readingPosition?: IVanillaReaderReadingPositionData;
}

export interface IVanillaReaderAppEvent_MEDIA_OBJECT_CLICKED extends IVanillaReaderAppEvent {
    mediaResourceData: IEngineEventMediaResourceData | null;
}

export interface IVanillaReaderAppEvent_CLICK extends IVanillaReaderAppEvent {
    srcEventData: IMouseEngineEventData | MouseEvent;
}

export interface IVanillaReaderAppEvent_POINTER_DOWN extends IVanillaReaderAppEvent {
    srcEventData: IPointerEngineEventData | PointerEvent;
}

export interface IVanillaReaderAppEvent_POINTER_UP extends IVanillaReaderAppEvent {
    srcEventData: IPointerEngineEventData | PointerEvent;
}

export interface IVanillaReaderAppEvent_RENDERER_CHANGED extends IVanillaReaderAppEvent {
    newActiveRendererName?: VanillaReaderRendererNames;
    newActiveRendererOptions?: IRendererOptions;
}

export interface IVanillaReaderAppEvent_RENDERER_CHANGE_INTENT extends IVanillaReaderAppEvent {
    rendererName?: VanillaReaderRendererNames;
    ignoreAspectRatio?: boolean;
}

export interface IVanillaReaderAppEvent_REDUCED_MOTION_OPTION_CHANGE_INTENT extends IVanillaReaderAppEvent {
    useReducedMotion: boolean;
}

export interface IVanillaReaderAppEvent_REDUCED_MOTION_OPTION_CHANGED extends IVanillaReaderAppEvent {
    useReducedMotion: boolean;
}

export interface IVanillaReaderAppEvent_RENDERER_CHANGE_ASPECT_RATIO_INTENT extends IVanillaReaderAppEvent {
    ignoreAspectRatio?: boolean;
}

export interface IVanillaReaderAppEvent_RENDERER_ASPECT_RATIO_CHANGED extends IVanillaReaderAppEvent {
    ignoreAspectRatio?: boolean;
}

export interface IVanillaReaderAppEvent_TEXT_SELECTED extends IVanillaReaderAppEvent {
    srcEventData: ISelectionChangedEngineEventData;
}

export interface IVanillaReaderAppEvent_BOOKMARKS_LOADED extends IVanillaReaderAppEvent {
    bookmarks: IVanillaReaderBookmarkData[];
}

export interface IVanillaReaderAppEvent_BOOKMARK_IN_VISIBLE_RANGE extends IVanillaReaderAppEvent {
    bookmark: IVanillaReaderBookmarkData;
}

export interface IVanillaReaderAppEvent_BOOKMARK_OUTSIDE_VISIBLE_RANGE extends IVanillaReaderAppEvent {
    bookmark: IVanillaReaderBookmarkData;
}

export interface IVanillaReaderAppEvent_BOOKMARK_ADDED extends IVanillaReaderAppEvent {
    bookmark: IVanillaReaderBookmarkData;
}

export interface IVanillaReaderAppEvent_BOOKMARK_READINGPOSITION_INTENT extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_BOOKMARK_TEXTLOCATION_INTENT extends IVanillaReaderAppEvent {
    text: string;
    documentIndex: number;
}

export interface IVanillaReaderAppEvent_BOOKMARK_TEXTLOCATION_FAILED extends IVanillaReaderAppEvent {
    text: string;
    documentIndex: number;
}

export interface IVanillaReaderAppEvent_BOOKMARK_CLICKED extends IVanillaReaderAppEvent {
    bookmark: IVanillaReaderBookmarkData;
}

export interface IVanillaReaderAppEvent_BOOKMARK_DELETE_INTENT extends IVanillaReaderAppEvent {
    bookmark: IVanillaReaderBookmarkData;
}

export interface IVanillaReaderAppEvent_BOOKMARK_DELETED extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_BOOKMARKS_UPDATED extends IVanillaReaderAppEvent {
    bookmarks: IVanillaReaderBookmarkData[];
}

export interface IVanillaReaderAppEvent_HIGHLIGHTS_LOADED extends IVanillaReaderAppEvent {
    highlights: IVanillaReaderHighlightData[];
}

export interface IVanillaReaderAppEvent_HIGHLIGHT_ADD_INTENT extends IVanillaReaderAppEvent {
    highlight: IVanillaReaderHighlightData;
}

export interface IVanillaReaderAppEvent_HIGHLIGHT_TEXTLOCATION_INTENT extends IVanillaReaderAppEvent {
    text: string,
    documentIndex: number,
    highlightData: IVanillaReaderHighlightData;
}

export interface IVanillaReaderAppEvent_HIGHLIGHT_TEXTLOCATION_FAILED extends IVanillaReaderAppEvent {
    text: string,
    documentIndex: number,
    highlightData: IVanillaReaderHighlightData;
}

export interface IVanillaReaderAppEvent_HIGHLIGHT_ADDED extends IVanillaReaderAppEvent {
    highlight: IVanillaReaderHighlightData;
}

export interface IVanillaReaderAppEvent_HIGHLIGHT_UPDATED extends IVanillaReaderAppEvent {
    highlight: IVanillaReaderHighlightData;
}

export interface IVanillaReaderAppEvent_HIGHLIGHT_UPDATE_INTENT extends IVanillaReaderAppEvent {
    highlight: IVanillaReaderHighlightData;
}

export interface IVanillaReaderAppEvent_HIGHLIGHT_DELETE_INTENT extends IVanillaReaderAppEvent {
    selector: string;
}

export interface IVanillaReaderAppEvent_HIGHLIGHT_DELETED extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_HIGHLIGHT_CLICKED extends IVanillaReaderAppEvent {
    highlight: IVanillaReaderHighlightData;
}

export interface IVanillaReaderAppEvent_HIGHLIGHTS_UPDATED extends IVanillaReaderAppEvent {
    highlights: IVanillaReaderHighlightData[];
}

export interface IVanillaReaderAppEvent_HIGHLIGHT_IN_VISIBLE_RANGE extends IVanillaReaderAppEvent {
    highlight: IVanillaReaderHighlightData;
}

export interface IVanillaReaderAppEvent_HIGHLIGHT_OUTSIDE_VISIBLE_RANGE extends IVanillaReaderAppEvent {
    highlight: IVanillaReaderHighlightData;
}

export interface IVanillaReaderAppEvent_TIMELINE_READY extends IVanillaReaderAppEvent {
    timelineLength: number;
}

export interface IVanillaReaderAppEvent_READING_POSITION_UPDATED extends IVanillaReaderAppEvent {
    readingPositionData: IVanillaReaderReadingPositionData;
}

export interface IVanillaReaderAppEvent_MEDIA_OBJECT_VIEW_CLOSED extends IVanillaReaderAppEvent {
    mediaUrl: string | undefined;
}

export interface IVanillaReaderAppEvent_PUBLICATION_STATE_RESTORED extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_PUBLICATION_SEARCH_INTENT extends IVanillaReaderAppEvent {
    searchTerm: string;
    resultLimit?: number,
    documentIndexes?: number[]
}

export interface IVanillaReaderAppEvent_PUBLICATION_SEARCH_CLEAR_INTENT extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_PUBLICATION_SEARCH_INDEX_BUILD_PROGRESS extends IVanillaReaderAppEvent {
    progress: number;
}

export interface IVanillaReaderAppEvent_PUBLICATION_SEARCH_INDEX_READY extends IVanillaReaderAppEvent {}

export interface IVanillaReaderAppEvent_PUBLICATION_STYLE_OPTIONS_CHANGE_INTENT extends IVanillaReaderAppEvent {
    styleOptions: IPublicationStyleOptions;
}

export interface IVanillaReaderAppEvent_PUBLICATION_STYLE_OPTIONS_CHANGED extends IVanillaReaderAppEvent {
    styleOptions: IPublicationStyleOptions;
}

export interface IVanillaReaderAppEvent_PUBLICATION_RESOURCES_DELETE_INTENT extends IVanillaReaderAppEvent {
    shelfItem: IVanillaReaderShelfItemData;
}

export interface IVanillaReaderAppEvent_PUBLICATION_RESOURCES_DELETED extends IVanillaReaderAppEvent {

}

export interface IVanillaReaderAppEvent_MEDIAPLAYER_TOGGLE_INTENT extends IVanillaReaderAppEvent {
    activate: boolean;
    options: IVanillaReaderSyncMediaOptions
}

export interface IVanillaReaderAppEvent_FOCUS_ON_READINGPOSITION_INTENT extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_MEDIAPLAYER_ATTACHED extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_MEDIAPLAYER_DETACHED extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_MEDIAPLAYER_CREATED extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_PREVIOUS_INTENT extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_NEXT_INTENT extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_BACKWARD_INTENT extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_FORWARD_INTENT extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_MEDIAPLAYER_PLAY_INTENT extends IVanillaReaderAppEvent {
    locator?: ILocatorData;
}

export interface IVanillaReaderAppEvent_MEDIAPLAYER_PAUSE_INTENT extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_MEDIAPLAYER_RATE_CHANGE_INTENT extends IVanillaReaderAppEvent {
    rate: number;
}

export interface IVanillaReaderAppEvent_MEDIAPLAYER_VOICE_CHANGE_INTENT extends IVanillaReaderAppEvent {
    voiceName: string | undefined;
}

export interface IVanillaReaderAppEvent_MEDIAPLAYER_VOICE_CHANGED extends IVanillaReaderAppEvent {
    voiceName: string | undefined;
}

export interface IVanillaReaderAppEvent_MEDIAPLAYER_VOLUME_CHANGE_INTENT extends IVanillaReaderAppEvent {
    volume: number;
}

export interface IVanillaReaderAppEvent_MEDIAPLAYER_PLAYBACK_STATE_CHANGED extends IVanillaReaderAppEvent {
    state: IVanillaSyncMediaPlaybackStateData;
}

export interface IVanillaReaderAppEvent_MEDIAPLAYER_SEEK_COMPLETED extends IVanillaReaderAppEvent {
    state: IVanillaSyncMediaPlaybackStateData;
}

export interface IVanillaReaderAppEvent_MEDIAPLAYER_PLAYBACK_POSITION_CHANGED extends IVanillaReaderAppEvent {
    state: IVanillaSyncMediaPlaybackStateData;
}

export interface IVanillaReaderAppEvent_MEDIAPLAYER_CREATE_PROGRESS_UPDATED extends IVanillaReaderAppEvent {
    progress: number;
}

export interface IVanillaReaderAppEvent_KEYBOARD_EVENT extends IVanillaReaderAppEvent {
    srcEventData: IKeyboardEngineEventData;
}

export interface IVanillaReaderAppEvent_TIMELINE_UPDATE_INTENT extends IVanillaReaderAppEvent {
    timelinePosition: number;
}

export interface IVanillaReaderAppEvent_VISIBLE_RANGE_DATA_CHANGED extends IVanillaReaderAppEvent {
    visibleRangeData: IVanillaReaderVisibleRangeData;
}

export interface IVanillaReaderAppEvent_NAVIGATION_TREE_READY extends IVanillaReaderAppEvent {
    navigationTreeData: IVanillaReaderNavigationTreeData | undefined;
}

export interface IVanillaReaderAppEvent_APP_UNLOADING extends IVanillaReaderAppEvent {
    appState: IVanillaReaderOptionsData | undefined;
}

export interface IVanillaReaderAppEvent_SETTINGS_UPDATED extends IVanillaReaderAppEvent {
    readerViewOptions: IReaderViewOptions | undefined;
}

export interface IVanillaReaderAppEvent_VIEW_REFRESH_INTENT extends IVanillaReaderAppEvent {
    force?: boolean;
}

export interface IVanillaReaderAppEvent_VIEW_TRANSITION_STARTED extends IVanillaReaderAppEvent {
    srcEventData: IReaderViewEngineEventData;
}

export interface IVanillaReaderAppEvent_VIEW_TRANSITION_ENDED extends IVanillaReaderAppEvent {
    srcEventData: IReaderViewEngineEventData;
}

export interface IVanillaReaderAppEvent_VIEW_TRANSFORM_PAN_MODE_ACTIVATE_INTENT extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_VIEW_TRANSFORM_PAN_MODE_ACTIVATED extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_VIEW_TRANSFORM_PAN_MODE_DEACTIVATE_INTENT extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_VIEW_TRANSFORM_PAN_MODE_DEACTIVATED extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_VIEW_TRANSFORM_RESET_INTENT extends IVanillaReaderAppEvent {
    animate?: boolean;
}

export interface IVanillaReaderAppEvent_VIEW_TRANSFORM_RESET extends IVanillaReaderAppEvent {
}

export interface IVanillaReaderAppEvent_VIEW_TRANSFORM_ZOOM_TO_RECT_INTENT extends IVanillaReaderAppEvent {
    zoomRect: DOMRect;
    animate?: boolean;
}

export interface IVanillaReaderAppEvent_VIEW_TRANSFORM_ZOOM_TO_LEVEL_INTENT extends IVanillaReaderAppEvent {
    zoomLevel: number;
    animate?: boolean;
}

export interface IVanillaReaderAppEvent_VIEW_TRANSFORM_ZOOMED extends IVanillaReaderAppEvent {
    transformData: ITransformData | undefined;
}

export interface IVanillaReaderAppEvent_VIEW_TRANSFORM_CHANGED extends IVanillaReaderAppEvent {
    transformData: ITransformData | undefined;
}

export interface IVanillaUiDialogResult<T> {
    title: string;
    data: T | undefined;
}