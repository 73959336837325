/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.4.0
 */
 'use strict'; (function (s, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-base','./colibrio-core-io-base','./colibrio-core-selector-base'], function (a0,a1,a2) { return f(s,a0,a1,a2); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(s,require('./colibrio-core-base'),require('./colibrio-core-io-base'),require('./colibrio-core-selector-base')); } else { throw new Error('Unsupported runtime environment!'); } }( typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function (__self,__dep__colibrio_core_base,__dep__colibrio_core_io_base,__dep__colibrio_core_selector_base) {  var api = {}; (function () { var h;function aa(a){var b=0;return function(){return b<a.length?{done:!1,value:a[b++]}:{done:!0}}}function q(a){if(!(a instanceof Array)){var b="undefined"!=typeof Symbol&&Symbol.iterator&&a[Symbol.iterator];a=b?b.call(a):{next:aa(a)};for(var c=[];!(b=a.next()).done;)c.push(b.value);a=c}return a}var ca="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},da;
if("function"==typeof Object.setPrototypeOf)da=Object.setPrototypeOf;else{var ea;a:{var fa={a:!0},ha={};try{ha.__proto__=fa;ea=ha.a;break a}catch(a){}ea=!1}da=ea?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var ia=da;
function ja(a,b){a.prototype=ca(b.prototype);a.prototype.constructor=a;if(ia)ia(a,b);else for(var c in b)if("prototype"!=c)if(Object.defineProperties){var d=Object.getOwnPropertyDescriptor(b,c);d&&Object.defineProperty(a,c,d)}else a[c]=b[c];a.F=b.prototype};var ka=__dep__colibrio_core_base;var u=__dep__colibrio_core_selector_base;function v(a){return 0<a.length?a[a.length-1]:void 0};function x(a){return a&&9===a.nodeType}function z(a){return a&&1===a.nodeType}function la(a,b,c){Array.isArray(a)?b=[]:b||(b={});if("object"===typeof a&&null!==a)for(var d=Object.keys(a),e=0;e<d.length;e++){var f=d[e],g=a[f];null!==g&&"object"===typeof g?b[f]=la(g,b[f],c):"undefined"===typeof g?c&&delete b[f]:b[f]=g}return b}var ma=Array.isArray;function A(a){return"number"===typeof a&&!isNaN(a)};function B(a){for(var b=0;a=a.previousSibling;)b++;return b};var na=new Uint16Array(1);na[0]=4660;new Uint8Array(na.buffer);function C(a,b){return ka.ColibrioError.call(this,"colibrio.core.selector.epubcfi",a,b)||this}ja(C,ka.ColibrioError);function D(a){switch(a.type){case "ASSERTION_GENERIC":return oa(a);case "ASSERTION_TEXT_LOCATION":return pa(a);case "ASSERTION_XML_ID":return qa(a)}}function E(a){return{indirection:a.indirection,srcOffset:a.srcOffset,steps:a.steps.map(function(b){return F(b)}),type:a.type}}
function G(a){switch(a.type){case "OFFSET_CHARACTER":return{assertion:a.assertion?D(a.assertion):null,characterOffset:a.characterOffset,srcOffset:a.srcOffset,type:a.type};case "OFFSET_SPATIAL":return{assertion:a.assertion?D(a.assertion):null,srcOffset:a.srcOffset,type:a.type,x:a.x,y:a.y};case "OFFSET_TEMPORAL":return{assertion:a.assertion?D(a.assertion):null,seconds:a.seconds,srcOffset:a.srcOffset,type:a.type,x:a.x,y:a.y}}}
function ra(a){return{complete:a.complete,localPaths:a.localPaths.map(function(b){return E(b)}),offset:a.offset?G(a.offset):null,srcOffset:a.srcOffset,type:a.type}}function H(a){return{errors:[],parentPath:a.parentPath?ra(a.parentPath):null,rangeEndPath:a.rangeEndPath?ra(a.rangeEndPath):null,rangeStartPath:a.rangeStartPath?ra(a.rangeStartPath):null,src:a.src,srcModified:a.srcModified,srcOffset:a.srcOffset,type:a.type}}
function F(a){switch(a.type){case "STEP_ELEMENT":return{assertion:a.assertion?qa(a.assertion):null,srcOffset:a.srcOffset,stepValue:a.stepValue,type:a.type};case "STEP_TEXT":return{assertion:a.assertion?D(a.assertion):null,srcOffset:a.srcOffset,stepValue:a.stepValue,type:a.type}}}function J(){return{complete:!1,localPaths:[],offset:null,type:"PATH"}}function sa(){return{indirection:!1,steps:[],type:"LOCAL_PATH"}}
function ta(){return{errors:[],parentPath:null,rangeEndPath:null,rangeStartPath:null,src:"",srcModified:!1,type:"ROOT"}}function va(a){return{name:a.name,srcOffset:a.srcOffset,type:a.type,values:a.values.slice()}}function oa(a){return{parameters:a.parameters.map(function(b){return va(b)}),srcOffset:a.srcOffset,type:a.type}}function pa(a){return{parameters:a.parameters.map(function(b){return va(b)}),srcOffset:a.srcOffset,textAfter:a.textAfter,textBefore:a.textBefore,type:a.type}}
function qa(a){return{parameters:a.parameters.map(function(b){return va(b)}),srcOffset:a.srcOffset,type:a.type,xmlId:a.xmlId}};function wa(a){var b=["epubcfi("];a.parentPath&&xa(a.parentPath,b);if(a.rangeStartPath||a.rangeEndPath)b.push(","),a.rangeStartPath&&xa(a.rangeStartPath,b),a.rangeEndPath&&(b.push(","),xa(a.rangeEndPath,b));b.push(")");return b.join("")}
function ya(a,b){b.push("[");"ASSERTION_XML_ID"===a.type?a.xmlId&&b.push(K(a.xmlId)):"ASSERTION_TEXT_LOCATION"===a.type&&(a.textBefore&&b.push(K(a.textBefore)),a.textAfter&&b.push(",",K(a.textAfter)));for(var c=0;c<a.parameters.length;c++){var d=a.parameters[c];b.push(";",K(d.name));for(var e="=",f=0;f<d.values.length;f++)b.push(e,K(d.values[f])),e=","}b.push("]")}
function xa(a,b){for(var c=0;c<a.localPaths.length;c++){var d=a.localPaths[c],e=b;d.indirection&&e.push("!");for(var f=0;f<d.steps.length;f++){var g=d.steps[f],k=e;k.push("/",g.stepValue);g.assertion&&ya(g.assertion,k)}}a.offset&&(a=a.offset,"OFFSET_CHARACTER"===a.type?b.push(":",a.characterOffset):"OFFSET_SPATIAL"===a.type?b.push("@",a.x,":",a.y):"OFFSET_TEMPORAL"===a.type&&(b.push("~",a.seconds),A(a.x)&&A(a.y)&&b.push("@",a.x,":",a.y)),a.assertion&&ya(a.assertion,b))}
function K(a){return a.replace(/[\^\[\]\(\),;=]/g,function(b){return"^"+b})};function za(a,b,c,d){var e=null;if(z(a)){var f=b<a.childNodes.length?a.childNodes[b]:null;z(f)?d=L(f,d):f?(d=L(a,d),d.push(Aa(f)),e=Ba(f)):(d=L(a,d),d.push({type:"STEP_ELEMENT",assertion:null,stepValue:0===b?0:2*a.childElementCount+2}))}else if(z(a.parentNode))a===d?d=null:(d=L(a.parentNode,d),d.push(Aa(a))),(e=Ba(a))&&a&&3===a.nodeType&&(e.characterOffset+=b);else throw new C("CONTAINER_NOT_ATTACHED_TO_DOCUMENT",""+a);c||(c=J());d&&c.localPaths.push({type:"LOCAL_PATH",indirection:0<c.localPaths.length,
steps:d});c.offset=e}function Ba(a){if(z(a))return null;a=a.previousSibling;for(var b=0;a&&!z(a);)a&&3===a.nodeType&&(b+=a.data.length),a=a.previousSibling;return{type:"OFFSET_CHARACTER",assertion:null,characterOffset:b}}function Ca(a,b){return{type:"LOCAL_PATH",indirection:b,steps:L(a)}}
function L(a,b){for(var c=[],d=[];a.parentNode&&!x(a.parentNode)&&a!==b;)c.push(a),a=a.parentNode;for(a=c.length-1;0<=a;a--){b=c[a];var e=null;b.id&&(e={type:"ASSERTION_XML_ID",xmlId:b.id,parameters:[]});d.push({type:"STEP_ELEMENT",stepValue:2*(Da(b)+1),assertion:e})}return d}function Aa(a){for(a=a.previousSibling;a&&!z(a);)a=a.previousSibling;return{type:"STEP_TEXT",stepValue:a?2*Da(a)+3:1,assertion:null}}function Da(a){for(var b=0;a=a.previousElementSibling;)b++;return b};function M(a){this.h=a?a:ta()}h=M.prototype;h.appendLocalPathTo=function(a){if(this.h.rangeStartPath||this.h.rangeEndPath)throw new C("RANGE_PATHS_ALREADY_SET");a=L(a);this.h.parentPath||(this.h.parentPath=J());this.h.parentPath.localPaths.push({type:"LOCAL_PATH",steps:a,indirection:0<this.h.parentPath.localPaths.length})};
h.appendTerminalDomPosition=function(a,b){b=Math.round(b);if(this.h.rangeStartPath||this.h.rangeEndPath)throw new C("RANGE_PATHS_ALREADY_SET");this.h.parentPath||(this.h.parentPath=J());za(a,b,this.h.parentPath)};
h.appendTerminalDomRange=function(a){var b=this.h;b||(b=ta());if(a.collapsed)b.parentPath||(b.parentPath=J()),za(a.startContainer,a.startOffset,b.parentPath);else{b.parentPath||(b.parentPath=J());if(b.rangeStartPath||b.rangeEndPath)throw new C("RANGE_PATHS_ALREADY_SET");b.rangeStartPath=J();b.rangeEndPath=J();za(a.startContainer,a.startOffset,b.rangeStartPath,a.commonAncestorContainer);za(a.endContainer,a.endOffset,b.rangeEndPath,a.commonAncestorContainer);var c=0<b.parentPath.localPaths.length;if(z(a.commonAncestorContainer))b.parentPath.localPaths.push(Ca(a.commonAncestorContainer,
c));else if(z(a.commonAncestorContainer.parentNode))c=Ca(a.commonAncestorContainer.parentNode,c),c.steps.push(Aa(a.commonAncestorContainer)),b.parentPath.localPaths.push(c);else throw new C("CONTAINER_NOT_ATTACHED_TO_DOCUMENT",""+a.commonAncestorContainer);}};
h.appendTerminalIndirection=function(){if(this.h.rangeStartPath||this.h.rangeEndPath)throw new C("RANGE_PATHS_ALREADY_SET");this.h.parentPath||(this.h.parentPath=J());this.h.parentPath.localPaths.push({type:"LOCAL_PATH",indirection:!0,steps:[{type:"STEP_ELEMENT",assertion:null,stepValue:0}]})};h.clone=function(){var a=la(this.h,{},!1);return new M(a)};h.collapseToEnd=function(){this.h.rangeEndPath&&Ea(this,this.h.rangeEndPath)};h.collapseToStart=function(){this.h.rangeStartPath&&Ea(this,this.h.rangeStartPath)};
h.getEpubCfiRootNode=function(){return this.h};h.prependLocalPathTo=function(a){a=L(a);this.h.parentPath||(this.h.parentPath=J());var b=this.h.parentPath.localPaths[0];b&&(b.indirection=!0);this.h.parentPath.localPaths.unshift({type:"LOCAL_PATH",steps:a,indirection:!1})};h.toString=function(){return wa(this.h)};
function Ea(a,b){a.h.rangeStartPath=null;a.h.rangeEndPath=null;a.h.parentPath||(a.h.parentPath=J());a=a.h.parentPath;if(a.localPaths){var c=b.localPaths;c=0<c.length?c[0]:void 0;var d=v(a.localPaths);c&&(d&&!c.indirection&&(d.steps.push.apply(d.steps,q(c.steps)),b.localPaths.shift()),a.localPaths.push.apply(a.localPaths,q(b.localPaths)))}else a.localPaths=b.localPaths;a.offset=b.offset};function N(a){var b=u.FragmentSelector.call(this)||this;b.h=a;b.m="";return b}ja(N,u.FragmentSelector);h=N.prototype;h.collapseToStart=function(){if(!this.isRangeSelector())return this;var a=new M(H(this.h));a.collapseToStart();return new N(a.getEpubCfiRootNode())};h.collapseToEnd=function(){if(!this.isRangeSelector())return this;var a=new M(H(this.h));a.collapseToEnd();return new N(a.getEpubCfiRootNode())};
h.createRangeSelectorTo=function(a){if(!(a instanceof N))return null;var b=this.h.parentPath;this.h.rangeStartPath&&(b=new M(H(this.h)),b.collapseToStart(),b=b.getEpubCfiRootNode().parentPath);var c=a.h.parentPath;a.h.rangeEndPath&&(c=new M(H(a.h)),c.collapseToEnd(),c=c.getEpubCfiRootNode().parentPath);if(!c||!b)return null;a=ta();for(var d=J(),e=J(),f=J(),g=Math.min(b.localPaths.length,c.localPaths.length),k=!1,l=0;!k&&l<g;){var n=b.localPaths[l],p=c.localPaths[l];if(n.indirection!==p.indirection)k=
!0;else{var m=n.steps;p=p.steps;for(var y=Math.min(m.length,p.length),t=0;!k&&t<y;){var w=m[t];var r=p[t];w.stepValue!==r.stepValue||w.assertion&&!r.assertion||!w.assertion&&r.assertion?r=!1:(w=w.assertion,r=r.assertion,r=w&&r?Fa(w,r):!0);r?t++:k=!0}m.length!==p.length&&(k=!0);if(k){if(0<t){y=sa();y.indirection=n.indirection;for(n=0;n<t;n++)y.steps.push(F(m[n]));d.localPaths.push(y);if(t<m.length){n=sa();for(y=t;y<m.length;y++)n.steps.push(F(m[y]));e.localPaths.push(n)}if(t<p.length){for(m=sa();t<
p.length;t++)m.steps.push(F(p[t]));f.localPaths.push(m)}l++}}else d.localPaths.push(E(n)),l++}}b.localPaths.length!==c.localPaths.length&&(k=!0);if(k){for(g=l;g<b.localPaths.length;g++)e.localPaths.push(E(b.localPaths[g]));for(;l<c.localPaths.length;l++)f.localPaths.push(E(c.localPaths[l]));b.offset&&(e.offset=G(b.offset));c.offset&&(f.offset=G(c.offset))}else b=b.offset,c=c.offset,b&&!c||!b&&c||b&&c&&!Ga(b,c)?(e.offset=b?G(b):null,f.offset=c?G(c):null,k=!0):d.offset=b?G(b):null;a.parentPath=d;k&&
(a.rangeStartPath=e,a.rangeEndPath=f);return new N(a)};h.getFragmentSpecificationIri=function(){return u.FragmentSpecificationIri.EPUB3};h.getRefinementSelectors=function(){return[]};h.getRootNode=function(){return this.h};h.getValue=function(){this.m||(this.m=wa(this.h));return this.m};h.isRangeSelector=function(){return!!this.h.rangeEndPath};
function Fa(a,b){if(a.type!==b.type||a.parameters.length!==b.parameters.length||!a.parameters.every(function(c,d){var e=b.parameters[d];return c.name===e.name&&c.values.length===e.values.length&&c.values.every(function(f,g){return f===e.values[g]})}))return!1;switch(a.type){case "ASSERTION_TEXT_LOCATION":if(a.textAfter!==b.textAfter||a.textBefore!==b.textBefore)return!1;break;case "ASSERTION_XML_ID":if(a.xmlId!==b.xmlId)return!1}return!0}
function Ga(a,b){if(a.type!==b.type)return!1;switch(a.type){case "OFFSET_CHARACTER":if(a.characterOffset!==b.characterOffset)return!1;break;case "OFFSET_SPATIAL":if(a.x!==b.x||a.y!==b.y)return!1;break;case "OFFSET_TEMPORAL":if(a.seconds!==b.seconds||a.x!==b.x||a.y!==b.y)return!1}if(a.assertion&&!b.assertion||!a.assertion&&b.assertion)return!1;a=a.assertion;b=b.assertion;return a&&b?Fa(a,b):!0};function O(a){this.g=a;this.i=0}O.prototype.next=function(){return this.g[this.i++]};O.prototype.peek=function(){return this.g[this.i]};function Ha(a){this.j=!1;this.i=void 0;this.src="string"===typeof a?a.replace(/[^\x09\x0A\x0D\u0020-\uFFFD]/g,""):"";var b=this.src.indexOf("epubcfi(");0<b&&(this.src=this.src.substring(b));-1===b?(this.g=0,this.j=!0,this.i={type:-1,value:this.src,srcOffset:0}):(this.g=8,this.i={type:0,value:"epubcfi(",srcOffset:0});this.srcModified=this.src!==a}Ha.prototype.next=function(){if(void 0!==this.i){var a=this.i;this.i=void 0;return a}return Ia(this)};
Ha.prototype.peek=function(){void 0===this.i&&(this.i=Ia(this));return this.i};
function Ia(a){if(!a.j){var b=a.g++,c=a.src.charCodeAt(b);if(33===c)b=P(40,"!",b);else if(44===c)b=P(41,",",b);else if(47===c){for(var d=c=a.g;Q(a.src.charCodeAt(c));)c++;a.g=c;b=d===c?P(-1,"/",b):{type:11,value:Number(a.src.substring(d,c)),srcOffset:d}}else if(58===c)b=P(42,":",b);else if(64===c)b=P(45,"@",b);else if(126===c)b=P(46,"~",b);else if(91===c){c=!0;for(d=[];c;){var e=void 0,f=a.g++,g=a.src.charCodeAt(f);if(44===g)e=P(41,",",f);else if(59===g)e=P(43,";",f);else if(61===g)e=P(44,"=",f);
else if(93===g)c=!1;else if(isNaN(g))e=P(-2,"",f),c=!1;else if(91===g||40===g||41===g)e=P(-1,String.fromCharCode(g),f);else{e=a;for(var k=g=f,l=!1,n="",p=!0;p;){var m=e.src.charCodeAt(g);94===m?(m=e.src.charCodeAt(g+1),isNaN(m)?p=!1:(n+=e.src.substring(k,g),55296<=m&&56319>=m?(n+=String.fromCharCode(m,e.src.charCodeAt(g+2)),g+=3):(n+=String.fromCharCode(m),g+=2),k=g)):91===m||93===m||40===m||41===m||44===m||59===m||61===m||isNaN(m)?p=!1:(32===m&&(l=!0),g++)}e.g=g;e={type:30,value:n+e.src.substring(k,
g),srcOffset:f,C:l}}e&&d.push(e)}b={type:20,value:d,srcOffset:b}}else if(41===c)b=P(1,")",b),a.j=!0;else if(isNaN(c))b=P(-2,"",b),a.j=!0;else if(Q(c)){for(c=a.g;Q(d=a.src.charCodeAt(c));)c++;if(46===d&&Q(a.src.charCodeAt(c+1)))for(c+=2;Q(a.src.charCodeAt(c));)c++;a.g=c;b={type:10,value:Number(a.src.substring(b,c)),srcOffset:b}}else{for(c=a.g;Ja(a.src.charCodeAt(c));)c++;a.g=c;b=P(-1,a.src.substring(b,c),b)}return b}}function P(a,b,c){return{type:a,value:b,srcOffset:c}}
function Ja(a){return 128<a||97<=a&&122>=a||65<=a&&97>=a}function Q(a){return 48<=a&&57>=a};function S(a,b){var c;if(b&&!ma(b)&&"object"===typeof b){var d=b.srcOffset;"string"===typeof b.value?c=b.value:c=ma(b.value)?"["+b.value.map(function(e){return e.value}).join("")+"]":""+b.value}else d=b;return{type:a,srcOffset:d,value:c||""}};function T(a){this.errors=[];this.g=new Ha(a)}T.prototype.parse=function(){var a=null,b=null,c=null;if(U(this,0,"INVALID_EPUBCFI_START")){a=Ka(this);var d=this.g.peek();d&&41===d.type&&(this.g.next(),b=Ka(this),U(this,41,"MISSING_END_RANGE")&&(c=Ka(this)));U(this,1,"INVALID_EPUBCFI_END")}return{type:"ROOT",srcOffset:0,src:this.g.src,srcModified:this.g.srcModified,parentPath:a,rangeStartPath:b,rangeEndPath:c,errors:this.errors}};
function U(a,b,c,d,e){e=e||a.g;var f=e.peek();if(f&&f.type===b)return e.next();V(a,c,f||{type:-2,value:"",srcOffset:d?d.srcOffset:a.g.g})}function La(a,b){var c=Ma(a,new O(b.value));return 0===c.length?(V(a,"INVALID_ASSERTION",b),null):Na(c,b.srcOffset)}
function Oa(a,b){var c=b.next();if(!c)return null;if(43!==c.type)return V(a,"INVALID_PARAMETER",c),null;var d=U(a,30,"INVALID_PARAMETER",c,b);if(!d)return null;if(d.C)return V(a,"INVALID_PARAMETER",d),null;if(!U(a,44,"INVALID_PARAMETER",d,b))return null;var e=U(a,30,"INVALID_PARAMETER",c,b);if(!e)return null;for(var f=[e.value],g;(g=b.peek())&&43!==g.type;)b.next(),41!==g.type?V(a,"INVALID_PARAMETER",g):(e=U(a,30,"INVALID_PARAMETER",g,b))&&f.push(e.value);return{type:"PARAMETER",srcOffset:c.srcOffset,
name:d.value,values:f}}function Ma(a,b){for(var c=[];b.peek();){var d=Oa(a,b);d&&c.push(d)}return c}
function Ka(a){var b=[],c=a.g.g,d=null,e=a.g.peek();if(e&&(11===e.type||40===e.type)){b=!1;var f;e=[];for(var g=!1;!b&&(f=a.g.peek());)if(11===f.type){for(var k=void 0,l=a,n=[];(k=l.g.peek())&&11===k.type;){var p=l,m=l.g.next(),y=0===m.value%2?"STEP_ELEMENT":"STEP_TEXT",t=m?m.srcOffset:p.g.g,w=null,r=p.g.peek();if(r&&20===r.type)if("STEP_TEXT"===y)w=La(p,p.g.next());else{w=void 0;r=p;p=p.g.next();var I=new O(p.value),ba=null,ua=I.peek();ua&&30===ua.type&&(ba=ua.value,I.next());I=Ma(r,I);null===ba&&
0===I.length&&V(r,"INVALID_ASSERTION",p);ba?w={type:"ASSERTION_XML_ID",xmlId:ba,srcOffset:p.srcOffset,parameters:I}:w=Na(I,p.srcOffset)}n.push({type:y,srcOffset:t,stepValue:m.value,assertion:w})}e.push({type:"LOCAL_PATH",steps:n,indirection:g,srcOffset:f.srcOffset});g=!1}else 40===f.type?(g&&V(a,"INVALID_INDIRECTION",f),g=!0,a.g.next()):b=!0;g&&f&&e.push({type:"LOCAL_PATH",steps:[],indirection:!0,srcOffset:f.srcOffset});b=e;e=a.g.peek()}if(f=e)f=e.type,f=46===f||45===f||42===f;if(f){d=null;if(f=a.g.peek())if(46===
f.type){var R=a.g.next();if(d=U(a,10,"INVALID_TEMPORAL_OFFSET",R)){f=a.g.peek();g=e=null;if(f&&45===f.type){if(e=Pa(a,a.g.next()))g=e.assertion;f=a.g.peek()}!g&&f&&20===f.type&&(g=La(a,a.g.next()));d={type:"OFFSET_TEMPORAL",srcOffset:R.srcOffset,assertion:g,seconds:d.value,x:e?e.x:null,y:e?e.y:null}}else d=null}else 45===f.type?d=Pa(a,a.g.next()):42===f.type&&(d=a.g.next(),(f=U(a,10,"INVALID_CHARACTER_OFFSET",d))?(e=null,(g=a.g.peek())&&20===g.type&&(e=a.g.next(),l=new O(e.value),k=g=null,(n=l.peek())&&
30===n.type&&(g=n,l.next(),n=l.peek()),n&&41===n.type&&(l.next(),k=U(a,30,"INVALID_ASSERTION",n,l)),l=Ma(a,l),null===g&&null===k&&0===l.length?(V(a,"INVALID_ASSERTION",e),e=null):(g||k?R={type:"ASSERTION_TEXT_LOCATION",textBefore:g?g.value:null,textAfter:k?k.value:null,parameters:l,srcOffset:e.srcOffset}:R=Na(l,e.srcOffset),e=R)),d={type:"OFFSET_CHARACTER",srcOffset:d.srcOffset,characterOffset:f.value,assertion:e}):d=null);e=a.g.peek()}return{type:"PATH",offset:d,localPaths:b,srcOffset:c,complete:!!e&&
(41===e.type||1===e.type)}}
function Pa(a,b){var c=U(a,10,"INVALID_SPATIAL_OFFSET",b);if(!c)return null;var d=c.value;if(0>d||100<d)V(a,"INVALID_SPATIAL_OFFSET_VALUE",c),d=0>d?0:100<d?100:d;c=U(a,42,"INVALID_SPATIAL_OFFSET",c);if(!c)return null;var e=U(a,10,"INVALID_SPATIAL_OFFSET",c);if(!e)return null;c=e.value;if(0>c||100<c)V(a,"INVALID_SPATIAL_OFFSET_VALUE",e),c=0>c?0:100<c?100:c;e=a.g.peek();var f=null;e&&20===e.type&&(f=La(a,a.g.next()));return{type:"OFFSET_SPATIAL",srcOffset:b.srcOffset,assertion:f,x:d,y:c}}
function V(a,b,c){a.errors.push(S(b,c))}T.isEpubCfi=Qa;T.parse=Ra;function Na(a,b){return{type:"ASSERTION_GENERIC",parameters:a,srcOffset:b}}function Qa(a){return 0<=a.indexOf("epubcfi(")}function Ra(a){return(new T(a)).parse()};function Sa(){}Sa.prototype.canParseFragment=function(a){return a.startsWith("epubcfi(")};Sa.prototype.getFragmentSpecificationIri=function(){return u.FragmentSpecificationIri.EPUB3};Sa.prototype.parseFragment=function(a){a=Ra(a);return a.parentPath?new N(a):null};function W(a,b,c,d,e,f){this.repairedWithXmlIdAssertions=this.missingXmlIdAssertions=!1;this.resolverErrors=[];this.stepsResolved=!0;this.ast=a;this.documentUrl=b;this.container=c;this.offset=d;this.isOpfDocument="package"===this.getDocument().documentElement.nodeName;this.intendedTargetType=e;this.virtualTarget=f}W.prototype.createResolverError=function(a,b,c){this.resolverErrors.push({type:a,documentUrl:this.documentUrl,node:b,errorData:c})};
W.prototype.getDocument=function(){return x(this.container)?this.container:this.container.ownerDocument};W.prototype.getTargetElement=function(){if("ELEMENT"===this.intendedTargetType&&null===this.virtualTarget&&(z(this.container)||x(this.container))){var a=this.container.childNodes[this.offset];return z(a)?a:null}return null};W.prototype.getTargetNode=function(){var a=null;null===this.virtualTarget&&(a=z(this.container)||x(this.container)?this.container.childNodes[this.offset]:this.container);return a};function Ta(a,b,c){this.j=a;this.g=b;this.i=c}
function Ua(a){for(var b=a.j.steps,c=0;c<b.length;c++){var d=b[c];if("STEP_ELEMENT"===d.type){var e=a;e.g.intendedTargetType="ELEMENT";if(e.g.virtualTarget)Va(e,d)&&(e.g.stepsResolved=!0,e.g.virtualTarget=null);else if(z(e.i)){var f=e,g=e.i,k=d,l=k.stepValue/2-1,n=g.children,p=n.length;0<=l&&l<p?f.i=n[l]:0>l?f.g.virtualTarget="FIRST_CHILD":(f.g.virtualTarget="LAST_CHILD",l!==p&&(f.g.createResolverError("STEP_VALUE_OUT_OF_BOUNDS",g,k),f.g.stepsResolved=!1));Va(e,d)}}else if(f=a,g=d,f.g.intendedTargetType=
"TEXT",!f.g.virtualTarget&&z(f.i))if(e=void 0,d=f,f=f.i,k=g,g=(k.stepValue>>1)-1,g<f.childElementCount)if(k=-1===g?0:B(f.children[g])+1,k<f.childNodes.length){for(g=f=f.childNodes[k];!e&&g&&!z(g);)g&&3===g.nodeType&&(e=g),g=g.nextSibling;d.i=e||f}else d.g.virtualTarget=-1===g?"FIRST_CHILD":"LAST_CHILD";else d.g.virtualTarget="LAST_CHILD",d.g.createResolverError("STEP_VALUE_OUT_OF_BOUNDS",f,k),d.g.stepsResolved=!1}a.g.virtualTarget?(a.g.container=a.i,a.g.offset="FIRST_CHILD"===a.g.virtualTarget?0:
a.i.childNodes.length):(a.g.container=a.i.parentNode,a.g.offset=B(a.i));return a.g}
function Va(a,b){var c=!1,d=a.i;if((b=b.assertion)&&b.xmlId){if(null!==a.g.virtualTarget||!z(d)||d.id!==b.xmlId){var e=d.ownerDocument.getElementById(b.xmlId);!e||"itemref"===d.nodeName&&"itemref"!==e.nodeName?a.g.createResolverError("XML_ID_ASSERTION_FAILED",d,b):(a.i=e,a.g.repairedWithXmlIdAssertions=!0,a.g.virtualTarget=null,c=!0)}}else null===a.g.virtualTarget&&z(d)&&d.id&&(a.g.missingXmlIdAssertions=!0);return c};function Wa(a,b){this.characterOffsetOutOfBounds=!1;this.elementCharacterOffset=null;this.indirectionsResolved=!0;this.spatialOffset=this.sideBias=null;this.stepsResolved=!0;this.temporalOffset=null;this.ast=a;this.localPaths=[];this.addResolvedLocalPath(b)}h=Wa.prototype;
h.addResolvedLocalPath=function(a){this.localPaths.push(a);this.stepsResolved=a.stepsResolved;this.container=a.container;this.documentUrl=a.documentUrl;this.intendedTargetType=a.intendedTargetType;this.offset=a.offset;this.virtualTarget=a.virtualTarget};h.getDocument=function(){return x(this.container)?this.container:this.container.ownerDocument};h.getResolverErrors=function(){for(var a=[],b=0;b<this.localPaths.length;b++){var c=this.localPaths[b];0<c.resolverErrors.length&&a.push.apply(a,q(c.resolverErrors))}return a};
h.getTargetElement=function(){return v(this.localPaths).getTargetElement()};h.getTargetNode=function(){return v(this.localPaths).getTargetNode()};h.getTerminalLocalPath=function(){return v(this.localPaths)};h.isMissingXmlIdAssertions=function(){return this.localPaths.some(function(a){return a.missingXmlIdAssertions})};h.isRepairedWithXmlIdAssertions=function(){return this.localPaths.some(function(a){return a.repairedWithXmlIdAssertions})};h.isTargetingOpfDocument=function(){return v(this.localPaths).isOpfDocument};function Xa(a){this.indirectionErrors=[];this.rangeStartPath=this.rangeEndPath=this.parentPath=null;this.ast=a}h=Xa.prototype;
h.createDomRange=function(){if(this.isTargetingOpfDocument())return null;var a=null;if(this.rangeStartPath&&this.rangeEndPath){var b=this.rangeStartPath.getDocument();a=b.createRange();a.setStart(this.rangeStartPath.container,this.rangeStartPath.offset);this.rangeEndPath.getDocument()===b&&a.setEnd(this.rangeEndPath.container,this.rangeEndPath.offset)}else this.parentPath&&(a=this.parentPath.getDocument().createRange(),a.setStart(this.parentPath.container,this.parentPath.offset));return a};
h.getElementCharacterOffsets=function(){return Ya(this,"elementCharacterOffset",{characterOffset:0})};h.getParserErrors=function(){return this.ast.errors};h.getResolverErrors=function(){for(var a=[],b=[this.parentPath,this.rangeStartPath,this.rangeEndPath],c=0;c<b.length;c++){var d=b[c];d&&a.push.apply(a,q(d.getResolverErrors()))}return a};h.getSideBias=function(){return!this.parentPath||this.rangeStartPath||this.rangeEndPath?null:this.parentPath.sideBias};
h.getSpatialOffsets=function(){return Ya(this,"spatialOffset",{x:0,y:0})};h.getTargetElement=function(){if(this.rangeStartPath&&this.rangeEndPath){var a=this.rangeStartPath.getTargetElement();return null!==a&&a===this.rangeEndPath.getTargetElement()?a:null}return this.parentPath?this.parentPath.getTargetElement():null};h.getTemporalOffsets=function(){return Ya(this,"temporalOffset",{seconds:0})};
h.hasElementOffsets=function(){return null!==this.getSpatialOffsets()||null!==this.getTemporalOffsets()||null!==this.getElementCharacterOffsets()};h.hasErrors=function(){return!this.hasParentPathOrRangePaths()||this.isTargetingOpfDocument()||!this.isEveryIndirectionResolved()||0<this.ast.errors.length||!this.isEveryStepAndOffsetParsed()||!this.isEveryStepResolved()||this.isSomeCharacterOffsetOutOfBounds()};
h.hasParentPathOrRangePaths=function(){return null!==this.parentPath&&(null===this.rangeStartPath&&null===this.rangeStartPath||null!==this.rangeStartPath&&null!==this.rangeEndPath)};h.hasRangePaths=function(){return null!==this.rangeStartPath&&null!==this.rangeEndPath};h.hasWarnings=function(){return this.isMissingXmlIdAssertions()||this.isRepairedWithXmlIdAssertions()};
h.isDomRange=function(){var a=this.rangeStartPath,b=this.rangeEndPath;return!!a&&!!b&&!this.isTargetingOpfDocument()&&a.getDocument()===b.getDocument()&&(a.container!==b.container||a.offset!==b.offset)};h.isEveryIndirectionResolved=function(){return 0===this.indirectionErrors.length};h.isEveryStepAndOffsetParsed=function(){return null!==this.ast.parentPath&&this.ast.parentPath.complete&&(!this.ast.rangeStartPath||this.ast.rangeStartPath.complete)&&(!this.ast.rangeEndPath||this.ast.rangeEndPath.complete)};
h.isEveryStepResolved=function(){return null!==this.parentPath&&this.parentPath.stepsResolved&&(!this.rangeStartPath||this.rangeStartPath.stepsResolved)&&(!this.rangeEndPath||this.rangeEndPath.stepsResolved)};h.isMissingXmlIdAssertions=function(){return null!==this.parentPath&&this.parentPath.isMissingXmlIdAssertions()||null!==this.rangeStartPath&&this.rangeStartPath.isMissingXmlIdAssertions()||null!==this.rangeEndPath&&this.rangeEndPath.isMissingXmlIdAssertions()};
h.isOwnedBySingleDocument=function(){return this.rangeStartPath&&this.rangeEndPath?this.rangeStartPath.getDocument()===this.rangeEndPath.getDocument():!0};h.isRepairedWithXmlIdAssertions=function(){return null!==this.parentPath&&this.parentPath.isRepairedWithXmlIdAssertions()||null!==this.rangeStartPath&&this.rangeStartPath.isRepairedWithXmlIdAssertions()||null!==this.rangeEndPath&&this.rangeEndPath.isRepairedWithXmlIdAssertions()};
h.isSomeCharacterOffsetOutOfBounds=function(){return[this.parentPath,this.rangeStartPath,this.rangeEndPath].some(function(a){return null!==a&&a.characterOffsetOutOfBounds})};h.isTargetingElement=function(){return null!==this.getTargetElement()};h.isTargetingOpfDocument=function(){return this.rangeStartPath&&this.rangeEndPath?this.rangeStartPath.isTargetingOpfDocument()||this.rangeEndPath.isTargetingOpfDocument():null!==this.parentPath&&this.parentPath.isTargetingOpfDocument()};
function Ya(a,b,c){var d=null,e=a.rangeStartPath,f=a.rangeEndPath;e&&f?f[b]?d={start:e[b]||c,end:f[b]}:e[b]&&(d={start:e[b],end:null}):(a=a.parentPath)&&a[b]&&(d={start:a[b],end:null});return d};function Za(a){var b=a.ast.offset;if("ELEMENT"===a.intendedTargetType&&b&&a.stepsResolved)if("OFFSET_TEMPORAL"===b.type)a.temporalOffset={seconds:b.seconds},A(b.x)&&A(b.y)&&(a.spatialOffset={x:b.x,y:b.y});else if("OFFSET_SPATIAL"===b.type)a.spatialOffset={x:b.x,y:b.y};else{if("OFFSET_CHARACTER"===b.type){var c=a.getTargetElement();c&&"img"===c.localName&&(a.elementCharacterOffset=b.characterOffset)}}else"TEXT"===a.intendedTargetType&&(c=!1,b&&("OFFSET_CHARACTER"===b.type&&a.stepsResolved&&($a(a,b.characterOffset),
c=!0),b.assertion&&(c=!0)),c||$a(a,0))}function $a(a,b){for(var c=a.getTargetNode(),d=[];c&&!z(c);)c&&3===c.nodeType&&d.push(c),c=c.nextSibling;0<d.length?(c=d.find(function(e){if(b<e.length)return!0;b-=e.length;return!1}))?(a.container=c,a.offset=b):(d=d[d.length-1],a.container=d,a.offset=d.data.length,a.characterOffsetOutOfBounds=0!==b):0<b&&(a.characterOffsetOutOfBounds=!0)};function X(a){this.o=0;this.B=!1;this.u=this.s=0;this.A=!1;this.h=a="string"===typeof a?Ra(a):a;this.l=a.parentPath;this.j=a.rangeStartPath;this.i=a.rangeEndPath;this.g=new Xa(a)}
X.prototype.continueResolving=function(a,b){var c=null;x(a)?c=a.documentElement:a&&11===a.nodeType&&z(a.firstChild)?c=a.firstChild:z(a)&&a.parentNode&&(c=a);if(!c){var d;this.g.indirectionErrors.push({from:null,fromPath:(null==(d=ab(this))?void 0:d.documentUrl)||null,target:c,targetPath:b});return null}if(this.l)a:{for(a=bb(this,1,c,b);this.l;){if(Y(this,1)){this.g.parentPath&&(this.g.parentPath.indirectionsResolved=!1);break a}cb(this,1,a,b)}db(this,a,b);eb(this,a,b)}else this.j?(a=bb(this,2,c,b),
db(this,a,b)):this.i&&(a=bb(this,3,c,b),eb(this,a,b));b=ab(this);fb(this);return b};X.prototype.getResolvedTarget=function(){return this.g};X.prototype.skipNextIndirection=function(){gb(this);var a=ab(this);fb(this);return a};
X.prototype.addResolvedLocalPath=function(a,b){a:{switch(a){case 1:var c=this.g.parentPath;break a;case 3:c=this.g.rangeEndPath;break a;case 2:c=this.g.rangeStartPath;break a}c=void 0}if(c)c.addResolvedLocalPath(b),c.indirectionsResolved=!0;else switch(a){case 1:c=this.g.parentPath=new Wa(this.h.parentPath,b);break;case 2:c=this.g.rangeStartPath=new Wa(this.h.rangeStartPath,b);break;case 3:c=this.g.rangeEndPath=new Wa(this.h.rangeEndPath,b)}return c};
function hb(a,b){var c=null;switch(b){case 1:a.l&&(c=Z(a.l,a.o++),Z(a.l,a.o)||(a.l=null));break;case 2:a.j&&(c=Z(a.j,a.u++),Z(a.j,a.u)||(a.j=null));break;case 3:a.i&&(c=Z(a.i,a.s++),Z(a.i,a.s)||(a.i=null))}return c}function eb(a,b,c){for(;a.i;){if(Y(a,3)){a.g.rangeEndPath&&(a.g.rangeEndPath.indirectionsResolved=!1);break}b=cb(a,3,b,c)}}function db(a,b,c){for(;a.j;){if(Y(a,2)){a.g.rangeStartPath&&(a.g.rangeStartPath.indirectionsResolved=!1);break}b=cb(a,2,b,c)}}
function Z(a,b){return b<a.localPaths.length?a.localPaths[b]:null}function ab(a){var b=null,c=null;Y(a,1)?b=a.g.parentPath:Y(a,2)?b=a.g.rangeStartPath||a.g.parentPath:Y(a,3)&&(b=a.g.rangeEndPath||a.g.parentPath);b&&((c=b.getTargetElement())?c={documentUrl:b.documentUrl,element:c}:(a.g.indirectionErrors.push({from:b.getTargetNode(),fromPath:b.documentUrl,target:null,targetPath:null}),gb(a),c=ab(a)));return c}
function ib(a){var b=null;if(a.ast.offset&&a.ast.offset.assertion)b=a.ast.offset.assertion;else{var c=a.getTerminalLocalPath();c.ast&&(c=v(c.ast.steps))&&(b=c.assertion)}b&&(b=b.parameters.find(function(d){return"s"===d.name&&0<d.values.length}))&&(a.sideBias="b"===b.values[0]?"BEFORE":"AFTER")}
function gb(a){a.l?(a.l=null,a.j=null,a.i=null,a.g.parentPath&&(a.g.parentPath.indirectionsResolved=!1,a.g.parentPath.stepsResolved=!1)):a.j?(a.j=null,a.g.rangeStartPath&&(a.g.rangeStartPath.indirectionsResolved=!1,a.g.rangeStartPath.stepsResolved=!1)):a.i&&(a.i=null,a.g.rangeEndPath&&(a.g.rangeEndPath.indirectionsResolved=!1,a.g.rangeEndPath.stepsResolved=!1))}
function Y(a,b){var c=!1;switch(b){case 1:a.l&&(a=Z(a.l,a.o),c=null!==a&&a.indirection);break;case 2:a.j&&(a=Z(a.j,a.u),c=null!==a&&a.indirection);break;case 3:a.i&&(a=Z(a.i,a.s),c=null!==a&&a.indirection)}return c}function fb(a){!a.g.parentPath||a.l||a.B||(Za(a.g.parentPath),ib(a.g.parentPath),a.B=!0);!a.g.rangeStartPath||a.j||a.A||(Za(a.g.rangeStartPath),ib(a.g.rangeStartPath),a.A=!0);a.g.rangeEndPath&&!a.i&&(Za(a.g.rangeEndPath),ib(a.g.rangeEndPath),a.A=!0)}
function cb(a,b,c,d){var e=hb(a,b);e?(c=new W(e,c.documentUrl,c.container,c.offset,c.intendedTargetType,c.virtualTarget),e=new Ta(e,c,c.getTargetNode()||c.container),e=Ua(e)):e=new W(null,d,c.container,c.offset,c.intendedTargetType,c.virtualTarget);return a.addResolvedLocalPath(b,e)}function bb(a,b,c,d){var e=hb(a,b);e?(d=new W(e,d,c.parentNode,B(c),"ELEMENT",null),c=Ua(new Ta(e,d,c))):c=new W(null,d,c.parentNode,B(c),"ELEMENT",null);return a.addResolvedLocalPath(b,c)};function jb(a){function b(d){var e=v(d.localPaths);e&&(e=v(e.steps))&&"STEP_TEXT"===e.type&&!d.offset&&(d.offset={type:"OFFSET_CHARACTER",characterOffset:0,assertion:null},c=!0)}var c=!1;a.parentPath&&b(a.parentPath);a.rangeStartPath&&b(a.rangeStartPath);a.rangeEndPath&&b(a.rangeEndPath);return c}
function kb(a,b){var c=!1;a.parentPath&&0!==a.parentPath.localPaths.length&&0!==a.parentPath.localPaths[0].steps.length?a.rangeStartPath&&!a.rangeEndPath?(b||a.errors.push(S("MISSING_END_RANGE")),c=!0,a.rangeStartPath=null):a.rangeEndPath&&(0===a.rangeEndPath.localPaths.length||0===a.rangeEndPath.localPaths[0].steps.length&&null===a.rangeEndPath.offset)&&(b||a.errors.push(S("PATH_EMPTY",a.rangeEndPath.srcOffset)),c=!0,a.rangeStartPath=null,a.rangeEndPath=null):(b||a.errors.push(S("PATH_EMPTY",a.srcOffset)),
c=!0,a.parentPath=null,a.rangeStartPath=null,a.rangeEndPath=null);return c}
function lb(a,b){function c(f){f.offset||(f=v(f.localPaths))&&f.indirection&&0===f.steps.length&&(b||a.errors.push(S("PATH_ENDS_WITH_INDIRECTION",f.srcOffset)),d=!0,f.steps.push({type:"STEP_ELEMENT",stepValue:0,assertion:null}))}var d=!1;if(a.parentPath&&!a.parentPath.offset){var e=v(a.parentPath.localPaths);e&&e.indirection&&0===e.steps.length&&(b||a.errors.push(S("PATH_ENDS_WITH_INDIRECTION",e.srcOffset)),d=!0,a.rangeStartPath&&a.rangeEndPath?(mb(a.rangeStartPath),mb(a.rangeEndPath),a.parentPath.localPaths.length--):
(e.steps.push({type:"STEP_ELEMENT",stepValue:0,assertion:null}),a.rangeStartPath=null,a.rangeEndPath=null))}a.rangeStartPath&&c(a.rangeStartPath);a.rangeEndPath&&c(a.rangeEndPath);return d}function nb(a,b){var c=!1;a.parentPath&&(c=ob(a,a.parentPath,b));a.rangeStartPath&&(c=ob(a,a.rangeStartPath,b)||c);a.rangeEndPath&&(c=ob(a,a.rangeEndPath,b)||c);return c}
function pb(a,b){var c=!1,d=a.rangeStartPath?a.rangeStartPath.offset:null,e=a.rangeEndPath?a.rangeEndPath.offset:null,f=!1;if(d&&e)d.type!==e.type?f=!0:"OFFSET_TEMPORAL"===d.type&&(null!==d.x&&null===e.x||null===d.x&&null!==e.x)&&(d.x=null,d.y=null,e.x=null,e.y=null,c=!0);else if(!d&&e&&"OFFSET_CHARACTER"!==e.type||d&&!e)f=!0;f&&(a.rangeStartPath&&(a.rangeStartPath.offset=null),a.rangeEndPath&&(a.rangeEndPath.offset=null),c=!0);c&&!b&&a.errors.push(S("INCOMPATIBLE_OFFSET_TYPE"));return c}
function qb(a,b){var c=!1;if(a.parentPath){var d=a.parentPath.localPaths[0];d&&d.indirection&&(d.indirection=!1,c=!0,b||a.errors.push(S("INVALID_INDIRECTION",d.srcOffset)))}return c}function rb(a,b){var c=!1;if(a.parentPath&&a.parentPath.offset){var d=a.parentPath.offset;d="OFFSET_TEMPORAL"===d.type&&null===d.x;a.rangeStartPath&&sb(a,a.rangeStartPath,d,b)&&(a.rangeStartPath=null,c=!0);a.rangeEndPath&&sb(a,a.rangeEndPath,d,b)&&(a.rangeEndPath=null,c=!0)}return c}
function tb(a,b){var c=!1;if(a.parentPath){var d=ub(a,a.parentPath,b);d.v?(c=!0,a.rangeStartPath=null,a.rangeEndPath=null):d.D&&(a.rangeStartPath&&0<a.rangeStartPath.localPaths.length&&(c=!0,a.rangeStartPath=null),a.rangeEndPath&&0<a.rangeEndPath.localPaths.length&&(c=!0,a.rangeEndPath=null))}a.rangeStartPath&&(d=ub(a,a.rangeStartPath,b),c=c||d.v);a.rangeEndPath&&(d=ub(a,a.rangeEndPath,b),c=c||d.v);return c}
function mb(a){if(a.localPaths[0])a.localPaths[0].indirection=!0;else{var b={type:"STEP_ELEMENT",stepValue:0,assertion:null};a.localPaths.push({type:"LOCAL_PATH",steps:a.offset?[]:[b],indirection:!0})}}
function ub(a,b,c){var d=!1,e=!1,f=b.localPaths.findIndex(function(g){var k=g.steps.findIndex(function(l){return"STEP_TEXT"===l.type});return-1!==k?(e=!0,k<g.steps.length-1&&(c||a.errors.push(S("INVALID_STEP",g.steps[k].srcOffset)),g.steps=g.steps.slice(0,k+1),b.offset=null,d=!0),!0):!1});-1!==f&&f<b.localPaths.length-1&&(b.localPaths=b.localPaths.slice(0,f+1),d=!0);return{v:d,D:e}}
function ob(a,b,c){var d=!1;if(b.offset){var e=v(b.localPaths);e&&e.indirection&&0===e.steps.length&&(c||a.errors.push(S("PATH_ENDS_WITH_INDIRECTION",e.srcOffset)),b.localPaths.length--,d=!0)}return d}function sb(a,b,c,d){var e=!1;0<b.localPaths.length?(d||a.errors.push(S("INVALID_RANGE_PATH",b.srcOffset)),e=!0):!b.offset||c&&"OFFSET_SPATIAL"===b.offset.type||(d||a.errors.push(S("INCOMPATIBLE_OFFSET_TYPE",b.offset.srcOffset)),e=!0);return e};api={EpubCfiBuilder:M,EpubCfiError:C,EpubCfiErrorType:{RANGE_PATHS_ALREADY_SET:"RANGE_PATHS_ALREADY_SET",CONTAINER_NOT_ATTACHED_TO_DOCUMENT:"CONTAINER_NOT_ATTACHED_TO_DOCUMENT"},EpubCfiIntendedTargetType:{ELEMENT:"ELEMENT",TEXT:"TEXT"},EpubCfiNodeType:{ROOT:"ROOT",PATH:"PATH",LOCAL_PATH:"LOCAL_PATH",STEP_ELEMENT:"STEP_ELEMENT",STEP_TEXT:"STEP_TEXT",ASSERTION_TEXT_LOCATION:"ASSERTION_TEXT_LOCATION",ASSERTION_XML_ID:"ASSERTION_XML_ID",ASSERTION_GENERIC:"ASSERTION_GENERIC",PARAMETER:"PARAMETER",OFFSET_CHARACTER:"OFFSET_CHARACTER",
OFFSET_TEMPORAL:"OFFSET_TEMPORAL",OFFSET_SPATIAL:"OFFSET_SPATIAL"},EpubCfiParser:T,EpubCfiParserErrorType:{INVALID_RANGE_PATH:"INVALID_RANGE_PATH",INCOMPATIBLE_OFFSET_TYPE:"INCOMPATIBLE_OFFSET_TYPE",INVALID_CHARACTER_OFFSET:"INVALID_CHARACTER_OFFSET",INVALID_ASSERTION:"INVALID_ASSERTION",INVALID_EPUBCFI_START:"INVALID_EPUBCFI_START",INVALID_EPUBCFI_END:"INVALID_EPUBCFI_END",INVALID_PARAMETER:"INVALID_PARAMETER",PATH_ENDS_WITH_INDIRECTION:"PATH_ENDS_WITH_INDIRECTION",INVALID_SPATIAL_OFFSET:"INVALID_SPATIAL_OFFSET",
INVALID_SPATIAL_OFFSET_VALUE:"INVALID_SPATIAL_OFFSET_VALUE",INVALID_STEP:"INVALID_STEP",INVALID_INDIRECTION:"INVALID_INDIRECTION",INVALID_TEMPORAL_OFFSET:"INVALID_TEMPORAL_OFFSET",MISSING_END_RANGE:"MISSING_END_RANGE",PATH_EMPTY:"PATH_EMPTY"},EpubCfiResolver:X,EpubCfiResolverErrorType:{XML_ID_ASSERTION_FAILED:"XML_ID_ASSERTION_FAILED",STEP_VALUE_OUT_OF_BOUNDS:"STEP_VALUE_OUT_OF_BOUNDS"},EpubCfiSelector:N,EpubCfiFragmentSelectorParser:Sa,EpubCfiSideBias:{BEFORE:"BEFORE",AFTER:"AFTER"},EpubCfiStringifier:{stringifyRootNode:wa},
EpubCfiUtils:{copyRootNode:H},EpubCfiValidator:{addExplicitCharacterOffsets:jb,checkEmptyParentPathAndRangeEnd:kb,expandEmptyIndirectionLocalPaths:lb,removeEmptyIndirectionBeforeOffsets:nb,removeIncompatibleRangeOffsets:pb,removeInitialIndirectionFromParentPath:qb,removeInvalidRangeAfterParentPathOffset:rb,removeStepsAfterTextSteps:tb,runAllValidations:function(a,b){var c=!1;c=tb(a,b)||c;c=qb(a,b)||c;c=rb(a,b)||c;c=pb(a,b)||c;c=kb(a,b)||c;c=jb(a)||c;c=lb(a,b)||c;return c=nb(a,b)||c}},EpubCfiVirtualTarget:{FIRST_CHILD:"FIRST_CHILD",
LAST_CHILD:"LAST_CHILD"}};var vb=new ArrayBuffer(8);new DataView(vb);new Uint8Array(vb); }).call(__self); return api; } ) );
