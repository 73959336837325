/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import { Dexie } from 'dexie';
import { VanillaBookmarkDataCallback, VanillaVoidCallback } from '../VanillaReader/VanillaReaderModel';
import { IVanillaReaderBookmarkData } from '../VanillaReader/VanillaReaderModel';
import { IVanillaReaderBookmarkDataStore } from './IVanillaReaderBookmarkDataStore';

/**
 * # VanillaReaderBookmarkIndexedDbDataStore
 *
 *  ## RESPONSIBILITIES
 *
 * This class acts as a runtime storage for the _bookmarks that have been added to a publication.
 * Again, it does not persist its state anywhere. To save _bookmarks between sessions call `getBookmarks()` and
 * store the returned data. To restore state you provide the same data to the `addBookmarks()` method.
 *
 * ## NOTE
 *
 * This is just the runtime storage, the rendering of _bookmarks is done in the `VanillaReaderAnnotationLayerBookmarks`
 * class.
 *
 */
export class VanillaReaderBookmarkIndexedDbDataStore extends Dexie implements IVanillaReaderBookmarkDataStore {

    // private _bookmarks: Dexie.Table<IVanillaReaderBookmarkData, string>;

    private _bookmarkAddedCallback: VanillaBookmarkDataCallback | undefined = undefined;
    private _bookmarkDeletedCallback: VanillaBookmarkDataCallback | undefined = undefined;
    private _bookmarksCollectionUpdatedCallback: VanillaVoidCallback | undefined = undefined;

    constructor(dbName: string = 'bookmarks.vanillareader.colibrio.com') {

        super(dbName);

        // this.version(1).stores({
        //     bookmarks: '_id, userid, locator, bookSection, publicationId, dateCreated',
        // });

        // this._bookmarks = this.table('bookmarks');

    }

    onBookmarkAdded(callback: VanillaBookmarkDataCallback) {
        this._bookmarkAddedCallback = callback;
    }

    onBookmarkDeleted(callback: VanillaBookmarkDataCallback) {
        this._bookmarkDeletedCallback = callback;
    }

    onBookmarksCollectionUpdated(callback: VanillaVoidCallback) {
        this._bookmarksCollectionUpdatedCallback = callback;
    }

    async addBookmark(bookmarkData: IVanillaReaderBookmarkData): Promise<IVanillaReaderBookmarkData> {

        let targetLocator = bookmarkData.locator
        let field_data = { name: 'locator', value: targetLocator }

        // @ts-ignore
        let data = await window.reader.fetchBookmark(field_data)

        if (!data.data.bookmark) {
            return this._addBookmark(bookmarkData).catch()
        }

        else {

            console.warn(`VanillaReaderBookmarkStore.addBookmark(): location "${targetLocator}" is already Bookmarked.`)
            return Promise.reject(`VanillaReaderBookmarkStore.addBookmark(): location "${targetLocator}" is already Bookmarked.`)

        }

    }

    async addBookmarks(bookmarkData: IVanillaReaderBookmarkData[], silent: boolean = true): Promise<void> {
        bookmarkData.forEach((bookmark: IVanillaReaderBookmarkData) => {
            this._addBookmark(bookmark, silent).catch(console.warn);
        });
        return Promise.resolve();
    }

    async fetchBookmark(locator: string): Promise<IVanillaReaderBookmarkData | undefined> {

        let field_data = { name: 'locator', value: locator }

        // @ts-ignore
        let data = await window.reader.fetchBookmark(field_data)

        if (data && Array.isArray(data.data.bookmark)) {

            return data.bookmark

        } else {
            console.warn(`VanillaReaderBookmarkStore.addBookmark(): no bookmark found for location "${locator}".`)
        }

        return

    }

    async fetchBookmarksByPublication(publicationId: string): Promise<IVanillaReaderBookmarkData[]> {

        try {
            // @ts-ignore
            let data = await window.reader.fetchBookmarksByPublication(publicationId)

            if (data && data.data && Array.isArray(data.data.bookmarks)) {

                return data.data.bookmarks

            } else {
                console.warn('No bookmarks found or invalid data format for publication:', publicationId)
                return []
            }

        } catch (error) {
            console.error('Error fetching bookmarks by publication:', error)
            return []
        }

    }

    async fetchAllBookmarks(): Promise<IVanillaReaderBookmarkData[]> {

        try {
            // @ts-ignore
            let data = await window.reader.fetchAllBookmarks()

            if (data && data.data && Array.isArray(data.data.bookmarks)) {

                return data.data.bookmarks

            } else {
                console.warn('No bookmarks found or invalid data format')
                return []
            }

        } catch (error) {
            console.error('Error fetching bookmarks:', error)
            return []
        }

    }

    async deleteBookmark(locator: string): Promise<void> {

        let field_data = { name: 'locator', value: locator }

        try {

            // @ts-ignore
            let data = await window.reader.fetchBookmark(field_data)

            if (data.data.bookmark && Object.keys(data.data.bookmark).length > 0) {

                // @ts-ignore
                await window.reader.deleteBookmark(locator)

                if (this._bookmarkDeletedCallback) {
                    this._bookmarkDeletedCallback(data.data.bookmark);
                }

                if (this._bookmarksCollectionUpdatedCallback) {
                    this._bookmarksCollectionUpdatedCallback();
                }

            } else {
                console.warn(`VanillaReaderBookmarkStore.deleteBookmark(): location "${locator}" is not bookmarked.`);
                return Promise.reject(`VanillaReaderBookmarkStore.deleteBookmark(): location "${locator}" is not bookmarked.`)
            }

        } catch (error) {
            console.error(`Error deleting highlight at location "${locator}":`, error)
            return Promise.reject(error)
        }

    }

    async deleteAllBookmarksByPublication(publicationId: string): Promise<void> {
        await this._bookmarks.where('pulicationId').equals(publicationId).delete();
        return Promise.resolve();
    }

    async deleteAllBookmarks(): Promise<void> {
        await this._bookmarks.each(async (bookmark: IVanillaReaderBookmarkData) => {
            if (bookmark._id) {
                await this.deleteBookmark(bookmark._id);
            }
        });
        return Promise.resolve();
    }

    /**
     *
     * PRIVATE METHODS
     *
     * */
    private async _addBookmark(
        bookmarkData: IVanillaReaderBookmarkData,
        silent: boolean = false,
    ): Promise<IVanillaReaderBookmarkData> {

        if (!bookmarkData.dateCreated) {
            bookmarkData.dateCreated = Date.now()
        }

        if (!bookmarkData._id) {
            bookmarkData._id = Date.now().toString()
        }

        // @ts-ignore
        await window.reader.addBookmark(bookmarkData)

        if (this._bookmarkAddedCallback) {
            this._bookmarkAddedCallback(bookmarkData, silent)
        }
        if (this._bookmarksCollectionUpdatedCallback) {
            this._bookmarksCollectionUpdatedCallback()
        }

        return bookmarkData

    }
}
