/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IPublicationMetadataItem,} from '@colibrio/colibrio-reader-framework/colibrio-core-publication-base';
import {
    IReaderDocument,
    IReaderDocumentSearch,
    IReaderPublication,
    IReaderView,
    IReaderViewAnnotationLayer,
    IReaderViewSearchAgent,
    IReadingSystemEngine, ISearchResultItem,
    NavigationCollectionType,
    TextSearchQueryWhitespaceMode,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';
import {ICreateIndexCallback, IPublicationSearchIndexData} from '../utils/PublicationSearchIndexDataFactory';
import {VanillaReaderAppUiDefaults} from '../VanillaReaderUI/VanillaReaderAppUiDefaults';
import {
    IVanillaReaderPublicationSearchResultItem,
    VanillaProgressEventCallback,
    VanillaVoidCallback,
} from './VanillaReaderModel';
import {IVanillaReaderPublicationSearch} from "./IVanillaReaderPublicationSearch";

export class VanillaReaderPublicationSearch implements IVanillaReaderPublicationSearch {
    private _colibrioDocumentSearch: IReaderDocumentSearch;
    private _colibrioReaderViewSearchAgent: IReaderViewSearchAgent;
    private _colibrioReaderViewAnnotationLayer: IReaderViewAnnotationLayer | undefined;
    private _colibrioReadingSystemEngine: IReadingSystemEngine;

    constructor(private _colibrioReaderPublication: IReaderPublication, private _colibrioReaderView: IReaderView) {
        this._colibrioReadingSystemEngine =  this._colibrioReaderPublication.getReadingSystemEngine();
        this._colibrioReaderViewAnnotationLayer = this._colibrioReaderView.createAnnotationLayer('vanilla-reader__publication-search-results');
        this._colibrioReaderViewAnnotationLayer.setLayerOptions({
            layerStyle: {
                'mix-blend-mode': 'multiply'
            }
        });
        this._colibrioReaderViewAnnotationLayer.setDefaultAnnotationOptions({
            rangeStyle: {
                backgroundColor: VanillaReaderAppUiDefaults.highlightColorValues.yellow
            }
        });
        this._colibrioDocumentSearch = this._colibrioReadingSystemEngine.getReaderDocumentSearch();
        this._colibrioReaderViewSearchAgent = this._colibrioDocumentSearch.createReaderViewSearchAgent(this._colibrioReaderViewAnnotationLayer);
    }

    public get hasIndexData():boolean {
        return true;
    }

    createIndex(_progressCallback?: ICreateIndexCallback): Promise<IPublicationSearchIndexData> {
        const readerPublication = this._colibrioReaderView.getReaderPublications()[0];
        const metadata: IPublicationMetadataItem[] = readerPublication ? readerPublication.getSourcePublication().getMetadata().getAll() : [];

        return Promise.resolve({
            documents: [],
            publicationIndexedTextLength: 0,
            hashSignature: readerPublication.getSourcePublication().getHashSignature().toString(),
            metadata
        });
    }

    async search(
        searchText: string,
        resultLimit: number,
        padding: number = 50,
        documentIndexes: number[]
    ): Promise<IVanillaReaderPublicationSearchResultItem[]> {
        return this._search(searchText, resultLimit, padding, documentIndexes, false);
    }

    clearSearch(): void {
        this._colibrioReaderViewSearchAgent?.setSearchQuery(null);
    }

    async searchAndHighlight(
        searchText: string,
        resultLimit: number,
        padding: number = 50,
        documentIndexes?: number[]
    ): Promise<IVanillaReaderPublicationSearchResultItem[]> {
        return this._search(searchText, resultLimit, padding, documentIndexes, true);
    }

    getIndexData(): IPublicationSearchIndexData | undefined {
        //NOOP
        return undefined;
    }

    setIndexData(_indexData: IPublicationSearchIndexData): void {
        //NOOP
    }

    fetchIndexData(): Promise<IPublicationSearchIndexData | undefined> {
        //NOOP
        return Promise.resolve(undefined);
    }

    onSearchIndexCreateProgressUpdate(_progressCallback: VanillaProgressEventCallback): void {
        //NOOP
    }

    onSearchIndexCreated(_createdCallback: VanillaVoidCallback): void {
        //NOOP
    }


    /**
     *
     * PRIVATE METHODS
     *
     * */

    async _search(searchText: string,
                  resultLimit: number,
                  padding: number = 50,
                  documentIndexes: number[] | undefined = undefined,
                  highlightResults: boolean
    ): Promise<IVanillaReaderPublicationSearchResultItem[]> {

        let documentsToSearch: IReaderDocument[] = [];

        const textQuery = this._colibrioDocumentSearch.createTextQuery(searchText, {
            ignoreCase: true,
            whitespaceMode: TextSearchQueryWhitespaceMode.IGNORE,
            searchResultOptions: {
                includeContentBlocks: true,
                numberOfCharactersBefore: padding,
                numberOfCharactersAfter: padding,
            }
        });

        if(highlightResults) {
            this._colibrioReaderViewSearchAgent?.setSearchQuery(textQuery);
        }

        // As no specific indexes where provided we use all ReaderDocuments added to the view.
        if(documentIndexes) {
            documentsToSearch = this._getColibrioReaderDocumentsFromSpineIndexes(documentIndexes);
        } else {
            documentsToSearch = this._colibrioReaderView.getReaderDocuments();
        }

        let resultIterator = textQuery.execute(documentsToSearch);
        let colibrioResultSet = await resultIterator.take(resultLimit);

        return this._createVanillaResultItemArray(colibrioResultSet);
    }

    _getColibrioReaderDocumentsFromSpineIndexes(_documentIndexes: number[]): IReaderDocument[] {
        return this._colibrioReaderPublication.getSpine().filter((_doc, index)=>{
            return _documentIndexes.includes(index);
        });
    }

    async _createVanillaResultItemArray(colibrioSearchResultItems: ISearchResultItem[]): Promise<IVanillaReaderPublicationSearchResultItem[]> {
        let vanillaResultSet: IVanillaReaderPublicationSearchResultItem[] = [];

        for(let colibrioSearchResultItem of colibrioSearchResultItems) {
            let bookSections = await colibrioSearchResultItem.contentLocation.fetchNavigationItemReferences({
                greedy: false,
                collectionTypes: [NavigationCollectionType.TOC]
            });
            let bookSection = bookSections.getItemsBefore()[0]?.getNavigationItem().getTextContent() || 'unknown';
            let resultItem = {
                bookSection,
                locator: colibrioSearchResultItem.contentLocation.getLocator().toString(),
                nodes: [],
                intersectingNodeText: colibrioSearchResultItem.textBefore + colibrioSearchResultItem.matchingText + colibrioSearchResultItem.textAfter,
                readerDocumentIndexInSpine: colibrioSearchResultItem.readerDocumentIndexInSpine,
                searchTermEndOffsetInIndexedContent: colibrioSearchResultItem.textAfter.length,
                searchTermStartOffsetInIndexedContent: colibrioSearchResultItem.textBefore.length

            };

            vanillaResultSet.push(resultItem);

        }

        return vanillaResultSet;
    }
}
