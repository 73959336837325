/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IVanillaReaderUI} from "./IVanillaReaderUI";
import {VanillaReaderUiMenuMediaPlayerController} from "./VanillaReaderUiMenuMediaPlayerController";
import {IVanillaReaderUiComponentController} from "./IVanillaReaderUiComponentController";
import {VanillaReaderUI} from "./VanillaReaderUI";

export interface VanillaReaderUiFabMediaPlayerController extends IVanillaReaderUiComponentController, IVanillaReaderUI, VanillaReaderUiMenuMediaPlayerController {}

/**
 * # VanillaReaderUiFabMediaPlayerController
 *
 * ## RESPONSIBILITIES
 * This controller mixing class handles all event callbacks from the `VanillaReaderUiFabMediaPlayer` class.
 *
 * Just as all other controller mixin classes, this class is used to add functionality to the `VanillaReaderUi` class
 * in a "modular" way. Read more about this in the `UI Components and Controller Mixins` in the README.md file.
 *
 * ## RELATED TYPES
 * - VanillaReaderUi
 * - VanillaReaderUiFabMediaPlayer
 *
 */
export class VanillaReaderUiFabMediaPlayerController {

    static __initialize__(vanillaReaderUiContext: VanillaReaderUI) {
        vanillaReaderUiContext.fabMediaPlayer.onClick(vanillaReaderUiContext._onFabMediaPlayerClicked.bind(vanillaReaderUiContext));
    }

    _onFabMediaPlayerClicked() {
        if (!this.menuMediaPlayer.stateIsPlaying) {
            this.startPlayback();
        } else {
            this.pausePlayback();
        }
    };

}