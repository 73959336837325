/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IVanillaReaderUI} from "./IVanillaReaderUI";
import {
    IVanillaReaderAppEvent_NAVIGATION_INTENT,
    IVanillaReaderAppEvent_PUBLICATION_SEARCH_CLEAR_INTENT, VanillaReaderAppEvents,
    VanillaReaderEventBus
} from "../VanillaReader/VanillaReaderEventBus";
import {IVanillaReaderUiComponentController} from "./IVanillaReaderUiComponentController";
import {VanillaReaderUI} from "./VanillaReaderUI";

export interface VanillaReaderUiDialogSearchController extends IVanillaReaderUiComponentController, IVanillaReaderUI {}

/**
 * # VanillaReaderUiDialogSearchController
 *
 * ## RESPONSIBILITIES
 * This controller mixing class handles all event callbacks from the `VanillaReaderUiDialogSearch` class.
 *
 * Just as all other controller mixin classes, this class is used to add functionality to the `VanillaReaderUi` class
 * in a "modular" way. Read more about this in the `UI Components and Controller Mixins` in the README.md file.
 *
 * ## RELATED TYPES
 * - VanillaReaderUi
 * - VanillaReaderUiDialogSearch
 *
 */
export class VanillaReaderUiDialogSearchController {

    static __initialize__(vanillaReaderUiContext: VanillaReaderUI) {
        vanillaReaderUiContext.dialogSearch.onSearchResultItemClicked(vanillaReaderUiContext._onDialogSearchResultItemClicked.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.dialogSearch.onSearchExecute(vanillaReaderUiContext._onDialogSearchExecute.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.dialogSearch.onSearchClear(vanillaReaderUiContext._onDialogSearchClear.bind(vanillaReaderUiContext));
    }

    _onDialogSearchExecute (searchTerm: string) {
        this.searchPublication(searchTerm);
    };

    _onDialogSearchClear(){
        VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_PUBLICATION_SEARCH_CLEAR_INTENT>(VanillaReaderAppEvents.PUBLICATION_SEARCH_CLEAR_INTENT));
        this.toaster.toast('Search results cleared', true).catch(console.warn);
        this.latestSelectedSearchResultLocator = undefined;
    };

    _onDialogSearchResultItemClicked(navigationData: IVanillaReaderAppEvent_NAVIGATION_INTENT) {
        if (navigationData.locator) {
            // Let's save this locator so that we can use it to highlight the correct result item the next time we show
            // the same search result.
            this.latestSelectedSearchResultLocator = navigationData.locator;

            this.navigateTo(navigationData.locator.toString());
        }

        this.dialogSearch.close();
    };
}