/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IVanillaReaderUI} from "./IVanillaReaderUI";
import {IVanillaReaderUiOptionsData} from "./VanillaReaderUiModel";
import {IVanillaReaderUiColorPalette, VanillaReaderRendererNames} from "../VanillaReader/VanillaReaderModel";
import {VanillaReaderAppUiDefaults} from "./VanillaReaderAppUiDefaults";
import {
    IVanillaReaderAppEvent_APP_UI_OPTIONS_CHANGED,
    IVanillaReaderAppEvent_MEDIAPLAYER_RATE_CHANGE_INTENT,
    IVanillaReaderAppEvent_MEDIAPLAYER_VOICE_CHANGE_INTENT,
    VanillaReaderAppEvents,
    VanillaReaderEventBus
} from "../VanillaReader/VanillaReaderEventBus";
import {IVanillaReaderUiComponentController} from "./IVanillaReaderUiComponentController";
import {VanillaReaderUI} from "./VanillaReaderUI";
export interface VanillaReaderUiDialogSettingsController extends IVanillaReaderUiComponentController, IVanillaReaderUI {}

/**
 * # VanillaReaderUiDialogSettingsController
 *
 * ## RESPONSIBILITIES
 * This controller mixing class handles all event callbacks from the `VanillaReaderUiDialogSettings` class.
 *
 * Just as all other controller mixin classes, this class is used to add functionality to the `VanillaReaderUi` class
 * in a "modular" way. Read more about this in the `UI Components and Controller Mixins` in the README.md file.
 *
 * ## RELATED TYPES
 * - VanillaReaderUi
 * - VanillaReaderUiDialogSettings
 *
 */
export class VanillaReaderUiDialogSettingsController {

    static __initialize__(vanillaReaderUiContext: VanillaReaderUI){
        vanillaReaderUiContext.dialogSettings.onSettingsUpdated(vanillaReaderUiContext._onDialogSettingsUpdated.bind(vanillaReaderUiContext));
    }

    _onDialogSettingsUpdated(settings: IVanillaReaderUiOptionsData) {

        if (settings.useAccessibilityMode !== this.isInAccessibilityMode) {
            this.dialogSettings.setUseAccessibilityMode(settings.useAccessibilityMode || false);
            if (settings.useAccessibilityMode) {
                this.enterAccessibilityMode();
                this.dialogSettings.close();

            } else {
                this.exitAccessibilityMode();
                this.dialogSettings.close();
            }

            return;

        }

        if (settings.activeUiPaletteName) {

            // Normally the `!` operator is a big no-no. In this case though we know that the `VanillaReaderAppUiDefaults`
            // has a set value for all it's members.
            let palette = Object.values<IVanillaReaderUiColorPalette>(VanillaReaderAppUiDefaults.options.palettes!).find((value: IVanillaReaderUiColorPalette) => {
                return value.name === settings.activeUiPaletteName;
            }) || VanillaReaderAppUiDefaults.options.palettes!.Default;

            this.isInDarkMode = palette.uiIsDark || false;

            this.setUiColorTheme(palette);

        }

        if (settings.activeRendererTypeName !== this.dialogSettings.activeRendererTypeName || !this.dialogSettings.activeRendererTypeName) {
            this.setPublicationRenderer(settings.activeRendererTypeName || VanillaReaderRendererNames.RESPONSIVE);
        }

        if (settings.ignoreAspectRatio !== this.dialogSettings.activeAspectRatioOption) {
            this.setIgnoreAspectRatio(settings.ignoreAspectRatio || false);
        }

        if (settings.useReducedMotion !== this.dialogSettings.activeReducedMotionOption) {
            this.setUseReducedMotion(settings.useReducedMotion || false);
        }

        if (settings.mediaPlayerPlaybackRate) {
            VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_MEDIAPLAYER_RATE_CHANGE_INTENT>(VanillaReaderAppEvents.MEDIAPLAYER_RATE_CHANGE_INTENT, {
                detail: {rate: settings.mediaPlayerPlaybackRate},
            }));
        }

        if (settings.ttsOptions?.voiceName !== this.dialogSettings.activeAspectTtsVoiceOption) {

            VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_MEDIAPLAYER_VOICE_CHANGE_INTENT>(VanillaReaderAppEvents.MEDIAPLAYER_VOICE_CHANGE_INTENT, {
                detail: {voiceName: settings.ttsOptions?.voiceName},
            }));

        }

        // Check if the media player is open and if so save it along with the other settings data
        settings.showNarrationControls = this.menuMediaPlayer.isOpen();

        // Finally we set the PublicationStyleOptions
        this.setPublicationStyleOptions(settings.publicationStyleOptions);

        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_APP_UI_OPTIONS_CHANGED>(new CustomEvent<IVanillaReaderAppEvent_APP_UI_OPTIONS_CHANGED>(VanillaReaderAppEvents.APP_UI_OPTIONS_CHANGED, {detail:{uiOptions: settings}}))

    };
}