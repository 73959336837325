/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {VanillaVoidCallback} from "../VanillaReader/VanillaReaderModel";

export class VanillaReaderUiButtonNext {
    private _style = `
    <style>
        #vanilla-reader__button-next {
            opacity: 1;
            height: 24px;
            width: auto;            
            bottom: 8px;                    
            transition: opacity ease-in-out 0.2s;            
            cursor: pointer;
            z-index: 1;
            opacity: 0.5;
            background-color: transparent;
            color: var(--vanilla-reader__color__ui__foreground-dark);
            border: 0;
        }
    </style>`;
    private _template = `<button id="vanilla-reader__button-next" title="Next" tabindex="0"><span aria-hidden="true">navigate_next</span></button>`;
    private _onClickCallback: VanillaVoidCallback | undefined;
    private _onVisibleCallback: VanillaVoidCallback | undefined;
    private _onHiddenCallback: VanillaVoidCallback | undefined;
    private _buttonNext: HTMLButtonElement;

    constructor(private _hostElement: HTMLElement, insertPosition: InsertPosition = 'beforeend') {
        this._hostElement.insertAdjacentHTML(insertPosition, this._style);
        this._hostElement.insertAdjacentHTML(insertPosition, this._template);
        this._buttonNext = this._hostElement.querySelector('#vanilla-reader__button-next')!;
        this._buttonNext.addEventListener('click', (_ev: MouseEvent) => {
            if (this._onClickCallback) {
                this._onClickCallback();
            }
        });
    }

    show() {
        this._buttonNext.removeAttribute('aria-hidden');
        this._buttonNext.style.opacity = '1';
        this._buttonNext.style.pointerEvents = 'all';
        if (this._onVisibleCallback) {
            this._onVisibleCallback();
        }
    }

    hide() {
        this._buttonNext.ariaHidden = 'true';
        this._buttonNext.style.opacity = '0';
        this._buttonNext.style.pointerEvents = 'none';
        if (this._onHiddenCallback) {
            this._onHiddenCallback();
        }
    }

    onClick(callback: VanillaVoidCallback) {
        this._onClickCallback = callback;
    }

    onVisible(callback: VanillaVoidCallback) {
        this._onVisibleCallback = callback;
    }

    onHidden(callback: VanillaVoidCallback) {
        this._onHiddenCallback = callback;
    }

}