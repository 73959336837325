/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {VanillaVoidCallback} from '../VanillaReader/VanillaReaderModel';

/**
 * # VanillaReaderUiFabMediaPlayer
 *
 * ## RESPONSIBILITIES
 *
 * ## RELATED TYPES
 * - VanillaReaderUiFabMediaPlayerController
 *
 */
export class VanillaReaderUiFabMediaPlayer {
    private _template = `<button class="vanilla-reader__fab" id="vanilla-reader__fab-mediaplayer" type="button" title="Start / stop read aloud " aria-hidden="true"><span class="vanilla-reader__fab__icon" id="vanilla-reader__fab-mediaplayer__icon" aria-hidden="true">play_arrow</span></button>`;
    private _onClickCallback: VanillaVoidCallback | undefined;
    private _onVisibleCallback: VanillaVoidCallback | undefined;
    private _onHiddenCallback: VanillaVoidCallback | undefined;
    private _playerButton: HTMLButtonElement;
    private _stateIsPlaying: boolean = false;
    private _playerButtonIcon: HTMLElement;

    constructor(private _hostElement: HTMLElement, insertPosition: InsertPosition = 'afterbegin') {
        this._hostElement.insertAdjacentHTML(insertPosition, this._template);
        this._playerButton = this._hostElement.querySelector('#vanilla-reader__fab-mediaplayer')!;
        this._playerButton.addEventListener('click', (ev: MouseEvent) => {
            ev.preventDefault();
            if (this._onClickCallback) {
                if (this.isVisible) {
                    this._onClickCallback();
                }
            }
        });
        this._playerButtonIcon = this._hostElement.querySelector('#vanilla-reader__fab-mediaplayer__icon')!;
    }

    public get isVisible(): boolean {
        return !this._playerButton.hasAttribute('aria-hidden');
    }

    public get stateIsPlaying(): boolean {
        return this._stateIsPlaying;
    }

    public set stateIsPlaying(isPlaying: boolean) {
        this._stateIsPlaying = isPlaying;
        if (isPlaying) {
            this._playerButtonIcon.innerText = 'pause';
        } else {
            this._playerButtonIcon.innerText = 'play_arrow';
        }
    }

    show() {
        this._playerButton.removeAttribute('aria-hidden');
        this._playerButton.style.display = 'flex';
        this._playerButton.style.opacity = '1';
        this._playerButton.style.pointerEvents = 'all';

        if (this._onVisibleCallback) {
            this._onVisibleCallback();
        }
    }

    hide() {
        this._playerButton.setAttribute('aria-hidden', 'true');
        this._playerButton.style.opacity = '0';
        this._playerButton.style.pointerEvents = 'none';

        setTimeout(()=>{
            this._playerButton.style.display = 'none';
        },200);

        if (this._onHiddenCallback) {
            this._onHiddenCallback();
        }
    }

    getComponentElement(): HTMLElement {
        return this._playerButton;
    }

    onClick(callback: VanillaVoidCallback) {
        this._onClickCallback = callback;
    }

    onVisible(callback: VanillaVoidCallback) {
        this._onVisibleCallback = callback;
    }

    onHidden(callback: VanillaVoidCallback) {
        this._onHiddenCallback = callback;
    }

}