/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.4.0
 */
 'use strict'; (function (s, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-base'], function (a0) { return f(s,a0); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(s,require('./colibrio-core-base')); } else { throw new Error('Unsupported runtime environment!'); } }( typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function (__self,__dep__colibrio_core_base) {  var api = {}; (function () { function h(a){var b=0;return function(){return b<a.length?{done:!1,value:a[b++]}:{done:!0}}}var k="function"==typeof Object.defineProperties?Object.defineProperty:function(a,b,c){if(a==Array.prototype||a==Object.prototype)return a;a[b]=c.value;return a};
function l(a){a=["object"==typeof globalThis&&globalThis,a,"object"==typeof window&&window,"object"==typeof self&&self,"object"==typeof global&&global];for(var b=0;b<a.length;++b){var c=a[b];if(c&&c.Math==Math)return c}throw Error("Cannot find global object");}var m=l(this);function n(a,b){if(b)a:{var c=m;a=a.split(".");for(var d=0;d<a.length-1;d++){var e=a[d];if(!(e in c))break a;c=c[e]}a=a[a.length-1];d=c[a];b=b(d);b!=d&&null!=b&&k(c,a,{configurable:!0,writable:!0,value:b})}}
n("Symbol",function(a){function b(f){if(this instanceof b)throw new TypeError("Symbol is not a constructor");return new c(d+(f||"")+"_"+e++,f)}function c(f,g){this.g=f;k(this,"description",{configurable:!0,writable:!0,value:g})}if(a)return a;c.prototype.toString=function(){return this.g};var d="jscomp_symbol_"+(1E9*Math.random()>>>0)+"_",e=0;return b});
n("Symbol.iterator",function(a){if(a)return a;a=Symbol("Symbol.iterator");for(var b="Array Int8Array Uint8Array Uint8ClampedArray Int16Array Uint16Array Int32Array Uint32Array Float32Array Float64Array".split(" "),c=0;c<b.length;c++){var d=m[b[c]];"function"===typeof d&&"function"!=typeof d.prototype[a]&&k(d.prototype,a,{configurable:!0,writable:!0,value:function(){return p(h(this))}})}return a});function p(a){a={next:a};a[Symbol.iterator]=function(){return this};return a}
var q="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},r;if("function"==typeof Object.setPrototypeOf)r=Object.setPrototypeOf;else{var t;a:{var u={a:!0},v={};try{v.__proto__=u;t=v.a;break a}catch(a){}t=!1}r=t?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var w=r;function x(){this.j=!1;this.g=null;this.m=void 0;this.h=1;this.o=0;this.i=null}
function y(a){if(a.j)throw new TypeError("Generator is already running");a.j=!0}x.prototype.l=function(a){this.m=a};function z(a,b){a.i={s:b,u:!0};a.h=a.o}x.prototype.return=function(a){this.i={return:a};this.h=this.o};function A(a,b){a.h=4;return{value:b}}function B(a){this.g=new x;this.h=a}function C(a,b){y(a.g);var c=a.g.g;if(c)return D(a,"return"in c?c["return"]:function(d){return{value:d,done:!0}},b,a.g.return);a.g.return(b);return E(a)}
function D(a,b,c,d){try{var e=b.call(a.g.g,c);if(!(e instanceof Object))throw new TypeError("Iterator result "+e+" is not an object");if(!e.done)return a.g.j=!1,e;var f=e.value}catch(g){return a.g.g=null,z(a.g,g),E(a)}a.g.g=null;d.call(a.g,f);return E(a)}function E(a){for(;a.g.h;)try{var b=a.h(a.g);if(b)return a.g.j=!1,{value:b.value,done:!1}}catch(c){a.g.m=void 0,z(a.g,c)}a.g.j=!1;if(a.g.i){b=a.g.i;a.g.i=null;if(b.u)throw b.s;return{value:b.return,done:!0}}return{value:void 0,done:!0}}
function F(a){this.next=function(b){y(a.g);a.g.g?b=D(a,a.g.g.next,b,a.g.l):(a.g.l(b),b=E(a));return b};this.throw=function(b){y(a.g);a.g.g?b=D(a,a.g.g["throw"],b,a.g.l):(z(a.g,b),b=E(a));return b};this.return=function(b){return C(a,b)};this[Symbol.iterator]=function(){return this}}function G(a){function b(d){return a.next(d)}function c(d){return a.throw(d)}return new Promise(function(d,e){function f(g){g.done?d(g.value):Promise.resolve(g.value).then(b,c).then(f,e)}f(a.next())})};var H=__dep__colibrio_core_base;function I(a){if(0===a.length)return new Uint8Array(0);if(1===a.length)return a[0];for(var b=0,c=0;c<a.length;c++)b+=a[c].byteLength;b=new Uint8Array(b);for(var d=c=0;d<a.length;d++){var e=a[d];b.set(e,c);c+=e.byteLength}return b}var J=new Uint16Array(1);J[0]=4660;new Uint8Array(J.buffer);function K(a,b){return"data:"+a+";base64,"+b};function L(){this.g=new Set;this.h=null}L.prototype.createUrlForBlob=function(a){a=URL.createObjectURL(a);this.g.add(a);return a};
L.prototype.createUrl=function(a,b,c){if(H.RuntimePlatform.isBlobUrlSupported()&&!c)b=b?new Blob(a,{type:b}):new Blob(a),a=URL.createObjectURL(b),b={blob:b,url:a},this.g.add(a);else{if(a.every(function(Z){return"string"===typeof Z}))a="data:"+b+";charset=UTF-8,"+encodeURIComponent(a.join(""));else{c=[];for(var d=0;d<a.length;d++){var e=a[d];if("string"===typeof e){var f=c,g=f.push;this.h||(this.h=new TextEncoder);e=this.h.encode(e);g.call(f,e)}else e instanceof ArrayBuffer?c.push(new Uint8Array(e)):
e instanceof Uint8Array?c.push(e):c.push(new Uint8Array(e.buffer,e.byteOffset,e.byteLength))}a=K(b,H.Base64.encode(I(c)))}b={blob:null,url:a}}return b};L.prototype.revokeAllUrls=function(){var a=this;this.g.forEach(function(b){return a.revokeUrl(b)});this.g.clear()};L.prototype.revokeUrl=function(a){this.g.delete(a)&&URL.revokeObjectURL(a)};function M(a,b){return H.ColibrioError.call(this,"colibrio.core.io",a,b)||this}var N=H.ColibrioError;M.prototype=q(N.prototype);M.prototype.constructor=M;if(w)w(M,N);else for(var O in N)if("prototype"!=O)if(Object.defineProperties){var P=Object.getOwnPropertyDescriptor(N,O);P&&Object.defineProperty(M,O,P)}else M[O]=N[O];function Q(a,b){this.i=a;this.h=b;this.g=null}Q.prototype.abort=function(){};Q.prototype.asText=function(){return Promise.reject(this.h)};Q.prototype.asUint8Array=function(){return Promise.reject(this.h)};Q.prototype.getMetadata=function(){return this.i};Q.prototype.getReadableStream=function(){var a=this;this.g||(this.g=new ReadableStream({start:function(b){b.error(a.h)}}));return this.g};function R(){this.h=this.g=void 0;this.i=new TextDecoder("utf-8")}var S;function T(){S||(S=new R);return S}R.prototype.decode=function(a){return 2<=a.length&&254===a[0]&&255===a[1]&&(void 0===this.g&&(this.g=U("utf-16be")),this.g)?this.g.decode(a):2<=a.length&&255===a[0]&&254===a[1]&&(void 0===this.h&&(this.h=U("utf-16le")),this.h)?this.h.decode(a):this.i.decode(a)};function U(a){try{return new TextDecoder(a)}catch(b){return console.error(b),null}};function V(a,b,c){this.i=a;this.h=b;this.j=c;this.g=!1}V.prototype.getMetadata=function(){return this.i};V.prototype.abort=function(){if(!this.g){this.g=!0;var a;null==(a=this.j)||a.call(this)}};V.prototype.asText=function(){return W(this).then(function(a){return T().decode(a)})};V.prototype.asUint8Array=function(){return W(this)};V.prototype.getReadableStream=function(){return this.h};
function W(a){var b,c,d,e;return G(new F(new B(function(f){switch(f.h){case 1:b=a.h.getReader(),c=[],d=!1;case 2:if(d){f.h=3;break}return A(f,b.read());case 4:e=f.m;if(a.g)throw b.cancel().catch(function(g){H.ColibrioError.isColibrioAbortedError(g)||H.Logger.logWarning(g)}),new M(H.ColibrioErrorType.ABORTED);e.value&&c.push(e.value);d=e.done;f.h=2;break;case 3:return f.return(I(c))}})))};function X(a,b){this.i=a;this.h=null;this.g=b}X.prototype.abort=function(){this.g&&(this.g=null)};X.prototype.asText=function(){if(!this.g)return Promise.reject(new M(H.ColibrioErrorType.ABORTED));var a=T().decode(I(this.g));return Promise.resolve(a)};X.prototype.asUint8Array=function(){return this.g?Promise.resolve(I(this.g)):Promise.reject(new M(H.ColibrioErrorType.ABORTED))};X.prototype.getMetadata=function(){return this.i};
X.prototype.getReadableStream=function(){var a=this;if(!this.h){var b=0;this.h=new ReadableStream({cancel:function(){a.g=null},pull:function(c){a.g?(b<a.g.length&&c.enqueue(a.g[b++]),b===a.g.length&&(a.g=null,c.close())):c.error(new M(H.ColibrioErrorType.ABORTED))}})}return this.h};api={BlobUrlManager:L,Endianness:{BIG_ENDIAN:"BIG_ENDIAN",LITTLE_ENDIAN:"LITTLE_ENDIAN"},ErrorResourceResponse:Q,IoError:M,IoErrorType:{BYTE_RANGES_NOT_SUPPORTED:"BYTE_RANGES_NOT_SUPPORTED",INVALID_BYTE_RANGE:"INVALID_BYTE_RANGE",READABLE_STREAM_ALREADY_LOCKED:"READABLE_STREAM_ALREADY_LOCKED",RESOURCE_NOT_FOUND:"RESOURCE_NOT_FOUND",RESOURCE_ENCRYPTED:"RESOURCE_ENCRYPTED",RESOURCE_BODY_LOCKED:"RESOURCE_BODY_LOCKED"},MediaType:{APPLICATION_EPUB_ZIP:"application/epub+zip",APPLICATION_HTML:"application/html",
APPLICATION_LPF_ZIP:"application/lpf+zip",APPLICATION_NCX:"application/x-dtbncx+xml",APPLICATION_OPF_PACKAGE:"application/oebps-package+xml",APPLICATION_PDF:"application/pdf",APPLICATION_SMIL:"application/smil+xml",APPLICATION_XHTML:"application/xhtml+xml",APPLICATION_VND_MS_OPENTYPE:"application/vnd.ms-opentype",APPLICATION_XML:"application/xml",APPLICATION_ZIP:"application/zip",AUDIO_AAC:"audio/aac",AUDIO_MPEG:"audio/mpeg",AUDIO_MP4:"audio/mp4",AUDIO_OGG:"audio/ogg",AUDIO_WEBM:"audio/webm",AUDIO_WAV:"audio/wav",
AUDIO_WAVE:"audio/wave",FONT_OTF:"font/opentype",FONT_TTF:"font/truetype",FONT_WOFF:"font/woff",FONT_WOFF2:"font/woff2",IMAGE_BMP:"image/bmp",IMAGE_PNG:"image/png",IMAGE_JPEG:"image/jpeg",IMAGE_SVG:"image/svg+xml",IMAGE_GIF:"image/gif",TEXT_CSS:"text/css",TEXT_JAVASCRIPT:"text/javascript",TEXT_HTML:"text/html",VIDEO_MP4:"video/mp4",VIDEO_WEBM:"video/webm"},MediaTypeCategory:{FONT:"font/*",IMAGE:"image/*",VIDEO:"video/*",AUDIO:"audio/*"},ReadableStreamResourceResponse:V,Uint8ArrayResourceResponse:X};var Y=new ArrayBuffer(8);new DataView(Y);new Uint8Array(Y); }).call(__self); return api; } ) );
