/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import * as colibrioCoreBase from '@colibrio/colibrio-reader-framework/colibrio-core-base';
import {MediaType} from '@colibrio/colibrio-reader-framework/colibrio-core-io-base';
import * as colibrioCoreLocator from '@colibrio/colibrio-reader-framework/colibrio-core-locator';
import {ILocator} from '@colibrio/colibrio-reader-framework/colibrio-core-locator';
import {
    ContentPositionTimelineUnit,
    IContentLocation,
    IContentPositionTimeline,
    IPageProgressionTimeline,
    IPageProgressionTimelinePositionData,
    IReaderPublication,
    IUnresolvedContentLocation,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';
import {
    EpubContentPositionTimelineOptions,
    IEpubReaderPublication,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-formatadapter-epub';
import {IPdfReaderPublication} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-formatadapter-pdf';

import {
    VanillaReaderAppEvents,
    VanillaReaderEventBus,
} from './VanillaReaderEventBus';
import {
    IVanillaReaderReadingProgressionTimeline,
    VanillaProgressEventCallback,
    VanillaTimelineReadyEventCallback
} from './VanillaReaderModel';
import {VanillaReaderPublication} from './VanillaReaderPublication';
import {VanillaReaderView} from './VanillaReaderView';

/*
* # VanillaReaderProgressionTimeline
*
* ## RESPONSIBILITIES
*
* This class is an high level abstraction atop the Colibrio Reader Framework's `IContentPositionTimeline` or IPageProgressionTimeline.
* It sets up relevant events and exposes methods to add event callbacks.
*
* ## PRIMARY COLIBRIO FRAMEWORK TYPES
*
* - IReaderPublication
* - IContentPositionTimeline
* - IPageProgressionTimeline
*
* ## NOTES:
*
* For publications that are very long or very complex, this it can take a while to build the timeline.
* To make it possible for you to design a good user experience around this, the constructor takes in a progress callback
* which is called continuously with a number between 0 and 1 indication the current state of the creation process.
*
* */

export class VanillaReaderProgressionTimeline implements IVanillaReaderReadingProgressionTimeline {
    private _colibrioTimelineInstance!: IContentPositionTimeline | IPageProgressionTimeline;
    private _colibrioReaderPublication: IReaderPublication;
    private _timelineReadyCallback: VanillaTimelineReadyEventCallback | undefined = undefined;
    private _timelineUpdateIntentCallback: VanillaProgressEventCallback | undefined = undefined;
    private _usePageProgressiontimeLine: boolean = false;

    constructor(
        private _vanillaReaderView: VanillaReaderView,
        private _vanillaPublication: VanillaReaderPublication,
        private _initialState: string | undefined = undefined,
        private _buildProgressCallback: (progress: number) => void,
    ) {
        this._colibrioReaderPublication = this._vanillaPublication.getColibrioReaderPublication();

        this._setup().catch(console.warn);
    }

    private async _setup() {
        if (!this._usePageProgressiontimeLine) {
            console.log('VanillaReaderProgressionTimeline', 'Using ContentPositionTimeline');

            await this._vanillaReaderView.setVanillaReaderOptions({
                colibrioReaderViewOptions: {
                    pageProgressionTimelineOptions: {
                        enabled: false,
                    },
                },
            });

            this._buildTimeline(this._colibrioReaderPublication, this._initialState, this._buildProgressCallback).then((timeline: IContentPositionTimeline) => {
                this._colibrioTimelineInstance = timeline;

                if (this._timelineReadyCallback) {
                    this._timelineReadyCallback(timeline.getLength());
                }
            }).catch(console.error);

        } else {
            console.log('VanillaReaderProgressionTimeline', 'Using PageProgressionTimeline');

            await this._vanillaReaderView.setVanillaReaderOptions({
                colibrioReaderViewOptions: {
                    pageProgressionTimelineOptions: {
                        enabled: true,
                        delayRecalculationUntilPagesVisible: true,
                    },
                },
            });

            const timelineCallback = () => {
                this._colibrioTimelineInstance = this._vanillaReaderView.getPageProgressionTimeline() as IPageProgressionTimeline;

                if (this._timelineReadyCallback) {
                    this._timelineReadyCallback(this.getLength());
                }
            };

            this._vanillaReaderView.onPageProgressionTimelineRecalculated(timelineCallback);

        }
    }

    onTimelineReady(callback: VanillaTimelineReadyEventCallback) {
        this._timelineReadyCallback = callback;
    }

    onTimelineUpdateIntent(callback: VanillaProgressEventCallback) {
        this._timelineUpdateIntentCallback = callback;
    }

    isReady(): boolean {
        return this._colibrioTimelineInstance !== null && this._colibrioTimelineInstance !== undefined;
    }

    async fetchContentLocation(timelinePosition: number): Promise<IContentLocation | undefined> {
        if (!this._colibrioTimelineInstance) {
            return undefined;
        }

        if (this._isContentPositionTimeline(this._colibrioTimelineInstance)) {
            return this._colibrioTimelineInstance?.fetchContentLocation(timelinePosition);
        } else {
            let positionData: IPageProgressionTimelinePositionData = {
                offset: 0,
                pageIndex: timelinePosition,
            };
            let locator = await this._colibrioTimelineInstance.fetchLocatorFromPosition(positionData);
            return this._colibrioReaderPublication.fetchContentLocation(locator);
        }

    }

    async fetchTimelinePosition(location: colibrioCoreLocator.ILocator): Promise<number | undefined> {
        if (!this._colibrioTimelineInstance) {
            return undefined;
        }

        if (this._isContentPositionTimeline(this._colibrioTimelineInstance)) {
            return this._colibrioTimelineInstance?.fetchTimelinePosition(location);
        } else {
            let locator = location as ILocator;
            let position = await this._colibrioTimelineInstance.fetchPositionFromLocator(locator);
            return position.pageIndex;
        }
    }

    async fetchTimelineRange(location: colibrioCoreLocator.ILocator | colibrioCoreLocator.ILocatorData | IContentLocation | IUnresolvedContentLocation): Promise<colibrioCoreBase.IIntegerRange | undefined> {
        if (this._isContentPositionTimeline(this._colibrioTimelineInstance)) {
            return this._colibrioTimelineInstance?.fetchTimelineRange(location);
        } else {
            return undefined;
        }
    }

    async fetchContentLocationAsRange(start: number, end: number): Promise<IContentLocation | undefined> {
        if (this._isContentPositionTimeline(this._colibrioTimelineInstance)) {
            return this._colibrioTimelineInstance.fetchContentLocationAsRange(start, end);
        } else {
            return undefined;
        }
    }

    getLength(): number {
        if (this._isContentPositionTimeline(this._colibrioTimelineInstance)) {
            return this._colibrioTimelineInstance.getLength() || 0;
        } else {
            return this._colibrioTimelineInstance.getTotalNumberOfPages();
        }
    }

    updateTimelinePosition(locator: ILocator) {

        const updateFn = async () => {
            if (this._colibrioTimelineInstance && locator) {
                let position = await this.fetchTimelinePosition(locator);
                if (this._timelineUpdateIntentCallback && position) {
                    this._timelineUpdateIntentCallback(position);
                }
            }
        };

        if (!this._colibrioTimelineInstance) {
            VanillaReaderEventBus.addEventListener(VanillaReaderAppEvents.TIMELINE_READY, updateFn, true);
        } else {
            updateFn().catch(console.error);
        }

    }

    getBackingTimelineImplementation(): IContentPositionTimeline | IPageProgressionTimeline {
        return this._colibrioTimelineInstance;
    }

    toSerializedData(): string | undefined {
        if (this._isContentPositionTimeline(this._colibrioTimelineInstance)) {
            return (this._colibrioTimelineInstance as IContentPositionTimeline).toSerializedData();
        } else {
            return undefined;
        }
    }

    /*
    *
    * PRIVATE METHODS
    *
    * */

    private async _buildTimeline(
        readerPublication: IReaderPublication,
        state: string | undefined,
        callback: (progress: number) => void,
    ): Promise<IContentPositionTimeline> {

        let timelinePromise: Promise<IContentPositionTimeline>;

        if (readerPublication.getSourcePublication().getMediaType() === MediaType.APPLICATION_EPUB_ZIP) {

            const epubReaderPublication: IEpubReaderPublication = readerPublication as IEpubReaderPublication;
            let timelineOptions: EpubContentPositionTimelineOptions;

            if (epubReaderPublication.getAvailableContentPositionTimelineUnits().includes(ContentPositionTimelineUnit.PAGES)) {
                timelineOptions = {
                    unit: ContentPositionTimelineUnit.PAGES,
                };
            } else {
                timelineOptions = {
                    unit: ContentPositionTimelineUnit.WORDS,
                };
            }

            timelineOptions.serializedData = state;

            timelinePromise = epubReaderPublication.createContentPositionTimeline(epubReaderPublication.getSpine(), timelineOptions, callback);

        } else {
            const pdfReaderPublication: IPdfReaderPublication = readerPublication as IPdfReaderPublication;

            let timelineOptions: EpubContentPositionTimelineOptions = {
                unit: ContentPositionTimelineUnit.PAGES,
                serializedData: state,
            };
            timelinePromise = pdfReaderPublication.createContentPositionTimeline(pdfReaderPublication.getSpine(), timelineOptions, callback);

        }

        return timelinePromise.then((timeline): IContentPositionTimeline => {
            return timeline;
        });
    }

    private _isContentPositionTimeline(_timeline: IContentPositionTimeline | IPageProgressionTimeline): _timeline is IContentPositionTimeline {
        return !this._usePageProgressiontimeLine;
    }
}
