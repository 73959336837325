/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IReaderPublication} from "@colibrio/colibrio-reader-framework/colibrio-readingsystem-base";
import {IEpubReaderPublication} from "@colibrio/colibrio-reader-framework/colibrio-readingsystem-formatadapter-epub";
import {MediaType} from "@colibrio/colibrio-reader-framework/colibrio-core-io-base";
import {IPdfReaderPublication} from "@colibrio/colibrio-reader-framework/colibrio-readingsystem-formatadapter-pdf";

export class VanillaReaderUtils {
    static isEpubReaderPublication(readerPublication: IReaderPublication): readerPublication is IEpubReaderPublication {
        return readerPublication.getSourcePublication().getMediaType() === MediaType.APPLICATION_EPUB_ZIP;
    }

    static isPdfReaderPublication(readerPublication: IReaderPublication): readerPublication is IPdfReaderPublication {
        return readerPublication.getSourcePublication().getMediaType() === MediaType.APPLICATION_PDF;
    }

}
