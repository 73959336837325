/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IntRange} from "./IntRange";

export class IntSequence {
    private sequence: IntRange[] = [];

    constructor(private length: number) {
        this.length = Math.round(length);
    }

    add(entry: number) {

        if(entry > this.length) {
            throw new Error("Entry can not exceed IntSequence length.")
        }

        entry = Math.round(entry);
        this.sequence.push(new IntRange(entry, entry));
        this.sequence.sort(this.sorter);
        this.merge();
    }

    addRange(start: number, end: number) {
        if(end > this.length) {
            throw new Error("Range end can not exceed IntSequence length.")
        }

        start = Math.round(start);
        end = Math.round(end);
        this.sequence.push(new IntRange(start, end));
        this.sequence.sort(this.sorter);
        this.merge();
    }

    getEmptySlots(): IntRange[] {
        let i = 0;
        let emptySlots: IntRange[] = [];

        let firstEntry = this.sequence[0];
        if(firstEntry.start > 0) {
            emptySlots.push(new IntRange(0, firstEntry.start));
        }

        while(this.sequence.length > i + 1) {
            let lowerEntry = this.sequence[i];
            let higherEntry = this.sequence[i + 1];

            if(higherEntry.start - lowerEntry.end > 1) {
                emptySlots.push(new IntRange(lowerEntry.end, higherEntry.start));
            }
            i++;
        }

        let lastEntry = this.sequence[this.sequence.length - 1];

        if(lastEntry.end < this.length) {
            emptySlots.push(new IntRange(lastEntry.end, this.length));
        }

        return emptySlots;
    }

    getEntries(): IntRange[] {
        return this.sequence;
    }

    /**
     *
     * Note, array must be sorted.
     *
     * */
    private merge() {
        let i = 0;

        while(this.sequence.length > i + 1) {

            let lowerEntry = this.sequence[i];
            let lowerIndex = i;
            let higherIndex = i + 1;

            let higherEntry = this.sequence[higherIndex];

            if(lowerEntry.intersects(higherEntry) || lowerEntry.isAdjancent(higherEntry)) {
                let mergedEntry = lowerEntry.merge(higherEntry);
                this.sequence.splice(lowerIndex, 2, mergedEntry);
            } else {
                i++;
            }
        }
    }
    
    private sorter = (entry: IntRange, entry2: IntRange) => {
        if(entry.start < entry2.start) {
            return -1;
        }
        if(entry.start > entry2.start) {
            return 1;
        }
        return 0;
    }
}
