/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.4.0
 */
 'use strict'; (function (s, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-base','./colibrio-core-io-base'], function (a0,a1) { return f(s,a0,a1); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(s,require('./colibrio-core-base'),require('./colibrio-core-io-base')); } else { throw new Error('Unsupported runtime environment!'); } }( typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function (__self,__dep__colibrio_core_base,__dep__colibrio_core_io_base) {  var api = {}; (function () { api={ContentDocumentFlowMode:{PAGINATED:"PAGINATED",SCROLLED_CONTINUOUS:"SCROLLED_CONTINUOUS",SCROLLED_DOC:"SCROLLED_DOC",AUTO:"AUTO"},ContentDocumentLayout:{REFLOWABLE:"REFLOWABLE",PRE_PAGINATED:"PRE_PAGINATED"},PageProgressionDirection:{LTR:"LTR",RTL:"RTL",AUTO:"AUTO"},PageSpreadSlot:{LEFT:"LEFT",RIGHT:"RIGHT",CENTER:"CENTER",AUTO:"AUTO"},PublicationMetadataType:{EPUB_PUBLICATION_METADATA:"EPUB_PUBLICATION_METADATA",PUBLICATION_METADATA:"PUBLICATION_METADATA",PDF_PUBLICATION_METADATA:"PDF_PUBLICATION_METADATA",
WP_PUBLICATION_METADATA:"WP_PUBLICATION_METADATA"},PublicationOrientation:{LANDSCAPE:"LANDSCAPE",PORTRAIT:"PORTRAIT",AUTO:"AUTO"},PublicationType:{EPUB:"EPUB",PDF:"PDF",WP:"WP"},SyntheticSpreadBehavior:{NONE:"NONE",LANDSCAPE:"LANDSCAPE",BOTH:"BOTH",AUTO:"AUTO"}}; }).call(__self); return api; } ) );
