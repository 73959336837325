/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import { LengthUnit } from '@colibrio/colibrio-reader-framework/colibrio-core-base';
import { ILocator, ILocatorData } from '@colibrio/colibrio-reader-framework/colibrio-core-locator';
import {
    ContentBlockTargetFetchMode,
    CustomReaderViewContentLayout,
    IContentBlockTarget,
    IContentLocation,
    IFetchNavigationItemReferencesResult,
    IFocusOnReadingPositionOptions,
    IPageProgressionTimeline,
    IReaderDocument,
    IReaderPublication,
    IReaderPublicationNavigationItemReference,
    IReaderView,
    IReaderViewAnnotationMouseEngineEvent,
    IReaderViewGotoOptions, IReaderViewTransformManager,
    IRenderer, ITransformData,
    IUnresolvedContentLocation,
    NavigationCollectionType,
    ReaderViewGestureType,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';
import { ResponsiveViewRule } from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-engine';
import {
    FlipBookRenderer,
    IPaginatedRendererOptions,
    SingleDocumentScrollRenderer,
    SinglePageSwipeRenderer,
    SpreadSwipeRenderer,
    StackRenderer,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-renderer';
import { IVanillaReaderOptionsDataStore } from '../VanillaReaderDataStore/IVanillaReaderOptionsDataStore';
import { VanillaReaderAnnotationLayerBookmarks } from './VanillaReaderAnnotationLayerBookmarks';
import { VanillaReaderAnnotationLayerHighlights } from './VanillaReaderAnnotationLayerHighlights';
import {
    IVanillaReaderAppEvent_BOOKMARK_CLICKED,
    IVanillaReaderAppEvent_HIGHLIGHT_CLICKED,
    IVanillaReaderAppEvent_HIGHLIGHT_IN_VISIBLE_RANGE,
    IVanillaReaderAppEvent_HIGHLIGHT_OUTSIDE_VISIBLE_RANGE,
    VanillaReaderAppEvents,
    VanillaReaderEventBus,
} from './VanillaReaderEventBus';
import {
    IVanillaReaderBookmarkData,
    IVanillaReaderHighlightData,
    IVanillaReaderNavigationItem,
    IVanillaReaderReadingPositionData,
    IVanillaReaderReadingProgressionTimeline,
    IVanillaReaderViewOptions,
    IVanillaReaderVisibleRangeData,
    VanillaActiveRendererChangedEventCallback,
    VanillaBooleanValueCallback,
    VanillaPageProgressionTimelineRecalculatedCallback,
    VanillaReaderRendererNames, VanillaReaderViewEventCallback,
    VanillaReaderViewOptionsCallback, VanillaVoidCallback,
} from './VanillaReaderModel';
import { VanillaReaderNavigationTree } from './VanillaReaderNavigationTree';
import { VanillaReaderPublication } from './VanillaReaderPublication';
import { VanillaReaderReadingStats } from './VanillaReaderReadingStats';
import { VanillaReaderReadingSystem } from './VanillaReaderReadingSystem';

export class VanillaReaderView {

    highlightsLayer: VanillaReaderAnnotationLayerHighlights | undefined;
    bookmarksLayer: VanillaReaderAnnotationLayerBookmarks | undefined;
    readingStats: VanillaReaderReadingStats | undefined;

    private _colibrioReaderView: IReaderView;
    private _useReducedMotion: boolean = false;
    private _ignoreAspectRatio: boolean = false;
    private _initialVanillaReaderViewOptions: IVanillaReaderViewOptions | undefined;
    private _defaultAnimationDuration: number = 1;

    private _onReaderViewCreatedCallback: VanillaReaderViewOptionsCallback | undefined;
    private _onReducedMotionValueChangedCallback: VanillaBooleanValueCallback | undefined;
    private _onIgnoreAspectRatioValueChangedCallback: VanillaBooleanValueCallback | undefined;
    private _onActiveRendererChangedCallback: VanillaActiveRendererChangedEventCallback | undefined;
    private _onRendererTransitionStartedCallback: VanillaReaderViewEventCallback | undefined;
    private _onRendererTransitionEndedCallback: VanillaReaderViewEventCallback | undefined;
    private _onRendererScrollStartedCallback: VanillaReaderViewEventCallback | undefined;
    private _onRendererScrollEndedCallback: VanillaReaderViewEventCallback | undefined;
    private _onPageProgressionTimelineRecalculatedCallback: VanillaPageProgressionTimelineRecalculatedCallback | undefined;

    constructor(
        private _readingSystem: VanillaReaderReadingSystem,
        private _vanillaReaderPublication: VanillaReaderPublication,
        private _appElement: HTMLElement,
        private _vanillaReaderOptionsDataStore: IVanillaReaderOptionsDataStore,
        private _customContentOnLoading?: string,
        private _customContentOnLoadError?: string,
    ) {
        this._colibrioReaderView = this._readingSystem.createReaderView({});
        // Now let's set the reader documents to the Colibrio ReaderView instance. Note that this needs to be done in the
        // sync constructor in order for the calling code to execute correctly.
        this._colibrioReaderView.setReaderDocuments(this._vanillaReaderPublication.getColibrioReaderPublication().getSpine());

        // Now we need to do some asynchronous set up so we call the async _setup() method.
        this._setup().catch(console.warn);
    }

    private async _setup() {

        // Let's get the current options data from the store. If we find nothing in the store, we set the initial
        // options from the defaults provided by the `getOptions` method.
        let initialOptionsData = await this._vanillaReaderOptionsDataStore.fetchVanillaReaderOptionsData();
        this._initialVanillaReaderViewOptions = initialOptionsData?.viewOptions ?
            initialOptionsData.viewOptions : this.getCurrentVanillaReaderViewOptionsState();

        this._createRenderers(this._colibrioReaderView);

        // Add custom content to be shown while the reader view is loading new content.
        this._colibrioReaderView.setContentOnLoading({
            layout: CustomReaderViewContentLayout.FLEX_CENTER,
            renderTo: (containerElement: HTMLElement) => {
                containerElement.innerHTML = this._customContentOnLoading || 'loading...';
            },
        });

        // Add custom content to be shown if a loading error occurs.
        this._colibrioReaderView.setContentOnLoadError({
            layout: CustomReaderViewContentLayout.FLEX_CENTER,
            renderTo: (containerElement: HTMLElement) => {
                containerElement.innerHTML = this._customContentOnLoadError || 'An error occurred';
            },
        });

        // We check the this._initialVanillaReaderViewOptions object to see if the user has a saved preference for which renderer to use. This
        // will override the renderers added above by explicitly calling `setActiveRenderer()` and their respective
        // `ResponsiveViewRules`.
        if (this._initialVanillaReaderViewOptions && this._initialVanillaReaderViewOptions?.activeRendererTypeName) {

            // We have a option in the `VanillaReaderRendererNames` called `RESPONSIVE`. This option is not really the
            // name of a renderer instance, but is a flag that we should let the `IReaderView` select a renderer for us.
            if (this._initialVanillaReaderViewOptions?.activeRendererTypeName !== VanillaReaderRendererNames.RESPONSIVE) {

                // Since the this._initialVanillaReaderViewOptions has supplied us with a renderer name that does not match `VanillaReaderRendererNames.RESPONSIVE`
                // we can tell the reader view that we will handle selection of renderers manually.
                this._colibrioReaderView.setResponsiveRendererSelectionEnabled(false);

                let newActiveRenderer = this._colibrioReaderView.getRendererByName(this._initialVanillaReaderViewOptions?.activeRendererTypeName);
                if (newActiveRenderer) {
                    this._colibrioReaderView.setActiveRenderer(newActiveRenderer);
                } else {
                    console.warn('VanillaReader.createReaderView()', 'Unable to set new active renderer "' + this._initialVanillaReaderViewOptions?.activeRendererTypeName + '" from this._initialVanillaReaderViewOptions?.');
                }

            } else {

                // The this._initialVanillaReaderViewOptions has supplied us with a renderer name that matches `VanillaReaderRendererNames.RESPONSIVE`.
                // Let's tell the reader view that it should use the `ResponsiveViewRules` to determine the best renderer
                // to use.
                this._colibrioReaderView.setResponsiveRendererSelectionEnabled(true);

            }
        }

        // Now that we have things set up we can finally renderer the Colibrio ReaderView to the HTML element that was
        // passed in to the constructor
        this._colibrioReaderView.renderTo(this._appElement);

        // Add some event listeners to keep track of transition events, such as page turn animation.
        this._colibrioReaderView.addEngineEventListener('rendererTransitionStarted', (evt) => {
            if (this._onRendererTransitionStartedCallback) {
                this._onRendererTransitionStartedCallback(evt);
            }
        });
        this._colibrioReaderView.addEngineEventListener('rendererTransitionEnded', (evt) => {
            if (this._onRendererTransitionEndedCallback) {
                this._onRendererTransitionEndedCallback(evt);
            }
        });
        this._colibrioReaderView.addEngineEventListener('rendererScrollStarted', (evt) => {
            if (this._onRendererScrollStartedCallback) {
                this._onRendererScrollStartedCallback(evt);
            }
        });
        this._colibrioReaderView.addEngineEventListener('rendererScrollEnded', (evt) => {
            if (this._onRendererScrollEndedCallback) {
                this._onRendererScrollEndedCallback(evt);
            }
        });
        this._colibrioReaderView.addEngineEventListener('activeRendererChanged', async (evt) => {
            if (this._onActiveRendererChangedCallback) {
                this._onActiveRendererChangedCallback(evt);
            }
            await this._persistCurrentVanillaReaderOptionsState().catch(console.warn);
        });

        this._colibrioReaderView.addEngineEventListener('pageProgressionTimelineRecalculated', (evt) => {

            if (this._onPageProgressionTimelineRecalculatedCallback) {
                this._onPageProgressionTimelineRecalculatedCallback(evt);
            }
        });

        // Here we create the Annotation Layer that will render all the highlights that the user has added to the book.
        this.highlightsLayer = new VanillaReaderAnnotationLayerHighlights('highlights', 'yellow', this._colibrioReaderView);
        this._setupHighlightLayerCallbacks(this.highlightsLayer);

        // And here we create the Annotation Layer that will render all the bookmarks that the user has added to the book.
        this.bookmarksLayer = new VanillaReaderAnnotationLayerBookmarks('bookmarks', this._colibrioReaderView);
        this._setupBookmarkLayerCallbacks(this.bookmarksLayer);

        // And then we create our simple reading stats instance.
        this.readingStats = new VanillaReaderReadingStats();

        if (this._onReaderViewCreatedCallback) {
            let options = this.getCurrentVanillaReaderViewOptionsState();
            this._onReaderViewCreatedCallback(options);
        }
        await this.setVanillaReaderOptions(this._initialVanillaReaderViewOptions);

    }

    onReducedMotionValueChanged(callback: VanillaBooleanValueCallback) {
        this._onReducedMotionValueChangedCallback = callback;
    }

    onIgnoreAspectRatioValueChanged(callback: VanillaBooleanValueCallback) {
        this._onIgnoreAspectRatioValueChangedCallback = callback;
    }

    onReaderViewCreated(callback: VanillaVoidCallback) {
        this._onReaderViewCreatedCallback = callback;
    }

    onActiveRendererChanged(callback: VanillaActiveRendererChangedEventCallback) {
        this._onActiveRendererChangedCallback = callback;
    }

    onRendererTransitionStarted(callback: VanillaReaderViewEventCallback) {
        this._onRendererTransitionStartedCallback = callback;
    }

    onRendererTransitionEnded(callback: VanillaReaderViewEventCallback) {
        this._onRendererTransitionEndedCallback = callback;
    }

    onRendererScrollStarted(callback: VanillaReaderViewEventCallback) {
        this._onRendererScrollStartedCallback = callback;
    }

    onRendererScrollEnded(callback: VanillaReaderViewEventCallback) {
        this._onRendererScrollEndedCallback = callback;
    }

    onPageProgressionTimelineRecalculated(callback: VanillaPageProgressionTimelineRecalculatedCallback) {

        this._onPageProgressionTimelineRecalculatedCallback = callback;
    }

    public get colibrioReaderView(): IReaderView {
        return this._colibrioReaderView;
    }

    setReaderDocuments(documents: IReaderDocument[]) {
        this._colibrioReaderView.setReaderDocuments(documents);
    }

    getActiveRenderer(): IRenderer | null {
        return this._colibrioReaderView.getActiveRenderer();
    }

    canPerformPrevious(): boolean {
        return this._colibrioReaderView.canPerformPrevious();
    }

    canPerformNext(): boolean {
        return this._colibrioReaderView.canPerformNext();
    }

    canPerformGoTo(): boolean {
        return this._colibrioReaderView.canPerformGoTo();
    }

    previous(): Promise<void> {

        return this._colibrioReaderView.previous()

    }

    next(): Promise<void> {

        return this._colibrioReaderView.next()

    }

    goTo(
        location: ILocator | ILocatorData | IUnresolvedContentLocation | IContentLocation,
        options?: IReaderViewGotoOptions,
    ): Promise<void> | undefined {
        return this._colibrioReaderView.goTo(location, options);
    }

    goToStart(): Promise<void> | undefined {
        return this._colibrioReaderView.goToStart();
    }

    isAtStart(): boolean {
        return this._colibrioReaderView.isAtStart();
    }

    isAtEnd(): boolean {
        return this._colibrioReaderView.isAtEnd();
    }

    isResponsiveRendererSelectionEnabled(): boolean {
        return this._colibrioReaderView.isResponsiveRendererSelectionEnabled();
    }

    getReadingPosition(): IContentLocation | null {
        return this._colibrioReaderView.getReadingPosition();
    }

    focusOnReadingPosition(options: IFocusOnReadingPositionOptions): Promise<void> {
        return this._colibrioReaderView.focusOnReadingPosition(options).catch(() => {
            console.log('VanillaReaderView.focusOnReadingPosition() failed');
        });
    }

    getCurrentVanillaReaderViewOptionsState(): IVanillaReaderViewOptions {
        let publicationStyleOptions = this.colibrioReaderView.getOptions().publicationStyleOptions;
        return {
            colibrioReaderViewOptions: {
                publicationStyleOptions
            },
            ignoreAspectRatio: this.ignoreAspectRatio,
            useReducedMotion: this.useReducedMotion,
            activeRendererTypeName: this.isResponsiveRendererSelectionEnabled() ?
                VanillaReaderRendererNames.RESPONSIVE :
                this.getActiveRenderer()?.getName() as VanillaReaderRendererNames,
            colibrioRendererOptions: this.colibrioReaderView.getActiveRenderer()?.getOptions(),

        };
    }

    async setVanillaReaderOptions(viewOptions: IVanillaReaderViewOptions) {
        if (viewOptions.colibrioReaderViewOptions) {
            this.colibrioReaderView.setOptions(viewOptions.colibrioReaderViewOptions);
        }
        if (viewOptions.colibrioRendererOptions) {
            this.colibrioReaderView.getActiveRenderer()?.setOptions(viewOptions.colibrioRendererOptions);
        }
        if (viewOptions.useReducedMotion !== undefined) {
            this.useReducedMotion = viewOptions.useReducedMotion;
        }
        if (viewOptions.ignoreAspectRatio !== undefined) {
            this.ignoreAspectRatio = viewOptions.ignoreAspectRatio;
        }

        await this._persistCurrentVanillaReaderOptionsState().catch(console.warn);

    }

    getColibrioReaderPublication(): IReaderPublication {
        return this._colibrioReaderView.getReaderPublications()[0];
    }

    getVanillaReaderPublication(): VanillaReaderPublication {
        return this._vanillaReaderPublication;
    }

    getVisibleRange(): IContentLocation | null {
        return this._colibrioReaderView.getVisibleRange();
    }

    getPageProgressionTimeline(): IPageProgressionTimeline | null {
        return this._colibrioReaderView.getPageProgressionTimeline();
    }

    setAllowedGestureTypes(gestureTypes: ReaderViewGestureType[]) {
        this._colibrioReaderView.setAllowedGestureTypes(gestureTypes);
    }

    setContentSelection(enabled: boolean) {
        this._colibrioReaderView.setContentSelectionEnabled(enabled);
    }

    async fetchVisibleRangeData(
        navigationTree?: VanillaReaderNavigationTree,
        readingProgressionTimeline?: IVanillaReaderReadingProgressionTimeline,
    ): Promise<IVanillaReaderVisibleRangeData | undefined> {


        const visibleRange = this._colibrioReaderView?.getVisibleRange();
        let locator = visibleRange?.getLocator();

        if (!visibleRange || !locator) {
            return undefined;
        }
        let readingProgression: number | undefined;
        let rangeTimelinePosition: number | undefined;

        if (readingProgressionTimeline) {
            rangeTimelinePosition = await readingProgressionTimeline.fetchTimelinePosition(visibleRange.collapseToStart().getLocator());
            readingProgression = rangeTimelinePosition ?
                rangeTimelinePosition / readingProgressionTimeline.getLength() :
                undefined;
        }

        let navItems: IVanillaReaderNavigationItem[] = [];

        let navItemRefs = await navigationTree?.fetchItemsInVisibleRange([
            NavigationCollectionType.TOC,
            NavigationCollectionType.PAGE_LIST,
            NavigationCollectionType.LANDMARKS,
        ], true, this);

        navItemRefs?.forEach((ref: IReaderPublicationNavigationItemReference) => {
            // alert("-33");
            let timelineStartPosition: number | undefined;
            navItems.push({
                locator: ref.getNavigationItem()?.getLocator()?.toString(),
                children: [],
                timelineStartPosition,
                collectionType: ref.getNavigationCollection().getType(),
                title: ref.getNavigationItem().getTextContent(),
            });

        });

        // Check if the start / of the visible range is at the start or end of the reader document, and if so send
        // events.

        // If the visible range has changed while we were fetching the ContentLocation we'll get a new event with more up-to-date data, so we can just return here.
        if (visibleRange !== this._colibrioReaderView?.getVisibleRange()) {
            return undefined;
        }

        let publicationIsAtStart = await this.visibleRangeIsAtStartOfPublication();
        let publicationIsAtEnd = await this.visibleRangeIsAtEndOfPublication();

        let documentIsAtStart = await this.visibleRangeIsAtStartOfDocument();
        let documentIsAtEnd = await this.visibleRangeIsAtEndOfDocument();

        let visibleContentBlocks = await visibleRange.fetchContentBlockTargets(ContentBlockTargetFetchMode.INTERSECTING);
        let visibleTextContent: string = '';

        visibleContentBlocks.forEach((blockTarget: IContentBlockTarget) => {
            let textContent = blockTarget.getContentBlock().getTextContent();
            let charOffset = blockTarget.getCharOffset();
            visibleTextContent += charOffset > 0 ? textContent.slice(charOffset) : textContent;
        });

        return {
            locator,
            timelinePosition: rangeTimelinePosition,
            readingProgression,
            bookmarks: this.bookmarksLayer?.getBookmarksInVisibleRange() || [],
            highlights: this.highlightsLayer?.getHighlightsInVisibleRange() || [],
            navItems: navItems || [],
            documentIsAtStart,
            documentIsAtEnd,
            publicationIsAtStart,
            publicationIsAtEnd,
            visibleTextContent,
        };

    }

    /*
    *
    * Change the current renderer for a new renderer instance that matches the `rendererName` argument.
    * Have a look at the `VanillaReaderRendererNames` enum to see all the available renderers.
    *
    * */
    changeRenderer(rendererName: string, ignoreAspectRatio: boolean | undefined) {
        if (this._colibrioReaderView) {

            // If the selected renderer name is not set to our `RESPONSIVE` flag we explicitly set the new renderer.
            // If is set to responsive however, we let the reader view take care of things for us.
            if (rendererName !== VanillaReaderRendererNames.RESPONSIVE) {

                if (Object.values(VanillaReaderRendererNames).includes(rendererName as VanillaReaderRendererNames)) {
                    let renderer = this._colibrioReaderView.getRendererByName(rendererName);
                    if (renderer) {
                        if (rendererName != VanillaReaderRendererNames.SCROLL) {
                            // If the renderer is paginated we should take care to use the set the `disableAnimations`
                            // so that the `_useReducedMotion` value is respected by the new renderer.
                            let paginatedRendererOptions: IPaginatedRendererOptions = {
                                disableAnimations: this._initialVanillaReaderViewOptions?.useReducedMotion,
                                ignoreAspectRatio: ignoreAspectRatio,
                            };
                            renderer.setOptions(paginatedRendererOptions);
                        }
                        this._colibrioReaderView.setResponsiveRendererSelectionEnabled(false);
                        this._colibrioReaderView.setActiveRenderer(renderer);
                        this.changeRendererAspectRatio(ignoreAspectRatio);
                    } else {
                        console.error('VanillaReader.changeRenderer: Renderer name "' + rendererName + '" does not exist');
                    }
                } else {
                    console.error('VanillaReader.changeRenderer: Renderer name "' + rendererName + '" does not exist');
                }

            } else {
                // The rendererName matches`VanillaReaderRendererNames.RESPONSIVE`, this is our flag to we let Colibrio
                // find the best renderer based on the options we specified in the `_createRenderers` method.
                this._colibrioReaderView.setResponsiveRendererSelectionEnabled(true);
                this.changeRendererAspectRatio(ignoreAspectRatio);
                this.refresh(true);
            }
        } else {
            console.warn('VanillaReader.changeRenderer(): There is no _colibrioReaderView instance, is the current publication an audiobook perhaps? ');
        }
    }

    /*
    *
    * Set the `ignoreAspectRatio` specifically. This makes the `IReaderView` ignore the width and height that the
    * publication has defined and will take up all available space.
    *
    * */
    changeRendererAspectRatio(ignore: boolean = false) {
        if (this._colibrioReaderView) {
            this.ignoreAspectRatio = ignore;

        } else {
            console.warn('VanillaReader.changeRenderer(): There is no _colibrioReaderView instance, is the current publication an audiobook perhaps? ');
        }
    }

    /**
     *
     * Refresh the `_colibrioReaderView`. You need to do this after changing view options in order for them to take effect.
     *
     * */
    refresh(force: boolean = false) {
        if (this._colibrioReaderView) {
            this._colibrioReaderView.refresh(force);
        }
    }

    /*
    *
    * This utility method checks if the content that is currently visible on the screen is the first
    * content available in the publication.
    *
    * */
    async visibleRangeIsAtStartOfPublication(): Promise<boolean> {
        const visibleRange = this._colibrioReaderView?.getVisibleRange();
        const colibrioReaderPublication = this._readingSystem.getColibrioReaderPublication();
        let locator = visibleRange?.getLocator();

        if (visibleRange && locator) {
            const visibleRangeStart = visibleRange.collapseToStart();

            const firstReaderDocumentInPublication = colibrioReaderPublication?.getSpine()[0];
            const firstReaderDocumentContentLocation = await firstReaderDocumentInPublication?.fetchContentLocationForEntireDocument();
            const firstReaderDocumentStart = firstReaderDocumentContentLocation?.collapseToStart();

            return firstReaderDocumentStart?.equals(visibleRangeStart) || false;
        } else {
            console.warn('VanillaReader.readingPositionIsAtStartOfPublication(): Could not resolve a locator or visibleRange.');
            return false;
        }
    }

    /*
    *
    * This utility method checks if the content that is currently visible on the screen is the last
    * content available in the publication.
    *
    * */
    async visibleRangeIsAtEndOfPublication(): Promise<boolean> {
        const visibleRange = this._colibrioReaderView?.getVisibleRange();
        const colibrioReaderPublication = this._readingSystem.getColibrioReaderPublication();
        let locator = visibleRange?.getLocator();

        if (visibleRange && locator) {
            const visibleRangeEnd = visibleRange.collapseToEnd();

            const readerPublicationLength = colibrioReaderPublication.getSpine().length || 0;
            const lastReaderDocumentInPublication = colibrioReaderPublication.getSpine()[readerPublicationLength - 1];
            const lastReaderDocumentContentLocation = await lastReaderDocumentInPublication?.fetchContentLocationForEntireDocument();
            const lastReaderDocumentEnd = lastReaderDocumentContentLocation?.collapseToEnd();

            return lastReaderDocumentEnd?.equals(visibleRangeEnd) || false;

        } else {
            console.warn('VanillaReader.readingPositionIsAtEndOfPublication(): Could not resolve a locator or visibleRange.');
            return false;
        }
    }

    /*
    *
    * This utility method checks if the content that is currently visible is at the very start of the content document.
    * In other words that the start `IContentLocation` of the visible range is equal to the start IContentLocation` of
    * the entire document.
    *
    * */
    async visibleRangeIsAtStartOfDocument(): Promise<boolean> {
        const visibleRange = this._colibrioReaderView?.getVisibleRange();
        let locator = visibleRange?.getLocator();

        if (visibleRange && locator) {
            const visibleRangeStart = visibleRange.collapseToStart();
            const visibleRangeEnd = visibleRange.collapseToEnd();
            const visibleReaderDocument = visibleRangeEnd.getReaderDocuments()[0];
            const visibleReaderDocumentContentLocation = await visibleReaderDocument.fetchContentLocationForEntireDocument();
            const visibleReaderDocumentStart = visibleReaderDocumentContentLocation.collapseToStart();

            return visibleReaderDocumentStart.equals(visibleRangeStart);

        } else {
            console.warn('VanillaReader.readingPositionIsAtStartOfDocument(): Could not resolve a locator or visibleRange.');
            return false;
        }
    }

    /*
    *
    * This utility method checks if the content that is currently visible is at the very end of the content document.
    * In other words that the end `IContentLocation` of the visible range is equal to the end IContentLocation` of
    * the entire document.
    *
    * */
    async visibleRangeIsAtEndOfDocument(): Promise<boolean> {
        const visibleRange = this._colibrioReaderView?.getVisibleRange();
        let locator = visibleRange?.getLocator();

        if (visibleRange && locator) {
            const visibleRangeEnd = visibleRange.collapseToEnd();
            const visibleReaderDocument = visibleRangeEnd.getReaderDocuments()[0];
            const visibleReaderDocumentContentLocation = await visibleReaderDocument.fetchContentLocationForEntireDocument();
            const visibleReaderDocumentEnd = visibleReaderDocumentContentLocation.collapseToEnd();

            return visibleReaderDocumentEnd.equals(visibleRangeEnd);

        } else {
            console.warn('VanillaReader.readingPositionIsAtEndOfDocument(): Could not resolve a locator or visibleRange.');
            return false;
        }
    }

    async getReadingPositionData(): Promise<IVanillaReaderReadingPositionData | undefined> {
        const readingPosition = this?.getReadingPosition();

        if (!readingPosition) {
            return undefined;
        }

        const navigationItems: IFetchNavigationItemReferencesResult | undefined = await readingPosition.fetchNavigationItemReferences({
            greedy: true,
            collectionTypes: [
                NavigationCollectionType.TOC,
                NavigationCollectionType.LANDMARKS,
                NavigationCollectionType.PAGE_LIST,
            ],
        });

        const navigationItemsData: IVanillaReaderNavigationItem[] | undefined = navigationItems.getItemsInRange()?.map((item: IReaderPublicationNavigationItemReference): IVanillaReaderNavigationItem => {
            return {
                title: item.getNavigationItem().getTextContent(),
                collectionType: item.getNavigationCollection().getType(),
                children: [],
                locator: item.getNavigationItem().getLocator()?.toString(),
            };
        });

        const contentBlockTargets = await readingPosition.fetchContentBlockTargets(ContentBlockTargetFetchMode.INTERSECTING);

        let readingPositionData: IVanillaReaderReadingPositionData = {
            publicationId: this._vanillaReaderPublication.id,
            documentIndex: readingPosition.getReaderDocuments()[0].getSourceContentDocument().getIndexInSpine(),
            locator: readingPosition.getLocator().toString(),
            contentBlockData: contentBlockTargets.length > 0 ? contentBlockTargets[0].getContentBlock().toSerializableData({ createLocators: true, recursive: false }) : undefined,
            navItemData: navigationItemsData,
            timestamp: Date.now(),
        };

        return readingPositionData;

    }

    renderBookmarks(bookmarkData: IVanillaReaderBookmarkData[]) {
        this.bookmarksLayer?.loadBookmarks(bookmarkData);
    }

    renderHighlights(highlightData: IVanillaReaderHighlightData[]) {
        this.highlightsLayer?.loadHighlights(highlightData);
    }

    /*
    *
    * This property controls if the `VanillaReader` should take care to not use too many UI animations.
    * As you can see in the setter we toggle off the renderer transition animations if `useReducedMotion` is set to true.
    * */

    public get useReducedMotion(): boolean {
        return this._useReducedMotion;
    }

    public set useReducedMotion(preference: boolean) {
        this._useReducedMotion = preference;
        if (this._colibrioReaderView) {
            let activeRendererOptions = this._colibrioReaderView.getActiveRenderer()?.getOptions();
            if (activeRendererOptions && 'disableAnimations' in activeRendererOptions) {
                this._colibrioReaderView.getActiveRenderer()?.setOptions({
                    // @ts-ignore
                    disableAnimations: preference,
                });
            }
        }

        if (this._onReducedMotionValueChangedCallback) {
            this._onReducedMotionValueChangedCallback(preference);
        }

    }

    public get ignoreAspectRatio(): boolean {
        return this._colibrioReaderView.getActiveRenderer()?.getOptions().ignoreAspectRatio || this._ignoreAspectRatio;
    }

    public set ignoreAspectRatio(preference: boolean) {
        this._ignoreAspectRatio = preference;
        if (this._colibrioReaderView) {
            let activeRendererOptions = this._colibrioReaderView.getActiveRenderer()?.getOptions();
            if (activeRendererOptions && 'ignoreAspectRatio' in activeRendererOptions) {
                this._colibrioReaderView.getActiveRenderer()?.setOptions({
                    // @ts-ignore
                    ignoreAspectRatio: preference,
                });
            }
        }

        if (this._onIgnoreAspectRatioValueChangedCallback) {
            this._onIgnoreAspectRatioValueChangedCallback(preference);
        }

    }

    resetViewTransformation() {
        const colibrioTransformManager: IReaderViewTransformManager = this._colibrioReaderView.getTransformManager();
        colibrioTransformManager?.removeActiveTransform().catch(console.warn);
    }

    async zoomToClientRect(zoomRect: DOMRect, animate: boolean = true): Promise<ITransformData | null> {
        const colibrioTransformManager: IReaderViewTransformManager = this._colibrioReaderView.getTransformManager();
        if (!colibrioTransformManager) {
            console.warn('VanillaReaderView.zoomToClientRect(), ReaderView is not in Transform state.');
            return null;
        }

        await colibrioTransformManager.zoomToClientRect(zoomRect, {
            animationOptions: {
                durationMs: animate ? this._defaultAnimationDuration : 0
            }
        });
        return colibrioTransformManager.getActiveTransform();
    }

    async zoomToLevel(zoomLevel: number, animate: boolean = true): Promise<ITransformData | null> {
        const colibrioTransformManager: IReaderViewTransformManager = this._colibrioReaderView.getTransformManager();
        if (!colibrioTransformManager) {
            console.warn('VanillaReaderView.zoomToClientRect(), ReaderView is not in Transform state.');
            return null;
        }
        let viewElementBoundingBox = this._appElement.getBoundingClientRect();
        let clientX = viewElementBoundingBox.left + viewElementBoundingBox.width / 2;
        let clientY = viewElementBoundingBox.top + viewElementBoundingBox.height / 2;

        await colibrioTransformManager.zoomToClientPosition(clientX, clientY, zoomLevel, {
            durationMs: animate ? this._defaultAnimationDuration : 0
        });

        return colibrioTransformManager.getActiveTransform();
    }



    /**
     *
     * PRIVATE METHODS
     *
     * */

    /**
     *
     * The _persistCurrentVanillaReaderOptionsState method retrieves the current active view options and persists them using the
     * `IVanillaReaderOptionsDataStore` instance.
     *
     * */
    private async _persistCurrentVanillaReaderOptionsState() {
        await this._vanillaReaderOptionsDataStore.setVanillaReaderOptionsData({
            dateCreated: Date.now(),
            viewOptions: this.getCurrentVanillaReaderViewOptionsState(),
        }).catch(console.warn);
    }

    /*
     *
     * _createRenderers creates the default `IReaderView` instance for the Imbiblio Reader. It also sets some default
     * view settings, and adds a number of renderers.
     *
     * For audiobooks that have no traditional view, please have a look at the _createAudiobookView method.
     *
     * */
    private _createRenderers(colibrioReaderView: IReaderView) {

        // Grab the background color to use for the renderer from the initial options
        let rendererBackgroundColor: string | undefined = this._initialVanillaReaderViewOptions?.colibrioReaderViewOptions?.publicationStyleOptions?.palette?.backgroundLight;

        /*
        *
        * ## A note about Renderers
        *
        * How a book is presented to the user is determined by what kind of `IRenderer` type that is currently set as
        * active in the `IReaderView`.
        *
        * Each type of renderer has different options that affects properties such as if the renderer should keep the
        * aspect ratio defined in the publication, if it should animate to the next / previous page, if it should show
        * a shadow behind the page or spread etc. etc.
        *
        * A handy feature that is also supported by the reader view is that it can choose a fitting renderer based on
        * so called `ResponsiveViewRule` objects. These are defined using the same syntax as CSS Media Queries and saves
        * you the trouble of manually switching renderer from the UI.
        *
        * */

        const colibrioFlipBookRenderer = new FlipBookRenderer({
            name: VanillaReaderRendererNames.FLIPBOOK,
            ignoreAspectRatio: this._initialVanillaReaderViewOptions?.ignoreAspectRatio,
            disableAnimations: this._initialVanillaReaderViewOptions?.useReducedMotion,
            pageBackgroundColor: rendererBackgroundColor,
        });

        const colibrioStackRenderer = new StackRenderer({
            name: VanillaReaderRendererNames.STACK,
            ignoreAspectRatio: this._initialVanillaReaderViewOptions?.ignoreAspectRatio,
            disableAnimations: this._initialVanillaReaderViewOptions?.useReducedMotion,
            pageBackgroundColor: rendererBackgroundColor,
        });

        const colibrioStackRendererMobile = new StackRenderer();
        colibrioStackRendererMobile.setOptions({
            name: VanillaReaderRendererNames.STACK,
            showRendererBackgroundShadow: false,
            ignoreAspectRatio: true,
            disableAnimations: this._initialVanillaReaderViewOptions?.useReducedMotion,
            pageBackgroundColor: rendererBackgroundColor,
        });
        const colibrioSwipeRendererSingle = new SinglePageSwipeRenderer({
            name: VanillaReaderRendererNames.SWIPE_SINGLE,
            ignoreAspectRatio: this._initialVanillaReaderViewOptions?.ignoreAspectRatio,
            disableAnimations: this._initialVanillaReaderViewOptions?.useReducedMotion,
            pageBackgroundColor: rendererBackgroundColor,
        });
        const colibrioSwipeRendererSpread = new SpreadSwipeRenderer({
            name: VanillaReaderRendererNames.SWIPE_SPREAD,
            ignoreAspectRatio: this._initialVanillaReaderViewOptions?.ignoreAspectRatio,
            disableAnimations: this._initialVanillaReaderViewOptions?.useReducedMotion,
            pageBackgroundColor: rendererBackgroundColor,
        });

        const colibrioScrollRenderer = new SingleDocumentScrollRenderer({
            name: VanillaReaderRendererNames.SCROLL,
            ignoreAspectRatio: this._initialVanillaReaderViewOptions?.ignoreAspectRatio,
            readingAreaOffsetTop: {
                value: 5,
                unit: LengthUnit.PERCENT,
            },
            pageBackgroundColor: rendererBackgroundColor,
        });

        // Use the flip book renderer for all screens wider than 600px that are in landscape mode
        colibrioReaderView.addRenderer(colibrioFlipBookRenderer, new ResponsiveViewRule('(orientation: landscape) and (min-width: 600px)'));

        // Use the stack renderer for all screens wider than 450px, but narrower than 600px
        colibrioReaderView.addRenderer(colibrioStackRenderer, new ResponsiveViewRule('(min-width: 450px)'));

        // Use the stack renderer without background shadow, and ignoring the publication aspect ratio for all screens
        // narrower than 450px
        colibrioReaderView.addRenderer(colibrioStackRendererMobile, new ResponsiveViewRule('(max-width: 450px)'));

        colibrioReaderView.addRenderer(colibrioSwipeRendererSingle);
        colibrioReaderView.addRenderer(colibrioSwipeRendererSpread);
        colibrioReaderView.addRenderer(colibrioScrollRenderer);

    }

    private _setupBookmarkLayerCallbacks(bookmarksLayer: VanillaReaderAnnotationLayerBookmarks) {

        bookmarksLayer.onBookmarkClick((ev: IReaderViewAnnotationMouseEngineEvent) => {
            let locator = ev.annotation.getLocator();
            if (locator) {
                let bookmark = bookmarksLayer.getBookmark(locator.toString());
                if (bookmark) {
                    VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_BOOKMARK_CLICKED>(new CustomEvent(VanillaReaderAppEvents.BOOKMARK_CLICKED, { detail: { bookmark } }));
                }
            }
        });

        bookmarksLayer.onBookmarkEnterView((bookmark: IVanillaReaderBookmarkData) => {
            VanillaReaderEventBus.dispatchEvent(new CustomEvent(VanillaReaderAppEvents.BOOKMARK_IN_VISIBLE_RANGE, { detail: bookmark }));
        });

        bookmarksLayer.onBookmarkExitView((bookmark: IVanillaReaderBookmarkData) => {
            VanillaReaderEventBus.dispatchEvent(new CustomEvent(VanillaReaderAppEvents.BOOKMARK_OUTSIDE_VISIBLE_RANGE, { detail: bookmark }));
        });
    }

    private _setupHighlightLayerCallbacks(highlightsLayer: VanillaReaderAnnotationLayerHighlights) {

        highlightsLayer.onHighlightContextMenu((event: IReaderViewAnnotationMouseEngineEvent) => {
            let highlight = event.annotation.getCustomData() as IVanillaReaderHighlightData;
            if (highlight) {
                VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_HIGHLIGHT_CLICKED>(new CustomEvent(VanillaReaderAppEvents.HIGHLIGHT_CLICKED, { detail: { highlight } }));
            }
        });

        highlightsLayer.onHighlightAdded(() => {
            this._colibrioReaderView?.clearContentSelection();
        });

        highlightsLayer.onHighlightEnterView((highlight: IVanillaReaderHighlightData) => {
            if (highlight) {
                VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_HIGHLIGHT_IN_VISIBLE_RANGE>(new CustomEvent(VanillaReaderAppEvents.HIGHLIGHT_IN_VISIBLE_RANGE, { detail: { highlight } }));
            }
        });

        highlightsLayer.onHighlightExitView((highlight: IVanillaReaderHighlightData) => {
            if (highlight) {
                VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_HIGHLIGHT_OUTSIDE_VISIBLE_RANGE>(new CustomEvent(VanillaReaderAppEvents.HIGHLIGHT_OUTSIDE_VISIBLE_RANGE, { detail: { highlight } }));
            }
        });
    }

}