/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IVanillaReaderUI} from "./IVanillaReaderUI";
import {VanillaReaderUiMenuMediaPlayerController} from "./VanillaReaderUiMenuMediaPlayerController";
import {VanillaReaderAppUiDefaults} from "./VanillaReaderAppUiDefaults";
import {IVanillaReaderUiActionKeyCommand, VanillaReaderUiActionNames} from "./VanillaReaderUiModel";
import {Key} from "../utils/Key.enum";

export interface VanillaReaderUiKeyboardShortcutController extends IVanillaReaderUI, VanillaReaderUiMenuMediaPlayerController {}

/**
 * # VanillaReaderUiKeyboardShortcutController
 *
 * ## RESPONSIBILITIES
 * This controller mixing class handles all keyboard event callbacks from `VanillaReaderUi`.
 *
 * Just as all other controller mixin classes, this class is used to add functionality to the `VanillaReaderUi` class
 * in a "modular" way. Read more about this in the `UI Components and Controller Mixins` in the README.md file.
 *
 * ## RELATED TYPES
 * - VanillaReaderUi
 * - VanillaReaderUiActionNames
 *
 */
export class VanillaReaderUiKeyboardShortcutController {

    _handleKeyboardShortcut(key: string, altKeyPressed: boolean, shiftKeyPressed: boolean) {

        // Get the user defined key mapping for this key
        let keyCommand = this._resolveUiActionKeyCommand(key, shiftKeyPressed);

        if(!keyCommand) return;

        // We do allow ArrowLeft and ArrowRight navigation without the Alt modifier key pressed.
        switch (key) {
            case 'ArrowLeft':
                if (this.activeDialogTitle === undefined) {
                    this.navigatePrevious();
                }
                return;
            case 'ArrowRight':
                if (this.activeDialogTitle === undefined) {
                    this.navigateNext();
                }
                return;
        }

        // The Alt key needs to be pressed for this event to be considered a valid keyboard action.
        if(!altKeyPressed) return;

        switch (keyCommand.actionName) {
            case VanillaReaderUiActionNames.NAVIGATE_PREVIOUS:
                if (this.activeDialogTitle === undefined) {
                    this.navigatePrevious();
                }
                break;
            case VanillaReaderUiActionNames.NAVIGATE_NEXT:
                if (this.activeDialogTitle === undefined) {
                    this.navigateNext();
                }
                break;
            case VanillaReaderUiActionNames.NAVIGATE_NEXT_HEADING:
                this.navigateToNextHeading();
                break;
            case VanillaReaderUiActionNames.NAVIGATE_PREVIOUS_HEADING:
                this.navigateToPreviousHeading();
                break;
            case VanillaReaderUiActionNames.MAIN_MENU_OPEN:
                this.menuBottom.show(!this.useReducedMotion);
                break;
            case VanillaReaderUiActionNames.SEARCH_DIALOG_OPEN:
                // If we have an active selection we use this text to populate the search input.
                if (this.activeSelection?.selectionText) {
                    this.dialogSearch.setSearchTerm(this.activeSelection.selectionText);
                }
                this.dialogSearch.show(false, false, undefined, this.latestSelectedSearchResultLocator);
                break;
            case VanillaReaderUiActionNames.SEARCH_CLEAR:
                this.dialogSearch.clear();
                break;
            case VanillaReaderUiActionNames.BOOKMARK_READING_POSITION:
                this.bookmarkReadingPosition();
                break;
            case VanillaReaderUiActionNames.BOOKMARK_CLIPBOARD:
                this.bookmarkClipboardContents().catch(console.warn);
                break;
            case VanillaReaderUiActionNames.TOC_OPEN_DIALOG:
                this.dialogContents.show(false, false);
                break;
            case VanillaReaderUiActionNames.SETTINGS_DIALOG_OPEN:
                this.dialogSettings.show(false, false);
                break;
            case VanillaReaderUiActionNames.BOOKSHELF_DIALOG_OPEN:
                this.closePublicationAndShowFileOpenDialog();
                break;
            case VanillaReaderUiActionNames.CLOSE_DIALOGS_AND_FOCUS_ON_READING_POSITION:
                this.closeAllDialogsAndFocusOnReadingPosition();
                break;
            case VanillaReaderUiActionNames.BOOKMARK_OPEN_DIALOG_ALL:
                this.dialogBookmarks.show(false, false);
                break;
            case VanillaReaderUiActionNames.BOOKMARK_OPEN_DIALOG_ACTIVE:
                this.dialogBookmarks.show(false, true, undefined, true);
                break;
            case VanillaReaderUiActionNames.HIGHLIGHT_OPEN_DIALOG_ALL:
                this.dialogHighlights.show(false, false);
                break;
            case VanillaReaderUiActionNames.HIGHLIGHT_OPEN_DIALOG_ACTIVE:
                this.dialogHighlights.show(false, true, undefined, true);
                break;
            case VanillaReaderUiActionNames.HELP_DIALOG_OPEN:
                this.dialogHelp.show(false, false);
                break;
            case VanillaReaderUiActionNames.HIGHLIGHT_SELECTION:
                if (this.activeSelection) {
                    this.showHighlightDialogWithActiveSelectionState();
                } else {
                    this.toaster.toast('There is no text selection to highlight.', true).catch(console.warn);
                }
                break;
            case VanillaReaderUiActionNames.HIGHLIGHT_CLIPBOARD:
                this.highlightClipboardContents().catch(console.warn);
                break;
            case VanillaReaderUiActionNames.WHERE_AM_I:
                this.announceReadingPosition().catch(console.warn);
                break;
            case VanillaReaderUiActionNames.MEDIA_PLAYBACK_START:
                this.openMediaPlayer(true);
                this.startMediaPlayer();
                break;
            case VanillaReaderUiActionNames.MEDIA_PLAYBACK_STOP:
                this.closeMediaPlayer();
                this.focusOnReadingPosition();
                break;
            case VanillaReaderUiActionNames.ACCESSIBILITY_MODE_ENTER:
                this.enterAccessibilityMode();
                break;
            case VanillaReaderUiActionNames.ACCESSIBILITY_MODE_EXIT:
                this.exitAccessibilityMode();
                break;
            case VanillaReaderUiActionNames.GOTO_PAGE_DIALOG_OPEN:
                this.showGoToPagePrompt();
                break;
            case VanillaReaderUiActionNames.ACTIONS_MENU_OPEN:
                this.dialogUiActions.show(true, true, undefined, true);
                break;
            case VanillaReaderUiActionNames.ZOOM_TOOL_ACTIVATE:
                this.activatePanZoomTool();
                break;
            case VanillaReaderUiActionNames.ZOOM_TOOL_DEACTIVATE:
                this.deactivatePanZoomTool();
                break;
            case VanillaReaderUiActionNames.ZOOM_OUT:
                if(this.isReaderViewIsInPanZoomMode) {
                    this.zoomToLevel(0.8);
                }
                break;
            case VanillaReaderUiActionNames.ZOOM_IN:
                this.zoomToLevel(1.2);
                break;
        }
    }

    /*
    * This method looks up the action that should be triggered for the specified key. Key mappings can be changed and
    * persisted by updating `IVanillaReaderUiActionKeyCommand` items the `VanillaReaderAppUiDefaults.options.uIActionKeyCommands`
    * array.
    * */
    _resolveUiActionKeyCommand(key: string, shiftKeyPressed: boolean): IVanillaReaderUiActionKeyCommand | undefined {
        return VanillaReaderAppUiDefaults.options.uIActionKeyCommands?.find((keyCommand: IVanillaReaderUiActionKeyCommand)=>{
            let keyCommandFound: boolean = keyCommand.key === key;

            if(keyCommandFound && shiftKeyPressed) {
                keyCommandFound = keyCommand.modifiers.includes(Key.Shift);
            }

            return keyCommandFound;
        });
    }
}