/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {
    IVanillaReaderAppEvent_BOOKMARK_DELETE_INTENT,
    IVanillaReaderAppEvent_NAVIGATION_INTENT, VanillaReaderAppEvents,
    VanillaReaderEventBus
} from "../VanillaReader/VanillaReaderEventBus";
import {IVanillaReaderBookmarkData} from "../VanillaReader/VanillaReaderModel";
import {IVanillaReaderUI} from "./IVanillaReaderUI";
import {IVanillaReaderUiComponentController} from "./IVanillaReaderUiComponentController";
import {VanillaReaderUI} from "./VanillaReaderUI";

export interface VanillaReaderUiDialogBookmarksController extends IVanillaReaderUiComponentController, IVanillaReaderUI {}

/**
 * # VanillaReaderUiDialogBookmarksController
 *
 * ## RESPONSIBILITIES
 * This controller mixing class handles all event callbacks from the `VanillaReaderUiDialogBookmarks` class.
 *
 * Just as all other controller mixin classes, this class is used to add functionality to the `VanillaReaderUi` class
 * in a "modular" way. Read more about this in the `UI Components and Controller Mixins` in the README.md file.
 *
 * ## RELATED TYPES
 * - VanillaReaderUi
 * - VanillaReaderUiDialogBookmarks
 *
 * */
export class VanillaReaderUiDialogBookmarksController {

    static __initialize__(vanillaReaderUiContext: VanillaReaderUI) {
        vanillaReaderUiContext.dialogBookmarks.onBookmarkItemClicked(vanillaReaderUiContext._onDialogBookmarksItemClicked.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.dialogBookmarks.onBookmarkItemButtonClicked(vanillaReaderUiContext._onDialogBookmarkItemButtonClicked.bind(vanillaReaderUiContext));
    }

    _onDialogBookmarksItemClicked(navigationData: IVanillaReaderAppEvent_NAVIGATION_INTENT) {
        if (navigationData.locator) {
            this.navigateTo(navigationData.locator.toString());
        }

        this.dialogBookmarks?.close();
    };

    _onDialogBookmarkItemButtonClicked (bookmark: IVanillaReaderBookmarkData) {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_BOOKMARK_DELETE_INTENT>(new CustomEvent<IVanillaReaderAppEvent_BOOKMARK_DELETE_INTENT>(VanillaReaderAppEvents.BOOKMARK_DELETE_INTENT, {detail: {bookmark}}));
    };
}