/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IVanillaReaderUI} from "./IVanillaReaderUI";
import {IVanillaReaderHighlightData} from "../VanillaReader/VanillaReaderModel";
import {IVanillaReaderAppEvent_NAVIGATION_INTENT} from "../VanillaReader/VanillaReaderEventBus";
import {IVanillaReaderUiComponentController} from "./IVanillaReaderUiComponentController";
import {VanillaReaderUI} from "./VanillaReaderUI";

export interface VanillaReaderUiDialogHighlightsController extends IVanillaReaderUiComponentController, IVanillaReaderUI {}

/**
* # VanillaReaderUiDialogHighlightsController
*
* ## RESPONSIBILITIES
* This controller mixing class handles all event callbacks from the `VanillaReaderUiDialogHighlights` class.
 *
* Just as all other controller mixin classes, this class is used to add functionality to the `VanillaReaderUi` class
* in a "modular" way. Read more about this in the `UI Components and Controller Mixins` in the README.md file.
*
* ## RELATED TYPES
* - VanillaReaderUi
* - VanillaReaderUiDialogHighlights
*
* */
export class VanillaReaderUiDialogHighlightsController {

    static __initialize__(vanillaReaderUiContext: VanillaReaderUI) {
        vanillaReaderUiContext.dialogHighlights.onHighlightItemClicked(vanillaReaderUiContext._onDialogHighlightsItemClicked.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.dialogHighlights.onHighlightItemEditButtonClicked(vanillaReaderUiContext._onDialogHighlightsItemEditButtonClicked.bind(vanillaReaderUiContext));
    }

    _onDialogHighlightsItemClicked (navigationData: IVanillaReaderAppEvent_NAVIGATION_INTENT) {
        if (navigationData.locator) {
            this.navigateTo(navigationData.locator.toString());
        }

        this.dialogHighlights?.close(true, false);
    };

    _onDialogHighlightsItemEditButtonClicked(highlight: IVanillaReaderHighlightData) {
        // If the highlight dialog was opened from the highlights list, let's close it.
        if (this.activeDialogTitle === 'Highlights') {
            this.dialogHighlights.close(true);
        }

        this.dialogHighlight?.setState(highlight, false);
        this.dialogHighlight?.show();
    };

}