/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import { IIntegerRange } from '@colibrio/colibrio-reader-framework/colibrio-core-base';
import { ILocator } from '@colibrio/colibrio-reader-framework/colibrio-core-locator';
import {
    IPublicationMetadataData,
} from '@colibrio/colibrio-reader-framework/colibrio-core-publication-base';
import {
    ContainerContentBlockType,
    IActiveRendererChangedEngineEvent,
    IContentBlockData,
    IContentLocation,
    IContentPositionTimeline,
    IKeyboardEngineEvent,
    IMouseEngineEvent,
    INavigationEndedEngineEvent,
    INavigationIntentEngineEvent,
    INavigationStartedEngineEvent,
    IPageProgressionTimeline,
    IPageProgressionTimelineEngineEvent,
    IPointerEngineEvent,
    IPublicationStyleOptions,
    IPublicationStylePalette,
    IReaderPublicationStorageState,
    IReaderViewAnnotationEngineEvent,
    IReaderViewAnnotationMouseEngineEvent,
    IReaderViewAnnotationPointerEngineEvent,
    IReaderViewEngineEvent,
    IReaderViewOptions,
    IRendererOptions,
    ISelectionChangedEngineEvent,
    ISyncMediaSegmentData,
    ISyncMediaTimeline, ITransformData,
    MediaContentBlockType,
    NavigationCollectionType,
    TextContentBlockType,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';
import {
    IPublicationSearchIndexContentNodeData,
    IPublicationSearchIndexData,
} from '../utils/PublicationSearchIndexDataFactory';

/*
*
* # VanillaReaderModel.ts
*
* ## RESPONSIBILITIES
*
* This file holds all model types (mostly interfaces) that are used throughout the code. It is the common "dictionary"
* of data types that used by all VanillaReader components to manage its runtime state, as well as its serialised state.
*
* The exception are the `VanillaReaderAppEvent_*` types which are located in the `VanillaReaderEventBus.ts` file.
*
* */

/*
* # VanillaReaderNavigationType
*
* Used by the application components to signal what type of navigation action that is intended, or that has occurred.
*
**/
export enum VanillaReaderNavigationType {
    GOTO = 'vanillareader_navigationtype_goto',
    TIMELINEPOSITION = 'vanillareader_navigationtype_timelineposition',
    GOTOSTART = 'vanillareader_navigationtype_gotostart',
    NEXT = 'vanillareader_navigationtype_next',
    PREVIOUS = 'vanillareader_navigationtype_previous',
    EXTERNAL = 'vanillareader_navigationtype_external'
}

/*
* # VanillaReaderRendererNames
*
* A list of the renderers used by the application. The string values correspond to the different class names of the
* Colibrio Framework IRenderer implementations.
*
* The only entry that does not match an instance in the `ReaderView` is `RESPONSIVE` which acts more like a flag that
* tells the `ReaderView` to use the `ResponsiveViewRules`. These rules are added when the renderers are added.
*
* NOTE: Do not change these values without really considering how you will migrate user settings as these string values
* will have been stored on the users device.
*
* * * ## RELATED TYPES
*
* - IRenderer
* - IReaderView
*
**/
export enum VanillaReaderRendererNames {
    RESPONSIVE = '__responsive__',
    FLIPBOOK = 'FlipBookRenderer',
    STACK = 'StackRenderer',
    SCROLL = 'SingleDocumentScrollRenderer',
    SWIPE_SINGLE = 'SinglePageSwipeRenderer',
    SWIPE_SPREAD = 'SpreadSwipeRenderer'
}

/*
* # IVanillaReaderHighlight
*
* This interface defines the data that is used to describe a highlight annotation in the VanillaReader. This data is used
* by for example the highlight annotation layer and the UI.
*
**/
export interface IVanillaReaderHighlightData {
    locator: string,
    _id?: string,
    publicationId?: string,
    selectionText: string,
    color: string,
    comment: string,
    dateCreated?: number,
    dateUpdated?: number,
}

/*
* # IVanillaReaderBookmark
*
* This interface defines the data that is used to describe a bookmark annotation in the VanillaReader. This data is used
* by for example the bookmark annotation layer and the UI.
*
**/
export interface IVanillaReaderBookmarkData {
    locator: string,
    _id?: string,
    publicationId?: string,
    color?: string,
    text?: string,
    bookSection?: string
    dateCreated?: number
}

/*
* # IVanillaReaderSearchResultNodes
*
* This interface describes a content node in a `IVanillaReaderPublicationSearchResultItem`. A node will often map to
* a HTML element, such as a `<p>` element, in the document content. A search result item can span more than one of these
* node so you will find a `nodes` array in the parent `IVanillaReaderPublicationSearchResultItem` instance.
*
* ## RELATED TYPES
*
* - IVanillaReaderPublicationSearchResultItem
*  - VanillaReaderPublicationSearch
* - IPublicationSearchIndexData
*
**/
export interface IVanillaReaderSearchResultNodes {
    locator: string,
    startOffsetInIndexedNodeText: number,
    endOffsetInIndexedNodeText: number,
    indexedNodeText?: string,
    searchIndexNode: IPublicationSearchIndexContentNodeData
}

/*
* # IVanillaReaderPublicationSearchResultItem
*
* This interface describes a search result returned by the `VanillaReaderPublicationSearch` class.
*
*  A node will often map to a HTML element, such as a `<p>` element, in the document content. A search result item can
* span more than one content nodes, so you iterate over each `IVanillaReaderSearchResultNodes` to get all te content
* that is part of the result.
*
* ## RELATED TYPES
*
* - IVanillaReaderSearchResultNodes
* - VanillaReaderPublicationSearch
* - IPublicationSearchIndexData
*
**/
export interface IVanillaReaderPublicationSearchResultItem {
    bookSection: string | undefined,
    nodes: IVanillaReaderSearchResultNodes[],
    searchTermStartOffsetInIndexedContent: number,
    searchTermEndOffsetInIndexedContent: number,
    locator: string,
    intersectingNodeText?: string,
    readerDocumentIndexInSpine?: number,
}

/*
* # IVanillaReaderNavigationTreeData
*
* This interface describes the entire publication navigation structure for a `VanillaReaderPublication` (and in turn a
* Colibrio Framework IReaderPublication of course).
*
* This data is used for example by the UI to render the Table of Contents, but also when looking up navigation items
* in relation to positions within the document contents.
*
* The reason for the application specific type will be clear when you have a look at the `IVanillaReaderNavigationItem`
* type that has a lot of extra data in comparison to its Colibrio Framework counterpart.
*
* ## RELATED TYPES
*
* - IReaderPublicationNavigation
* - IVanillaReaderNavigationItem
*
**/
export interface IVanillaReaderNavigationTreeData {

    collections: IVanillaReaderNavigationCollection[],
    items: IVanillaReaderNavigationItem[]
}

/*
* # IVanillaReaderNavigationCollection
*
* This interface describes one type of navigation collection. The types of collections are the same as the once defined
* in the Colibrio Framework `NavigationCollectionType` enum, such as `TOC`, `PAGE_LIST`, `LIST_OF_ILLUSTRATIONS` etc.
*
* ## RELATED TYPES
*
* - NavigationCollectionType
* - IVanillaReaderNavigationItem
*
**/
export interface IVanillaReaderNavigationCollection {
    type: NavigationCollectionType,
    children: IVanillaReaderNavigationItem[]
}

/*
* # IVanillaReaderNavigationItem
*
* This interface describes a more contextually rich representation of a navigation item that what you get from its
* Colibrio Framework counterpart `IReaderPublicationNavigationItem`. On this type you for example get access to timeline
* position and content location data.
*
* Because of the extra data this item is more expensive to build and therefore will benefit from being reused between
* sessions.
*
* ## RELATED TYPES
*
* - IReaderPublicationNavigationItem
* - IReaderPublicationNavigation
* - IVanillaReaderNavigationItem
*
**/
export interface IVanillaReaderNavigationItem {
    title: string,
    collectionType?: NavigationCollectionType,
    locator?: string,
    timelineStartPosition?: number,
    children: IVanillaReaderNavigationItem[]
}

/*
* # IVanillaReaderSyncMediaOptions
*
* This interface describes options for the sync media playback.
*
* ## RELATED TYPES
*
* - IVanillaReaderAppUiSettingsData
* - IVanillaReaderPublicationData
*
**/
export interface IVanillaReaderSyncMediaOptions {
    highlightColor?: string,
    volume?: number,
    rate?: number
}

/*
* # IVanillaReaderTtsOptions
*
* This interface describes specific options for the TTS playback.
*
**/
export interface IVanillaReaderTtsOptions extends IVanillaReaderSyncMediaOptions {
    voiceName: string | undefined,
}

export interface IVanillaReaderPublicationData {
    id: string,
    fileName: string,
    fileSourceUri: string,
    title?: string,
    format: string,
    url: string,
    isRtl?: boolean,
    isFixedLayout?: boolean,
    isScripted?: boolean,
    isAudiobook?: boolean,
    hasMoSyncMedia?: boolean,
    metadata?: IPublicationMetadataData,
    coverImageAsBase64?: string,
    language?: string,
}

export interface IVanillaReaderShelfItemData extends IVanillaReaderPublicationData {
    latestReadingPositionData?: IVanillaReaderReadingPositionData,
    starred?: boolean,
    category?: string,
    hasOfflineData?: boolean,
}

/*
* # IVanillaReaderPublicationData
*
* This interface describes the complete serializable application state that is related to the VanillaReaderPublication.
* Its purpose is primarily to describe the storage model for the publication and care should be taken when changing
* this type in order to not break user data.
*
* This data is also sent back to the UI once the publication is loaded as metadata.
*
* This data is created by the `VanillaReaderPublication` class.
*
* ## RELATED TYPES
*
* - VanillaReaderPublication
* - IVanillaReaderAppState
* - IVanillaReaderDataStore
*
**/
export interface IVanillaReaderPublicationStateData extends IVanillaReaderPublicationData {
    latestReadingPositionData?: IVanillaReaderReadingPositionData,
    bookmarks?: IVanillaReaderBookmarkData[],
    highlights?: IVanillaReaderHighlightData[],
    landmarkData?: IVanillaReaderPublicationLandmarkData | undefined,
    storageState?: IReaderPublicationStorageState,
    styleOptions?: IPublicationStyleOptions,
    ttsOptions?: IVanillaReaderTtsOptions
    searchIndexData?: IPublicationSearchIndexData,
    navigationTreeData?: IVanillaReaderNavigationTreeData,
    timelineData?: string | undefined
}

export interface IVanillaReaderPublicationCacheData {
    _id?: string,
    publicationId?: string,
    landmarkData?: IVanillaReaderPublicationLandmarkData | undefined,
    storageState?: IReaderPublicationStorageState,
    searchIndexData?: IPublicationSearchIndexData,
    navigationTreeData?: IVanillaReaderNavigationTreeData,
    timelineData?: string | undefined
}

export interface IVanillaReaderPublicationOptionsData {
    _id?: string,
    publicationId: string,
    styleOptions?: IPublicationStyleOptions,
    ttsOptions?: IVanillaReaderTtsOptions
}

/*
* # IVanillaReaderAppState
*
* This interface describes the complete serializable application state including the `IVanillaReaderPublicationData` and
* the `IVanillaReaderViewOptions`. This is all the data that is needed to restore a previous session.
*
* This data is created by the `VanillaReader` class.
*
* ## RELATED TYPES
*
* - IVanillaReaderPublicationData
* - IVanillaReaderViewOptions
*
**/
export interface IVanillaReaderOptionsData {
    _id?: number,
    dateCreated?: number,
    viewOptions?: IVanillaReaderViewOptions,
    syncMediaOptions?: IVanillaReaderSyncMediaOptions,
}

/*
* # IVanillaReaderViewOptions
*
* This interface describes the all the serializable application state related to rendering specific features, such as
* what IRenderer, what options to use for that renderer instance etc.
*
* This data is created by the `VanillaReader` class.
*
* ## RELATED TYPES
*
* - IVanillaReaderAppState
* - IVanillaReaderPublicationData
*
**/
export interface IVanillaReaderViewOptions {
    _id?: number,
    colibrioRendererOptions?: IRendererOptions,
    colibrioReaderViewOptions?: IReaderViewOptions,
    activeRendererTypeName?: VanillaReaderRendererNames
    ignoreAspectRatio?: boolean,
    useReducedMotion?: boolean,
}

/*
* # IVanillaReaderInitialConfig
*
* This interface describes data that can be used to not only restore a previous application session using saved
* `IVanillaReaderAppState` state, but also take in possible additional parameters. `initialUrl` for example tells
* the VanillaReader to load the specified url as it boots up. `slowNetwork` is also a helpful parameter that the
* application start up code (index.ts) can send to the VanillaReader so that it may optimise user experience accordingly.
*
* This data is created in the `index.ts` file.
*
* ## RELATED TYPES
*
* - IVanillaReaderAppState
*
**/
export interface IVanillaReaderInitialOptions {
    slowNetwork?: boolean;
    storeOnDevice?: boolean;
    viewOptions?: IVanillaReaderViewOptions;
    syncMediaOptions?: IVanillaReaderSyncMediaOptions;
    customReaderViewContentOnLoading?: string;
    customReaderViewContentOnLoadError?: string;
}

/*
* # IVanillaReaderVisibleRangeData
*
* This interface describes data for the currently visible content. It is sent as event data in the `IVanillaReaderAppEvent_VISIBLE_RANGE_CHANGED`
* app event.
*
* ## RELATED TYPES
*
* - `IVanillaReaderAppEvent_VISIBLE_RANGE_CHANGED`
*
**/
export interface IVanillaReaderVisibleRangeData {
    locator?: ILocator,
    timelinePosition?: number | undefined,
    readingProgression?: number | undefined,
    visibleTextContent?: string,
    bookmarks: IVanillaReaderBookmarkData[],
    highlights: IVanillaReaderHighlightData[],
    navItems: IVanillaReaderNavigationItem[],
    documentIsAtStart: boolean,
    documentIsAtEnd: boolean,
    publicationIsAtStart: boolean,
    publicationIsAtEnd: boolean,

}

/*
* # IVanillaReaderReadingPositionData
*
**/
export interface IVanillaReaderReadingPositionData {
    _id?: string | undefined,
    publicationId?: string | undefined,
    contentBlockData?: IContentBlockData,
    locator: string | undefined,
    navItemData?: IVanillaReaderNavigationItem[],
    documentIndex?: number,
    timestamp: number
}

/*
* # VanillaReaderPublicationLandmarkTypeNote
*
**/

export enum VanillaReaderPublicationLandmarkTypeNote {
    FOOTNOTE = 'FOOTNOTE',
    REARNOTE = 'REARNOTE',
    ENDNOTE = 'ENDNOTE'
}

/*
* # VanillaReaderPublicationLandmarkType
*
**/

export enum VanillaReaderPublicationLandmarkType {
    ASIDE = ContainerContentBlockType.ASIDE,
    ARTICLE = ContainerContentBlockType.ARTICLE,
    FIGURE = ContainerContentBlockType.FIGURE,
    TABLE = ContainerContentBlockType.TABLE,
    SECTION = ContainerContentBlockType.SECTION,
    DESCRIPTION_LIST = ContainerContentBlockType.DESCRIPTION_LIST,
    ORDERED_LIST = ContainerContentBlockType.ORDERED_LIST,
    UNORDERED_LIST = ContainerContentBlockType.UNORDERED_LIST,
    IMAGE = MediaContentBlockType.IMAGE,
    AUDIO = MediaContentBlockType.AUDIO,
    VIDEO = MediaContentBlockType.VIDEO,
    HEADING1 = TextContentBlockType.HEADING1,
    HEADING2 = TextContentBlockType.HEADING2,
    HEADING3 = TextContentBlockType.HEADING3,
    HEADING4 = TextContentBlockType.HEADING4,
    HEADING5 = TextContentBlockType.HEADING5,
    HEADING6 = TextContentBlockType.HEADING6,
    FOOTNOTE = VanillaReaderPublicationLandmarkTypeNote.FOOTNOTE,
    REARNOTE = VanillaReaderPublicationLandmarkTypeNote.REARNOTE,
    ENDNOTE = VanillaReaderPublicationLandmarkTypeNote.ENDNOTE,
}

/*
* # IVanillaReaderPublicationLandmarkData
*
**/

export interface IVanillaReaderPublicationLandmarkData {
    _id?: number,
    publicationId: string,
    documents: IVanillaReaderDocumentLandmarkData[],
}

/*
* # IVanillaReaderDocumentLandmarkData
*
**/

export interface IVanillaReaderDocumentLandmarkData {
    documentIndex: number,
    headings: IVanillaReaderLandmarkData[],
    tables: IVanillaReaderLandmarkData[],
    figures: IVanillaReaderLandmarkData[],
    audio: IVanillaReaderLandmarkData[],
    video: IVanillaReaderLandmarkData[],
    lists: IVanillaReaderLandmarkData[],
    sections: IVanillaReaderLandmarkData[],
    articles: IVanillaReaderLandmarkData[],
    asides: IVanillaReaderLandmarkData[],
    footnotes: IVanillaReaderLandmarkData[],
}

export interface IVanillaReaderLandmarkData extends IContentBlockData {
    timelinePosition: number | undefined;
    subType?: string;
    description?: string;
    locatorUrl?: string;
}

export interface IVanillaReaderAppTheme {
    publicationPalette?: IPublicationStylePalette;
    uiPalette?: IVanillaReaderUiColorPalette;
}

export interface IVanillaReaderUiColorPalette extends IPublicationStylePalette {
    //IPublicationStylePalette
    foregroundDark: string;
    foregroundLight: string;
    backgroundDark: string;
    backgroundLight: string;
    accent: string;

    // IVanillaReaderUiColorPalette extension
    name: string;
    uiIsDark: boolean;
    uiOutlineDark?: string;
    uiOutlineLight?: string;
    uiBackgroundDark?: string;
    uiBackgroundLight?: string;
    uiForegroundDark?: string;
    uiForegroundLight?: string;
}

/*
* # IVanillaSyncMediaPlaybackStateData
*
* This interface describes the runtime state of the `ISyncMediaTimeline` during playback. It is sent in various app
* events such as `IVanillaReaderAppEvent_MEDIAPLAYER_PLAYBACK_STATE_CHANGED` and `IVanillaReaderAppEvent_MEDIAPLAYER_PLAYBACK_POSITION_CHANGED`.
*
* ## RELATED TYPES
*
* - VanillaReader
* - VanillaReaderAudiobookPlayer
* - VanillaReaderMediaPlayer
*
* */
export interface IVanillaSyncMediaPlaybackStateData {
    position: number,
    isSeeking?: boolean,
    isPaused?: boolean,
    isMuted?: boolean,
    rate?: number,
    volume?: number,
    navigationItems?: IVanillaReaderNavigationItem[],
    locator?: string,
    segmentData?: ISyncMediaSegmentData,
    publicationDurationMs?: number
}


/*
* # IVanillaReaderReadingProgressionTimeline
*
* A common interface to describe the reading progression in a reader publication using a numeric index.
*
* It is used to hide the differences between the `IContentPositionTimeline` in text based book and the `ISyncMediaTimeline`
* used in audiobooks.
*
* This is a convenience abstraction that is very implementation specific to the VanillaReader. Your own implementation
* may choose another strategy.
*
* ## RELATED TYPES
*
* - VanillaReaderAudiobookProgressionTimeline
* - VanillaReaderProgressionTimeline
* - IContentPositionTimeline
* - ISyncMediaTimeline
*
**/
export interface IVanillaReaderReadingProgressionTimeline {
    fetchTimelinePosition: (location: ILocator) => Promise<number | undefined>;
    fetchContentLocation: (timelinePosition: number) => Promise<IContentLocation | undefined>;
    fetchTimelineRange: (location: ILocator) => Promise<IIntegerRange | undefined>;
    getLength: () => number;
    isReady: () => boolean;
    getBackingTimelineImplementation: () => IContentPositionTimeline | IPageProgressionTimeline | ISyncMediaTimeline;
    toSerializedData: () => string | undefined;
    updateTimelinePosition: (locator: ILocator) => void;
    onTimelineUpdateIntent: (callback: VanillaProgressEventCallback) => void;
    onTimelineReady: (callback: VanillaTimelineReadyEventCallback) => void;
}

export interface IVanillaReaderUserProfileData {
    id: string;
    token: string;
}

// CALLBACK TYPES

export type VanillaUnloadEventCallback = (ev: IVanillaReaderOptionsData) => Promise<void>;
export type VanillaNavigationStartedEventCallback = (ev: INavigationStartedEngineEvent) => void;
export type VanillaNavigationEndedEventCallback = (ev: INavigationEndedEngineEvent) => void;
export type VanillaReaderViewEventCallback = (ev: IReaderViewEngineEvent) => void;
export type VanillaPageProgressionTimelineRecalculatedCallback = (ev: IPageProgressionTimelineEngineEvent) => void;
export type VanillaNavigationIntentEventCallback = (ev: INavigationIntentEngineEvent) => void;
export type VanillaPublicationSearchIntentEventCallback = (searchTerm: string) => void;
export type VanillaBookmarkReadingPositionIntentEventCallback = () => void;
export type VanillaSelectionChangedEventCallback = (ev: ISelectionChangedEngineEvent) => void;
export type VanillaMouseEventCallback = (ev: IMouseEngineEvent) => void;
export type VanillaPointerEventCallback = (ev: IPointerEngineEvent) => void;
export type VanillaViewTransformChangedCallback = (transformState: ITransformData | null) => void;
export type VanillaZoomToolDragCallback = (ev: PointerEvent, rect?: DOMRect) => void;
export type VanillaActiveRendererChangedEventCallback = (ev: IActiveRendererChangedEngineEvent) => void;
export type VanillaKeyboardEventCallback = (ev: IKeyboardEngineEvent) => void;
export type VanillaAnnotationMouseEventCallback = (ev: IReaderViewAnnotationMouseEngineEvent) => void;
export type VanillaAnnotationPointerEventCallback = (ev: IReaderViewAnnotationPointerEngineEvent) => void;
export type VanillaAnnotationEventCallback = (ev: IReaderViewAnnotationEngineEvent) => void;
export type VanillaAnnotationCollectionChangedEventCallback = () => void;
export type VanillaAnnotationDeletedEventCallback = () => void;
export type VanillaTimelineReadyEventCallback = (length: number) => void;
export type VanillaProgressEventCallback = (progress: number, resourceId?: string) => void;
export type VanillaHighlightDataCallback = (highlight: IVanillaReaderHighlightData, silent?: boolean) => void;
export type VanillaBookmarkDataCallback = (bookmark: IVanillaReaderBookmarkData, silent?: boolean) => void;
export type VanillaOpenFileCallback = (fileObject: File) => void;
export type VanillaOpenUrlCallback = (url: string) => void;
export type VanillaVoidCallback = () => void;
export type VanillaReaderViewOptionsCallback = (options: IVanillaReaderViewOptions) => void;
export type VanillaPublicationDataCallback = (publicationData: IVanillaReaderPublicationData) => void;
export type VanillaPublicationStateDataCallback = (publicationData: IVanillaReaderPublicationStateData) => void;
export type VanillaPublicationCacheDataCallback = (publicationCacheData: IVanillaReaderPublicationCacheData) => void;
export type VanillaPublicationOptionsDataCallback = (publicationOptionsData: IVanillaReaderPublicationOptionsData) => void;
export type VanillaStringValueCallback = (value: string) => void;
export type VanillaNumberValueCallback = (value: number) => void;
export type VanillaBooleanValueCallback = (value: boolean) => void;
export type VanillaDialogOpenedCallback = (dialogName: string, silent: boolean) => void;
export type VanillaDialogClosedCallback = (dialogName: string, silent: boolean) => void;
export type VanillaAppStateUpdatedCallback = (appState: IVanillaReaderOptionsData) => void;
export type VanillaMediaPlayerPlaybackStateChangedCallback = (state: IVanillaSyncMediaPlaybackStateData) => void;
export type VanillaMediaPlayerTrackChangedCallback = (state: IVanillaSyncMediaPlaybackStateData) => void;
export type VanillaZoomToLevelCallback = (zoomLevel: number, animate?: boolean) => void;

