/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {VanillaVoidCallback} from '../VanillaReader/VanillaReaderModel';

/**
 * # VanillaReaderUiFabHighlight
 *
 * ## RESPONSIBILITIES
 *
 * ## RELATED TYPES
 * - VanillaReaderUiFabHighlightController
 *
 */
export class VanillaReaderUiFabHighlight {
    private _template = `<button class="vanilla-reader__fab" id="vanilla-reader__fab-highlight" type="button" title="Add highlight" aria-hidden="true"><span class="vanilla-reader__fab__icon" aria-hidden="true">edit_note</span></button>`;
    private _onClickCallback: VanillaVoidCallback | undefined;
    private _onVisibleCallback: VanillaVoidCallback | undefined;
    private _onHiddenCallback: VanillaVoidCallback | undefined;
    private _hightlightButton: HTMLButtonElement;

    constructor(private _hostElement: HTMLElement, insertPosition: InsertPosition = 'afterbegin') {
        this._hostElement.insertAdjacentHTML(insertPosition, this._template);
        this._hightlightButton = this._hostElement.querySelector('#vanilla-reader__fab-highlight')!;
        this._hightlightButton.addEventListener('click', (_ev: MouseEvent) => {
            if (this._onClickCallback) {
                if (this.isVisible) {
                    this._onClickCallback();
                }
            }
        });
    }

    public get isVisible(): boolean {
        return !this._hightlightButton.hasAttribute('aria-hidden');
    }

    show() {
        this._hightlightButton.removeAttribute('aria-hidden');
        this._hightlightButton.setAttribute('accesskey', 'i');
        this._hightlightButton.style.display = 'flex';
        this._hightlightButton.style.opacity = '1';
        this._hightlightButton.style.pointerEvents = 'all';
        if (this._onVisibleCallback) {
            this._onVisibleCallback();
        }
    }

    hide() {
        this._hightlightButton.removeAttribute('accesskey');
        this._hightlightButton.setAttribute('aria-hidden', 'true');
        this._hightlightButton.style.opacity = '0';
        this._hightlightButton.style.pointerEvents = 'none';

        setTimeout(()=>{
            this._hightlightButton.style.display = 'none';
        },200);

        if (this._onHiddenCallback) {
            this._onHiddenCallback();
        }
    }

    getComponentElement(): HTMLElement {
        return this._hightlightButton;
    }

    onClick(callback: VanillaVoidCallback) {
        this._onClickCallback = callback;
    }

    onVisible(callback: VanillaVoidCallback) {
        this._onVisibleCallback = callback;
    }

    onHidden(callback: VanillaVoidCallback) {
        this._onHiddenCallback = callback;
    }

}