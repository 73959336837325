/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.4.0
 */
 'use strict'; (function (s, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-base','./colibrio-core-selector-base','./colibrio-core-url','./colibrio-core-io-base'], function (a0,a1,a2,a3) { return f(s,a0,a1,a2,a3); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(s,require('./colibrio-core-base'),require('./colibrio-core-selector-base'),require('./colibrio-core-url'),require('./colibrio-core-io-base')); } else { throw new Error('Unsupported runtime environment!'); } }( typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function (__self,__dep__colibrio_core_base,__dep__colibrio_core_selector_base,__dep__colibrio_core_url,__dep__colibrio_core_io_base) {  var api = {}; (function () { var d,e="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},g;if("function"==typeof Object.setPrototypeOf)g=Object.setPrototypeOf;else{var h;a:{var k={a:!0},l={};try{l.__proto__=k;h=l.a;break a}catch(a){}h=!1}g=h?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var n=g;var p=__dep__colibrio_core_base;var q=__dep__colibrio_core_url;var u=Array.isArray;function v(a){return"string"===typeof a};var w=new Uint16Array(1);w[0]=4660;new Uint8Array(w.buffer);function x(a,b){this.h=a;this.g=b}d=x.prototype;d.getSelectors=function(){return this.g};d.getSourceUrl=function(){return this.h};d.toJSON=function(){return{sourceUrl:this.h,selectors:this.g.map(function(a){return a.toString()})}};d.toLegacyAnnotationTarget=function(){return 0<this.g.length?{id:this.h,selector:this.g[0].toJSON()}:{id:this.h}};d.toString=function(a){v(a)||(a=this.h);return 0===this.g.length?a:a+"#"+this.g[0].toString()};d.toUrl=function(a){return new URL(this.toString(a))};
d.toWebAnnotationResource=function(){var a=this.h;return{id:0===this.g.length?a:a+"#"+this.g[0].toString()}};d.toWebAnnotationSpecificResource=function(){return{source:this.h,selector:this.g.map(function(a){return a.toJSON()})}};function y(a,b){return p.ColibrioError.call(this,"core.locator",a,b)||this}var z=p.ColibrioError;y.prototype=e(z.prototype);y.prototype.constructor=y;if(n)n(y,z);else for(var A in z)if("prototype"!=A)if(Object.defineProperties){var E=Object.getOwnPropertyDescriptor(z,A);E&&Object.defineProperty(y,A,E)}else y[A]=z[A];function F(a){this.g=a}d=F.prototype;d.collapseRangeLocatorToEnd=function(a){var b=a.getSelectors().map(function(c){return c.collapseToEnd()});return new x(a.getSourceUrl(),b)};d.collapseRangeLocatorToStart=function(a){var b=a.getSelectors().map(function(c){return c.collapseToStart()});return new x(a.getSourceUrl(),b)};
d.createRangeLocator=function(a,b){return a.getSourceUrl()!==b.getSourceUrl()?{locator:null,error:new y("INVALID_URL","startLocator and endLocator have different sourceUrls.")}:(b=this.g.createRangeSelectors(a.getSelectors(),b.getSelectors()))&&0!==b.length?{locator:new x(a.getSourceUrl(),b),error:null}:{locator:null,error:new y("UNABLE_TO_CREATE_RANGE_SELECTORS","Unable to create rangeSelectors")}};d.getSelectorFactory=function(){return this.g};
d.parse=function(a,b){a=G(this,a,b);if(a instanceof x)return{error:null,locator:a};p.Logger.logError(a);return{error:a,locator:null}};
function H(a,b,c){var f=null,r=!0;if(v(b.sourceUrl)&&u(b.selectors))try{f=new URL(b.sourceUrl);var B=b.selectors;var m=[];for(c=0;c<B.length;c++){var C=B[c];if(v(C)){var D=a.g.createFromFragment(C);D&&m.push(D)}}}catch(t){return new y("INVALID_LOCATOR_DATA",'Invalid "sourceUrl": '+b.sourceUrl)}else if(v(b.source))try{f=new URL(b.source)}catch(t){return new y("INVALID_LOCATOR_DATA",'Invalid "source": '+b.source)}else if(v(b.id))try{f=new URL(b.id),r=!f.hash||"#"===f.hash}catch(t){return new y("INVALID_LOCATOR_DATA",
'Invalid "id": '+b.id)}else if(c&&b.selector)if(c instanceof URL)f=c;else try{f=new URL(c)}catch(t){return new y("INVALID_URL","Invalid baseUrl: "+c)}else return new y("INVALID_LOCATOR_DATA",'Missing "source" or "id"');m||(m=r&&"undefined"!==typeof b.selector?a.g.createFromSelectorData(b.selector):[]);return I(a,f,m)}function I(a,b,c){(b.hash||"#"!==b.hash)&&(a=a.g.createFromFragment(b.hash,c))&&(c=[a]);return new x(q.URLUtils.getUrlWithoutHash(b),c)}
function G(a,b,c){if(b instanceof x)return b;if(b instanceof URL)return I(a,b,[]);if(v(b))try{var f=new URL(b,c);return I(a,f,[])}catch(r){return new y("INVALID_URL",b)}else return b&&!u(b)&&"object"===typeof b?H(a,b,c):new y("INVALID_LOCATOR_DATA","Must be an object or string. Got: "+typeof b)};api={Locator:x,LocatorError:y,LocatorErrorType:{INVALID_URL:"INVALID_URL",INVALID_LOCATOR_DATA:"INVALID_LOCATOR_DATA",UNABLE_TO_CREATE_RANGE_SELECTORS:"UNABLE_TO_CREATE_RANGE_SELECTORS"},LocatorFactory:F};var J=new ArrayBuffer(8);new DataView(J);new Uint8Array(J); }).call(__self); return api; } ) );
