/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IVanillaReaderUI} from "./IVanillaReaderUI";
import {IVanillaReaderHighlightData} from "../VanillaReader/VanillaReaderModel";
import {
    IVanillaReaderAppEvent_HIGHLIGHT_ADD_INTENT,
    IVanillaReaderAppEvent_HIGHLIGHT_DELETE_INTENT,
    IVanillaReaderAppEvent_HIGHLIGHT_UPDATE_INTENT,
    VanillaReaderAppEvents,
    VanillaReaderEventBus
} from "../VanillaReader/VanillaReaderEventBus";
import {IVanillaReaderUiComponentController} from "./IVanillaReaderUiComponentController";
import {VanillaReaderUI} from "./VanillaReaderUI";

export interface VanillaReaderUiDialogHighlightController extends IVanillaReaderUiComponentController, IVanillaReaderUI {}

/**
* # VanillaReaderUiDialogHighlightController
*
* ## RESPONSIBILITIES
* This controller mixing class handles all event callbacks from the `VanillaReaderUiDialogHighlight` class.
 *
* Just as all other controller mixin classes, this class is used to add functionality to the `VanillaReaderUi` class
* in a "modular" way. Read more about this in the `UI Components and Controller Mixins` in the README.md file.
*
* ## RELATED TYPES
* - VanillaReaderUi
* - VanillaReaderUiDialogHighlight
*
* */
export class VanillaReaderUiDialogHighlightController {

    static __initialize__(vanillaReaderUiContext: VanillaReaderUI) {
        vanillaReaderUiContext.dialogHighlight.onHighlightAdd(vanillaReaderUiContext._onDialogHighlightAdd.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.dialogHighlight.onHighlightDelete(vanillaReaderUiContext._onDialogHighlightDelete.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.dialogHighlight.onHighlightUpdate(vanillaReaderUiContext._onDialogHighlightUpdate.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.dialogHighlight.onGotoHighlightLocation(vanillaReaderUiContext._onDialogHighlightGoToLocation.bind(vanillaReaderUiContext));
    }

    _onDialogHighlightAdd(highlight: IVanillaReaderHighlightData){
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_HIGHLIGHT_ADD_INTENT>(new CustomEvent(VanillaReaderAppEvents.HIGHLIGHT_ADD_INTENT, {
            detail: {highlight},
        }));
    };

    _onDialogHighlightDelete (highlight: IVanillaReaderHighlightData) {
        VanillaReaderEventBus.dispatchEvent(new CustomEvent<IVanillaReaderAppEvent_HIGHLIGHT_DELETE_INTENT>(VanillaReaderAppEvents.HIGHLIGHT_DELETE_INTENT, {detail: {selector: highlight.locator}}));
    };

    _onDialogHighlightUpdate (highlight: IVanillaReaderHighlightData) {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_HIGHLIGHT_UPDATE_INTENT>(new CustomEvent(VanillaReaderAppEvents.HIGHLIGHT_UPDATE_INTENT, {
            detail: {highlight},
        }));
    };

    _onDialogHighlightGoToLocation (locator: string) {
        this.dialogHighlight.close(true, false);
        this.navigateTo(locator);
    };
}