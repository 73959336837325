/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
/***
 * Sync Media Player
 */

import {ILocator, ILocatorData} from '@colibrio/colibrio-reader-framework/colibrio-core-locator';

import {
    IContentLocation,
    IFetchNavigationItemReferencesResult,
    IReaderPublication,
    IReaderPublicationNavigationItemReference,
    IReaderViewAnnotationLayer,
    IReadingSystemEngine,
    ISyncMediaPlayer,
    NavigationCollectionType,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';
import {
    ISyncMediaTtsTimelineBuilderOptions,
    SyncMediaTimelinePosition, WebSpeechTtsSynthesizer,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-engine';
import {
    IEpubMediaOverlaySyncMediaTimelineOptions,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-formatadapter-epub';
import {
    VanillaReaderAppEvents,
    VanillaReaderEventBus,
} from './VanillaReaderEventBus';
import {
    IVanillaReaderNavigationItem,
    IVanillaReaderSyncMediaOptions,
    IVanillaReaderTtsOptions,
    IVanillaSyncMediaPlaybackStateData,
    VanillaMediaPlayerPlaybackStateChangedCallback,
    VanillaProgressEventCallback,
    VanillaVoidCallback
} from './VanillaReaderModel';
import {VanillaReaderUtils} from './VanillaReaderUtils';
import {VanillaReaderView} from "./VanillaReaderView";
import {VanillaReaderPublication} from "./VanillaReaderPublication";
import {VanillaReaderAppUiDefaults} from "../VanillaReaderUI/VanillaReaderAppUiDefaults";
import {WebSpeechUtils} from "../utils/WebSpeechUtils";

/*
* # VanillaReaderMediaPlayer
*
* ## PRIMARY COLIBRIO FRAMEWORK TYPES
*
* - ISyncMediaPlayer
* - ISyncMediaTimeline
* - IReaderPublication
* - IReaderViewAnnotationLayer
*
* ## RESPONSIBILITIES
*
* Wraps the Colibrio Reader Framework's `SyncMediaPlayer` and the `SyncMediaTimeline` to expose a more high level API
* for playback of TTS, Media Overlays.
*
* The `VanillaReaderMediaPlayer` API exposes all the familiar methods for media playback such as `play()`, `pause()`,
* `seekToNext()` and `seekToPrevious`, but you can also seek to a locator using `seekToLocator()`.
*
* * ## NOTES:
*
* For publications that are very long or very complex, the TTS timeline can take a while to build.
* To make it possible for you to design a good user experience around this, you can set a progress callback using the
* `timelineCreationProgressCallback()` method. This callback is called continuously with a number between 0 and 1
* indication the current state of the creation process.
*
* Audiobooks have their own media player called implementation `VanillaReaderAudiobookPlayer`
*
* */

export class VanillaReaderMediaPlayer {

    playerIsAttachedToView: boolean = false;

    private _colibrioReadingSystem: IReadingSystemEngine;
    private _colibrioReaderPublication: IReaderPublication | null = null;
    private _colibrioSyncMediaPlayer: ISyncMediaPlayer | null = null;
    private _vanillaReaderPublication: VanillaReaderPublication | null = null;
    private _syncMediaTtsColibrioAnnotationLayer: IReaderViewAnnotationLayer;
    private _currentActiveNavigationItems: IVanillaReaderNavigationItem[] | undefined;
    private _mediaPlayerPlaybackStateChangedCallback: VanillaMediaPlayerPlaybackStateChangedCallback | undefined;
    private _ttsVoice: SpeechSynthesisVoice | undefined;

    private _attachedCallback: VanillaVoidCallback | undefined;
    private _detachedCallback: VanillaVoidCallback | undefined;
    private _createdCallback: VanillaVoidCallback | undefined;

    constructor(
        private _vanillaReaderView: VanillaReaderView,
        private _syncMediaOptions?: IVanillaReaderSyncMediaOptions,
        private _timelineCreationProgressCallback?: VanillaProgressEventCallback,
    ) {
        this._colibrioReadingSystem = this._vanillaReaderView.colibrioReaderView.getReadingSystemEngine();
        this._vanillaReaderPublication = this._vanillaReaderView.getVanillaReaderPublication();
        this._syncMediaTtsColibrioAnnotationLayer = this._vanillaReaderView.colibrioReaderView.createAnnotationLayer('syncmedia-tts-highlights');

        this._create().catch(console.warn);
    }

    onPlaybackStateChanged(callback: VanillaMediaPlayerPlaybackStateChangedCallback) {
        this._mediaPlayerPlaybackStateChangedCallback = callback;
    }

    onAttached(callback: VanillaVoidCallback) {
        this._attachedCallback = callback;
    }

    onDetached(callback: VanillaVoidCallback) {
        this._detachedCallback = callback;
    }

    onCreated(callback: VanillaVoidCallback) {
        this._createdCallback = callback;
    }

    attach() {
        if (this._colibrioSyncMediaPlayer) {
            this._vanillaReaderView.colibrioReaderView.setSyncMediaPlayer(this._colibrioSyncMediaPlayer);
            this._syncMediaTtsColibrioAnnotationLayer?.setVisible(true);
            this.playerIsAttachedToView = true;

            if (this._attachedCallback) {
                this._attachedCallback();
            }
        }
    }

    detach() {
        if (this._colibrioSyncMediaPlayer) {
            this._vanillaReaderView.colibrioReaderView.setSyncMediaPlayer(null);
            this._syncMediaTtsColibrioAnnotationLayer?.setVisible(false);
            this.playerIsAttachedToView = false;

            if (this._detachedCallback) {
                this._detachedCallback();
            }
        }
    }

    toggle(attach: boolean) {
        if (attach) {
            this.attach();
        } else {
            this.detach();
        }
    }

    play() {
        if (this._colibrioSyncMediaPlayer && this.playerIsAttachedToView) {
            this._colibrioSyncMediaPlayer.play();

            if (this._mediaPlayerPlaybackStateChangedCallback) {
                this._getPlaybackStateData().then((state: IVanillaSyncMediaPlaybackStateData) => {
                    if (state && this._mediaPlayerPlaybackStateChangedCallback) {
                        this._mediaPlayerPlaybackStateChangedCallback(state);
                    }
                }).catch(console.warn);
            }
        }
    }

    pause() {
        if (this._colibrioSyncMediaPlayer && this.playerIsAttachedToView) {
            this._colibrioSyncMediaPlayer.pause();

            if (this._mediaPlayerPlaybackStateChangedCallback) {
                this._getPlaybackStateData().then((state: IVanillaSyncMediaPlaybackStateData) => {
                    if (state && this._mediaPlayerPlaybackStateChangedCallback) {
                        this._mediaPlayerPlaybackStateChangedCallback(state);
                    }
                }).catch(console.warn);
            }
        }
    };

    seekToNext() {
        if (this._colibrioSyncMediaPlayer && this.playerIsAttachedToView) {
            this._colibrioSyncMediaPlayer.seekToNextSegment(true);
        }
    };

    seekToPrevious() {
        if (this._colibrioSyncMediaPlayer && this.playerIsAttachedToView) {
            this._colibrioSyncMediaPlayer.seekToPreviousSegment(true);
        }
    };

    seekForward(offsetMs: number = 10000) {
        if (this._colibrioSyncMediaPlayer && this.playerIsAttachedToView) {
            this._colibrioSyncMediaPlayer.seekToApproximateTimeMs(this._colibrioSyncMediaPlayer.getApproximateElapsedTimeMs() + offsetMs);
        }
    };

    seekBackward(offsetMs: number = 10000) {
        if (this._colibrioSyncMediaPlayer && this.playerIsAttachedToView) {
            this._colibrioSyncMediaPlayer.seekToApproximateTimeMs(this._colibrioSyncMediaPlayer.getApproximateElapsedTimeMs() - offsetMs);
        }
    };

    seekToApproximateMs(ms: number) {
        if (this._colibrioSyncMediaPlayer && this.playerIsAttachedToView) {
            this._colibrioSyncMediaPlayer.seekToApproximateTimeMs(ms);
        }
    };

    setVolume(volume: number) {
        if (this._colibrioSyncMediaPlayer && this.playerIsAttachedToView) {
            this._colibrioSyncMediaPlayer.setVolume(volume);
        }
    };

    setPlaybackRate(rate: number) {
        if (this._colibrioSyncMediaPlayer && this.playerIsAttachedToView) {
            this._colibrioSyncMediaPlayer.setPlaybackRate(rate);
        }
    }

    async setTtsVoiceByName(voiceName: string | undefined) {
        let voicesCollection = await WebSpeechUtils.fetchTtsVoices();
        let voices = voicesCollection.allVoices;

        if(!voiceName) {
            this._ttsVoice = undefined;
            return;
        }

        this._ttsVoice = voices.find((voice: SpeechSynthesisVoice)=>{
            return  voice.name.includes(voiceName);
        });
    }

    async seekToLocatorWithinVisibleRange(locator: ILocator) {

        const syncMediaPlayer = this._colibrioSyncMediaPlayer;
        let visibleRange = this._vanillaReaderView.getVisibleRange();
        if (syncMediaPlayer && this.playerIsAttachedToView && visibleRange) {

            let timeline = syncMediaPlayer.getTimeline();
            const visibleRangeStartTimelinePosition = await timeline.fetchTimelinePosition(visibleRange.collapseToStart());
            const pos = await timeline.fetchTimelinePosition(locator);
            const contentLocation = await timeline.fetchContentLocation(pos);

            if (visibleRange.contains(contentLocation)) {
                const posAtStartOfSegment = new SyncMediaTimelinePosition(pos.getTimeline(), pos.getSegmentIndex(), 0);

                if (posAtStartOfSegment.isBefore(visibleRangeStartTimelinePosition)) {
                    syncMediaPlayer.seekToTimelinePosition(visibleRangeStartTimelinePosition);
                } else {
                    syncMediaPlayer.seekToTimelinePosition(posAtStartOfSegment);
                }

            }
        }
    }

    async seekToLocator(locator: ILocator | ILocatorData) {
        if (this._colibrioSyncMediaPlayer && this.playerIsAttachedToView) {

            const mediaPlayer = this._vanillaReaderView.colibrioReaderView.getSyncMediaPlayer();
            if (mediaPlayer && locator) {
                let timeline = mediaPlayer.getTimeline();
                let pos = await timeline.fetchTimelinePosition(locator);
                // Always seek to beginning of segment by setting offsetMs to 0
                let newPosition = new SyncMediaTimelinePosition(pos.getTimeline(), pos.getSegmentIndex(), 0);
                mediaPlayer.seekToTimelinePosition(newPosition);
            }
        }
    }

    destroy() {
    }

    public get isPaused(): boolean {
        if (this._colibrioSyncMediaPlayer) {
            return this._colibrioSyncMediaPlayer.isPaused();
        }
        return true;
    }

    public getTtsPlaybackOptions(): IVanillaReaderTtsOptions {
        return {
            rate: this._colibrioSyncMediaPlayer?.getPlaybackRate() || 1,
            volume: this._colibrioSyncMediaPlayer?.getVolume() || 1,
            highlightColor: this._vanillaReaderPublication?.ttsOptions?.highlightColor || VanillaReaderAppUiDefaults.highlightColorValues.yellow,
            voiceName: this._ttsVoice?.name,
        };
    }

    /*
    *
    * PRIVATE METHODS
    *
    * */

    private _setUpEventHandlers() {
    }

    private async _create() {
        this._setUpEventHandlers();
        this._colibrioReaderPublication = this._vanillaReaderView.colibrioReaderView.getReaderPublications()[0];

        if (this._colibrioReaderPublication && (VanillaReaderUtils.isEpubReaderPublication(this._colibrioReaderPublication) || VanillaReaderUtils.isPdfReaderPublication(this._colibrioReaderPublication))) {

            // Let's see if this is publication has Media Overlays, if so we create a `IEpubMediaOverlaySyncMediaTimeline`
            if (VanillaReaderUtils.isEpubReaderPublication(this._colibrioReaderPublication) && this._colibrioReaderPublication.isMediaOverlaySyncMediaAvailable()) {
                let moOptions: IEpubMediaOverlaySyncMediaTimelineOptions = {
                    defaultAudioRendererOptions: {
                        enableMediaStreaming: true,
                    },
                };
                this._colibrioReaderPublication.createMediaOverlaySyncMediaTimeline(this._vanillaReaderView.colibrioReaderView.getReaderDocuments(), moOptions).then(timeline => {
                    this._colibrioSyncMediaPlayer = this._colibrioReadingSystem.createSyncMediaPlayer(timeline);
                    this.attach();

                    if(this._syncMediaOptions?.rate) {
                        this.setPlaybackRate(this._syncMediaOptions.rate)
                    }

                    if(this._syncMediaOptions?.volume) {
                        this.setPlaybackRate(this._syncMediaOptions.volume)
                    }

                    if (this._createdCallback) {
                        this._createdCallback();
                    }

                }).catch(console.error);

            } else {
                // This publication has no Media Overlay so let's create a TTS timeline instead.

                // In order to use the correct `SpeechSynthesisVoice` instance as specified in the `VanillaReaderPublication`'s
                // `ttsOptions.voiceName` string, we need to get all available voices.
                let voicesCollection = await WebSpeechUtils.fetchTtsVoices();
                let voices = voicesCollection.allVoices;

                // Now we filter out the matching `SpeechSynthesisVoice` instance.
                this._ttsVoice = voices.find((voice: SpeechSynthesisVoice)=>{
                    return this._vanillaReaderPublication?.ttsOptions?.voiceName ? voice.name.includes(this._vanillaReaderPublication.ttsOptions.voiceName) : false;
                });

                let ttsSpeechSynth = new WebSpeechTtsSynthesizer((utterance: SpeechSynthesisUtterance)=>{
                    if(this._ttsVoice) {
                        utterance.lang = this._ttsVoice.lang;
                        utterance.voice = this._ttsVoice;
                    }
                });

                // The TTS SyncMedia can use an annotation layer to highlight the current reading position. Let's set up
                // the layer and set the styling options for the layer and the highlight annotations.
                this._syncMediaTtsColibrioAnnotationLayer = this._vanillaReaderView.colibrioReaderView.createAnnotationLayer('syncmedia-tts-highlights');
                this._syncMediaTtsColibrioAnnotationLayer.setLayerOptions({
                    layerStyle: {
                        'mix-blend-mode': 'multiply',
                        opacity: '1',
                    },
                });
                this._syncMediaTtsColibrioAnnotationLayer.setDefaultAnnotationOptions({
                    rangeStyle: {
                        'background-color': VanillaReaderAppUiDefaults.highlightColorValues.yellow,
                        'opacity': '0.4',
                    },
                });

                let syncMediaTimelineOptions: ISyncMediaTtsTimelineBuilderOptions = {
                    defaultTtsHighlightLayer: this._syncMediaTtsColibrioAnnotationLayer,
                    defaultTtsRendererOptions: {
                        wordHighlightEnabled: true,
                        segmentHighlightEnabled: true,
                        wordHighlightAnnotationOptions: {
                            rangeStyle: {
                                'filter': 'brightness(1)',
                                'opacity': '1',
                            },
                        },
                    },
                    defaultTtsSynthesizer: ttsSpeechSynth
                };

                // Now that we have the highlight layer set up we can create the SyncMedia Timeline.
                this._colibrioReaderPublication.createTtsSyncMediaTimeline(this._vanillaReaderView.colibrioReaderView.getReaderDocuments(), syncMediaTimelineOptions, this._timelineCreationProgressCallback).then(timeline => {
                    this._colibrioSyncMediaPlayer = this._colibrioReadingSystem.createSyncMediaPlayer(timeline);
                    this.attach();
                    this.playerIsAttachedToView = true;

                    // TODO: Implement callbacks for this event
                    VanillaReaderEventBus.dispatchEvent(new CustomEvent(VanillaReaderAppEvents.MEDIAPLAYER_CREATED));

                    // TODO: Implement callbacks for this event
                    this._colibrioSyncMediaPlayer.addEngineEventListener('syncMediaEndReached', (ev) => {
                        console.log('syncMediaEndReached', ev);
                    });
                    // TODO: Implement callbacks for this event
                    this._colibrioSyncMediaPlayer.addEngineEventListener('syncMediaError', (ev) => {
                        console.log('syncMediaError', ev);
                    });

                    if(this._syncMediaOptions?.rate) {
                        this.setPlaybackRate(this._syncMediaOptions.rate)
                    }

                    if(this._syncMediaOptions?.volume) {
                        this.setPlaybackRate(this._syncMediaOptions.volume)
                    }
                }).catch(console.error);

            }

        } else {
            console.warn('VanillaReader.syncMediaPlayer_create()', 'Cannot create SyncMediaPlayer. No Colibrio ReaderPublication loaded.');
        }
    };

    private async _getPlaybackStateData(): Promise<IVanillaSyncMediaPlaybackStateData> {
        let positionMs = this._colibrioSyncMediaPlayer?.getApproximateElapsedTimeMs() || 0;
        let position = this._colibrioSyncMediaPlayer?.getTimelinePosition();
        let location: IContentLocation | undefined;
        if (position) {
            location = await this._colibrioSyncMediaPlayer?.getTimeline()?.fetchContentLocation(position);
        }
        let rate = this._colibrioSyncMediaPlayer?.getPlaybackRate();
        let volume = this._colibrioSyncMediaPlayer?.getVolume();
        let isSeeking = this._colibrioSyncMediaPlayer?.isSeeking();
        let isMuted = this._colibrioSyncMediaPlayer?.isMuted();
        let isPaused = this._colibrioSyncMediaPlayer?.isPaused();
        let locator: string | undefined;
        let navigationItems: IVanillaReaderNavigationItem[] = [];
        let navigationItemRefs: IFetchNavigationItemReferencesResult | undefined;
        let segmentData = this._colibrioSyncMediaPlayer?.getTimelinePosition().getSegment()?.toJSON();
        let publicationDurationMs = this._colibrioSyncMediaPlayer?.getTimeline();

        if (!this._currentActiveNavigationItems) {
            navigationItemRefs = await location?.fetchNavigationItemReferences({
                collectionTypes: [NavigationCollectionType.TOC],
                greedy: false,
            });

            navigationItemRefs?.getItemsInRange()?.forEach((ref: IReaderPublicationNavigationItemReference) => {
                let timelineStartPosition: number | undefined;
                navigationItems.push({
                    locator: ref.getNavigationItem()?.getLocator()?.toString(),
                    children: [],
                    timelineStartPosition,
                    collectionType: ref.getNavigationCollection().getType(),
                    title: ref.getNavigationItem().getTextContent(),
                });

            });

            // Store this value for upcoming calls. The `this._currentActiveNavigationItems` field is "reset" in the
            // `_event_segmentActive` event handler, when we have a new segment.
            this._currentActiveNavigationItems = navigationItems;
        } else {
            navigationItems = this._currentActiveNavigationItems;
        }

        if (location) {
            locator = location?.getLocator().toString();
        }

        return Promise.resolve({
            locator,
            position: positionMs,
            navigationItems,
            rate,
            volume,
            isSeeking,
            isMuted,
            isPaused,
            segmentData,
            publicationDurationMs,
        } as IVanillaSyncMediaPlaybackStateData);
    }

    /*
    *
    * EVENT HANDLERS
    *
    * */

}
