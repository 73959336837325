/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.4.0
 */
 'use strict'; (function (s, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-base','./colibrio-core-io-base'], function (a0,a1) { return f(s,a0,a1); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(s,require('./colibrio-core-base'),require('./colibrio-core-io-base')); } else { throw new Error('Unsupported runtime environment!'); } }( typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function (__self,__dep__colibrio_core_base,__dep__colibrio_core_io_base) {  var api = {}; (function () { var l="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},m;if("function"==typeof Object.setPrototypeOf)m=Object.setPrototypeOf;else{var p;a:{var q={a:!0},r={};try{r.__proto__=q;p=r.a;break a}catch(a){}p=!1}m=p?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var t=m;var w=__dep__colibrio_core_base;var x=__dep__colibrio_core_io_base;var y=new Uint16Array(1);y[0]=4660;new Uint8Array(y.buffer);function z(a,b,c){a=w.ColibrioError.call(this,"colibrio.core.xml",a,c)||this;a.parserErrorDocument=b;return a}var A=w.ColibrioError;z.prototype=l(A.prototype);z.prototype.constructor=z;if(t)t(z,A);else for(var B in A)if("prototype"!=B)if(Object.defineProperties){var C=Object.getOwnPropertyDescriptor(A,B);C&&Object.defineProperty(z,B,C)}else z[B]=A[B];function D(){}D.prototype.parseHtmlString=function(a,b){a=E().parseFromString(a,x.MediaType.TEXT_HTML);G(a,b);return a};D.prototype.parseXhtmlString=function(a,b){a=E().parseFromString(a,x.MediaType.APPLICATION_XHTML);G(a,b);return a};D.prototype.parseXmlString=function(a,b){a=E().parseFromString(a,x.MediaType.APPLICATION_XML);G(a,b);return a};function G(a,b){H(a.documentElement,b);H(a.documentElement.firstElementChild,b);a.body&&H(a.body.firstElementChild,b)}
function H(a,b){if(a&&"parsererror"===a.localName)throw new z("DOM_PARSE_ERROR",a.ownerDocument,'"'+b+'": '+(a.textContent||""));}var I;function E(){I||(I=new DOMParser);return I};function J(a){this.h=a;this.j="";this.i=this.g=0;this.l=a.length}function K(a){for(;62!==a.h.charCodeAt(a.g++););}
function L(a,b){var c=a.g;for(var e;(e=a.h.charCodeAt(++a.g))&&9!==e&&10!==e&&12!==e&&32!==e&&47!==e&&62!==e;);c=b?a.h.slice(c,a.g):"";e=!0;for(var d=!1;e;){var h=a.h.charCodeAt(a.g++);if(34===h||39===h)for(var f=a,g=f.h.charCodeAt(f.g++);g!==h;)g=f.h.charCodeAt(f.g++);else 47===h?(h=a.h.charCodeAt(a.g),62===h&&(d=!0,e=!1,a.g++)):62===h?e=!1:h||(e=!1)}b&&d&&!O.has(c.toLowerCase())&&(a.j+=a.h.slice(a.i,a.g-2)+"></"+c+">",a.i=a.g)}var O=new Set("area base br col embed hr img input link meta param source track wbr".split(" "));function P(a,b,c){this.h=this.g=this.i=null;this.j="";var e=new D,d=!1;if(!c){if((c=/^([\s\S]+?)<html/i.exec(a))&&c[1]){c=c[1];var h=/^<\?xml[\s\S]+?\?>/.exec(c);h&&h[0]&&(this.j=h[0]);/<!DOCTYPE\s+html\s*[>\[]/i.test(c)||(a=a.replace(/^[\s\S]+?<html/i,this.j+"<!DOCTYPE html>\n<html"))}try{var f=e.parseXhtmlString(a,b);"html"===f.documentElement.localName&&f.documentElement.style?this.h=f:w.Logger.logWarning(new z("INVALID_XHTML_DOCUMENT",null,'path="'+b+'" rootTagName="'+f.documentElement.localName+
'"'))}catch(u){w.Logger.logWarning(u),w.Logger.logWarning(new z("INVALID_XHTML_DOCUMENT",null,'path="'+b+'"')),this.g=u.parserErrorDocument||null,d=!0}}if(!this.h)try{if(d){w.Logger.logInfo("Falling back to HTML parser for: "+b);for(var g=new J(a);g.g<g.l;)if(60===g.h.charCodeAt(g.g++)){var n=g.h.charCodeAt(g.g);if(65<=n&&90>=n||97<=n&&122>=n)L(g,!0);else if(47===n){g.g++;d=g;var v=d.h.charCodeAt(d.g);65<=v&&90>=v||97<=v&&122>=v?L(d,!1):62===v?d.g++:K(d)}else if(63===n)K(g);else if(33===n){g.g++;
d=g;var M=d.h.charCodeAt(d.g);if(45===M&&45===d.h.charCodeAt(d.g+1))for(d.g+=2,f=!0,c=1;f;){var k=d.h.charCodeAt(d.g++);switch(c){case 1:45===k?c=2:62===k?f=!1:(d.g--,c=3);break;case 2:45===k?c=8:62===k?f=!1:(d.g--,c=3);break;case 3:60===k?c=4:45===k?c=7:"undefined"===typeof k&&(f=!1);break;case 4:33===k?c=5:60!==k&&(d.g--,c=3);break;case 5:45===k?c=6:(d.g--,c=3);break;case 6:45===k?c=8:(d.g--,c=7);break;case 7:45===k?c=8:(d.g--,c=3);break;case 8:62===k?f=!1:33===k?c=9:45!==k&&(d.g--,c=3);break;case 9:45===
k?c=7:62===k?f=!1:(d.g--,c=3)}}else if(91===M&&"[CDATA["===d.h.slice(d.g,d.g+7))for(d.g+=7,f=!0;f;){var N=d.h.charCodeAt(d.g++);93===N&&93===d.h.charCodeAt(d.g)&&62===d.h.charCodeAt(d.g+1)?(d.g+=2,f=!1):"undefined"===typeof N&&(f=!1)}else K(d)}else g.g++}a=0===g.i?g.h:g.j+g.h.slice(g.i)}var F=e.parseHtmlString(a,b);"html"===F.documentElement.localName&&F.documentElement.style&&(this.i=F)}catch(u){w.Logger.logError(u),w.Logger.logError(new z("INVALID_HTML_DOCUMENT",null,'path="'+b+'"')),this.g||(this.g=
u.parserErrorDocument||null)}}P.prototype.getDocument=function(){return this.h||this.i};P.prototype.getParserError=function(){return this.g};P.prototype.getXmlHeader=function(){return this.j};P.prototype.isHtmlDocument=function(){return null!==this.i};P.prototype.isXhtmlDocument=function(){return null!==this.h};function Q(a){return a.replace(/["&'<>]/g,function(b){switch(b){case '"':return"&quot;";case "&":return"&amp;";case "'":return"&apos;";case "<":return"&lt;";case ">":return"&gt;"}return""})}
function R(a,b,c){switch(b.nodeType){case 1:c.push("<",b.tagName);var e=b.attributes;if(0<e.length)for(var d=0;d<e.length;d++){var h=e[d];c.push(" ",Q(h.name),'="',Q(h.value),'"')}a:{if(b.namespaceURI===w.XmlNamespace.HTML)switch(b.localName){case "area":case "base":case "br":case "col":case "command":case "embed":case "hr":case "img":case "input":case "keygen":case "link":case "menuitem":case "meta":case "param":case "source":case "track":case "wbr":e=!0;break a}e=!1}if(e)c.push(" />");else{c.push(">");
e="script"===b.localName;d=b.childNodes;for(h=0;h<d.length;h++){var f=d[h];!e||3!==f.nodeType&&4!==f.nodeType?R(a,f,c):c.push(f.data.replace(/<\/script>/g,"&lt;/script>"))}c.push("</"+b.tagName+">")}break;case 3:c.push(Q(b.data));break;case 4:c.push(Q(b.data));break;case 8:c.push("\x3c!--",Q(b.data.replace(/--/g,"- "))||" ","--\x3e");break;case 9:b=b.childNodes;for(e=0;e<b.length;e++)R(a,b[e],c);break;case 10:b.name&&(c.push("<!DOCTYPE ",b.name),b.publicId&&c.push(' PUBLIC "',b.publicId,'"'),b.systemId&&
c.push(' "',b.systemId,'"'),c.push(">"));break;default:throw new z("UNSUPPORTED_NODE_TYPE",null,""+b.nodeType);}};var S=new function(){},T=new XMLSerializer;function U(){}U.prototype.serializeXml=function(a){var b=!0;a&&9===a.nodeType&&"HTML"===a.documentElement.tagName?b=!1:a&&1===a.nodeType&&a.namespaceURI===w.XmlNamespace.HTML&&a.tagName!==a.tagName.toLowerCase()&&(b=!1);b?a=T.serializeToString(a):(b=[],R(S,a,b),a=b.join(""));return a};api={CoreXmlError:z,CoreXmlErrorType:{DOM_PARSE_ERROR:"DOM_PARSE_ERROR",INVALID_HTML_DOCUMENT:"INVALID_HTML_DOCUMENT",INVALID_XHTML_DOCUMENT:"INVALID_XHTML_DOCUMENT",UNSUPPORTED_NODE_TYPE:"UNSUPPORTED_NODE_TYPE"},DomParser:D,XhtmlParser:P,XmlSerializer:U};var V=new ArrayBuffer(8);new DataView(V);new Uint8Array(V); }).call(__self); return api; } ) );
