export class Cache {

  private static cache: Map<string, any>;

  private constructor() {} // Private constructor to prevent instantiation

  /**
   * Initializes the cache map if it hasn't been initialized yet.
   */
  private static initialize(): void {
      if (!Cache.cache) {
          Cache.cache = new Map<string, any>();
      }
  }

  /**
   * Stores data in the cache with a specified key.
   * @param key The key to store the data with.
   * @param data The data to store in the cache.
   */
  public static set(key: string, data: any): void {
      Cache.initialize();
      Cache.cache.set(key, data);
  }

  /**
   * Retrieves data from the cache with the specified key.
   * @param key The key to retrieve data for.
   * @returns The data stored in the cache with the specified key, or undefined if not found.
   */
  public static get(key: string): any | undefined {
      Cache.initialize();
      return Cache.cache.get(key);
  }

  /**
   * Removes data from the cache with the specified key.
   * @param key The key to remove data for.
   */
  public static remove(key: string): void {
      Cache.initialize();
      Cache.cache.delete(key);
  }

  /**
   * Checks if data with the specified key exists in the cache.
   * @param key The key to check for existence.
   * @returns True if data with the specified key exists in the cache, false otherwise.
   */
  public static exists(key: string): boolean {
      Cache.initialize();
      return Cache.cache.has(key);
  }

  /**
   * Clears all data from the cache.
   */
  public static clear(): void {
      Cache.initialize();
      Cache.cache.clear();
  }
}
