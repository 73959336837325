/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {VanillaReaderUiDialog} from './VanillaReaderUiDialog';

/**
 * # VanillaReaderUiDialogSearchResult
 *
 * ## RESPONSIBILITIES
 *
 *
 */

export class VanillaReaderUiDialogHelp extends VanillaReaderUiDialog<void> {

    private _bodyStyle = `
        <style>    
            #vanilla-reader__dialog-help section {
               margin-bottom: 2em;
               line-height: 1.5em;
            }            
            #vanilla-reader__dialog-help section h2 {
               font-size: 18px;
            }
            #vanilla-reader__dialog-help section h3 {
               font-size: 16px;
            }
            #vanilla-reader__dialog-help kbd {
                border: 1px solid var(--vanilla-reader__color__ui__outline-dark);
                border-radius: 4px;
                padding: 4px;
                background-color: rgba(256,256,256,0.2);;
            }            
            #vanilla-reader__dialog-help a {            
                color: var(--vanilla-reader__color__ui__accent);
            }

        </style>`;
    private _bodyTemplate = `
        <section>
            <a href="#vanilla-reader__dialog-help__keyboard-shortcuts">Jump to keyboard shortcuts</a>
            <h2>
                Note to screen reader users
            </h2>
            
            <p>
                For a good reading experience we recommend that you use the <kbd>Alt</kbd> + <kbd>Z</kbd> / <kbd>Ctrl</kbd> + <kbd>Option</kbd> + <kbd>Z</kbd> shortcut to enter 
                into the screen reader accessibility preset mode. This mode is simply a reading configuration that uses
                the scroll renderer, increases the text font size and turns off unnecessary animations. In this mode, 
                the Read Aloud controls are also active. These controls let you use the built-in Colibrio
                Text to Speech functionality. This will handle all navigation, so you can just listen to your book without
                care.
            </p>
            
            <h3>Note on reading controls</h3>
            <p>
                In order for Colibrio to properly determine where the screen reader is focused, you will need to read one
                object (paragraph, heading or section) at a time. Using this reading method bookmarks, highlights and
                so much more will work well.
            </p>
            <p>
                If you just want to listen continuously, without pressing keyboard buttons to move through the text, we 
                recommend using our build in Text to Speech (<kbd>Alt</kbd> + <kbd>R</kbd>). When you stop the built-in TTS (<kbd>Alt</kbd> + <kbd>Shift</kbd> + <kbd>R</kbd>), 
                we will try to focus the screen reader as close as possible to the current reading position.
            </p>
            
            <h3>Android specifics</h3>
            <p>
                To navigate to the next or previous you can swipe left or right using two fingers.
            </p>
            
        </section>
        <section>
            <h2 id="vanilla-reader__dialog-help__keyboard-shortcuts">
                Keyboard shortcuts (Windows / Mac)
            </h2>      
            
            <h3>General</h3>
            <ul tabindex="0">
                <li>                    
                    <h4>Open Help dialog (this one).</h4>                    
                    <span><kbd>Alt</kbd> + <kbd>F1</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>F1</kbd></span>
                </li>                          
                <li>                    
                    <h4>Open App actions dialog.</h4>
                    <span><kbd>Alt</kbd> + <kbd>Control</kbd> + <kbd>F2</kbd></span>
                </li>
                <li>                                    
                    <h4>Open Search dialog.</h4>
                    <span><kbd>Alt</kbd> + <kbd>S</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>S</kbd></span>
                </li>
                <li>                                    
                    <h4>Open Settings dialog.</h4>
                    <span><kbd>Alt</kbd> + <kbd>T</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>T</kbd></span>
                </li>
                <li>                
                    <h4>Close all dialogs and focus on reading position.</h4>
                    <span><kbd>Alt</kbd> + <kbd>X</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>X</kbd></span>
                </h4>
                <li>                                    
                    <h4>Open new book.</h4>
                    <span><kbd>Alt</kbd> + <kbd>Q</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>Q</kbd></span>                    
                </li>
                <li>                
                    <h4>Open the Main Menu.</h4>
                    <span><kbd>Alt</kbd> + <kbd>M</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>M</kbd></span>
                </li>
            </ul>

            <h3>Navigation</h3>
            <ul tabindex="0">
                <li>                    
                    <h4>Open Table of Contents.</h4>
                    <span><kbd>Alt</kbd> + <kbd>C</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>C</kbd></span>
                </li>
                <li>                    
                    <h4>Go back.</h4>
                    <span><kbd>Arrow Left</kbd></span>
                </li>
                <li>                    
                    <h4>Go forward.</h4>
                    <span><kbd>Arrow Right</kbd></span>
                </li>
                <li>                    
                    <h4>Go back.</h4>
                    <span><kbd>Alt</kbd> + <kbd>Arrow Up</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>Arrow Up</kbd></span>
                </li>
                <li>                    
                    <h4>Go forward.</h4>                    
                    <span><kbd>Alt</kbd> + <kbd>Arrow Down</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>Arrow Down</kbd></span>
                </li>
                <li>
                    <h4>Go to next heading.</h4>
                    <span><kbd>Alt</kbd> + <kbd>G</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>G</kbd></span>
                </li>                
                <li>                                    
                    <h4>Go to previous heading.</h4>
                    <span><kbd>Alt</kbd> + <kbd>Shift</kbd> + <kbd>G</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>Shift</kbd> + <kbd>G</kbd></span>
                </li>
                <li>                    
                    <h4>Go to a specific page in the book. Note, this only works if the book has a page list.</h4>                
                    <span><kbd>Alt</kbd> + <kbd>P</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>P</kbd></span>                
                </li>
            </ul>
                
            <h3>Bookmarking</h3>
            <ul tabindex="0">
                <li>
                    <h4>Bookmark reading location.</h4>
                    <span><kbd>Alt</kbd> + <kbd>B</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>B</kbd></span>
                </li>
                <li>
                    <h4>Bookmark text in clipboard. Perfect for screen readers such as NVDA. Please note the extra <kbd>Control</kbd> key modifier for this shortcut.</h4>
                    <span><kbd>Alt</kbd> + <kbd>Shift</kbd> + <kbd>B</kbd> / <kbd>Control</kbd> + <kbd>Shift</kbd> + <kbd>Option</kbd> + <kbd>B</kbd></span>
                </li>        
                <li>
                    <h4>Open Bookmarks dialog.</h4>
                    <span><kbd>Alt</kbd> + <kbd>O</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>O</kbd></span>
                </li>
                <li>
                    <h4>Show Visible Bookmarks dialog.</h4>            
                    <span><kbd>Alt</kbd> + <kbd>Shift</kbd> + <kbd>O</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>Shift</kbd> + <kbd>O</kbd></span>
                </li>
            </ul>    
                        
            <h3>Highlighting</h3>
            <ul tabindex="0">
                <li>
                    <h4>Highlight selected text.</h4>
                    <span><kbd>Alt</kbd> + <kbd>I</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>I</kbd></span>
                    <details>
                        <summary>NVDA and JAWS note</summary>
                        For NVDA and JAWS users, please use the "Highlight text in clipboard." (<kbd>Alt</kbd> + <kbd>Shift</kbd> + <kbd>Control</kbd> + <kbd>K</kbd>)
                    </details>
                </li>
                <li>
                    <h4>Highlight text in clipboard.</h4>
                    <span><kbd>Alt</kbd> + <kbd>Shift</kbd> + <kbd>Control</kbd> + <kbd>I</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>Shift</kbd> + <kbd>I</kbd></span>
                    
                </li>
                <li>
                    <h4>Show Highlights dialog.</h4> 
                    <span><kbd>Alt</kbd> + <kbd>H</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>H</kbd></span>
                </li>
                <li>
                    <h4>Show Visible Highlights dialog.</h4>                    
                    <span><kbd>Alt</kbd> + <kbd>Shift</kbd> + <kbd>H</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>Shift</kbd> + <kbd>H</kbd></span>
                </li>
            </ul>   
                        
            <h3>Accessibility</h3>
                <ul tabindex="0">
                    <li>
                        <h4>Use screen reader accessibility mode.</h4>
                        <span><kbd>Alt</kbd> + <kbd>Z</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>Z</kbd></span>
                    </li>
                    <li>
                        <h4>Exit screen reader accessibility mode.</h4>
                        <span><kbd>Alt</kbd> + <kbd>Shift</kbd> + <kbd>Z</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>Shift</kbd> + <kbd>Z</kbd></span>
                    </li>
                    <li>
                        <h4>Announce current reading position.</h4>
                        <span><kbd>Alt</kbd> + <kbd>W</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>W</kbd></span>
                    </li>            
                </ul>
                
            <h3>Read Aloud</h3>
                <ul tabindex="0">
                    <li>
                        <h4>Start Read Aloud.</h4>
                        <span><kbd>Alt</kbd> + <kbd>R</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>R</kbd></span>
                    </li>
                    <li>
                        <h4>End Read Aloud.</h4>
                        <span><kbd>Alt</kbd> + <kbd>Shift</kbd> + <kbd>R</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>Shift</kbd> + <kbd>R</kbd></span>  
                    </li>
                </ul>

            <h3>Zooming and Panning</h3>
                <ul tabindex="0">
                    <li>
                        <h4>Zoom in 20%.</h4>
                        <span><kbd>Alt</kbd> + <kbd>+</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>+</kbd></span>
                    </li>
                    <li>
                        <h4>Zoom out 20%.</h4>
                        <span><kbd>Alt</kbd> + <kbd>-</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>-</kbd></span>  
                    </li>
                    <li>
                        <h4>Zoom to rectangle.</h4>
                        <span><kbd>Alt</kbd> + <kbd>Shift</kbd> + <kbd>+</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>Shift</kbd> + <kbd>+</kbd></span>
                    </li>
                    <li>
                        <h4>Exit pan and zoom mode.</h4>
                        <span><kbd>Alt</kbd> + <kbd>Shift</kbd> + <kbd>-</kbd> / <kbd>Control</kbd> + <kbd>Option</kbd> + <kbd>Shift</kbd> + <kbd>-</kbd></span>  
                    </li>
                </ul>
                                                     
        </section>
        
        <section>
            <h2>About</h2>
            <p>
                The Imbiblio Reader is a demo implementation of the Colibrio Reader Framework that uses only "vanilla" 
                HTML and CSS. In other words it uses none of those fancy pants JS frameworks that mess up accessibility. 
            </p>
            <p>
                App version: 220922-1520
            </p>
        </section>`;

    constructor(hostElement: HTMLElement, insertPosition: InsertPosition = 'afterbegin') {
        super(hostElement, 'vanilla-reader__dialog-help', 'Imbiblio Reader Help', insertPosition);

        this._dialogElement.insertAdjacentHTML(insertPosition, this._bodyStyle);
        this._dialogBodyElement.innerHTML = this._bodyTemplate;

    }
}
