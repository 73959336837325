/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {VanillaReaderUiDialog} from './VanillaReaderUiDialog';
import {VanillaStringValueCallback} from "../VanillaReader/VanillaReaderModel";
import {VanillaUiDialogResultCallback} from "./VanillaReaderUiModel";

/**
 * # VanillaReaderUiDialogFootnote
 *
 * ## RESPONSIBILITIES
 *
 * This dialog takes in HTML markup and shows it.
 * It also takes in an id ref so that it can help navigate to the footnote.
 *
 * ## RELATED TYPES
 * - VanillaReaderUiDialogFootnoteController
 */

export class VanillaReaderUiDialogFootnote extends VanillaReaderUiDialog<string> {
    private _footnote: HTMLElement;
    private _buttonGotoFootnote: HTMLButtonElement;
    private _gotoFootnoteButtonClickedCallback: VanillaStringValueCallback | undefined;
    private _footnoteElementId: string | undefined;
    private _noteRefLocator: string | undefined;

    private _bodyStyle = `<style>
                
                #vanilla-reader__dialog-footnote + .vanilla-reader__modal-overlay {
                    background-color: transparent !important;
                }

                #vanilla-reader__dialog-footnote {
                    min-height: 20% !important;
                    height: 30% !important;
                    max-width: 600px!important;
                    bottom: 0 !important;
                    top: auto !important;
                    border-bottom-left-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                }
                #vanilla-reader__dialog-footnote__button-goto {
                    font-family: Lato;
                    margin-left: 0;
                }
            </style>`;

    private _bodyTemplate = `
            <aside id="vanilla-reader__dialog-footnote__content"></aside>`;

    private _footerTemplate = `
            <button id="vanilla-reader__dialog-footnote__button-goto">Go to footnote</button>`;

    constructor(hostElement: HTMLElement, insertPosition: InsertPosition = 'afterbegin') {
        super(hostElement, 'vanilla-reader__dialog-footnote', 'Footnote', insertPosition);

        this._dialogElement.insertAdjacentHTML('afterbegin', this._bodyStyle);
        this._dialogBodyElement.innerHTML = this._bodyTemplate;
        this._dialogFooterElement.innerHTML = this._footerTemplate;
        this._footnote = this._dialogBodyElement.querySelector('#vanilla-reader__dialog-footnote__content')!;
        this._buttonGotoFootnote = this._dialogFooterElement.querySelector('#vanilla-reader__dialog-footnote__button-goto')!;

        this._buttonGotoFootnote.addEventListener('click', () => {
            if (this._gotoFootnoteButtonClickedCallback && this._footnoteElementId) {
                this._gotoFootnoteButtonClickedCallback(this._footnoteElementId);
            }
        });
    }

    onGotoFootnoteButtonClicked(callback: VanillaStringValueCallback) {
        this._gotoFootnoteButtonClickedCallback = callback;
    }

    show(
        silent?: boolean,
        returnFocusOnClose: boolean = true,
        onDialogCloseCallbackOverride?: VanillaUiDialogResultCallback<string>,
        footnoteHTML?: string,
        footnoteElementId?: string,
        noteRefLocator?: string,
    ) {
        super.show(silent, returnFocusOnClose, onDialogCloseCallbackOverride);

        this._footnote.innerHTML = footnoteHTML || '🤔';
        this._footnoteElementId = footnoteElementId;
        this._noteRefLocator = noteRefLocator;

    }

    close(silent: boolean = false, returnDialogResult: boolean = true) {
        this._footnote.innerHTML = '';
        super.close(silent, returnDialogResult);
    }

    /*
    * Class internal
    * */

    // Return the element id of the noteRef element so that we can put focus back where it should be.
    _getDialogResult<T>(): T {
        // @ts-ignore
        return this._noteRefLocator;
    }

}
