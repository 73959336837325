/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IRandomAccessDataSource} from '@colibrio/colibrio-reader-framework/colibrio-core-io-base';
import {
    IVanillaReaderStreamedResourceStorage,
} from '../../../VanillaReaderDataStore/VanillaReaderStreamedResourceIndexDbStorage';
import {IIntRangeData} from '../../IntRange';
import {HttpRequest} from './HttpRequest';

export type PreloadProgressCallback = (progress: number, resourceId?: string) => void;

export class HttpCachingRandomAccessDataSource implements IRandomAccessDataSource {

    private _numPriorityRequests = 0;
    private _totalNumOfChunks = 0;
    private _numOfChunksDownloaded = 0;
    private _chunkCache: Map<number, Promise<ArrayBuffer>> = new Map();
    private _chunkDescriptorsToPreload: IIntRangeData[] = [];
    private _isPreloading = false;
    private _appStorage: IVanillaReaderStreamedResourceStorage;
    private _preloadProgressCallback: PreloadProgressCallback | undefined;

    constructor(
        storageClient: IVanillaReaderStreamedResourceStorage,
        private publicationId: string,
        private url: string,
        private size: number = 0,
        chunkDescriptors?: IIntRangeData[],
    ) {
        this._appStorage = storageClient;

        if (chunkDescriptors) {
            this._chunkDescriptorsToPreload = chunkDescriptors;
            this._totalNumOfChunks = chunkDescriptors.length;
            this._totalNumOfChunks = this._chunkDescriptorsToPreload.length;
        }
    }

    setChunkDescriptors(chunkDescriptors: IIntRangeData[]) {
        if (chunkDescriptors) {
            this._chunkDescriptorsToPreload = chunkDescriptors;
            this._totalNumOfChunks = chunkDescriptors.length;
            this._totalNumOfChunks = this._chunkDescriptorsToPreload.length;
        }
    }

    onPreloadProgressUpdated(callback: PreloadProgressCallback) {
        this._preloadProgressCallback = callback;
    }

    async fetchChunk(startOffset: number, _endOffset: number): Promise<ArrayBuffer> {
        this._numPriorityRequests++;

        // Check for the chunk in the db
        try {
            let result = await this._appStorage.getResourceDataByStartOffset(this.publicationId, startOffset);
            this._numPriorityRequests--;
            await this._preload();
            if (result) {
                console.log('HttpCachingRandomAccessDataSource.fetchChunk returning cached data');
                return result.data;
            }
        } catch (e) {
            console.log('HttpCachingRandomAccessDataSource.fetchChunk: getPublicationDataByStartOffset returned nothing', e);
        }

        // Check for the chunk in the cache
        if (this._chunkCache.has(startOffset)) {
            console.log('HttpCachingRandomAccessDataSource.fetchChunk: found ' + startOffset + ' in the cache');
            this._numPriorityRequests--;
            this._preload().catch(console.error);
            return this._chunkCache.get(startOffset)!;
        }

        // It wasn't in the cache so let's initiate a request and place it in the cache to be resolved
        console.log('HttpCachingRandomAccessDataSource.fetchChunk: Nothing found in cache, calling sendHttpRangeRequest()');

        let chunkRequestPromise = this.sendHttpRangeRequest(startOffset, _endOffset).finally(() => {
            this._numPriorityRequests--;
            this._preload().catch(console.error);
            this._updatePreloadProgress();
        });

        this._chunkCache.set(startOffset, chunkRequestPromise);

        return chunkRequestPromise;
    }

    private sendHttpRangeRequest(startOffset: number, _endOffset: number): Promise<ArrayBuffer> {
        return HttpRequest.sendRangeRequest(this.url, startOffset, _endOffset - 1).then(arrayBuffer => {
            // Add resolved result to the AppStorage
            this._appStorage.addResourceData(this.publicationId, startOffset, _endOffset, arrayBuffer).then(() => {
                this._chunkCache.delete(startOffset);
            }).catch(err => {
                console.warn('HttpCachingRandomAccessDataSource.sendHttpRangeRequest: ', err);
            });
            return arrayBuffer;
        }).catch(err => {
            return new Promise<ArrayBuffer>((_resolve, reject) => {
                window.setTimeout(() => reject(err), 2000);
            });
        });
    }

    getSize(): number {
        return this.size;
    }

    private _updatePreloadProgress() {
        this._numOfChunksDownloaded++;
        if (this._preloadProgressCallback) {
            this._preloadProgressCallback(this._numOfChunksDownloaded / this._totalNumOfChunks, this.url);
        }
    }

    private async _preload() {
        // If there are no ongoing requests we can continue preloading resources

        if (this._numPriorityRequests > 0 || this._chunkDescriptorsToPreload.length === 0 || this._isPreloading) {
            return;
        }
        this._isPreloading = true;

        let chunkDesc: IIntRangeData | undefined;
        let foundEntryToPreload = false;

        // Also handles clean-up of descriptors that have already been downloaded.
        while (!foundEntryToPreload && this._chunkDescriptorsToPreload.length > 0) {
            chunkDesc = this._chunkDescriptorsToPreload.shift()!;
            if (!this._chunkCache.has(chunkDesc.start)) {
                let storedInDb = await this._appStorage.hasResourceDataByStartOffset(this.publicationId, chunkDesc.start);
                foundEntryToPreload = !storedInDb;

                if (!storedInDb) {
                    console.log('HttpCachingRandomAccessDataSource._preload', 'Entry NOT stored in db: ' + chunkDesc.start);
                }
            }
        }

        if (chunkDesc && foundEntryToPreload) {
            if (this._numPriorityRequests > 0) {
                this._chunkDescriptorsToPreload.unshift(chunkDesc);
                this._isPreloading = false;

            } else if (!this._chunkCache.has(chunkDesc.start)) {

                console.log('HttpCachingRandomAccessDataSource._preload', 'Preloading starting for offset: ', chunkDesc.start);

                let chunkRequestPromise = this.sendHttpRangeRequest(chunkDesc.start, chunkDesc.end).catch(err => {
                    this._chunkDescriptorsToPreload.unshift(chunkDesc!);
                    return Promise.reject(err);
                }).finally(() => {
                    console.log('HttpCachingRandomAccessDataSource._preload', 'Preloading finished for offset: ', chunkDesc!.start);
                    this._updatePreloadProgress();
                    this._isPreloading = false;
                    this._preload().catch(console.error);
                });
                this._chunkCache.set(chunkDesc.start, chunkRequestPromise);

                // console.log('preload', chunkDesc.start);
            } else {
                this._isPreloading = false;
                console.log('HttpCachingRandomAccessDataSource._preload', chunkDesc.start + ' was already in the cache');
            }

        } else {
            this._isPreloading = false;
        }

    }

    async preload(): Promise<void> {
        console.log('HttpCachingRandomAccessDataSource.preload', 'number of ChunkDescriptors', this._chunkDescriptorsToPreload.length);
        await this._preload();
    }

}
