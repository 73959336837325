/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {
    IReaderView,
    IReaderViewAnnotation,
    IReaderViewAnnotationEngineEvent,
    IReaderViewAnnotationLayer,
    IReaderViewAnnotationMouseEngineEvent,
    IReaderViewAnnotationPointerEngineEvent,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';
import { VanillaReaderAppUiDefaults } from '../VanillaReaderUI/VanillaReaderAppUiDefaults';
import {
    IVanillaReaderHighlightData,
    VanillaAnnotationMouseEventCallback,
    VanillaAnnotationPointerEventCallback,
    VanillaHighlightDataCallback,
    VanillaVoidCallback,
} from './VanillaReaderModel';

/**
 * # VanillaReaderAnnotationLayerHighlights
 *
 * ## PRIMARY COLIBRIO FRAMEWORK TYPES USED
 *
 * - IReaderViewAnnotationLayer
 * - IReaderViewAnnotation
 * - IReaderViewAnnotationEngineEvent
 *
 * ## RESPONSIBILITIES
 *
 * This class wraps the highlight's ReaderViewAnnotationLayer. It also acts as runtime storage for highlights.
 *
 */

export class VanillaReaderAnnotationLayerHighlights {
    private _locatorToAnnotationMap: Map<string, IReaderViewAnnotation<IVanillaReaderHighlightData>> = new Map<string, IReaderViewAnnotation>();
    private _colibrioAnnotationLayer: IReaderViewAnnotationLayer;
    private _highlightsInVisibleRange: Set<IVanillaReaderHighlightData>;
    private _highlightAddedCallback: VanillaHighlightDataCallback | undefined = undefined;
    private _highlightDeletedCallback: VanillaHighlightDataCallback | undefined = undefined;
    private _highlightUpdatedCallback: VanillaHighlightDataCallback | undefined = undefined;
    private _highlightsCollectionUpdatedCallback: VanillaVoidCallback | undefined = undefined;
    private _highlightClickCallback: VanillaAnnotationMouseEventCallback | undefined = undefined;
    private _highlightPointerEnterCallback: VanillaAnnotationPointerEventCallback | undefined = undefined;
    private _highlightPointerLeaveCallback: VanillaAnnotationPointerEventCallback | undefined = undefined;
    private _highlightEnterViewCallback: VanillaHighlightDataCallback | undefined = undefined;
    private _highlightExitViewCallback: VanillaHighlightDataCallback | undefined = undefined;
    private _highlightContextMenuCallback: VanillaAnnotationMouseEventCallback | undefined = undefined;

    constructor(
        private name: string,
        private color: string = VanillaReaderAppUiDefaults.highlightColorValues.yellow,
        private _colibrioReaderView: IReaderView,
    ) {
        this._colibrioAnnotationLayer = this._colibrioReaderView.createAnnotationLayer(this.name);
        this._highlightsInVisibleRange = new Set<IVanillaReaderHighlightData>();
        this._create();
    }

    onHighlightAdded(callback: VanillaHighlightDataCallback) {
        this._highlightAddedCallback = callback;
    }

    onHighlightUpdated(callback: VanillaHighlightDataCallback) {
        this._highlightUpdatedCallback = callback;
    }

    onHighlightDeleted(callback: VanillaHighlightDataCallback) {
        this._highlightDeletedCallback = callback;
    }

    onHighlightsCollectionUpdated(callback: VanillaVoidCallback) {
        this._highlightsCollectionUpdatedCallback = callback;
    }

    onHighlightClick(callback: VanillaAnnotationMouseEventCallback) {
        this._highlightClickCallback = callback;
    }

    onHighlightPointerEnter(callback: VanillaAnnotationPointerEventCallback) {
        this._highlightPointerEnterCallback = callback;
    }

    onHighlightPointerLeave(callback: VanillaAnnotationPointerEventCallback) {
        this._highlightPointerLeaveCallback = callback;
    }

    onHighlightEnterView(callback: VanillaHighlightDataCallback) {
        this._highlightEnterViewCallback = callback;
    }

    onHighlightExitView(callback: VanillaHighlightDataCallback) {
        this._highlightExitViewCallback = callback;
    }

    onHighlightContextMenu(callback: VanillaAnnotationMouseEventCallback) {
        this._highlightContextMenuCallback = callback;
    }

    async loadHighlights(items: IVanillaReaderHighlightData[]) {

        try {

            // @ts-ignore
            let data = await window.reader.fetchAllHighlights()

            items = Array.isArray(data.data.highlights) ? data.data.highlights : []

            if (this._colibrioAnnotationLayer) {
                items.forEach((item) => {
                    this.addHighlight(item)
                })
            }
        } catch (error) {
            console.error("Error loading highlights:", error)
        }

    }

    getHighlights(): IVanillaReaderHighlightData[] {

        let highlights: IVanillaReaderHighlightData[] = [];
        this._colibrioAnnotationLayer.getAnnotations().forEach((item) => {
            highlights.push(item.getCustomData());
        });

        return highlights;
    }

    getReaderViewAnnotations(): IReaderViewAnnotation[] {
        return this._colibrioAnnotationLayer.getAnnotations();
    }

    addHighlight(highlightData: IVanillaReaderHighlightData) {

        let readerViewAnnotation: IReaderViewAnnotation;

        readerViewAnnotation = this._colibrioAnnotationLayer.createAnnotation<IVanillaReaderHighlightData>(highlightData.locator);
        readerViewAnnotation.setOptions({
            rangeStyle: {
                'background-color': highlightData.color,
            },
        });

        readerViewAnnotation.setCustomData(highlightData);

        this._locatorToAnnotationMap.set(highlightData.locator, readerViewAnnotation);

        if (this._highlightAddedCallback) {
            this._highlightAddedCallback(highlightData);
        }

        if (this._highlightsCollectionUpdatedCallback) {
            this._highlightsCollectionUpdatedCallback();
        }

    }

    updateHighlight = (highlight: IVanillaReaderHighlightData) => {

        let existingTarget = this._locatorToAnnotationMap.get(highlight.locator);
        if (existingTarget) {
            existingTarget.setCustomData(highlight);
            existingTarget.setOptions({
                rangeStyle: {
                    'background-color': highlight.color,
                },
            });

            if (this._highlightUpdatedCallback) {
                this._highlightUpdatedCallback(highlight);
            }

            if (this._highlightsCollectionUpdatedCallback) {
                this._highlightsCollectionUpdatedCallback();
            }

        } else {
            console.log('annotationLayerHighlights_update', 'Could not find existing VanillaReaderHighlight with contentBlockLocator ', highlight.locator);
        }

    };

    deleteHighlight(locator: string) {
        let existingTarget = this._locatorToAnnotationMap.get(locator);
        if (existingTarget) {
            this._locatorToAnnotationMap.delete(locator);
            let vanillaReaderAnnotationData = existingTarget.getCustomData();
            existingTarget.getAnnotationLayer().destroyAnnotation(existingTarget);

            if (this._highlightDeletedCallback) {
                if (vanillaReaderAnnotationData) {
                    this._highlightDeletedCallback(vanillaReaderAnnotationData);
                } else {
                    console.warn(`VanillaReaderAnnotationLayerHighlights.deleteHighlight(): Unable to get annotation data for annotation with locator "${locator}"`);
                }
            }

            if (this._highlightsCollectionUpdatedCallback) {
                this._highlightsCollectionUpdatedCallback();
            }

        } else {
            console.log('annotationLayerHighlights_deleteHighlight', 'Could not find existing VanillaReaderHighlight with contentBlockLocator ', locator);
        }

    }

    getHighlight(locator: string): IVanillaReaderHighlightData | undefined {
        if (this._locatorToAnnotationMap.has(locator)) {
            return this._locatorToAnnotationMap.get(locator)!.getCustomData() || undefined;
        } else {
            return;
        }
    }

    destroy() {
        this._colibrioReaderView.destroyAnnotationLayer(this._colibrioAnnotationLayer);
    }

    clear() {
        this._colibrioAnnotationLayer.destroyAllAnnotations();
    }

    async getHighlightsInVisibleRange(): Promise<IVanillaReaderHighlightData[]> {

        try {

            // @ts-ignore
            let data = await window.reader.fetchAllHighlights()
    
            if (data && data.data.highlights && Array.isArray(data.data.highlights)) {
                if (data.data.highlights.length > 0) {

                    return data.data.highlights

                } else {
                    
                    console.warn('Highlights array is empty')
                    return []
                }
            } else {

                console.warn('No highlights found (invalid data format)', data)
                return []

            }
        } catch (error) {

            console.error('Error fetching highlights:', error)
            return Promise.reject(error)
            
        }
    }

    /*
    *
    * PRIVATE METHODS
    *
    * */

    private _create() {

        this._colibrioAnnotationLayer.setDefaultAnnotationOptions({
            rangeClassName: 'vanilla-reader__highlights-layer',
            rangeStyle: { 'background-color': this.color },
        });


        this._colibrioAnnotationLayer.setLayerOptions({
            annotationInputEngineEventsEnabled: true,
            layerStyle: { 'mix-blend-mode': 'multiply' },
        });

        this._colibrioAnnotationLayer.addEngineEventListener('annotationContextMenu', this._event_contextMenu);
        this._colibrioAnnotationLayer.addEngineEventListener('annotationClick', this._event_click);
        this._colibrioAnnotationLayer.addEngineEventListener('annotationIntersectingVisibleRange', this._event_enterView);
        this._colibrioAnnotationLayer.addEngineEventListener('annotationOutsideVisibleRange', this._event_exitView);
        this._colibrioAnnotationLayer.addEngineEventListener('annotationPointerEnter', this._event_pointerEnter);
        this._colibrioAnnotationLayer.addEngineEventListener('annotationPointerLeave', this._event_pointerLeave);
    }

    /*
    *
    * EVENT HANDLERS
    *
    * */

    private _event_click = (event: IReaderViewAnnotationMouseEngineEvent) => {
        if (this._highlightClickCallback) {
            this._highlightClickCallback(event);
        }
    };
    private _event_enterView = (event: IReaderViewAnnotationEngineEvent) => {
        this._highlightsInVisibleRange.add(event.annotation.getCustomData());
        if (this._highlightEnterViewCallback) {
            this._highlightEnterViewCallback(event.annotation.getCustomData());
        }
    };

    private _event_exitView = (event: IReaderViewAnnotationEngineEvent) => {
        this._highlightsInVisibleRange.delete(event.annotation.getCustomData());
        if (this._highlightExitViewCallback) {
            this._highlightExitViewCallback(event.annotation.getCustomData());
        }
    };
    private _event_pointerEnter = (event: IReaderViewAnnotationPointerEngineEvent) => {
        if (this._highlightPointerEnterCallback) {
            this._highlightPointerEnterCallback(event);
        }
    };
    private _event_pointerLeave = (event: IReaderViewAnnotationPointerEngineEvent) => {
        if (this._highlightPointerLeaveCallback) {
            this._highlightPointerLeaveCallback(event);
        }

    };
    private _event_contextMenu = (event: IReaderViewAnnotationMouseEngineEvent) => {

        if (this._highlightContextMenuCallback) {
            this._highlightContextMenuCallback(event);
        }

    };

}
