/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {Dexie, PromiseExtended} from 'dexie';

/*
* # IStoredResource
*
* ## RESPONSIBILITIES
*
* Describes the metadata for a stored resource.
*
* */
export interface IStoredResource {
    id: string;
    name: string;
    url: string;
    mimetype: string;
    size: number;
}

/*
* # IStoredResource
*
* ## RESPONSIBILITIES
*
* Describes the data stored for each chunk of resource content.
*
* */
export interface IStoredResourceData {
    resourceId: string;
    startOffset: number;
    endOffset: number;
    data: ArrayBuffer;
}

export type IStoredResourceMutationEventCallback = (id: string) => void;

export interface IVanillaReaderStreamedResourceStorage {
    hasResource(id: string): PromiseExtended<boolean>;

    hasResourceByName(name: string): PromiseExtended<boolean>;

    getResource(id: string): PromiseExtended<IStoredResource | undefined | void>;

    getResourceByName(name: string): PromiseExtended<IStoredResource | undefined | void>;

    addResource(resource: IStoredResource): PromiseExtended<string | void>;

    deleteResource(id: string): PromiseExtended<string | void>;

    deleteResourceByName(name: string): PromiseExtended<string | void>;

    addResourceData(
        id: string,
        startOffset: number,
        endOffset: number,
        data: ArrayBuffer,
    ): PromiseExtended<string | void>;

    deleteResourceData(id: string): PromiseExtended<number | void>;

    getResourceDataByStartOffset(
        id: string,
        startOffset: number,
    ): PromiseExtended<IStoredResourceData | undefined | void>;

    hasResourceDataByStartOffset(id: string, startOffset: number): PromiseExtended<boolean | void>;

    onResourceDeleted(callback: IStoredResourceMutationEventCallback): void;

    onResourceAdded(callback: IStoredResourceMutationEventCallback): void;

}

/*
* # VanillaReaderStreamedResourceStorage
*
* ## RESPONSIBILITIES
*
* This class is a very handy, generic store for data that benefits from being stored in chunks, such as data that has
* been streamed from a data source.
*
* It has manages two data types, `IStoredResource` which contains metadata about the resource, and `IStoredResourceData`
* which contains a specific chunk of resource data.
*
**/

export class VanillaReaderStreamedResourceIndexDbStorage extends Dexie implements IVanillaReaderStreamedResourceStorage {

    resource: Dexie.Table<IStoredResource, string>;
    resourceData: Dexie.Table<IStoredResourceData, string>;

    private _resourceDeletedEventCallback: IStoredResourceMutationEventCallback | undefined;
    private _resourceAddedEventCallback: IStoredResourceMutationEventCallback | undefined;

    constructor(dbName: string) {
        super(dbName);

        this.version(1).stores({
            resource: 'id, name',
            resourceData: '[resourceId+startOffset], resourceId',

        });

        this.resource = this.table('resource');
        this.resourceData = this.table('resourceData');
    }

    onResourceDeleted(callback: IStoredResourceMutationEventCallback): void {
        this._resourceDeletedEventCallback = callback;
    }

    onResourceAdded(callback: IStoredResourceMutationEventCallback): void {
        this._resourceAddedEventCallback = callback;
    }

    getResource(id: string): PromiseExtended<IStoredResource | undefined> {
        return this.resource.where('id').equals(id).first((res) => {
            return res;
        });
    }

    getResourceByName(name: string): PromiseExtended<IStoredResource | undefined> {
        return this.resource.where('name').equals(name).first((res) => {
            return res;
        });
    }

    hasResource(id: string): PromiseExtended<boolean> {
        return this.resource.where('id').equals(id).count((num) => {
            return num >= 1;
        });
    }

    hasResourceByName(name: string): PromiseExtended<boolean> {
        return this.resource.where('name').equals(name).count((num) => {
            return num >= 1;
        });
    }

    addResource(resource: IStoredResource): PromiseExtended<string | void> {
        return this.hasResource(resource.id).then((hasResource: boolean) => {
            if (!hasResource) {
                return this.resource.add(resource, resource.url).then((res) => {
                    console.log('addResource', res);
                    if (this._resourceAddedEventCallback) {
                        this._resourceAddedEventCallback(resource.id);
                    }
                    return res;
                });
            } else {
                console.log('resource exists', resource);
                return;
            }
        });
    }

    deleteResource(id: string): PromiseExtended<string | void> {
        return this.getResource(id).then((resource: IStoredResource | undefined) => {
            if (resource) {
                return this.deleteResourceData('publicationId').then(() => {
                    return this.resource.where('id').equals(resource.url).delete().then((_res) => {
                        if (this._resourceDeletedEventCallback) {
                            this._resourceDeletedEventCallback(id);
                        }
                        return Promise.resolve();
                    });
                });
            } else {
                console.warn(`IVanillaReaderStreamedResourceStorage.deleteResource(): could not find resource with id "${id}".`);
                return;
            }
        });
    }

    deleteResourceByName(name: string): PromiseExtended<string | void> {
        return this.getResourceByName(name).then((resource: IStoredResource | undefined) => {
            if (resource) {
                return this.deleteResourceData(resource.url).then(() => {
                    return this.resource.where('name').equals(name).delete().then((_res) => {
                        if (this._resourceDeletedEventCallback) {
                            this._resourceDeletedEventCallback(name);
                        }
                        return Promise.resolve();
                    });
                });
            } else {
                console.warn(`IVanillaReaderStreamedResourceStorage.deleteResourceByName(): could not find resource with id "${name}".`);
                return;
            }
        });
    }

    addResourceData(
        id: string,
        startOffset: number,
        endOffset: number,
        data: ArrayBuffer,
    ): PromiseExtended<string | void> {
        return this.resourceData.add({
            resourceId: id,
            startOffset: startOffset,
            endOffset: endOffset,
            data: data,
        } as IStoredResourceData, id).catch((err) => {
            console.log('Exception in ResourceStorage.addResourceData: ', err);
            throw err;
        });
    }

    getResourceDataByStartOffset(id: string, startOffset: number): PromiseExtended<IStoredResourceData | undefined> {
        return this.resourceData.where('[resourceId+startOffset]').equals([id, startOffset]).first((dataChunk) => {
            return dataChunk;
        });
    }

    hasResourceDataByStartOffset(id: string, startOffset: number): PromiseExtended<boolean | void> {
        return this.resourceData.where('[resourceId+startOffset]').equals([id, startOffset]).count().then((num) => {
            return num >= 1;
        }).catch(err => {
            console.error('Exception in ResourceStorage.hasResourcenDataByStartOffset("' + id + '", "' + startOffset + '")', err);
            throw err;
        });
    }

    deleteResourceData(id: string): PromiseExtended<number | void> {
        return this.resourceData.where('resourceId').equals(id).delete().catch((err) => {
            console.error('Exception in ResourceStorage.deleteResourceData("' + id + '")', err);
            throw err;
        });
    }

}