/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
/**
 *
 * WebSpeechUtils
 *
 * This utility class helps out with getting voices in a cross platform manner. It is not pretty folks, but `setInterval`
 * is the recommended quirkfix to this issue.
 *
 * */

export class WebSpeechUtils {
    static fetchTtsVoices = (): Promise<ISpeechSynthesisVoiceCollection> => {
        let iterationsCount: number = 0;
        let intervalHandle: number;
        let fetchVoicesPromise = new Promise<ISpeechSynthesisVoiceCollection>((resolve, reject)=>{

            const getVoices = () => {
                if(iterationsCount > 5) {
                    window.clearInterval(intervalHandle);
                    reject('TTS voices request took too long.');
                }

                iterationsCount++;

                let voicesList = window.speechSynthesis.getVoices();

                if(voicesList.length > 0) {

                    window.clearInterval(intervalHandle);

                    let voiceCollection: ISpeechSynthesisVoiceCollection = {
                        allVoices: [],
                        languages: new Map(),
                        remoteVoices: [],
                        localVoices: []
                    };

                    voiceCollection.allVoices = voicesList;

                    voicesList.forEach((voice)=>{

                        // Split lang code so that we separate language "family" and regional dialect.
                        let langFamilyCode = voice.lang.includes('-') ? voice.lang.split('-')[0] : voice.lang.split('_')[0];

                        if(!voiceCollection.languages.has(langFamilyCode)) {
                            voiceCollection.languages.set(langFamilyCode, []);
                        }
                        voiceCollection.languages.get(langFamilyCode)?.push(voice);

                        if(voice.localService) {
                            voiceCollection.localVoices.push(voice);
                        }

                        if(voice.voiceURI) {
                            voiceCollection.remoteVoices.push(voice);
                        }

                    });

                    resolve(voiceCollection);
                }
            };

            /**
             * We need to run the `getVoices` function until we get a voice list from the browser, or until
             * we have reached the maximum of 5 iterations.
             * The main reason for this quirkfix is that Safari does not support the `voicesChanged` event, so there
             * is no way of knowing when it has finished building its list of available voices.
             *
             */

            intervalHandle = window.setInterval(getVoices, 500);

        });

        return fetchVoicesPromise;
    }

}

export interface ISpeechSynthesisVoiceCollection {
    allVoices: SpeechSynthesisVoice[],
    languages: Map<string, SpeechSynthesisVoice[]>,
    localVoices: SpeechSynthesisVoice[],
    remoteVoices: SpeechSynthesisVoice[],
}