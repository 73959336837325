/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IntRange} from '../utils/IntRange';
import {IntSequence} from '../utils/IntSequence';

/*
* # VanillaReaderReadingStats
*
* ## RESPONSIBILITIES
*
* This class works with the data in a IPublicationSearchIndexData, which is a utility class (not part of the framework)
* that works with an IReaderPublication's ContentBlocks structure, to search through publication's text.
* The IPublicationSearchIndexData can be saved once it has been created. Use the `IPublicationSearchIndexData.hashSignature`
* to check so that the version of the index is still up-to-date with the source publication.
*
* * ## PRIMARY COLIBRIO FRAMEWORK TYPES
*
* - No framework members are used in this class. It only works with integer ranges.
*
* ## NOTES:
*
* This class is very much an experiment, so use this as reference for your own code 🙂
*
**/

export class VanillaReaderReadingStats {
    private _wordSequence: IntSequence | null = null;
    private _totalNumberOfWords: number | null = null;
    private _previousRange: IntRange | null = null;
    private _latestRangeChangedTimestamp: number | undefined;
    private _wordReadingRateHistory: number[][] = [];

    constructor() {
    }

    setPublicationLength(numberOrWords: number) {
        this._totalNumberOfWords = numberOrWords;
        this._wordSequence = new IntSequence(numberOrWords);
    }

    getPublicationLength(): number | null {
        return this._totalNumberOfWords;
    }

    addWordRange(range: IntRange) {
        this._updateAverageWordsPerMinute(this._previousRange || range);
        this._previousRange = range;
        this._wordSequence?.addRange(range.start, range.end);
    }

    invalidateLatestWordRange() {
        this._wordReadingRateHistory.pop();
    }

    getNumberOfWordsRead(): number {
        let readRanges = this._wordSequence?.getEntries();
        let wordCount: number = 0;

        readRanges?.forEach((range) => {
            wordCount += range.end - range.start;
        });

        return wordCount;
    }

    getPercentRead(): number | undefined {
        if (this._totalNumberOfWords) {
            let wordCount = this.getNumberOfWordsRead();
            return wordCount / this._totalNumberOfWords * 100;
        }
        return undefined;
    }

    getRangesNotRead(): IntRange[] | undefined {
        return this._wordSequence?.getEmptySlots();
    }

    getRangesRead(): IntRange[] | undefined {
        return this._wordSequence?.getEntries();
    }

    getAverageWordsPerMinute(): number {

        let averagesSum = 0;
        this._wordReadingRateHistory.forEach((historyItem: number[]) => {
            averagesSum += historyItem[1];
        });

        return averagesSum / this._wordReadingRateHistory.length;
    }

    getLatestAverageWordsPerMinute(): number {
        return this._wordReadingRateHistory[this._wordReadingRateHistory.length - 1][1];
    }

    private _updateAverageWordsPerMinute(wordRange: IntRange) {
        let nowTimestampInSeconds = Date.now() / 1000;
        let latestRangeChangedTimestamp = this._latestRangeChangedTimestamp ?
            this._latestRangeChangedTimestamp :
            nowTimestampInSeconds;
        let secondsElapsedSinceLastChange = nowTimestampInSeconds - latestRangeChangedTimestamp > 0 ?
            nowTimestampInSeconds - latestRangeChangedTimestamp :
            1;
        this._latestRangeChangedTimestamp = nowTimestampInSeconds;
        let averageWordsPerMinute = wordRange.length / (secondsElapsedSinceLastChange / 60);
        this._wordReadingRateHistory.push([nowTimestampInSeconds, averageWordsPerMinute]);
    }

}
