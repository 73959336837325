/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IVanillaReaderUI} from "./IVanillaReaderUI";
import {IVanillaReaderHighlightData} from "../VanillaReader/VanillaReaderModel";
import {VanillaReaderAppUiDefaults} from "./VanillaReaderAppUiDefaults";
import {VanillaReaderUiMenuMediaPlayerController} from "./VanillaReaderUiMenuMediaPlayerController";
import {VanillaReaderUiActionNames} from "./VanillaReaderUiModel";
import {IVanillaReaderUiComponentController} from "./IVanillaReaderUiComponentController";
import {VanillaReaderUI} from "./VanillaReaderUI";

export interface VanillaReaderUiDialogUiActionsController extends IVanillaReaderUiComponentController, IVanillaReaderUI, VanillaReaderUiMenuMediaPlayerController {}

/**
 * # VanillaReaderUiDialogActionsController
 *
 * ## RESPONSIBILITIES
 * This controller mixing class handles all event callbacks from the `VanillaReaderUiDialogUiActions` class.
 *
 * Just as all other controller mixin classes, this class is used to add functionality to the `VanillaReaderUi` class
 * in a "modular" way. Read more about this in the `UI Components and Controller Mixins` in the README.md file.
 *
 * ## RELATED TYPES
 * - VanillaReaderUi
 * - VanillaReaderUiDialogUiActions
 *
 * */
export class VanillaReaderUiDialogUiActionsController {

    static __initialize__(vanillaReaderUiContext: VanillaReaderUI){
        vanillaReaderUiContext.dialogUiActions.onActionItemClicked(vanillaReaderUiContext._onDialogActionsItemSelected.bind(vanillaReaderUiContext));
    }

    _onDialogActionsItemSelected(actionName: string) {
        let closeActionsDialogAfterActionHasBeenCalled: boolean = true;

        const onAfterChildDialogClosed = () => {
            this.dialogUiActions.close(true, false);
            this.focusOnReadingPosition();
        }

        switch (actionName) {
            case VanillaReaderUiActionNames.MAIN_MENU_OPEN:
                this.menuBottom.show(!this.useReducedMotion);
                break;
            case VanillaReaderUiActionNames.NAVIGATE_NEXT:
                this.navigateNext();
                break;
            case VanillaReaderUiActionNames.NAVIGATE_PREVIOUS:
                this.navigatePrevious();
                break;
            case VanillaReaderUiActionNames.SEARCH_DIALOG_OPEN:
                // If we have an active selection we use this text to populate the search input.
                if (this.activeSelection?.selectionText) {
                    this.dialogSearch.setSearchTerm(this.activeSelection.selectionText);
                }
                this.dialogSearch.show(false, false, onAfterChildDialogClosed, this.latestSelectedSearchResultLocator);
                closeActionsDialogAfterActionHasBeenCalled = false;
                break;
            case  VanillaReaderUiActionNames.SEARCH_CLEAR:
                this.dialogSearch.clear();
                break;
            case VanillaReaderUiActionNames.BOOKMARK_READING_POSITION:
                this.bookmarkReadingPosition();
                break;
            case VanillaReaderUiActionNames.BOOKMARK_CLIPBOARD:
                this.bookmarkClipboardContents().catch(console.warn);
                break;
            case VanillaReaderUiActionNames.TOC_OPEN_DIALOG:
                this.dialogContents.show(false, true, onAfterChildDialogClosed);
                closeActionsDialogAfterActionHasBeenCalled = false;
                break;
            case VanillaReaderUiActionNames.SETTINGS_DIALOG_OPEN:
                this.dialogSettings.show(false, false, onAfterChildDialogClosed);
                closeActionsDialogAfterActionHasBeenCalled = false;
                break;
            case VanillaReaderUiActionNames.BOOKSHELF_DIALOG_OPEN:
                this.closePublicationAndShowFileOpenDialog();
                break;
            case VanillaReaderUiActionNames.CLOSE_DIALOGS_AND_FOCUS_ON_READING_POSITION:
                this.closeAllDialogsAndFocusOnReadingPosition();
                break;
            case VanillaReaderUiActionNames.BOOKMARK_OPEN_DIALOG_ALL:
                this.dialogBookmarks.show(false, false, onAfterChildDialogClosed);
                closeActionsDialogAfterActionHasBeenCalled = false;
                break;
            case VanillaReaderUiActionNames.BOOKMARK_OPEN_DIALOG_ACTIVE:
                this.dialogBookmarks.show(false, false, onAfterChildDialogClosed, true);
                closeActionsDialogAfterActionHasBeenCalled = false;
                break;
            case VanillaReaderUiActionNames.HIGHLIGHT_OPEN_DIALOG_ALL:
                this.dialogHighlights.show(false, false, onAfterChildDialogClosed);
                closeActionsDialogAfterActionHasBeenCalled = false;
                break;
            case VanillaReaderUiActionNames.HIGHLIGHT_OPEN_DIALOG_ACTIVE:
                this.dialogHighlights.show(false, false, onAfterChildDialogClosed, true);
                closeActionsDialogAfterActionHasBeenCalled = false;
                break;
            case VanillaReaderUiActionNames.HELP_DIALOG_OPEN:
                this.dialogHelp.show(false, false, onAfterChildDialogClosed);
                closeActionsDialogAfterActionHasBeenCalled = false;
                break;
            case VanillaReaderUiActionNames.HIGHLIGHT_SELECTION:
                if (this.activeSelection) {
                    if (this.activeSelection && this.activeSelection.selector) {
                        let highlight: IVanillaReaderHighlightData = {
                            locator: this.activeSelection.selector,
                            color: VanillaReaderAppUiDefaults.highlightColorValues.yellow,
                            selectionText: this.activeSelection.selectionText,
                            comment: '',
                        };
                        this.dialogHighlight?.setState(highlight, true);
                        this.dialogHighlight?.show();
                    }
                } else {
                    this.toaster.toast('There is no text selection to highlight.', true).catch(console.warn);
                    closeActionsDialogAfterActionHasBeenCalled = false;
                }
                break;
            case VanillaReaderUiActionNames.HIGHLIGHT_CLIPBOARD:
                this.highlightClipboardContents().catch(() => {
                    this.toaster.toast('Unable to use system clipboard', true).catch(console.warn);
                    closeActionsDialogAfterActionHasBeenCalled = false;
                });
                break;
            case VanillaReaderUiActionNames.HIGHLIGHT_ACTIVE_CONTENT:
                this.highlightVisibleRange(false);
                break;

            case VanillaReaderUiActionNames.HIGHLIGHT_READING_POSITION:
                this.highlightReadingPosition(false);
                break;
            case VanillaReaderUiActionNames.WHERE_AM_I:
                this.announceReadingPosition().catch(console.warn);
                closeActionsDialogAfterActionHasBeenCalled = false;
                break;
            case VanillaReaderUiActionNames.NAVIGATE_NEXT_HEADING:
                this.navigateToNextHeading();
                break;
            case VanillaReaderUiActionNames.NAVIGATE_PREVIOUS_HEADING:
                this.navigateToPreviousHeading();
                break;
            case VanillaReaderUiActionNames.MEDIA_PLAYBACK_START:
                this.openMediaPlayer(true);
                this.startMediaPlayer();
                break;
            case VanillaReaderUiActionNames.MEDIA_PLAYBACK_STOP:
                this.closeMediaPlayer();
                this.focusOnReadingPosition();
                break;
            case VanillaReaderUiActionNames.ACCESSIBILITY_MODE_ENTER:
                this.enterAccessibilityMode();
                break;
            case VanillaReaderUiActionNames.ACCESSIBILITY_MODE_EXIT:
                this.exitAccessibilityMode();
                break;
            case VanillaReaderUiActionNames.GOTO_PAGE_DIALOG_OPEN:
                this.showGoToPagePrompt();
                break;
        }

        if (closeActionsDialogAfterActionHasBeenCalled) {
            this.dialogUiActions.close(true);
        }
    }
}