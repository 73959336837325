/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {createFocusTrap, FocusTrap} from 'focus-trap';
import {
    VanillaBooleanValueCallback,
    VanillaDialogClosedCallback,
    VanillaDialogOpenedCallback, VanillaStringValueCallback, VanillaVoidCallback
} from "../VanillaReader/VanillaReaderModel";
import { VanillaUiDialogResultCallback } from './VanillaReaderUiModel';

/**
 * # VanillaReaderUiDialog
 *
 * ## RESPONSIBILITIES
 *
 * This is a base class for all UI dialogs. It handles general life cycle related events as well as focus trapping etc.
 * Dialog body contents is set by the creating code. This is done in a rather "naive" fashion by using the
 * `_dialogBodyElement` property.
 *
 */

export class VanillaReaderUiDialog<T> {
    _dialogElement: HTMLElement;
    _dialogHeaderElement: HTMLElement;
    _dialogHeaderTitleElement: HTMLElement;
    _dialogCloseButtonElement: HTMLElement;
    _dialogBodyElement: HTMLElement;
    _dialogFooterElement: HTMLElement;
    _modalOverlayElement: HTMLElement;
    _hostDocument: Document;
    _focusTrap: FocusTrap | undefined;
    _returnFocusOnClose: boolean = true;

    _closeOnEscKeyUp: boolean = true;

    static _sharedOnDialogOpenedCallback: VanillaDialogOpenedCallback | undefined;
    static _sharedOnDialogClosedCallback: VanillaDialogClosedCallback | undefined;
    static _sharedOnDialogFocusTrapDeactivatedCallback: VanillaBooleanValueCallback | undefined;

    private _modalOverlayHasFocusCallback: VanillaVoidCallback | undefined;
    private _clickOutsideCallback: VanillaVoidCallback | undefined;
    _onDialogOpenedCallback: VanillaStringValueCallback | undefined;
    _onDialogClosedCallback: VanillaUiDialogResultCallback<T> | undefined;

    private _dialogTemplate = `
        <div class="vanilla-reader-ui__dialog" id="${this._id}" role="dialog" tabindex="0">
            <div class="vanilla-reader-ui__dialog__header">        
                <h2 class="vanilla-reader-ui__dialog__header__title">${this._title}</h2>
                <button class="vanilla-reader-ui__dialog__header__close-button" title="Close dialog"><span aria-hidden="true">close</span></button>
            </div>
            <div class="vanilla-reader-ui__dialog__body">                
            </div>
            <div class="vanilla-reader-ui__dialog__footer">
            </div>           
        </div>
        <div class="vanilla-reader__modal-overlay"></div>`;

    private _dialogStyle = `
        <style>
            #${this._id}[role=dialog] {
                position: fixed;
                display: none;
                flex-direction: column;
                opacity: 0;
                top: 5%;
                left: 15%;
                right: 15%;
                min-height: 50%;
                height: 60%;
                max-width: 900px;
                margin-left: auto;
                margin-right: auto;
                overflow: auto;
                background-color: var(--vanilla-reader__color__ui__background-light);
                color: var(--vanilla-reader__color__ui__foreground-dark);
                border: 1px solid var(--vanilla-reader__color__ui__foreground-dark);
                padding: 2em;
                box-shadow: 5px 5px 20px rgb(0 0 0 / 20%);
                border-radius: 8px;
                z-index: 101;
                transition: all 0.2s ease-out;
            }
            
            /* When on phone screens we place the dialogs at the bottom of the screen. */
            @media only screen and (max-device-width: 480px) {
                #${this._id}[role=dialog] {
                    top: unset;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    border-bottom: 0;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    box-shadow: 0 -8px 16px rgba(0,0,0,0.2);
                    transition: all 0.2s ease-in-out;              
                }
            }

            #${this._id} .vanilla-reader-ui__dialog__header {
                display: flex;
                align-content: space-between;
                flex-direction: row;
                flex-wrap: nowrap;
                width: 100%;
            }

            #${this._id} .vanilla-reader-ui__dialog__header__close-button {
                width: 48px;
                height: 48px;
                background-color: transparent;
                -webkit-appearance: none;
                cursor: pointer;
                color: var(--vanilla-reader__color__ui__foreground-dark);
                border: 1px solid var(--vanilla-reader__color__ui__foreground-dark);                
                font-size: 1.2em;
            }

            #${this._id} .vanilla-reader-ui__dialog__header__title {
                flex-grow: 1;
            }
            
            #${this._id} .vanilla-reader-ui__dialog__body {
                flex-grow: 1;
            }
                       
            #${this._id} + .vanilla-reader__modal-overlay {
                display: none;
                position: fixed;
                top: 0;
                left: 0;
                opacity: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,0.5);
                z-index: 100;
                transition: all 0.2s ease-in-out;
            }

    </style>`;

    constructor(
        private _hostElement: HTMLElement,
        private _id: string,
        private _title?: string,
        insertPosition: InsertPosition = 'afterbegin',
    ) {
        this._hostDocument = this._hostElement.ownerDocument;
        this._hostElement.insertAdjacentHTML(insertPosition, this._dialogTemplate);

        this._dialogElement = this._hostDocument.getElementById(this._id)!;
        this._dialogElement.insertAdjacentHTML(insertPosition, this._dialogStyle);

        this._dialogHeaderElement = this._dialogElement.querySelector<HTMLElement>('.vanilla-reader-ui__dialog__header')!;
        this._dialogHeaderTitleElement = this._dialogElement.querySelector<HTMLElement>('.vanilla-reader-ui__dialog__header__title')!;
        this._dialogCloseButtonElement = this._dialogElement.querySelector<HTMLElement>('.vanilla-reader-ui__dialog__header__close-button')!;
        this._dialogBodyElement = this._dialogElement.querySelector<HTMLElement>('.vanilla-reader-ui__dialog__body')!;
        this._dialogFooterElement = this._dialogElement.querySelector<HTMLElement>('.vanilla-reader-ui__dialog__footer')!;
        this._modalOverlayElement = this._hostDocument.querySelector<HTMLElement>('.vanilla-reader__modal-overlay')!;

        this._modalOverlayElement.addEventListener('focus', this._event_modalOverlay_focus);
        this._modalOverlayElement.addEventListener('keyup', this._event_modalOverlay_keyup);
        this._dialogElement.addEventListener('focus', this._event_dialog_focus);
        this._dialogElement.addEventListener('keyup', this._event_dialog_keyup);
        this._dialogCloseButtonElement.addEventListener('click', this._event_dialog_closeButtonClick);

    }

    // This method, as well as the "onAfterDialogClosed" method, are static because they are shared between all dialogs.
    // It lets the VanillaReaderUI listen for these life cycle events in one place.
    static sharedOnDialogOpened(callback: VanillaDialogClosedCallback) {
        VanillaReaderUiDialog._sharedOnDialogOpenedCallback = callback;
    }

    static sharedOnDialogClosed(callback: VanillaDialogClosedCallback) {
        VanillaReaderUiDialog._sharedOnDialogClosedCallback = callback;
    }

    static sharedOnDialogFocusTrapDeactivated(callback: VanillaBooleanValueCallback) {
        VanillaReaderUiDialog._sharedOnDialogFocusTrapDeactivatedCallback = callback;
    }

    onDialogOpened(callback: VanillaStringValueCallback) {
        this._onDialogOpenedCallback = callback;
    }

    public set closeOnEscKeyUp(value: boolean) {
        this._closeOnEscKeyUp = value;
    }

    public get closeOnEscKeyUp(): boolean {
        return this._closeOnEscKeyUp;
    }

    public get id(): string {
        return this._id;
    }

    public set title(value: string) {
        this._dialogHeaderTitleElement.innerText = value;
    }

    public get title(): string {
        return this._dialogHeaderTitleElement.innerText;
    }

    isOpen(): boolean {
        return this._dialogElement.style.display.toLocaleLowerCase() === 'flex';
    }

    show(silent: boolean = false, returnFocusOnClose: boolean = true, onDialogCloseCallbackOverride?: VanillaUiDialogResultCallback<T>) {
        this._returnFocusOnClose = returnFocusOnClose;
        this._dialogElement.style.display = 'flex';
        this._modalOverlayElement.style.display = 'block';
        setTimeout(() => {
            this._dialogElement.style.opacity = '1';
            this._modalOverlayElement.style.opacity = '1';
        }, 0.3);

        // We can not always guarantee that there are focusable elements within the dialog body element.
        try {
            this._focusTrap = createFocusTrap(this._dialogElement, {
                allowOutsideClick: true,
                escapeDeactivates: false,
                delayInitialFocus: true,
                returnFocusOnDeactivate: returnFocusOnClose,
                onPostDeactivate: this._onFocusTrapDeactivated,
            });
            this._focusTrap.activate();
        } catch (e) {
        }

        (this._dialogElement as HTMLElement).focus();

        this._modalOverlayElement.addEventListener('click', this._event_modalOverlay_click);

        if (this._onDialogOpenedCallback) {
            this._onDialogOpenedCallback(this._title || '');
        }


        if(onDialogCloseCallbackOverride) {
            this._onDialogClosedCallback = onDialogCloseCallbackOverride;
        }

        if (VanillaReaderUiDialog._sharedOnDialogOpenedCallback) {
            VanillaReaderUiDialog._sharedOnDialogOpenedCallback(this._title || '', silent);
        }

    }

    close(silent: boolean = false, returnDialogResult: boolean = true) {

        if (this._focusTrap) {
            try {
                this._focusTrap.deactivate();
            } catch (e) {
                console.log(e);
            }
        }

        this._focusTrap = undefined;

        if (this._dialogElement.style.display === 'flex') {
            this._dialogElement.style.opacity = '0';
            setTimeout(() => {
                this._dialogElement.style.display = 'none';
            }, 200);
        }
        this._modalOverlayElement.style.opacity = '0';
        setTimeout(() => {
            this._modalOverlayElement.style.display = 'none';
        }, 200);

        if (this._onDialogClosedCallback) {
            let resultData = returnDialogResult ?
                {title: this._title || '', data: this._getDialogResult<T>()} :
                {title: this._title || '', data: undefined};
            this._onDialogClosedCallback(resultData);
        }

        if (VanillaReaderUiDialog._sharedOnDialogClosedCallback) {
            VanillaReaderUiDialog._sharedOnDialogClosedCallback(this._title || '', silent);
        }

        this._modalOverlayElement.removeEventListener('click', this._event_modalOverlay_click);
    }

    /*
    * Private methods
    * */

    private _onFocusTrapDeactivated = () => {
        if (VanillaReaderUiDialog._sharedOnDialogFocusTrapDeactivatedCallback) {
            VanillaReaderUiDialog._sharedOnDialogFocusTrapDeactivatedCallback(this._returnFocusOnClose);
        }

    }

    /*
    * Class internal
    * */

    /**
     * This method can be overridden by an implementation if it needs to return data from the dialog.
     * It is called as part of the close procedure in the `close` method.
     * */
    _getDialogResult<T>(): T {
        return undefined!;
    }

    /*
    * EVENT HANDLERS
    * */

    private _event_modalOverlay_click = (_ev: MouseEvent) => {
        if (this._clickOutsideCallback) {
            this._clickOutsideCallback();
        }
        this.close();
    };
    private _event_modalOverlay_focus = (_ev: FocusEvent) => {
        if (this._modalOverlayHasFocusCallback) {
            this._modalOverlayHasFocusCallback();
        }

    };
    private _event_modalOverlay_keyup = (_ev: KeyboardEvent) => {
    };
    private _event_dialog_focus = (_ev: FocusEvent) => {
    };
    private _event_dialog_keyup = (ev: KeyboardEvent) => {

        switch (ev.key) {
            case 'Escape':
                if (this._closeOnEscKeyUp) {
                    this.close();
                }
                break;
        }
    };
    private _event_dialog_closeButtonClick = (_e: MouseEvent) => {
        this.close();
    };
}