/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.4.0
 */
 'use strict'; (function (s, f) { if (typeof define === 'function' && define.amd) { define([], function () { return f(s); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(s); } else { throw new Error('Unsupported runtime environment!'); } }( typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function (__self) {  var api = {}; (function () { var h="function"==typeof Object.defineProperties?Object.defineProperty:function(b,a,c){if(b==Array.prototype||b==Object.prototype)return b;b[a]=c.value;return b};function p(b){b=["object"==typeof globalThis&&globalThis,b,"object"==typeof window&&window,"object"==typeof self&&self,"object"==typeof global&&global];for(var a=0;a<b.length;++a){var c=b[a];if(c&&c.Math==Math)return c}throw Error("Cannot find global object");}var r=p(this);
function u(b,a){if(a)a:{var c=r;b=b.split(".");for(var d=0;d<b.length-1;d++){var e=b[d];if(!(e in c))break a;c=c[e]}b=b[b.length-1];d=c[b];a=a(d);a!=d&&null!=a&&h(c,b,{configurable:!0,writable:!0,value:a})}}
u("Array.prototype.copyWithin",function(b){function a(c){c=Number(c);return Infinity===c||-Infinity===c?c:c|0}return b?b:function(c,d,e){var l=this.length;c=a(c);d=a(d);e=void 0===e?l:a(e);c=0>c?Math.max(l+c,0):Math.min(c,l);d=0>d?Math.max(l+d,0):Math.min(d,l);e=0>e?Math.max(l+e,0):Math.min(e,l);if(c<d)for(;d<e;)d in this?this[c++]=this[d++]:(delete this[c++],d++);else for(e=Math.min(e,l+d-c),c+=e-d;e>d;)--e in this?this[--c]=this[e]:delete this[--c];return this}});
function v(b){var a=0;return function(){return a<b.length?{done:!1,value:b[a++]}:{done:!0}}}u("Symbol",function(b){function a(l){if(this instanceof a)throw new TypeError("Symbol is not a constructor");return new c(d+(l||"")+"_"+e++,l)}function c(l,f){this.g=l;h(this,"description",{configurable:!0,writable:!0,value:f})}if(b)return b;c.prototype.toString=function(){return this.g};var d="jscomp_symbol_"+(1E9*Math.random()>>>0)+"_",e=0;return a});
u("Symbol.iterator",function(b){if(b)return b;b=Symbol("Symbol.iterator");for(var a="Array Int8Array Uint8Array Uint8ClampedArray Int16Array Uint16Array Int32Array Uint32Array Float32Array Float64Array".split(" "),c=0;c<a.length;c++){var d=r[a[c]];"function"===typeof d&&"function"!=typeof d.prototype[b]&&h(d.prototype,b,{configurable:!0,writable:!0,value:function(){return w(v(this))}})}return b});u("Symbol.asyncIterator",function(b){return b?b:Symbol("Symbol.asyncIterator")});
function w(b){b={next:b};b[Symbol.iterator]=function(){return this};return b}function y(b,a){b instanceof String&&(b+="");var c=0,d=!1,e={next:function(){if(!d&&c<b.length){var l=c++;return{value:a(l,b[l]),done:!1}}d=!0;return{done:!0,value:void 0}}};e[Symbol.iterator]=function(){return e};return e}u("Array.prototype.entries",function(b){return b?b:function(){return y(this,function(a,c){return[a,c]})}});
u("Array.prototype.fill",function(b){return b?b:function(a,c,d){var e=this.length||0;0>c&&(c=Math.max(0,e+c));if(null==d||d>e)d=e;d=Number(d);0>d&&(d=Math.max(0,e+d));for(c=Number(c||0);c<d;c++)this[c]=a;return this}});function z(b,a,c){b instanceof String&&(b=String(b));for(var d=b.length,e=0;e<d;e++){var l=b[e];if(a.call(c,l,e,b))return{u:e,C:l}}return{u:-1,C:void 0}}u("Array.prototype.find",function(b){return b?b:function(a,c){return z(this,a,c).C}});
u("Array.prototype.findIndex",function(b){return b?b:function(a,c){return z(this,a,c).u}});u("Array.prototype.flat",function(b){return b?b:function(a){a=void 0===a?1:a;for(var c=[],d=0;d<this.length;d++){var e=this[d];Array.isArray(e)&&0<a?(e=Array.prototype.flat.call(e,a-1),c.push.apply(c,e)):c.push(e)}return c}});u("Array.prototype.flatMap",function(b){return b?b:function(a,c){for(var d=[],e=0;e<this.length;e++){var l=a.call(c,this[e],e,this);Array.isArray(l)?d.push.apply(d,l):d.push(l)}return d}});
u("Array.from",function(b){return b?b:function(a,c,d){c=null!=c?c:function(g){return g};var e=[],l="undefined"!=typeof Symbol&&Symbol.iterator&&a[Symbol.iterator];if("function"==typeof l){a=l.call(a);for(var f=0;!(l=a.next()).done;)e.push(c.call(d,l.value,f++))}else for(l=a.length,f=0;f<l;f++)e.push(c.call(d,a[f],f));return e}});u("Object.is",function(b){return b?b:function(a,c){return a===c?0!==a||1/a===1/c:a!==a&&c!==c}});
u("Array.prototype.includes",function(b){return b?b:function(a,c){var d=this;d instanceof String&&(d=String(d));var e=d.length;c=c||0;for(0>c&&(c=Math.max(c+e,0));c<e;c++){var l=d[c];if(l===a||Object.is(l,a))return!0}return!1}});u("Array.prototype.keys",function(b){return b?b:function(){return y(this,function(a){return a})}});u("Array.of",function(b){return b?b:function(a){return Array.from(arguments)}});u("Array.prototype.values",function(b){return b?b:function(){return y(this,function(a,c){return c})}});
function A(b){var a="undefined"!=typeof Symbol&&Symbol.iterator&&b[Symbol.iterator];return a?a.call(b):{next:v(b)}}var B;if("function"==typeof Object.setPrototypeOf)B=Object.setPrototypeOf;else{var C;a:{var D={a:!0},G={};try{G.__proto__=D;C=G.a;break a}catch(b){}C=!1}B=C?function(b,a){b.__proto__=a;if(b.__proto__!==a)throw new TypeError(b+" is not extensible");return b}:null}var H=B;u("globalThis",function(b){return b||r});function I(b,a){return Object.prototype.hasOwnProperty.call(b,a)}
u("WeakMap",function(b){function a(k){this.g=(g+=Math.random()+1).toString();if(k){k=A(k);for(var m;!(m=k.next()).done;)m=m.value,this.set(m[0],m[1])}}function c(){}function d(k){var m=typeof k;return"object"===m&&null!==k||"function"===m}function e(k){if(!I(k,f)){var m=new c;h(k,f,{value:m})}}function l(k){var m=Object[k];m&&(Object[k]=function(n){if(n instanceof c)return n;Object.isExtensible(n)&&e(n);return m(n)})}if(function(){if(!b||!Object.seal)return!1;try{var k=Object.seal({}),m=Object.seal({}),
n=new b([[k,2],[m,3]]);if(2!=n.get(k)||3!=n.get(m))return!1;n.delete(k);n.set(m,4);return!n.has(k)&&4==n.get(m)}catch(q){return!1}}())return b;var f="$jscomp_hidden_"+Math.random();l("freeze");l("preventExtensions");l("seal");var g=0;a.prototype.set=function(k,m){if(!d(k))throw Error("Invalid WeakMap key");e(k);if(!I(k,f))throw Error("WeakMap key fail: "+k);k[f][this.g]=m;return this};a.prototype.get=function(k){return d(k)&&I(k,f)?k[f][this.g]:void 0};a.prototype.has=function(k){return d(k)&&I(k,
f)&&I(k[f],this.g)};a.prototype.delete=function(k){return d(k)&&I(k,f)&&I(k[f],this.g)?delete k[f][this.g]:!1};return a});
u("Map",function(b){function a(){var g={};return g.j=g.next=g.head=g}function c(g,k){var m=g.g;return w(function(){if(m){for(;m.head!=g.g;)m=m.j;for(;m.next!=m.head;)return m=m.next,{done:!1,value:k(m)};m=null}return{done:!0,value:void 0}})}function d(g,k){var m=k&&typeof k;"object"==m||"function"==m?l.has(k)?m=l.get(k):(m=""+ ++f,l.set(k,m)):m="p_"+k;var n=g.i[m];if(n&&I(g.i,m))for(g=0;g<n.length;g++){var q=n[g];if(k!==k&&q.key!==q.key||k===q.key)return{id:m,list:n,index:g,h:q}}return{id:m,list:n,
index:-1,h:void 0}}function e(g){this.i={};this.g=a();this.size=0;if(g){g=A(g);for(var k;!(k=g.next()).done;)k=k.value,this.set(k[0],k[1])}}if(function(){if(!b||"function"!=typeof b||!b.prototype.entries||"function"!=typeof Object.seal)return!1;try{var g=Object.seal({x:4}),k=new b(A([[g,"s"]]));if("s"!=k.get(g)||1!=k.size||k.get({x:4})||k.set({x:4},"t")!=k||2!=k.size)return!1;var m=k.entries(),n=m.next();if(n.done||n.value[0]!=g||"s"!=n.value[1])return!1;n=m.next();return n.done||4!=n.value[0].x||
"t"!=n.value[1]||!m.next().done?!1:!0}catch(q){return!1}}())return b;var l=new WeakMap;e.prototype.set=function(g,k){g=0===g?0:g;var m=d(this,g);m.list||(m.list=this.i[m.id]=[]);m.h?m.h.value=k:(m.h={next:this.g,j:this.g.j,head:this.g,key:g,value:k},m.list.push(m.h),this.g.j.next=m.h,this.g.j=m.h,this.size++);return this};e.prototype.delete=function(g){g=d(this,g);return g.h&&g.list?(g.list.splice(g.index,1),g.list.length||delete this.i[g.id],g.h.j.next=g.h.next,g.h.next.j=g.h.j,g.h.head=null,this.size--,
!0):!1};e.prototype.clear=function(){this.i={};this.g=this.g.j=a();this.size=0};e.prototype.has=function(g){return!!d(this,g).h};e.prototype.get=function(g){return(g=d(this,g).h)&&g.value};e.prototype.entries=function(){return c(this,function(g){return[g.key,g.value]})};e.prototype.keys=function(){return c(this,function(g){return g.key})};e.prototype.values=function(){return c(this,function(g){return g.value})};e.prototype.forEach=function(g,k){for(var m=this.entries(),n;!(n=m.next()).done;)n=n.value,
g.call(k,n[1],n[0],this)};e.prototype[Symbol.iterator]=e.prototype.entries;var f=0;return e});u("Math.acosh",function(b){return b?b:function(a){a=Number(a);return Math.log(a+Math.sqrt(a*a-1))}});u("Math.asinh",function(b){return b?b:function(a){a=Number(a);if(0===a)return a;var c=Math.log(Math.abs(a)+Math.sqrt(a*a+1));return 0>a?-c:c}});
u("Math.log1p",function(b){return b?b:function(a){a=Number(a);if(.25>a&&-.25<a){for(var c=a,d=1,e=a,l=0,f=1;l!=e;)c*=a,f*=-1,e=(l=e)+f*c/++d;return e}return Math.log(1+a)}});u("Math.atanh",function(b){if(b)return b;var a=Math.log1p;return function(c){c=Number(c);return(a(c)-a(-c))/2}});u("Math.cbrt",function(b){return b?b:function(a){if(0===a)return a;a=Number(a);var c=Math.pow(Math.abs(a),1/3);return 0>a?-c:c}});
u("Math.clz32",function(b){return b?b:function(a){a=Number(a)>>>0;if(0===a)return 32;var c=0;0===(a&4294901760)&&(a<<=16,c+=16);0===(a&4278190080)&&(a<<=8,c+=8);0===(a&4026531840)&&(a<<=4,c+=4);0===(a&3221225472)&&(a<<=2,c+=2);0===(a&2147483648)&&c++;return c}});u("Math.cosh",function(b){if(b)return b;var a=Math.exp;return function(c){c=Number(c);return(a(c)+a(-c))/2}});
u("Math.expm1",function(b){return b?b:function(a){a=Number(a);if(.25>a&&-.25<a){for(var c=a,d=1,e=a,l=0;l!=e;)c*=a/++d,e=(l=e)+c;return e}return Math.exp(a)-1}});u("Math.fround",function(b){if(b)return b;if("function"!==typeof Float32Array)return function(c){return c};var a=new Float32Array(1);return function(c){a[0]=c;return a[0]}});
u("Math.hypot",function(b){return b?b:function(a){if(2>arguments.length)return arguments.length?Math.abs(arguments[0]):0;var c,d,e;for(c=e=0;c<arguments.length;c++)e=Math.max(e,Math.abs(arguments[c]));if(1E100<e||1E-100>e){if(!e)return e;for(c=d=0;c<arguments.length;c++){var l=Number(arguments[c])/e;d+=l*l}return Math.sqrt(d)*e}for(c=d=0;c<arguments.length;c++)l=Number(arguments[c]),d+=l*l;return Math.sqrt(d)}});
u("Math.imul",function(b){return b?b:function(a,c){a=Number(a);c=Number(c);var d=a&65535,e=c&65535;return d*e+((a>>>16&65535)*e+d*(c>>>16&65535)<<16>>>0)|0}});u("Math.log10",function(b){return b?b:function(a){return Math.log(a)/Math.LN10}});u("Math.log2",function(b){return b?b:function(a){return Math.log(a)/Math.LN2}});u("Math.sign",function(b){return b?b:function(a){a=Number(a);return 0===a||isNaN(a)?a:0<a?1:-1}});
u("Math.sinh",function(b){if(b)return b;var a=Math.exp;return function(c){c=Number(c);return 0===c?c:(a(c)-a(-c))/2}});u("Math.tanh",function(b){return b?b:function(a){a=Number(a);if(0===a)return a;var c=Math.exp(-2*Math.abs(a));c=(1-c)/(1+c);return 0>a?-c:c}});u("Math.trunc",function(b){return b?b:function(a){a=Number(a);if(isNaN(a)||Infinity===a||-Infinity===a||0===a)return a;var c=Math.floor(Math.abs(a));return 0>a?-c:c}});u("Number.EPSILON",function(){return Math.pow(2,-52)});
u("Number.MAX_SAFE_INTEGER",function(){return 9007199254740991});u("Number.MIN_SAFE_INTEGER",function(){return-9007199254740991});u("Number.isFinite",function(b){return b?b:function(a){return"number"!==typeof a?!1:!isNaN(a)&&Infinity!==a&&-Infinity!==a}});u("Number.isInteger",function(b){return b?b:function(a){return Number.isFinite(a)?a===Math.floor(a):!1}});u("Number.isNaN",function(b){return b?b:function(a){return"number"===typeof a&&isNaN(a)}});
u("Number.isSafeInteger",function(b){return b?b:function(a){return Number.isInteger(a)&&Math.abs(a)<=Number.MAX_SAFE_INTEGER}});u("Number.parseFloat",function(b){return b||parseFloat});u("Number.parseInt",function(b){return b||parseInt});var J="function"==typeof Object.assign?Object.assign:function(b,a){for(var c=1;c<arguments.length;c++){var d=arguments[c];if(d)for(var e in d)I(d,e)&&(b[e]=d[e])}return b};u("Object.assign",function(b){return b||J});
u("Object.entries",function(b){return b?b:function(a){var c=[],d;for(d in a)I(a,d)&&c.push([d,a[d]]);return c}});u("Object.fromEntries",function(b){return b?b:function(a){var c={};if(!(Symbol.iterator in a))throw new TypeError(""+a+" is not iterable");a=a[Symbol.iterator].call(a);for(var d=a.next();!d.done;d=a.next()){d=d.value;if(Object(d)!==d)throw new TypeError("iterable for fromEntries should yield objects");c[d[0]]=d[1]}return c}});u("Reflect",function(b){return b?b:{}});
u("Object.getOwnPropertySymbols",function(b){return b?b:function(){return[]}});u("Reflect.ownKeys",function(b){return b?b:function(a){var c=[],d=Object.getOwnPropertyNames(a);a=Object.getOwnPropertySymbols(a);for(var e=0;e<d.length;e++)("jscomp_symbol_"==d[e].substring(0,14)?a:c).push(d[e]);return c.concat(a)}});u("Object.getOwnPropertyDescriptors",function(b){return b?b:function(a){for(var c={},d=Reflect.ownKeys(a),e=0;e<d.length;e++)c[d[e]]=Object.getOwnPropertyDescriptor(a,d[e]);return c}});
u("Object.setPrototypeOf",function(b){return b||H});u("Object.values",function(b){return b?b:function(a){var c=[],d;for(d in a)I(a,d)&&c.push(a[d]);return c}});
u("Promise",function(b){function a(f){this.i=0;this.l=void 0;this.g=[];this.A=!1;var g=this.m();try{f(g.resolve,g.reject)}catch(k){g.reject(k)}}function c(){this.g=null}function d(f){return f instanceof a?f:new a(function(g){g(f)})}if(b)return b;c.prototype.i=function(f){if(null==this.g){this.g=[];var g=this;this.l(function(){g.o()})}this.g.push(f)};var e=r.setTimeout;c.prototype.l=function(f){e(f,0)};c.prototype.o=function(){for(;this.g&&this.g.length;){var f=this.g;this.g=[];for(var g=0;g<f.length;++g){var k=
f[g];f[g]=null;try{k()}catch(m){this.m(m)}}}this.g=null};c.prototype.m=function(f){this.l(function(){throw f;})};a.prototype.m=function(){function f(m){return function(n){k||(k=!0,m.call(g,n))}}var g=this,k=!1;return{resolve:f(this.H),reject:f(this.o)}};a.prototype.H=function(f){if(f===this)this.o(new TypeError("A Promise cannot resolve to itself"));else if(f instanceof a)this.J(f);else{a:switch(typeof f){case "object":var g=null!=f;break a;case "function":g=!0;break a;default:g=!1}g?this.G(f):this.v(f)}};
a.prototype.G=function(f){var g=void 0;try{g=f.then}catch(k){this.o(k);return}"function"==typeof g?this.K(g,f):this.v(f)};a.prototype.o=function(f){this.B(2,f)};a.prototype.v=function(f){this.B(1,f)};a.prototype.B=function(f,g){if(0!=this.i)throw Error("Cannot settle("+f+", "+g+"): Promise already settled in state"+this.i);this.i=f;this.l=g;2===this.i&&this.I();this.D()};a.prototype.I=function(){var f=this;e(function(){if(f.F()){var g=r.console;"undefined"!==typeof g&&g.error(f.l)}},1)};a.prototype.F=
function(){if(this.A)return!1;var f=r.CustomEvent,g=r.Event,k=r.dispatchEvent;if("undefined"===typeof k)return!0;"function"===typeof f?f=new f("unhandledrejection",{cancelable:!0}):"function"===typeof g?f=new g("unhandledrejection",{cancelable:!0}):(f=r.document.createEvent("CustomEvent"),f.initCustomEvent("unhandledrejection",!1,!0,f));f.promise=this;f.reason=this.l;return k(f)};a.prototype.D=function(){if(null!=this.g){for(var f=0;f<this.g.length;++f)l.i(this.g[f]);this.g=null}};var l=new c;a.prototype.J=
function(f){var g=this.m();f.s(g.resolve,g.reject)};a.prototype.K=function(f,g){var k=this.m();try{f.call(g,k.resolve,k.reject)}catch(m){k.reject(m)}};a.prototype.then=function(f,g){function k(t,x){return"function"==typeof t?function(E){try{m(t(E))}catch(F){n(F)}}:x}var m,n,q=new a(function(t,x){m=t;n=x});this.s(k(f,m),k(g,n));return q};a.prototype.catch=function(f){return this.then(void 0,f)};a.prototype.s=function(f,g){function k(){switch(m.i){case 1:f(m.l);break;case 2:g(m.l);break;default:throw Error("Unexpected state: "+
m.i);}}var m=this;null==this.g?l.i(k):this.g.push(k);this.A=!0};a.resolve=d;a.reject=function(f){return new a(function(g,k){k(f)})};a.race=function(f){return new a(function(g,k){for(var m=A(f),n=m.next();!n.done;n=m.next())d(n.value).s(g,k)})};a.all=function(f){var g=A(f),k=g.next();return k.done?d([]):new a(function(m,n){function q(E){return function(F){t[E]=F;x--;0==x&&m(t)}}var t=[],x=0;do t.push(void 0),x++,d(k.value).s(q(t.length-1),n),k=g.next();while(!k.done)})};return a});
u("Promise.allSettled",function(b){function a(d){return{status:"fulfilled",value:d}}function c(d){return{status:"rejected",reason:d}}return b?b:function(d){var e=this;d=Array.from(d,function(l){return e.resolve(l).then(a,c)});return e.all(d)}});u("Promise.prototype.finally",function(b){return b?b:function(a){return this.then(function(c){return Promise.resolve(a()).then(function(){return c})},function(c){return Promise.resolve(a()).then(function(){throw c;})})}});
var K="function"==typeof Object.create?Object.create:function(b){function a(){}a.prototype=b;return new a};function L(b,a){b.prototype=K(a.prototype);b.prototype.constructor=b;if(H)H(b,a);else for(var c in a)if("prototype"!=c)if(Object.defineProperties){var d=Object.getOwnPropertyDescriptor(a,c);d&&Object.defineProperty(b,c,d)}else b[c]=a[c];b.L=a.prototype}
u("AggregateError",function(b){function a(c,d){d=Error(d);"stack"in d&&(this.stack=d.stack);this.errors=c;this.message=d.message}if(b)return b;L(a,Error);a.prototype.name="AggregateError";return a});u("Promise.any",function(b){return b?b:function(a){a=a instanceof Array?a:Array.from(a);return Promise.all(a.map(function(c){return Promise.resolve(c).then(function(d){throw d;},function(d){return d})})).then(function(c){throw new AggregateError(c,"All promises were rejected");},function(c){return c})}});
u("Reflect.apply",function(b){if(b)return b;var a=Function.prototype.apply;return function(c,d,e){return a.call(c,d,e)}});
var M=function(){function b(){function c(){}new c;Reflect.construct(c,[],function(){});return new c instanceof c}if("undefined"!=typeof Reflect&&Reflect.construct){if(b())return Reflect.construct;var a=Reflect.construct;return function(c,d,e){c=a(c,d);e&&Reflect.setPrototypeOf(c,e.prototype);return c}}return function(c,d,e){void 0===e&&(e=c);e=K(e.prototype||Object.prototype);return Function.prototype.apply.call(c,e,d)||e}}();u("Reflect.construct",function(){return M});
u("Reflect.defineProperty",function(b){return b?b:function(a,c,d){try{Object.defineProperty(a,c,d);var e=Object.getOwnPropertyDescriptor(a,c);return e?e.configurable===(d.configurable||!1)&&e.enumerable===(d.enumerable||!1)&&("value"in e?e.value===d.value&&e.writable===(d.writable||!1):e.get===d.get&&e.set===d.set):!1}catch(l){return!1}}});u("Reflect.deleteProperty",function(b){return b?b:function(a,c){if(!I(a,c))return!0;try{return delete a[c]}catch(d){return!1}}});
u("Reflect.getOwnPropertyDescriptor",function(b){return b||Object.getOwnPropertyDescriptor});u("Reflect.getPrototypeOf",function(b){return b||Object.getPrototypeOf});function N(b,a){for(;b;){var c=Reflect.getOwnPropertyDescriptor(b,a);if(c)return c;b=Reflect.getPrototypeOf(b)}}u("Reflect.get",function(b){return b?b:function(a,c,d){if(2>=arguments.length)return a[c];var e=N(a,c);if(e)return e.get?e.get.call(d):e.value}});u("Reflect.has",function(b){return b?b:function(a,c){return c in a}});
u("Reflect.isExtensible",function(b){return b?b:"function"==typeof Object.isExtensible?Object.isExtensible:function(){return!0}});u("Reflect.preventExtensions",function(b){return b?b:"function"!=typeof Object.preventExtensions?function(){return!1}:function(a){Object.preventExtensions(a);return!Object.isExtensible(a)}});
u("Reflect.set",function(b){return b?b:function(a,c,d,e){var l=N(a,c);return l?l.set?(l.set.call(3<arguments.length?e:a,d),!0):l.writable&&!Object.isFrozen(a)?(a[c]=d,!0):!1:Reflect.isExtensible(a)?(a[c]=d,!0):!1}});u("Reflect.setPrototypeOf",function(b){return b?b:H?function(a,c){try{return H(a,c),!0}catch(d){return!1}}:null});
u("Set",function(b){function a(c){this.g=new Map;if(c){c=A(c);for(var d;!(d=c.next()).done;)this.add(d.value)}this.size=this.g.size}if(function(){if(!b||"function"!=typeof b||!b.prototype.entries||"function"!=typeof Object.seal)return!1;try{var c=Object.seal({x:4}),d=new b(A([c]));if(!d.has(c)||1!=d.size||d.add(c)!=d||1!=d.size||d.add({x:4})!=d||2!=d.size)return!1;var e=d.entries(),l=e.next();if(l.done||l.value[0]!=c||l.value[1]!=c)return!1;l=e.next();return l.done||l.value[0]==c||4!=l.value[0].x||
l.value[1]!=l.value[0]?!1:e.next().done}catch(f){return!1}}())return b;a.prototype.add=function(c){c=0===c?0:c;this.g.set(c,c);this.size=this.g.size;return this};a.prototype.delete=function(c){c=this.g.delete(c);this.size=this.g.size;return c};a.prototype.clear=function(){this.g.clear();this.size=0};a.prototype.has=function(c){return this.g.has(c)};a.prototype.entries=function(){return this.g.entries()};a.prototype.values=function(){return this.g.values()};a.prototype.keys=a.prototype.values;a.prototype[Symbol.iterator]=
a.prototype.values;a.prototype.forEach=function(c,d){var e=this;this.g.forEach(function(l){return c.call(d,l,l,e)})};return a});function O(b,a,c){if(null==b)throw new TypeError("The 'this' value for String.prototype."+c+" must not be null or undefined");if(a instanceof RegExp)throw new TypeError("First argument to String.prototype."+c+" must not be a regular expression");return b+""}
u("String.prototype.codePointAt",function(b){return b?b:function(a){var c=O(this,null,"codePointAt"),d=c.length;a=Number(a)||0;if(0<=a&&a<d){a|=0;var e=c.charCodeAt(a);if(55296>e||56319<e||a+1===d)return e;a=c.charCodeAt(a+1);return 56320>a||57343<a?e:1024*(e-55296)+a+9216}}});u("String.prototype.endsWith",function(b){return b?b:function(a,c){var d=O(this,a,"endsWith");void 0===c&&(c=d.length);c=Math.max(0,Math.min(c|0,d.length));for(var e=a.length;0<e&&0<c;)if(d[--c]!=a[--e])return!1;return 0>=e}});
u("String.fromCodePoint",function(b){return b?b:function(a){for(var c="",d=0;d<arguments.length;d++){var e=Number(arguments[d]);if(0>e||1114111<e||e!==Math.floor(e))throw new RangeError("invalid_code_point "+e);65535>=e?c+=String.fromCharCode(e):(e-=65536,c+=String.fromCharCode(e>>>10&1023|55296),c+=String.fromCharCode(e&1023|56320))}return c}});u("String.prototype.includes",function(b){return b?b:function(a,c){return-1!==O(this,a,"includes").indexOf(a,c||0)}});
u("String.prototype.matchAll",function(b){return b?b:function(a){if(a instanceof RegExp&&!a.global)throw new TypeError("RegExp passed into String.prototype.matchAll() must have global tag.");var c=new RegExp(a,a instanceof RegExp?void 0:"g"),d=this,e=!1,l={next:function(){if(e)return{value:void 0,done:!0};var f=c.exec(d);if(!f)return e=!0,{value:void 0,done:!0};""===f[0]&&(c.lastIndex+=1);return{value:f,done:!1}}};l[Symbol.iterator]=function(){return l};return l}});
u("String.prototype.repeat",function(b){return b?b:function(a){var c=O(this,null,"repeat");if(0>a||1342177279<a)throw new RangeError("Invalid count value");a|=0;for(var d="";a;)if(a&1&&(d+=c),a>>>=1)c+=c;return d}});function P(b,a){b=void 0!==b?String(b):" ";return 0<a&&b?b.repeat(Math.ceil(a/b.length)).substring(0,a):""}u("String.prototype.padEnd",function(b){return b?b:function(a,c){var d=O(this,null,"padStart");return d+P(c,a-d.length)}});
u("String.prototype.padStart",function(b){return b?b:function(a,c){var d=O(this,null,"padStart");return P(c,a-d.length)+d}});u("String.raw",function(b){return b?b:function(a,c){if(null==a)throw new TypeError("Cannot convert undefined or null to object");for(var d=a.raw,e=d.length,l="",f=0;f<e;++f)l+=d[f],f+1<e&&f+1<arguments.length&&(l+=String(arguments[f+1]));return l}});
u("String.prototype.replaceAll",function(b){return b?b:function(a,c){if(a instanceof RegExp&&!a.global)throw new TypeError("String.prototype.replaceAll called with a non-global RegExp argument.");return a instanceof RegExp?this.replace(a,c):this.replace(new RegExp(String(a).replace(/([-()\[\]{}+?*.$\^|,:#<!\\])/g,"\\$1").replace(/\x08/g,"\\x08"),"g"),c)}});
u("String.prototype.startsWith",function(b){return b?b:function(a,c){var d=O(this,a,"startsWith"),e=d.length,l=a.length;c=Math.max(0,Math.min(c|0,d.length));for(var f=0;f<l&&c<e;)if(d[c++]!=a[f++])return!1;return f>=l}});u("String.prototype.trimRight",function(b){function a(){return this.replace(/[\s\xa0]+$/,"")}return b||a});u("String.prototype.trimEnd",function(b){return b||String.prototype.trimRight});
u("String.prototype.trimLeft",function(b){function a(){return this.replace(/^[\s\xa0]+/,"")}return b||a});u("String.prototype.trimStart",function(b){return b||String.prototype.trimLeft});function Q(b){return b?b:Array.prototype.copyWithin}u("Int8Array.prototype.copyWithin",Q);u("Uint8Array.prototype.copyWithin",Q);u("Uint8ClampedArray.prototype.copyWithin",Q);u("Int16Array.prototype.copyWithin",Q);u("Uint16Array.prototype.copyWithin",Q);u("Int32Array.prototype.copyWithin",Q);
u("Uint32Array.prototype.copyWithin",Q);u("Float32Array.prototype.copyWithin",Q);u("Float64Array.prototype.copyWithin",Q);function R(b){return b?b:Array.prototype.fill}u("Int8Array.prototype.fill",R);u("Uint8Array.prototype.fill",R);u("Uint8ClampedArray.prototype.fill",R);u("Int16Array.prototype.fill",R);u("Uint16Array.prototype.fill",R);u("Int32Array.prototype.fill",R);u("Uint32Array.prototype.fill",R);u("Float32Array.prototype.fill",R);u("Float64Array.prototype.fill",R);
u("WeakSet",function(b){function a(c){this.g=new WeakMap;if(c){c=A(c);for(var d;!(d=c.next()).done;)this.add(d.value)}}if(function(){if(!b||!Object.seal)return!1;try{var c=Object.seal({}),d=Object.seal({}),e=new b([c]);if(!e.has(c)||e.has(d))return!1;e.delete(c);e.add(d);return!e.has(c)&&e.has(d)}catch(l){return!1}}())return b;a.prototype.add=function(c){this.g.set(c,!0);return this};a.prototype.has=function(c){return this.g.has(c)};a.prototype.delete=function(c){return this.g.delete(c)};return a});var S=[Int8Array,Uint8Array,Uint8ClampedArray,Int16Array,Uint16Array,Int32Array,Uint32Array,Float32Array,Float64Array];function T(b,a){var c=this.length;"number"!==typeof b&&(b=0);"number"!==typeof a&&(a=c);0>b&&(b=c+b);0>a&&(a=c+a);c=this.constructor;b=b*c.BYTES_PER_ELEMENT+this.byteOffset;a=a*c.BYTES_PER_ELEMENT+this.byteOffset;return new c(this.buffer.slice(b,a))}
if(!("slice"in S[0].prototype))for(var U=0;U<S.length;U++)Object.defineProperty(S[U].prototype,"slice",{configurable:!0,writable:!0,value:T}); }).call(__self); return api; } ) );
