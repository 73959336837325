/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IAttributeData} from '@colibrio/colibrio-reader-framework/colibrio-core-base';
import {IContentBlockData} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';

export class ContentBlockDataHtmlRenderer {

    rendererToElement(blocks: IContentBlockData[], rootElement: HTMLElement) {
        blocks.forEach((block) => {
            this._templateBlockData(block, rootElement);
        });
    }

    rendererToString(blocks: IContentBlockData[], wrapperElementTagName: string = 'div'): string {
        let rootElement = document.createElement(wrapperElementTagName);
        blocks.forEach((block) => {
            this._templateBlockData(block, rootElement);
        });
        return rootElement.innerHTML;
    }

    private _templateBlockData(block: IContentBlockData, parentElement: HTMLElement | DocumentFragment) {

        let el = this._applyElementTemplate(block);

        if (el) {

            block.attributes.forEach((attrData: IAttributeData) => {
                if (attrData.prefix) {
                    el.setAttributeNS(attrData.prefix, attrData.localName, attrData.value);
                } else {
                    el.setAttribute(attrData.localName, attrData.value);
                }
            });

            if (block.children.length > 0) {
                block.children.forEach((childBlock) => {
                    this._templateBlockData(childBlock, el);
                });
            }

            parentElement.appendChild(el);
        }

        return parentElement;
    }

    private _applyElementTemplate(block: IContentBlockData) {
        let element: HTMLElement;

        switch (block.blockType) {
            case 'ARTICLE':
                element = document.createElement('article');
                break;
            case 'ASIDE':
                element = document.createElement('aside');
                break;
            case 'BLOCK_QUOTE':
                element = document.createElement('blockquote');
                break;
            case 'DESCRIPTION_LIST':
                element = document.createElement('dl');
                break;
            case 'DESCRIPTION_TERM':
                element = document.createElement('dt');
                break;
            case 'DESCRIPTION_DEFINITION':
                element = document.createElement('dd');
                break;
            case 'FIGURE':
                element = document.createElement('figure');
                break;
            case 'GENERIC':
                element = document.createElement('div');
                break;
            case 'ORDERED_LIST':
                element = document.createElement('ol');
                break;
            case 'LIST_ITEM':
                element = document.createElement('li');
                break;
            case 'SECTION':
                element = document.createElement('section');
                break;
            case 'TABLE':
                element = document.createElement('table');
                break;
            case 'TABLE_BODY':
                element = document.createElement('tbody');
                break;
            case 'TABLE_CELL':
                element = document.createElement('td');
                break;
            case 'TABLE_FOOTER':
                element = document.createElement('tfooter');
                break;
            case 'TABLE_HEADER':
                element = document.createElement('th');
                break;
            case 'TABLE_ROW':
                element = document.createElement('tr');
                break;
            case 'UNORDERED_LIST':
                element = document.createElement('ul');
                break;

            case 'AUDIO':
                element = document.createElement('audio');
                break;
            case 'IMAGE':
                element = document.createElement('image');
                break;
            case 'VIDEO':
                element = document.createElement('video');
                break;

            case 'HEADING1':
                element = document.createElement('h1');
                break;
            case 'HEADING2':
                element = document.createElement('h2');
                break;
            case 'HEADING3':
                element = document.createElement('h3');
                break;
            case 'HEADING4':
                element = document.createElement('h4');
                break;
            case 'HEADING5':
                element = document.createElement('h5');
                break;
            case 'HEADING6':
                element = document.createElement('h6');
                break;
            case 'PARAGRAPH':
                element = document.createElement('p');
                break;
            case 'TEXT':
                element = document.createElement('p');
                break;
            default:
                element = document.createElement('div');
        }

        const textNode = document.createTextNode(block.textContent);
        element.appendChild(textNode);

        return element;

    }
}
