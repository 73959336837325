/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {Base64} from '@colibrio/colibrio-reader-framework/colibrio-core-base';
import {BlobUrlManager, MediaType} from '@colibrio/colibrio-reader-framework/colibrio-core-io-base';
import {ILocator} from '@colibrio/colibrio-reader-framework/colibrio-core-locator';
import {IPublicationMetadataItem} from '@colibrio/colibrio-reader-framework/colibrio-core-publication-base';
import {IWpPublication} from '@colibrio/colibrio-reader-framework/colibrio-core-publication-wp';
import {
    IContentLocation,
    IPublicationStyleOptions,
    IReaderDocument,
    IReaderPublication,
    IReaderPublicationNavigation,
    IReaderPublicationStorageState,
    IReaderViewOptions,
    NavigationCollectionType,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';
import {IEpubReaderPublication} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-formatadapter-epub';
import {IPdfReaderPublication} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-formatadapter-pdf';
import {
    IWpAudiobookReaderPublication,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-formatadapter-wp-audiobook';

import {
    IVanillaReaderStreamedResourceStorage
} from '../VanillaReaderDataStore/VanillaReaderStreamedResourceIndexDbStorage';
import {
    IVanillaReaderAppEvent_NAVIGATION_TREE_READY,
    IVanillaReaderAppEvent_PUBLICATION_STYLE_OPTIONS_CHANGED,
    VanillaReaderAppEvents,
    VanillaReaderEventBus,
} from './VanillaReaderEventBus';
import {
    IVanillaReaderBookmarkData,
    IVanillaReaderHighlightData,
    IVanillaReaderPublicationCacheData,
    IVanillaReaderPublicationData,
    IVanillaReaderPublicationLandmarkData,
    IVanillaReaderPublicationOptionsData,
    IVanillaReaderReadingPositionData,
    IVanillaReaderReadingProgressionTimeline,
    IVanillaReaderTtsOptions,
    VanillaReaderPublicationLandmarkType,
} from './VanillaReaderModel';
import {VanillaReaderNavigationTree} from './VanillaReaderNavigationTree';
import {VanillaReaderPublicationLandmarkCollection} from './VanillaReaderPublicationLandmarkCollection';
import {VanillaReaderView} from './VanillaReaderView';
import {IVanillaReaderPublicationDataStore} from "../VanillaReaderDataStore/IVanillaReaderPublicationDataStore";
import {IVanillaReaderHighlightDataStore} from "../VanillaReaderDataStore/IVanillaReaderHighlightDataStore";
import {IVanillaReaderBookmarkDataStore} from "../VanillaReaderDataStore/IVanillaReaderBookmarkDataStore";

/*
* # VanillaReaderPublication
*
* ## PRIMARY COLIBRIO FRAMEWORK TYPES
*
* - IReaderPublication
*
* ## RESPONSIBILITIES
*
* This is a helper wrapper around the Colibrio Reader Framework's `IReaderPublication` type. It's main purpose
* is to abstract away the Colibrio "base type", and also to add a more rich serializable format that holds publication
* specific runtime data such as the latest reading position, highlights and bookmarks as well as other information
* such as search index data and localstorage state.
*
* */

export class VanillaReaderPublication {

    private _id: string = '';
    private _fileName: string = '';
    private _fileSourceUri: string;
    private _title: string | undefined = '';
    private _format: MediaType;
    private _url: string = '';
    private _isRtl: boolean = false;
    private _isFixedLayout: boolean = false;
    private _hasMoSyncMedia: boolean = false;
    private _metadata: IPublicationMetadataItem[] = [];
    private _coverImageUrl: URL | undefined = undefined;
    private _coverImageData: string | undefined = undefined;
    private _blobUrlManager: BlobUrlManager = new BlobUrlManager();
    private _landmarksCollection: VanillaReaderPublicationLandmarkCollection | undefined = undefined;
    private _landmarksCollectionData: IVanillaReaderPublicationLandmarkData | undefined = undefined;
    private _navigationTree: VanillaReaderNavigationTree;
    private _progressionTimeline: IVanillaReaderReadingProgressionTimeline | undefined;
    private _currentReadingPositionData: IVanillaReaderReadingPositionData | undefined;
    private _ttsOptions: IVanillaReaderTtsOptions | undefined;

    private _initialPublicationCacheDataState: IVanillaReaderPublicationCacheData | undefined;
    private _initialPublicationOptionsDataState: IVanillaReaderPublicationOptionsData | undefined;
    private _initialPublicationDataState: IVanillaReaderPublicationData | undefined;

    constructor(
        fileName: string,
        fileSourceUri: string,
        private _colibrioReaderPublication: IReaderPublication,
        private _bookmarksDataStore: IVanillaReaderBookmarkDataStore,
        private _highlightsDataStore: IVanillaReaderHighlightDataStore,
        private _vanillaReaderPublicationDataStore: IVanillaReaderPublicationDataStore,
        private _publicationResourceDataStore: IVanillaReaderStreamedResourceStorage | undefined,
    ) {

        // An EPUB can contain both fixed layout documents and reflowable document. In the Imbiblio Reader however we treat a publication that has
        // one or more "pre-paginated" content documents as being fixed layout.
        const _publicationIsFixedLayout = this._colibrioReaderPublication.getSpine().some((doc: IReaderDocument) => {
            return doc.getSourceContentDocument().getLayout() === 'PRE_PAGINATED';
        });

        this._fileName = fileName;
        this._fileSourceUri = fileSourceUri;
        this._id = this._colibrioReaderPublication.getSourcePublication().getMetadata().getIdentifiers()[0]?.content.value || fileSourceUri;
        this._title = this._colibrioReaderPublication.getSourcePublication().getMetadata().getTitles()[0]?.content.value;
        this._url = this._colibrioReaderPublication.getDefaultLocatorUrl();
        this._format = this._colibrioReaderPublication.getSourcePublication().getMediaType();
        this._isFixedLayout = _publicationIsFixedLayout;
        this._isRtl = this._colibrioReaderPublication.getSourcePublication().getPageProgressionDirection() === 'RTL';
        this._hasMoSyncMedia = this._colibrioReaderPublication.getAvailableSyncMediaFormats().some(value => value === 'EPUB_MEDIA_OVERLAY');
        this._metadata = this._colibrioReaderPublication.getSourcePublication().getMetadata().getAll();

        // Cover images are only available for EPUBs and Audiobooks at the moment.
        if (this.isEpubReaderPublication(this._colibrioReaderPublication) || this.isWpAudiobookReaderPublication(this._colibrioReaderPublication)) {
            this._coverImageUrl = this._colibrioReaderPublication.getSourcePublication().getCoverImageResourceUrl() || undefined;
        }

        // The navigation tree is instantiated, but it is not yet built. That happens when the timeline has been built.
        // This is because we want to map timeline positions to navigation items.
        // See `VanillaReader._event_timelineReady()` and `VanillaReaderNavigationTree.build()`
        this._navigationTree = new VanillaReaderNavigationTree();

        // Now we need to do some asynchronous set up, so we call the async _initializeState() method.
        this._initializeState().catch(console.warn);
    }

    private async _initializeState() {
        let publicationHasStoredCachedData = await this._vanillaReaderPublicationDataStore.hasPublicationCacheData(this.id);
        let publicationHasStoredOptionsData = await this._vanillaReaderPublicationDataStore.hasPublicationOptionsData(this.id);
        let publicationHasStoredMetadata = await this._vanillaReaderPublicationDataStore.hasPublicationData(this.id);

        if(publicationHasStoredCachedData) {
            this._initialPublicationCacheDataState = await this._vanillaReaderPublicationDataStore.fetchPublicationCacheData(this.id);
            // Updating the tree data seems to be an issue as it can create duplicated. Not sure why still.
            // if (this._initialPublicationCacheDataState?.navigationTreeData) {
            //     this._navigationTree.setTreeData(this._initialPublicationCacheDataState?.navigationTreeData);
            // }

            if (this._initialPublicationCacheDataState?.landmarkData) {
                // The actual landmarksCollection is created late, so we need to store the data in order to
                // set it later in the `fetchLandmarksCollection()` method.
                this._landmarksCollectionData = this._initialPublicationCacheDataState.landmarkData;
            }
        }

        if(publicationHasStoredOptionsData) {
            this._initialPublicationOptionsDataState = await this._vanillaReaderPublicationDataStore.fetchPublicationOptionsData(this.id);
            if (this._initialPublicationOptionsDataState?.ttsOptions) {
                this._ttsOptions = this._initialPublicationOptionsDataState.ttsOptions;
            }
        }

        if(publicationHasStoredMetadata) {
            this._initialPublicationDataState = await this._vanillaReaderPublicationDataStore.fetchPublicationData(this.id);
            if (this._initialPublicationDataState?.coverImageAsBase64) {
                this._coverImageData = this._initialPublicationDataState.coverImageAsBase64;
            }
        }

        // If this is the first time that the publication has been opened, or some of the data has not previously
        // been persisted, we need to do so.

        if(!publicationHasStoredMetadata) {
            this._persistCurrentPublicationDataState().catch(console.warn);
        }

        if(!publicationHasStoredOptionsData) {
            this._persistCurrentPublicationOptionsState().catch(console.warn);
        }

        if(!publicationHasStoredCachedData) {
            this._persistCurrentPublicationCacheDataState().catch(console.warn);
        }
    }

    public get id(): string {
        return this._id;
    }

    public get fileName(): string {
        return this._fileName;
    }

    public get fileSourceUri(): string {
        return this._fileSourceUri;
    }

    public get title(): string | undefined {
        return this._title;
    }

    public get url(): string {
        return this._url;
    }

    public get format(): string {
        return this._format;
    }

    public get isRtl(): boolean {
        return this._isRtl;
    }

    public get isFixedLayout(): boolean {
        return this._isFixedLayout;
    }

    public get isAudiobook(): boolean {
        return this._format === MediaType.APPLICATION_LPF_ZIP;
    }

    public get hasMoSyncMedia(): boolean {
        return this._hasMoSyncMedia;
    }

    public get metadata(): IPublicationMetadataItem[] {
        return this._metadata;
    }

    public get language(): string | undefined {
        if(this._colibrioReaderPublication.getSourcePublication().getMetadata().getLanguages().length > 0) {
            return  this._colibrioReaderPublication.getSourcePublication().getMetadata().getLanguages()[0].content.value || undefined;
        } else {
            return;
        }
    }

    public get navigationTree(): VanillaReaderNavigationTree {
        return this._navigationTree;
    }

    public get currentReadingPositionData(): IVanillaReaderReadingPositionData | undefined {
        return this._currentReadingPositionData;
    }

    public setCurrentReadingPositionData(readingPositionData: IVanillaReaderReadingPositionData | undefined) {
        this._currentReadingPositionData = readingPositionData;
        if (readingPositionData && readingPositionData.locator) {
            // Save the new reading position data to the `IVanillaReaderPublicationDataStore` store.
            this._vanillaReaderPublicationDataStore.setPublicationReadingPositionData(this.id, readingPositionData).catch(console.warn);
        }
    }

    public get storageState(): IReaderPublicationStorageState {
        return this._colibrioReaderPublication.getReaderPublicationStorage().getStorageState();
    }

    public set storageState(state: IReaderPublicationStorageState) {
        this._colibrioReaderPublication.getReaderPublicationStorage().setStorageState(state);
    }

    public get ttsOptions(): IVanillaReaderTtsOptions | undefined {
        return this._ttsOptions;
    }

    public set ttsOptions(options: IVanillaReaderTtsOptions | undefined) {
        this._ttsOptions = options;
        
        // Save the new TTS options data to the `IVanillaReaderPublicationDataStore` store.
        this._vanillaReaderPublicationDataStore.updatePublicationOptionsData(this.id, {
            publicationId: this.id,
            ttsOptions: options,

        }).catch(console.warn);

    }

    public get colibrioReaderPublication(): IReaderPublication {
        return this._colibrioReaderPublication;
    }

    public set progressionTimeline(instance: IVanillaReaderReadingProgressionTimeline | undefined) {
        this._progressionTimeline = instance;

        // Save the new timeline data to the `IVanillaReaderPublicationDataStore` store.
        this._vanillaReaderPublicationDataStore.updatePublicationCacheData(this.id, {
            publicationId: this.id,
            timelineData: this._progressionTimeline?.toSerializedData(),
        }).catch(console.warn);

    }

    public get progressionTimeline(): IVanillaReaderReadingProgressionTimeline | undefined {
        return this._progressionTimeline;
    }

    async hasOfflineData(): Promise<boolean> {
        return await this._publicationResourceDataStore?.hasResource(this.id) || false;
    }

    async fetchLandmarkCollection(timeline?: IVanillaReaderReadingProgressionTimeline): Promise<VanillaReaderPublicationLandmarkCollection | undefined> {
        if (this._landmarksCollection) {
            return this._landmarksCollection;
        }

        this._landmarksCollection = new VanillaReaderPublicationLandmarkCollection(this._colibrioReaderPublication, timeline);

        // We have data that we can restore so that we do not need to repopulate the collection.
        if (this._landmarksCollectionData) {
            this._landmarksCollection.setPublicatonLandmarkData(this._landmarksCollectionData);
            return this._landmarksCollection;
        }

        await this._landmarksCollection.populate(undefined, [
            VanillaReaderPublicationLandmarkType.HEADING1,
            VanillaReaderPublicationLandmarkType.HEADING2,
            VanillaReaderPublicationLandmarkType.HEADING3,
            VanillaReaderPublicationLandmarkType.HEADING4,
            VanillaReaderPublicationLandmarkType.HEADING5,
            VanillaReaderPublicationLandmarkType.HEADING6,
            VanillaReaderPublicationLandmarkType.FOOTNOTE,
            VanillaReaderPublicationLandmarkType.FIGURE,
            VanillaReaderPublicationLandmarkType.IMAGE,
        ]);

        // Save the landmark data to the `IVanillaReaderPublicationDataStore` store.
        this._vanillaReaderPublicationDataStore.updatePublicationCacheData(this.id, {
            publicationId: this.id,
            landmarkData: this._landmarksCollection.getAll(),
        }).catch(console.warn);

        return this._landmarksCollection;
    }

    async fetchLandmarkCollectionForDocument(
        documentIndex: number,
        timeline?: IVanillaReaderReadingProgressionTimeline,
    ): Promise<VanillaReaderPublicationLandmarkCollection | undefined> {
        if (!this._landmarksCollection) {
            this._landmarksCollection = new VanillaReaderPublicationLandmarkCollection(this._colibrioReaderPublication, timeline);
        }

        await this._landmarksCollection.populate([documentIndex], [
            VanillaReaderPublicationLandmarkType.HEADING1,
            VanillaReaderPublicationLandmarkType.HEADING2,
            VanillaReaderPublicationLandmarkType.HEADING3,
            VanillaReaderPublicationLandmarkType.HEADING4,
            VanillaReaderPublicationLandmarkType.HEADING5,
            VanillaReaderPublicationLandmarkType.HEADING6,
            VanillaReaderPublicationLandmarkType.FOOTNOTE,
            VanillaReaderPublicationLandmarkType.FIGURE,
            VanillaReaderPublicationLandmarkType.IMAGE,
        ]);
        return this._landmarksCollection;
    }

    async fetchCoverImageAsBase64(): Promise<string | undefined> {

        if (this._coverImageData) {
            return this._coverImageData;
        }

        if (this.isEpubReaderPublication(this._colibrioReaderPublication) && this._coverImageUrl) {
            let imageResource = await this._colibrioReaderPublication.getSourcePublication().getEpubResourceProvider().fetch(this._coverImageUrl);
            if (imageResource) {
                this._coverImageData = Base64.encode(await imageResource.asUint8Array());
                return this._coverImageData;

            }
        } else if (this.isWpAudiobookReaderPublication(this._colibrioReaderPublication)) {
            const publication = this._colibrioReaderPublication.getSourcePublication() as IWpPublication;
            const coverImageResourceUrl = publication.getCoverImageResourceUrl();
            if (coverImageResourceUrl) {
                const coverImageResponse = await publication.getBackingResourceProvider().fetch(coverImageResourceUrl);
                this._coverImageData = Base64.encode(await coverImageResponse.asUint8Array());
                return this._coverImageData;
            }
        }

        return undefined;
    }

    async fetchResourceBlobUrl(url: string, forceDataUrl = false): Promise<string | undefined> {
        let resource;

        if (this.isEpubReaderPublication(this._colibrioReaderPublication)) {
            resource = await this._colibrioReaderPublication.getSourcePublication().getEpubResourceProvider().fetch(url);
        }

        if (resource) {
            let resourceData = await resource.asUint8Array();
            let mediaType = resource.getMetadata().mediaType;
            if (mediaType) {
                return this._blobUrlManager.createUrl([resourceData], mediaType, forceDataUrl).url;
            }
        }
        return undefined;
    }

    revokeResourceBlobUrl(blobUrl: string): void {
        this._blobUrlManager.revokeUrl(blobUrl);
    }

    revokeAllResourceBlobUrls(): void {
        this._blobUrlManager.revokeAllUrls();
    }

    getColibrioReaderPublication(): IReaderPublication {
        return this._colibrioReaderPublication;
    }

    /**
     *
     * This method takes in `IPublicationStyleOptions` data and applies the style options using `IReaderView.setOptions()`.
     * `IPublicationStyleOptions` include options for which fonts to use, what colors to display etc.
     *
     * */
    async setPublicationStyleOptions(styleOptions: IPublicationStyleOptions, vanillaReaderView: VanillaReaderView) {
        let colibrioReaderViewOptions: IReaderViewOptions = {
            publicationStyleOptions: styleOptions,
        };

        await vanillaReaderView.setVanillaReaderOptions({colibrioReaderViewOptions});

        if (!this.isAudiobook) {
            vanillaReaderView.getActiveRenderer()?.setOptions({
                pageBackgroundColor: colibrioReaderViewOptions.publicationStyleOptions?.palette?.backgroundLight,
            });
        }

        // Save the new options data to the `IVanillaReaderPublicationDataStore` store.
        this._vanillaReaderPublicationDataStore.updatePublicationOptionsData(this.id, {
            publicationId: this.id,
            styleOptions: styleOptions,
        }).catch(console.warn);

        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_PUBLICATION_STYLE_OPTIONS_CHANGED>(new CustomEvent<IVanillaReaderAppEvent_PUBLICATION_STYLE_OPTIONS_CHANGED>(VanillaReaderAppEvents.PUBLICATION_STYLE_OPTIONS_CHANGED, {detail: {styleOptions}}));

    }

    async fetchPublicationOptionsData(): Promise<IVanillaReaderPublicationOptionsData | undefined> {
        return this._vanillaReaderPublicationDataStore.fetchPublicationOptionsData(this.id);
    }

    async addHighlight(highlightData: IVanillaReaderHighlightData): Promise<IVanillaReaderHighlightData | void> {
        let locator = highlightData.locator;
        let contentLocation = await this._colibrioReaderPublication.fetchContentLocation(highlightData.locator);

        highlightData.publicationId = this.id;

        let navItemRefs = await contentLocation?.fetchNavigationItemReferences({
            collectionTypes: [NavigationCollectionType.TOC],
            greedy: true,
        });

        if (navItemRefs) {
            // Get the last reference toc item text for the `bookSection` field.
            highlightData.bookSection = navItemRefs.getItemsInRange().length > 0 ?
                navItemRefs.getItemsInRange()[navItemRefs.getItemsInRange().length - 1].getNavigationItem().getTextContent() :
                undefined;
        }

        if (locator) {
            return this._highlightsDataStore.addHighlight(highlightData);
        } else {
            return;
        }
    }

    async addBookmark(locator: ILocator | string): Promise<IVanillaReaderBookmarkData | undefined> {
        let location: IContentLocation | undefined | null;
        let bookmarkData: IVanillaReaderBookmarkData | undefined = undefined;

        if (typeof locator === 'string') {
            locator = (await this._colibrioReaderPublication.fetchContentLocation(locator)).getLocator();
        }

        if (this.isAudiobook && this._progressionTimeline) {
            let timelinePosition = await this._progressionTimeline?.fetchTimelinePosition(locator);

            if (timelinePosition === undefined) {
                console.warn(`VanillaPublication.addBookmark(): Unable to get timeline position for locator ${locator.toString()}`);
                return;
            }

            location = await this._progressionTimeline.fetchContentLocation(timelinePosition);

            if (location) {
                let fetchResult = await location.fetchNavigationItemReferences({
                    collectionTypes: [NavigationCollectionType.TOC],
                    greedy: false,
                });
                let itemsInRange = fetchResult.getItemsInRange();
                let bookSection: string | undefined;
                if (itemsInRange && itemsInRange.length > 0) {
                    bookSection = itemsInRange[0].getNavigationItem().getTextContent();
                }

                bookmarkData = {
                    publicationId: this.id,
                    locator: location.getLocator().toString(),
                    bookSection,
                };
            }

        } else {
            location = await this._colibrioReaderPublication.fetchContentLocation(locator);
            let locationContent = await location?.fetchContentResolver();
            let textExtract = locationContent?.getTextContentAfter(250) || '';

            if (location) {
                let fetchResult = await location.fetchNavigationItemReferences({
                    collectionTypes: [NavigationCollectionType.TOC],
                    greedy: true,
                });

                let itemsInRange = fetchResult.getItemsInRange();
                let bookSection: string | undefined;
                if (itemsInRange && itemsInRange.length > 0) {
                    bookSection = itemsInRange[0].getNavigationItem().getTextContent();
                }

                bookmarkData = {
                    publicationId: this.id,
                    locator: location.getLocator().toString(),
                    bookSection,
                    text: textExtract,
                };
            }
        }

        if (bookmarkData) {
            this._bookmarksDataStore.addBookmark(bookmarkData).catch(console.warn);
        }

        return bookmarkData;
    }

    async serialize(): Promise<IVanillaReaderPublicationData> {
        return {
            id: this.id,
            fileName: this.fileName,
            fileSourceUri: this.fileSourceUri,
            isFixedLayout: this.isFixedLayout,
            url: this.url,
            title: this.title,
            isRtl: this.isRtl,
            hasMoSyncMedia: this.hasMoSyncMedia,
            isAudiobook: this.isAudiobook,
            format: this.format,
            metadata: this._colibrioReaderPublication.getSourcePublication().getMetadata().toJSON(),
            language: this.language
        };
    }

    async buildNavigationTree(
        timeline?: IVanillaReaderReadingProgressionTimeline,
    ) {

        if(!this._navigationTree.hasBeenBuilt) {
            let navigation = await this._colibrioReaderPublication.fetchPublicationNavigation().then(async (nav: IReaderPublicationNavigation) => {
                return nav;
            });

            await this._navigationTree.build(navigation, timeline);

            this._vanillaReaderPublicationDataStore.updatePublicationCacheData(this.id, {
                publicationId: this.id,
                navigationTreeData: this._navigationTree.getTreeData(),
            }).catch(console.warn);
        }

        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_NAVIGATION_TREE_READY>(new CustomEvent<IVanillaReaderAppEvent_NAVIGATION_TREE_READY>(VanillaReaderAppEvents.NAVIGATION_TREE_READY, {detail: {navigationTreeData: this.navigationTree?.getTreeData()}}));
    }

    /**
     *
     * PRIVATE METHODS
     *
     * */

    private async _persistCurrentPublicationDataState(): Promise<void> {
        let publicationMetadata: IVanillaReaderPublicationData = {
            id: this.id,
            fileName: this.fileName,
            fileSourceUri: this.fileSourceUri,
            isFixedLayout: this.isFixedLayout,
            url: this.url,
            title: this.title,
            isRtl: this.isRtl,
            hasMoSyncMedia: this.hasMoSyncMedia,
            isAudiobook: this.isAudiobook,
            format: this.format,
            metadata: this._colibrioReaderPublication.getSourcePublication().getMetadata().toJSON(),
            coverImageAsBase64: await this.fetchCoverImageAsBase64(),
            language: this.language
        };

        await this._vanillaReaderPublicationDataStore.setPublicationData(this.id, publicationMetadata).catch(console.warn);

    }

    private async _persistCurrentPublicationOptionsState() {
        await this._vanillaReaderPublicationDataStore.updatePublicationOptionsData(this.id, {
            publicationId: this.id,
            ttsOptions: this._ttsOptions,
        });
    }

    private async _persistCurrentPublicationCacheDataState(): Promise<void> {

        let publicationCacheData: IVanillaReaderPublicationCacheData = {
            publicationId: this.id,
            storageState: this.storageState,
            navigationTreeData: this.navigationTree?.getTreeData(),
            timelineData: this.progressionTimeline?.toSerializedData(),
        };

        if(this._landmarksCollection ||this._landmarksCollectionData) {
            publicationCacheData.landmarkData = this._landmarksCollectionData ? this._landmarksCollectionData : this._landmarksCollection?.getAll();
        }

        await this._vanillaReaderPublicationDataStore.setPublicationCacheData(this.id, publicationCacheData).catch(console.warn);
    }

    isEpubReaderPublication(colibrioReaderPublication: IReaderPublication): colibrioReaderPublication is IEpubReaderPublication {
        return colibrioReaderPublication.getSourcePublication().getMediaType() === MediaType.APPLICATION_EPUB_ZIP;
    }

    isPdfReaderPublication(colibrioReaderPublication: IReaderPublication): colibrioReaderPublication is IPdfReaderPublication {
        return colibrioReaderPublication.getSourcePublication().getMediaType() === MediaType.APPLICATION_PDF;
    }

    isWpAudiobookReaderPublication(colibrioReaderPublication: IReaderPublication): colibrioReaderPublication is IWpAudiobookReaderPublication {
        return colibrioReaderPublication.getSourcePublication().getMediaType() === MediaType.APPLICATION_LPF_ZIP;
    }

}
