/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.4.0
 */
 'use strict'; (function (s, f) { if (typeof define === 'function' && define.amd) { define([], function () { return f(s); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(s); } else { throw new Error('Unsupported runtime environment!'); } }( typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function (__self) {  var api = {}; (function () { var l="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},m;if("function"==typeof Object.setPrototypeOf)m=Object.setPrototypeOf;else{var n;a:{var p={a:!0},q={};try{q.__proto__=p;n=q.a;break a}catch(a){}n=!1}m=n?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var t=m;
function u(a,b){a.prototype=l(b.prototype);a.prototype.constructor=a;if(t)t(a,b);else for(var d in b)if("prototype"!=d)if(Object.defineProperties){var c=Object.getOwnPropertyDescriptor(b,d);c&&Object.defineProperty(a,d,c)}else a[d]=b[d];a.u=b.prototype};var v=new Uint16Array(1);v[0]=4660;new Uint8Array(v.buffer);function w(a){var b=new Uint8Array(a);if("undefined"!==typeof crypto&&crypto.getRandomValues)crypto.getRandomValues(b);else for(var d=0;d<a;d++)b[d]=256*Math.random()|0;return b};function x(a){var b=Error.call(this,"[colibrio.internal] "+(a||""));this.message=b.message;"stack"in b&&(this.stack=b.stack);this.errorMessage=a}u(x,Error);function y(a,b){this.l=!1;this.m=new Uint8Array(64);this.i=this.h=0;this.o=new Int32Array(a);this.g=new Int32Array(b);this.s(this.g)}
y.prototype.append=function(a){if(this.l)throw new x("Illegal Sha.append(). Already finalized.");var b=0;a instanceof ArrayBuffer?a=new Uint8Array(a):a instanceof Uint8Array||("string"===typeof a?(z||(z=new TextEncoder),a=z.encode(a)):a=new Uint8Array(a.buffer,a.byteOffset,a.byteLength));if(a.length>Number.MAX_SAFE_INTEGER)throw new x("Data passed to Sha.append() is too large");var d=a.length;if(0<this.h){b=Math.min(64-this.h,d);for(var c=0;c<b;c++)this.m[this.h++]=a[c];64===this.h&&(this.j(this.m,
0,this.o,this.g),this.h=0)}this.i+=d;if(!(b>=d)){c=d-(d-b)%64;if(b<c){for(;b<c;b+=64)this.j(a,b,this.o,this.g);b>c&&(b-=64)}if(b<d)for(;b<d;)this.m[this.h++]=a[b++]}};y.prototype.digestToHex=function(){B(this);for(var a=Array(2*this.g.byteLength),b=0,d=0;d<this.g.length;d++)for(var c=this.g[d],e=7;0<=e;e--){var f=c>>(e<<2)&15;a[b++]=(10>f?48:87)+f}return String.fromCharCode.apply(null,a)};y.prototype.digestToUint32Array=function(){B(this);return new Uint32Array(this.g)};
y.prototype.digestToUint8Array=function(){B(this);for(var a=new Uint8Array(this.g.byteLength),b=0,d=0;d<this.g.length;d++){var c=this.g[d];a[b++]=c>>>24;a[b++]=c>>>16;a[b++]=c>>>8;a[b++]=c}return a};y.prototype.reset=function(){this.s(this.g);this.l=!1;this.i=this.h=0};
function B(a){if(!a.l){a.l=!0;var b=a.h,d=a.m;d[b++]=128;if(56<b){for(;64>b;)d[b++]=0;a.j(d,0,a.o,a.g);b=0}for(;56>b;)d[b++]=0;if(a.i>Math.pow(2,29)-1){var c=Math.trunc(a.i/Math.pow(2,29))|0;d[b++]=c>>>24;d[b++]=c>>>16;d[b++]=c>>>8;d[b++]=c}else for(;60>b;)d[b++]=0;c=a.i<<3|0;d[b++]=c>>>24;d[b++]=c>>>16;d[b++]=c>>>8;d[b++]=c;a.j(d,0,a.o,a.g)}}var z=null;function C(){y.call(this,80,5)}u(C,y);
C.prototype.j=function(a,b,d,c){for(var e=0;16>e;e++)d[e]=a[b++]<<24|a[b++]<<16|a[b++]<<8|a[b++];for(a=16;80>a;a++)d[a]=D(d[a-3]^d[a-8]^d[a-14]^d[a-16],1);a=c[0];b=c[1];e=c[2];for(var f=c[3],h=c[4],g=0;20>g;g++){var k=D(a,5)+(b&e|~b&f)+h+d[g]+1518500249|0;h=f;f=e;e=D(b,30);b=a;a=k}for(g=20;40>g;g++)k=D(a,5)+(b^e^f)+h+d[g]+1859775393|0,h=f,f=e,e=D(b,30),b=a,a=k;for(g=40;60>g;g++)k=D(a,5)+(b&e|b&f|e&f)+h+d[g]+2400959708|0,h=f,f=e,e=D(b,30),b=a,a=k;for(g=60;80>g;g++)k=D(a,5)+(b^e^f)+h+d[g]+3395469782|
0,h=f,f=e,e=D(b,30),b=a,a=k;c[0]+=a;c[1]+=b;c[2]+=e;c[3]+=f;c[4]+=h};C.prototype.s=function(a){a[0]=1732584193;a[1]=4023233417;a[2]=2562383102;a[3]=271733878;a[4]=3285377520};function D(a,b){return a<<b|a>>>32-b};function F(){y.call(this,64,8)}u(F,y);
F.prototype.j=function(a,b,d,c){for(var e=0;16>e;e++)d[e]=a[b++]<<24|a[b++]<<16|a[b++]<<8|a[b++];for(a=16;64>a;a++)b=d[a-15],e=d[a-2],d[a]=(G(e,17)^G(e,19)^e>>>10)+d[a-7]+(G(b,7)^G(b,18)^b>>>3)+d[a-16];a=c[0];b=c[1];e=c[2];for(var f=c[3],h=c[4],g=c[5],k=c[6],A=c[7],r=0;64>r;r++){var E=A+(G(h,6)^G(h,11)^G(h,25))+(h&g^~h&k)+H[r]+d[r]|0,J=(G(a,2)^G(a,13)^G(a,22))+(a&b^a&e^b&e)|0;A=k;k=g;g=h;h=f+E|0;f=e;e=b;b=a;a=E+J|0}c[0]+=a;c[1]+=b;c[2]+=e;c[3]+=f;c[4]+=h;c[5]+=g;c[6]+=k;c[7]+=A};
F.prototype.s=function(a){a[0]=1779033703;a[1]=3144134277;a[2]=1013904242;a[3]=2773480762;a[4]=1359893119;a[5]=2600822924;a[6]=528734635;a[7]=1541459225};
var H=[1116352408,1899447441,3049323471,3921009573,961987163,1508970993,2453635748,2870763221,3624381080,310598401,607225278,1426881987,1925078388,2162078206,2614888103,3248222580,3835390401,4022224774,264347078,604807628,770255983,1249150122,1555081692,1996064986,2554220882,2821834349,2952996808,3210313671,3336571891,3584528711,113926993,338241895,666307205,773529912,1294757372,1396182291,1695183700,1986661051,2177026350,2456956037,2730485921,2820302411,3259730800,3345764771,3516065817,3600352804,
4094571909,275423344,430227734,506948616,659060556,883997877,958139571,1322822218,1537002063,1747873779,1955562222,2024104815,2227730452,2361852424,2428436474,2756734187,3204031479,3329325298];function G(a,b){return a>>>b|a<<32-b};api={Random:{getRandomBytes:w,getRandomHexString:function(a){a=w(a);for(var b=[],d=0;d<a.length;d++)16>a[d]&&b.push("0"),b.push(a[d].toString(16));return b.join("")},getRandomString:function(a,b){for(var d=w(a),c="",e=0;e<a;e++)c+=String.fromCharCode(d[e]);a=btoa(c);b&&(a=a.replace(/[.+\/]/g,function(f){return"."===f?"a":"+"===f?"A":"0"}));return a}},Sha1:C,Sha256:F};var I=new ArrayBuffer(8);new DataView(I);new Uint8Array(I); }).call(__self); return api; } ) );
