/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IVanillaUiDialogResult} from "../VanillaReader/VanillaReaderEventBus";
import {IVanillaReaderUI} from "./IVanillaReaderUI";
import {IVanillaReaderUiComponentController} from "./IVanillaReaderUiComponentController";
import {VanillaReaderUI} from "./VanillaReaderUI";

export interface VanillaReaderUiDialogFootnoteController extends IVanillaReaderUiComponentController, IVanillaReaderUI {}

/**
* # VanillaReaderUiDialogFootnoteController
*
* ## RESPONSIBILITIES
* This controller mixing class handles all event callbacks from the `VanillaReaderUiDialogFootnote` class.
*
* Just as all other controller mixin classes, this class is used to add functionality to the `VanillaReaderUi` class
* in a "modular" way. Read more about this in the `UI Components and Controller Mixins` in the README.md file.
*
* ## RELATED TYPES
* - VanillaReaderUi
* - VanillaReaderUiDialogFootnote
*
* */
export class VanillaReaderUiDialogFootnoteController {

    static __initialize__(vanillaReaderUiContext: VanillaReaderUI){
        vanillaReaderUiContext.dialogFootnote.onGotoFootnoteButtonClicked(vanillaReaderUiContext._onDialogFootnoteButtonGotoFootnoteClicked.bind(vanillaReaderUiContext));
    }

    _onDialogFootnoteButtonGotoFootnoteClicked(locator: string) {
        if (locator) {
            this.navigateTo(locator, true);
        }

        this.dialogFootnote.close(true, false);
    };

    _onDialogFootnoteClosed(result: IVanillaUiDialogResult<string>) {
        if (result.data) {
            this.navigateTo(result.data, true);
        }
    };

}