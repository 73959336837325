/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {VanillaHighlightDataCallback, VanillaOpenUrlCallback} from '../VanillaReader/VanillaReaderModel';
import {IVanillaReaderHighlightData} from '../VanillaReader/VanillaReaderModel';
import {VanillaReaderAppUiDefaults} from './VanillaReaderAppUiDefaults';
import {VanillaReaderUiDialog} from './VanillaReaderUiDialog';

/**
 * # VanillaReaderUiDialogHighlight
 *
 * ## RESPONSIBILITIES
 *
 * This dialog component displays the state for a IVanillaReaderHighlight object, or acts as the input form for a new
 * IVanillaReaderHighlight instance.
 *
 * This component is not stateful, once it is closed its form is reset.
 *
 * ## RELATED TYPES
 * - VanillaReaderUiDialogHighlightController
 */

export class VanillaReaderUiDialogHighlight extends VanillaReaderUiDialog<void> {
    private _form: HTMLFormElement;
    private _buttonDelete: HTMLElement;
    private _buttonSubmit: HTMLElement;
    private _linkGoto: HTMLElement;

    private _gotoHighlightLocationCallback: VanillaOpenUrlCallback | undefined;
    private _addHighlightCallback: VanillaHighlightDataCallback | undefined;
    private _deleteHighlightCallback: VanillaHighlightDataCallback | undefined;
    private _updateHighlightCallback: VanillaHighlightDataCallback | undefined;

    private _bodyStyle = `
    <style>
    
    label {
        font-weight: bold;
    }
    
    input[type=radio][name="highlight-color"] {
        -webkit-appearance: none;
        margin: 0;
        width: 40px;
        height: 40px;
        display: inline-block;
        border: 0;
        border-radius: 50%;
      }
    
      input[type=radio][name="highlight-color"]:checked {
        border: 2px solid var(--vanilla-reader__color__ui__outline-dark);    
      }
    
      input[type=radio][title="red"] {
        background-color: ${VanillaReaderAppUiDefaults.highlightColorValues.red};
      }
    
      input[type=radio][title="green"] {
        background-color: ${VanillaReaderAppUiDefaults.highlightColorValues.green};
      }

      input[type=radio][title="blue"] {
        background-color: ${VanillaReaderAppUiDefaults.highlightColorValues.blue};
      }
    
      input[type=radio][title="yellow"] {
        background-color: ${VanillaReaderAppUiDefaults.highlightColorValues.yellow};
      }
      
      #vanilla-reader__dialog-highlight__form__buttonSave {
        font-family: sans-serif;            
        display: inline-block;
        background-color:#333;
        color:#ffffff;
      }

      #vanilla-reader__dialog-highlight__form__buttonDelete {
        font-family: sans-serif;
        display: inline-block;
        background-color:#333;
        color:#ffffff;
      }
      
      #vanilla-reader__dialog-highlight__selection-text {
        max-height: 112px;
        overflow-y: scroll;
        font-size: 0.9em;
      }
      
      #vanilla-reader__dialog-highlight__goto-location-link {
        display: block;
        text-decoration: underline;
        margin-top: 1em;
        color: var(--vanilla-reader__color__ui__foreground-dark);
      }

      #vanilla-reader__dialog-highlight__form__comment{
        background-color:rgba(210,175,80,0.5);
        border: 1px solid #a77b02;
      }

      .vanilla-reader__dialog-settings__form__field{
        background-color:rgba(210,175,80,0.5);
        border: 1px solid #a77b02;
      }

      .vanilla-reader__dialog-settings__form__field
      {
        background-color:rgba(210,175,80,0.5);
        border: 1px solid #a77b02;
      }

     .vanilla-reader__dialog-settings__form__field
      {
       background-color:rgba(210,175,80,0.5);
        border: 1px solid #a77b02;
      }

      #vanilla-reader__dialog-search-form__input 
      {
        background-color:rgba(210,175,80,0.5);
        border: 1px solid #a77b02;
      }
    
    </style>`;
    private _bodyTemplate = `
        <form id="vanilla-reader__dialog-highlight__form">
            <label for="vanilla-reader__dialog-highlight__form__comment">Highlighted text</label>
            <div id="vanilla-reader__dialog-highlight__selection-text"></div>      
            <a data-locator="" id="vanilla-reader__dialog-highlight__goto-location-link" href="#">Goto highlighted location</a>      
            <label for="vanilla-reader__dialog-highlight__form__comment">Note</label>
            <textarea id="vanilla-reader__dialog-highlight__form__comment"></textarea>
            <label>Highlight color</label>
            <input type="radio" name="highlight-color" id="vanilla-reader__dialog-highlight__form__color-red" value="${VanillaReaderAppUiDefaults.highlightColorValues.red}" title="red"></input>
            <input type="radio" name="highlight-color" id="vanilla-reader__dialog-highlight__form__color-green" value="${VanillaReaderAppUiDefaults.highlightColorValues.green}" title="green"></input>
            <input type="radio" name="highlight-color" id="vanilla-reader__dialog-highlight__form__color-blue" value="${VanillaReaderAppUiDefaults.highlightColorValues.blue}" title="blue"></input>
            <input type="radio" name="highlight-color" id="vanilla-reader__dialog-highlight__form__color-yellow" value="${VanillaReaderAppUiDefaults.highlightColorValues.yellow}" title="yellow" checked></input>
            <input type="hidden" id="vanilla-reader__dialog-highlight__form__selection-text" value="">
            <input type="hidden" id="vanilla-reader__dialog-highlight__form__locator" value="">
            <input type="hidden" id="vanilla-reader__dialog-highlight__form__id" value="">
            <input type="hidden" id="vanilla-reader__dialog-highlight__form__publication-id" value="">
            <hr>            
            <input type="submit" id="vanilla-reader__dialog-highlight__form__buttonSave" title="Save highlight" value="Save">
            <input type="button" id="vanilla-reader__dialog-highlight__form__buttonDelete" title="Delele highlight" value="Delete">
            <input type="hidden" id="vanilla-reader__dialog-highlight__form__new-instance" value="">
        </form>`;

    constructor(hostElement: HTMLElement, insertPosition: InsertPosition = 'afterbegin') {
        super(hostElement, 'vanilla-reader__dialog-highlight', 'Highlight');

        this._dialogElement.insertAdjacentHTML(insertPosition, this._bodyStyle);
        this._dialogBodyElement.innerHTML = this._bodyTemplate;

        this._form = this._hostDocument.getElementById('vanilla-reader__dialog-highlight__form')! as HTMLFormElement;
        this._buttonDelete = this._hostDocument.getElementById('vanilla-reader__dialog-highlight__form__buttonDelete')!;
        this._buttonSubmit = this._hostDocument.getElementById('vanilla-reader__dialog-highlight__form__buttonSave')!;
        this._linkGoto = this._hostDocument.getElementById('vanilla-reader__dialog-highlight__goto-location-link')!;
        this._form.addEventListener('submit', this._event_submit);
        this._buttonDelete.addEventListener('click', this._event__delete.bind(this));
        this._buttonSubmit.addEventListener('click', this._event_submit.bind(this));

        this._linkGoto.addEventListener('click', (ev) => {
            ev.preventDefault();
            if (this._gotoHighlightLocationCallback) {
                let highlightLocator = (ev.target as HTMLElement).getAttribute('data-locator');
                if (highlightLocator) {
                    this._gotoHighlightLocationCallback(highlightLocator);
                } else {
                    console.warn('Unable to get a valid locator from link element.');
                }
            }
        });
    }

    onGotoHighlightLocation(callback: VanillaOpenUrlCallback) {
        this._gotoHighlightLocationCallback = callback;
    }

    onHighlightAdd(callback: VanillaHighlightDataCallback) {
        this._addHighlightCallback = callback;
    }

    onHighlightDelete(callback: VanillaHighlightDataCallback) {
        this._deleteHighlightCallback = callback;
    }

    onHighlightUpdate(callback: VanillaHighlightDataCallback) {
        this._updateHighlightCallback = callback;
    }

    setState(highlight: IVanillaReaderHighlightData, isNewInstance: boolean = false) {

        if (isNewInstance) {
            // Don't allow the user to navigate to location before the highlight as saved.
            this._linkGoto.style.display = 'none';
        } else {
            this._linkGoto.setAttribute('data-locator', highlight.locator);
            this._linkGoto.style.display = 'block';
        }

        let highlightColor = highlight.color ?
            highlight.color :
            VanillaReaderAppUiDefaults.highlightColorValues.yellow;

        let formElementId = this._form.querySelector('#vanilla-reader__dialog-highlight__form__id') as HTMLInputElement;
        let formElementPublicationId = this._form.querySelector('#vanilla-reader__dialog-highlight__form__publication-id') as HTMLInputElement;
        let formElementColor = this._form.querySelector('input[name="highlight-color"][value="' + highlightColor + '"]') as HTMLInputElement;
        let formElementComment = this._form.querySelector('#vanilla-reader__dialog-highlight__form__comment') as HTMLTextAreaElement;
        let formElementLocator = this._form.querySelector('#vanilla-reader__dialog-highlight__form__locator') as HTMLInputElement;
        let formElementSelectionText = this._form.querySelector('#vanilla-reader__dialog-highlight__form__selection-text') as HTMLInputElement;
        let formElementIsNewInstance = this._form.querySelector('#vanilla-reader__dialog-highlight__form__new-instance') as HTMLInputElement;
        let elementSelectionText = this._dialogBodyElement.querySelector('#vanilla-reader__dialog-highlight__selection-text') as HTMLElement;

        elementSelectionText.textContent = highlight.selectionText || '';

        formElementId.value = highlight._id || '';
        formElementPublicationId.value = highlight.publicationId || '';
        formElementIsNewInstance.value = isNewInstance ? 'true' : 'false';
        formElementLocator.value = highlight.locator;
        formElementSelectionText.value = highlight.selectionText || '';

        let formElementColorsAll = this._form.querySelectorAll<HTMLInputElement>('input[name="highlight-color"]');
        formElementColorsAll.forEach((element: HTMLInputElement) => {
            element.style.display = 'inline-block';
        });

        if (formElementIsNewInstance.value === 'false') {
            formElementComment.value = highlight.comment || '';
            formElementColor.checked = true;
        } else {
            let formElementColorChecked = this._form.querySelector('input[name="highlight-color"]:checked') as HTMLInputElement;
            if (formElementColorChecked) {
                formElementColorChecked.checked = true;
            }
            formElementComment.value = '';
        }
    }

    serialize(): IVanillaReaderHighlightData {
        let formElementId = this._form.querySelector('#vanilla-reader__dialog-highlight__form__id') as HTMLInputElement;
        let formElementPublicationId = this._form.querySelector('#vanilla-reader__dialog-highlight__form__publication-id') as HTMLInputElement;
        let formElementComment = this._form.querySelector('#vanilla-reader__dialog-highlight__form__comment') as HTMLTextAreaElement;
        let formElementColor = this._form.querySelector('input[name="highlight-color"]:checked') as HTMLInputElement;
        let formElementSelectionText = this._form.querySelector('#vanilla-reader__dialog-highlight__form__selection-text') as HTMLInputElement;
        let formElementLocator = this._form.querySelector('#vanilla-reader__dialog-highlight__form__locator') as HTMLInputElement;
        let color = formElementColor ? formElementColor.value : '';

        return {
            _id: formElementId.value || Date.now().toString(),
            publicationId: formElementPublicationId.value,
            comment: formElementComment.value,
            color: color,
            selectionText: formElementSelectionText.value,
            locator: formElementLocator.value,
        };
    }

    /*
    *
    * EVENT HANDLERS
    *
    * */

    private _event_submit = (event: Event) => {

       
        event.preventDefault();

        let formElementIsNewInstance = this._form.querySelector('#vanilla-reader__dialog-highlight__form__new-instance') as HTMLInputElement;

        let highlight = this.serialize();


        if (formElementIsNewInstance.value === 'false') {
            if (this._updateHighlightCallback) {
                this._updateHighlightCallback(highlight);
            }
        } else {
            if (this._addHighlightCallback) {
                this._addHighlightCallback(highlight);
            }
        }

        this.close();
        return false;
    };

    private _event__delete = (_event: MouseEvent) => {
        let highlight = this.serialize();
        if (this._deleteHighlightCallback) {
            this._deleteHighlightCallback(highlight);
        }
        this.close();
    };

}
