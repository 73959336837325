/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {VanillaReaderUiDialog} from './VanillaReaderUiDialog';
import {ITreeViewItemSourceData, TreeView} from "../utils/TreeView";
import {
    VanillaUiActionEventCallback, VanillaUiDialogResultCallback
} from "../VanillaReaderUI/VanillaReaderUiModel";
import {IVanillaReaderUiAction, VanillaReaderUiActionNames} from "./VanillaReaderUiModel";

/**
 * # VanillaReaderUiDialogActions
 *
 * ## RESPONSIBILITIES
 *
 * ## RELATED TYPES
 * - VanillaReaderUiDialogActionsController
 *
 */

export class VanillaReaderUiDialogActions extends VanillaReaderUiDialog<void> {
    private _actionsListElement: HTMLElement;
    private _actionItemClickedCallback: VanillaUiActionEventCallback | undefined = undefined;
    private _treeViewActions: TreeView | undefined = undefined;

    private _bodyStyle = `
        <style>
            
            #vanilla-reader__dialog-ui-actions__list ul[role=tree] {
                padding-left: 0;
                list-style: none;
            }
                        
            #vanilla-reader__dialog-ui-actions__list ul[role=tree] button {
                font-size: x-large;
                margin-left: 0;
            }

            #vanilla-reader__dialog-ui-actions__list ul[role=group] {
                padding-left: 1em;
                list-style: none;
            }
            
            #vanilla-reader__dialog-ui-actions__list ul[role=group] li:not([data-treeview-role=group]) {
               margin-left: 1em;
            }
            
            #vanilla-reader__dialog-ui-actions__list li {
                align-items: center;
            }

            #vanilla-reader__dialog-ui-actions__list li a {
                max-width: 70%;
            }

            #vanilla-reader__dialog-ui-actions__list li[aria-selected=true] {
                text-decoration: underline;
            }
            
            #vanilla-reader__dialog-ui-actions__list li[aria-expanded=true] ul {
                border-left: 1px dotted var(--vanilla-reader__color__ui__outline-dark);
            }
              
        </style>`;

    private _bodyTemplate = `<section aria-label="App actions" id="vanilla-reader__dialog-ui-actions__list"></section>`;

    constructor(
        _hostElement: HTMLElement,
        _insertPosition: InsertPosition = 'afterbegin',
    ) {
        super(_hostElement, 'vanilla-reader__dialog-ui-actions', 'App actions', _insertPosition);

        this._dialogBodyElement.innerHTML = this._bodyTemplate;
        this._dialogElement.insertAdjacentHTML('afterbegin', this._bodyStyle);
        this._actionsListElement = this._dialogElement.querySelector<HTMLElement>('#vanilla-reader__dialog-ui-actions__list')!;

        this.populate();
    }

    show(silent: boolean = false, returnFocusOnClose: boolean = true, onDialogCloseCallbackOverride?: VanillaUiDialogResultCallback<void>, collapseAll: boolean = true): void {
        super.show(silent, returnFocusOnClose, onDialogCloseCallbackOverride);
        if(collapseAll) {
            this._treeViewActions?.collapseAll();
        }
    }

    close(silent: boolean = false, returnDialogResult: boolean = true) {
        silent = true;
        super.close(silent, returnDialogResult);
    }

    onActionItemClicked(callback: VanillaUiActionEventCallback) {
        this._actionItemClickedCallback = callback;
    }

    populate() {
        let actionItems = VanillaReaderUiActions;

        this._treeViewActions = new TreeView<IVanillaReaderUiAction | undefined>(this._actionsListElement, actionItems);
        this._treeViewActions.cssClassName = 'vanilla-reader__dialog-ui-actions-list';

        this._treeViewActions.addItemClickEventHandler((actionItem: ITreeViewItemSourceData<IVanillaReaderUiAction>) => {
            if (this._actionItemClickedCallback && actionItem.data) {
                this._actionItemClickedCallback(actionItem.data.name as VanillaReaderUiActionNames);
            } else {
                let treeViewItem = this._treeViewActions?.treeViewItemDataToTreeViewItemMap.get(actionItem);
                if(treeViewItem) {
                    treeViewItem.setIsExpanded(!treeViewItem.getIsExpanded());
                }
            }
        });

        this._treeViewActions.render();
    }

    empty() {
        if (this._treeViewActions) {
            this._treeViewActions.destroy();
        }
    }

}

export const VanillaReaderUiActions: ITreeViewItemSourceData<IVanillaReaderUiAction | undefined>[] = [
    {
        id: Date.now().toString(),
        textContent: 'Where am I?',
        data: {
            name: VanillaReaderUiActionNames.WHERE_AM_I
        },
        children: []
    },
    {
        id: Date.now().toString(),
        textContent: 'Open Table of Contents',
        data: {
            name: VanillaReaderUiActionNames.TOC_OPEN_DIALOG
        },
        children: []
    },
    // Navigation actions
    {
        id: Date.now().toString(),
        data: undefined,
        textContent: "Navigation actions",
        children: [
            {
                id: Date.now().toString(),
                textContent: 'Next book section',
                data: {
                    name: VanillaReaderUiActionNames.NAVIGATE_NEXT
                },
                children: [],
            },
            {
                id: Date.now().toString(),
                textContent: 'Previous book section',
                data: {
                    name: VanillaReaderUiActionNames.NAVIGATE_PREVIOUS
                },
                children: [],
            },
            {
                id: Date.now().toString(),
                textContent: 'Next heading',
                data: {
                    name: VanillaReaderUiActionNames.NAVIGATE_NEXT_HEADING
                },
                children: [],
            },
            {
                id: Date.now().toString(),
                textContent: 'Previous heading',
                data: {
                    name: VanillaReaderUiActionNames.NAVIGATE_PREVIOUS_HEADING
                },
                children: [],
            },
            {
                id: Date.now().toString(),
                textContent: 'Go to page number',
                data: {
                    name: VanillaReaderUiActionNames.GOTO_PAGE_DIALOG_OPEN
                },
                children: [],
            },

        ],
    },
    // Highlighting actions
    {
        id: Date.now().toString(),
        data: undefined,
        textContent: "Highlight actions",
        children: [
            {
                id: Date.now().toString(),
                textContent: 'Highlight reading position',
                data: {
                    name: VanillaReaderUiActionNames.HIGHLIGHT_READING_POSITION
                },
                children: [],
            },
            {
                id: Date.now().toString(),
                textContent: 'Highlight clipboard contents',
                data: {
                    name: VanillaReaderUiActionNames.HIGHLIGHT_CLIPBOARD
                },
                children: [],
            },
            {
                id: Date.now().toString(),
                textContent: 'Show all highlights',
                data: {
                    name: VanillaReaderUiActionNames.HIGHLIGHT_OPEN_DIALOG_ALL
                },
                children: [],
            },
            {
                id: Date.now().toString(),
                textContent: 'Show active highlights',
                data: {
                    name: VanillaReaderUiActionNames.HIGHLIGHT_OPEN_DIALOG_ACTIVE
                },
                children: [],
            }
        ],
    },
    // Bookmarking actions
    {
        id: Date.now().toString(),
        data: undefined,
        textContent: "Bookmark actions",
        children: [
            {
                id: Date.now().toString(),
                textContent: 'Bookmark reading position',
                data: {
                    name: VanillaReaderUiActionNames.BOOKMARK_READING_POSITION
                },
                children: [],
            },
            {
                id: Date.now().toString(),
                textContent: 'Bookmark clipboard contents',
                data: {
                    name: VanillaReaderUiActionNames.BOOKMARK_CLIPBOARD
                },
                children: [],
            },
            {
                id: Date.now().toString(),
                textContent: 'Show all bookmarks',
                data: {
                    name: VanillaReaderUiActionNames.BOOKMARK_OPEN_DIALOG_ALL
                },
                children: [],
            },
            {
                id: Date.now().toString(),
                textContent: 'Show active bookmarks',
                data: {
                    name: VanillaReaderUiActionNames.BOOKMARK_OPEN_DIALOG_ACTIVE
                },
                children: [],
            }
        ],
    },
    // Search actions
    {
        textContent: 'Book search',
        children: [
            {
                textContent: 'Open search dialog',
                children: [],
                data: {
                    name:VanillaReaderUiActionNames.SEARCH_DIALOG_OPEN
                },
                id: Date.now().toString()
            },
            {
                textContent: 'Clear search',
                children: [],
                data: {
                    name:VanillaReaderUiActionNames.SEARCH_CLEAR
                },
                id: Date.now().toString()
            }
        ],
        data: undefined,
        id: Date.now().toString()
    },
    {
        id: Date.now().toString(),
        textContent: 'Exit to bookshelf',
        data: {
            name: VanillaReaderUiActionNames.BOOKSHELF_DIALOG_OPEN
        },
        children: [],
    },
];
