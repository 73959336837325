/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IVanillaReaderUI} from "./IVanillaReaderUI";
import {
    IVanillaReaderAppEvent_MEDIA_OBJECT_VIEW_CLOSED,
    IVanillaUiDialogResult, VanillaReaderAppEvents,
    VanillaReaderEventBus
} from "../VanillaReader/VanillaReaderEventBus";

export interface VanillaReaderUiDialogImageViewerController extends IVanillaReaderUI {}

/**
 * # VanillaReaderUiDialogImageViewerController
 *
 * ## RESPONSIBILITIES
 * This controller mixing class handles all event callbacks from the `VanillaReaderUiDialogImageViewer` class.
 *
 * Just as all other controller mixin classes, this class is used to add functionality to the `VanillaReaderUi` class
 * in a "modular" way. Read more about this in the `UI Components and Controller Mixins` in the README.md file.
 *
 * ## RELATED TYPES
 * - VanillaReaderUi
 * - VanillaReaderUiDialogImageViewer
 */
export class VanillaReaderUiDialogImageViewerController {
    _onDialogImageViewerDialogClosed(dialogResult: IVanillaUiDialogResult<string>) {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_MEDIA_OBJECT_VIEW_CLOSED>(new CustomEvent(VanillaReaderAppEvents.MEDIA_OBJECT_VIEW_CLOSED, {detail: {mediaUrl: dialogResult.data}}));
    }

}