/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
export interface IVanillaToast {
    text: string,
    assertive: boolean
}

/**
 * # VanillaReaderUiToaster
 *
 * ## RESPONSIBILITIES
 *
 * ## RELATED TYPES
 *
 */
export class VanillaReaderUiToaster {
    private _toastContainer: HTMLElement;
    private _currentToasts: Set<IVanillaToast> = new Set<IVanillaToast>();
    private _template = `<section id="vanilla-reader-ui__toast-container"></section>`;

    private _style = `
    <style>
         #vanilla-reader-ui__toast-container {
              position: fixed;
              z-index: 500;       
              inset-block-end: 0;
              inset-inline: 0;
              padding-block-end: 5vh;
            
              display: grid;
              justify-items: center;
              justify-content: center;
              gap: 1vh;
            
              pointer-events: none;
        }
        
        .vanilla-reader-ui__toast {
          --_duration: 3s;
          --_bg-lightness: 90%;
          --_travel-distance: 0;
        
          font-family: system-ui, sans-serif;
          color: var(--vanilla-reader__color__ui__foreground-light);
          background: var(--vanilla-reader__color__ui__background-dark);
          opacity: 0.9;
          
          max-inline-size: min(25ch, 90vw);
          padding-block: .5ch;
          padding-inline: 1ch;
          border-radius: 3px;
          font-size: 1rem;          
        
          will-change: transform;
          animation: 
            fade-in .3s ease,
            slide-in .3s ease,
            fade-out .3s ease var(--_duration);
        
          @media (prefers-color-scheme: dark) {
            color: white;
            --_bg-lightness: 20%;
          }
        
          @media (prefers-reduced-motion: no-preference) {
            --_travel-distance: 5vh;
          }
        }
        
        @keyframes fade-in {
          from { opacity: 0 }
        }
        
        @keyframes fade-out {
          to { opacity: 0 }
        }
        
        @keyframes slide-in {
          from { transform: translateY(var(--_travel-distance, 10px)) }
        }
    </style>`;

    constructor(private _hostElement: HTMLElement, insertPosition: InsertPosition = 'beforeend') {
        this._hostElement.insertAdjacentHTML(insertPosition, this._template);
        this._toastContainer = this._hostElement.querySelector('#vanilla-reader-ui__toast-container')!;
        this._toastContainer.insertAdjacentHTML('afterbegin', this._style);
    }

    private _createToast(text: string, assertive: boolean = false) {
        const node = document.createElement('output');

        node.innerText = text;
        node.classList.add('vanilla-reader-ui__toast');
        node.setAttribute('role', 'status');
        node.setAttribute('aria-live', 'polite');

        if (assertive) {
            node.setAttribute('role', 'alert');
            node.setAttribute('aria-live', 'assertive');
        }

        return node;
    }

    private _addToast(toast: HTMLOutputElement) {

        const {matches: motionOK} = window.matchMedia(
            '(prefers-reduced-motion: no-preference)',
        );

        this._toastContainer.children.length && motionOK
            ? this._flipToast(toast)
            : this._toastContainer.appendChild(toast);

    }

    toast(text: string, assertive: boolean = false) {

        let toastData: IVanillaToast = {
            text,
            assertive,
        };

        let isDuplicate: boolean = false;

        this._currentToasts.forEach((toast) => {
            if (toast.text === text) {
                isDuplicate = true;
            }
        });

        if (isDuplicate) {
            return Promise.resolve();
        }

        let toast: HTMLOutputElement = this._createToast(text, assertive);
        this._addToast(toast);
        this._currentToasts.add(toastData);

        return new Promise<void>(async (resolve) => {
            // @ts-ignore
            await Promise.allSettled(
                toast.getAnimations().map(animation =>
                    animation.finished,
                ),
            );
            this._toastContainer.removeChild(toast);
            this._currentToasts.delete(toastData);
            resolve();
        });
    }

    // https://aerotwist.com/blog/flip-your-animations/
    private _flipToast(toast: HTMLOutputElement) {
        // FIRST
        const first = this._toastContainer.offsetHeight;

        // add new child to change container size
        this._toastContainer.appendChild(toast);

        // LAST
        const last = this._toastContainer.offsetHeight;

        // INVERT
        const invert = last - first;

        // PLAY
        const animation = this._toastContainer.animate([
            {transform: `translateY(${invert}px)`},
            {transform: 'translateY(0)'},
        ], {
            duration: 150,
            easing: 'ease-out',
        });

        animation.startTime = document.timeline.currentTime;
    }
}