/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.4.0
 */
 'use strict'; (function (s, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-selector-base'], function (a0) { return f(s,a0); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(s,require('./colibrio-core-selector-base')); } else { throw new Error('Unsupported runtime environment!'); } }( typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function (__self,__dep__colibrio_core_selector_base) {  var api = {}; (function () { var e,f="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},k;if("function"==typeof Object.setPrototypeOf)k=Object.setPrototypeOf;else{var l;a:{var m={a:!0},n={};try{n.__proto__=m;l=n.a;break a}catch(a){}l=!1}k=l?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var p=k;var q=__dep__colibrio_core_selector_base;var r=new Uint16Array(1);r[0]=4660;new Uint8Array(r.buffer);function t(a){var b=a.highlight,d=a.viewRect;return{highlight:b&&new DOMRect(b.x,b.y,b.width,b.height),namedDest:a.namedDest,pageNumber:a.pageNumber,viewRect:d&&new DOMRect(d.x,d.y,d.width,d.height)}};function u(a,b){var d=q.FragmentSelector.call(this)||this;d.g=a;d.i=b;d.h="";return d}var v=q.FragmentSelector;u.prototype=f(v.prototype);u.prototype.constructor=u;if(p)p(u,v);else for(var w in v)if("prototype"!=w)if(Object.defineProperties){var x=Object.getOwnPropertyDescriptor(v,w);x&&Object.defineProperty(u,w,x)}else u[w]=v[w];e=u.prototype;
e.collapseToEnd=function(){var a=t(this.g);a.highlight&&(a.highlight.x+=a.highlight.width,a.highlight.y+=a.highlight.height,a.highlight.width=0,a.highlight.height=0);return new u(a,this.i.map(function(b){return b.collapseToEnd()}))};e.collapseToStart=function(){var a=t(this.g);a.highlight&&(a.highlight.width=0,a.highlight.height=0);return new u(a,this.i.map(function(b){return b.collapseToStart()}))};
e.createRangeSelectorTo=function(a){if(a instanceof u&&this.g.highlight&&a.g.highlight&&(this.g.pageNumber===a.g.pageNumber||this.g.namedDest===a.g.namedDest)){var b=t(this.g),d=this.g.highlight;a=a.g.highlight;var h=Math.min(d.left,a.left),c=Math.min(d.top,a.top);b.highlight=new DOMRect(h,c,Math.max(d.right,a.right)-h,Math.max(d.bottom,a.bottom)-c);return new u(b,[])}return null};e.getFragmentSpecificationIri=function(){return q.FragmentSpecificationIri.PDF};
e.getHighlight=function(){return this.g.highlight||null};e.getNamedDest=function(){return this.g.namedDest||null};e.getPageNumber=function(){return this.g.pageNumber||null};e.getRefinementSelectors=function(){return this.i};
e.getValue=function(){if(!this.h){var a=this.g,b=[],d=a.namedDest;"string"===typeof d&&/\S/.test(d)?b.push("nameddest="+encodeURIComponent(a.namedDest)):(d=a.pageNumber,"number"===typeof d&&!isNaN(d)&&b.push("page="+a.pageNumber));a.highlight&&(d=a.highlight,b.push("highlight="+Math.round(d.left)+","+Math.round(d.right)+","+Math.round(d.top)+","+Math.round(d.bottom)));a.viewRect&&(a=a.viewRect,b.push("viewrect="+a.left+","+a.top+","+a.width+","+a.height));this.h=b.join("&")}return this.h};
e.getViewRect=function(){return this.g.viewRect||null};e.isRangeSelector=function(){var a=this.g.highlight;return!!a&&0<a.width&&0<a.height};function y(){}y.prototype.canParseFragment=function(a){return/(?:highlight|nameddest|page|viewrect)=/.test(a)};y.prototype.getFragmentSpecificationIri=function(){return q.FragmentSpecificationIri.PDF};
y.prototype.parseFragment=function(a,b,d){b={};a=a.split("&");for(var h=0;h<a.length;h++){var c=a[h].split("=");if(2<=c.length){var g=decodeURIComponent(c[0]).toLowerCase();c=decodeURIComponent(c[1]);switch(g){case "highlight":g=void 0;c=c.split(",");4<=c.length&&(c=c.map(parseFloat),c.some(isNaN)||(g=new DOMRect(c[0],c[2],c[1]-c[0],c[3]-c[2])));b.highlight=g;break;case "nameddest":b.namedDest=c;break;case "page":c=parseInt(c);isNaN(c)||(b.pageNumber=c);break;case "viewrect":g=void 0,c=c.split(","),
4<=c.length&&(c=c.map(parseFloat),c.some(isNaN)||(g=new DOMRect(c[0],c[1],c[2],c[3]))),b.viewRect=g}}}return b.pageNumber||b.namedDest?new u(b,d):null};api={PdfFragmentSelector:u,PdfFragmentSelectorParser:y};var z=new ArrayBuffer(8);new DataView(z);new Uint8Array(z); }).call(__self); return api; } ) );
