/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IVanillaReaderUI} from "./IVanillaReaderUI";
import {IVanillaReaderAppEvent_NAVIGATION_INTENT} from "../VanillaReader/VanillaReaderEventBus";
import {IVanillaReaderUiComponentController} from "./IVanillaReaderUiComponentController";
import {VanillaReaderUI} from "./VanillaReaderUI";

export interface VanillaReaderUiDialogContentsController extends IVanillaReaderUiComponentController, IVanillaReaderUI {}

/**
 * # VanillaReaderUiDialogContentsController
 *
 * ## RESPONSIBILITIES
 * This controller mixing class handles all event callbacks from the `VanillaReaderUiDialogContents` class.
 *
 * Just as all other controller mixin classes, this class is used to add functionality to the `VanillaReaderUi` class
 * in a "modular" way. Read more about this in the `UI Components and Controller Mixins` in the README.md file.
 *
 * ## RELATED TYPES
 * - VanillaReaderUi
 * - VanillaReaderUiDialogContents
 *
 * */
export class VanillaReaderUiDialogContentsController {

    static __initialize__(vanillaReaderUiContext: VanillaReaderUI){
        vanillaReaderUiContext.dialogContents.onNavItemClicked(vanillaReaderUiContext._onDialogContentsNavigationItemClicked.bind(vanillaReaderUiContext));
    }

    _onDialogContentsNavigationItemClicked(navigationData: IVanillaReaderAppEvent_NAVIGATION_INTENT) {
        if (navigationData.locator) {
            this.navigateTo(navigationData.locator.toString());
        }

        this.menuBottom.hide();
        this.dialogContents?.close();
    };
}