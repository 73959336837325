/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {
    IVanillaReaderBookmarkData,
    VanillaBookmarkDataCallback,
    VanillaReaderNavigationType
} from '../VanillaReader/VanillaReaderModel';
import { VanillaReaderUiDialog } from './VanillaReaderUiDialog';
import { VanillaUiDialogResultCallback, VanillaUiNavigationIntentEventCallback } from "./VanillaReaderUiModel";

/**
 * # VanillaReaderUiDialogBookmarks
 *
 * ## RESPONSIBILITIES
 *
 * This dialog shows a list of all bookmarks in the publication.
 *
 * This component is stateful. It's list state is refreshed by the VanillaReaderUI class whenever new data is available.
 *
 * ## RELATED TYPES
 * - VanillaReaderUiDialogBookmarksController
 */

export class VanillaReaderUiDialogBookmarks extends VanillaReaderUiDialog<void> {
    private _list: HTMLElement;
    private _listIsFiltered: boolean = false;
    private _selectedItems: IVanillaReaderBookmarkData[] | undefined;

    private _onBookmarkItemClickedCallback: VanillaUiNavigationIntentEventCallback | undefined;
    private _onBookmarkItemButtonClickedCallback: VanillaBookmarkDataCallback | undefined;

    private _bodyStyle = `
        <style>
            #vanilla-reader__dialog-bookmarks__list {
                padding-inline: 0;
            }

            #vanilla-reader__dialog-bookmarks__list li {
                min-height: 48px;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                align-content: space-between;
            }

            #vanilla-reader__dialog-bookmarks__list li a {
                vertical-align: super;
                margin-right: 1em;
                margin-top: 1em;
                flex-grow: 1;
            }

            #vanilla-reader__dialog-bookmarks__list li button {
                align-self: flex-start;
            }


        </style>`;

    private _bodyTemplate = `<ul id="vanilla-reader__dialog-bookmarks__list">
        <span class="vanilla-reader__dialog-bookmarks__list--empty">There are no bookmarks yet</span>
    </ul>`;

    private _dialogTitleWhenFiltered: string = 'Bookmark in view';
    private static _dialogTitle: string = 'Bookmarks';

    constructor(hostElement: HTMLElement, insertPosition: InsertPosition = 'beforeend') {
        super(hostElement, 'vanilla-reader__dialog-bookmarks', VanillaReaderUiDialogBookmarks._dialogTitle);

        this._dialogElement.insertAdjacentHTML(insertPosition, this._bodyStyle);
        this._dialogBodyElement.innerHTML = this._bodyTemplate;
        this._list = this._dialogElement.querySelector('#vanilla-reader__dialog-bookmarks__list')!;
    }

    public get listIsFiltered(): boolean {
        return this._listIsFiltered;
    }

    public get selectedItems(): IVanillaReaderBookmarkData[] | undefined {
        return this._selectedItems;
    }

    onBookmarkItemClicked(callback: VanillaUiNavigationIntentEventCallback) {
        this._onBookmarkItemClickedCallback = callback;
    }

    onBookmarkItemButtonClicked(callback: VanillaBookmarkDataCallback) {
        this._onBookmarkItemButtonClickedCallback = callback;
    }

    async populate(annotations: IVanillaReaderBookmarkData[]): Promise<void> {

        this.empty()
        this._list.innerHTML = ''

        try {

            // @ts-ignore
            let data = await window.reader.fetchAllBookmarks()
            annotations = data.data.bookmarks || []

            if (annotations.length > 0) {
                const bookmarksList = document.querySelector('#vanilla-reader__dialog-bookmarks__list')

                if (bookmarksList) {
                    const existingLocators = Array.from(bookmarksList.children).map(

                        // @ts-ignore
                        element => element.dataset.contentblocklocator

                    )

                    annotations.forEach(bookmark => {
                        if (!existingLocators.includes(bookmark.locator)) {
                            // @ts-ignore
                            let listItem = this.createItem(bookmark)
                            this._list.appendChild(listItem)
                        }
                    })
                }
            } else {
                this._list.innerHTML = '<span class="vanilla-reader__dialog-bookmarks__list--empty">There are no bookmarks yet</span>'
            }

            if (this.listIsFiltered) {
                this.filterSelected()
            }

        } catch (error) {
            console.error('Error loading bookmarks:', error)
            this._list.innerHTML = '<span class="vanilla-reader__dialog-bookmarks__list--error">Error loading bookmarks</span>';
        }

    }

    setSelected(selectedBookmarks: IVanillaReaderBookmarkData[] = []) {
        this._selectedItems = selectedBookmarks;
        this._list.querySelectorAll('li').forEach((item) => {
            item.removeAttribute('aria-selected');
            let bookmarkLocator = item.getAttribute('data-contentblocklocator');
            if (bookmarkLocator) {
                let shouldSelect = selectedBookmarks.find(item => item.locator === bookmarkLocator);
                if (shouldSelect) {
                    item.setAttribute('aria-selected', 'true');
                }
            }
        });
    }

    createItem(bookmark: IVanillaReaderBookmarkData) {
        let listItem = this._hostDocument.createElement('li');
        listItem.setAttribute('tabindex', '-1');
        listItem.setAttribute('data-booksection', bookmark.bookSection || '');
        listItem.setAttribute('data-contentblocklocator', bookmark.locator);
        listItem.setAttribute('data-datecreated', `${bookmark.dateCreated || ''}`);

        let listItemActionButton = this._hostDocument.createElement('button');
        listItemActionButton.innerHTML = '<span aria-hidden="true">delete</span>';
        listItemActionButton.title = 'Delete bookmark';
        listItemActionButton.setAttribute('tabindex', '0');

        listItemActionButton.addEventListener('click', (_ev) => {
            if (this._onBookmarkItemButtonClickedCallback) {
                this._onBookmarkItemButtonClickedCallback(bookmark);
            }
        });

        let listItemAnchor = this._hostDocument.createElement('a');
        listItemAnchor.setAttribute('data-contentblocklocator', bookmark.locator);
        listItemAnchor.addEventListener('click', (ev) => {
            this._event_listItemClicked(ev);
        });

        listItem.addEventListener('keyup', (ev) => {
            if (ev.key === 'Enter' || ev.key === 'Space') {
                this._event_listItemClicked(ev);
            }
        });

        listItem.setAttribute('data-contentblocklocator', bookmark.locator);

        listItemAnchor.innerText = bookmark.bookSection ? bookmark.bookSection : 'No title available';
        listItemAnchor.setAttribute('tabindex', '0');
        listItem.appendChild(listItemAnchor);
        listItem.appendChild(listItemActionButton);

        return listItem;
    }

    show(silent?: boolean, returnFocusOnClose: boolean = true, onDialogCloseCallbackOverride?: VanillaUiDialogResultCallback<void>, filterSelected: boolean = false) {
        super.show(silent, returnFocusOnClose, onDialogCloseCallbackOverride);
        if (this._listIsFiltered) {
            this.removeSelectedFilter();
        }

        if (filterSelected) {
            this.title = this._dialogTitleWhenFiltered;
            this.filterSelected();
        } else {
            this.title = VanillaReaderUiDialogBookmarks._dialogTitle;
        }

    }

    filterSelected() {
        this._listIsFiltered = true;

        this._list.querySelectorAll<HTMLLIElement>('li').forEach((item: HTMLLIElement) => {
            let itemLocator = item.getAttribute('data-contentblocklocator');
            let keepItem = this._selectedItems?.find((item: IVanillaReaderBookmarkData) => {
                return item.locator === itemLocator;
            });
            if (!keepItem) {
                item.style.display = 'none';
            }
        });

    }

    removeSelectedFilter() {
        this._listIsFiltered = false;
        this._list.querySelectorAll<HTMLLIElement>('li').forEach((item: HTMLLIElement) => {
            item.style.display = 'flex';
        });
    }

    empty() {
        if (this._list.childElementCount > 0) {
            let firstListItem = this._list.firstChild;
            while (firstListItem) {
                firstListItem.remove();
                firstListItem = this._list.firstChild;
            }
        }
    }

    /*
    *
    * EVENT HANDLERS
    *
    * */

    private _event_listItemClicked = (ev: Event) => {
        let locator = (ev.target as HTMLElement).getAttribute('data-contentblocklocator') || (ev.target as HTMLElement).parentElement!.getAttribute('data-contentblocklocator');
        if (locator && this._onBookmarkItemClickedCallback) {
            this._onBookmarkItemClickedCallback({
                navigationType: VanillaReaderNavigationType.GOTO,
                locator: locator,
            });
        }
    };

}
