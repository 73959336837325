/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.4.0
 */
 'use strict'; (function (s, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-base','./colibrio-core-io-base','./colibrio-core-locator','./colibrio-core-publication-base','./colibrio-core-selector-base'], function (a0,a1,a2,a3,a4) { return f(s,a0,a1,a2,a3,a4); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(s,require('./colibrio-core-base'),require('./colibrio-core-io-base'),require('./colibrio-core-locator'),require('./colibrio-core-publication-base'),require('./colibrio-core-selector-base')); } else { throw new Error('Unsupported runtime environment!'); } }( typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function (__self,__dep__colibrio_core_base,__dep__colibrio_core_io_base,__dep__colibrio_core_locator,__dep__colibrio_core_publication_base,__dep__colibrio_core_selector_base) {  var api = {}; (function () { var c="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b};function d(a){a=["object"==typeof globalThis&&globalThis,a,"object"==typeof window&&window,"object"==typeof self&&self,"object"==typeof global&&global];for(var b=0;b<a.length;++b){var e=a[b];if(e&&e.Math==Math)return e}throw Error("Cannot find global object");}var f=d(this),g;
if("function"==typeof Object.setPrototypeOf)g=Object.setPrototypeOf;else{var h;a:{var k={a:!0},l={};try{l.__proto__=k;h=l.a;break a}catch(a){}h=!1}g=h?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var m=g;var n=__dep__colibrio_core_base;var p=Array.isArray,q=Function.prototype.call.bind(Object.prototype.hasOwnProperty);var r={BEFORE_CONTENT_LOCATION:-1,INTERSECTS_CONTENT_LOCATION:0,AFTER_CONTENT_LOCATION:1};r[r.BEFORE_CONTENT_LOCATION]="BEFORE_CONTENT_LOCATION";r[r.INTERSECTS_CONTENT_LOCATION]="INTERSECTS_CONTENT_LOCATION";r[r.AFTER_CONTENT_LOCATION]="AFTER_CONTENT_LOCATION";function t(a){if(!a||1!==a.nodeType)return null;var b=null,e=a.parentNode;e&&1===e.nodeType&&(b=t(a.parentNode));return{attributes:n.NodeDataUtils.createXmlElementAttributeData(a),namespaceURI:a.namespaceURI,nodeName:a.localName,nodeType:1,parent:b,prefix:a.prefix}};function u(a,b){return n.ColibrioError.call(this,"colibrio.readingsystem.base",a,b)||this}var v=n.ColibrioError;u.prototype=c(v.prototype);u.prototype.constructor=u;if(m)m(u,v);else for(var w in v)if("prototype"!=w)if(Object.defineProperties){var x=Object.getOwnPropertyDescriptor(v,w);x&&Object.defineProperty(u,w,x)}else u[w]=v[w];function y(a){this.g=a}y.prototype.toJSON=function(){var a;return{locator:this.contentLocation.toJSON(),contentBlocks:null==(a=this.contentBlocks)?void 0:a.map(function(b){return b.toSerializableData()}),matchingText:this.matchingText,textBefore:this.textBefore,textAfter:this.textAfter,readerDocumentIndexInSpine:this.readerDocumentIndexInSpine}};
f.Object.defineProperties(y.prototype,{contentBlocks:{configurable:!0,enumerable:!0,get:function(){return this.g.contentBlocks}},contentLocation:{configurable:!0,enumerable:!0,get:function(){return this.g.contentLocation}},matchingText:{configurable:!0,enumerable:!0,get:function(){return this.g.matchingText}},readerDocumentIndexInSpine:{configurable:!0,enumerable:!0,get:function(){return this.g.readerDocumentIndexInSpine}},textAfter:{configurable:!0,enumerable:!0,get:function(){return this.g.textAfter}},
textBefore:{configurable:!0,enumerable:!0,get:function(){return this.g.textBefore}}});var z={LINE_BOX:1,ELEMENT:2};z[z.LINE_BOX]="LINE_BOX";z[z.ELEMENT]="ELEMENT";api={ContainerContentBlockType:{ARTICLE:"ARTICLE",ASIDE:"ASIDE",BLOCK_QUOTE:"BLOCK_QUOTE",DESCRIPTION_LIST:"DESCRIPTION_LIST",DESCRIPTION_TERM:"DESCRIPTION_TERM",DESCRIPTION_DEFINITION:"DESCRIPTION_DEFINITION",FIGURE:"FIGURE",GENERIC:"GENERIC",ORDERED_LIST:"ORDERED_LIST",LIST_ITEM:"LIST_ITEM",SECTION:"SECTION",TABLE:"TABLE",TABLE_BODY:"TABLE_BODY",TABLE_CELL:"TABLE_CELL",TABLE_FOOTER:"TABLE_FOOTER",TABLE_HEADER:"TABLE_HEADER",TABLE_ROW:"TABLE_ROW",UNORDERED_LIST:"UNORDERED_LIST"},ContentBlockClass:{CONTAINER:"CONTAINER",
MEDIA:"MEDIA",TEXT:"TEXT"},ContentBlockMarkType:{ANCHOR:"ANCHOR",EMPHASIS:"EMPHASIS",STRONG:"STRONG"},ContentBlockMicrodataAttribute:{ITEMSCOPE:"itemscope",ITEMPROP:"itemprop",ITEMID:"itemid",ITEMTYPE:"itemtype",ITEMREF:"itemref"},ContentBlockRdfAttribute:{VOCAB:"vocab",TYPEOF:"typeof",PROPERTY:"property"},ContentBlockTargetAnchorPoint:r,ContentBlockTargetFetchMode:{INTERSECTING_OR_NEAREST_BEFORE:"INTERSECTING_OR_NEAREST_BEFORE",INTERSECTING:"INTERSECTING",INTERSECTING_OR_NEAREST_AFTER:"INTERSECTING_OR_NEAREST_AFTER"},
ContentPositionTimelineUnit:{CHARACTERS:"CHARACTERS",DOCUMENTS:"DOCUMENTS",PAGES:"PAGES",WORDS:"WORDS"},CustomReaderViewContentLayout:{FLEX_CENTER:"FLEX_CENTER",BLOCK:"BLOCK"},EngineEventDataObjectType:{ACTIVE_GESTURE_TYPE_CHANGED_ENGINE_EVENT:"ACTIVE_GESTURE_TYPE_CHANGED_ENGINE_EVENT",CONTENT_POSITION_TIMELINE_CREATE_PROGRESS_ENGINE_EVENT:"CONTENT_POSITION_TIMELINE_CREATE_PROGRESS_ENGINE_EVENT",ENGINE_EVENT:"ENGINE_EVENT",KEYBOARD_ENGINE_EVENT:"KEYBOARD_ENGINE_EVENT",LICENSE_ENGINE_EVENT:"LICENSE_ENGINE_EVENT",
MOUSE_ENGINE_EVENT:"MOUSE_ENGINE_EVENT",NAVIGATION_ENDED_ENGINE_EVENT:"NAVIGATION_ENDED_ENGINE_EVENT",NAVIGATION_INTENT_ENGINE_EVENT:"NAVIGATION_INTENT_ENGINE_EVENT",NAVIGATION_STARTED_ENGINE_EVENT:"NAVIGATION_STARTED_ENGINE_EVENT",PAGE_PROGRESSION_TIMELINE_RECALCULATING_ENGINE_EVENT:"PAGE_PROGRESSION_TIMELINE_RECALCULATING_ENGINE_EVENT",PAGE_PROGRESSION_TIMELINE_ENGINE_EVENT:"PAGE_PROGRESSION_TIMELINE_ENGINE_EVENT",POINTER_ENGINE_EVENT:"POINTER_ENGINE_EVENT",PUBLICATION_MEDIA_ELEMENT_ENGINE_EVENT:"PUBLICATION_MEDIA_ELEMENT_ENGINE_EVENT",
READER_VIEW_ENGINE_EVENT:"READER_VIEW_ENGINE_EVENT",READER_VIEW_TRANSFORM_ENGINE_EVENT:"READER_VIEW_TRANSFORM_ENGINE_EVENT",RENDERER_SCROLL_ENGINE_EVENT:"RENDERER_SCROLL_ENGINE_EVENT",SELECTION_CHANGED_ENGINE_EVENT:"SELECTION_CHANGED_ENGINE_EVENT",SYNC_MEDIA_ERROR_ENGINE_EVENT:"SYNC_MEDIA_ERROR_ENGINE_EVENT",SYNC_MEDIA_ENGINE_EVENT:"SYNC_MEDIA_ENGINE_EVENT",SYNC_MEDIA_SEGMENT_ACTIVE_ENGINE_EVENT:"SYNC_MEDIA_SEGMENT_ACTIVE_ENGINE_EVENT",SYNC_MEDIA_SEGMENT_DURATION_CHANGED_EVENT:"SYNC_MEDIA_SEGMENT_DURATION_CHANGED_EVENT",
SYNC_MEDIA_TIMELINE_CREATE_PROGRESS_ENGINE_EVENT:"SYNC_MEDIA_TIMELINE_CREATE_PROGRESS_ENGINE_EVENT",SYNC_MEDIA_VIEW_SYNCHRONIZATION_STATE_CHANGED_ENGINE_EVENT:"SYNC_MEDIA_VIEW_SYNCHRONIZATION_STATE_CHANGED_ENGINE_EVENT",SYNC_MEDIA_WAITING_ENGINE_EVENT:"SYNC_MEDIA_WAITING_ENGINE_EVENT"},EngineEventTargetFrameworkComponent:{APP_WINDOW:"APP_WINDOW",READER_DOCUMENT:"READER_DOCUMENT",READER_VIEW:"READER_VIEW",READER_VIEW_ANNOTATION:"READER_VIEW_ANNOTATION"},EngineEventTargetNodeUtils:{createEventTargetXmlElementNodeData:t,
isEventTargetXmlElementNodeData:function(a){return!!a&&1===a.nodeType&&p(a.attributes)&&!q(a,"childNodes")}},EngineEventTargetObjectType:{ENGINE_EVENT_TARGET:"ENGINE_EVENT_TARGET",SYNC_MEDIA_SEGMENT_TARGET:"SYNC_MEDIA_SEGMENT_TARGET"},MediaContentBlockType:{AUDIO:"AUDIO",IMAGE:"IMAGE",VIDEO:"VIDEO"},NavigationAction:{NEXT_CONTENT_RANGE:"NEXT_CONTENT_RANGE",PREVIOUS_CONTENT_RANGE:"PREVIOUS_CONTENT_RANGE",NEXT_RENDERABLE_PAGE:"NEXT_RENDERABLE_PAGE",PREVIOUS_RENDERABLE_PAGE:"PREVIOUS_RENDERABLE_PAGE",
CURRENT:"CURRENT",GOTO:"GOTO"},NavigationCollectionType:{TOC:"TOC",PAGE_LIST:"PAGE_LIST",LANDMARKS:"LANDMARKS",CUSTOM:"CUSTOM",LIST_OF_AUDIO_CLIPS:"LIST_OF_AUDIO_CLIPS",LIST_OF_ILLUSTRATIONS:"LIST_OF_ILLUSTRATIONS",LIST_OF_TABLES:"LIST_OF_TABLES",LIST_OF_VIDEO_CLIPS:"LIST_OF_VIDEO_CLIPS"},PageFlowDirection:{TB:"TB",LR:"LR",RL:"RL"},PageProgressionTimelineType:{FIXED_PAGES:"FIXED_PAGES",ESTIMATED_PAGES:"ESTIMATED_PAGES",RENDERED_PAGES:"RENDERED_PAGES"},ReadingSystemError:u,ReadingSystemErrorType:{CONTENT_BLOCK_WALKER_ALREADY_AT_END:"CONTENT_BLOCK_WALKER_ALREADY_AT_END"},
ReaderDocumentEventState:{NOT_PROCESSED:"NOT_PROCESSED",PROCESSED:"PROCESSED",DEFAULT_PREVENTED:"DEFAULT_PREVENTED"},ReaderViewGestureType:{PAN_ZOOM:"PAN_ZOOM",SWIPE_NAVIGATION:"SWIPE_NAVIGATION"},ReaderViewPageProgressionDirection:{LTR:"LTR",RTL:"RTL"},RenderableState:{DESTROYED:"DESTROYED",CREATED:"CREATED",ATTACHED_TO_DOM:"ATTACHED_TO_DOM",VISIBLE:"VISIBLE"},RendererSpreadMode:{SINGLE_PAGE:"SINGLE_PAGE",TWO_PAGES:"TWO_PAGES",MIXED:"MIXED"},SearchResultItem:y,SyncMediaFormat:{CONTENT_BLOCK_TTS:"CONTENT_BLOCK_TTS",
EPUB_MEDIA_OVERLAY:"EPUB_MEDIA_OVERLAY",WP_AUDIOBOOK:"WP_AUDIOBOOK"},SyncMediaObjectRefType:{AUDIO:"AUDIO",EPUB_MEDIA_OVERLAY_TEXT:"MO_TEXT",TTS_CONTENT_BLOCK:"TTS_CONTENT_BLOCK"},SyncMediaPlayerErrorType:{MEDIA_NOT_SUPPORTED:"MEDIA_NOT_SUPPORTED",PLAYBACK_NOT_ALLOWED:"PLAYBACK_NOT_ALLOWED",MEDIA_LOAD_ERROR:"MEDIA_LOAD_ERROR",UNKNOWN_ERROR:"UNKNOWN_ERROR"},SyncMediaPositionRelativeToVisibleRange:{VISIBLE:"VISIBLE",NEXT:"NEXT",PREVIOUS:"PREVIOUS",AFTER_NEXT:"AFTER_NEXT",BEFORE_PREVIOUS:"BEFORE_PREVIOUS",
NO_MATCHING_DOCUMENT:"NO_MATCHING_DOCUMENT"},SyncMediaReaderViewSynchronizationWaitBehavior:{WAIT_OUTSIDE_ADJACENT:"WAIT_OUTSIDE_ADJACENT",WAIT_OUTSIDE_VISIBLE:"WAIT_OUTSIDE_VISIBLE",DONT_WAIT:"DONT_WAIT"},SyncMediaSegmentDurationType:{FIXED:"FIXED",UNKNOWN:"UNKNOWN"},SyncMediaSyncEventTrigger:{READER_VIEW_SCROLL:"READER_VIEW_SCROLL",READER_VIEW_NAVIGATION:"READER_VIEW_NAVIGATION",SYNC_MEDIA_SEEK:"SYNC_MEDIA_SEEK",SYNC_MEDIA_PLAYBACK:"SYNC_MEDIA_PLAYBACK"},SyncMediaReaderViewSynchronizationMethod:{READER_VIEW_GOTO:"READER_VIEW_GOTO",
READER_VIEW_NEXT:"READER_VIEW_NEXT",READER_VIEW_PREVIOUS:"READER_VIEW_PREVIOUS",SYNC_MEDIA_SEEK:"SYNC_MEDIA_SEEK"},TextAlignment:{CENTER:"center",JUSTIFY:"justify",LEFT:"left",RIGHT:"right"},TextContentBlockType:{HEADING1:"HEADING1",HEADING2:"HEADING2",HEADING3:"HEADING3",HEADING4:"HEADING4",HEADING5:"HEADING5",HEADING6:"HEADING6",PARAGRAPH:"PARAGRAPH",TEXT:"TEXT"},TextSearchQueryWhitespaceMode:{COLLAPSE:"COLLAPSE",IGNORE:"IGNORE"},TextSearchResultExpandMode:{NO_EXPAND:"NO_EXPAND",EXPAND_TO_WORD_BOUNDARY:"EXPAND_TO_WORD_BOUNDARY",
EXPAND_TO_CONTENT_BLOCK_BOUNDARY:"EXPAND_TO_CONTENT_BLOCK_BOUNDARY"},TransformEasing:{EASE:"cubic-bezier(0.25, 0.1, 0.25, 1.0)",EASE_IN:"cubic-bezier(0.42, 0.0, 1.0, 1.0)",EASE_IN_OUT:"cubic-bezier(0.42, 0.0, 0.58, 1.0)",EASE_OUT:"cubic-bezier(0.0, 0.0, 0.58, 1.0)",MATERIAL_STANDARD_CURVE:"cubic-bezier(0.4, 0.0, 0.2, 1)",MATERIAL_DECELERATION_CURVE:"cubic-bezier(0.0, 0.0, 0.2, 1)",MATERIAL_ACCELERATION_CURVE:"cubic-bezier(0.4, 0.0, 1, 1)"},VisibleContentRectType:z}; }).call(__self); return api; } ) );
