/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {
    IVanillaReaderPublicationSearchResultItem,
    VanillaReaderNavigationType, VanillaStringValueCallback, VanillaVoidCallback,
} from '../VanillaReader/VanillaReaderModel';
import {VanillaReaderUiDialog} from './VanillaReaderUiDialog';
import {VanillaUiDialogResultCallback, VanillaUiNavigationIntentEventCallback} from "./VanillaReaderUiModel";

/**
 * # VanillaReaderUiDialogSearch
 *
 * ## RESPONSIBILITIES
 *
 * ## RELATED TYPES
 * - VanillaReaderUiDialogSearchController
 *
 */

export class VanillaReaderUiDialogSearch extends VanillaReaderUiDialog<void> {
    private _form: HTMLFormElement;
    private _searchInput: HTMLInputElement;
    private _resultList: HTMLElement;
    private _resultContainer: HTMLElement;
    private _activeResultItem: HTMLElement | undefined = undefined;

    private _searchResultClickedCallback: VanillaUiNavigationIntentEventCallback | undefined = undefined;
    private _onExecuteSearchCallback: VanillaStringValueCallback | undefined = undefined;
    private _onClearSearchCallback: VanillaVoidCallback | undefined = undefined;

    private _bodyStyle = `
        <style>
            #vanilla-reader__dialog-search-form__input {
                width: 100%;
            }
            
            #vanilla-reader__dialog-search-results__container {
                display: none;
            }
            
            #vanilla-reader__dialog-search-results__list {                
                padding-left: 16px;
            }
                        
            #vanilla-reader__dialog-search-results__list li {                
                border: 1px solid var(--vanilla-reader__color__ui__outline-light);
                border-radius: 6px;
                padding: 1em;
            }
            
            .vanilla-reader__dialog-search-form__list__item__section-title {
                /* We set pointer events to none to let the list item receive any key/pointer events */
                pointer-events: none;
                display: block;
                font-weight: bold;
                margin-bottom: 0.5em;                
            }
              
        </style>`;
    private _bodyTemplate = `
            <form id="vanilla-reader__dialog-search-form">
               <input type="search" placeholder="Enter search term" aria-label="Enter search term" id="vanilla-reader__dialog-search-form__input">               
            </form>
            <section id="vanilla-reader__dialog-search-results__container" aria-label="Search results" tabindex="-1">               
               <ol id="vanilla-reader__dialog-search-results__list" tabindex="-1">
                </ol>
            </section>`;

    constructor(hostElement: HTMLElement, insertPosition: InsertPosition = 'afterbegin') {
        super(hostElement, 'vanilla-reader__dialog-search-results', 'Search publication', insertPosition);

        this._dialogElement.insertAdjacentHTML(insertPosition, this._bodyStyle);
        this._dialogBodyElement.innerHTML = this._bodyTemplate;
        this._form = this._hostDocument.getElementById('vanilla-reader__dialog-search-form')! as HTMLFormElement;
        this._searchInput = this._hostDocument.getElementById('vanilla-reader__dialog-search-form__input')! as HTMLInputElement;
        this._resultContainer = this._hostDocument.getElementById('vanilla-reader__dialog-search-results__container')!;
        this._resultList = this._hostDocument.getElementById('vanilla-reader__dialog-search-results__list')!;

        this._form.addEventListener('submit', this._event_formSubmit);
        this._searchInput.addEventListener('input', this._event_formMaybeClear);

    }

    onSearchExecute(callback: VanillaStringValueCallback) {
        this._onExecuteSearchCallback = callback;
    }

    onSearchClear(callback: VanillaVoidCallback) {
        this._onClearSearchCallback = callback;
    }

    onSearchResultItemClicked(callback: VanillaUiNavigationIntentEventCallback) {
        this._searchResultClickedCallback = callback;
    }

    populate(resultSet: IVanillaReaderPublicationSearchResultItem[]) {

        this._empty();

        this._resultList.setAttribute('aria-setsize', resultSet.length.toString());

        resultSet.forEach((item: IVanillaReaderPublicationSearchResultItem) => {
            let listItem = this.createItem(item);
            listItem.setAttribute('aria-posinset', (this._resultList.children.length + 1).toString());
            this._resultList.appendChild(listItem);
        });

        this._resultContainer.style.display = 'block';
        window.setTimeout(() => {
            this._searchInput.blur();
            this._resultContainer.focus();
        }, 500);

    }

    createItem(resultItem: IVanillaReaderPublicationSearchResultItem) {
        let itemLocator = resultItem.locator;
        let listItem = this._hostDocument.createElement('li');
        let listItemAnchor = this._hostDocument.createElement('a');
        listItem.setAttribute('tabindex', '-1');
        listItemAnchor.setAttribute('tabindex', '0');
        listItemAnchor.setAttribute('data-contentblocklocator', resultItem.locator);
        listItemAnchor.addEventListener('click', (ev) => {
            this._event_click_keyup(ev);
        });

        listItem.addEventListener('keyup', (ev) => {
            if (ev.key === 'Enter' || ev.key === 'Space') {
                this._event_click_keyup(ev);
            }
        });

        if (itemLocator) {
            listItem.setAttribute('data-contentblocklocator', itemLocator.toString());
        }

        listItemAnchor.insertAdjacentHTML('afterbegin', `<span class="vanilla-reader__dialog-search-form__list__item__section-title" aria-label="Book section">${resultItem.bookSection || ''}</span>`);
        listItemAnchor.insertAdjacentText('beforeend', resultItem.intersectingNodeText || '');

        listItem.appendChild(listItemAnchor);

        return listItem;
    }

    clear() {
        this._empty();
        this._resultContainer.style.display = 'none';
        this._searchInput.value = '';
        this._searchInput.focus();
        if (this._onClearSearchCallback) {
            this._onClearSearchCallback();
        }
    }

    show(silent?: boolean, returnFocusOnClose: boolean = true, onDialogCloseCallbackOverride?: VanillaUiDialogResultCallback<void>, activeItemLocator?: string) {
        super.show(silent, returnFocusOnClose, onDialogCloseCallbackOverride);
        if (activeItemLocator) {
            this.focusOnItemByLocator(activeItemLocator);
        } else {
            this._searchInput.focus();
        }
    }

    setSearchTerm(term: string) {
        this._searchInput.value = term;
    }

    focusOnItemByLocator(locator: string) {
        let resultItem = this._resultList.querySelector<HTMLLIElement>(`[data-contentblocklocator="${locator}"]`);
        if (resultItem) {
            resultItem.focus();
        } else {
            console.log('VanillaReaderUiDialogSearch.focusOnItemByLocator(): Unable to find list item with locator ' + locator);
        }

    }

    /*
    *
    * PRIVATE METHODS
    *
    * */

    private _empty() {
        if (this._resultList.childElementCount > 0) {
            let firstListItem = this._resultList.firstChild;
            while (firstListItem) {
                firstListItem.remove();
                firstListItem = this._resultList.firstChild;
            }
        }
    }

    /*
    *
    * EVENT HANDLERS
    *
    * */

    private _event_click_keyup = (ev: Event) => {
        let targetElement = (ev.target as HTMLElement);
        let locator = targetElement.getAttribute('data-contentblocklocator') || (ev.target as HTMLElement).parentElement!.getAttribute('data-contentblocklocator');
        if (locator && this._searchResultClickedCallback) {

            this._activeResultItem?.removeAttribute('aria-selected');
            targetElement.setAttribute('aria-selected', 'true');

            this._activeResultItem = targetElement;

            this._searchResultClickedCallback({
                navigationType: VanillaReaderNavigationType.GOTO,
                locator: locator,
            });
        }
    };

    private _event_formSubmit = (ev: SubmitEvent) => {
        ev.preventDefault();

        if (this._onExecuteSearchCallback) {
            this._onExecuteSearchCallback(this._searchInput.value);
        }
    };

    private _event_formMaybeClear = (_ev: Event) => {
        if (this._searchInput.value === '' && this._onClearSearchCallback) {
            this.clear();
        }
    };

}
