/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.4.0
 */
 'use strict'; (function (s, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-base'], function (a0) { return f(s,a0); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(s,require('./colibrio-core-base')); } else { throw new Error('Unsupported runtime environment!'); } }( typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function (__self,__dep__colibrio_core_base) {  var api = {}; (function () { var f;function h(a){var c=0;return function(){return c<a.length?{done:!1,value:a[c++]}:{done:!0}}}function k(a){if(!(a instanceof Array)){var c="undefined"!=typeof Symbol&&Symbol.iterator&&a[Symbol.iterator];a=c?c.call(a):{next:h(a)};for(var b=[];!(c=a.next()).done;)b.push(c.value);a=b}return a}var l="function"==typeof Object.create?Object.create:function(a){function c(){}c.prototype=a;return new c},m;
if("function"==typeof Object.setPrototypeOf)m=Object.setPrototypeOf;else{var p;a:{var q={a:!0},r={};try{r.__proto__=q;p=r.a;break a}catch(a){}p=!1}m=p?function(a,c){a.__proto__=c;if(a.__proto__!==c)throw new TypeError(a+" is not extensible");return a}:null}var t=m;function u(a){return"string"===typeof a&&/\S/.test(a)}var v=Array.isArray;var x=new Uint16Array(1);x[0]=4660;new Uint8Array(x.buffer);function y(a){return!!a&&"string"===typeof a.type};function z(a){for(var c=Object.keys(a),b=[],d=0;d<c.length;d++){var e=c[d],g=a[e];e=A(e)+"=";y(g)?b.push(e+z(g)):v(g)&&0<g.length?(g=g[0],y(g)&&b.push(e+z(g))):"string"===typeof g?b.push(e+A(g)):("number"===typeof g&&!isNaN(g)||"boolean"===typeof g)&&b.push(e+g.toString())}return"selector("+b.join(",")+")"}function A(a){return a.replace(/[ =,#()]/g,function(c){return"%"+c.charCodeAt(0).toString(16)})};function B(){}B.prototype.getType=function(){return"FragmentSelector"};B.prototype.toJSON=function(){var a=this.getRefinementSelectors().map(function(b){return b.toJSON()}),c;1<a.length?c=a:1===a.length&&(c=a[0]);return{refinedBy:c,type:this.getType(),value:this.getValue()}};B.prototype.toString=function(){return 0<this.getRefinementSelectors().length?z(this.toJSON()):this.getValue()};function C(a,c,b){this.l=a;this.g=c;this.h=b}C.prototype=l(B.prototype);C.prototype.constructor=C;if(t)t(C,B);else for(var D in B)if("prototype"!=D)if(Object.defineProperties){var E=Object.getOwnPropertyDescriptor(B,D);E&&Object.defineProperty(C,D,E)}else C[D]=B[D];f=C.prototype;f.collapseToEnd=function(){return this};f.collapseToStart=function(){return this};f.createRangeSelectorTo=function(){return null};f.getFragmentSpecificationIri=function(){return this.g};f.getRefinementSelectors=function(){return this.h};
f.getValue=function(){return this.l};f.isRangeSelector=function(){return!1};function F(){}F.prototype.canParseSelectorData=function(a){return"FragmentSelector"===a.type};F.prototype.parseSelectorData=function(a,c){if(!u(a.value))return null;var b=null,d=c.createFromSelectorData(a.refinedBy);if(u(a.conformsTo)){var e=c.getFragmentSelectorParsers().find(function(g){return g.getFragmentSpecificationIri()===a.conformsTo&&g.canParseFragment(a.value)});e&&(b=e.parseFragment(a.value,c,d))}b||(b=c.createFromFragment(a.value,d));b||(b=new C(a.value,a.conformsTo||"",d));return b};function G(a,c){this.g=a.collapseToStart();this.h=c.collapseToEnd()}f=G.prototype;f.collapseToEnd=function(){return this.h};f.collapseToStart=function(){return this.g};f.createRangeSelectorTo=function(a){return new G(this.g,a)};f.getEndSelector=function(){return this.h};f.getRefinementSelectors=function(){return[]};f.getStartSelector=function(){return this.g};f.getType=function(){return"RangeSelector"};f.isRangeSelector=function(){return!0};
f.toJSON=function(){return{end:this.h.toJSON(),start:this.g.toJSON(),type:"RangeSelector"}};f.toString=function(){return z(this.toJSON())};function H(a){this.h=a;this.g=0;this.m=this.h.length}function I(a){for(var c=a.g,b=-1,d="";a.g<a.m&&-1===b;){var e=a.h.charCodeAt(a.g);40===e?(b=1,d=a.h.slice(c,a.g++)):41===e?(b=4,d=a.h.slice(c,a.g)):44===e?(b=4,d=a.h.slice(c,a.g++)):a.g++}return{l:d,i:b}}
function J(a){var c={};do{var b=a;for(var d=-1,e="";b.g<b.m&&44===b.h.charCodeAt(b.g);)b.g++;for(var g=b.g;b.g<b.m&&-1===d;){var n=b.h.charCodeAt(b.g);61===n?(d=3,e=b.h.slice(g,b.g++)):(41===n&&(d=2),b.g++)}b={l:e,i:d};3===b.i&&(d=decodeURIComponent(b.l),b=I(a),4===b.i?c[d]=decodeURIComponent(b.l):1===b.i&&(c[d]=J(a)))}while(-1!==b.i&&2!==b.i);return c};function K(){}K.prototype.canParseFragment=function(a){return a.startsWith("selector(")||a.startsWith("state(")};K.prototype.getFragmentSpecificationIri=function(){return""};K.prototype.parseFragment=function(a,c){a=new H(a);a=1===I(a).i?J(a):null;c=c.createFromSelectorData(a);return 0<c.length?c[0]:null};function L(a){this.g=[];this.h=[];this.h.push(new K);for(var c=0;c<a.length;c++){var b=a[c];"function"===typeof b.canParseFragment&&this.h.push(b);"function"===typeof b.canParseSelectorData&&this.g.push(b)}this.g.push(new F)}f=L.prototype;f.createFromFragment=function(a,c){a.startsWith("#")&&(a=a.slice(1));for(var b=null,d=0;!b&&d<this.h.length;d++){var e=this.h[d];e.canParseFragment(a)&&(b=e.parseFragment(a,this,c||[]))}return b};
f.createFromSelectorData=function(a){var c=[];if(v(a))for(var b=0;b<a.length;b++){if(y(a[b])){var d=M(this,a[b]);d&&c.push(d)}}else y(a)&&(a=M(this,a))&&c.push(a);return c};f.createRangeSelectors=function(a,c){for(var b=[],d={},e=0;e<a.length;d={j:d.j},e++){d.j=a[e];for(var g=null,n=0;!g&&n<c.length;n++){var w=c[n];(g=d.j.createRangeSelectorTo(w))||d.j.getType()!==w.getType()||(g=new G(d.j,w))}g?b.push(g):b.push.apply(b,k(c.map(function(P){return function(Q){return new G(P.j,Q)}}(d))))}return b};
f.getFragmentSelectorParsers=function(){return this.h};f.getSelectorDataParsers=function(){return this.g};function M(a,c){for(var b=null,d=0;!b&&d<a.g.length;d++){var e=a.g[d];e.canParseSelectorData(c)&&(b=e.parseSelectorData(c,a))}return b};function N(){}N.prototype.canParseSelectorData=function(a){return"RangeSelector"===a.type};N.prototype.parseSelectorData=function(a,c){if(!y(a.start)||!y(a.end))return null;var b=c.createFromSelectorData(a.start);a=c.createFromSelectorData(a.end);return 1!==b.length||1!==a.length?null:new G(b[0],a[0])};api={FragmentSelector:B,FragmentSpecificationIri:{CSV:"http://tools.ietf.org/rfc/rfc7111",EPUB3:"http://www.idpf.org/epub/linking/cfi/epub-cfi.html",HTML:"http://tools.ietf.org/rfc/rfc3236",MEDIA:"http://www.w3.org/TR/media-frags/",PDF:"http://tools.ietf.org/rfc/rfc3778",PLAIN_TEXT:"http://tools.ietf.org/rfc/rfc5147",RDF_XML:"http://tools.ietf.org/rfc/rfc3870",SVG:"http://www.w3.org/TR/SVG/",XML:"http://tools.ietf.org/rfc/rfc3023"},RangeSelector:G,RangeSelectorDataParser:N,SelectorFactory:L,SelectorType:{CSS_SELECTOR:"CssSelector",
FRAGMENT_SELECTOR:"FragmentSelector",RANGE_SELECTOR:"RangeSelector",SVG_SELECTOR:"SvgSelector",TEXT_QUOTE_SELECTOR:"TextQuoteSelector",TEXT_POSITION_SELECTOR:"TextPositionSelector",XPATH_SELECTOR:"XPathSelector"},UnknownFragmentSelector:C};var O=new ArrayBuffer(8);new DataView(O);new Uint8Array(O); }).call(__self); return api; } ) );
