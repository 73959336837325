/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {VanillaVoidCallback} from "../VanillaReader/VanillaReaderModel";

export class VanillaReaderUiButtonMenu {
    private _style = `
    <style>
        #vanilla-reader__button-menu {
          background-color: #cea942 !important;
            border: 1px solid #000;
            opacity: 1;
            height: 24px;
            width: auto;
            position: fixed;
            top: 8px;
            margin-left: auto;
            margin-right: auto;
            transition: opacity ease-in-out 0.2s;            
            cursor: pointer;
            z-index: 1;
            opacity: 0.5;
            background-color: var(--vanilla-reader__color__ui__background-light);
            color: var(--vanilla-reader__color__ui__foreground-dark);
        }
    </style>`;
    private _template = `<button id="vanilla-reader__button-menu" title="Open main menu" tabindex="0" accesskey="m"><span aria-hidden="true">drag_handle</span></button>`;
    private _onClickCallback: VanillaVoidCallback | undefined;
    private _onVisibleCallback: VanillaVoidCallback | undefined;
    private _onHiddenCallback: VanillaVoidCallback | undefined;
    private _menuButton: HTMLButtonElement;

    constructor(private _hostElement: HTMLElement, insertPosition: InsertPosition = 'beforeend') {
        this._hostElement.insertAdjacentHTML(insertPosition, this._style);
        this._hostElement.insertAdjacentHTML(insertPosition, this._template);
        this._menuButton = this._hostElement.querySelector('#vanilla-reader__button-menu')!;
        this._menuButton.addEventListener('click', (_ev: MouseEvent) => {
            if (this._onClickCallback) {
                this._onClickCallback();
            }
        });
    }

    show() {
        this._menuButton.removeAttribute('aria-hidden');
        this._menuButton.style.opacity = '1';
        this._menuButton.style.pointerEvents = 'all';
        if (this._onVisibleCallback) {
            this._onVisibleCallback();
        }
    }

    hide() {
        this._menuButton.ariaHidden = 'true';
        this._menuButton.style.opacity = '0';
        this._menuButton.style.pointerEvents = 'none';
        if (this._onHiddenCallback) {
            this._onHiddenCallback();
        }
    }

    onClick(callback: VanillaVoidCallback) {
        this._onClickCallback = callback;
    }

    onVisible(callback: VanillaVoidCallback) {
        this._onVisibleCallback = callback;
    }

    onHidden(callback: VanillaVoidCallback) {
        this._onHiddenCallback = callback;
    }

}