/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.4.0
 */
 'use strict'; (function (s, f) { if (typeof define === 'function' && define.amd) { define([], function () { return f(s); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(s); } else { throw new Error('Unsupported runtime environment!'); } }( typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function (__self) {  var api = {}; (function () { function e(a){a=["object"==typeof globalThis&&globalThis,a,"object"==typeof window&&window,"object"==typeof self&&self,"object"==typeof global&&global];for(var b=0;b<a.length;++b){var c=a[b];if(c&&c.Math==Math)return c}throw Error("Cannot find global object");}var f=e(this);if("undefined"===typeof DOMRect){var Rect=function(a,b,c,d){this.x=a?+a:0;this.y=b?+b:0;this.width=c?+c:0;this.height=d?+d:0};Rect.prototype.toJSON=function(){return{bottom:this.bottom,height:this.height,left:this.left,right:this.right,top:this.top,width:this.width,x:this.x,y:this.y}};f.Object.defineProperties(Rect.prototype,{bottom:{configurable:!0,enumerable:!0,get:function(){return 0<this.height?this.y+this.height:this.y}},left:{configurable:!0,enumerable:!0,get:function(){return 0<this.width?
this.x:this.x+this.width}},right:{configurable:!0,enumerable:!0,get:function(){return 0<this.width?this.x+this.width:this.x}},top:{configurable:!0,enumerable:!0,get:function(){return 0<this.height?this.y:this.y+this.height}}});Rect.fromRect=g;Object.defineProperty(window,"DOMRect",{configurable:!0,writable:!0,value:Rect})}function g(a){return a?new DOMRect(a.x,a.y,a.width,a.height):new DOMRect}; }).call(__self); return api; } ) );
