/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {IVanillaReaderUI} from "./IVanillaReaderUI";
import {
    IVanillaReaderAppEvent_VIEW_TRANSFORM_RESET_INTENT,
    IVanillaReaderAppEvent_VIEW_TRANSFORM_PAN_MODE_ACTIVATE_INTENT,
    IVanillaReaderAppEvent_VIEW_TRANSFORM_PAN_MODE_DEACTIVATE_INTENT,
    IVanillaReaderAppEvent_VIEW_TRANSFORM_ZOOM_TO_LEVEL_INTENT,
    IVanillaReaderAppEvent_VIEW_TRANSFORM_ZOOM_TO_RECT_INTENT,
    VanillaReaderAppEvents,
    VanillaReaderEventBus
} from "../VanillaReader/VanillaReaderEventBus";
import {IVanillaReaderUiComponentController} from "./IVanillaReaderUiComponentController";
import {VanillaReaderUI} from "./VanillaReaderUI";

export interface VanillaReaderUiPanZoomToolController extends IVanillaReaderUiComponentController, IVanillaReaderUI{}

/**
 * # VanillaReaderUiPanZoomToolController
 *
 * ## RESPONSIBILITIES
 * This controller mixing class handles all events and callbacks from `VanillaReaderUiPanZoomTool`.
 *
 * Just as all other controller mixin classes, this class is used to add functionality to the `VanillaReaderUi` class
 * in a "modular" way. Read more about this in the `UI Components and Controller Mixins` in the README.md file.
 *
 * ## RELATED TYPES
 * - VanillaReaderUiPanZoomTool
 *
 */
export class VanillaReaderUiPanZoomToolController {

    static __initialize__(vanillaReaderUiContext: VanillaReaderUI) {
        vanillaReaderUiContext.panZoomTool.onActivate(vanillaReaderUiContext._onPanZoomStateActivateIntent.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.panZoomTool.onDeactivate(vanillaReaderUiContext._onPanZoomStateDeactivateIntent.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.panZoomTool.onZoomReset(vanillaReaderUiContext._onZoomLevelResetIntent.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.panZoomTool.onDragStart(vanillaReaderUiContext._onZoomToolDragStart.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.panZoomTool.onDrag(vanillaReaderUiContext._onZoomToolDrag.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.panZoomTool.onDragEnd(vanillaReaderUiContext._onZoomToolDragEnd.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.panZoomTool.onPanningEnable(vanillaReaderUiContext._onPanningEnableIntent.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.panZoomTool.onPanningDisable(vanillaReaderUiContext._onPanningDisableIntent.bind(vanillaReaderUiContext));
        vanillaReaderUiContext.panZoomTool.onZoomToLevel(vanillaReaderUiContext._onZoomToLevel.bind(vanillaReaderUiContext));

    }

    _onPanZoomStateActivateIntent() {
        if(!this.isMobileScreenSize) {
            this.menuBottom.togglePanZoomButtonState(true);
        }
    }

    _onPanZoomStateDeactivateIntent() {
        this.panZoomTool.resetZoomToolRect();

        if(!this.isMobileScreenSize) {
            this.menuBottom.togglePanZoomButtonState(false);
        }
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_RESET_INTENT>(
            new CustomEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_RESET_INTENT>(VanillaReaderAppEvents.VIEW_TRANSFORM_STATE_RESET_INTENT)
        );
    }

    _onZoomLevelResetIntent() {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_ZOOM_TO_LEVEL_INTENT>(
            new CustomEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_ZOOM_TO_LEVEL_INTENT>(VanillaReaderAppEvents.VIEW_TRANSFORM_ZOOM_TO_LEVEL_INTENT , {detail: {zoomLevel: 1}})
        );
    }

    _onPanningEnableIntent() {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_PAN_MODE_ACTIVATE_INTENT>(
            new CustomEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_PAN_MODE_ACTIVATE_INTENT>(VanillaReaderAppEvents.VIEW_TRANSFORM_PAN_MODE_ACTIVATE_INTENT)
        );
    }

    _onPanningDisableIntent() {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_PAN_MODE_DEACTIVATE_INTENT>(
            new CustomEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_PAN_MODE_DEACTIVATE_INTENT>(VanillaReaderAppEvents.VIEW_TRANSFORM_PAN_MODE_DEACTIVATE_INTENT)
        );
    }

    _onZoomToLevel(zoomLevel: number) {
        VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_ZOOM_TO_LEVEL_INTENT>(
            new CustomEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_ZOOM_TO_LEVEL_INTENT>(VanillaReaderAppEvents.VIEW_TRANSFORM_ZOOM_TO_LEVEL_INTENT , {detail: {zoomLevel}})
        );
    }

    _onZoomToolDragStart() {}

    _onZoomToolDrag() {}

    _onZoomToolDragEnd(_ev: PointerEvent, zoomRect: DOMRect | undefined) {
        this.panZoomTool.disablePointerZoom();
        this.panZoomTool.resetZoomToolRect();
        if(zoomRect) {
            VanillaReaderEventBus.dispatchEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_ZOOM_TO_RECT_INTENT>(
                new CustomEvent<IVanillaReaderAppEvent_VIEW_TRANSFORM_ZOOM_TO_RECT_INTENT>(VanillaReaderAppEvents.VIEW_TRANSFORM_ZOOM_TO_RECT_INTENT , {detail: {zoomRect}})
            );
        }
    }
}