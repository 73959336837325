/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.4.0
 */
 'use strict'; (function (s, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-selector-base','./colibrio-core-base'], function (a0,a1) { return f(s,a0,a1); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(s,require('./colibrio-core-selector-base'),require('./colibrio-core-base')); } else { throw new Error('Unsupported runtime environment!'); } }( typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function (__self,__dep__colibrio_core_selector_base,__dep__colibrio_core_base) {  var api = {}; (function () { var d;function e(a){var b=0;return function(){return b<a.length?{done:!1,value:a[b++]}:{done:!0}}}function h(a){var b="undefined"!=typeof Symbol&&Symbol.iterator&&a[Symbol.iterator];return b?b.call(a):{next:e(a)}}var k="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},l;
if("function"==typeof Object.setPrototypeOf)l=Object.setPrototypeOf;else{var m;a:{var n={a:!0},p={};try{p.__proto__=n;m=p.a;break a}catch(a){}m=!1}l=m?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var q=l;var r=__dep__colibrio_core_base;var t=__dep__colibrio_core_selector_base;function u(a){return"number"===typeof a&&!isNaN(a)};var v=new Uint16Array(1);v[0]=4660;new Uint8Array(v.buffer);function w(a,b){var c=t.FragmentSelector.call(this)||this;c.g=a;c.i=b;c.h="";return c}var x=t.FragmentSelector;w.prototype=k(x.prototype);w.prototype.constructor=w;if(q)q(w,x);else for(var y in x)if("prototype"!=y)if(Object.defineProperties){var z=Object.getOwnPropertyDescriptor(x,y);z&&Object.defineProperty(w,y,z)}else w[y]=x[y];d=w.prototype;
d.collapseToEnd=function(){var a=this.g.timeInterval,b=this.i.map(function(c){return c.collapseToEnd()});return a&&a.endMs?(a=a.endMs,new w({timeInterval:{startMs:a,endMs:a}},b)):new w(this.g,b)};d.collapseToStart=function(){var a=this.g.timeInterval,b=this.i.map(function(c){return c.collapseToStart()});return a?(a=a.startMs,new w({timeInterval:{startMs:a,endMs:a}},b)):new w(this.g,b)};
d.createRangeSelectorTo=function(a){if(a instanceof w&&a.g.timeInterval&&this.g.timeInterval){var b=a.g.timeInterval;a=b.startMs;b=b.endMs;return new w({timeInterval:{startMs:this.g.timeInterval.startMs,endMs:u(b)?b:a}},[])}return null};d.getFragmentSpecificationIri=function(){return t.FragmentSpecificationIri.MEDIA};d.getRefinementSelectors=function(){return this.i};d.getTimeInterval=function(){return this.g.timeInterval||null};
d.getValue=function(){if(!this.h&&this.g.timeInterval){var a=this.g.timeInterval,b=a.endMs;this.h="t="+a.startMs/1E3+(u(b)?","+b/1E3:"")}return this.h};d.isRangeSelector=function(){var a=this.g.timeInterval;return a&&a.endMs?a.endMs>a.startMs:!1};function A(a){try{var b=h(a.split("=").map(function(f){return f?decodeURIComponent(f):void 0})),c=b.next().value,g=b.next().value;if(void 0!==c&&void 0!==g&&"t"===c.toLowerCase())return B(g)}catch(f){console.error("Failed to decode fragment"+a)}}
function B(a){var b=h(a.split(",").map(function(g){return g?parseFloat(g):void 0}));a=b.next().value;var c=b.next().value;b=void 0!==c?isNaN(c)?null:Math.round(1E3*c):void 0;a=u(a)?Math.round(1E3*a):c?0:void 0;if(void 0!==a&&null!==b)return{startMs:a,endMs:b}};var C=r.Logger.logError;function D(){}D.prototype.canParseFragment=function(a){try{var b=decodeURIComponent(a);return/t=/.test(b)}catch(c){return C("Failed to decode fragment: ",a),!1}};D.prototype.getFragmentSpecificationIri=function(){return t.FragmentSpecificationIri.MEDIA};D.prototype.parseFragment=function(a,b,c){return(a=a.split("&").reduce(function(g,f){return(f=A(f))?f:g},void 0))?new w({timeInterval:a},c):null};api={MediaFragmentSelector:w,MediaFragmentSelectorParser:D,MediaFragmentSelectorUtils:{parseTimeIntervalPart:A}};var E=new ArrayBuffer(8);new DataView(E);new Uint8Array(E); }).call(__self); return api; } ) );
