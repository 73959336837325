/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.4.0
 */
 'use strict'; (function (s, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-base','./colibrio-core-io-base','./colibrio-core-url'], function (a0,a1,a2) { return f(s,a0,a1,a2); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(s,require('./colibrio-core-base'),require('./colibrio-core-io-base'),require('./colibrio-core-url')); } else { throw new Error('Unsupported runtime environment!'); } }( typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function (__self,__dep__colibrio_core_base,__dep__colibrio_core_io_base,__dep__colibrio_core_url) {  var api = {}; (function () { var g;function k(a){var b=0;return function(){return b<a.length?{done:!1,value:a[b++]}:{done:!0}}}var l="function"==typeof Object.defineProperties?Object.defineProperty:function(a,b,c){if(a==Array.prototype||a==Object.prototype)return a;a[b]=c.value;return a};
function n(a){a=["object"==typeof globalThis&&globalThis,a,"object"==typeof window&&window,"object"==typeof self&&self,"object"==typeof global&&global];for(var b=0;b<a.length;++b){var c=a[b];if(c&&c.Math==Math)return c}throw Error("Cannot find global object");}var p=n(this);function q(a,b){if(b)a:{var c=p;a=a.split(".");for(var d=0;d<a.length-1;d++){var f=a[d];if(!(f in c))break a;c=c[f]}a=a[a.length-1];d=c[a];b=b(d);b!=d&&null!=b&&l(c,a,{configurable:!0,writable:!0,value:b})}}
q("Symbol",function(a){function b(e){if(this instanceof b)throw new TypeError("Symbol is not a constructor");return new c(d+(e||"")+"_"+f++,e)}function c(e,h){this.g=e;l(this,"description",{configurable:!0,writable:!0,value:h})}if(a)return a;c.prototype.toString=function(){return this.g};var d="jscomp_symbol_"+(1E9*Math.random()>>>0)+"_",f=0;return b});
q("Symbol.iterator",function(a){if(a)return a;a=Symbol("Symbol.iterator");for(var b="Array Int8Array Uint8Array Uint8ClampedArray Int16Array Uint16Array Int32Array Uint32Array Float32Array Float64Array".split(" "),c=0;c<b.length;c++){var d=p[b[c]];"function"===typeof d&&"function"!=typeof d.prototype[a]&&l(d.prototype,a,{configurable:!0,writable:!0,value:function(){return r(k(this))}})}return a});function r(a){a={next:a};a[Symbol.iterator]=function(){return this};return a}
var t="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},u;if("function"==typeof Object.setPrototypeOf)u=Object.setPrototypeOf;else{var v;a:{var w={a:!0},x={};try{x.__proto__=w;v=x.a;break a}catch(a){}v=!1}u=v?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var y=u;function z(){this.j=!1;this.h=null;this.l=void 0;this.g=1;this.o=0;this.i=null}
function A(a){if(a.j)throw new TypeError("Generator is already running");a.j=!0}z.prototype.m=function(a){this.l=a};function B(a,b){a.i={s:b,u:!0};a.g=a.o}z.prototype.return=function(a){this.i={return:a};this.g=this.o};function D(a){this.g=new z;this.h=a}function E(a,b){A(a.g);var c=a.g.h;if(c)return F(a,"return"in c?c["return"]:function(d){return{value:d,done:!0}},b,a.g.return);a.g.return(b);return G(a)}
function F(a,b,c,d){try{var f=b.call(a.g.h,c);if(!(f instanceof Object))throw new TypeError("Iterator result "+f+" is not an object");if(!f.done)return a.g.j=!1,f;var e=f.value}catch(h){return a.g.h=null,B(a.g,h),G(a)}a.g.h=null;d.call(a.g,e);return G(a)}function G(a){for(;a.g.g;)try{var b=a.h(a.g);if(b)return a.g.j=!1,{value:b.value,done:!1}}catch(c){a.g.l=void 0,B(a.g,c)}a.g.j=!1;if(a.g.i){b=a.g.i;a.g.i=null;if(b.u)throw b.s;return{value:b.return,done:!0}}return{value:void 0,done:!0}}
function H(a){this.next=function(b){A(a.g);a.g.h?b=F(a,a.g.h.next,b,a.g.m):(a.g.m(b),b=G(a));return b};this.throw=function(b){A(a.g);a.g.h?b=F(a,a.g.h["throw"],b,a.g.m):(B(a.g,b),b=G(a));return b};this.return=function(b){return E(a,b)};this[Symbol.iterator]=function(){return this}}function I(a){function b(d){return a.next(d)}function c(d){return a.throw(d)}return new Promise(function(d,f){function e(h){h.done?d(h.value):Promise.resolve(h.value).then(b,c).then(e,f)}e(a.next())})};var J=__dep__colibrio_core_base;var aa=__dep__colibrio_core_io_base;var ba=__dep__colibrio_core_url;function K(a,b){a:{a=a.attributes;for(var c=0;c<a.length;c++){var d=a.item(c);if(d&&d.name===b){b=d.value;break a}}b=void 0}if(!b)return null;b=parseInt(b);return"number"!==typeof b||isNaN(b)?null:b};var L=new Uint16Array(1);L[0]=4660;new Uint8Array(L.buffer);function M(a,b){return J.ColibrioError.call(this,"colibrio.core.drm",a,b)||this}var N=J.ColibrioError;M.prototype=t(N.prototype);M.prototype.constructor=M;if(y)y(M,N);else for(var O in N)if("prototype"!=O)if(Object.defineProperties){var P=Object.getOwnPropertyDescriptor(N,O);P&&Object.defineProperty(M,O,P)}else M[O]=N[O];function Q(){this.h=this.g=void 0;this.i=new TextDecoder("utf-8")}var R;Q.prototype.decode=function(a){return 2<=a.length&&254===a[0]&&255===a[1]&&(void 0===this.g&&(this.g=T("utf-16be")),this.g)?this.g.decode(a):2<=a.length&&255===a[0]&&254===a[1]&&(void 0===this.h&&(this.h=T("utf-16le")),this.h)?this.h.decode(a):this.i.decode(a)};function T(a){try{return new TextDecoder(a)}catch(b){return console.error(b),null}};function U(a,b,c,d){this.l=a;this.g=b;this.j=c;this.m=d;this.i=!1;this.h=null}g=U.prototype;g.abort=function(){this.i=!0;this.g.abort()};g.asText=function(){return V(this).then(function(a){R||(R=new Q);return R.decode(a)})};g.asUint8Array=function(){return V(this)};g.getMetadata=function(){return this.l};
g.getReadableStream=function(){var a=this;this.h||(this.h=new ReadableStream({cancel:function(){a.g.abort()},start:function(b){var c;return I(new H(new D(function(d){if(1==d.g){if(a.i){b.error(new M(J.ColibrioErrorType.ABORTED));d.g=0;return}var f=V(a);d.g=3;return{value:f}}c=d.l;b.enqueue(c);b.close();d.g=0})))}}));return this.h};
function V(a){var b;return I(new H(new D(function(c){if(1==c.g){var d=a.g.asUint8Array();c.g=2;return{value:d}}b=c.l;return c.return(a.j.decrypt(b,a.g.getMetadata(),a.m))})))};function W(a){this.i=a;this.h=new Map;this.g=null}g=W.prototype;g.addEncryptionMethod=function(a){this.h.set(a.getName(),a)};g.getDecryptedResourceMetadata=function(a){return this.g?this.g.getEncryptedDataEntryByUri(a.url)?X(a):a:a};
g.getDecryptedResourceResponse=function(a){if(!this.g)return a;var b=this.g.getEncryptedDataEntryByUri(a.getMetadata().url);if(b&&b.algorithmName){var c=this.h.get(b.algorithmName);if(c){var d=X(a.getMetadata());a=new U(d,a,c,b)}else a=this.i?this.i(a,b):new aa.ErrorResourceResponse(a.getMetadata(),new M("ENCRYPTION_ALGORITHM_NOT_SUPPORTED","Algorithm: "+b.algorithmName))}return a};g.getEncryptionMethod=function(a){return this.h.get(a)};g.getEncryptionMethods=function(){return Array.from(this.h.values())};
g.getXmlEncryptionInfo=function(){return this.g};g.isEncrypted=function(a){var b;this.g&&(b=this.g.getEncryptedDataEntryByUri(a.toString()));return void 0!=b};g.removeEncryptionMethod=function(a){this.h.delete(a)};g.setXmlEncryptionInfo=function(a){this.g=a};function X(a){a=Object.assign({},a);a.acceptsRanges=!1;return a};function Y(a,b){this.i=b;this.g=new Map;this.h=[];for(a=a.documentElement.firstChild;a;){if(a&&1===a.nodeType&&a.namespaceURI===J.XmlNamespace.XML_ENCRYPTION&&"EncryptedData"===a.localName){b=a;for(var c=b.firstChild,d=null,f=null,e=null,h=null;c;){if(c&&1===c.nodeType)if(c.namespaceURI===J.XmlNamespace.XML_ENCRYPTION)if("EncryptionMethod"===c.localName)d=c.getAttribute("Algorithm");else if("CipherData"===c.localName){var m=c.firstElementChild;m&&"CipherReference"===m.localName&&(f=m.getAttribute("URI"))}else{if("EncryptionProperties"===
c.localName)b:{for(e=c.firstChild;e;){if(e&&1===e.nodeType&&e.namespaceURI===J.XmlNamespace.XML_ENCRYPTION&&"EncryptionProperty"===e.localName&&(m=e.getElementsByTagNameNS(J.XmlNamespace.EPUB_ENCRYPTION_COMPRESSION,"Compression"),0<m.length)){e=m[0];e={compressionMethod:K(e,"Method"),originalLength:K(e,"OriginalLength")};break b}e=e.nextSibling}e=null}}else if(c.namespaceURI===J.XmlNamespace.XML_SIGNATURES&&"KeyInfo"===c.localName)if(h=c.getElementsByTagNameNS(J.XmlNamespace.XML_SIGNATURES,"RetrievalMethod"),
0<h.length){m=[];for(var C=0;C<h.length;C++){var S=h[C];m.push({uri:S.getAttribute("URI"),type:S.getAttribute("Type")})}h={retrievalMethods:m}}else h=null;c=c.nextSibling}f&&d?(c=new URL(f,this.i),ba.URLUtils.normalizePathnamePercentEncoding(c),b={algorithmName:d,cipherRefUrl:c.toString(),cipherRefUrlRaw:f,encryptionProperties:e,keyInfo:h,nodeTree:J.NodeDataUtils.createXmlElementNodeData(b,!0)}):b=null;b&&(this.h.push(b),this.g.set(b.cipherRefUrl,b))}a=a.nextSibling}}
Y.prototype.getEncryptedDataEntries=function(){return this.h.slice()};Y.prototype.getEncryptedDataEntryByUri=function(a){return this.g.get(a)};api={DrmError:M,DrmErrorType:{ENCRYPTION_ALGORITHM_NOT_SUPPORTED:"ENCRYPTION_ALGORITHM_NOT_SUPPORTED"},DrmManager:W,XmlEncryptionInfo:Y};var Z=new ArrayBuffer(8);new DataView(Z);new Uint8Array(Z); }).call(__self); return api; } ) );
