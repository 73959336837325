/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {
    IVanillaReaderHighlightData,
    VanillaHighlightDataCallback,
    VanillaReaderNavigationType
} from '../VanillaReader/VanillaReaderModel';
import { VanillaReaderUiDialog } from './VanillaReaderUiDialog';
import { VanillaUiDialogResultCallback, VanillaUiNavigationIntentEventCallback } from "./VanillaReaderUiModel";

/**
 * # VanillaReaderUiDialogHighlights
 *
 * ## RESPONSIBILITIES
 *
 * This dialog shows a list of all highlights in the publication.
 *
 * This component is stateful. It's list state is refreshed by the VanillaReaderUI class whenever new data is available.
 *
 * ## RELATED TYPES
 * - VanillaReaderUiDialogHighlightsController
 */

export class VanillaReaderUiDialogHighlights extends VanillaReaderUiDialog<void> {
    private _list: HTMLElement;
    private _onHighlightItemClickedCallback: VanillaUiNavigationIntentEventCallback | undefined;
    private _onHighlightItemEditButtonClickedCallback: VanillaHighlightDataCallback | undefined;
    private _listIsFiltered: boolean = false;
    private _selectedItems: IVanillaReaderHighlightData[] | undefined;

    private _bodyStyle = `
        <style>
            #vanilla-reader__dialog-highlights__list {
                padding-inline: 0;
            }

            #vanilla-reader__dialog-highlights__list li {
                min-height: 48px;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                align-content: space-between;
            }

            #vanilla-reader__dialog-highlights__list li a {
                display: block;
                margin-right: 1em;
                padding: 1em;
                flex-grow: 2;
                border-radius: 8px;
                color: rgba(0,0,0,0.8);
            }

            #vanilla-reader__dialog-highlights__list li button {
                align-self: flex-start;
            }

            .vanilla-reader__dialog-highlights__list__item__section-title {
                /* We set pointer events to none to let the list item receive any key/pointer events */
                pointer-events: none;
                display: block;
                font-weight: bold;
                margin-bottom: 8px;
            }

            .vanilla-reader__dialog-highlights__list__item__selection-text {
                pointer-events: none;
            }

            .vanilla-reader__dialog-highlights__list__item__note {
                margin-top: 16px;
            }

            .vanilla-reader__dialog-highlights__list__item__note summary {
                margin-bottom: 8px;
            }

            .vanilla-empty-element {
                display: none;
            }
        </style>`;

    private _bodyTemplate = `<ul id="vanilla-reader__dialog-highlights__list">
        <span class="vanilla-reader__dialog-highlights__list--empty">There are no highlights yet</span>
    </ul>`;

    private _dialogTitleWhenFiltered: string = 'Highlights in view';
    private static _dialogTitle: string = 'Highlights';

    constructor(hostElement: HTMLElement, insertPosition: InsertPosition = 'afterbegin') {
        super(hostElement, 'vanilla-reader__dialog-highlights', VanillaReaderUiDialogHighlights._dialogTitle, insertPosition);
        this._dialogElement.insertAdjacentHTML('afterbegin', this._bodyStyle);
        this._dialogBodyElement.innerHTML = this._bodyTemplate;
        this._list = this._dialogElement.querySelector<HTMLElement>('#vanilla-reader__dialog-highlights__list')!;
    }

    public get listIsFiltered(): boolean {
        return this._listIsFiltered;
    }

    public get selectedItems(): IVanillaReaderHighlightData[] | undefined {

        return this._selectedItems;
    }

    onHighlightItemClicked(callback: VanillaUiNavigationIntentEventCallback) {
        this._onHighlightItemClickedCallback = callback;
    }

    onHighlightItemEditButtonClicked(callback: VanillaHighlightDataCallback) {
        this._onHighlightItemEditButtonClickedCallback = callback;
    }

    async populate(highlights: IVanillaReaderHighlightData[]): Promise<void> {

        this.empty()
        this._list.innerHTML = ''

        try {

            // @ts-ignore
            let data = await window.reader.fetchAllHighlights()
            highlights = data.data.highlights || []

            if (highlights.length > 0) {
                const highlightsList = document.querySelector('#vanilla-reader__dialog-highlights__list')

                if (highlightsList) {
                    const existingLocators = Array.from(highlightsList.children).map(element =>

                        // @ts-ignore
                        element.dataset.contentblocklocator

                    )

                    highlights.forEach(highlight => {
                        if (!existingLocators.includes(highlight.locator)) {
                            const listItem = this.createItem(highlight)
                            this._list.appendChild(listItem)
                        }
                    })
                }
            } else {
                this._list.innerHTML = '<span class="vanilla-reader__dialog-highlights__list--empty">There are no highlights yet</span>'
            }

            if (this.listIsFiltered) {
                this.filterSelected()
            }

        } catch (error) {
            console.error("Error loading highlights:", error)
            this._list.innerHTML = '<span class="vanilla-reader__dialog-highlights__list--error">Error loading highlights</span>'
        }

    }

    setSelected(selectedHighlights: IVanillaReaderHighlightData[] = []) {

        this._selectedItems = selectedHighlights;
        this._list.querySelectorAll('li').forEach((item) => {
            item.removeAttribute('aria-selected');
            let highlightLocator = item.getAttribute('data-contentblocklocator');
            if (highlightLocator) {
                let shouldSelect = selectedHighlights.find(item => item.locator === highlightLocator);
                if (shouldSelect) {
                    item.setAttribute('aria-selected', 'true');
                }
            }
        });
    }

    show(silent?: boolean, returnFocusOnClose: boolean = true, onDialogCloseCallbackOverride?: VanillaUiDialogResultCallback<void>, filterSelected?: boolean) {

        super.show(silent, returnFocusOnClose, onDialogCloseCallbackOverride);
        if (this._listIsFiltered) {
            this.removeSelectedFilter();
        }

        if (filterSelected) {
            this.title = this._dialogTitleWhenFiltered;
            this.filterSelected();
        } else {
            this.title = VanillaReaderUiDialogHighlights._dialogTitle;
        }

    }

    filterSelected() {

        this._listIsFiltered = true;

        this._list.querySelectorAll<HTMLLIElement>('li').forEach((item: HTMLLIElement) => {
            let itemLocator = item.getAttribute('data-contentblocklocator');
            let keepItem = this._selectedItems?.find((item: IVanillaReaderHighlightData) => {

                return item.locator === itemLocator;
            });
            if (!keepItem) {
                item.style.display = 'none';
            }
        });

    }

    removeSelectedFilter() {
        this._listIsFiltered = false;
        this._list.querySelectorAll<HTMLLIElement>('li').forEach((item: HTMLLIElement) => {
            item.style.display = 'flex';
        });
    }

    createItem(highlight: IVanillaReaderHighlightData) {

        let listItem = this._hostDocument.createElement('li');

        listItem.setAttribute('tabindex', '0');
        listItem.setAttribute('title', `Highlight`);
        listItem.setAttribute('data-booksection', highlight.bookSection || '');
        listItem.setAttribute('data-contentblocklocator', highlight.locator);
        listItem.setAttribute('data-datecreated', `${highlight.dateCreated || ''}`);
        listItem.setAttribute('data-dateupdated', `${highlight.dateUpdated || ''}`);
        listItem.className = 'vanilla-reader__dialog-highlights__list__item';

        let listItemEditButton = this._hostDocument.createElement('button');
        listItemEditButton.innerHTML = '<span aria-hidden="true">edit</span>';
        listItemEditButton.title = 'Edit highlight';
        listItemEditButton.className = 'vanilla-reader__dialog-highlights__list__item__button-edit';
        listItemEditButton.setAttribute('tabindex', '0');

        listItemEditButton.addEventListener('click', (_ev) => {
            if (this._onHighlightItemEditButtonClickedCallback) {
                this._onHighlightItemEditButtonClickedCallback(highlight);
            }
        });

        let listItemAnchor: HTMLElement = this._hostDocument.createElement('a');
        listItemAnchor.setAttribute('data-contentblocklocator', highlight.locator);
        listItemAnchor.addEventListener('click', (ev) => {
            this._event_listItemClicked(ev);
        });

        listItem.addEventListener('keyup', (ev) => {
            if (ev.key === 'Enter' || ev.key === 'Space') {
                this._event_listItemClicked(ev);
            }
        });

        listItemAnchor.style.backgroundColor = highlight.color;
        listItemAnchor.innerHTML = `
            <span class="vanilla-reader__dialog-highlights__list__item__section-title" title="Book section"></span>
            <span class="vanilla-reader__dialog-highlights__list__item__selection-text" title="Highlighted text"></span>
            <details class="vanilla-reader__dialog-highlights__list__item__note ${highlight.comment != '' ?
                '' :
                'vanilla-empty-element'}">
                <summary>Note</summary>
                <span class="vanilla-reader__dialog-highlights__list__item__note__text"></span>
            </details>
            `;

        listItemAnchor.setAttribute('tabindex', '0');
        let spanElementSelectionText = listItemAnchor.querySelector('.vanilla-reader__dialog-highlights__list__item__selection-text') as HTMLElement;
        spanElementSelectionText.innerText = highlight.selectionText.length > 150 ? highlight.selectionText.slice(0, 150) + '...' : highlight.selectionText;
        let spanElementTitle = listItemAnchor.querySelector('.vanilla-reader__dialog-highlights__list__item__section-title') as HTMLElement;
        spanElementTitle.innerText = highlight.bookSection || '';
        let spanElementNoteText = listItemAnchor.querySelector('.vanilla-reader__dialog-highlights__list__item__note__text') as HTMLElement;
        spanElementNoteText.innerText = highlight.comment;

        listItem.appendChild(listItemAnchor);
        listItem.appendChild(listItemEditButton);

        return listItem;
    }

    empty() {
        if (this._list.childElementCount > 0) {
            let firstListItem = this._list.firstChild;
            while (firstListItem) {
                firstListItem.remove();
                firstListItem = this._list.firstChild;
            }
        }
    }

    /*
    *
    * EVENT HANDLERS
    *
    * */

    private _event_listItemClicked = (ev: Event) => {
        let locator = (ev.target as HTMLElement).getAttribute('data-contentblocklocator') || (ev.target as HTMLElement).parentElement!.getAttribute('data-contentblocklocator');
        if (locator && this._onHighlightItemClickedCallback) {
            this._onHighlightItemClickedCallback({ navigationType: VanillaReaderNavigationType.GOTO, locator: locator });
        }
    };

}
