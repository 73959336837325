/**
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SAMPLE_CODE.md file.
 *
 * @copyright Colibrio Software AB - All Rights Reserved
 */
import {ILocator} from '@colibrio/colibrio-reader-framework/colibrio-core-locator';
import {
    IFetchNavigationItemReferencesResult,
    IReaderPublicationNavigationItemReference,
    ISyncMediaEngineEvent,
    ISyncMediaPlayer,
    ISyncMediaSegmentActiveEngineEvent,
    ISyncMediaTimeline,
    ISyncMediaTimelinePosition,
    ISyncMediaTimelinePositionData,
    NavigationCollectionType,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-base';
import {
    ReadingSystemEngine,
    SyncMediaAudioRenderer,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-engine';
import {
    IWpAudiobookReaderPublication,
} from '@colibrio/colibrio-reader-framework/colibrio-readingsystem-formatadapter-wp-audiobook';
import {VanillaReaderAudiobookProgressionTimeline} from './VanillaReaderAudiobookProgressionTimeline';
import {
    VanillaMediaPlayerPlaybackStateChangedCallback,
    VanillaProgressEventCallback,
    VanillaVoidCallback,
} from './VanillaReaderModel';
import {
    IVanillaReaderNavigationItem,
    IVanillaReaderReadingProgressionTimeline,
    IVanillaSyncMediaPlaybackStateData,
} from './VanillaReaderModel';
import {VanillaReaderPublication} from './VanillaReaderPublication';

/*
*
* # VanillaReaderAudiobookPlayer
*
* ## RESPONSIBILITIES
*
* This class encapsulates functionality common to the Imbiblio Reader's Audiobook playback. It is very much a wrapper
* around the existing `SyncMediaPlayer` API, but with some helpful features to make the developer experience a bit more
* intuitive.
*
* A noteworthy method is `_getPlaybackStateData()` that is used to serialize the players current state, which is then
* used by events sent to the UI.
*
* ## PRIMARY COLIBRIO FRAMEWORK TYPES USED
*
* - ISyncMediaPlayer
* - ISyncMediaTimeline
*
*
**/

export class VanillaReaderAudiobookPlayer {
    private _currentActiveNavigationItems: IVanillaReaderNavigationItem[] | undefined;
    private _onMediaPlayerPlaybackPositionChangedCallback: VanillaMediaPlayerPlaybackStateChangedCallback | undefined;
    private _onMediaPlayerPlaybackStateChangedCallback: VanillaMediaPlayerPlaybackStateChangedCallback | undefined;
    private _onMediaPlayerTrackChangedCallback: VanillaMediaPlayerPlaybackStateChangedCallback | undefined;
    private _onMediaPlayerCreated: VanillaVoidCallback | undefined;
    private _onMediaPlayerCreateProgressUpdated: VanillaProgressEventCallback | undefined;

    private _timelinePositionEventTimeoutHandle: number | undefined;
    private _publicationDurationMs: number | undefined;
    private _colibrioSyncMediaPlayer: ISyncMediaPlayer | undefined;
    private _colibrioSyncMediaTimeline: ISyncMediaTimeline | undefined;
    private _colibrioReaderPublication: IWpAudiobookReaderPublication;
    private _colibrioReaderReadingSystem: ReadingSystemEngine;
    private _vanillaReadingProgressionTimeline: VanillaReaderAudiobookProgressionTimeline | undefined;

    constructor(
        private _vanillaReaderPublication: VanillaReaderPublication,
    ) {
        this._colibrioReaderPublication = this._vanillaReaderPublication.getColibrioReaderPublication() as IWpAudiobookReaderPublication;
        this._colibrioReaderReadingSystem = this._colibrioReaderPublication.getReadingSystemEngine();

        /**
         * Creating the timeline can take a while depending on the book. The `progressCallback` is fired continuously
         * during the process to let the UI update its state.
         */
        this._colibrioReaderPublication.createSyncMediaTimeline(this._colibrioReaderPublication.getSpine(), {name: 'audiobookPlayer'}, progress => {
            if (this._onMediaPlayerCreateProgressUpdated) {
                this._onMediaPlayerCreateProgressUpdated(progress);
            }
        }).then((colibrioSyncMediaTimeline: ISyncMediaTimeline) => {

            // All done, let's move on...
            this._onAfterSyncMediaTimelineCreated(colibrioSyncMediaTimeline);
        }).catch((_reason) => {
            console.warn('Unable to create SyncMediaTimeline');
        });
    }

    private _onAfterSyncMediaTimelineCreated(colibrioSyncMediaTimeline: ISyncMediaTimeline) {

        this._colibrioSyncMediaTimeline = colibrioSyncMediaTimeline;
        this._colibrioSyncMediaPlayer = this._colibrioReaderReadingSystem.createSyncMediaPlayer(this._colibrioSyncMediaTimeline, {
            addDefaultMediaObjectRenderers: false,
        });

        // We set the `audioElementPoolSize` to 1 in order to make sure that we reuse the same audio element. This
        // makes it less likely that background playback will be disrupted by garbage collection.
        let audioRenderer = new SyncMediaAudioRenderer({
            audioElementPoolSize: 1,
        });

        this._colibrioSyncMediaPlayer.addMediaObjectRenderer(audioRenderer);

        this._colibrioSyncMediaPlayer.addEngineEventListener('syncMediaSegmentActive', this._event_segmentActive);
        this._colibrioSyncMediaPlayer.addEngineEventListener('syncMediaSeeking', this._event_seeking);
        this._colibrioSyncMediaPlayer.addEngineEventListener('syncMediaSeeked', this._event_seekCompleted);
        this._colibrioSyncMediaPlayer.addEngineEventListener('syncMediaPlay', this._event_playing);
        this._colibrioSyncMediaPlayer.addEngineEventListener('syncMediaPaused', this._event_paused);
        this._colibrioSyncMediaPlayer.addEngineEventListener('syncMediaEndReached', this._event_endReached);
        this._colibrioSyncMediaPlayer.addEngineEventListener('syncMediaTimelinePositionChanged', this._event_timelinePositionChanged);

        this._vanillaReadingProgressionTimeline = new VanillaReaderAudiobookProgressionTimeline(this._colibrioSyncMediaPlayer);

        this._publicationDurationMs = this._vanillaReadingProgressionTimeline.getLength();

        if (this._onMediaPlayerCreated) {
            this._onMediaPlayerCreated();
        }
    }

    public get hasBeenCreated(): boolean {
        return (this._colibrioSyncMediaPlayer != undefined);
    }

    public get hasTimelineBeenCreated(): boolean {
        return (this._colibrioSyncMediaTimeline != undefined);
    }

    onMediaPlayerCreated(callback: VanillaVoidCallback) {
        this._onMediaPlayerCreated = callback;
    }

    onMediaPlayerCreateProgressUpdated(callback: VanillaProgressEventCallback) {
        this._onMediaPlayerCreateProgressUpdated = callback;
    }

    onMediaPlayerTrackChanged(callback: VanillaMediaPlayerPlaybackStateChangedCallback) {
        this._onMediaPlayerTrackChangedCallback = callback;
    }

    onMediaPlayerPlaybackPositionChanged(callback: VanillaMediaPlayerPlaybackStateChangedCallback) {
        this._onMediaPlayerPlaybackPositionChangedCallback = callback;
    }

    onMediaPlayerPlaybackStateChanged(callback: VanillaMediaPlayerPlaybackStateChangedCallback) {
        this._onMediaPlayerPlaybackStateChangedCallback = callback;
    }

    getSyncMediaPlayer(): ISyncMediaPlayer | undefined {
        return this._colibrioSyncMediaPlayer;
    }

    /**
     * Gets the approximate elapsed time from timeline start to current position in milliseconds.
     */
    getApproximateElapsedTimeMs(): number {
        if (!this._colibrioSyncMediaPlayer) {
            throw new DOMException('VanillaReaderAudiobookPlayer: ColibrioSyncMediaPlayer instance does not exist');
        }

        return this._colibrioSyncMediaPlayer.getApproximateElapsedTimeMs();
    }

    /**
     * Get the approximate elapsed time from timeline start in milliseconds to the specified timeline position.
     */
    getApproximateElapsedTimeMsForTimelinePosition(timelinePosition: ISyncMediaTimelinePosition): number {
        if (!this._colibrioSyncMediaPlayer) {
            throw new DOMException('VanillaReaderAudiobookPlayer: ColibrioSyncMediaPlayer instance does not exist');
        }

        return this._colibrioSyncMediaPlayer.getApproximateElapsedTimeMsForTimelinePosition(timelinePosition);
    }

    /**
     * Get the current playback rate, where 1.0 means normal speed.
     * For example: 0.5 is half speed, and 2.0 is double speed.
     */
    getPlaybackRate(): number {
        if (!this._colibrioSyncMediaPlayer) {
            throw new DOMException('VanillaReaderAudiobookPlayer: ColibrioSyncMediaPlayer instance does not exist');
        }

        return this._colibrioSyncMediaPlayer.getPlaybackRate();
    }

    /**
     * Get the SyncMediaTimeline that this player is using.
     */
    getTimeline(): IVanillaReaderReadingProgressionTimeline {
        if (!this._vanillaReadingProgressionTimeline) {
            throw new DOMException('VanillaReaderAudiobookPlayer: ColibrioSyncMediaPlayer instance does not exist');
        }

        return this._vanillaReadingProgressionTimeline;
    }

    /**
     * Get this player's current timeline position.
     */
    getTimelinePosition(): ISyncMediaTimelinePosition {
        if (!this._colibrioSyncMediaPlayer) {
            throw new DOMException('VanillaReaderAudiobookPlayer: ColibrioSyncMediaPlayer instance does not exist');
        }

        return this._colibrioSyncMediaPlayer.getTimelinePosition();
    }

    /**
     * Get the current volume as a value between 0.0 and 1.0
     */
    getVolume(): number {
        if (!this._colibrioSyncMediaPlayer) {
            throw new DOMException('VanillaReaderAudiobookPlayer: ColibrioSyncMediaPlayer instance does not exist');
        }

        return this._colibrioSyncMediaPlayer.getVolume();
    }

    /**
     * If the player is currently at the start of the timeline.
     */
    isAtStart(): boolean {
        if (!this._colibrioSyncMediaPlayer) {
            throw new DOMException('VanillaReaderAudiobookPlayer: ColibrioSyncMediaPlayer instance does not exist');
        }

        return this._colibrioSyncMediaPlayer.isAtStart();
    }

    /**
     * Checks if this instance has been destroyed.
     */
    isDestroyed(): boolean {
        if (!this._colibrioSyncMediaPlayer) {
            throw new DOMException('VanillaReaderAudiobookPlayer: ColibrioSyncMediaPlayer instance does not exist');
        }

        return this._colibrioSyncMediaPlayer.isDestroyed();
    }

    /**
     * If the player has reached the end of the timeline.
     * Calling play() has no effect when this method returns true.
     */
    isAtEnd(): boolean {
        if (!this._colibrioSyncMediaPlayer) {
            throw new DOMException('VanillaReaderAudiobookPlayer: ColibrioSyncMediaPlayer instance does not exist');
        }

        return this._colibrioSyncMediaPlayer.isAtEnd();
    }

    /**
     * If audio is muted.
     */
    isMuted(): boolean {
        if (!this._colibrioSyncMediaPlayer) {
            throw new DOMException('VanillaReaderAudiobookPlayer: ColibrioSyncMediaPlayer instance does not exist');
        }

        return this._colibrioSyncMediaPlayer.isMuted();
    }

    /**
     * If the player is currently paused.
     *
     * Even if this method returns false,
     * the player might still be waiting for media to load or for ReaderView synchronization.
     * Also see `isWaitingForMediaObjectRenderers()` and `isWaitingForReaderViewSynchronization()`.
     */
    isPaused(): boolean {
        if (!this._colibrioSyncMediaPlayer) {
            throw new DOMException('VanillaReaderAudiobookPlayer: ColibrioSyncMediaPlayer instance does not exist');
        }

        return this._colibrioSyncMediaPlayer.isPaused();
    }

    /**
     * If the player is currently playing media.
     * This is a shorthand for: `!isPaused() && isReady()`
     */
    isPlaying(): boolean {
        if (!this._colibrioSyncMediaPlayer) {
            throw new DOMException('VanillaReaderAudiobookPlayer: ColibrioSyncMediaPlayer instance does not exist');
        }
        return this._colibrioSyncMediaPlayer.isPlaying();
    }

    /**
     * True if the media player is ready to play.
     * False if either isWaitingForReaderViewSynchronization() or isWaitingForMediaObjectRenderers() returns true.
     *
     * Clients can still call play() to make the media player resume playback when it has reached ready state.
     */
    isReady(): boolean {
        if (!this._colibrioSyncMediaPlayer) {
            throw new DOMException('VanillaReaderAudiobookPlayer: ColibrioSyncMediaPlayer instance does not exist');
        }
        return this._colibrioSyncMediaPlayer.isReady();
    }

    /**
     * Returns true if the player is currently seeking to a new timeline position.
     */
    isSeeking(): boolean {
        if (!this._colibrioSyncMediaPlayer) {
            throw new DOMException('VanillaReaderAudiobookPlayer: ColibrioSyncMediaPlayer instance does not exist');
        }
        return this._colibrioSyncMediaPlayer.isSeeking();
    }

    /**
     * Pause playback.
     */
    async pause(): Promise<void> {
        this._colibrioSyncMediaPlayer?.pause();

        if (this._onMediaPlayerPlaybackStateChangedCallback) {
            let playbackState = await this._getPlaybackStateData();
            this._onMediaPlayerPlaybackStateChangedCallback(playbackState);
        }
        return;
    }

    /**
     * Attempts to resume playback from the current timeline position as soon as the player is ready.
     * The player will pause if it fails to resume playback.
     */
    async play(): Promise<void> {
        this._colibrioSyncMediaPlayer?.play();

        if (this._onMediaPlayerPlaybackStateChangedCallback) {
            let playbackState = await this._getPlaybackStateData();
            this._onMediaPlayerPlaybackStateChangedCallback(playbackState);
        }

        return;
    }

    seekForward(offsetMs: number = 10000) {
        if (this._colibrioSyncMediaPlayer) {
            this._colibrioSyncMediaPlayer.seekToApproximateTimeMs(this._colibrioSyncMediaPlayer.getApproximateElapsedTimeMs() + offsetMs);
        }
    };

    seekBackward(offsetMs: number = 10000) {
        if (this._colibrioSyncMediaPlayer) {
            this._colibrioSyncMediaPlayer.seekToApproximateTimeMs(this._colibrioSyncMediaPlayer.getApproximateElapsedTimeMs() - offsetMs);
        }
    };


    /**
     * Seek to the approximate time from timeline start in milliseconds.
     *
     * @return true if the player started seeking, false if it was not possible to do so.
     */
    async seekToApproximateTimeMs(timeMs: number): Promise<boolean> {
        let seekResult: boolean = false;

        seekResult = this._colibrioSyncMediaPlayer?.seekToApproximateTimeMs(timeMs) || false;
        if (seekResult) {
            let location = await this._vanillaReadingProgressionTimeline?.fetchContentLocation(timeMs);
            if (location) {
                this._vanillaReadingProgressionTimeline?.updateTimelinePosition(location.getLocator());
            }
        } else {
            console.warn('VanillaReaderAudiobookPlayer.seekToApproximateTimeMs(): Unable to seek to time offset ' + timeMs);
        }
        return seekResult;
    }

    async seekToLocator(locator: ILocator): Promise<boolean> {
        let seekResult: boolean = false;
        let position = await this._vanillaReadingProgressionTimeline?.fetchTimelinePosition(locator);
        if (position && position >= 0) {
            seekResult = this._colibrioSyncMediaPlayer?.seekToApproximateTimeMs(position) || false;
            if (seekResult) {
                this._vanillaReadingProgressionTimeline?.updateTimelinePosition(locator);
            } else {
                console.warn('VanillaReaderAudiobookPlayer.seekToLocator(): Unable to seek to position ', locator.toString());
            }
        } else {
            console.warn('VanillaReaderAudiobookPlayer.seekToLocator(): Could resolve WpAudiobook timeline position using locator ', locator.toString());
        }
        return seekResult;
    }

    /**
     * Seeks to the next segment in the timeline, if such segment exists.
     *
     * @return true if the player started seeking to the new segment, false if it was not possible to do so.
     */
    async seekToNextSegment(): Promise<boolean> {
        let seekResult = this._colibrioSyncMediaPlayer?.seekToNextSegment();
        let position = this._colibrioSyncMediaPlayer?.getApproximateElapsedTimeMs() || 0;
        let locator = await this._vanillaReadingProgressionTimeline?.fetchContentLocation(position);
        if (locator) {
            this._vanillaReadingProgressionTimeline?.updateTimelinePosition(locator.getLocator());
        } else {
            console.warn('VanillaReaderAudiobookPlayer.seekToNextSegment(): Unable to fetch ContentLocation for timeline position ', position);
        }

        return seekResult || false;

    }

    /**
     * Seeks to the previous segment in the timeline, if such segment exists.
     *
     * @return true if the player started seeking to the new segment, false if it was not possible to do so.
     */
    async seekToPreviousSegment(): Promise<boolean> {
        let seekResult = this._colibrioSyncMediaPlayer?.seekToPreviousSegment();
        let position = this._colibrioSyncMediaPlayer?.getApproximateElapsedTimeMs() || 0;
        let locator = await this._vanillaReadingProgressionTimeline?.fetchContentLocation(position);
        if (locator) {
            this._vanillaReadingProgressionTimeline?.updateTimelinePosition(locator.getLocator());
        } else {
            console.warn('VanillaReaderAudiobookPlayer.seekToPreviousSegment(): Unable to fetch ContentLocation for timeline position ', position);
        }
        return seekResult || false;
    }

    /**
     * Seek to a position in the timeline.
     *
     * @return true if the player started seeking to the new position, false if it was not possible to do so.
     */
    seekToTimelinePosition(timelinePosition: ISyncMediaTimelinePosition | ISyncMediaTimelinePositionData): boolean {
        return this._colibrioSyncMediaPlayer?.seekToTimelinePosition(timelinePosition) || false;
    }

    /**
     * Set if audio should be muted.
     */
    setMuted(muted: boolean): void {
        return this._colibrioSyncMediaPlayer?.setMuted(muted);
    }

    /**
     * Sets the playback rate of the media player as a value between 0.25 and 5.0.
     *
     * A value of 1.0 means "normal speed".
     * Values less than 1.0 make the media to play slower than normal.
     * Values greater than 1.0 make it play faster.
     *
     * @param playbackRate - The new playback rate. The value will be clamped between 0.25 and 5.0.
     */
    setPlaybackRate(playbackRate: number): void {
        return this._colibrioSyncMediaPlayer?.setPlaybackRate(playbackRate);
    }

    /**
     * Sets the volume for the media player as a value between 0.0 and 1.0.
     *
     * @param volume - The new volume. The value will be clamped between 0.0 and 1.0.
     */
    setVolume(volume: number): void {
        return this._colibrioSyncMediaPlayer?.setVolume(volume);
    }

    /**
     * Returns the complete runtime state of the audio player. This data is generated continuously as the
     *`onMediaPlayerPlaybackStateChanged` is triggered. A callback function the `VanillaReader` class then sends this
     * data to the UI as an event.
     *
     * */
    private async _getPlaybackStateData(): Promise<IVanillaSyncMediaPlaybackStateData> {

        if (!this._colibrioSyncMediaPlayer) {
            throw new DOMException('VanillaReaderAudiobookPlayer: ColibrioSyncMediaPlayer instance does not exist');
        }

        let position = this._colibrioSyncMediaPlayer.getApproximateElapsedTimeMs();
        let location = await this._vanillaReadingProgressionTimeline?.fetchContentLocation(position);
        let rate = this._colibrioSyncMediaPlayer.getPlaybackRate();
        let volume = this._colibrioSyncMediaPlayer.getVolume();
        let isSeeking = this._colibrioSyncMediaPlayer.isSeeking();
        let isMuted = this._colibrioSyncMediaPlayer.isMuted();
        let isPaused = this._colibrioSyncMediaPlayer.isPaused();
        let locator: string | undefined;
        let navigationItems: IVanillaReaderNavigationItem[] = [];
        let navigationItemRefs: IFetchNavigationItemReferencesResult | undefined;
        let segmentData = this._colibrioSyncMediaPlayer.getTimelinePosition().getSegment()?.toJSON();
        let publicationDurationMs = this._publicationDurationMs;

        if (!this._currentActiveNavigationItems) {
            navigationItemRefs = await location?.fetchNavigationItemReferences({
                collectionTypes: [NavigationCollectionType.TOC],
                greedy: false,
            });

            navigationItemRefs?.getItemsInRange()?.forEach((ref: IReaderPublicationNavigationItemReference) => {
                let timelineStartPosition: number | undefined;
                navigationItems.push({
                    locator: ref.getNavigationItem()?.getLocator()?.toString(),
                    children: [],
                    timelineStartPosition,
                    collectionType: ref.getNavigationCollection().getType(),
                    title: ref.getNavigationItem().getTextContent(),
                });

            });

            // Store this value for upcoming calls. The `this._currentActiveNavigationItems` field is "reset" in the
            // `_event_segmentActive` event handler, when we have a new segment.
            this._currentActiveNavigationItems = navigationItems;
        } else {
            navigationItems = this._currentActiveNavigationItems;
        }

        if (location) {
            locator = location?.getLocator().toString();
        }

        return Promise.resolve({
            locator,
            position,
            navigationItems,
            rate,
            volume,
            isSeeking,
            isMuted,
            isPaused,
            segmentData,
            publicationDurationMs,
        });
    }

    /*
    * Events
    * */

    private _event_segmentActive = async (_ev: ISyncMediaSegmentActiveEngineEvent) => {
        this._currentActiveNavigationItems = undefined;

        if (this._onMediaPlayerTrackChangedCallback) {
            let currentPlaybackState = await this._getPlaybackStateData();
            this._onMediaPlayerTrackChangedCallback(currentPlaybackState);

        }

    };

    private _event_playing = async (_ev: ISyncMediaEngineEvent) => {
        if (this._onMediaPlayerPlaybackStateChangedCallback) {
            let currentPlaybackState = await this._getPlaybackStateData();
            this._onMediaPlayerPlaybackStateChangedCallback(currentPlaybackState);
        }
    };

    private _event_paused = async (_ev: ISyncMediaEngineEvent) => {
        if (this._onMediaPlayerPlaybackStateChangedCallback) {
            let currentPlaybackState = await this._getPlaybackStateData();
            this._onMediaPlayerPlaybackStateChangedCallback(currentPlaybackState);
        }
    };

    private _event_seeking = async (_ev: ISyncMediaEngineEvent) => {
        if (this._onMediaPlayerPlaybackStateChangedCallback) {
            let currentPlaybackState = await this._getPlaybackStateData();
            this._onMediaPlayerPlaybackStateChangedCallback(currentPlaybackState);
        }
    };

    private _event_seekCompleted = async (_ev: ISyncMediaEngineEvent) => {
        if (this._onMediaPlayerPlaybackStateChangedCallback) {
            let currentPlaybackState = await this._getPlaybackStateData();
            this._onMediaPlayerPlaybackStateChangedCallback(currentPlaybackState);
        }
    };

    private _event_endReached = async (_ev: ISyncMediaEngineEvent) => {
        if (this._onMediaPlayerPlaybackStateChangedCallback) {
            let currentPlaybackState = await this._getPlaybackStateData();
            this._onMediaPlayerPlaybackStateChangedCallback(currentPlaybackState);
        }
    };

    private _event_timelinePositionChanged = async (_ev: ISyncMediaEngineEvent) => {
        // If the `_timelinePositionEventTimeoutHandle` is not undefined it is not yet time to run the
        // `_onMediaPlayerPlaybackPositionChangedCallback` so we return.
        if (this._timelinePositionEventTimeoutHandle) {
            return;
        }

        // The SyncMediaTimeline emits this event every 100ms. That's a little to generous, so we set a timeout
        // to only emit it every second instead.
        this._timelinePositionEventTimeoutHandle = window.setTimeout(async () => {
            this._timelinePositionEventTimeoutHandle = undefined;
            if (this._onMediaPlayerPlaybackPositionChangedCallback) {
                let currentPlaybackState = await this._getPlaybackStateData();
                this._onMediaPlayerPlaybackPositionChangedCallback(currentPlaybackState);
            }
        }, 1000);
    };

}